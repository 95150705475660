import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { Panel, PanelBody } from '../../components/panel/panel';
import Select from "react-select";
import ApiEngine from '../../util/ApiEngine';
import { ApiKey, ApiUrl, LanguageKey, _GAME_RANK_TYPE, DEFAULT_PAGE_SIZE } from '../../util/Constant';
import { createFormBody, stringIsNullOrEmpty } from '../../util/Util';
import { setSaveAdminLog, showMessage } from '../../redux/AppAction';
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { AsyncPaginate } from "react-select-async-paginate";
import { array } from 'prop-types';


const ManageNewGameRank = (props) => {
  const _dispatch = useDispatch();
  let _history = useHistory();
  const _location = useLocation();
  const { t, i18n } = useTranslation(LanguageKey._PRIMARY);
  const [labelItemName, setLabelItemName] = useState("All");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [textareaValue, setTextareaValue] = useState("");
  const [gameRankType, setGameRankType] = useState(0);
  const [gameRankName, setGameRankName] = useState("");
  const updateNewGame = async () => {
    let params = {};
    params = {
      "gameNameList": JSON.stringify({ selectedOptions }),
      "gameRankType": gameRankType
    };
    //_dispatch(setSaveAdminLog("MANAGE_UPDATE_NEW_GAME_RANK_TYPE", _history.location.pathname, params));
    let responseJson = await ApiEngine.post(ApiUrl._API_MANAGE_NEW_GAME_WITH_RANK_TYPE, createFormBody(params));
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      _dispatch(showMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
      setLabelItemName("All");
      setSelectedOptions([]);
      setTextareaValue("");
    }
  }

  async function getItemList(search, loadOptions, { page }) {
    let formattedData = [];
    var responseJson = await ApiEngine.get(
      `${ApiUrl._API_GET_PRODUCT_GAME_LIST}?&start=${(page - 1) * DEFAULT_PAGE_SIZE
      }&count=${DEFAULT_PAGE_SIZE}&keyword=${search}`
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY]["data"];
      formattedData = data.map((m) => {
        let name = m.itemName + "-" +  m.providerName
        return { label: name, value: m.id };
      });

      return {
        options: formattedData,
        hasMore: formattedData.length == DEFAULT_PAGE_SIZE,
        additional: {
          page: page + 1,
        },
      };
    }
  }

  const handleSelectChange = (selected) => {
    let x = [...selectedOptions];
    if (!x.filter(x => x.value == selected.value).length > 0) {
      x.push(selected);
    }
    setSelectedOptions(x); // Update the state with the new array
    setTextareaValue(x.map(option => option.label).join('\n'));
  };

  const handleTextareaChange = (event) => {
    const value = event.target.value;
    const lines = value.split('\n');
    const updatedOptions = selectedOptions.filter(option => lines.includes(option.label));
    setSelectedOptions(updatedOptions);
    setTextareaValue(value);
  };
  console.log("123", selectedOptions)

  return (
    <div>
      <div className="row">
        <div className="col-xl-12">
          <h1 className="page-header">{t("MANAGE_GAME_RANK_TITLE")}</h1>
          <br />
          <br />
          <Panel>
            <PanelBody>
              <div className="row">
                <div className="col-lg-4">
                  <div className="form-group">
                    <label>
                      <b>{t("GAME_RANK_TYPE")}</b>
                    </label>
                    <Select
                      styles={{
                        container: () => ({
                          width: "100%"
                        })
                      }}
                      value={gameRankName}
                      options={_GAME_RANK_TYPE}
                      onChange={(e) => {
                        setGameRankType(e.value);
                        setGameRankName(e.label);
                      }}
                      placeholder={t(gameRankName)}
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group">
                    <label>
                      <b>{t("GAME_NAME")}</b>
                    </label>
                    {<AsyncPaginate
                      placeholder={labelItemName}
                      debounceTimeout={250}
                      loadOptions={getItemList}
                      additional={{
                        page: 1,
                      }}
                      onChange={handleSelectChange}
                    />}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-8">
                  <div className="form-group row m-b-15">
                    <label className="col-md-4 col-form-label">
                      <b>{t("ENTER_GAME_NAME")}</b>
                    </label>
                    {/*<label className="col-md-5 col-form-label">*/}
                    {/*  <b>{t("SET_TYPE")}</b>*/}
                    {/*</label>*/}
                    <div className="col-4">
                      <textarea
                        className="form-control"
                        rows="10"
                        cols="50"
                        style={{
                          marginTop: "10px"
                        }}
                        value={textareaValue}
                        onChange={handleTextareaChange}
                      >
                      </textarea>
                    </div>
                    <div>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-lg-3">
                  <div className="form-group">
                    <button type="button" className="btn btn-primary" onClick={() => updateNewGame()}>
                      {t("SUBMIT")}
                    </button>
                  </div>
                </div>
              </div>
            </PanelBody>
          </Panel>
        </div>
      </div>
    </div>
  )
}

export default ManageNewGameRank;