import React, { useState, useEffect } from "react";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import "../../assets/css/custom.css";
import { useHistory } from "react-router-dom";
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { navigateTo, stringIsNullOrEmpty } from "../../util/Util";
import {
  WebUrl,
  ApiKey,
  ApiUrl,
  AccessRight,
  LanguageKey,
  AllVipOption,
} from "../../util/Constant";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import { checkIfPermissionExist } from "../../util/PermissionChecker";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
import { useDispatch } from "react-redux";
import { setBusy, setIdle, showMessage } from "../../redux/AppAction";

/// <summary>
/// Author : -
/// </summary>

const ManageBigWinning = (props) => {
  const { t } = useTranslation(LanguageKey._PRIMARY);
  let _history = useHistory();
  const _dispatch = useDispatch();
  const [bigWinningData, setBigWinningData] = useState([]);
  const [deleteId, setDeleteId] = useState(0);

  let _tableColumns = [
    {
      Header: "USERNAME",
      accessor: "username",
      disableSortBy: true,
    },
    {
      Header: "WINNING_DATE",
      accessor: "winningDate",
      Cell: ({ row }) => {
        return moment(row.original.winningDate).format("YYYY-MM-DD HH:mm:ss");
      },
    },
    {
      Header: "COUNTRY",
      accessor: "country",
      Cell: ({ row }) => {
        return <div> <img
          className="country-flag"
          src={require("../../assets/img/country/" + row.original.country + "-circle.svg")}
          width="25"
          height="25"
          alt="country flag"
        /> {t(row.original.country)}</div>;
      },
    },
    {
      Header: "PROVIDER_NAME",
      accessor: "providerName",
    },
    {
      Header: "GAME_NAME",
      accessor: "gameName",
    },
    {
      Header: "AMOUNT",
      accessor: "amount",
    },
    {
      Header: "STATUS",
      accessor: "status",
      Cell: ({ row }) => (
        <div className="btn-group m-r-5 m-b-5">
          {row.original.status == 1 ? (
            <span className="badge badge-secondary badge-green">
              {t("ACTIVE")}
            </span>
          ) : (
            <span className="badge badge-secondary badge-danger">
              {t("SUSPENDED")}
            </span>
          )}
        </div>
      ),
      disableSortBy: true,
      style: { overflow: "visible" },
    },
    {
      id: "action",
      Header: "",
      Cell: ({ row }) =>
        checkIfPermissionExist(
          AccessRight._BIG_WINNING_PERMISSION,
          true
        ) && (
          <div className="btn-group m-r-5 m-b-5">
            <UncontrolledButtonDropdown>
              <DropdownToggle caret color="default">
                <i className="fas fa-cog"></i>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={() => {
                    navigateTo(
                      _history,
                      WebUrl._URL_CREATE_OR_UPDATE_BIG_WINNING,
                      row.original
                    );
                  }}
                >
                  <span className="text-warning">{t("EDIT")}</span>
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    navigateTo(_history, WebUrl._URL_CREATE_OR_UPDATE_BIG_WINNING, {
                      refId: row.original.id,
                    });
                  }}
                >
                  <span className="text-primary">{t("CLONE")}</span>
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    setDeleteId(row.original.id);
                  }}
                >
                  <span className="text-danger">{t("DELETE")}</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        ),
      disableSortBy: true,
      disableFilters: true,
      width: 100,
      style: { overflow: "visible" },
    },
  ];

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    getBigWinning();
  }, []);


  const getBigWinning = () => {
    fetch(ApiUrl._API_GET_USER_BIG_WINNING, {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          setBigWinningData(responseJson[ApiKey._API_DATA_KEY]);
        }
      });
  }

  const deleteBigWinning = (id) => {
    if (id > 0) {
      _dispatch(setBusy());
      fetch(ApiUrl._API_DELETE_BIG_WINNING + "?id=" + id, {
        method: ApiKey._API_POST,
        headers: {
          "Content-Type": ApiKey._API_FORM_URLENCODED,
          "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          _dispatch(
            showMessage(
              responseJson[ApiKey._API_SUCCESS_KEY],
              t(responseJson[ApiKey._API_MESSAGE_KEY])
            )
          );
          _dispatch(setIdle());
          if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            getBigWinning();
          }
        });
    }
  }

  return (
    <div>
      <h1 className="page-header">
        {t("BIG_WINNING")}
        {checkIfPermissionExist(
          AccessRight._BANNER_SETTING_PERMISSION,
          true
        ) && (
            <NavigationButton
              history={_history}
              url={WebUrl._URL_CREATE_OR_UPDATE_BIG_WINNING}
              buttonText={t("ADD_BIG_WINNING")}
            />
          )}
      </h1>
      <Panel>
        <PanelBody>
          <ReactTable data={bigWinningData} columns={_tableColumns} />
        </PanelBody>
      </Panel>

      {deleteId > 0 && (
        <SweetAlert
          warning
          showCancel
          confirmBtnText={t("CONFIRM")}
          cancelBtnText={t("CANCEL")}
          confirmBtnBsStyle="danger"
          title={t("ARE_YOU_SURE")}
          onConfirm={() => {
            if (deleteId > 0) {
              deleteBigWinning(deleteId);
            }
            setDeleteId(0);
          }}
          onCancel={() => {
            setDeleteId(0);
          }}
        >
          {t("UNABLE_TO_REVERT")}
        </SweetAlert>
      )}
    </div>
  );
};

export default ManageBigWinning;
