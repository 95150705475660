import React, { useState, useEffect, useCallback } from "react";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import { Panel, PanelBody } from "../../components/panel/panel.jsx";
import Select from "react-select";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { setBusy, setIdle, showMessage } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import { ApiKey, ApiUrl, LanguageKey } from "../../util/Constant";
import { stringIsNullOrEmpty, createMultiPartFormBody, numberWithCurrencyFormat, numberWithCurrencyFormat2 } from "../../util/Util";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";
import ApiEngine from "../../util/ApiEngine.js";

/// <summary>
/// Author : -
/// </summary>

const ManageArchiveSalesReport = (props) => {
  const { t } = useTranslation(LanguageKey._PRIMARY);
  const _dispatch = useDispatch();
  const [startDate, setStartDate] = useState(
    moment().startOf("day").format("YYYY-MM-DD HH:mm:ss")
  );
  const [endDate, setEndDate] = useState(
    moment().endOf("day").format("YYYY-MM-DD HH:mm:ss")
  );
  const [username, setUsername] = useState("");
  const [profitLossDatas, setProfitLossDatas] = useState([]);
  const [triggerSearch, setTriggerSearch] = useState(false);
  const [createdBy, setCreatedBy] = useState("");
  const [filteredDatas, setFilteredDatas] = useState([]);
  const [firstHeader, setFirstHeader] = useState("ACCOUNT");

  

  let _tableColumns = [
    {
          Header: t("Date"),
          accessor: "theDate",
          minWidth: 100,
          Cell: ({ row, value }) => {
              return <p>{moment(row.original.theDate).format("YYYY-MM-DD")}</p>
          },
      Footer: ({ row }) => {
        return (
          <span>
            <b>{t("TOTAL")}</b>
          </span>
        );
      },
    },
      {
          Header: t("Account"),
          accessor: "memberUsername",
          minWidth: 100,
      },
    {
      Header: t("Bet Count"),
        accessor: "totalTickets",
      minWidth: 100,
      Cell: ({ row, value }) => {
          if (row.original.totalTickets < 0) {
          return <p style={{ color: "red" }}>{numberWithCurrencyFormat(value)}</p>
        } else {
          return <p>{value}</p>
        }
      },
      Footer: () => {
        let wagerTotal = 0;
        for (let i = 0; i <= filteredDatas.length; i++) {
            if (filteredDatas[i] && filteredDatas[i].original.totalTickets) {
                wagerTotal += parseFloat(filteredDatas[i].original.totalTickets);
          }
        }
        return (
          <span>
            <b>{wagerTotal}</b>
          </span>
        );
      },
    },
    {
      Header: t("Turnover"),
      accessor: "sumSales",
        Cell: ({ row }) => {
          if (row.original.sumSales < 0) {
          return (
              <p style={{ color: "red" }}>{numberWithCurrencyFormat2(row.original.sumSales)}</p>
          );
        } else {
              return <p>{numberWithCurrencyFormat2(row.original.sumSales.toFixed(2), 2, true)}</p>;
        }
      },
      Footer: () => {
        let amountTotal = 0;
        for (let i = 0; i <= filteredDatas.length; i++) {
            if (filteredDatas[i] && filteredDatas[i].original.sumSales) {
                amountTotal += parseFloat(filteredDatas[i].original.sumSales);
          }
        }
        return (
          <span>
                <b>{numberWithCurrencyFormat2(amountTotal.toFixed(2), 2, true)}</b>
          </span>
        );
      },
    },
    {
      Header: t("V.Turnover"),
        accessor: "sumVSales",
      Cell: ({ row }) => {
        if (row.validTurnover < 0) {
          return (
              <p style={{ color: "red" }}>{numberWithCurrencyFormat2(row.original.sumVSales.toFixed(2), 2, true)}</p>
          );
        } else {
            return <p>{numberWithCurrencyFormat2(row.original.sumVSales.toFixed(2), 2, true)}</p>;
        }
      },
      Footer: () => {
        let amountTotal = 0;
        for (let i = 0; i <= filteredDatas.length; i++) {
            if (filteredDatas[i] && filteredDatas[i].original.sumVSales) {
                amountTotal += parseFloat(filteredDatas[i].original.sumVSales);
          }
        }
        return (
          <span>
                <b>{numberWithCurrencyFormat2(amountTotal.toFixed(2), 2, true)}</b>
          </span>
        );
      },
    },
    {
      Header: t("MEMBER_PROFIT_LOSS"),
        accessor: "memberProfitLoss",
      Cell: ({ row }) => {
          if (row.original.memberProfitLoss < 0) {
          return (
            <p style={{ color: "red" }}>
                  {numberWithCurrencyFormat2(row.original.memberProfitLoss.toFixed(2), 2, true)}
            </p>
          );
        } else {
              return <p>{numberWithCurrencyFormat2(row.original.memberProfitLoss.toFixed(2), 2, true)}</p>;
        }
      },
      Footer: () => {
        let memberPLTotal = 0;
        for (let i = 0; i <= filteredDatas.length; i++) {
            if (filteredDatas[i] && filteredDatas[i].original.memberProfitLoss) {
            memberPLTotal += parseFloat(
                filteredDatas[i].original.memberProfitLoss
            );
          }
        }
        return (
          <span>
                <b>{numberWithCurrencyFormat2(memberPLTotal.toFixed(2), 2 ,true)}</b>
          </span>
        );
      },
    },
    {
      Header: t("CLUBFEES"),
        accessor: "sumClubFee",
      Cell: ({ row }) => {
        return (
            <p className={row.original.clubFee < 0 ? "text-red" : ""}>
                {numberWithCurrencyFormat2((row.original.sumClubFee).toFixed(2), 2, true)}
          </p>
        );
      },
      Footer: () => {
        let amountTotal = 0;
        for (let i = 0; i <= filteredDatas.length; i++) {
            if (filteredDatas[i] && filteredDatas[i].original.sumClubFee) {
                amountTotal += parseFloat(filteredDatas[i].original.sumClubFee);
          }
        }
        return (
          <span>
            <b>{numberWithCurrencyFormat2(amountTotal.toFixed(2),2, true)}</b>
          </span>
        );
      },
    },
    {
      Header: t("TAX"),
        accessor: "sumTax",
      Cell: ({ row }) => {
        if (row.tax < 0) {
          return (
            <p style={{ color: "red" }}>
                  {numberWithCurrencyFormat2(row.original.sumTax.toFixed(2), 2, true)}
            </p>
          );
        } else {
            return <p>{numberWithCurrencyFormat2(row.original.sumTax.toFixed(2), 2, true)}</p>;
        }
      },
      Footer: () => {
        let amountTotal = 0;
        for (let i = 0; i <= filteredDatas.length; i++) {
            if (filteredDatas[i] && filteredDatas[i].original.sumTax) {
                amountTotal += parseFloat(filteredDatas[i].original.sumTax);
          }
        }
        return (
          <span>
                <b>{numberWithCurrencyFormat2(amountTotal.toFixed(2), 2, true)}</b>
          </span>
        );
      },
    },
    {
      Header: t("JPC"),
        accessor: "sumJpc",
      Cell: ({ row }) => {
          if (row.original.SumJpc < 0) {
          return (
            <p style={{ color: "red" }}>
                  {row.original.sumJpc.toFixed(2)}
            </p>
          );
        } else {
              return <p>{numberWithCurrencyFormat2(row.original.sumJpc.toFixed(2), 2, true)}</p>;
        }
      },
        Footer: () => {
            let amountTotal = 0;
            for (let i = 0; i <= filteredDatas.length; i++) {
                if (filteredDatas[i] && filteredDatas[i].original.sumJpc) {
                    amountTotal += parseFloat(filteredDatas[i].original.sumJpc);
                }
            }
            return (
                <span>
                    <b>{numberWithCurrencyFormat2(amountTotal.toFixed(2), 2, true)}</b>
                </span>
            );
        },
    },
    {
      Header: t("COMPANY_PROFIT_LOSS"),
      accessor: "companyProfitLoss",
      Cell: ({ row }) => {
          if (row.original.companyProfitLoss < 0) {
          return (
            <p style={{ color: "red" }}>
                  {numberWithCurrencyFormat2(row.original.companyProfitLoss.toFixed(2), 2, true)}
            </p>
          );
        } else {
              return <p>{numberWithCurrencyFormat2(row.original.companyProfitLoss.toFixed(2), 2, true)}</p>;
        }
      },
      Footer: () => {
        let companyPLTotal = 0;
        for (let i = 0; i <= filteredDatas.length; i++) {
            if (filteredDatas[i] && filteredDatas[i].original.companyProfitLoss) {
            companyPLTotal += parseFloat(
                filteredDatas[i].original.companyProfitLoss
            );
          }
        }
        return (
          <span>
                <b>{numberWithCurrencyFormat2(companyPLTotal.toFixed(2), 2, true)}</b>
          </span>
        );
      },
    },
  ];


  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    if (props.id) {
      setUsername(props.username);
      searchBtnHandler(props.username);
    }
  }, [props.id]);

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    init();
    if (props.id) {
      setUsername(props.username);
      searchBtnHandler(props.username);
    }
  }, []);

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    if (!stringIsNullOrEmpty(username)) {
      searchBtnHandler(username);
    }
  }, [triggerSearch]);

  /// <summary>
  /// Author : -
  /// this is used to get the latest filtered record from reacttable
  /// </summary>
  const getFilteredRecords = useCallback(({ page }) => {
    setFilteredDatas(page);
  }, []);

  /// <summary>
  /// Author : -
  /// </summary>
  const init = async () => {
    
 
  };

  /// <summary>
  /// Author : -
  /// </summary>
  const handleEvent = (event, picker) => {
    setStartDate(moment(picker.startDate).format("YYYY-MM-DD HH:mm:ss"));
    setEndDate(moment(picker.endDate).format("YYYY-MM-DD HH:mm:ss"));
  };

  /// <summary>
  /// Author : -
  /// </summary>
  const searchBtnHandler = async (parsedUsername) => {
    let apiUrl =
        ApiUrl._API_GET_ARCHIVE_SALES_REPORT +
      "?startDate=" +
      startDate +
      "&endDate=" +
      endDate;

    if (!stringIsNullOrEmpty(parsedUsername)) {
      apiUrl += "&username=" + parsedUsername;
    }

      _dispatch(setBusy());
      var responseJson = await ApiEngine.get(apiUrl);

      console.log(responseJson);
      console.log(responseJson.data);
      console.log(responseJson["data"]);
      console.log(responseJson[ApiKey._API_SUCCESS_KEY]);
      console.log(responseJson[ApiKey._API_DATA_KEY]);

      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          if (responseJson[ApiKey._API_DATA_KEY] != null) {
              console.log("here")
              setProfitLossDatas(responseJson[ApiKey._API_DATA_KEY]);
              console.log(profitLossDatas)
              console.log("there")
          }
          else {
              setProfitLossDatas([]);
          }
      } else {
          _dispatch(
              showMessage(
                  responseJson[ApiKey._API_SUCCESS_KEY],
                  t(responseJson[ApiKey._API_MESSAGE_KEY])
              )
          );
      }
      console.log(profitLossDatas);
      console.log("hi");
      _dispatch(setIdle());
      
  };


  return (
    <div>
      <h1 className="page-header">
        {stringIsNullOrEmpty(props.id) && t("ARCHIVE_SALES_REPORT")}
      </h1>
      <Panel>
        <PanelBody>
          <div className="row">
            <div className="col-lg-3">
              <div
                className="form-group"
                style={{ display: "flex", width: "100%" }}
              >
                <div
                  className="input-group"
                  style={{ width: "100%", flexFlow: "nowrap" }}
                >
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fa fa-calendar"></i>
                    </span>
                  </div>
                  <div style={{ width: "80%" }}>
                    <DateRangePicker
                      containerStyles={{ width: "100%" }}
                      startDate={startDate}
                      endDate={endDate}
                      onApply={handleEvent}
                      alwaysShowCalendars={true}
                      locale={{ format: "YYYY-MM-DD HH:mm:ss" }}
                      timePicker={true}
                      timePickerSeconds={true}
                      ranges={{
                        Today: [moment().startOf("day"), moment().endOf("day")],
                        Yesterday: [
                          moment().subtract(1, "days").startOf("day"),
                          moment().subtract(1, "days").endOf("day"),
                        ],
                        "Last 7 Days": [
                          moment().subtract(6, "days").startOf("day"),
                          moment().endOf("day"),
                        ],
                        "Last 30 Days": [
                          moment().subtract(29, "days").startOf("day"),
                          moment().endOf("day"),
                        ],
                        "This Month": [
                          moment().startOf("month"),
                          moment().endOf("month"),
                        ],
                        "Last Month": [
                          moment().subtract(1, "month").startOf("month"),
                          moment().subtract(1, "month").endOf("month"),
                        ],
                        "This Year": [
                          moment().startOf("year"),
                          moment().endOf("year"),
                        ],
                      }}
                    >
                      <input
                        type="text"
                        className="form-control"
                        value={startDate + " - " + endDate}
                      />
                    </DateRangePicker>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div
                className="form-group"
                style={{ display: "flex", width: "100%" }}
              >
                <div
                  className="input-group"
                  style={{ width: "100%", flexFlow: "nowrap" }}
                >
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fas fa-user"></i>
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    value={username}
                    placeholder={t("USERNAME")}
                    readOnly={!stringIsNullOrEmpty(props.username)}
                    onChange={(e) => {
                      setUsername(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  searchBtnHandler(username);
                }}
              >
                <i className="fas fa-search"></i> {t("SEARCH")}
              </button>
            </div>
          </div>
          <hr />
          <ReactTable
            data={profitLossDatas}
            columns={_tableColumns}
            renderFooter={true}
            getFilteredRecords={getFilteredRecords}
          />
        </PanelBody>
      </Panel>
    </div>
  );
};

export default ManageArchiveSalesReport;