import React, { useState } from "react";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { ApiKey, ApiUrl, LanguageKey } from "../../util/Constant";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";
import { setBusy, setIdle, showMessage, setSaveAdminLog } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

/// <summary>
/// Author : -
/// </summary>

const ManageMemberReport = (props) => {
  const _dispatch = useDispatch();
  let _history = useHistory();
  const { t } = useTranslation(LanguageKey._PRIMARY);
  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [memberReportDatas, setMemberReportDatas] = useState([]);

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    getMemberReport();
  }, []);

  /// <summary>
  /// Author : -
  /// </summary>
  const getMemberReport = async () => {
    _dispatch(setBusy());
    await fetch(
      ApiUrl._API_GET_MEMBER_REPORT +
        "?startDate=" +
        startDate +
        "&endDate=" +
        endDate,
      {
        method: ApiKey._API_GET,
        headers: {
          "Content-Type": ApiKey._API_FORM_URLENCODED,
          "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
        },
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
          if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          setMemberReportDatas(responseJson[ApiKey._API_DATA_KEY]);
        } else {
          _dispatch(
            showMessage(
              responseJson[ApiKey._API_SUCCESS_KEY],
              t(responseJson[ApiKey._API_MESSAGE_KEY])
            )
          );
        }
        _dispatch(setIdle());
      });
  };

  let _tableColumns = [
    {
      Header: t("USERNAME"),
      accessor: "username",
    },
    {
      Header: t("FULLNAME"),
      accessor: "fullName",
    },
    {
      Header: t("LAST_LOGIN"),
      accessor: "lastLogin",
      minWidth: 100,
      Cell: ({ row }) => {
        return (    
            <>{row.original.lastLogin}</>
        );
      },
    },
    {
      Header: t("DEPOSIT"),
      accessor: "deposit",
    },
    {
      Header: t("WITHDRAWAL"),
      accessor: "withdrawal",
    },
    {
      Header: t("BONUS"),
      accessor: "bonus",
    },
    {
      Header: t("COMMISSION"),
      accessor: "rebate",
    },
    {
      Header: t("TURNOVER"),
      accessor: "turnover",
    },
    {
      Header: t("PROFIT_LOSS"),
      accessor: "profitLoss",
    },
  ];

  /// <summary>
  /// Author : -
  /// </summary>
  const handleEvent = (event, picker) => {
    setStartDate(moment(picker.startDate).format("YYYY-MM-DD"));
    setEndDate(moment(picker.endDate).format("YYYY-MM-DD"));
  };

  async function adminLog() {
    let params = {
      "startDate": startDate,
      "endDate": endDate
    };

    _dispatch(setSaveAdminLog("VIEW_MEMBER_REPORT", _history.location.pathname, params));
  }

  return (
    <div>
      <h1 className="page-header">{t("MEMBER_REPORT")}</h1>
      <Panel>
        <PanelBody>
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-4">
                  <div
                    className="form-group"
                    style={{ display: "flex", width: "100%" }}
                  >
                    <div className="input-group" style={{ width: "100%" }}>
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="fa fa-calendar"></i>
                        </span>
                      </div>
                      <div style={{ width: "80%" }}>
                        <DateRangePicker
                          containerStyles={{ width: "100%" }}
                          startDate={startDate}
                          endDate={endDate}
                          onApply={handleEvent}
                          alwaysShowCalendars={true}
                          locale={{ format: "YYYY-MM-DD" }}
                          ranges={{
                            Today: [moment(), moment()],
                            Yesterday: [
                              moment().subtract(1, "days"),
                              moment().subtract(1, "days"),
                            ],
                            "Last 7 Days": [
                              moment().subtract(6, "days"),
                              moment(),
                            ],
                            "Last 30 Days": [
                              moment().subtract(29, "days"),
                              moment(),
                            ],
                            "This Month": [
                              moment().startOf("month"),
                              moment().endOf("month"),
                            ],
                            "Last Month": [
                              moment().subtract(1, "month").startOf("month"),
                              moment().subtract(1, "month").endOf("month"),
                            ],
                          }}
                        >
                          <input
                            type="text"
                            className="form-control"
                            value={startDate + " - " + endDate}
                          />
                        </DateRangePicker>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => { getMemberReport(); adminLog(); }}
                  >
                    {t("SEARCH")}
                  </button>
                </div>
              </div>
              <hr />
              <ReactTable data={memberReportDatas} columns={_tableColumns} />
            </div>
          </div>
        </PanelBody>
      </Panel>
    </div>
  );
};

export default ManageMemberReport;
