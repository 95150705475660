import React from 'react';
import { PageSettings } from './config/page-settings.js';

import Header from './components/header/header.jsx';
import Sidebar from './components/sidebar/sidebar.jsx';
import Content from './components/content/content.jsx';
import Footer from './components/footer/footer.jsx';
import FloatSubMenu from './components/float-sub-menu/float-sub-menu.jsx';
import LoadingOverlay from 'react-loading-overlay';
import { disposeMessage } from "./redux/AppAction.js";
import { connect } from "react-redux";
import { updateLoginUser, resetLogin, performLogout } from "./redux/AuthAction.js";
import { ApiKey, ApiUrl, WebUrl } from "./util/Constant";
import SweetAlert from 'react-bootstrap-sweetalert';
import { stringIsNullOrEmpty } from './util/Util';
import moment from 'moment';
import { fetch } from 'whatwg-fetch';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import notificationSound from '../src/assets/notification.mp3';
import { Modal, ModalBody } from 'reactstrap';

class App extends React.Component {
    constructor(props) {
        super(props);

        this._1_HR_TIMEOUT_TIME = 60 * 60 * 1000;

        this.events = [
            "load",
            "mousedown",
            "click",
            "keypress"
        ]

        this.audio = new Audio(require("../src/assets/notification.mp3"));

        const Msg = ({ Title, Body }) => (
            <div className="toast-brand">
                <div className="toast-title">{Title}</div>
                <div className="toast-message">{Body}</div>
            </div>
        )

        this.pauseAudio = () => {
            if (window.location.pathname == WebUrl._URL_TRANSACTION_ENQUIRY) {
                this.audio.pause();
            }
        }

        this.toast = (messageContent) => {
            toast(Msg(messageContent));

            this.audio.loop = true;
            //this.audio.play();
        }

        this.toggleSidebarMinify = (e) => {
            e.preventDefault();
            if (this.state.pageSidebarMinify) {
                this.setState(state => ({
                    pageFloatSubMenuActive: false
                }));
            }
            this.setState(state => ({
                pageSidebarMinify: !this.state.pageSidebarMinify
            }));
        }
        this.toggleMobileSidebar = (e) => {
            this.setState(state => ({
                pageSidebarToggled: !this.state.pageSidebarToggled
            }));
        }
        this.handleSetPageSidebar = (value) => {
            this.setState(state => ({
                pageSidebar: value
            }));
        }
        this.handleSetPageSidebarMinified = (value) => {
            this.setState(state => ({
                pageSidebarMinify: value
            }));
        }
        this.handleSetPageSidebarWide = (value) => {
            this.setState(state => ({
                pageSidebarWide: value
            }));
        }
        this.handleSetPageSidebarLight = (value) => {
            this.setState(state => ({
                pageSidebarLight: value
            }));
        }
        this.handleSetPageSidebarTransparent = (value) => {
            this.setState(state => ({
                pageSidebarTransparent: value
            }));
        }
        this.handleSetPageSidebarSearch = (value) => {
            this.setState(state => ({
                pageSidebarSearch: value
            }));
        }

        this.toggleRightSidebar = (e) => {
            e.preventDefault();
            this.setState(state => ({
                pageRightSidebarCollapsed: !this.state.pageRightSidebarCollapsed
            }));
        }
        this.toggleMobileRightSidebar = (e) => {
            e.preventDefault();
            this.setState(state => ({
                pageMobileRightSidebarToggled: !this.state.pageMobileRightSidebarToggled
            }));
        }
        this.handleSetPageRightSidebar = (value) => {
            this.setState(state => ({
                pageRightSidebar: value
            }));
        }

        var floatSubMenuRemove;
        var floatSubMenuCalculate;
        var floatSubMenuRemoveTime = 250;
        this.handleFloatSubMenuOnMouseOver = (e) => {
            clearTimeout(floatSubMenuRemove);
            clearTimeout(floatSubMenuCalculate);
        }
        this.handleFloatSubMenuOnMouseOut = (e) => {
            floatSubMenuRemove = setTimeout(() => {
                this.setState(state => ({
                    pageFloatSubMenuActive: false
                }));
            }, floatSubMenuRemoveTime);
        }
        this.handleSidebarOnMouseOver = (e, menu) => {
            if (this.state.pageSidebarMinify) {
                if (menu.children) {
                    var left = (document.getElementById('sidebar').offsetWidth + document.getElementById('sidebar').offsetLeft) + 'px';

                    clearTimeout(floatSubMenuRemove);
                    clearTimeout(floatSubMenuCalculate);

                    this.setState(state => ({
                        pageFloatSubMenu: menu,
                        pageFloatSubMenuActive: true,
                        pageFloatSubMenuLeft: left
                    }));

                    var offset = e.currentTarget.offsetParent.getBoundingClientRect();

                    floatSubMenuCalculate = setTimeout(() => {
                        var targetTop = offset.top;
                        var windowHeight = window.innerHeight;
                        var targetHeight = document.querySelector('.float-sub-menu-container').offsetHeight;
                        var top, bottom, arrowTop, arrowBottom, lineTop, lineBottom;

                        if ((windowHeight - targetTop) > targetHeight) {
                            top = offset.top + 'px';
                            bottom = 'auto';
                            arrowTop = '20px';
                            arrowBottom = 'auto';
                            lineTop = '20px';
                            lineBottom = 'auto';
                        } else {
                            var aBottom = (windowHeight - targetTop) - 21;
                            top = 'auto';
                            bottom = '0';
                            arrowTop = 'auto';
                            arrowBottom = aBottom + 'px';
                            lineTop = '20px';
                            lineBottom = aBottom + 'px';
                        }

                        this.setState(state => ({
                            pageFloatSubMenuTop: top,
                            pageFloatSubMenuBottom: bottom,
                            pageFloatSubMenuLineTop: lineTop,
                            pageFloatSubMenuLineBottom: lineBottom,
                            pageFloatSubMenuArrowTop: arrowTop,
                            pageFloatSubMenuArrowBottom: arrowBottom,
                            pageFloatSubMenuOffset: offset
                        }));
                    }, 0);

                } else {
                    floatSubMenuRemove = setTimeout(() => {
                        this.setState(state => ({
                            pageFloatSubMenu: '',
                            pageFloatSubMenuActive: false
                        }));
                    }, floatSubMenuRemoveTime);
                }
            }
        }
        this.handleSidebarOnMouseOut = (e) => {
            if (this.state.pageSidebarMinify) {
                floatSubMenuRemove = setTimeout(() => {
                    this.setState(state => ({
                        pageFloatSubMenuActive: false
                    }));
                }, floatSubMenuRemoveTime);
            }
        }
        this.handleFloatSubMenuClick = () => {
            if (this.state.pageSidebarMinify) {
                const windowHeight = window.innerHeight;
                const targetHeight = document.getElementById('float-sub-menu').offsetHeight;
                const targetTop = this.state.pageFloatSubMenuOffset.top;
                const top = ((windowHeight - targetTop) > targetHeight) ? targetTop : 'auto';
                const left = (this.state.pageFloatSubMenuOffset.left + document.getElementById('sidebar').offsetWidth) + 'px';
                const bottom = ((windowHeight - targetTop) > targetHeight) ? 'auto' : '0';
                const arrowTop = ((windowHeight - targetTop) > targetHeight) ? '20px' : 'auto';
                const arrowBottom = ((windowHeight - targetTop) > targetHeight) ? 'auto' : ((windowHeight - targetTop) - 21) + 'px';
                const lineTop = ((windowHeight - targetTop) > targetHeight) ? '20px' : 'auto';
                const lineBottom = ((windowHeight - targetTop) > targetHeight) ? 'auto' : ((windowHeight - targetTop) - 21) + 'px';

                this.setState(state => ({
                    pageFloatSubMenuTop: top,
                    pageFloatSubMenuLeft: left,
                    pageFloatSubMenuBottom: bottom,
                    pageFloatSubMenuLineTop: lineTop,
                    pageFloatSubMenuLineBottom: lineBottom,
                    pageFloatSubMenuArrowTop: arrowTop,
                    pageFloatSubMenuArrowBottom: arrowBottom
                }));
            }
        }

        this.handleSetPageContent = (value) => {
            this.setState(state => ({
                pageContent: value
            }));
        }
        this.handleSetPageContentClass = (value) => {
            this.setState(state => ({
                pageContentClass: value
            }));
        }
        this.handleSetPageContentFullHeight = (value) => {
            this.setState(state => ({
                pageContentFullHeight: value
            }));
        }
        this.handleSetPageContentFullWidth = (value) => {
            this.setState(state => ({
                pageContentFullWidth: value
            }));
        }
        this.handleSetPageContentInverseMode = (value) => {
            this.setState(state => ({
                pageContentInverseMode: value
            }));
        }

        this.handleSetPageHeader = (value) => {
            this.setState(state => ({
                pageHeader: value
            }));
        }
        this.handleSetPageHeaderMegaMenu = (value) => {
            this.setState(state => ({
                pageHeaderMegaMenu: value
            }));
        }
        this.handleSetPageHeaderLanguageBar = (value) => {
            this.setState(state => ({
                pageHeaderLanguageBar: value
            }));
        }

        this.handleSetPageFooter = (value) => {
            this.setState(state => ({
                pageFooter: value
            }));
        }
        this.handleSetPageTopMenu = (value) => {
            this.setState(state => ({
                pageTopMenu: value
            }));
        }
        this.toggleMobileTopMenu = (e) => {
            e.preventDefault();
            this.setState(state => ({
                pageMobileTopMenu: !this.state.pageMobileTopMenu
            }));
        }
        this.handleSetPageTwoSidebar = (value) => {
            this.setState(state => ({
                pageTwoSidebar: value
            }));
        }
        this.handleSetPageBoxedLayout = (value) => {
            if (value === true) {
                document.body.classList.add('boxed-layout');
            } else {
                document.body.classList.remove('boxed-layout');
            }
        }
        this.handleSetBodyWhiteBg = (value) => {
            if (value === true) {
                document.body.classList.add('bg-white');
            } else {
                document.body.classList.remove('bg-white');
            }
        }

        /// <summary>
        /// Author : -
        /// </summary>
        this.resetTimeout = this.resetTimeout.bind(this);

        this.setTimeoutAlert = (value) => {
            this.setState(state => ({
                timeoutAlert: value
            }));
        }

        this.state = {
            pageHeader: true,
            pageheaderMegaMenu: false,
            pageHeaderLanguageBar: true,
            hasScroll: false,
            handleSetPageHeader: this.handleSetPageHeader,
            handleSetPageHeaderLanguageBar: this.handleSetPageHeaderLanguageBar,
            handleSetPageHeaderMegaMenu: this.handleSetPageHeaderMegaMenu,

            pageSidebar: true,
            pageSidebarWide: false,
            pageSidebarLight: false,
            pageSidebarMinify: false,
            pageSidebarToggled: false,
            pageSidebarTransparent: false,
            pageSidebarSearch: false,
            handleSetPageSidebar: this.handleSetPageSidebar,
            handleSetPageSidebarWide: this.handleSetPageSidebarWide,
            handleSetPageSidebarLight: this.handleSetPageSidebarLight,
            handleSetPageSidebarMinified: this.handleSetPageSidebarMinified,
            handleSetPageSidebarTransparent: this.handleSetPageSidebarTransparent,
            handleSetPageSidebarSearch: this.handleSetPageSidebarSearch,
            handleSidebarOnMouseOut: this.handleSidebarOnMouseOut,
            handleSidebarOnMouseOver: this.handleSidebarOnMouseOver,
            toggleSidebarMinify: this.toggleSidebarMinify,
            toggleMobileSidebar: this.toggleMobileSidebar,

            pageFloatSubMenuActive: false,
            pageFloatSubMenu: '',
            pageFloatSubMenuTop: 'auto',
            pageFloatSubMenuLeft: 'auto',
            pageFloatSubMenuBottom: 'auto',
            pageFloatSubMenuLineTop: 'auto',
            pageFloatSubMenuLineBottom: 'auto',
            pageFloatSubMenuArrowTop: 'auto',
            pageFloatSubMenuArrowBottom: 'auto',
            pageFloatSubMenuOffset: '',
            handleFloatSubMenuOnMouseOver: this.handleFloatSubMenuOnMouseOver,
            handleFloatSubMenuOnMouseOut: this.handleFloatSubMenuOnMouseOut,
            handleFloatSubMenuClick: this.handleFloatSubMenuClick,

            pageContent: true,
            pageContentClass: '',
            pageContentFullHeight: false,
            pageContentFullWidth: false,
            pageContentInverseMode: false,
            handleSetPageContent: this.handleSetPageContent,
            handleSetPageContentClass: this.handleSetPageContentClass,
            handleSetPageContentFullHeight: this.handleSetPageContentFullHeight,
            handleSetPageContentFullWidth: this.handleSetPageContentFullWidth,
            handleSetPageContentInverseMode: this.handleSetPageContentInverseMode,

            pageFooter: false,
            handleSetPageFooter: this.handleSetPageFooter,

            pageTopMenu: false,
            pageMobileTopMenu: false,
            toggleMobileTopMenu: this.toggleMobileTopMenu,
            handleSetPageTopMenu: this.handleSetPageTopMenu,

            pageTwoSidebar: false,
            handleSetPageTwoSidebar: this.handleSetPageTwoSidebar,

            pageRightSidebar: false,
            pageRightSidebarToggled: true,
            pageMobileRightSidebarToggled: false,
            toggleRightSidebar: this.toggleRightSidebar,
            toggleMobileRightSidebar: this.toggleMobileRightSidebar,
            handleSetPageRightSidebar: this.handleSetPageRightSidebar,

            handleSetBodyWhiteBg: this.handleSetBodyWhiteBg,
            handleSetPageBoxedLayout: this.handleSetPageBoxedLayout,

            /// <summary>
            /// Author : -
            /// </summary>
            timeoutAlert: false,
            setTimeoutAlert: this.setTimeoutAlert
        };
    }

    /// <summary>
    /// Author : -
    /// Edit: Add Checking for last active
    /// </summary>
    componentDidMount() {
        window.addEventListener("click", this.pauseAudio);

        if (localStorage.getItem("lastActiveAt") != null) {
            let lastDateTime = moment(localStorage.getItem("lastActiveAt"));
            if (moment().diff(lastDateTime, "hours") >= 1) {
                //this.props.performLogout();
                localStorage.removeItem("lastActiveAt");
                return;
            }
        }

        fetch(ApiUrl._API_IS_LOGGED_IN, {
            method: ApiKey._API_GET,
            headers: {
                "Accept": ApiKey._API_APPLICATION_JSON,
                "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY
            }
        }).then((response) => response.json()).then((responseJson) => {
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                this.props.updateLoginUser(responseJson[ApiKey._API_DATA_KEY]);
            }
            else {
                this.props.resetLogin();
            }
        });
    }

    componentWillUnmount() {
    }

    /// <summary>
    /// Author : -
    /// </summary>
    componentDidUpdate(prevState) {
        if (this.props.firebaseState.dataContent != null) {
            this.toast(this.props.firebaseState.dataContent);
        }

        if (prevState.authState.isLoggedIn != this.props.authState.isLoggedIn) {
            if (this.props.authState.isLoggedIn) {
                this.resetTimeout();

                for (var i in this.events) {
                    window.addEventListener(this.events[i], this.resetTimeout)
                }
            }
            else {
                this.destroyTimeout();

                for (var i in this.events) {
                    window.removeEventListener(this.events[i], this.resetTimeout)
                }
            }
        }
    }

    /// <summary>
    /// Author : -
    /// Edit: added localstorage
    /// </summary>
    resetTimeout = () => {
        if (this.props.authState.isLoggedIn) {
            clearTimeout(this.startTimeout);
            this.startTimeout = setTimeout(() => { this.setTimeoutAlert(true); localStorage.removeItem("lastActiveAt"); }, this._1_HR_TIMEOUT_TIME);
            localStorage.setItem("lastActiveAt", moment().format("YYYY-MM-DD HH:mm:ss"));
        }
    }

    /// <summary>
    /// Author : -
    /// Edit: added localstorage remove
    /// </summary>
    destroyTimeout = () => {
        if (this.startTimeout) {
            clearTimeout(this.startTimeout);
            localStorage.removeItem("lastActiveAt");
        }
    }

    /**
     * <summary>
     * Author: -
     * added loading screen
     * </summary>
     **/
    render() {
        return (
            <PageSettings.Provider value={this.state}>
                {this.state.timeoutAlert && <SweetAlert
                    info
                    title="Logging Out"
                    onConfirm={() => { this.setTimeoutAlert(false); this.props.performLogout(); }}
                    onCancel={() => { this.setTimeoutAlert(false); this.props.performLogout(); }}
                    timeout={3000} />
                }
                <LoadingOverlay
                    active={this.props.appState.isBusy || !this.props.authState.loginStateInitialized}
                    spinner
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            zIndex: '10000',
                            position: 'fixed',
                        })
                    }}
                    text={this.props.appState.message}
                >
                </LoadingOverlay>
                {
                    !stringIsNullOrEmpty(this.props.appState.responseMessage) &&
                    <SweetAlert
                        type={this.props.appState.alertType}
                        title={this.props.appState.responseMessage}
                        onConfirm={() => { this.props.disposeMessage(); }}
                        onCancel={() => { this.props.disposeMessage(); }} />
                }
                {this.props.authState.loginStateInitialized &&
                    <div className={
                        'fade page-sidebar-fixed show page-container ' +
                        (this.state.pageHeader ? 'page-header-fixed ' : '') +
                        (this.state.pageSidebar ? '' : 'page-without-sidebar ') +
                        (this.state.pageRightSidebar ? 'page-with-right-sidebar ' : '') +
                        (this.state.pageSidebarWide ? 'page-with-wide-sidebar ' : '') +
                        (this.state.pageSidebarLight ? 'page-with-light-sidebar ' : '') +
                        (this.state.pageSidebarMinify ? 'page-sidebar-minified ' : '') +
                        (this.state.pageSidebarToggled ? 'page-sidebar-toggled ' : '') +
                        (this.state.pageTopMenu ? 'page-with-top-menu ' : '') +
                        (this.state.pageContentFullHeight ? 'page-content-full-height ' : '') +
                        (this.state.pageTwoSidebar ? 'page-with-two-sidebar ' : '') +
                        (this.state.pageRightSidebarCollapsed ? 'page-right-sidebar-collapsed ' : '') +
                        (this.state.pageMobileRightSidebarToggled ? 'page-right-sidebar-toggled ' : '') +
                        (this.state.hasScroll ? 'has-scroll ' : '')
                    }>
                        {this.state.pageHeader && (<Header />)}
                        {this.state.pageSidebar && (<Sidebar />)}
                        {this.state.pageContent && (<Content />)}
                        {this.state.pageFooter && (<Footer />)}
                        <FloatSubMenu />
                    </div>}
            </PageSettings.Provider>
        )
    }
}

const mapStateToProps = state => ({
    ...state
});

export default connect(
    mapStateToProps,
    {
        disposeMessage: disposeMessage,
        updateLoginUser: updateLoginUser,
        resetLogin: resetLogin,
        performLogout: performLogout
    }
)(App);