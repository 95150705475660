import React, { useState, useEffect } from "react";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { ApiKey, ApiUrl, LanguageKey } from "../../util/Constant";
import { numberWithCurrencyFormat } from "../../util/Util";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";
import { setBusy, setIdle, showMessage, setSaveAdminLog } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

/// <summary>
/// Author : -
/// </summary>

const ShareholderProcessSalesReport = (props) => {
    const _dispatch = useDispatch();
    let _history = useHistory();
    const { t } = useTranslation(LanguageKey._PRIMARY);
    const [startDate, setStartDate] = useState(moment().startOf('day').format("YYYY-MM-DD HH:mm:ss"));
    const [endDate, setEndDate] = useState(moment().endOf('day').format("YYYY-MM-DD HH:mm:ss"));
    const [salesData, setSalesData] = useState({});
    const [salesTotal, setSalesTotal] = useState({});
    const [initPage, setInitPage] = useState(true);

    const textLeft = {
        textAlign: "left"
    };

    const textRight = {
        textAlign: "right"
    };

    /// <summary>
    /// Author : -
    /// </summary>
    const getSalesReport = async () => {
        _dispatch(setBusy());
        let params = {
          "startDate": startDate,
          "endDate": endDate
        };

        _dispatch(setSaveAdminLog("VIEW_SHAREHOLDE_PROCESS_SALES_REPORT", _history.location.pathname, params));
        await fetch(
            ApiUrl._API_GET_SHAREHOLDER_PROCESS_SALES_REPORT +
            "?startDate=" +
            startDate +
            "&endDate=" +
            endDate,
            {
                method: ApiKey._API_GET,
                headers: {
                    "Content-Type": ApiKey._API_FORM_URLENCODED,
                    "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
                },
            }
        )
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    setSalesData(responseJson[ApiKey._API_DATA_KEY]);
                } else {
                    _dispatch(
                        showMessage(
                            responseJson[ApiKey._API_SUCCESS_KEY],
                            t(responseJson[ApiKey._API_MESSAGE_KEY])
                        )
                    );
                }
                setInitPage(false);
                _dispatch(setIdle());
            });
    };

    /// <summary>
    /// Author : -
    /// </summary>
    const handleEvent = (event, picker) => {
        setStartDate(moment(picker.startDate).format("YYYY-MM-DD HH:mm:ss"));
        setEndDate(moment(picker.endDate).format("YYYY-MM-DD HH:mm:ss"));
    };

    return (
        <div>
            <h1 className="page-header">{t("SHAREHOLDER_PROCESS_SALES_REPORT")}</h1>
            <Panel>
                <PanelBody>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="row">
                                <div className="col-lg-4">
                                    <div
                                        className="form-group"
                                        style={{ display: "flex", width: "100%" }}
                                    >
                                        <div className="input-group" style={{ width: "100%" }}>
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                    <i className="fa fa-calendar"></i>
                                                </span>
                                            </div>
                                            <div style={{ width: "80%" }}>
                                                <DateRangePicker
                                                    containerStyles={{ width: "100%" }}
                                                    alwaysShowCalendars={true}
                                                    timePicker={true}
                                                    timePickerSeconds={true}
                                                    onApply={handleEvent}
                                                    maxDate={moment().endOf('day')}
                                                    ranges={{
                                                        'Today': [moment().startOf('day'), moment().endOf('day')],
                                                        'Yesterday': [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],
                                                        'Last 7 Days': [moment().subtract(6, 'days').startOf('day'), moment().endOf('day')],
                                                        'Last 30 Days': [moment().subtract(29, 'days').startOf('day'), moment().endOf('day')],
                                                        'This Month': [moment().startOf('month'), moment().endOf('month')],
                                                        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                                                        'This Year': [moment().startOf('year'), moment().endOf('year')]
                                                    }}>
                                                    <input type="text" className="form-control" value={startDate + " - " + endDate} />
                                                </DateRangePicker>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={() => getSalesReport()}
                                    >
                                        {t("SEARCH")}
                                    </button>
                                </div>
                            </div>
                            {Object.keys(salesData) && Object.keys(salesData).length > 0 &&
                                <>
                                    <table className="table table-hover">
                                        <tr>
                                            <th className="process-table process-header" id="date" style={textLeft}>{t("Date")}</th>
                                        <th className="process-table process-header" id="processed">{t("Processed")}</th>
                                        <th className="process-table process-header" id="turnover" style={textRight}>{t("Turnover")}</th>
                                        <th className="process-table process-header" id="turnover" style={textRight}>{t("V.Turnover")}</th>
                                            <th className="process-table process-header" id="winLose" style={textRight}>{t("WinLose")}</th>
                                            <th className="process-table process-header" id="royalty" style={textRight}>{t("Royalty")}</th>
                                            <th className="process-table process-header" id="commission" style={textRight}>{t("Commission")}</th>
                                            <th className="process-table process-header" id="balance" style={textRight}>{t("Balance")}</th>
                                            <th className="process-table process-header" id="payout" style={textRight}>{t("Payout")}</th>
                                        <th className="process-table process-header" id="netProfit" style={textRight}>{t("Net Profit")}</th>
                                        <th className="process-table process-header" id="payout" style={textRight}>{t("Rewards")}</th>
                                        <th className="process-table process-header" id="netProfit" style={textRight}>{t("Club Fee (CF)")}</th>
                                        </tr>

                                        {Object.keys(salesData) && Object.keys(salesData).length > 0 &&
                                            salesData.map((data, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td className="process-table process-data" id="date">{ moment(data["date"]).format("DD-MM-YYYY") }</td>
                                                        <td className="process-table process-data" id="processed">{data["processed"] ? <span className="badge badge-secondary badge-green">{t("YES")}</span> : <span className="badge badge-secondary badge-danger">{t("NO")}</span>}</td>
                                                        <td className="process-table process-data" id="turnover" style={textRight}>{numberWithCurrencyFormat(parseFloat(data["turnover"]), 3, true)}</td>
                                                        <td className="process-table process-data" id="turnover" style={textRight}>{numberWithCurrencyFormat(parseFloat(data["vTurnover"]), 3, true)}</td>
                                                        <td className="process-table process-data" id="winLose" style={textRight}>{numberWithCurrencyFormat(parseFloat(data["winloss"]), 3, true)}</td>
                                                        <td className="process-table process-data" id="royalty" style={textRight}>{numberWithCurrencyFormat(parseFloat(data["royalty"]), 3, true)}</td>
                                                        <td className="process-table process-data" id="commission" style={textRight}>{numberWithCurrencyFormat(parseFloat(data["commission"]), 3, true)}</td>
                                                        <td className="process-table process-data" id="balance" style={textRight}>{numberWithCurrencyFormat(parseFloat(data["balance"]), 3, true)}</td>
                                                        <td className="process-table process-data" id="payout" style={textRight}>{numberWithCurrencyFormat(parseFloat(data["payout"]), 3, true)}</td>
                                                        <td className="process-table process-data" id="netProfit" style={textRight}>{numberWithCurrencyFormat(parseFloat(data["netProfit"]), 3, true)}</td>
                                                        <td className="process-table process-data" id="payout" style={textRight}>{numberWithCurrencyFormat(parseFloat(data["rewards"]), 3, true)}</td>
                                                        <td className="process-table process-data" id="netProfit" style={textRight}>{numberWithCurrencyFormat(parseFloat(data["clubFee"]), 3, true)}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </table>
                                </>
                            }

                            {Object.keys(salesData).length == 0 &&
                                <>
                                    <table className="table table-hover">
                                        <thead>
                                            <tr>
                                                <th className="process-table process-header" id="date" style={textLeft}>{t("Date")}</th>
                                                <th className="process-table process-header" id="processed">{t("Processed")}</th>
                                                <th className="process-table process-header" id="turnover" style={textRight}>{t("Turnover")}</th>
                                                <th className="process-table process-header" id="winLose" style={textRight}>{t("WinLose")}</th>
                                                <th className="process-table process-header" id="royalty" style={textRight}>{t("Royalty")}</th>
                                                <th className="process-table process-header" id="commission" style={textRight}>{t("Commission")}</th>
                                                <th className="process-table process-header" id="balance" style={textRight}>{t("Balance")}</th>
                                                <th className="process-table process-header" id="payout" style={textRight}>{t("Payout")}</th>
                                                <th className="process-table process-header" id="netProfit" style={textRight}>{t("Net Profit")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td colSpan="9">{initPage ? "Please select date and press \"Search\" to continue" : "No report for the date"}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </>
                            }
                        </div>
                    </div>
                </PanelBody>
            </Panel>
        </div>
    );
};

export default ShareholderProcessSalesReport;
