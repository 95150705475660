import React, { useState, useEffect } from "react";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import Select from "react-select";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { showMessage, setSaveAdminLog } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import { ApiKey, ApiUrl, LanguageKey } from "../../util/Constant";
import { stringIsNullOrEmpty } from "../../util/Util";
import BetDetailPanel from "./BetDetailPanel.js";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";
import { useHistory } from "react-router-dom";

/// <summary>
/// Author : -
/// </summary>

const MemberManageBetDetailReport = (props) => {
  const { t } = useTranslation(LanguageKey._PRIMARY);
  const _dispatch = useDispatch();
  let _history = useHistory();
  const ALL_OPTION_LABEL = "All";
  const ALL_OPTION_VALUE = "all";
  const [startDate, setStartDate] = useState(
    moment().startOf("day").format("YYYY-MM-DD HH:mm:ss")
  );
  const [endDate, setEndDate] = useState(
    moment().endOf("day").format("YYYY-MM-DD HH:mm:ss")
  );
  const [search, setSearch] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [productList, setProductList] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(ALL_OPTION_VALUE);
  const [categoryList, setCategoryList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(ALL_OPTION_VALUE);
  const [categoryTabs, setCategoryTabs] = useState([]);
  const [username, setUsername] = useState("");
  const [initFinish, setInitFinish] = useState(false);
  const [searchTrigger, setSearchTrigger] = useState(Date.now());

  /// <summary>
  /// Author : -
  /// </summary>
  const _OPTION_STYLES = {
    control: (base) => ({
      ...base,
      "min-height": "34px",
      height: "34px",
    }),
    valueContainer: (base) => ({
      ...base,
      height: "34px",
      "min-height": "34px",
    }),
    indicatorsContainer: (base) => ({
      ...base,
      height: "34px",
      "min-height": "34px",
    }),
    input: (base) => ({
      height: "34px",
      "min-height": "34px",
    }),
  };

  /// <summary>
  /// Author : -
  /// </summary>
  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    init();
  }, []);

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    if (initFinish && props.id) {
      setUsername(props.username);
      setSearch(true);
      searchBtnHandler();
    }
  }, [initFinish]);

  /// <summary>
  /// Author : -
  /// </summary>
  const init = async () => {
    await fetch(ApiUrl._API_GET_MASTER_PRODUCT_ASSIGNMENT_DATA, {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            const accountProductOptionList = [
              { label: ALL_OPTION_LABEL, value: ALL_OPTION_VALUE },
            ];
            responseJson[ApiKey._API_DATA_KEY].forEach(function (option) {
              accountProductOptionList.push({
                label: option.productName,
                value: option.productId,
              });
            });

            setProductList(accountProductOptionList);
          }
        } else {
          _dispatch(
            showMessage(
              responseJson[ApiKey._API_SUCCESS_KEY],
              t(responseJson[ApiKey._API_MESSAGE_KEY])
            )
          );
        }
      });

    await fetch(ApiUrl._API_GET_PRODUCT_CATEGORY, {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          const productCategorys = [
            { label: ALL_OPTION_LABEL, value: ALL_OPTION_VALUE },
          ];
          const productCategoryTabs = [];
          responseJson[ApiKey._API_DATA_KEY].forEach(function (
            productCategory,
            index
          ) {
            productCategorys.push({
              label: productCategory.title,
              value: productCategory.id,
            });
            /// the data below will be passed to reacttable
            productCategoryTabs.push({
              label: productCategory.title,
              id: productCategory.id,
              fetchUrl: "",
              tempUrl: "",
              display: true,
              refreshRequired: true,
            });
          });
          setCategoryTabs(productCategoryTabs);
          setCategoryList(productCategorys);
        } else {
          _dispatch(
            showMessage(
              responseJson[ApiKey._API_SUCCESS_KEY],
              t(responseJson[ApiKey._API_MESSAGE_KEY])
            )
          );
        }
      });

    setInitFinish(true);
  };

  /// <summary>
  /// Author : -
  /// </summary>
  const handleEvent = (event, picker) => {
    setStartDate(moment(picker.startDate).format("YYYY-MM-DD HH:mm:ss"));
    setEndDate(moment(picker.endDate).format("YYYY-MM-DD HH:mm:ss"));
  };

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    if (initFinish) {
      setCategoryTabs(
        categoryTabs.map((x) => {
          if (activeTab == x.id && x.refreshRequired && x.display) {
            return {
              ...x,
              fetchUrl: x.tempUrl + "&v=" + Date.now(),
              refreshRequired: false,
            };
          } else {
            return { ...x };
          }
        })
      );
    }
  }, [searchTrigger]);

  /// <summary>
  /// Author : -
  /// </summary>
  const searchBtnHandler = async () => {
    let apiUrl =
        ApiUrl._API_GET_MEMBER_BET_DETAIL_REPORT +
        "?memberId=" +
        props.id +
      "&startDate=" +
      startDate +
      "&endDate=" +
      endDate +
      "&GroupDateRequired=false" 

    if (selectedProduct != ALL_OPTION_VALUE) {
      apiUrl += "&productId=" + selectedProduct;
    }

    let tempTabs = categoryTabs.map((x) => {
      if (selectedCategory == ALL_OPTION_VALUE || selectedCategory == x.id) {
        return {
          ...x,
          tempUrl: apiUrl + "&categoryId=" + x.id,
          display: true,
          refreshRequired: true,
        };
      } else {
        return {
          ...x,
          tempUrl: apiUrl + "&categoryId=" + x.id,
          display: false,
          refreshRequired: true,
        };
      }
    });

    setCategoryTabs(tempTabs);

    setActiveTab(
      tempTabs.filter((x) => x.display).length > 0
        ? tempTabs.filter((x) => x.display)[0]?.id
        : 0
    );
    setSearchTrigger(Date.now());
  };

  async function adminLog() {
    let params = {
      "memberId": props.id,
      "startDate": startDate,
      "endDate": endDate,
      "GroupDateRequired": "false"
    };

    if (selectedProduct != ALL_OPTION_VALUE) {
      params["productId"] = selectedProduct;
    }
    _dispatch(setSaveAdminLog("VIEW_MEMBER_BET_DETAIL", _history.location.pathname, params));
  }

  return (
    <div>
      <h1 className="page-header">
        {stringIsNullOrEmpty(props.id) && t("BET_DETAIL_REPORT")}
      </h1>
      <Panel
        style={{
          marginBottom: !stringIsNullOrEmpty(props.id) ? "0px" : "20px",
        }}
      >
        <PanelBody>
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-3">
                  <div
                    className="form-group"
                    style={{
                      display: "flex",
                      width: "100%",
                      flexFlow: "nowrap",
                    }}
                  >
                    <div
                      className="input-group"
                      style={{ width: "100%", flexFlow: "nowrap" }}
                    >
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="fa fa-calendar"></i>
                        </span>
                      </div>
                      <div style={{ width: "80%" }}>
                        <DateRangePicker
                          containerStyles={{ width: "100%" }}
                          startDate={startDate}
                          endDate={endDate}
                          onApply={handleEvent}
                          alwaysShowCalendars={true}
                          locale={{ format: "YYYY-MM-DD HH:mm:ss" }}
                          timePicker={true}
                          timePickerSeconds={true}
                          ranges={{
                            Today: [
                              moment().startOf("day"),
                              moment().endOf("day"),
                            ],
                            Yesterday: [
                              moment().subtract(1, "days").startOf("day"),
                              moment().subtract(1, "days").endOf("day"),
                            ],
                            "Last 7 Days": [
                              moment().subtract(6, "days").startOf("day"),
                              moment().endOf("day"),
                            ],
                            "Last 30 Days": [
                              moment().subtract(29, "days").startOf("day"),
                              moment().endOf("day"),
                            ],
                            "This Month": [
                              moment().startOf("month"),
                              moment().endOf("month"),
                            ],
                            "Last Month": [
                              moment().subtract(1, "month").startOf("month"),
                              moment().subtract(1, "month").endOf("month"),
                            ],
                            "This Year": [
                              moment().startOf("year"),
                              moment().endOf("year"),
                            ],
                          }}
                        >
                          <input
                            type="text"
                            className="form-control"
                            value={startDate + " - " + endDate}
                          />
                        </DateRangePicker>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2">
                  <div
                    className="form-group"
                    style={{ display: "flex", width: "100%" }}
                  >
                    <div
                      className="input-group"
                      style={{ width: "100%", flexFlow: "nowrap" }}
                    >
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="fas fa-gamepad"></i>
                        </span>
                      </div>
                      <div style={{ width: "70%" }}>
                        <Select
                          styles={_OPTION_STYLES}
                          value={productList.filter(
                            (product) => product.value == selectedProduct
                          )}
                          options={productList}
                          onChange={(e) => {
                            setSelectedProduct(e.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2">
                  <div
                    className="form-group"
                    style={{ display: "flex", width: "100%" }}
                  >
                    <div
                      className="input-group"
                      style={{ width: "100%", flexFlow: "nowrap" }}
                    >
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="fas fa-tag"></i>
                        </span>
                      </div>
                      <div style={{ width: "70%" }}>
                        <Select
                          styles={_OPTION_STYLES}
                          value={categoryList.filter(
                            (category) => category.value == selectedCategory
                          )}
                          options={categoryList}
                          onChange={(e) => {
                            setSelectedCategory(e.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2">
                  <div
                    className="form-group"
                    style={{ display: "flex", width: "100%" }}
                  >
                    <div className="input-group" style={{ width: "100%" }}>
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="fas fa-user"></i>
                        </span>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        value={username}
                        placeholder={t("USERNAME")}
                        onChange={(e) => {
                          setUsername(e.target.value);
                        }}
                        readOnly={!stringIsNullOrEmpty(props.username)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-2">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                      setSearch(true);
                      searchBtnHandler();
                      adminLog();
                    }}
                  >
                    <i className="fas fa-search"></i> {t("SEARCH")}
                  </button>
                </div>
              </div>
              <hr />
            </div>
          </div>
        </PanelBody>
      </Panel>
      {search && (
        <div
          className="row"
          style={{
            padding: !stringIsNullOrEmpty(props.id) ? "0px 15px 0px 15px" : "",
          }}
        >
          <div className="col-lg-12">
            <Nav tabs>
              {categoryTabs
                .filter((x) => x.display)
                .map((category, index) => {
                  return (
                    <NavItem key={index}>
                      <NavLink
                        key={index}
                        className={classnames(
                          { active: activeTab == category.id },
                          { "member-bet-nav": !stringIsNullOrEmpty(props.id) }
                        )}
                        onClick={() => {
                          setActiveTab(category.id);
                          setSearchTrigger(Date.now());
                        }}
                      >
                        <span className="d-sm-none">{category.label}</span>
                        <span className="d-sm-block d-none">
                          {category.label}
                        </span>
                      </NavLink>
                    </NavItem>
                  );
                })}
            </Nav>
            <TabContent
              activeTab={activeTab}
              className={props.id ? "member-bet-detail" : ""}
            >
              {categoryTabs.map((category, index) => {
                return (
                  <TabPane tabId={category.id} key={index}>
                    <h3>{category.label}</h3>
                    <BetDetailPanel fetchUrl={category.fetchUrl} />
                  </TabPane>
                );
              })}
            </TabContent>
          </div>
        </div>
      )}
    </div>
  );
};

export default MemberManageBetDetailReport;
