import React, { useEffect } from 'react';
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Panel, PanelBody } from '../../components/panel/panel';
import { useForm } from "react-hook-form";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import { useHistory } from "react-router-dom";
import ApiEngine from '../../util/ApiEngine';
import { ApiKey, ApiUrl, DEFAULT_PAGE_SIZE, LanguageKey } from '../../util/Constant';
import { useTranslation } from "react-i18next";
import { createFormBody, numberWithCurrencyFormat, stringIsNullOrEmpty } from '../../util/Util';
import { useDispatch } from 'react-redux';
import { setBusy, setIdle, showMessage } from '../../redux/AppAction';
import SweetAlert from "react-bootstrap-sweetalert";
import moment from "moment";
import { DateRangePicker } from "react-bootstrap-daterangepicker";
import DatePicker from "react-datepicker";
import { AsyncPaginate } from "react-select-async-paginate";

const FreeSpinRecord = (props) => {
  let { t } = useTranslation(LanguageKey._PRIMARY);
  let _history = useHistory();
  let _dispatch = useDispatch();
  const [initCheck, setInitCheck] = useState(true);
  const [selectedMember, setSelectedMember] = useState("");
  const [selectedContent, setSelectedContent] = useState("");
  const [recordId, setRecordId] = useState(0);
  const [labelUsername, setLabelUsername] = useState("All");
  const [labelContent, setLabelContent] = useState("All");
  const [freeSpinRecordData, setFreeSpinRecordData] = useState([]);
  const [startDate, setStartDate] = useState(
    moment().startOf("day").format("YYYY-MM-DD HH:mm:ss")
  );
  const [endDate, setEndDate] = useState(
    moment().endOf("day").format("YYYY-MM-DD HH:mm:ss")
  );
  const [searchTrigger, setSearchTrigger] = useState(ApiUrl._API_GET_FREE_SPIN_RECORD + "?startDate=" + startDate + "&endDate=" + endDate);
  const _SELECTED_MEMBER_KEY = "selectedMemberKey";
  const _SELECTED_CONTENT_KEY = "selectedContentKey";

  const _columns = [
    {
      Header: "RECORD_ID",
      accessor: "id",
      Cell: ({ row }) => (
        <div>{row.original.id}</div>
      ),
    },
    {
      Header: "MEMBER_USERNAME",
      accessor: "memberUsername",
      Cell: ({ row }) => (
        <div style={{ textAlign: "center" }}>{row.original.memberUsername}</div>
      ),
    },
    {
      Header: "FREE_SPIN_NAME",
      accessor: "freeSpinContentName",
      Cell: ({ row }) => (
        <div style={{ textAlign: "center" }}>{row.original.freeSpinContentName}</div>
      ),
    },
    {
      Header: "DATETIME",
      accessor: "createdTime",
      Cell: ({ row }) => (
        <div style={{ textAlign: "center" }}>{row.original.createdTime}</div>
      ),
    },
    {
      Header: "AMOUNT",
      accessor: "freeSpinWinAmount",
      Cell: ({ row }) => (
        <div style={{ textAlign: "right" }}>{numberWithCurrencyFormat(row.original.freeSpinWinAmount, 3, true)}</div>
      ),
    },
    {
      Header: "MEMBER_BALANCE_BEFORE",
      accessor: "preSettleMemberPrimaryBalance",
      Cell: ({ row }) => (
        <div style={{ textAlign: "right" }}>{numberWithCurrencyFormat(row.original.preSettleMemberPrimaryBalance, 3, true)}</div>
      ),
    },
    {
      Header: "MEMBER_BALANCE_AFTER",
      accessor: "postSettleMemberPrimaryBalance",
      Cell: ({ row }) => (
        <div style={{ textAlign: "right" }}>{numberWithCurrencyFormat(row.original.postSettleMemberPrimaryBalance, 3, true)}</div>
      ),
    }
  ];

  const handleEvent = (event, picker) => {
    setStartDate(moment(picker.startDate).format("YYYY-MM-DD HH:mm:ss"));
    setEndDate(moment(picker.endDate).format("YYYY-MM-DD HH:mm:ss"));
  };

  const searchBtnHandler = async () => {
    let apiUrl =
      ApiUrl._API_GET_FREE_SPIN_RECORD +
      "?startDate=" +
      startDate +
      "&endDate=" +
      endDate

    if (selectedMember !== "") {
      apiUrl += "&memberId=" + selectedMember;
    }
    if (selectedContent !== "") {
      apiUrl += "&contentId=" + selectedContent;
    }
    if (recordId > 0) {
      apiUrl += "&recordId=" + recordId;
    }

    setSearchTrigger(apiUrl);
  };

  async function getMemberList(search, loadOptions, { page }) {
    var responseJson = await ApiEngine.get(
      `${ApiUrl._API_GET_USER_MEMBER_LIST}?&start=${(page - 1) * DEFAULT_PAGE_SIZE
      }&count=${DEFAULT_PAGE_SIZE}&keyword=${search}`
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY]["data"];
      let formattedData = data.map((m) => {
        return { label: m.username, value: m.id };
      });

      return {
        options: formattedData,
        hasMore: formattedData.length == DEFAULT_PAGE_SIZE,
        additional: {
          page: page + 1,
        },
      };
    }
  }

  async function getFreeSpinItemList(search, loadOptions, { page }) {
    var responseJson = await ApiEngine.get(
      `${ApiUrl._API_GET_FREE_SPIN_CONTENTS_LIST}?&start=${(page - 1) * DEFAULT_PAGE_SIZE
      }&count=${DEFAULT_PAGE_SIZE}&keyword=${search}`
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY]["data"];
      let formattedData = data.map((m) => {
        return { label: m.name, value: m.id };
      });

      return {
        options: formattedData,
        hasMore: formattedData.length == DEFAULT_PAGE_SIZE,
        additional: {
          page: page + 1,
        },
      };
    }
  }

  useEffect(async () => {
    _dispatch(setBusy());
    let responseJson = await ApiEngine.get(searchTrigger);

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      if (responseJson[ApiKey._API_DATA_KEY] != null) {
        setFreeSpinRecordData(responseJson[ApiKey._API_DATA_KEY]);
      }
      else {
        setFreeSpinRecordData([]);
      }
    }

    _dispatch(setIdle());
  }, [searchTrigger]);

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    //let responseJson = await ApiEngine.get(ApiUrl._GET_FREE_SPIN_RECORD);
  }

  return (
    <div>
      <h1 className="page-header">
        {t("FREE_SPIN_RECORD")}
        <NavigationButton history={_history} />
      </h1>
      <div className="row">
        <div className="col-xl-12">
          {initCheck && <Panel>
            <PanelBody>
              <div className="row">
                <div className="col-lg-3" style={{ display: "flex", alignItems: "end" }}>
                  <div
                    className="form-group"
                    style={{
                      display: "flex",
                      width: "100%",
                      flexFlow: "nowrap",
                      flexDirection: "column"
                    }}
                  >
                    <label>
                      <b>{t("DATETIME")}</b>
                    </label>
                    <div
                      className="input-group"
                      style={{ width: "100%", flexFlow: "nowrap" }}
                    >
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="fa fa-calendar"></i>
                        </span>
                      </div>
                      <div style={{ width: "80%" }}>
                        <DateRangePicker
                          containerStyles={{ width: "100%" }}
                          startDate={startDate}
                          endDate={endDate}
                          onApply={handleEvent}
                          alwaysShowCalendars={true}
                          locale={{ format: "YYYY-MM-DD HH:mm:ss" }}
                          timePicker={true}
                          timePickerSeconds={true}
                          ranges={{
                            Today: [
                              moment().startOf("day"),
                              moment().endOf("day"),
                            ],
                            Yesterday: [
                              moment().subtract(1, "days").startOf("day"),
                              moment().subtract(1, "days").endOf("day"),
                            ],
                            "Last 7 Days": [
                              moment().subtract(6, "days").startOf("day"),
                              moment().endOf("day"),
                            ],
                            "Last 30 Days": [
                              moment().subtract(29, "days").startOf("day"),
                              moment().endOf("day"),
                            ],
                            "This Month": [
                              moment().startOf("month"),
                              moment().endOf("month"),
                            ],
                            "Last Month": [
                              moment().subtract(1, "month").startOf("month"),
                              moment().subtract(1, "month").endOf("month"),
                            ],
                            "This Year": [
                              moment().startOf("year"),
                              moment().endOf("year"),
                            ],
                          }}
                        >
                          <input
                            type="text"
                            className="form-control"
                            value={startDate + " - " + endDate}
                          />
                        </DateRangePicker>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="form-group">
                    <label>
                      <b>{t("USERNAME")}</b>
                    </label>
                    {<AsyncPaginate
                      placeholder={labelUsername}
                      debounceTimeout={250}
                      loadOptions={getMemberList}
                      additional={{
                        page: 1,
                      }}
                      value={selectedMember}
                      onChange={(e) => {
                        if (!stringIsNullOrEmpty(e.value)) {
                          sessionStorage.setItem(_SELECTED_MEMBER_KEY, e.value);
                        } else {
                          sessionStorage.removeItem(_SELECTED_MEMBER_KEY);
                        }
                        setLabelUsername(e.label);
                        setSelectedMember(e.value);
                      }}
                    />}
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="form-group">
                    <label>
                      <b>{t("FREE_SPIN_CONTENT")}</b>
                    </label>
                    {<AsyncPaginate
                      placeholder={labelContent}
                      debounceTimeout={250}
                      loadOptions={getFreeSpinItemList}
                      additional={{
                        page: 1,
                      }}
                      value={selectedContent}
                      onChange={(e) => {
                        if (!stringIsNullOrEmpty(e.value)) {
                          sessionStorage.setItem(_SELECTED_CONTENT_KEY, e.value);
                        } else {
                          sessionStorage.removeItem(_SELECTED_CONTENT_KEY);
                        }
                        setLabelContent(e.label);
                        setSelectedContent(e.value);
                      }}
                    />}
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="form-group">
                    <label>
                      <b>{t("RECORD_ID")}</b>
                    </label>
                    <input
                      type="number"
                      className="form-control record-id-input"
                      step="1"
                      placeholder={t("RECORD_ID")}
                      onChange={(e) => setRecordId(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-3">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                      //setSearch(true);
                      searchBtnHandler();
                    }}
                  >
                    <i className="fas fa-search"></i> {t("SEARCH")}
                  </button>
                </div>
              </div>
              <hr />
              <ReactTable
                columns={_columns}
                data={freeSpinRecordData}
                filterable
              />
            </PanelBody>
          </Panel>}
        </div>
      </div>
    </div>
  )
}

export default withRouter(FreeSpinRecord);