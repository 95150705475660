import React, { useState, useEffect } from 'react';
import { Panel, PanelBody } from './../../components/panel/panel.jsx';
import 'react-datepicker/dist/react-datepicker.css';
import { ApiKey, ApiUrl, Status, PaymentType, Gender, LanguageKey, MemberStatus, TransferBackMainWallet, Role, AccessRight } from "../../util/Constant";
import { stringIsNullOrEmpty, createFormBody } from "../../util/Util";
import { useForm, Controller } from "react-hook-form";
import moment from 'moment';
import { setBusy, setIdle, showMessage, setSaveAdminLog } from '../../redux/AppAction';
import { useDispatch, useSelector } from "react-redux";
import DateTime from 'react-datetime';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import { fetch } from 'whatwg-fetch';
import ApiEngine from '../../util/ApiEngine.js';
import { useHistory, withRouter, useLocation } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import { checkIfPermissionExist } from "../../util/PermissionChecker";
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';

/// <summary>
/// Author : -
/// </summary>
const EditMemberDetail = (props) => {
    const { t } = useTranslation(LanguageKey._PRIMARY);
    const _dispatch = useDispatch();
    const _location = useLocation();
    const _history = useHistory();

    const { register, handleSubmit, errors, setValue, watch, trigger, formState, control } = useForm();

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [memberId, setMemberId] = useState("");

    const [confirmPassword, setConfirmPassword] = useState("");
    const [memberPin, setMemberPin] = useState("");
    const [shareholderPin, setShareholderPin] = useState("");
    const [fullName, setFullName] = useState("");
    const [phone, setPhone] = useState("");
    const [isPhoneVerified, setIsPhoneVerified] = useState(false);
    const [email, setEmail] = useState("");
    const [remarks, setRemarks] = useState("");
    const [gender, setGender] = useState("");
    const [rollOverThreshold, setRollOverThreshold] = useState("");
    const [riskLevel, setRiskLevel] = useState("");
    const [internalTransferLimit, setInternalTransferLimit] = useState(0);
    const [betLimitCode, setBetLimitCode] = useState("");
    const [creditLimit, setCreditLimit] = useState(0);
    const [memberDateJoined, setMemberDateJoined] = useState(moment());
    const [memberLastLogin, setMemberLastLogin] = useState(moment());
    const [shareholderDate, setShareholderDate] = useState(moment());
    const [shareholderPercent, setShareholderPercent] = useState("");
    const [phoneNumberUpdatedByText, setPhoneNumberUpdatedByText] = useState("");
    const [phoneNumberUpdated, setPhoneNumberUpdated] = useState(false);
    const [oldPhoneNumber, setOldPhoneNumber] = useState("");
    const [internalTransferStatus, setInternalTransferStatus] = useState(false);
    const [isTestAccount, setIsTestAccount] = useState(false);
    const [isTestAccountOld, setIsTestAccountOld] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);

    const [timeZone, setTimeZone] = useState(0);
    const [memberData, setMemberData] = useState([]);
    const [source, setSource] = useState("");
    const [campaign, setCampaign] = useState("");
    const [address, setAddress] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [state, setState] = useState("");
    const [city, setCity] = useState("");
    const [referralCode, setReferralCode] = useState('');
    const [parentMemberId, setParentMemberId] = useState('');

    const [paymentType, setPaymentType] = useState("");
    const [vipOption, setVipOption] = useState([]);
    const [status, setStatus] = useState(true);
    const [changeRiskLvl, setChangeRiskLvl] = useState(false);
    const [blockWithdraw, setBlockWithdraw] = useState(false);

    const _DETAIL_TAB = 1;
    const _BET_LIMIT = 2;
    const [activeTab, setActiveTab] = useState(_DETAIL_TAB);
    const [casinoProduct, setCasinoProduct] = useState([]);
    const [maxBetLimitLength, setMaxBetLimitLength] = useState(0);
    const [selectedBetLimit, setSelectedBetLimit] = useState({});

    const statusOption = Object.keys(MemberStatus).filter(x => {
      if (checkIfPermissionExist(AccessRight._CLOSE_MEMBER_ACCOUNT_PERMISSION, true)) {
        return x;
      }
      else {
        return x !== "_CLOSED";
      }
    })
      .map((status, index) => {
        return { label: t(status.substr(status.indexOf("_") + 1)), value: MemberStatus[status] }
    });

    const [transferBackToMainWallet, setTransferBackToMainWallet] = useState("");
    const transferOption = Object.keys(TransferBackMainWallet).map((transferBackToMainWallet, index) => {
        return { label: t(transferBackToMainWallet.substr(transferBackToMainWallet.indexOf("_") + 1)), value: TransferBackMainWallet[transferBackToMainWallet] }
    });
    const _userData = useSelector((state) => state.authState.userData);
    const isCompany = _userData.userRoleId === Role._COMPANY;
    const isAllowToEditHp = isCompany || checkIfPermissionExist(AccessRight._EDIT_MEMBER_PHONE_NUMBER_PERMISSION, true);

    /// <summary>
    /// Author : -
    /// </summary>
    const submitForm = async (data, e) => {
        if (data.password != data.confirmPassword) {
            _dispatch(showMessage(false, t("CONFIRM_PASSWORD_AND_PASSWORD_IS_NOT_TALLY")));
            return;
        }

        //if (data.internalTransferStatus === true && internalTransferLimit < 0) {
        //    _dispatch(showMessage(false, "INTERNAL_TRANSFER_LIMIT_NOT_LESS_THAN_0"));
        //    return;
        //}

        let params = {
            "username": data.username,
            "password": data.password,
            "pinNumber": data.memberPin,
            "shareholderPin": data.shareholderPin,
            "userFullName": data.fullName,
            "remarks": data.remarks,
            "phoneNumber": data.phone,
            "email": data.email,
            "paymentModeId": data.paymentType,
            "rollOverThreshold": data.rollOverThreshold,
            "status": data.status,
            "birthDate": data.birthDate,
            "vipId": data.vip,
            "riskLevel": changeRiskLvl ? data.riskLevel : memberData["riskLevel"],
            "internalTransferStatus": data.internalTransferStatus,
            "internalTransferLimit": data.internalTransferLimit,
            "betLimitCode": data.betLimitCode,
            "creditLimit": data.creditLimit,
            "transferBackMainWallet": typeof (data.transferBackMainWallet) !== "undefined" ? data.transferBackMainWallet : 0,
            "isTestAccount": data.isTestAccount,
            "isBlockWithdraw": data.blockWithdraw
        };

        if (!stringIsNullOrEmpty(memberId)) {
            params["id"] = memberId;
        }
        else if (!stringIsNullOrEmpty(referralCode)) {
            params['referralCode'] = referralCode;
        }

        if (!stringIsNullOrEmpty(parentMemberId)) {
            params['referralMember'] = parentMemberId;
        }
        //let tempIsPhoneVerified = true;
        //if (data.phone !== phone){
        //    params['isPhoneVerify'] = false;
        //    tempIsPhoneVerified = false;
        //}
        _dispatch(setSaveAdminLog("EDIT_MEMBER_DETAIL", _history.location.pathname, params));
        let formBody = createFormBody(params);
        _dispatch(setBusy());

        fetch(ApiUrl._API_CREATE_OR_UPDATE_MEMBER, {
            method: ApiKey._API_POST,
            headers: {
                'Content-Type': ApiKey._API_FORM_URLENCODED,
                "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY
            },
            body: formBody
        }).then((response) => response.json()).then((responseJson) => {

            _dispatch(setIdle());

            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                //setIsPhoneVerified(tempIsPhoneVerified);  
                setMemberId(responseJson[ApiKey._API_DATA_KEY]["id"]);
                setIsTestAccountOld(data.isTestAccount);
                if (phone !== data.phone) {
                    setPhoneNumberUpdatedBy();
                }
                if (data.status == MemberStatus._CLOSED) {
                    init();
                }
            }

            _dispatch(showMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
        });
    }

    /// <summary>
    /// Author : -
    /// </summary>
    useEffect(() => {
        if (_location.state) {
            init();
            getPhoneNumberUpdatedBy();
        }
    }, []);

    /// <summary>
    /// Author : -
    /// </summary>
    async function init() {
        _dispatch(setBusy());

        try {
            var vipList = [];
            var parentReferralCode = '';
            var parentMemberId = _location.state.parentMemberId ?? "";

            var responseJson = await ApiEngine.get(ApiUrl._API_GET_USER_VIP);

            if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }

            responseJson[ApiKey._API_DATA_KEY].forEach(function (vipLevel) {
                vipList.push({ label: vipLevel.name, value: vipLevel.id });
            });

            setVipOption(vipList);

            if (_location.state.id) {
                responseJson = await ApiEngine.get(ApiUrl._API_GET_USER_MEMBER_BY_ID + "?id=" + _location.state.id);

                if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                    throw responseJson[ApiKey._API_MESSAGE_KEY];
                }
                let data = responseJson[ApiKey._API_DATA_KEY];
                setTimeZone(data["userTimeZone"]);
                let tempMemberData = data["memberData"];
                setMemberData(tempMemberData);
                setMemberId(tempMemberData["id"]);
                setUsername(tempMemberData["username"]);
                setRemarks(tempMemberData["remarks"]);
                setPassword(tempMemberData["password"]);
                setConfirmPassword(tempMemberData["password"]);
                setMemberPin(tempMemberData["pinNumber"]);
                setShareholderPin(tempMemberData["shareholderPin"]);
                setFullName(tempMemberData["userFullName"]);
                setPhone(tempMemberData["phoneNumber"]);
                setOldPhoneNumber(tempMemberData["phoneNumber"]);
                setIsPhoneVerified(tempMemberData["isPhoneVerify"]);
                setValue("phone", tempMemberData["phoneNumber"])
                setEmail(tempMemberData["email"]);
                setRollOverThreshold(tempMemberData["rollOverThreshold"]);
                setGender(tempMemberData["gender"] ? Gender._MALE : Gender._FEMALE);
                setInternalTransferStatus(tempMemberData["internalTransferStatus"]);
                setIsTestAccount(tempMemberData["isTestAccount"]);
                setIsTestAccountOld(tempMemberData["isTestAccount"]);
                setSource(tempMemberData["utmSource"]);
                setCampaign(tempMemberData["utmCampaign"]);
                setAddress(tempMemberData["address"]);
                setPostalCode(tempMemberData["postalCode"]);
                setState(tempMemberData["state"]);
                setCity(tempMemberData["city"]);
                setPaymentType(tempMemberData["paymentModeId"]);
                setInternalTransferLimit(tempMemberData["internalTransferLimit"]);
                setBetLimitCode(tempMemberData["betLimitCode"]);
                setCreditLimit(tempMemberData["creditLimit"]);
                setValue("creditLimit", tempMemberData["creditLimit"]);
                setValue("birthDate", tempMemberData["birthDateString"]);
                setValue('vip', tempMemberData["vipId"]);
                setValue("status", tempMemberData["status"]);
                setValue("transferBackMainWallet", tempMemberData["transferBackMainWallet"]);
                setBlockWithdraw(tempMemberData["isBlockWithdraw"]);
                let tempRiskLvl = tempMemberData["riskLevel"];
                if (tempRiskLvl === -99) { setRiskLevel(0); }
                else { setRiskLevel(tempRiskLvl); }
                setMemberDateJoined(moment(tempMemberData['createdTime']).add(data["userTimeZone"], 'hour').format('YYYY-MM-DD HH:mm:ss'));
                setMemberLastLogin(moment(tempMemberData['lastLogin']).add(data["userTimeZone"], 'hour').format('YYYY-MM-DD HH:mm:ss'));
                setShareholderDate(moment(tempMemberData['shareHolderDate']).format('YYYY-MM-DD HH:mm:ss'));
                setShareholderPercent(tempMemberData['sharePercent']);

                if (tempMemberData['parentReferralCodeModel']) {
                    parentReferralCode = tempMemberData['parentReferralCodeModel']['code'];
                    parentMemberId = tempMemberData['parentReferralCodeModel']['memberId'];
                    setReferralCode(parentReferralCode);
                }
            }

            setParentMemberId(parentMemberId);

            if (!stringIsNullOrEmpty(parentMemberId)) {
                responseJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_REFERRAL_CODES + "?ShowDetails=false&MemberId=" + parentMemberId);

                if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                    throw responseJson[ApiKey._API_MESSAGE_KEY];
                }

                if (stringIsNullOrEmpty(parentReferralCode) && responseJson[ApiKey._API_DATA_KEY]['length'] > 0) {
                    let notExpiredCodes = responseJson[ApiKey._API_DATA_KEY].filter(object => {
                        return moment().startOf('day').isBefore(moment(object["expiryDate"]))
                    });

                    if (notExpiredCodes.length > 0) {
                        setReferralCode(notExpiredCodes[0]['code']);
                    }
                }
            }
        }
        catch (errorMessage) {
            _dispatch(showMessage(false, t(errorMessage)));
        }
        finally {
            _dispatch(setIdle());
        }
    }

    const setPhoneNumberUpdatedBy = () => {
        let params = {
            oldPhoneNumber: oldPhoneNumber,
            memberId: _location.state.id
        };

        let formBody = createFormBody(params);
        _dispatch(setBusy());

        fetch(ApiUrl._API_SET_PHONE_NUMBER_UPDATED_BY, {
            method: ApiKey._API_POST,
            headers: {
                "Content-Type": ApiKey._API_FORM_URLENCODED,
                "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
            },
            body: formBody,
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    setPhoneNumberUpdated(true);
                }

            });

        _dispatch(setIdle());
    };

    useEffect(() => {
        getPhoneNumberUpdatedBy();
        setPhoneNumberUpdated(false);
    }, [phoneNumberUpdated]);

    const getPhoneNumberUpdatedBy = async () => {
        _dispatch(setBusy());
        var apiUrl = ApiUrl._API_GET_PHONE_NUMBER_UPDATED_BY;
        apiUrl += "?memberId=" + _location.state.id;
        await fetch(apiUrl, {
            method: ApiKey._API_GET,
            headers: {
                "Content-Type": ApiKey._API_FORM_URLENCODED,
                "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
            },
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    var text = "";
                    for (let data of responseJson[ApiKey._API_DATA_KEY]) {
                        text += data["username"] + ": on " + data["updatedTime"] + " changed from " + data["oldPhoneNumber"] + "\n";
                    }
                    setPhoneNumberUpdatedByText(text);
                }
                else {
                    console.log("fail");
                }
            });
        _dispatch(setIdle());
    };

    /// <summary>
    /// Author -
    /// </summary>
    const onMobileChange = (isValid, newNumber, countryData, fullNumber, ext) => {
        let formattedNumber = fullNumber.split(/[- ]+/).join('').replace("+", "");
        setPhone(formattedNumber);

        setValue("phone", formattedNumber);
        //if (isValid) {
        //    setValue("phone", formattedNumber);
        //}
        //else {
        //    var isEmptyNumber = stringIsNullOrEmpty(formattedNumber);
        //    setValue("phone", isEmptyNumber ? "" : "INVALID_PHONE");
        //}

        trigger("phone");
    }

    const onTestAccountChange = () => {
        if (isTestAccountOld == isTestAccount) {
            setShowConfirmation(true);
        } else {
            setIsTestAccount(!isTestAccount);
        }
    }

    //useEffect(() => {
    //    if (internalTransferStatus === false || internalTransferStatus === 'false') {
    //        setInternalTransferLimit(-999);
    //    }
    //    else {
    //        setInternalTransferLimit(0);
    //    }
    //}, [internalTransferStatus]);

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      let tabName = "DETAIL_TAB";

      switch (tab) {
        case _DETAIL_TAB:
          tabName = "DETAIL_TAB";
          break;
        case _BET_LIMIT:
          tabName = "BET_LIMIT";
          break;
      }

      _dispatch(setSaveAdminLog("TO_" + tabName, _history.location.pathname, { "tab": tab }));
    }
  }

  useEffect(() => {
    getCasinoMasterProduct();
  }, []);

  async function getCasinoMasterProduct() {
    const productList = [];
    var responseJson = await ApiEngine.get(
      `${ApiUrl._API_GET_CASINO_MASTER_PRODUCT}`
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      const casinoProduct = responseJson[ApiKey._API_DATA_KEY];
      var betLimitResponseJson = await ApiEngine.get(
        `${ApiUrl._API_GET_BET_LIMIT_LIST}`
      );

      if (betLimitResponseJson[ApiKey._API_SUCCESS_KEY]) {
        const betLimitList = betLimitResponseJson[ApiKey._API_DATA_KEY];
        for (var i = 0; i < casinoProduct.length; i++) {
          const betLimitJson = (betLimitList.filter(x => x.productId == casinoProduct[i].id)[0]);
          const betList = [];
          if (typeof betLimitJson != 'undefined') {
            const json = JSON.parse(betLimitJson.betData);
            const data = Object.values(json).map((item) => JSON.parse(item));
            betList.push(data);
          }
          productList.push({
            id: casinoProduct[i].id,
            productName: casinoProduct[i].productName,
            betList: betList,
            isMultiple: betList.length > 0 ? betLimitJson.isMultiple: false,
            maxBetLimitOption: betList.length > 0 ? betLimitJson.maxBetLimitOption : 0
          })
        }

        var responseMemberBetLimitJson = await ApiEngine.get(
          `${ApiUrl._API_GET_MEMBER_PRODUCT_BET_LIMIT}?&memberId=${_location.state.id}`
        );

        if (responseMemberBetLimitJson[ApiKey._API_SUCCESS_KEY]) {
          let memberBetLimitJson = responseMemberBetLimitJson[ApiKey._API_DATA_KEY];
          const transformedData = memberBetLimitJson.reduce((acc, item) => {
            const limits = item.betLimit.split(',').reduce((limitAcc, limit) => {
              limitAcc[limit] = true;
              return limitAcc;
            }, {});

            acc[item.productId] = limits;
            return acc;

          }, {});
          setSelectedBetLimit(transformedData);
        }

        setCasinoProduct(productList);
        const maxLength = productList.reduce((max, item) => {
          const betListLength = item.betList[0] ? item.betList[0].length : 0;
          return Math.max(max, betListLength);
        }, 0);
        setMaxBetLimitLength(maxLength);
      }
    }
  }
 
  const handleUpdateMemberBetLimit = async () => {
    let params = {
      "memberId": _location.state.id,
      "betList": JSON.stringify(selectedBetLimit)
    };
    let formBody = createFormBody(params);
    //_dispatch(setBusy());

    fetch(ApiUrl._API_CREATE_OR_UPDATE_MEMBER_BET_LIMIT, {
      method: ApiKey._API_POST,
      headers: {
        'Content-Type': ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY
      },
      body: formBody
    }).then((response) => response.json()).then((responseJson) => {

      _dispatch(setIdle());

      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        
      }

      _dispatch(showMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
    });

  }
  const handleBetLimitSelect = (provider, betData) => {
    setSelectedBetLimit(prev => ({
      ...prev,
      [provider]: {
        ...prev[provider],
        [betData]: !prev[provider]?.[betData]
      }
    }));
  }

  const handleRangeSelect = (provider, betData) => {
    setSelectedBetLimit(prev => ({
      ...prev,
      [provider]: {
        [betData]: !prev[provider]?.[betData]
      }
    }));
  };


    return (
        <div>
            {showConfirmation && (
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText={t("CONFIRM")}
                    cancelBtnText={t("CANCEL")}
                    confirmBtnBsStyle="danger"
                    title={t("CONFIRM_CHANGE_TEST_ACCOUNT")}
                    onConfirm={() => {
                        setShowConfirmation(false);
                        setIsTestAccount(!isTestAccount);
                    }}
                    onCancel={() => {
                        setShowConfirmation(false);
                    }}
                ></SweetAlert>
            )}
            <h1 className="page-header">{memberId ? t("ACCOUNT_PROFILE") + " - " + username : t("ADD_MEMBER_DETAIL")}<button type="button" className="btn btn-inverse" style={{ float: "right" }} onClick={() => { _history.goBack() }}><i className="fas fa-long-arrow-alt-left"></i></button></h1>
            <div className="row">
                <div className="col-xl-12">
                   {memberId && <Nav tabs>
                     {true ? <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === _DETAIL_TAB })}
                        onClick={() => { toggleTab(_DETAIL_TAB); }}>
                        <span className="d-sm-none">{t("ACCOUNT")}</span>
                        <span className="d-sm-block d-none">{t("ACCOUNT")}</span>
                      </NavLink>
                     </NavItem> : <></>}
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === _BET_LIMIT })}
                          onClick={() => { toggleTab(_BET_LIMIT); }}>
                          <span className="d-sm-none">{t("BET_LIMIT")}</span>
                          <span className="d-sm-block d-none">{t("BET_LIMIT")}</span>
                        </NavLink>
                      </NavItem>
                   </Nav>
                   }
                   <TabContent activeTab={activeTab}>
                    <TabPane tabId={_DETAIL_TAB}>
                      <Panel>
                  <PanelBody>
                    {!checkIfPermissionExist(AccessRight._CLOSE_MEMBER_ACCOUNT_PERMISSION, true) && memberData["status"] === MemberStatus._CLOSED ? <>
                      <div>{t("USERNAME") + " " + memberData.username + " " + t("CLOSED")}</div>
                    </> :
                      <>
                        <h1 className="page-header" style={{ display: "flex", justifyContent: "space-between" }}>
                          <div>{memberId && t("EDIT_MEMBER_DETAIL")}</div>
                          <div style={{ display: "flex", fontSize: "15px" }}>
                            <div style={{ marginRight: "25px" }}>{t("DATE_JOINED") + " : " + memberDateJoined + " GMT+" + timeZone + ":00"}</div>
                            <div style={{ marginRight: "25px" }}>{t("PREVIOUS_LOGIN") + " : " + memberLastLogin + " GMT+" + timeZone + ":00"}</div>
                            <div style={{ marginRight: "20px" }}>{t("SHAREHOLDER_DATE") + " : " + shareholderDate}</div>
                            <div style={{ marginRight: "20px" }}>{t("SHAREHOLDER_PERCENT") + " : " + shareholderPercent + " % "}</div>
                          </div>
                        </h1>
                        <hr />
                        <form onSubmit={handleSubmit(submitForm)}>
                          <h3>{t("PERSONAL_INFORMATION")}</h3>
                          <div className="row">
                            <div className="col-lg-3">
                              <div className="form-group">
                                <label><b>{t("USERNAME")} *</b></label>
                                <input type="text"
                                  ref={register({ required: "PLEASE_ENTER_USERNAME" })}
                                  name="username"
                                  className="form-control m-b-5"
                                  placeholder={t("PLEASE_ENTER_USERNAME")}
                                  defaultValue={username}
                                  readOnly={username == "" ? false : true}
                                  autoComplete="new-password"
                                />
                                {errors.username && <div className="invalid-feedback">{t(errors.username.message)}</div>}
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div className="form-group">
                                <label><b>{t("PASSWORD")} *</b></label>
                                <input type="password"
                                  ref={register({
                                    minLength: {
                                      value: 6,
                                      message: 'MINIMUM_6_DIGIT_PASSWORD'
                                    }
                                  })}
                                  name="password"
                                  className="form-control m-b-5"
                                  placeholder={t("PLEASE_ENTER_PASSWORD")}
                                  defaultValue={""}
                                  autoComplete="new-password"
                                />
                                {errors.password && <div className="invalid-feedback">{t(errors.password.message)}</div>}
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div className="form-group">
                                <label><b>{t("CONFIRM_PASSWORD")} *</b></label>
                                <input type="password"
                                  name="confirmPassword"
                                  ref={register({
                                    minLength: {
                                      value: 6,
                                      message: 'MINIMUM_6_DIGIT_PASSWORD'
                                    }
                                  })}
                                  className="form-control m-b-5"
                                  defaultValue={""}
                                  placeholder={t("PLEASE_ENTER_CONFIRM_PASSWORD")} />
                                {errors.confirmPassword && <div className="invalid-feedback">{t(errors.confirmPassword.message)}</div>}
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div className="form-group">
                                <label><b>{t("MEMBER_PIN")} *</b></label>
                                <input type="password"
                                  name="memberPin"
                                  ref={register({
                                    maxLength: {
                                      value: 4,
                                      message: 'MAXIMUM_4_DIGIT_PASSWORD'
                                    }
                                  })}
                                  className="form-control m-b-5"
                                  defaultValue={""}
                                  placeholder={t("PLEASE_ENTER_PIN")} />
                                {errors.memberPin && <div className="invalid-feedback">{t(errors.memberPin.message)}</div>}
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div className="form-group">
                                <label><b>{t("FULLNAME")} *</b></label>
                                <input type="text"
                                  name="fullName"
                                  ref={register({ required: "PLEASE_ENTER_FULL_NAME" })}
                                  className="form-control m-b-5"
                                  defaultValue={fullName}
                                  placeholder={t("PLEASE_ENTER_FULL_NAME")}
                                />
                                {errors.fullName && <div className="invalid-feedback">{t(errors.fullName.message)}</div>}
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div className="form-group">
                                <label><b>{t("MOBILE")} *</b></label>
                                <div>
                                  <input type="text"
                                    readOnly={!isAllowToEditHp}
                                    name="phone"
                                    ref={register({ required: "PLEASE_ENTER_PHONE_" })}
                                    className="form-control m-b-5"
                                    defaultValue={phone}
                                    placeholder={t("60123456789")}
                                  />{isPhoneVerified && <i className="phone-green-tick fa fa-check"></i>}
                                  {(errors.phone && formState.submitCount > 0) && <div className="invalid-feedback">{t(errors.phone.message)}</div>}
                                </div>
                                <div style={{
                                  color: "red",
                                  whiteSpace: "pre-line"
                                }}>
                                  {phoneNumberUpdatedByText}
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div className="form-group">
                                <label><b>{t("EMAIL")}</b></label>
                                <input type="text"
                                  name="email"
                                  ref={register({
                                    pattern: {
                                      value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                      message: "INVALID_EMAIL"
                                    }
                                  })}
                                  className="form-control m-b-5"
                                  defaultValue={email}
                                  placeholder={t("PLEASE_ENTER_EMAIL")} />
                                {errors.email && <div className="invalid-feedback">{t(errors.email.message)}</div>}
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div className="form-group">
                                <label><b>{t("SHAREHOLDER_PIN")} *</b></label>
                                <input type="password"
                                  name="shareholderPin"
                                  ref={register({
                                    minLength: {
                                      value: 4,
                                      message: 'MINIMUM_4_DIGIT_PASSWORD'
                                    },
                                    maxLength: {
                                      value: 4,
                                      message: 'MAXIMUM_4_DIGIT_PASSWORD'
                                    }
                                  })}
                                  className="form-control m-b-5"
                                  defaultValue={""}
                                  placeholder={t("PLEASE_ENTER_PIN")} />
                                {errors.shareholderPin && <div className="invalid-feedback">{t(errors.shareholderPin.message)}</div>}
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div className="form-group">
                                <label><b>{t("REMARKS")}</b></label>
                                <input type="text"
                                  ref={register}
                                  name="remarks"
                                  className="form-control m-b-5"
                                  placeholder={t("PLEASE_ENTER_REMARKS")}
                                  defaultValue={remarks}
                                  autoComplete="new-password"
                                />
                                {errors.username && <div className="invalid-feedback">{t(errors.remarks.message)}</div>}
                              </div>
                            </div>
                          </div>
                          <hr />
                          <h3>{t("ACCOUNT_SETTINGS")}</h3>
                          <div className="row">
                            <div className="col-lg-3">
                              <div className="form-group">
                                <label><b>{t("ACCOUNT_MODE")}</b></label>
                                <select className="form-control"
                                  name="paymentType"
                                  ref={register}
                                  onChange={(e) => setPaymentType(e.target.value)}
                                  value={paymentType}>
                                  <option value={PaymentType._POSTPAID}>{t("POST_PAID")}</option>
                                  <option value={PaymentType._PREPAID}>{t("PRE_PAID")}</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div className="form-group">
                                <label><b>{t("ROLLOVER_THRESHOLD")}</b></label>
                                <input type="number"
                                  name="rollOverThreshold"
                                  ref={register({
                                    required: "PLEASE_ENTER_ROLLOVER_THRESHOLD",
                                    min: {
                                      value: 0,
                                      message: 'PLEASE_ENTER_VALUE_NOT_LESS_THAN_ZERO'
                                    },
                                    max: {
                                      value: 100,
                                      message: 'PLEASE_ENTER_VALUE_NOT_MORE_THAN_100'
                                    }
                                  })}
                                  className="form-control m-b-5"
                                  placeholder={t("PLEASE_ENTER_ROLLOVER_THRESHOLD")}
                                  defaultValue={rollOverThreshold} />
                                {errors.rollOverThreshold && <div className="invalid-feedback">{t(errors.rollOverThreshold.message)}</div>}
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div className="form-group">
                                <label><b>{t("VIP_LEVEL")}</b></label>
                                <Controller
                                  control={control}
                                  name="vip"
                                  render={({ onChange, value }) => (
                                    <Select options={vipOption}
                                      placeholder={(vipOption.filter(option => option.value == value)[0] !== undefined) ? (vipOption.filter(option => option.value == value)[0].label) : ""}
                                      value={vipOption.filter(option => option.value == value)}
                                      onChange={(e) => {
                                        onChange(e.value);
                                      }} />
                                  )}
                                  rules={{ required: "PLEASE_SELECT_VIP_LEVEL" }}
                                />
                                {errors.vip && <div className="invalid-feedback">{t(errors.vip.message)}</div>}
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div className="form-group">
                                <label><b>{t("STATUS")}</b></label><br />
                                <Controller
                                  control={control}
                                  name="status"
                                  render={({ onChange, value }) => (
                                    <Select options={statusOption}
                                      value={statusOption.filter(option => option.value == value)}
                                      onChange={(e) => {
                                        onChange(e.value);
                                      }} />
                                  )}
                                  rules={{ required: "PLEASE_SELECT_STATUS" }}
                                />
                                {errors.status && <div className="invalid-feedback">{t(errors.status.message)}</div>}
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div className="form-group">
                                <label><b>{t("TRANSFER_BACK_MAIN_WALLET")}</b></label><br />
                                <Controller
                                  control={control}
                                  name="transferBackMainWallet"
                                  render={({ onChange, value }) => (
                                    <Select options={transferOption}
                                      value={transferOption.filter(option => option.value == value)}
                                      onChange={(e) => {
                                        onChange(e.value);
                                      }} />
                                  )}
                                />
                                {errors.transferBackMainWallet && <div className="invalid-feedback">{t(errors.transferBackMainWallet.message)}</div>}
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div className="form-group">
                                <label><b>{t("RISK_LEVEL")}</b></label>
                                <div className="risk-lvl-wrapper"><input type="number"
                                  name="riskLevel"
                                  ref={register({
                                    required: "PLEASE_ENTER_RISK_LEVEL",
                                    min: {
                                      value: 0,
                                      message: 'PLEASE_ENTER_VALUE_NOT_LESS_THAN_ZERO'
                                    },
                                    max: {
                                      value: 6,
                                      message: t('PLEASE_ENTER_VALUE_NOT_MORE_THAN', { value: 6 })
                                    }
                                  })}
                                  className="form-control m-b-5"
                                  placeholder={t("PLEASE_ENTER_RISK_LEVEL")}
                                  readOnly={!changeRiskLvl}
                                  defaultValue={riskLevel} /><input className="risk-lvl-checkbox" type="checkbox" defaultChecked={false} onChange={() => setChangeRiskLvl(!changeRiskLvl)} /><div className="tooltip-text">{t("CHECK_TO_CHANGE", { value: t("RISK_LEVEL") })}</div></div>
                                {errors.riskLevel && <div className="invalid-feedback">{t(errors.riskLevel.message)}</div>}
                              </div>
                            </div>
                            {/*<div className="col-lg-3">
                                            <div className="form-group">
                                                <label><b>{t("INTERNAL_TRANSFER_STATUS")}</b></label>
                                                <div className="m-b-5">
                                                    <select className="form-control"
                                                        name="internalTransferStatus"
                                                        ref={register}
                                                        onChange={(e) => setInternalTransferStatus(e.target.value)}
                                                        value={internalTransferStatus}>
                                                        <option value={true}>{t("ACTIVE")}</option>
                                                        <option value={false}>{t("INACTIVE")}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>*/}
                            <div className="col-lg-3">
                              <div className="form-group">
                                <label><b>{t("INTERNAL_TRANSFER_STATUS")}</b></label>
                                <div className="switcher d-block m-b-5">
                                  <input
                                    type="checkbox"
                                    name="internalTransferStatus"
                                    id="internalTransferStatus"
                                    onChange={() => setInternalTransferStatus(!internalTransferStatus)}
                                    checked={internalTransferStatus}
                                    value={true}
                                    ref={register}
                                  />
                                  <label htmlFor="internalTransferStatus"></label>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div className="form-group">
                                <label><b>{t("INTERNAL_TRANSFER_LIMIT")}</b></label>
                                <input type="number"
                                  ref={register()}
                                  name="internalTransferLimit"
                                  className="form-control m-b-5"
                                  value={internalTransferLimit}
                                  placeholder={internalTransferLimit}
                                  //readOnly={internalTransferStatus === false || internalTransferStatus === 'false'}
                                  onChange={(e) => setInternalTransferLimit(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div className="form-group">
                                <label><b>{t("BET_LIMIT_CODE")}</b></label>
                                <input type="text" name="betLimitCode" className="form-control m-b-5"
                                  ref={register}
                                  defaultValue={betLimitCode}
                                  placeholder={t("PLEASE_ENTER_BET_LIMIT_CODE")} />
                                {errors.betLimitCode && <div className="invalid-feedback">{t(errors.betLimitCode.message)}</div>}
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div className="form-group">
                                <label><b>{t("CREDIT_LIMIT")}</b></label>
                                <input type="text" name="creditLimit" className="form-control m-b-5"
                                  ref={register}
                                  defaultValue={creditLimit}
                                  placeholder={t("PLEASE_ENTER_CREDIT_LIMIT")} />
                                {errors.creditLimit && <div className="invalid-feedback">{t(errors.creditLimit.message)}</div>}
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div className="form-group">
                                <label><b>{t("TEST_ACCOUNT")}</b></label>
                                <div className="switcher d-block m-b-5">
                                  <input
                                    type="checkbox"
                                    name="isTestAccount"
                                    id="isTestAccount"
                                    onChange={() => onTestAccountChange()}
                                    checked={isTestAccount}
                                    value={true}
                                    ref={register}
                                  />
                                  <label htmlFor="isTestAccount"></label>
                                </div>
                              </div>
                            </div>
                            {isCompany &&
                              <div className="col-lg-3">
                                <div className="form-group">
                                  <label><b>{t("BLOCK_WITHDRAW")}</b></label>
                                  <div className="switcher d-block m-b-5">
                                    <input
                                      type="checkbox"
                                      name="blockWithdraw"
                                      id="blockWithdraw"
                                      onChange={() => setBlockWithdraw(!blockWithdraw)}
                                      checked={blockWithdraw}
                                      value={true}
                                      ref={register}
                                    />
                                    <label htmlFor="blockWithdraw"></label>
                                  </div>
                                </div>
                              </div>
                            }
                          </div>
                          <hr />
                          <h3>{t("ADDITIONAL_INFORMATION")}</h3>
                          <div className="row">
                            <div className="col-lg-3">
                              <div className="form-group">
                                <label><b>{t("DATE_OF_BIRTH")}</b></label>
                                <Controller
                                  control={control}
                                  name="birthDate"
                                  render={({ onChange, value }) => (
                                    <DateTime
                                      value={value} dateFormat="YYYY-MM-DD"
                                      closeOnSelect={true}
                                      onChange={(e) => {
                                        if (e instanceof moment) {
                                          onChange(e.format("YYYY-MM-DD"));
                                        }
                                        else {
                                          onChange('');
                                        }
                                      }} />
                                  )}
                                  rules={{ required: "PLEASE_ENTER_VALID_DATE" }}
                                />
                                {errors.birthDate && <div className="invalid-feedback">{t(errors.birthDate.message)}</div>}
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div className="form-group">
                                <label><b>{t("GENDER")}</b></label>
                                <div className="m-b-5">
                                  <select className="form-control"
                                    name="gender"
                                    ref={register}
                                    onChange={(e) => setGender(e.target.value)}
                                    value={gender}>
                                    <option value={Gender._MALE}>{t("MALE")}</option>
                                    <option value={Gender._FEMALE}>{t("FEMALE")}</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div className="form-group">
                                <label><b>{t("SOURCE")}</b></label>
                                <input type="text" name="source" className="form-control m-b-5"
                                  ref={register}
                                  defaultValue={source}
                                  placeholder={t("PLEASE_ENTER_SOURCE")} />
                                {errors.source && <div className="invalid-feedback">{t(errors.source.message)}</div>}
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div className="form-group">
                                <label><b>{t("CAMPAIGN")}</b></label>
                                <input type="text" name="campaign" className="form-control m-b-5"
                                  ref={register}
                                  defaultValue={campaign}
                                  placeholder={t("PLEASE_ENTER_CAMPAIGN")} />
                                {errors.campaign && <div className="invalid-feedback">{t(errors.campaign.message)}</div>}
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group">
                                <label><b>{t("ADDRESS")}</b></label>
                                <input type="text" name="address" className="form-control m-b-5"
                                  ref={register}
                                  defaultValue={address}
                                  placeholder={t("PLEASE_ENTER_ADDRESS")} />
                                {errors.address && <div className="invalid-feedback">{t(errors.address.message)}</div>}
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group">
                                <label><b>{t("POSTAL_CODE")}</b></label>
                                <input type="text" name="postalCode" className="form-control m-b-5"
                                  ref={register}
                                  defaultValue={postalCode}
                                  placeholder={t("PLEASE_ENTER_POSTAL_CODE")} />
                                {errors.postalCode && <div className="invalid-feedback">{t(errors.postalCode.message)}</div>}
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group">
                                <label><b>{t("STATE")}</b></label>
                                <input type="text" name="state" className="form-control m-b-5"
                                  ref={register}
                                  defaultValue={state}
                                  placeholder={t("PLEASE_ENTER_STATE")} />
                                {errors.state && <div className="invalid-feedback">{t(errors.state.message)}</div>}
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group">
                                <label><b>{t("CITY")}</b></label>
                                <input type="text" name="city" className="form-control m-b-5"
                                  ref={register}
                                  defaultValue={city}
                                  placeholder={t("PLEASE_ENTER_CITY")} />
                                {errors.city && <div className="invalid-feedback">{t(errors.city.message)}</div>}
                              </div>
                            </div>
                          </div>
                          {
                            !stringIsNullOrEmpty(referralCode) &&
                            <div className="row">
                              <div className="col-lg-6">
                                <div className="form-group">
                                  <label><b>{t("REFERRAL_MEMBER_CODE")}</b></label><br />
                                  <label>{referralCode}</label>
                                </div>
                              </div>
                            </div>
                          }
                          <hr />
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="form-group">
                                <button type="submit" className="btn btn-primary">{t("SUBMIT")}</button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </>}
                  </PanelBody>
                </Panel>
                   </TabPane>
                   {memberId && <TabPane tabId={_BET_LIMIT}>
                     {activeTab === _BET_LIMIT && <div className="row">
                      <div className="col-lg-12">
                        <table className="table table-hover">
                          <thead>
                            <tr className="bg-gray-50">
                              <th className="p-2 text-left">
                                <div className="flex items-center gap-2">
                                  {/*<Checkbox*/}
                                  {/*  checked={Object.values(selectedProviders).every(v => v)}*/}
                                  {/*  onCheckedChange={handleSelectAll}*/}
                                  {/*  id="select-all"*/}
                                  {/*/>*/}
                                  <label htmlFor="select-all" className="text-sm font-medium">
                                    {t("MASTER_PRODUCT")}
                                  </label>
                                </div>
                              </th>
                              {[...Array(maxBetLimitLength)].map((_, i) => (
                                <th key={i} className="p-2 text-left font-medium text-gray-700" />
                              ))}
                            </tr>
                          </thead>
                        <tbody className="casino-product-tbody">
                          {Object.keys(casinoProduct).length > 0 &&
                            casinoProduct.map((data, index) => {
                            return (
                              <tr key={index} className="border-t hover:bg-gray-50">
                                <td className="p-2">
                                  <div className="casino-product-div">
                                    {/*<input*/}
                                    {/*  type="checkbox"*/}
                                    {/*  name="status"*/}
                                    {/*  id="checkbox"*/}
                                    {/*/>*/}
                                    <label htmlFor={`provider-${data.productName}`} >
                                      {data.productName}
                                    </label>
                                  </div>
                                </td>
                                {data.betList.length > 0 &&
                                  data.betList[0].map((bet, index1) => {
                                    return (
                                    <td className="p-2">
                                        <div className="casino-product-div">
                                          {/*{console.log("009999", Object.keys(selectedBetLimit[data.id]).length)}*/}
                                          {data.isMultiple ? 
                                            <input
                                              type="checkbox"
                                              name="status"
                                              id="checkbox"
                                              checked={selectedBetLimit[data.id]?.[bet.code] || false}
                                              onChange={() => {  handleBetLimitSelect(data.id, bet.code) }}
                                            />
                                            :
                                            <input
                                              type="radio"
                                              name="status"
                                              id="radio"
                                              checked={selectedBetLimit[data.id]?.[bet.code] || false}
                                              onChange={() => { handleRangeSelect(data.id, bet.code) }}
                                            />

                                          }
                                        
                                          <label htmlFor={`${data.productName}-bet-${index1}`}>
                                          {t("CASINO_PRODUCT_VALUE", { min: bet.minBet, max: bet.maxBet })}
                                        </label>
                                      </div>
                                    </td>
                                  )
                                  })}
                                {[...Array(maxBetLimitLength - (data.betList.length > 0 ? data.betList[0].length : 0))].map((_, i) => (
                                  <td key={`empty-${i}`} className="p-2" />
                                ))}
                              </tr>
                            )
                          })}
                          </tbody>
                        </table>
                        <button type="button" className="btn btn-inverse" style={{ float: "right" }} onClick={() => { handleUpdateMemberBetLimit() }}>Update</button>
                      </div>
                     </div>}
                   </TabPane>}
                   </TabContent>
                </div>
            </div>
        </div>
    )

}

export default EditMemberDetail;