import React, { useState, useEffect } from "react";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import { useHistory } from "react-router-dom";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import { ApiKey, ApiUrl, LanguageKey } from "../../util/Constant";
import { stringIsNullOrEmpty, createFormBody } from "../../util/Util";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { setBusy, setIdle, showMessage, setSaveAdminLog } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";

/// <summary>
/// Author : -
/// </summary>

const GamePointsRemarkDetail = (props) => {
    const { t } = useTranslation(LanguageKey._PRIMARY);
    let _history = useHistory();
    const _location = useLocation();
    const _dispatch = useDispatch();

    const [description, setDescription] = useState("");
    const [status, setStatus] = useState(true);
    const [remarkId, setRemarkId] = useState("");

    const { register, handleSubmit, errors } = useForm();

    const [submitError, setSubmitError] = useState("");
    const [submitSuccess, setSubmitSuccess] = useState("");

    /// <summary>
    /// Author : -
    /// </summary>
    const submitForm = async (data, e) => {
        let params = {
            description: data.description,
            status: data.status,
        };

        if (!stringIsNullOrEmpty(remarkId)) {
            params["id"] = remarkId;
            _dispatch(setSaveAdminLog("EDIT_BO_GAME_POINT_REMARK", _history.location.pathname, params));
        } else {
            _dispatch(setSaveAdminLog("CREATE_BO_GAME_POINT_REMARK", _history.location.pathname, params));
        }

        let formBody = createFormBody(params);
        _dispatch(setBusy());

        fetch(ApiUrl._API_CREATE_OR_UPDATE_GAME_POINTS_ADJUSTMENT_REMARK, {
            method: ApiKey._API_POST,
            headers: {
                "Content-Type": ApiKey._API_FORM_URLENCODED,
                "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
            },
            body: formBody,
        })
            .then((response) => response.json())
            .then((responseJson) => {
                _dispatch(setIdle());
                _dispatch(
                    showMessage(
                        responseJson[ApiKey._API_SUCCESS_KEY],
                        t(responseJson[ApiKey._API_MESSAGE_KEY])
                    )
                );

                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    setRemarkId(responseJson[ApiKey._API_DATA_KEY]["id"]);
                }
            });
    };

    /// <summary>
    /// Author : -
    /// </summary>
    useEffect(() => {
        if (_location.state) {
            var apiUrl = ApiUrl._API_GET_GAME_POINTS_ADJUSTMENT_REMARK_BY_ID;
            apiUrl += "?id=" + _location.state.id;
            fetch(apiUrl, {
                method: ApiKey._API_GET,
                headers: {
                    "Content-Type": ApiKey._API_FORM_URLENCODED,
                    "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
                },
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                        setRemarkId(responseJson[ApiKey._API_DATA_KEY]["id"]);
                        setDescription(responseJson[ApiKey._API_DATA_KEY]["description"]);
                        setStatus(responseJson[ApiKey._API_DATA_KEY]["status"]);
                    }
                });
        }
    }, []);

    return (
        <div>
            <h1 className="page-header">
                {stringIsNullOrEmpty(remarkId) ? t("ADD_GAME_POINTS_REMARK") : t("EDIT_GAME_POINTS_REMARK")}
                <NavigationButton history={_history} />
            </h1>
            <div className="row">
                <div className="col-xl-12">
                    <Panel>
                        <PanelBody>
                            <form onSubmit={handleSubmit(submitForm)}>
                                <div className="row">
                                    <div className="col-lg-8">
                                        <div className="form-group row m-b-15">
                                            <label className="col-md-4 col-form-label">
                                                <b>{t("GAME_POINTS_REMARK")}</b>
                                            </label>
                                            <div className="col-md-7">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder={t("PLEASE_ENTER_REMARK")}
                                                    name="description"
                                                    ref={register({ required: "PLEASE_ENTER_REMARK" })}
                                                    defaultValue={description}
                                                />
                                                {errors.description && (
                                                    <div className="invalid-feedback">
                                                        {t(errors.description.message)}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-8">
                                        <div className="form-group row m-b-15">
                                            <label className="col-md-4 col-form-label">
                                                <b>{t("STATUS")}</b>
                                            </label>
                                            <div className="col-md-7 col-form-label">
                                                <div className="switcher">
                                                    <input
                                                        type="checkbox"
                                                        name="status"
                                                        id="status"
                                                        onChange={(e) => setStatus(e.target.checked)}
                                                        value={true}
                                                        checked={status}
                                                        ref={register}
                                                    />
                                                    <label htmlFor="status"></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-lg-3">
                                        <div className="form-group">
                                            <button type="submit" className="btn btn-primary">
                                                {t("SUBMIT")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </PanelBody>
                    </Panel>
                </div>
            </div>
        </div>
    );
};

export default GamePointsRemarkDetail;
