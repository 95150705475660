import React, { useState } from "react";
import MemberCreditTable from "../../components/constantComponent/reactTable/MemberCreditTable";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import {
    ApiKey,
    ApiUrl,
    LanguageKey,
    Status,
    DEFAULT_PAGE_SIZE,
} from "../../util/Constant";
import { showMessage, setMemberSearch, setSaveAdminLog } from "../../redux/AppAction";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import ApiEngine from "../../util/ApiEngine";
import {
    stringIsNullOrEmpty,
    numberWithCurrencyFormat,
    isObjectEmpty,
} from "../../util/Util";
import Select from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import { Route, withRouter, useHistory } from 'react-router-dom';

/// <summary>
/// Author : -
/// </summary>

const MemberCreditReport = (props) => {
    const _dispatch = useDispatch();
    const _history = useHistory();
    const { t } = useTranslation(LanguageKey._PRIMARY);
    const _ALL_VALUE = -1;
    const _OVERALL_KEYS = {
        IN: "TOTAL_IN",
        OUT: "TOTAL_OUT",
    };
    const [startDate, setStartDate] = useState(
        moment().startOf("day").format("YYYY-MM-DD HH:mm:ss")
    );
    const [endDate, setEndDate] = useState(
        moment().format("YYYY-MM-DD HH:mm:ss")
    );
    const [apiUrl, setApiUrl] = useState("");
    const [initFinish, setInitFinish] = useState(true);
    const [overallInfo, setOverallInfo] = useState({});
    const params = new URLSearchParams(window.location.search);
    const [totalOpenBalance, setTotalOpenBalance] = useState('');
    const _SELECTED_MEMBER_KEY = "selectedMemberKey";
    const memberIdf = params.get("memberId");
    const startDatef = params.get("startDate");
    const endDatef = params.get("endDate");
    const optionf = params.get("option");
    const [selectedMember, setSelectedMember] = useState();
    const [checkUrl, setCheckUrl] = useState();
    const [labelUsername, setLabelUsername] = useState("Select...");


    /// <summary>
    /// Author : -
    /// </summary>
    useEffect(() => {
        getMemberCreditReport();
        if (!stringIsNullOrEmpty(memberIdf)) {
          getMemberbyId();
          adminLog("url");
        }
       
    }, [initFinish]);

    async function getMemberbyId() {
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_USER_MEMBER_BY_ID + "?id=" + memberIdf)
        console.log("tttttttttttt", responseJson);
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setLabelUsername(responseJson[ApiKey._API_DATA_KEY]['memberData']['username']);
            }
    }

    /// <summary>
    /// Author : -
    /// </summary>
    async function getMemberCreditReport() {
       /* setSelectedMember({ label: usernamef, value:memberIdf});*/
        let fetchUrl, ckUrl;
        if (!stringIsNullOrEmpty(memberIdf)) {
            fetchUrl  =
                ApiUrl._API_GET_MEMBER_CREDIT_REPORT_LOG +
                "?v=" +
                Date.now() +
                "&StartDate=" +
                startDatef +
                "&EndDate=" +
                endDatef + 
                "&Option=" +
                optionf +
                "&MemberId=" +
                memberIdf
            ckUrl = ApiUrl._API_GET_MEMBER_CREDIT_CHECK_BALANCE +
                "?v=" +
                Date.now() +
                "&StartDate=" +
                startDatef +
                "&EndDate=" +
                endDatef +
                "&Option=" +
                optionf +
                "&MemberId=" +
                memberIdf

        } else {
            fetchUrl =
                ApiUrl._API_GET_MEMBER_CREDIT_REPORT_LOG +
                "?v=" +
                Date.now() +
                "&StartDate=" +
                startDate +
                "&EndDate=" +
                endDate
            ckUrl = ApiUrl._API_GET_MEMBER_CREDIT_CHECK_BALANCE +
                "?v=" +
                Date.now() +
                "&StartDate=" +
                startDate +
                "&EndDate=" +
                endDate
        }

        if (!stringIsNullOrEmpty(selectedMember)) {
            fetchUrl += "&MemberId=" + selectedMember.value;
            ckUrl += "&MemberId=" + selectedMember.value;
        }
        setApiUrl(fetchUrl);
        setCheckUrl(ckUrl);
    }

    let _tableColumns = [
        {
            Header: "TARGET_MEMBER",
            accessor: "memberName",
            Footer: () => {
                return (
                    <span>
                        <b>{t("TOTAL")}: </b>
                    </span>
                );
            },
        },
        {
            Header: "AMOUNT",
            accessor: "amount",
            Cell: ({ row }) => {
                return (
                    <>{numberWithCurrencyFormat(parseFloat(row.original.amount), 3)}</>
                );
            },
            Footer: ({ page }) => {
                const creditSum = page.reduce((sum, currentValue) => {
                    return sum + parseFloat(currentValue.original.amount);
                }, 0);
                setTotalOpenBalance(numberWithCurrencyFormat(parseFloat(creditSum), 3));
                return <span><b>{numberWithCurrencyFormat(parseFloat(creditSum), 3)}</b></span>
            }
        },
        {
            Header: "CURRENT_BALANCE",
            accessor: "currentBalance",
            Cell: ({ row }) => {
                return (
                    <>{numberWithCurrencyFormat(parseFloat(row.original.currentBalance), 3)}</>
                );
            },
        },
        {
            Header: "REMARK",
            accessor: "remark",
                },
        {
            Header: "OPERATED_BY",
            accessor: "operatedBy",
        },
        {
            Header: "CREATED_TIME",
            accessor: "createdTime",
            minWidth: 100,
            Cell: ({ row }) => {
                return (
                    <>{moment(row.original.createdTime).format("DD-MM-YYYY HH:mm:ss")}</>
                );
            },
        },
    ];

    /// <summary>
    /// Author : -
    /// </summary>
    const handleEvent = (event, picker) => {
            setStartDate(moment(picker.startDate).format("YYYY-MM-DD HH:mm:ss"));
            setEndDate(moment(picker.endDate).format("YYYY-MM-DD HH:mm:ss"));
    };

    /// <summary>
    /// Author : -
    /// </summary>
    function getOverallInfo(info) {
        setOverallInfo(info);
    }

    /// <summary>
    /// Author : Wong
    /// </summary>
    async function getMemberList(search, loadOptions, { page }) {
        var responseJson = await ApiEngine.get(
            `${ApiUrl._API_GET_USER_MEMBER_LIST}?start=${(page - 1) * DEFAULT_PAGE_SIZE
            }&count=${DEFAULT_PAGE_SIZE}&keyword=${search}`
        );

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            let data = responseJson[ApiKey._API_DATA_KEY]["data"];
            let formattedData = data.map((m) => {
                return { label: m.username, value: m.id };
            });

            return {
                options: formattedData,
                hasMore: formattedData.length == DEFAULT_PAGE_SIZE,
                additional: {
                    page: page + 1,
                },
            };
        }
    }

  async function adminLog(type) {
    let paramss = [];
    if (type === "search") {
      paramss = {
        "startDate": startDate,
        "endDate": endDate
      };

      if (!stringIsNullOrEmpty(selectedMember)) {
        params["selectedMember"] = selectedMember.value;
      }
    } else {
      paramss = {
        "startDate": startDatef,
        "endDate": endDatef,
        "selectedMember": memberIdf
      };
    }
    _dispatch(setSaveAdminLog("VIEW_MEMBER_CREDIT_REPORT_LOG", _history.location.pathname, paramss));
  }
    
    return (
        <div>
            <h1 className="page-header">{t("MEMBER_CREDIT_REPORT_LOG")}</h1>
            <Panel>
                <PanelBody>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="row">
                                <div className="col-lg-4">
                                    <div
                                        className="form-group"
                                        style={{ display: "flex", width: "100%" }}
                                    >
                                        <div
                                            className="input-group"
                                            style={{ width: "100%", flexFlow: "nowrap" }}
                                        >
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                    <i className="fa fa-calendar"></i>
                                                </span>
                                            </div>
                                            <div style={{ width: "80%" }}>
                                                <DateRangePicker
                                                    containerStyles={{ width: "100%" }}
                                                    startDate={startDate}
                                                    endDate={endDate}
                                                    onApply={handleEvent}
                                                    alwaysShowCalendars={true}
                                                    locale={{ format: "YYYY-MM-DD HH:mm:ss" }}
                                                    ranges={{
                                                        Today: [moment(), moment()],
                                                        Yesterday: [
                                                            moment().subtract(1, "days"),
                                                            moment().subtract(1, "days"),
                                                        ],
                                                        "Last 7 Days": [
                                                            moment().subtract(6, "days"),
                                                            moment(),
                                                        ],
                                                        "Last 30 Days": [
                                                            moment().subtract(29, "days"),
                                                            moment(),
                                                        ],
                                                        "This Month": [
                                                            moment().startOf("month"),
                                                            moment().endOf("month"),
                                                        ],
                                                        "Last Month": [
                                                            moment().subtract(1, "month").startOf("month"),
                                                            moment().subtract(1, "month").endOf("month"),
                                                        ],
                                                    }}
                                                >
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={startDate + " - " + endDate}
                                                    />
                                                </DateRangePicker>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div
                                        className="input-group mb-3"
                                        style={{ flexFlow: "nowrap" }}
                                    >
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <i className="fas fa-user"></i>
                                            </span>
                                        </div>
                                        <div
                                            className="input-group-append"
                                            style={{ width: "100%" }}
                                        >
                                            

                                            <AsyncPaginate
                                                styles={{
                                                    container: () => ({
                                                        width: "100%",
                                                    }),
                                                }}
                                                placeholder={labelUsername}
                                                debounceTimeout={250}
                                                isDisabled={!stringIsNullOrEmpty(props.id)}
                                                loadOptions={getMemberList}
                                                additional={{
                                                    page: 1,
                                                }}
                                                value={selectedMember}
                                                onChange={setSelectedMember}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={() => {getMemberCreditReport(); adminLog("search");}}
                                    >
                                        {t("SEARCH")}
                                    </button>
                                </div>
                            </div>
                            <hr />
                            <MemberCreditTable
                                fetchUrl={apiUrl}
                                checkUrl={checkUrl}
                                columns={_tableColumns}
                                getOverallInfo={getOverallInfo}
                                renderFooter={true}
                                renderOpening={true}
                                renderClosing={true}
                            />
                        </div>
                    </div>
                </PanelBody>
            </Panel>
        </div>
    );
};

export default MemberCreditReport;
