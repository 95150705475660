import React, { useEffect } from 'react';
import { useState } from 'react';
import ReactTable from '../../components/constantComponent/reactTable/ReactTable';
import ApiEngine from '../../util/ApiEngine';
import { ApiKey, ApiUrl, LanguageKey } from '../../util/Constant';
import { useTranslation } from "react-i18next";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import moment from 'moment';

const ManageMobileTAC = (props) => {
  const { t, i18n } = useTranslation(LanguageKey._PRIMARY);
  const [TACData, setTACData] = useState([]);
  useEffect(() => {
    init();
  }, []);

  const _columns = [
    {
      id: "phoneNumber",
      Header: t("PHONE_NUMBER"),
      accessor: "phoneNumber",
      Cell: ({ row }) => {
        return (<span>{row.original.phoneNumber}</span>)
      }
    },
    {
      id: "tac",
      Header: t("TAC"),
      accessor: "tac",
      Cell: ({ row }) => {
        return (<span>{row.original.tac}</span>)
      }
    },
    {
      id: "createdTime",
      Header: t("CREATED_TIME"),
      accessor: "createdTime",
      Cell: ({ row }) => {
        return (<span>{moment(row.original.createdTime).format('YYYY-MM-DD HH:mm:ss')}</span>)
      }
    },
    {
      id: "expireAt",
      Header: t("EXPIRED_TIME"),
      accessor: "expireAt",
      Cell: ({ row }) => {
        return (<span>{moment(row.original.expireAt).format('YYYY-MM-DD HH:mm:ss')}</span>)
      }
    },
  ];

  const init = async () => {
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_MOBILE_TAC);
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY];
      setTACData(data);
    }
  }
  return (
    <div id="">
      <h1 className="page-header">{t("MOBILE_TAC")}</h1>
      <Panel>
        <PanelBody>
          <ReactTable
            columns={_columns}
            data={TACData}
            initialPageSize={999}
          />
        </PanelBody>
      </Panel>
    </div>
  )
}

export default ManageMobileTAC;