import React, { useState, useEffect } from "react";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import { useHistory } from "react-router-dom";
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import {
  navigateTo,
  stringIsNullOrEmpty,
  numberWithCurrencyFormat,
} from "../../util/Util";
import {
  ApiKey,
  ApiUrl,
  AccessRight,
  WebUrl,
  LanguageKey,
  DEFAULT_PAGE_SIZE,
  Role,
} from "../../util/Constant";
import { checkIfPermissionExist } from "../../util/PermissionChecker";
import { showMessage, setMemberSearch, setSaveAdminLog } from "../../redux/AppAction";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ApiEngine from "../../util/ApiEngine";
import { AsyncPaginate } from "react-select-async-paginate";
import classNames from "classnames";

/// <summary>
/// Author : -
/// </summary>
const ManageMemberPO = (props) => {
  const { t } = useTranslation(LanguageKey._PRIMARY);
  let _history = useHistory();

  var _username = useSelector(
    (state) => state["authState"]["userData"]["username"]
  );
  var _searchedMember = useSelector((state => state.appState.memberSearch));
  const _dispatch = useDispatch();
  const [memberData, setMemberData] = useState([]);

  const _SELECTED_MEMBER_KEY = "selectedMemberKey";

  const [selectedMember, setSelectedMember] = useState("");
  const [selectedMemberPhone, setSelectedMemberPhone] = useState("");
  const [locationStateObj, setLocationStateObj] = useState();
  const [memberId, setMemberId] = useState("");
  const [nodePathMembers, setNodePathMembers] = useState([]);
  const [labelUsername, setLabelUsername] = useState("All");
  const [labelUserPhone, setLabelUserPhone] = useState("All");
  const [usernameKeyword, setUsernameKeyword] = useState("");
  const [phoneNumKeyword, setPhoneNumKeyword] = useState("");
  const [memberListUrl, setMemberListUrl] = useState(
    `${ApiUrl._API_GET_DOWNLINE_BY_REFERRER}?memberId=${memberId}`
  );
  const [tableData, setTableData] = useState([]);
  const [totalData, setTotalData] = useState({});
  const [formattedDataList, setFormattedDataList] = useState([]);

  let _tableColumns = [
    {
      Header: "USERNAME",
      accessor: "username",
      minWidth: 100,
      Cell: ({ row }) => {
        return (
          <span
            className={classNames({
              "clickable-username":
                nodePathMembers.length > 0
                  ? nodePathMembers[nodePathMembers.length - 1].id !=
                  row.original.id
                  : true,
            })}
            onClick={() => setSelectedMember(row.original.id)}
          >
            {row.original.username}
          </span>
        );
      },
    },
    {
      Header: "FULLNAME",
      accessor: "userFullName",
      minWidth: 100,
    },
    {
      Header: "EMAIL",
      accessor: "email",
      minWidth: 100,
    },
    {
      Header: "WALLET",
      accessor: "primaryCredit",
      Cell: ({ row }) => {
        return (
          <>
            {numberWithCurrencyFormat(
              parseFloat(row.original.primaryCredit),
              3
            )}
          </>
        );
      },
      minWidth: 100,
    },
    {
      Header: "PHONE",
      accessor: "phoneNumber",
      minWidth: 100,
    },
    {
      Header: "STATUS",
      accessor: "status",
      Cell: ({ row }) => {
        return (
          <span>
            {row.original.status == 1 ? (
              <span className="badge badge-secondary badge-green">
                {t("ACTIVE")}
              </span>
            ) : (
              <span className="badge badge-secondary badge-danger">
                {t("SUSPENDED")}
              </span>
            )}
          </span>
        );
      },
      minWidth: 100,
    },
    {
      id: "action",
      Header: "",
      Cell: ({ row }) =>
        checkIfPermissionExist(
          AccessRight._MEMBER_CONTROL_PERMISSION,
          true
        ) && (
          <div className="btn-group m-r-5 m-b-5">
            <UncontrolledButtonDropdown>
              <DropdownToggle caret color="default">
                <i className="fas fa-cog"></i>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => {
                  navigateTo(_history, WebUrl._URL_VIEW_ACCOUNT, {
                    id: row.original.id,
                    username: row.original.username,
                    parentMemberId: row.original.parentMemberId,
                    navigateFromRole: "PO"
                  });
                }}><span className="text-warning">{t("EDIT")}</span></DropdownItem>
                <DropdownItem onClick={() => { navigateTo(_history, WebUrl._URL_VIEW_PRODUCT_ACCOUNT, row.original); }}><span className="text-warning">{t("VIEW_PRODUCT_ACCOUNT")}</span></DropdownItem>
                <DropdownItem onClick={() => syncProductData(row.original)}>
                  <span className="text-primary">{t("SYNC_PRODUCT")}</span>
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    navigateTo(
                      _history,
                      WebUrl._URL_MEMBER_PRORUCT_MAINTENANCE,
                      row.original
                    );
                  }}
                >
                  <span className="text-primary">{t("VIEW_PRODUCT")}</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        ),
      disableSortBy: true,
      disableFilters: true,
      width: 100,
      style: { overflow: "visible" },
    },
  ];

  /// <summary>
  /// Author : Wong
  /// </summary>
  useEffect(() => {
    if (!stringIsNullOrEmpty(_searchedMember)) {
      sessionStorage.setItem(_SELECTED_MEMBER_KEY, _searchedMember);
      setSelectedMember(_searchedMember);
      let temp1 = totalData && totalData['data'] && totalData['data'].length > 0 ? totalData['data'].find(x => x.id == _searchedMember) : {};
      let tempLabelUsername = totalData && totalData['data'] && totalData['data'].length > 0 && typeof (temp1) !== "undefined" ? temp1.username : "All";
      setLabelUsername(tempLabelUsername);
    }
  }, [totalData]);

  async function getDownlineBySelectedReferrer() {
    if (sessionStorage.getItem(_SELECTED_MEMBER_KEY)) {
      sessionStorage.removeItem(_SELECTED_MEMBER_KEY);
    }
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_USER_MEMBER_BY_USERNAME + "?username=" + usernameKeyword);
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY];
      setTotalData(data);
      setTableData(data["data"]);
      sessionStorage.setItem(_SELECTED_MEMBER_KEY, data["data"][0]["id"]);
      setLabelUsername(data["data"][0]["username"]);
      setSelectedMember(data["data"][0]["id"]);
    }
    _dispatch(setSaveAdminLog("VIEW_MEMBER_PO", _history.location.pathname, { "username": usernameKeyword, "phoneNumber": phoneNumKeyword }));
  }

  /// <summary>
  /// Author : -
  /// </summary>
  async function syncProductData(data) {
    var responseJson = await ApiEngine.post(
      `${ApiUrl._API_SYNC_MEMBER_TO_OWNER_PRODUCT_DATA}?id=${data["id"]}`
    );
    _dispatch(
      showMessage(
        responseJson[ApiKey._API_SUCCESS_KEY],
        t(responseJson[ApiKey._API_MESSAGE_KEY])
      )
    );
  }

  /// <summary>
  /// Author : Wong
  /// </summary>
  async function getMemberList(search, loadOptions, { page }) {
    var responseJson = await ApiEngine.get(
      `${ApiUrl._API_GET_USER_MEMBER_LIST}?&start=${(page - 1) * DEFAULT_PAGE_SIZE
      }&count=${DEFAULT_PAGE_SIZE}&keyword=${search}`
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      console.log(responseJson[ApiKey._API_DATA_KEY]);
      let data = responseJson[ApiKey._API_DATA_KEY]["data"];
      setMemberData(data);
      let formattedData = data.map((m) => {
        return { label: m.username, value: m.id };
      });

      return {
        options: formattedData,
        hasMore: formattedData.length == DEFAULT_PAGE_SIZE,
        additional: {
          page: page + 1,
        },
      };
    }
  }

  /// <summary>
  /// Author : Wong
  /// </summary>
  async function getMemberPhoneList(search, loadOptions, { page }) {
    var responseJson = await ApiEngine.get(
      `${ApiUrl._API_GET_USER_MEMBER_LIST}?&start=${(page - 1) * DEFAULT_PAGE_SIZE
      }&count=${DEFAULT_PAGE_SIZE}&keyword=${search}`
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      console.log(responseJson[ApiKey._API_DATA_KEY]);
      let data = responseJson[ApiKey._API_DATA_KEY]["data"];
      let formattedData = data.map((m) => {
        return { label: m.phoneNumber, value: m.id };
      });

      return {
        options: formattedData,
        hasMore: formattedData.length == DEFAULT_PAGE_SIZE,
        additional: {
          page: page + 1,
        },
      };
    }
  }

  return (
    <div>
      <h1 className="page-header">
        {t("MANAGE_ACCOUNT")}
        {checkIfPermissionExist(
          AccessRight._MEMBER_CONTROL_PERMISSION,
          true
        ) && (
            <NavigationButton
              history={_history}
              url={WebUrl._URL_VIEW_ACCOUNT}
              state={locationStateObj}
              buttonText={t("ADD_NEW_ACCOUNT")}
            />
          )}
      </h1>
      <Panel>
        <div className="row">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-3" style={{ margin: "15px 0 0 15px" }}>
                <div className="form-group">
                  <label>
                    <b>{t("USERNAME")}</b>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    onChange={(e) => {
                      setUsernameKeyword(e.target.value);
                    }}
                  />
                  <div>{t("KEY_KEYWORD_TO_CONTINUE")}</div>
                </div>
              </div>
              <div className="col-lg-3" style={{ margin: "15px 0 0 15px" }}>
                <div className="form-group">
                  <label>
                    <b>{t("PHONE")}</b>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    onChange={(e) => {
                      setPhoneNumKeyword(e.target.value);
                    }}
                  />
                </div>
              </div>
              {<div className="col-lg-2">
                <button className="btn btn-primary" style={{ marginLeft: "5px", marginTop: "40px", width: "100px" }} onClick={() => getDownlineBySelectedReferrer()}>{t("SEARCH")}</button>
              </div>}
            </div>
            <PanelBody>
              <ReactTable
                data={tableData}
                columns={_tableColumns}
                filterable
              />
            </PanelBody>
          </div>
        </div>
      </Panel>
    </div>
  );
};

export default ManageMemberPO;