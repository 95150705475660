import React, { useState, useEffect } from "react";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import {
  ApiKey,
  ApiUrl,
  DisplayMode,
  LanguageKey,
  ContentTypeString,
  AllVipOption,
  _PAYMENT_METHOD,
} from "../../util/Constant";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { stringIsNullOrEmpty, createFormBody } from "../../util/Util";
import { setBusy, setIdle, showMessage, setSaveAdminLog } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import ManageConfigurationLanguage from "./ManageConfigurationLanguage";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import { fetch } from "whatwg-fetch";
import DepositTutorialContentLangauge from "./DepositTutorialContentLanguage.js";
import { setDate } from "date-fns";
import { param } from "jquery";

/// <summary>
/// Author : -
/// </summary>

const DepositTutorialContentDetail = (props) => {
  const _DETAIL_TAB = "1";
  const _LANGUAGE_TAB = "2";
  const { t } = useTranslation(LanguageKey._PRIMARY);
  let _history = useHistory();
  const _location = useLocation();
  const _dispatch = useDispatch();
  const { register, unregister, handleSubmit, errors, setValue } = useForm();
  const [depositTutorialId, setDepositTutorialId] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [paymentMethodLabel, setPaymentMethodLabel] = useState("");
  const [status, setStatus] = useState(true);
  const [apiData, setApiData] = useState([]);
  const [noOfRows, setNoOfRows] = useState(1);
  const [contentList, setContentList] = useState([]);
  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    init();
  }, []);

  const [activeTab, setActiveTab] = useState(_DETAIL_TAB);

  useEffect(() => {
    init();
  }, [_location.state != undefined]);
  
  /// <summary>
  /// Author : -
  /// </summary>
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  /// <summary>
  /// Author : -
  /// </summary>
  async function init() {
    if (_location.state) {
      let stateContentId = _location.state.id;
      var apiUrl = ApiUrl._API_GET_DEPOSIT_TUTORIAL_CONTENT_WITH_ID;
      apiUrl += "?id=" + stateContentId;
      fetch(apiUrl, {
        method: ApiKey._API_GET,
        headers: {
          "Content-Type": ApiKey._API_FORM_URLENCODED,
          "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            if (_location.state.id) {
              var data = responseJson[ApiKey._API_DATA_KEY][0];
              setDepositTutorialId(data["id"]);
              setStatus(data["status"]);
              setPaymentMethod(data["paymentMethod"]);
              if (data["paymentMethod"] == 1) {
                setPaymentMethodLabel("INSTANT_PAYMENT");
              }
              else if (data["paymentMethod"] == 2) {
                setPaymentMethodLabel("DUITNOW");
              }
              else {
                setPaymentMethodLabel("NORMAL_DEPOSIT");
              }

              var content = JSON.parse(data["content"]);
              var contentLength = Object.keys(content).length;
              setNoOfRows(contentLength);


                var newData = [];
                for (var i = 0; i < contentLength; i++) {
                    newData[i] = {
                      name: "STEP " + (i + 1),
                      key: content["parameter_" + (i + 1)],
                      value: i + 1,
                    };

                }
                setApiData([]);
                setApiData(newData);

              //if (responseJson[ApiKey._API_SUCCESS_KEY]) {
             
              //}
            }
          }
        });
    }
    //else {
    //  var newData = [];
    //  for (var i = 0; i < META_COUNT; i++) {
    //    newData[i] = {
    //      name: "Parameter " + (i + 1),
    //      key: "",
    //      value: "",
    //    };
    //  }
    //  setApiData([]);
    //  setApiData(newData);

    //}
  }
  /// <summary>
  /// Author : -
  /// </summary>
  const submitForm = async (data, e) => {

    let params = {
      paymentMethod: paymentMethod,
      status: data.status,
    };

    var parameter = [];
    for (var i = 1; i <= noOfRows; i++)
    {
      if (!stringIsNullOrEmpty(data["Parameter_" + i])) {
        parameter.push({
          value: data["Parameter_" + i]
        });
}
    }
    for (var x = 0; x < parameter.length; x++) {
      params["parameter_" + (x + 1)] = parameter[x].value;
    }

    _dispatch(setBusy());

    if (!stringIsNullOrEmpty(depositTutorialId)) {
      params["id"] = depositTutorialId;
      _dispatch(setSaveAdminLog("EDIT_BO_DEPOSIT_TUTORIAL_CONTENT", _history.location.pathname, params));
    } else {
      _dispatch(setSaveAdminLog("CREATE_BO_DEPOSIT_TUTORIAL_CONTENT", _history.location.pathname, params));
    }
    let formBody = createFormBody(params);

    fetch(ApiUrl._API_CREATE_OR_UPDATE_DEPOSIT_TUTORIAL_CONTENT, {
      method: ApiKey._API_POST,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
      body: formBody,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        _dispatch(setIdle());
        _dispatch(
          showMessage(
            responseJson[ApiKey._API_SUCCESS_KEY],
            t(responseJson[ApiKey._API_MESSAGE_KEY])
          )
        );

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          setDepositTutorialId(responseJson[ApiKey._API_DATA_KEY]["id"]);
          init();
          _history.replace({
            pathname: _location.pathname,
            state: {
              id: responseJson[ApiKey._API_DATA_KEY]["id"],
            },
          });
        }
      });
    
  };


  async function addStep() {
    let list = apiData;

    let countRow = noOfRows + 1;
    var newData = [];


      let data1 = {
        name: "STEP " + (countRow),
            key: "",
            value: countRow,
    };
    list.push(data1);
    setNoOfRows(countRow);
    setApiData(list);
  }

  async function removeStep(index) {
      let list = apiData;
      let countRow = noOfRows - 1;
      let inx = index + 1;
      var newData = [];
      newData = list.filter((x) => x.value !== inx);

      var parameter = [];
      var params = [];
      for (var i = 0; i < newData.length; i++) {
        parameter.push({
          value: newData[i]
        });
      }
      
      for (var x = 0; x < parameter.length; x++) {
        params[x] = {
          name: "STEP " + (x + 1),
          key: parameter[x].value.key,
          value: x + 1,
        };
       
    }
    for (var i = 1; i < params.length; i++) {
      var x = document.getElementById("inputText_" + (i + 1));
      x.value = parameter[i].value.key;
    }
      
      setNoOfRows(countRow);
      setApiData(params);
      
  }



  return (
    <div>
      <h1 className="page-header">
        {depositTutorialId ? t("EDIT_DEPOSIT_TUTORIAL_CONTENT") : t("ADD_DEPOSIT_TUTORIAL_CONTENT")}
        <NavigationButton history={_history} />
      </h1>
      {depositTutorialId && (
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === _DETAIL_TAB })}
              onClick={() => {
                toggle(_DETAIL_TAB);
              }}
            >
              {t("DETAIL")}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === _LANGUAGE_TAB })}
              onClick={() => {
                toggle(_LANGUAGE_TAB);
              }}
            >
              {t("LANGUAGE")}
            </NavLink>
          </NavItem>
        </Nav>
      )}
      <TabContent activeTab={activeTab}>
        <TabPane tabId={_DETAIL_TAB}>
          <div className="row">
            <div className="col-xl-12">
              <Panel>
                <PanelBody>
                  <form onSubmit={handleSubmit(submitForm)}>
                    <div className="row">
                      <div className="col-lg-8">
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>{t("PAYMENT_METHOD")}</b>
                          </label>
                          <div className="col-md-7">
                            <Select
                              styles={{
                                container: () => ({
                                  width: "100%"
                                })
                              }}
                              value={paymentMethod}
                              options={_PAYMENT_METHOD}
                              onChange={(e) => {
                                setPaymentMethod(e.value)
                                setPaymentMethodLabel(e.label)
                              }}
                              placeholder={t(paymentMethodLabel)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>{t("STATUS")}</b>
                          </label>
                          <div className="col-md-7">
                            <div className="switcher">
                              <input
                                type="checkbox"
                                name="status"
                                id="status"
                                onChange={(e) => setStatus(e.target.checked)}
                                value={true}
                                checked={status}
                                ref={register}
                              />
                              <label htmlFor="status"></label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <div><h3>{t("CONTENT")}</h3></div>
                      <div><button type="button" class="btn btn-primary me-3" onClick={() => !stringIsNullOrEmpty(depositTutorialId) ? addStep() : setNoOfRows(noOfRows + 1)}>Add</button></div>
                    </div>
                    
                    <div className="row">
                      <div className="col-lg-12">
                        <table className="table table-hover">
                          <thead>
                            <tr>
                              <th>{t("STEPS")}</th>
                              <th>{t("CONTENT")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {/*{Object.keys(apiData).map((key, value) => {*/}
                            {/*    return (*/}
                                  
                            {/*      <tr key={key}>*/}
                            {/*        <td>{apiData[key].name}</td>*/}
                            {/*        <td>*/}
                            {/*          <input*/}
                            {/*            type="text"*/}
                            {/*            defaultValue={apiData[key]["key"] }*/}
                            {/*            className="form-control m-b-5"*/}
                            {/*            name={"Parameter_" + (value + 1) }*/}
                            {/*            ref={register}*/}
                            {/*          />*/}
                            {/*        </td>*/}
                            {/*      </tr>*/}
                            {/*    );*/}
                            {/*  })*/}
                            {/*}*/}
                            {depositTutorialId ? (
                              apiData.map((data, key) => {
                                return (
                                  <tr key={key}>
                                    <td>{data.name}</td>
                                    <td>
                                      <input
                                        type="text"
                                        id={"inputText_" + (key + 1)}
                                        defaultValue={data.key}
                                        className="form-control m-b-5"
                                        name={"Parameter_" + (key + 1)}
                                        ref={register}
                                      />
                                    </td>
                                    <td><button type="button" class="btn btn-danger me-3" onClick={() => removeStep(key)}>-</button></td>
                                  </tr>
                                )
                              })
                            ) : (
                              [...Array(noOfRows)].map((elementInArray, index) => {
                                  return (
                                    <tr>
                                    <th scope="row">{t("STEPS ")} {index + 1}</th>
                                      <td>
                                          <input
                                            type="text"
                                            className="form-control m-b-5"
                                            name={"Parameter_" + (index + 1)}
                                            ref={register}
                                          />
                                    </td>
                                  </tr>
                                ) 
                              })
                            )}
                            
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-lg-3">
                        <div className="form-group">
                          <button type="submit" className="btn btn-primary">
                            {t("SUBMIT")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </PanelBody>
              </Panel>
            </div>
          </div>
        </TabPane>
        <TabPane tabId={_LANGUAGE_TAB}>
          {depositTutorialId && (
            <div className="row">
              <div className="col-lg-12">
                <DepositTutorialContentLangauge
                  id={depositTutorialId}
                  method={paymentMethod}
                  type={ContentTypeString._DEPOSIT_TUTORIAL_CONTENT}
                />
              </div>
            </div>
          )}
        </TabPane>
      </TabContent>
    </div>
  );
};

export default DepositTutorialContentDetail;
