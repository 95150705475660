import React, { useState, useEffect } from "react";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import "../../assets/css/custom.css";
import { useHistory } from "react-router-dom";
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { navigateTo, numberWithCommas, stringIsNullOrEmpty } from "../../util/Util";
import {
  ApiUrl,
  ApiKey,
  WebUrl,
  AccessRight,
  LanguageKey,
  AllVipOption
} from "../../util/Constant";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import { checkIfPermissionExist } from "../../util/PermissionChecker";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";
import { useDispatch } from "react-redux";
import { showMessage } from "../../redux/AppAction";

/// <summary>
/// Author : -
/// </summary>

const ManageNotiScanBankAccount = (props) => {
  const { t } = useTranslation(LanguageKey._PRIMARY);
  let _history = useHistory();
  const _dispatch = useDispatch();
  const [bonusDepositData, setBonusDepositData] = useState([]);
  let urlEncodedStr = encodeURIComponent(btoa(AccessRight._NOTISCAN_BANKACCOUNT_PERMISSION));

  let _tableColumns = [
    {
      Header: "BankAccountNumber",
      accessor: "bankAccountNumber",
    },
    {
      Header: "BankAccountHolderName",
      accessor: "bankAccountHolderName",
    },
    {
      Header: "ApiKey",
      accessor: "apiKey",
    },
    {
      Header: "ApiSecret",
      accessor: "apiSecret",
    },
    {
      Header: "XCustomAccountId",
      accessor: "xCustomAccountId",
    },
    {
      Header: "STATUS",
      accessor: "status",
      Cell: ({ row }) => (
        <div className="btn-group m-r-5 m-b-5">
          {row.original.status == 1 ? (
            <span className="badge badge-secondary badge-green">
              {t("ACTIVE")}
            </span>
          ) : (
            <span className="badge badge-secondary badge-danger">
              {t("SUSPENDED")}
            </span>
          )}
        </div>
      ),
      disableSortBy: true,
      style: { overflow: "visible" },
    },
    {
      id: "action",
      Header: "",
      Cell: ({ row }) =>
        (
          <div className="btn-group m-r-5 m-b-5">
            <UncontrolledButtonDropdown>
              <DropdownToggle caret color="default">
                <i className="fas fa-cog"></i>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={() => {
                    navigateTo(
                      _history,
                      WebUrl._URL_CREATE_OR_UPDATE_NOTISCAN_BANK_ACCOUNT,
                      row.original
                    );
                  }}
                >
                  <span className="text-warning">{t("EDIT")}</span>
                </DropdownItem>
                <DropdownItem onClick={() => updateSettingStatus(row.original)}>
                  {row.original.status == 1 ? t("DISABLE") : t("ENABLE")}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        ),
      disableSortBy: true,
      disableFilters: true,
      width: 100,
      style: { overflow: "visible" },
    },
  ];

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    await fetch(ApiUrl._API_GET_ALL_NOTISCAN_BANKACCOUNT, {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
        "Permission": urlEncodedStr
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          setBonusDepositData(responseJson[ApiKey._API_DATA_KEY]);
        }
      });
      
  }

    function updateSettingStatus(data) {
        fetch(
            ApiUrl._API_UPDATE_NOTISCAN_BANK_ACCOUNT_STATUS +
            "?instantPaySettingId=" +
            data["id"] +
            "&enable=" +
            !data["status"],
            {
                method: ApiKey._API_POST,
                headers: {
                    "Content-Type": ApiKey._API_FORM_URLENCODED,
                    "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
                },
            }
        )
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    _dispatch(showMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
                    GetInstantPaySetting();
                }
                else {
                    _dispatch(showMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
                    GetInstantPaySetting();
                }
            });
    }

    function GetInstantPaySetting() {
        fetch(ApiUrl._API_GET_ALL_NOTISCAN_BANKACCOUNT, {
            method: ApiKey._API_GET,
            headers: {
                "Content-Type": ApiKey._API_FORM_URLENCODED,
                "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
                "Permission": urlEncodedStr
            },
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    setBonusDepositData(responseJson[ApiKey._API_DATA_KEY]);
                }
            });
    }

  return (
    <div>
      <h1 className="page-header">
        {t("NOTISCAN_BANK_ACCOUNT")}
        {(
          <NavigationButton
            history={_history}
            url={WebUrl._URL_CREATE_OR_UPDATE_NOTISCAN_BANK_ACCOUNT}
            buttonText={t("ADD_NOTISCAN_BANK_ACCOUNT")}
          />
        )}
      </h1>
      <Panel>
        <PanelBody>
          <ReactTable data={bonusDepositData} columns={_tableColumns} />
        </PanelBody>
      </Panel>
    </div>
  );
};

export default ManageNotiScanBankAccount;
