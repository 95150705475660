import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment from "moment";
import {
  ApiKey,
  ApiUrl,
  LanguageKey
} from "../../util/Constant";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import DateRangePicker from "react-bootstrap-daterangepicker";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable.js";
import { useDispatch } from "react-redux";
import { setBusy, setIdle } from "../../redux/AppAction";
import ExcelJS from 'exceljs';

const DailyNonDepositNewPlayerReport = () => {
  const _dispatch = useDispatch();
  const { t } = useTranslation(LanguageKey._PRIMARY);
  const [selectedDate, setSelectedDate] = useState(moment().format("YYYY-MM-DD"));
  const [apiUrl, setApiUrl] = useState("");
  const [isExport, setIsExport] = useState(false);
  const [exportDatas, setExportDatas] = useState([]);

  const _headers = [
    "Member Username",
    "Member Fullname",
    "Member Phone Number",
    "Upline Username",
    "Upline Fullname",
    "Upline Phone Number"
  ];

  useEffect(() => {
    getReport();
  }, [])

  const getReport = async () => {
    let fetchUrl =
      ApiUrl._API_GET_NON_DEPOSIT_NEW_PLAYERS +
      "?date=" +
      selectedDate
      ;

    setApiUrl(fetchUrl);
  }

  let _tableColumns = [
    {
      Header: "MEMBER_USERNAME",
      accessor: "memberUsername"
    },
    {
      Header: "MEMBER_FULLNAME",
      accessor: "memberFullname",
    },
    {
      Header: "MEMBER_PHONE_NUMBER",
      accessor: "memberPhoneNumber",
    },
    {
      Header: "UPLINE_USERNAME",
      accessor: "uplineUsername",
    },
    {
      Header: "UPLINE_USER_FULLNAME",
      accessor: "uplineUserFullname",
    },
    {
      Header: "UPLINE_PHONE_NUMBER",
      accessor: "uplinePhoneNumber",
    }
  ];

  /// <summary>
  /// Author : -
  /// </summary>
  const handleEvent = (event, picker) => {
    setSelectedDate(moment(picker.startDate).format("YYYY-MM-DD"));
  };

  const exportExcel = async () => {
    _dispatch(setBusy())

    fetch(ApiUrl._API_GET_NON_DEPOSIT_NEW_PLAYERS_FOR_EXCEL + "?date=" + selectedDate, {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          let data = responseJson[ApiKey._API_DATA_KEY];
          setExportDatas(data);
          setIsExport(true);
        }
      })
    _dispatch(setIdle())
  }

  const downloadExcel = async () => {
    let data = [...exportDatas];

    // Create a new workbook
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');

    // Define cell styles for headers
    const headerStyle = {
      font: { bold: true, size: 12 },
      alignment: { horizontal: 'center', vertical: 'middle' }
    };

    // Add header row with styling
    const headerRow = worksheet.addRow(_headers);
    headerRow.eachCell((cell) => {
      cell.font = headerStyle.font;
      cell.alignment = headerStyle.alignment;
    });

    const commonWidth = 28;

    // Loop through the columns and set their widths
    for (let columnIndex = 1; columnIndex <= _headers.length + 1; columnIndex++) {
      worksheet.getColumn(columnIndex).width = commonWidth;
    }

    data.forEach((rowData, index) => {
      Object.values(rowData)
      worksheet.addRow(Object.values(rowData));
    })

    // Generate the Excel file
    const buffer = await workbook.xlsx.writeBuffer();

    // Create a Blob and download the file
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = "NonDepositNewPlayerReport (" +
      moment().format("YYYY-MM-DD HH:mm:ss") +
      ").xlsx";
    a.click();
  }

  return (
    <div>
      <h1 className="page-header">
        {t("DAILY_NON_DEPOSIT_NEW_PLAYER_REPORT")}
      </h1>
      <Panel
      >
        <PanelBody>
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-3">
                  <div
                    className="form-group"
                    style={{
                      display: "flex",
                      width: "100%",
                      flexFlow: "nowrap",
                    }}
                  >
                    <div
                      className="input-group"
                      style={{ width: "100%", flexFlow: "nowrap" }}
                    >
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="fa fa-calendar"></i>
                        </span>
                      </div>
                      <div style={{ width: "80%" }}>
                        <DateRangePicker
                          containerStyles={{ width: "100%" }}
                          singleDatePicker={true}
                          onApply={handleEvent}
                          alwaysShowCalendars={true}
                          locale={{ format: "YYYY-MM-DD" }}
                        >
                          <input
                            type="text"
                            className="form-control"
                            value={selectedDate}
                          />
                        </DateRangePicker>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                      getReport();
                    }}
                  >
                    <i className="fas fa-search"></i> {t("SEARCH")}
                  </button>
                </div>
                <div className="col-lg-7" style={{ display: "flex", alignItems: "start", justifyContent: "end" }}>
                  {isExport ?
                    <button className="btn btn-primary btn-lg" onClick={downloadExcel} >
                      <i class="fa fa-download" aria-hidden="true"></i>
                    </button>
                    :
                    <button className="btn btn-primary btn-lg" onClick={() => { exportExcel(); }} >
                      <i className="fas fa-file-csv"></i>
                    </button>
                  }
                </div>
              </div>
              <hr />
            </div>
          </div>
          <ReactTable fetchUrl={apiUrl} columns={_tableColumns} />
        </PanelBody>
      </Panel>
    </div>
  );
};

export default DailyNonDepositNewPlayerReport;