import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import { ApiKey, ApiUrl, LanguageKey } from "../../util/Constant";
import { useTranslation } from "react-i18next";
import moment from "moment";
import ApiEngine from "../../util/ApiEngine.js";
import { stringIsNullOrEmpty, numberWithCurrencyFormat } from "../../util/Util";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { showMessage, setSaveAdminLog } from '../../redux/AppAction';
import { useHistory } from "react-router-dom";

const ShareholderUnclaimReport = () => {
    const { t, i18n } = useTranslation(LanguageKey._PRIMARY);
    var _dispatch = useDispatch();
    let _history = useHistory();
    var _username = useSelector(
        (state) => state["authState"]["userData"]["username"]
    );

    const [processedData, setProcessedData] = useState({});
    const [processedUplineTotal, setProcessedUplineTotal] = useState({});
    const [processedGrandTotal, setProcessedGrandTotal] = useState({});
    const [temp, setTemp] = useState([]);
    const [initPage, setInitPage] = useState(true);
    const [startDate, setStartDate] = useState(moment().add(-1, "days").startOf('day').format("YYYY-MM-DD"));
    const [endDate, setEndDate] = useState(moment().add(-1, "days").endOf('day').format("YYYY-MM-DD"));

    const [currentViewer, setCurrentViewer] = useState("");
    const _SELECTED_MEMBER_KEY = "selectedMemberKey";

    const [selectedMember, setSelectedMember] = useState("");
    const [locationStateObj, setLocationStateObj] = useState();
    const [memberId, setMemberId] = useState("");
    const [nodePathMembers, setNodePathMembers] = useState([]);

    useEffect(() => {
        let nodePath = [];
        let memId = "";
        if (temp.memberNodePath) {
            if (temp.memberNodePath.length > 0) {
                nodePath = temp.memberNodePath;
                memId = nodePath[nodePath.length - 1].id;
            }
        }

        setNodePathMembers(nodePath);
        setMemberId(memId);
        setLocationStateObj({
            parentMemberId: memId,
        });
    }, [temp]);

    const getUnclaimDetails = async (username = null) => {
        let params = {
          "startDate": startDate,
          "endDate": endDate
        };
        if (username !== null) {
          setCurrentViewer(username);
          params["username"] = username;
        }
        _dispatch(setSaveAdminLog("VIEW_SHAREHOLDER_UNCLAIMED_SALES_REPORT", _history.location.pathname, params));
        let apiUrl = ApiUrl._API_GET_UNCLAIMED_SALES_REPORT + "?startDate=" + startDate + "&endDate=" + endDate; //"&username="
        if (!stringIsNullOrEmpty(username)) {
            apiUrl += "&username=" + username;
        }
        var responseJson = await ApiEngine.get(apiUrl);
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            let data = responseJson[ApiKey._API_DATA_KEY];
            performCalculations(data, username);
            responseJson = await ApiEngine.get(ApiUrl._API_GET_DOWNLINE_BY_REFERRER_USING_USERNAME + "?username=" + username);
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                let data = responseJson[ApiKey._API_DATA_KEY];
                setTemp(data);
            }
        }
        setInitPage(false);
    }

    const performCalculations = (data, username) => {
        let dataRows = {};
        let userTotal = {};
        let grandTotal = { netProfit: 0 };
        if (data["downlineData"].length != 0) {
            setCurrentViewer(username);
            if (username === "Company") {
                data["downlineData"].map((downline, i) => {
                    let uplineUser = data["usernameList"][i];
                    if (!dataRows[uplineUser]) {
                        dataRows[uplineUser] = {};
                        userTotal[uplineUser] = { netProfit: 0 };
                    }

                    downline.map((player, index) => {
                        userTotal[uplineUser].netProfit += player.netProfit;
                        grandTotal.netProfit += player.netProfit;
                    })
                });
            }
            else {
                data["downlineData"].map((e, i) => {
                    if (!dataRows[e.username]) {
                        dataRows[e.username] = {};
                        userTotal[e.username] = { netProfit: 0 };
                    }
                    userTotal[e.username].netProfit += e.netProfit;
                    grandTotal.netProfit += e.netProfit;
                });
            }
            setProcessedUplineTotal(userTotal);
            setProcessedData(dataRows);
            setProcessedGrandTotal(grandTotal);
        }
        else {
            if (username !== null) {
                _dispatch(showMessage(false, t("NO_FURTHER_DOWNLINE_DATA_FOUND")));
            }
        }
    }

    /// <summary>
    /// Author : -
    /// </summary>
    const handleEvent = (event, picker) => {
        setStartDate(moment(picker.startDate).format("YYYY-MM-DD"));
        setEndDate(moment(picker.endDate).format("YYYY-MM-DD"));
    };

    return (
        <div>
            <h1 className="page-header">{t("SHAREHOLDER_UNCLAIM_REPORT")}</h1>
            <Panel>
                <div className="row">
                    <div className="col-lg-12">
                        <PanelBody>
                            <div className="row">
                                <div className="col-lg-12">
                                    {currentViewer !== "" &&
                                        <>
                                            <div className="hierarchy-line">
                                                <a
                                                    href="javascript:;"
                                                    onClick={() => {
                                                        setSelectedMember("");
                                                        sessionStorage.removeItem(_SELECTED_MEMBER_KEY);
                                                        getUnclaimDetails();
                                                    }}
                                                >
                                                    {_username}
                                                </a>
                                                {nodePathMembers.map((m, index) => {
                                                    return (
                                                        <>
                                                            {index == 0 && ">"}
                                                            <a
                                                                href="javascript:;"
                                                                onClick={() => getUnclaimDetails(m.username)}
                                                            >
                                                                {m.username}
                                                            </a>
                                                            {index != nodePathMembers.length - 1 && ">"}
                                                        </>
                                                    );
                                                })}
                                            </div>
                                            <hr />
                                        </>
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-4">
                                    <div
                                        className="form-group"
                                        style={{ display: "flex", width: "100%" }}
                                    >
                                        <div className="input-group" style={{ width: "100%" }}>
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                    <i className="fa fa-calendar"></i>
                                                </span>
                                            </div>
                                            <div style={{ width: "80%" }}>
                                                <DateRangePicker
                                                    containerStyles={{ width: "100%" }}
                                                    alwaysShowCalendars={true}
                                                    onApply={handleEvent}
                                                    maxDate={moment().endOf('day')}
                                                    ranges={{
                                                        'Today': [moment().startOf('day'), moment().endOf('day')],
                                                        'Yesterday': [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],
                                                        'Last 7 Days': [moment().subtract(6, 'days').startOf('day'), moment().endOf('day')],
                                                        'Last 30 Days': [moment().subtract(29, 'days').startOf('day'), moment().endOf('day')],
                                                        'This Month': [moment().startOf('month'), moment().endOf('month')],
                                                        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                                                        'This Year': [moment().startOf('year'), moment().endOf('year')]
                                                    }}>
                                                    <input type="text" className="form-control" value={startDate + " - " + endDate} />
                                                </DateRangePicker>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={() => getUnclaimDetails()}
                                    >
                                        {t("SEARCH")}
                                    </button>
                                </div>
                            </div>
                            {Object.keys(processedData).length > 0 && <>
                                <hr />
                                <div className="card-table table-responsive">
                                    <table className="table table-kpi-summary">
                                        <thead>
                                            <tr>
                                                <th style={{ textAlign: "left" }}>{t("USERNAME")}</th>
                                                <th style={{ textAlign: "right" }}>{t("NET_PROFIT")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                Object.keys(processedData).map((_username, i) => {
                                                    if (typeof (processedUplineTotal[_username]) !== "undefined") {
                                                        return (
                                                            <>
                                                                <tr key={i}>
                                                                    <td>{currentViewer !== _username ? <a href="" onClick={(e) => { e.preventDefault(); getUnclaimDetails(_username); }}>{_username}</a> : _username}</td>
                                                                    <td className={processedUplineTotal[_username].netProfit < 0 ? "text-red" : ""} style={{ textAlign: "right" }}>{numberWithCurrencyFormat(parseFloat(processedUplineTotal[_username].netProfit), 3, true)}</td>
                                                                </tr>
                                                            </>
                                                        )
                                                    }
                                                })
                                            }
                                            <tr>
                                                <td>{t("GRAND_TOTAL")}</td>
                                                <td className={processedGrandTotal.netProfit < 0 ? "text-red" : ""} style={{ textAlign: "right" }}>{numberWithCurrencyFormat(parseFloat(processedGrandTotal.netProfit), 3, true)}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </>
                            }
                            {Object.keys(processedData).length == 0 && <>
                                <hr />
                                <div className="card-table table-responsive">
                                    <table className="table table-kpi-summary">
                                        <thead>
                                            <tr>
                                                <th style={{ textAlign: "left" }}>{t("USERNAME")}</th>
                                                <th style={{ textAlign: "right" }}>{t("NET_PROFIT")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td colSpan="2">{initPage ? t("SELECT_DATE_AND_CONTINUE") : t("NO_REPORT_FOR_THE_DATE")}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </>
                            }
                        </PanelBody>
                    </div>
                </div>
            </Panel>
        </div>
        )
}

export default ShareholderUnclaimReport;