
import React, { useState, useEffect } from "react";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import "../../assets/css/custom.css";
import { useHistory, useLocation } from "react-router-dom";
import {
    UncontrolledButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";
import { navigateTo, numberWithCurrencyFormat } from "../../util/Util";
import { useForm, Controller } from "react-hook-form";
import { createFormBody } from "../../util/Util";
import { ApiKey, ApiUrl, WebUrl, LanguageKey } from "../../util/Constant";
import { setBusy, setIdle } from "../../redux/AppAction";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";
import { showMessage } from "../../redux/AppAction";
import ApiEngine from "../../util/ApiEngine";

import DateTime from 'react-datetime';
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
import ExcelJS from 'exceljs';

/// <summary>
/// Author : -
/// </summary>

const ShareholdersExternalProcessSales = (props) => {
    const { t } = useTranslation(LanguageKey._PRIMARY);
    const _dispatch = useDispatch();
    const _location = useLocation();
    let _history = useHistory();
    const [productData, setProductData] = useState([]);
    const [categoryData, setCategoryData] = useState({});
    const [categoryTotal, setCategoryTotal] = useState({});
    const [date, setDate] = useState(moment().add(-1, "days").format("DD-MM-YYYY"));
    const [oktoprocess, setOktoprocess] = useState(true);
    const [okcount, setOkcount] = useState(0);
    const [processed, setProcessed] = useState(false);
    const { loginUserId } = useSelector((state) => ({
        loginUserId: state.authState.userData.userId,
    }));
    const { handleSubmit, control } = useForm({ mode: "onBlur" });
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showConfirmationRelease, setShowConfirmationRelease] = useState(false);
    const [initPage, setInitPage] = useState(true);


    const yesterday = moment().subtract(1, 'day');
    const disableFutureDt = current => {
        return current.isBefore(yesterday);
    };
    const [isExport, setIsExport] = useState(false);
    const [exportDatas, setExportDatas] = useState([]);
    const _GAME_CATEGORIES = ["Lottery", "Sports", "Casino", "V.Sports", "RNG", "Slots", "eSports", "Poker", "Connect", "CockFighting", "Togel", "ThaiLotto"];
  
    let _headers = [
        "Name",
        "Turnover",
        "V.Turnover",
        "Win/Loss",
        "ClubFee",
        "Royalty",
        "Commission",
        "Reward",
        "Value In Currency",
        "Points Real Value",
        "ClubFee88",
        "ClubFee60",
        "Balance",
        "Payout",
        "Net Profit"
    ];

    let _tableColumns = [
        {
            Header: "NAME",
            accessor: "productName",
            Cell: ({ row }) => (
                <div className="text-left">
                    <div>{row.original.productName}</div>
                    { row.original.id > 0 && <>
                        <div><small>Royalty: {row.original.royalty}%</small></div>
                        <div><small>Markup: {row.original.markup}%</small></div>
                    </>
                    }

                </div>
            ),
            disableSortBy: true,
        },
        {
            Header: "TURNOVER",
            accessor: "netSales",
            Cell: ({ row }) => (
                <div className="text-center">
                    <div>
                        <input type="text" className="form-control text-right" disabled value={row.original.grossSales} />
                    </div>
                </div>
            ),
            disableSortBy: true,
            style: { overflow: "visible" },
        },
        {
            Header: "PAYOUT",
            accessor: "payout",
            Cell: ({ row }) => (
                <div className="text-center">
                    { row.original.payout == null ?
                        <span className="badge bg-danger">NOT_SET</span>
                        :
                        <input type="text" className="form-control text-right" disabled value={row.original.payout} />
                    }
                </div>
            ),
            disableSortBy: true,
            style: { overflow: "visible" },
        },
        {
            Header: "NET PROFIT",
            accessor: "netProfit",
            Cell: ({ row }) => (
                <div className="text-center">

                { row.original.payout == null ? <span className="badge bg-danger">NOT_SET</span> : <input type="text" className="form-control text-right" disabled value={row.original.netProfit} />}
                </div>
            ),
            disableSortBy: true,
            style: { overflow: "visible" },
        },
        {
            id: "action",
            Header: "",
            Cell: ({ row }) => (
                <div className="btn-group m-r-5 m-b-5">
                    {
                        !processed && <UncontrolledButtonDropdown>
                        <DropdownToggle caret color="default">
                            <i className="fas fa-cog"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem
                                onClick={() => {
                                    navigateTo(
                                        _history,
                                        WebUrl._URL_SHAREHOLDERS_PAYOUT_EDIT,
                                        row.original
                                    );
                                }}
                            >
                                <span className="text-primary">
                                    {t("EDIT_PAYOUT")}
                                </span>
                            </DropdownItem>
                        </DropdownMenu>
                        </UncontrolledButtonDropdown>
                    }
                </div>
            ),
            disableSortBy: true,
            disableFilters: true,
            width: 100,
            style: { overflow: "visible" },
        },
    ];

    const tableStyle = {
        border: "2px solid #000000"
    };

    const greyStyle = {
        border: "none",
        background: "lightgrey"
    };

    const lineRowStyle = {
        borderTop: "2px solid #000000",
        borderBottom: "2px solid #000000",
        fontWeight: "bold"
    };

    const leftBorderStyle = {
        border: "none",
        borderColor: "#000000",
        borderLeft: "2px solid #000000",
        textAlign: "right"
    };

    const rightBorderStyle = {
        border: "none",
        borderColor: "#000000",
        borderRight: "2px solid #000000",
        textAlign: "right"
    };

    const payoutStyle = {
        border: "none",
        borderColor: "#000000",
        textAlign: "right",
        background: "#d9edf7"
    };

    const netProfitStyle = {
        border: "none",
        borderColor: "#000000",
        borderLeft: "2px solid #000000",
        textAlign: "right",
        background: "#fcf8e3"
    };

    const balanceStyle = {
        border: "none",
        borderColor: "#000000",
        textAlign: "right",
        background: "#fcf8e3"
    };

    const textRightStyle = {
        border: "none",
        borderColor: "#000000",
        textAlign: "right"
    };

    const textLeftStyle = {
        border: "none",
        borderColor: "#000000",
        textAlign: "left"
    };

    /// <summary>
    /// Author : -
    /// </summary>
    function getProductData(date) {
        _dispatch(setBusy());
        setOkcount(0);
        fetch(ApiUrl._API_GET_SHAREHOLDER_EXTERNAL_PRODUCT_DATA + "?date=" + date, {
            method: ApiKey._API_GET,
            headers: {
                "Content-Type": ApiKey._API_FORM_URLENCODED,
                "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
            },
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    setProcessed(responseJson[ApiKey._API_DATA_KEY].processed);
                    let data = responseJson[ApiKey._API_DATA_KEY]["data"];
                    setOktoprocess(true);
                    responseJson[ApiKey._API_DATA_KEY].data.map((product, index) => {
                        if (product.payout == null)
                            setOktoprocess(false);
                    });
                    setProductData(data);

                    let categoryRow = {};
                    let catTotal = {};

                    data.map((e, i) => {
                        if (e.grossSales == null) {

                        }

                        let winLose = e.winAmount - e.betAmt;
                        
                        let royaltyAmt = e.cRoyalty; //winLose * (e.royalty / 100)
                        let commissionAmt = e.cCommission;// * (e.commission / 100);
                        let balanceAmt = 0;
                        
                        if (e.clubFee == 0) {
                            balanceAmt = royaltyAmt + commissionAmt + e.rewards + e.pointsRealValue;
                        }
                        else {
                            balanceAmt = e.clubFee88 - e.clubFee60;
                        }

                        var payoutAmt = e.payout;
                        let netProfitAmt = e.cNetProfit;
                        winLose = Math.round(winLose * 1000)/1000;


                        if (!categoryRow[e.categoryName]) {
                            categoryRow[e.categoryName] = {};
                            if (typeof (e.categoryName) !== "undefined") {
                              catTotal[e.categoryName] = { turnover: 0, validTurnover: 0, winlose: 0, royalty: 0, commission: 0, balance: 0, payout: 0, netProfit: 0, clubFee: 0, clubFee88: 0, clubFee60: 0, rewards: 0,  valueInCurrency: 0, pointsRealValue: 0 };
                            }
                            else {
                                catTotal[e.categoryName] = { turnover: e.grossSales, validTurnover: e.validTurnover, payout: e.payout, netProfit: e.netProfit, clubFee: e.clubFee, clubFee88: e.clubFee88, clubFee60: e.clubFee60, rewards: e.rewards, valueInCurrency: "-", pointsRealValue: e.pointsRealValue };
                            }
                        }
                        if (e.productName !== "Settlement") {
                            if (!categoryRow[e.categoryName][e.productName]) {
                                categoryRow[e.categoryName][e.productName] = { turnover: e.grossSales, validTurnover: e.validTurnover, winlose: winLose, royalty: royaltyAmt, balance: balanceAmt, payout: payoutAmt, commission: commissionAmt, netProfit: netProfitAmt, clubFee: e.clubFee, clubFee88: e.clubFee88, clubFee60: e.clubFee60, rewards: e.rewards, valueInCurrency: e.valueInCurrency, pointsRealValue: e.pointsRealValue };
                            }
                        }
                        if (typeof (e.categoryName) !== "undefined") {
                            catTotal[e.categoryName].turnover += e.grossSales;
                            catTotal[e.categoryName].validTurnover += e.validTurnover;
                            catTotal[e.categoryName].winlose += winLose;
                            catTotal[e.categoryName].royalty += royaltyAmt;
                            catTotal[e.categoryName].balance += balanceAmt;
                            catTotal[e.categoryName].payout += payoutAmt;
                            catTotal[e.categoryName].commission += commissionAmt;
                            

                            catTotal[e.categoryName].netProfit += netProfitAmt;
                            
                            catTotal[e.categoryName].clubFee += e.clubFee;
                            catTotal[e.categoryName].clubFee88 += e.clubFee88;
                            catTotal[e.categoryName].clubFee60 += e.clubFee60;
                            catTotal[e.categoryName].rewards += e.rewards;
                            catTotal[e.categoryName].pointsRealValue += e.pointsRealValue;
                        }

                    })
                    setCategoryData(categoryRow);
                    setCategoryTotal(catTotal);
                }
                setInitPage(false);
                _dispatch(setIdle());
            });
    }


    /// <summary>
    /// Author : -
    /// </summary>
    useEffect(() => {
        if (_location.state) {
            if (_location.state.date != "") {
                setDate(_location.state.date);
                getProductData(_location.state.date);
            }
        }
    }, []);



    function preSubmit() {
        setShowConfirmation(true);
    }

    function grabData(){
        handleSubmit(grabSHHubData)();
    }

    function confirmStep() {
        handleSubmit(confirmStepData)();
    }

    function preSubmitReleased() {
        setShowConfirmationRelease(true);
    }

    const submitProcess = async (data, e) => {
        let params = { date: date };

        let formBody = createFormBody(params);
        let responseJson = await ApiEngine.post(
            ApiUrl._API_UPDATE_SHAREHOLDER_PROCESS,
            formBody
        );
        _dispatch(
            showMessage(
                responseJson[ApiKey._API_SUCCESS_KEY],
                t(responseJson[ApiKey._API_MESSAGE_KEY])
            )
        );

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            getProductData(date);
        }
    };

    const grabSHHubData = async (data, e) => {
        let params = { date: date };

        let formBody = createFormBody(params);
        let responseJson = await ApiEngine.post(
            ApiUrl._API_GET_SHAREHOLDER_SH_HUB_DATA,
            formBody
        );
        getProductData(date);
        _dispatch(
            showMessage(
                responseJson[ApiKey._API_SUCCESS_KEY],
                t(responseJson[ApiKey._API_MESSAGE_KEY])
            )
        );
    };

    
    const confirmStepData = async (data, e) => {
        let params = { date: date };

        let formBody = createFormBody(params);
        let responseJson = await ApiEngine.post(
            ApiUrl._API_UPDATE_SHAREHOLDER_EXTERNAL_READY,
            formBody
        );
        getProductData(date);
        _dispatch(
            showMessage(
                responseJson[ApiKey._API_SUCCESS_KEY],
                t(responseJson[ApiKey._API_MESSAGE_KEY])
            )
        );
    };
    

    async function unstuckSH() {
        let apiUrl = ApiUrl._API_UNSTUCK_SH;
        fetch(apiUrl, {
            method: ApiKey._API_POST,
            headers: {
                "Content-Type": ApiKey._API_FORM_URLENCODED,
                "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
            },
            body: createFormBody({ date: date })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log(responseJson[ApiKey._API_SUCCESS_KEY]);
                console.log(responseJson[ApiKey._API_MESSAGE_KEY]);
            });
    }

    const clearSH = async (productName) => {
        let responseJson = await ApiEngine.post(ApiUrl._API_RESET_PRODUCT_PROCESS, createFormBody({ "productName": productName, "date": date }));
        if (responseJson[ApiKey._API_SUCCESS_KEY]){
            window.location.reload();
        }
    }

  /// <summary>
  /// Export data
  /// </summary>
  const getShareholderDataForEx = () => {
    _dispatch(setBusy());
    var exportTable = [];

    fetch(ApiUrl._API_GET_SHAREHOLDER_EXTERNAL_PRODUCT_DATA + "?date=" + date, {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          let data = responseJson[ApiKey._API_DATA_KEY]["data"];

          // Group data based on category name
          const groupByCategory = data.reduce((group, product) => {
            const { categoryName } = product;
            group[categoryName] = group[categoryName] ?? [];
            group[categoryName].push(product);
            return group;
          }, {});
          let temp = groupByCategory;

          let catTotal = {};
          for (let i = 0; i < _GAME_CATEGORIES.length; i++) {
            if (typeof temp[_GAME_CATEGORIES[i]] !== "undefined") {
              let categoryRow = {};

              // Sort product name by alphabet
              const sortedProduct = [...temp[_GAME_CATEGORIES[i]]].sort((a, b) =>
                a.productName < b.productName ? 1 : -1,
              );

              sortedProduct.map((e, index) => {
                if (e.grossSales == null) {

                }

                let winLose = e.winAmount - e.betAmt;
                let royaltyAmt = e.cRoyalty;
                let commissionAmt = e.cCommission;
                let balanceAmt = 0;
                if (e.clubFee == 0) {
                  balanceAmt = royaltyAmt + commissionAmt + e.rewards + e.pointsRealValue;
                }
                else {
                  balanceAmt = e.clubFee88 - e.clubFee60;
                }
                var payoutAmt = e.payout;
                let netProfitAmt = e.cNetProfit;

                winLose = Math.round(winLose * 1000) / 1000;

                if (!categoryRow[e.categoryName]) {
                  categoryRow[e.categoryName] = {};
                  if (typeof (e.categoryName) !== "undefined") {
                    catTotal[e.categoryName] = { turnover: 0, validTurnover: 0, winlose: 0, royalty: 0, commission: 0, balance: 0, payout: 0, netProfit: 0, clubFee: 0, clubFee88: 0, clubFee60: 0, rewards: 0, valueInCurrency: 0, pointsRealValue: 0 };
                  }
                  else {
                    catTotal[e.categoryName] = { turnover: e.grossSales, validTurnover: e.validTurnover, payout: e.payout, netProfit: e.netProfit, clubFee: e.clubFee, clubFee88: e.clubFee88, clubFee60: e.clubFee60, rewards: e.rewards, valueInCurrency: "_", pointsRealValue: e.pointsRealValue };
                  }
                }
                if (typeof (e.categoryName) !== "undefined") {
                  catTotal[e.categoryName].turnover += e.grossSales;
                  catTotal[e.categoryName].validTurnover += e.validTurnover;
                  catTotal[e.categoryName].winlose += winLose;
                  catTotal[e.categoryName].royalty += royaltyAmt;
                  catTotal[e.categoryName].balance += balanceAmt;
                  catTotal[e.categoryName].payout += payoutAmt;
                  catTotal[e.categoryName].commission += commissionAmt;
                  catTotal[e.categoryName].netProfit += netProfitAmt;
                  catTotal[e.categoryName].clubFee += e.clubFee;
                  catTotal[e.categoryName].clubFee88 += e.clubFee88;
                  catTotal[e.categoryName].clubFee60 += e.clubFee60;
                  catTotal[e.categoryName].rewards += e.rewards;
                  catTotal[e.categoryName].pointsRealValue += e.pointsRealValue;
                  catTotal[e.categoryName].valueInCurrency += e.valueInCurrency;
                }

                // Provider
                if (e.productName !== "Settlement") {
                  if (!categoryRow[e.categoryName][e.productName]) {
                    categoryRow[e.categoryName][e.productName] = {
                      name: e.productName,
                      turnover: `${numberWithCurrencyFormat(e.grossSales, 3, true)}`,
                      validTurnover: `${numberWithCurrencyFormat(e.validTurnover, 3, true)}`,
                      winlose: `${numberWithCurrencyFormat(winLose, 3, true)}`,
                      clubFee: `${numberWithCurrencyFormat(e.clubFee, 3, true)}`,
                      royalty: `${numberWithCurrencyFormat(royaltyAmt, 3, true)}`,
                      commission: `${numberWithCurrencyFormat(commissionAmt, 3, true)}`,
                      rewards: `${numberWithCurrencyFormat(e.rewards, 3, true)}`,
                      valueInCurrency: `${numberWithCurrencyFormat(e.valueInCurrency, 8, true)}`,
                      pointsRealValue: `${numberWithCurrencyFormat(e.pointsRealValue, 8, true)}`,
                      clubFee88: `${numberWithCurrencyFormat(e.clubFee88, 3, true)}`,
                      clubFee60: `${numberWithCurrencyFormat(e.clubFee60, 3, true)}`,
                      balance: `${numberWithCurrencyFormat(balanceAmt, 3, true)}`,
                      payout: `${numberWithCurrencyFormat(payoutAmt, 3, true)}`,
                      netProfit: `${numberWithCurrencyFormat(netProfitAmt, 3, true)}`,
                    };

                    exportTable.push(categoryRow[e.categoryName][e.productName]);
                  }
                }

                // Category total
                if (index === temp[_GAME_CATEGORIES[i]].length - 1) {
                  if (typeof catTotal[_GAME_CATEGORIES[i]] !== "undefined") {
                    exportTable.push(
                      {
                        name: _GAME_CATEGORIES[i],
                        turnover: `${numberWithCurrencyFormat(catTotal[_GAME_CATEGORIES[i]].turnover, 3, true)}`,
                        validTurnover: `${numberWithCurrencyFormat(catTotal[_GAME_CATEGORIES[i]].validTurnover, 3, true)}`,
                        winlose: `${numberWithCurrencyFormat(catTotal[_GAME_CATEGORIES[i]].winlose, 3, true)}`,
                        clubFee: `${numberWithCurrencyFormat(catTotal[_GAME_CATEGORIES[i]].clubFee, 3, true)}`,
                        royalty: `${numberWithCurrencyFormat(catTotal[_GAME_CATEGORIES[i]].royalty, 3, true)}`,
                        commission: `${numberWithCurrencyFormat(catTotal[_GAME_CATEGORIES[i]].commission, 3, true)}`,
                        rewards: `${numberWithCurrencyFormat(catTotal[_GAME_CATEGORIES[i]].rewards, 3, true)}`,
                        valueInCurrency: `${numberWithCurrencyFormat(catTotal[_GAME_CATEGORIES[i]].valueInCurrency, 8, true)}`,
                        pointsRealValue: `${numberWithCurrencyFormat(catTotal[_GAME_CATEGORIES[i]].pointsRealValue, 8, true)}`,
                        clubFee88: `${numberWithCurrencyFormat(catTotal[_GAME_CATEGORIES[i]].clubFee88, 3, true)}`,
                        clubFee60: `${numberWithCurrencyFormat(catTotal[_GAME_CATEGORIES[i]].clubFee60, 3, true)}`,
                        balance: `${numberWithCurrencyFormat(catTotal[_GAME_CATEGORIES[i]].balance, 3, true)}`,
                        payout: `${numberWithCurrencyFormat(catTotal[_GAME_CATEGORIES[i]].payout, 3, true)}`,
                        netProfit: `${numberWithCurrencyFormat(catTotal[_GAME_CATEGORIES[i]].netProfit, 3, true)}`,
                      }
                    );
                  }
                }
              });
            }
          }

          // Settlement
          temp["undefined"].map((e, index) => {
            exportTable.push(
              {
                name: "",
                turnover: "",
                validTurnover: "",
                winlose: "",
                clubFee: "",
                royalty: "",
                commission: "",
                rewards: "",
                valueInCurrency: "",
                pointsRealValue: "",
                clubFee88: "",
                clubFee60: "",
                balance: "",
                payout: "",
                netProfit: "",
              }
            );
            exportTable.push(
              {
                name: e.productName,
                turnover: `${numberWithCurrencyFormat(e.grossSales, 3, true)}`,
                validTurnover: `${numberWithCurrencyFormat(e.validTurnover, 3, true)}`,
                winlose: e.winlose,
                clubFee: e.clubFee,
                royalty: e.royalty,
                commission: e.commission,
                rewards: e.rewards,
                valueInCurrency: e.valueInCurrency,
                pointsRealValue: e.pointsRealValue,
                clubFee88: e.clubFee88,
                clubFee60: e.clubFee60,
                balance: e.balance,
                payout: `${numberWithCurrencyFormat(e.payout, 3, true)}`,
                netProfit: e.payout > 0 ? `${numberWithCurrencyFormat(e.payout * -1, 3, true)}` : `${numberWithCurrencyFormat(e.payout, 3, true)}`,
              }
            );
          })

          // Reverse arrangement of data to be similar with BO
          const reverseExportTable = [...exportTable].reverse().map(element => {
            return element
          });

          setExportDatas(reverseExportTable);
          setIsExport(true);
        }
        _dispatch(setIdle());
      });
  }

  const downloadExcel = async () => {
    let tempArr = [];
    let tempData = [...exportDatas];

    // Create a new workbook
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');

    // Define cell styles for headers
    const headerStyle = {
      font: { bold: true, size: 12 },
      alignment: { horizontal: 'center', vertical: 'middle' }
    };

    // Add header row with styling
    const headerRow = worksheet.addRow(_headers);
    headerRow.eachCell((cell) => {
      cell.font = headerStyle.font;
      cell.alignment = headerStyle.alignment;
      cell.fill = headerStyle.fill;
    });

    for (var i = 0; i < tempData.length; i++) {
      let temp1 = Object.values(tempData[i]);
      let temp2 = [];
      let temp3 = [];

      // force all to string
      temp1.map(x => {
        if (x !== undefined) {
          temp2.push(x.toString());
        }
        else {
          temp2.push("-");
        }
      });

      // check is a number, convert to number
      temp2.map((x) => {
        let formattedX = x.replaceAll(",", "");

        const regex = /^-?[0-9]\d*(\.\d+)?$/;

        if (regex.test(formattedX)) {
          temp3.push(parseFloat(formattedX));
        }
        else {
          temp3.push(formattedX);
        }
      });

      tempArr.push(temp3);
    }

    // Set the width of the first column
    worksheet.getColumn(1).width = 28;

    const commonWidth = 15;

    // Loop through the columns and set their widths
    for (let columnIndex = 2; columnIndex <= _headers.length + 1; columnIndex++) {
      worksheet.getColumn(columnIndex).width = commonWidth;
    }

    // Grey bg
    const customRowStyle = {
      font: { size: 12 },
      fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'd3d3d3' } },
    };

    // White bg
    const customRowStyle2 = {
      font: { bold: true, size: 12 },
      fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ffffff' } },
    };

    tempArr.forEach((rowData, index) => {
      const row = worksheet.addRow(rowData);

      // Apply to all cell bg color
      row.eachCell((cell) => {
        cell.font = customRowStyle.font;
        cell.fill = customRowStyle.fill;
      });

      // Apply to category cell and header cell
      for (var x = 0; x < _GAME_CATEGORIES.length; x++) {
        if (rowData.includes("Settlement") || rowData.includes("Name") || rowData.includes(_GAME_CATEGORIES[x])) {
          row.eachCell((cell) => {
            cell.font = customRowStyle2.font;
            cell.fill = customRowStyle2.fill;
          });
        }
      }

      // Apply conditional formatting to a specific cell / negative number color
      for (var i = 2; i < _headers.length + 1; i++) {
        const cell = row.getCell(i);

        // Set number format
        cell.numFmt = '#,##0.000';

        // Check if category row
        const result = _GAME_CATEGORIES.some((item) => rowData.includes(item));

        if (rowData.includes("Settlement") || result) {
          if (rowData[i - 1] < 0) {
            cell.font = { bold: true, color: { argb: 'ec261c' }, size: 12 };
          }
        }
        else {
          if (rowData[i - 1] < 0) {
            cell.font = { color: { argb: 'ec261c' }, size: 12 };
          }
        }
      }
    });

    // Generate the Excel file
    const buffer = await workbook.xlsx.writeBuffer();

    // Create a Blob and download the file
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = "ExternalProcessSharesSales(" +
      moment().format("YYYY-MM-DD HH:mm:ss") +
      ").xlsx";
    a.click();
  };

    return (
        <div>
            {showConfirmation && (
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText={t("CONFIRM")}
                    cancelBtnText={t("CANCEL")}
                    confirmBtnBsStyle="danger"
                    title={t("CONFIRM_PROCESS_SALES OF " + date + "?")}
                    onConfirm={() => {
                        setShowConfirmation(false);
                        handleSubmit(submitProcess)();
                    }}
                    onCancel={() => {
                        setShowConfirmation(false);
                    }}
                ></SweetAlert>
            )}

            <h1 className="page-header">{t("Shareholder External Sales Process")}</h1>
            <Panel>
                <PanelBody>
                    <div className="process-sales-header-wrapper">
                        <h2 className="process-sales-header">
                            <div>Process
                                <div
                                    style={{ width: "90px", textAlign: "center", display: "inline-block" }}>
                                    <Controller
                                    control={control}
                                    name="date"
                                    render={({ onChange, value }) => (
                                        <DateTime
                                            isValidDate={disableFutureDt}
                                            value={date}
                                            dateFormat="DD-MM-YYYY"
                                            timeFormat={false}
                                            closeOnSelect={true}
                                            onChange={(e) => {
                                                if (e instanceof moment) {
                                                    setDate(e.format("DD-MM-YYYY"));
                                                    getProductData(e.format("DD-MM-YYYY"));
                                                    onChange(e.format("DD-MM-YYYY"));
                                                    setIsExport(false);
                                                    setExportDatas([]);
                                                }
                                                else {
                                                    onChange('');
                                                }
                                            }} />
                                    )}
                                    onChange={(value) => {
                                        setDate(value);
                                    }}
                                    />
                                </div> sales
                            </div>
                            {processed && <div style={{ color: "white", background: "white", userSelect: "none", fontSize: "10px", textAlign: "center", padding: "5px", height: "25px" }} onClick={() => unstuckSH()}>Unstuck</div>}
                        </h2>

                        <div>
                            {isExport ? 
                                <button className="btn btn-primary btn-lg" onClick={downloadExcel} >
                                    <i class="fa fa-download" aria-hidden="true"></i>
                                </button> 
                                :
                                <button className="btn btn-primary btn-lg" onClick={() => { getShareholderDataForEx(); }} >
                                    <i className="fas fa-file-csv"></i>
                                </button>
                            }
                        </div>
                    </div>

                    {// display message depending whether processed or released yet
                        (!processed) ?
                            (productData.length > 0 && oktoprocess ?
                            <div>
                                <div className="alert alert-success m-b-20">
                                    Grab latest data with <button className="btn btn-primary btn-sm" onClick={() => grabData()} style={{marginLeft:"5px", marginRight:"5px"}}>Grab Data</button> or click <button className="btn btn-primary btn-sm" onClick={() => confirmStep()} style={{marginLeft:"5px", marginRight:"5px"}}>Confirm</button> to go next step.
                                </div>
                            </div>
                            :
                            <div className="alert alert-warning m-b-20" role="alert">
                                {initPage ? "Please select date to continue." : <button className="btn btn-primary btn-sm" onClick={() => grabData()}>Grab Data</button>}
                            </div>)
                        :
                        <div className="alert alert-success m-b-20">
                            <u><b>{date}</b></u> external data confirmed. Please go 3.4.3 to continue.
                        </div>
                    }

                    <table key="main-table" className="table table-hover table-process" style={tableStyle}>
                        <tbody>
                            <tr style={lineRowStyle}>
                                <th style={textLeftStyle}>Name</th>
                                <th style={leftBorderStyle}>Turnover</th>
                                <th style={textRightStyle}>V.Turnover</th>
                                <th style={textRightStyle}>Win/Loss</th>
                                <th style={rightBorderStyle}>Club Fee(CF)</th>
                                <th style={textRightStyle}>Royalty</th>
                                <th style={textRightStyle}>Comm</th>
                                <th style={textRightStyle}>Reward</th>
                                <th style={textRightStyle}>Value In Currency</th>
                                <th style={textRightStyle}>Points Real Value</th>
                                <th style={textRightStyle}>CF Royalty 88%</th>
                                <th style={textRightStyle}>CF 60%</th>
                                <th style={textRightStyle}>Balance</th>
                                <th style={textRightStyle}>Payout</th>
                                <th style={leftBorderStyle}>Net Profit</th>
                            </tr>

                            {Object.keys(categoryData) && Object.keys(categoryData).length > 0 &&
                                Object.keys(categoryData).map((categoryName, i) => {
                                    if (typeof (categoryTotal[categoryName]) !== "undefined") {
                                        return (
                                            <>
                                                {
                                                    categoryName === "undefined" && <>
                                                        <tr style={{ position: "relative" }} key={i}>
                                                            <td style={textLeftStyle}>{t("Settlement")} {!processed && <div className="reset-sh-btn" onClick={() => clearSH("Settlement")}>{t("DELETE")}</div>}</td>
                                                            <td style={leftBorderStyle}>{
                                                                categoryTotal[categoryName].turnover != null ?
                                                                    numberWithCurrencyFormat(parseFloat(categoryTotal[categoryName].turnover), 3, true)
                                                                    :
                                                                    "-"
                                                            }</td>
                                                            <th style={textRightStyle}>{numberWithCurrencyFormat(parseFloat(categoryTotal[categoryName].validTurnover), 3, true)}</th>
                                                            <td style={textRightStyle}>NIL</td>
                                                            <td style={rightBorderStyle}>NIL</td>
                                                            <td style={textRightStyle}>NIL</td>
                                                            <td style={textRightStyle}>NIL</td>
                                                            <td style={textRightStyle}>NIL</td>
                                                            <td style={textRightStyle}>NIL</td>
                                                            <td style={textRightStyle}>NIL</td>
                                                            <td style={textRightStyle}>NIL</td>
                                                            <td style={textRightStyle}>NIL</td>
                                                            <td style={textRightStyle}>NIL</td>
                                                            <td style={textRightStyle}>{numberWithCurrencyFormat(parseFloat(categoryTotal[categoryName].payout), 3, true)}</td>
                                                            <td className={(categoryTotal[categoryName].payout * -1 < 0 ? "text-red":"")} style={leftBorderStyle}>{categoryTotal[categoryName].netProfit !== null ? numberWithCurrencyFormat(parseFloat(categoryTotal[categoryName].payout*-1), 3, true) : "NIL"}</td>
                                                        </tr>
                                                        <tr style={greyStyle}>
                                                            <th style={textLeftStyle}></th>
                                                            <th style={leftBorderStyle}></th>
                                                            <th style={textRightStyle}></th>
                                                            <th style={textRightStyle}></th>
                                                            <th style={rightBorderStyle}></th>
                                                            <th style={textRightStyle}></th>
                                                            <th style={textRightStyle}></th>
                                                            <th style={textRightStyle}></th>
                                                            <th style={textRightStyle}></th>
                                                            <th style={textRightStyle}></th>
                                                            <th style={textRightStyle}></th>
                                                            <th style={textRightStyle}></th>
                                                            <th style={textRightStyle}></th>
                                                            <th style={textRightStyle}></th>
                                                            <th style={leftBorderStyle}></th>
                                                        </tr>
                                                    </>
                                                }

                                                {
                                                    categoryName !== "undefined" && <>
                                                        <tr key={i} style={lineRowStyle}>
                                                            <td style={textLeftStyle}>{categoryName} <span style={{float:"right"}}>TOTAL</span></td>
                                                            <td className={(categoryTotal[categoryName].turnover < 0 ? "text-red" : "")} style={leftBorderStyle}>{

                                                                categoryTotal[categoryName].turnover != null ?
                                                                    numberWithCurrencyFormat(parseFloat(categoryTotal[categoryName].turnover), 3, true)
                                                                    :
                                                                    "-"
                                                            }</td>
                                                            <td className={(categoryTotal[categoryName].validTurnover < 0 ? "text-red" : "")} style={textRightStyle}>{numberWithCurrencyFormat(parseFloat(categoryTotal[categoryName].validTurnover), 3, true)}</td>
                                                            <td className={(categoryTotal[categoryName].winlose < 0 ? "text-red" : "")} style={textRightStyle}>{numberWithCurrencyFormat(parseFloat(categoryTotal[categoryName].winlose), 3, true)}</td>
                                                            <td className={(categoryTotal[categoryName].clubFee < 0 ? "text-red" : "")} style={rightBorderStyle}>{numberWithCurrencyFormat(parseFloat(categoryTotal[categoryName].clubFee), 3, true)}</td>
                                                            <td className={(categoryTotal[categoryName].royalty < 0 ? "text-red" : "")} style={textRightStyle}>{numberWithCurrencyFormat(parseFloat(categoryTotal[categoryName].royalty), 3, true)}</td>
                                                            <td className={(categoryTotal[categoryName].commission < 0 ? "text-red" : "")} style={textRightStyle}>{numberWithCurrencyFormat(parseFloat(categoryTotal[categoryName].commission), 3, true)}</td>
                                                            <td className={(categoryTotal[categoryName].rewards < 0 ? "text-red" : "")} style={textRightStyle}>{numberWithCurrencyFormat(parseFloat(categoryTotal[categoryName].rewards), 3, true)}</td>
                                                            <td style={textRightStyle}>-</td>
                                                            <td className={(categoryTotal[categoryName].pointsRealValue < 0 ? "text-red" : "")} style={textRightStyle}>{numberWithCurrencyFormat(parseFloat(categoryTotal[categoryName].pointsRealValue), 8, true)}</td>
                                                            <td className={(categoryTotal[categoryName].clubFee88 < 0 ? "text-red" : "")} style={textRightStyle}>{numberWithCurrencyFormat(parseFloat(categoryTotal[categoryName].clubFee88), 3, true)}</td>
                                                            <td className={(categoryTotal[categoryName].clubFee60 < 0 ? "text-red" : "")} style={textRightStyle}>{numberWithCurrencyFormat(parseFloat(categoryTotal[categoryName].clubFee60), 3, true)}</td>
                                                            <td className={(categoryTotal[categoryName].balance < 0 ? "text-red" : "")} style={balanceStyle}>{numberWithCurrencyFormat(parseFloat(categoryTotal[categoryName].balance), 3, true)}</td>
                                                            <td style={payoutStyle}>{
                                                                isNaN(categoryTotal[categoryName].payout) ?
                                                                    "NIL"
                                                                    :
                                                                    numberWithCurrencyFormat(parseFloat(categoryTotal[categoryName].payout), 3, true)
                                                            }</td>
                                                            <td className={(categoryTotal[categoryName].netProfit < 0 ? "text-red" : "")} style={netProfitStyle}>{categoryTotal[categoryName].netProfit !== null ? numberWithCurrencyFormat(parseFloat(categoryTotal[categoryName].netProfit), 3, true) : <span className="badge bg-danger">{t("NOT_SET")}</span>}</td>
                                                        </tr>
                                                    </>
                                                }

                                                {Object.keys(categoryData[categoryName]) && Object.keys(categoryData[categoryName]).length > 0 &&
                                                    Object.keys(categoryData[categoryName]).map((product, i) => {
                                                        return (
                                                            <tr key={i} style={{ position: "relative", ...greyStyle}}>
                                                                <td className="process-category" style={{ textAlign: "left", paddingLeft: "20px" }}>{i + 1}. {product}{!processed && <div className="reset-sh-btn" onClick={() => clearSH(product)}>{t("DELETE")}</div>}</td>
                                                                <td className={(categoryData[categoryName][product].turnover < 0 ? "text-red" : "")} style={leftBorderStyle}>{

                                                                    categoryData[categoryName][product].turnover != null ?
                                                                        numberWithCurrencyFormat(parseFloat(categoryData[categoryName][product].turnover), 3, true)
                                                                        :
                                                                        "-"
                                                                }</td>
                                                                <td className={(categoryData[categoryName][product].validTurnover < 0 ? "text-red" : "")} style={textRightStyle}>{
                                                                    categoryData[categoryName][product].turnover != null ?
                                                                        numberWithCurrencyFormat(parseFloat(categoryData[categoryName][product].validTurnover), 3, true)
                                                                        :
                                                                        "-"
                                                                }</td>
                                                                <td className={(categoryData[categoryName][product].winlose < 0 ? "text-red" : "")} style={textRightStyle}>{
                                                                    categoryData[categoryName][product].turnover != null ?
                                                                        numberWithCurrencyFormat(parseFloat(categoryData[categoryName][product].winlose), 3, true)
                                                                        :
                                                                        "-"
                                                                }</td>
                                                                <td className={(categoryData[categoryName][product].clubFee < 0 ? "text-red" : "")} style={rightBorderStyle}>{
                                                                    categoryData[categoryName][product].turnover != null ?
                                                                        numberWithCurrencyFormat(parseFloat(categoryData[categoryName][product].clubFee), 3, true)
                                                                        :
                                                                        "-"
                                                                }</td>
                                                                <td className={(categoryData[categoryName][product].royalty < 0 ? "text-red" : "")} style={textRightStyle}>{
                                                                    categoryData[categoryName][product].turnover != null ?
                                                                        numberWithCurrencyFormat(parseFloat(categoryData[categoryName][product].royalty), 3, true)
                                                                        :
                                                                        "-"
                                                                }</td>
                                                                <td className={(categoryData[categoryName][product].commission < 0 ? "text-red" : "")} style={textRightStyle}>{
                                                                    categoryData[categoryName][product].turnover != null ?
                                                                        numberWithCurrencyFormat(parseFloat(categoryData[categoryName][product].commission), 3, true)
                                                                        :
                                                                        "-"
                                                                }</td>
                                                                <td className={(categoryData[categoryName][product].rewards < 0 ? "text-red" : "")} style={textRightStyle}>{
                                                                    categoryData[categoryName][product].turnover != null ?
                                                                        numberWithCurrencyFormat(parseFloat(categoryData[categoryName][product].rewards), 3, true)
                                                                        :
                                                                        "-"
                                                                }</td>
                                                                <td style={textRightStyle}>{
                                                                    categoryData[categoryName][product].valueInCurrency != null ?
                                                                        numberWithCurrencyFormat(parseFloat(categoryData[categoryName][product].valueInCurrency), 8, true)
                                                                        :
                                                                        "-"
                                                                }</td>
                                                                <td style={textRightStyle}>{
                                                                    categoryData[categoryName][product].pointsRealValue != null ?
                                                                        numberWithCurrencyFormat(parseFloat(categoryData[categoryName][product].pointsRealValue), 8, true)
                                                                        :
                                                                        "-"
                                                                }</td>
                                                                <td className={(categoryData[categoryName][product].clubFee88 < 0 ? "text-red" : "")} style={textRightStyle}>{
                                                                    categoryData[categoryName][product].turnover != null ?
                                                                        numberWithCurrencyFormat(parseFloat(categoryData[categoryName][product].clubFee88), 3, true)
                                                                        :
                                                                        "-"
                                                                }</td>
                                                                <td className={(categoryData[categoryName][product].clubFee60 < 0 ? "text-red" : "")} style={textRightStyle}>{
                                                                    categoryData[categoryName][product].turnover != null ?
                                                                        numberWithCurrencyFormat(parseFloat(categoryData[categoryName][product].clubFee60), 3, true)
                                                                        :
                                                                        "-"
                                                                }</td>
                                                                <td className={(categoryData[categoryName][product].balance < 0 ? "text-red" : "")} style={textRightStyle}>{
                                                                    categoryData[categoryName][product].turnover != null ?
                                                                        numberWithCurrencyFormat(parseFloat(categoryData[categoryName][product].balance), 3, true)
                                                                        :
                                                                        "-"
                                                                }</td>
                                                                <td style={textRightStyle}>{numberWithCurrencyFormat(parseFloat(categoryData[categoryName][product].payout),3,true)}
                                                                </td>
                                                                <td className={(categoryData[categoryName][product].validTurnover - categoryData[categoryName][product].payout < 0 ? "text-red" : "")} style={leftBorderStyle}>{
                                                                    categoryData[categoryName][product].payout > 0 ? numberWithCurrencyFormat(parseFloat(categoryData[categoryName][product].validTurnover-categoryData[categoryName][product].payout), 3, true) : ""
                                                                }</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </>
                                        )
                                    }
                                })
                            }
                        </tbody>
                    </table>

                </PanelBody>

            </Panel>
        </div>
    );
};

export default ShareholdersExternalProcessSales;