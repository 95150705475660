import React, { useState, useEffect } from "react";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import { useHistory } from "react-router-dom";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import { ApiKey, ApiUrl, LanguageKey } from "../../util/Constant";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { stringIsNullOrEmpty, createMultiPartFormBody } from "../../util/Util";
import { setBusy, setIdle, showMessage, setSaveAdminLog } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Dropzone from "react-dropzone";
import { fetch } from "whatwg-fetch";

/// <summary>
/// Author : -
/// </summary>

const SkinSettingsDetail = (props) => {
    const { t } = useTranslation(LanguageKey._PRIMARY);
    let _history = useHistory();
    const _location = useLocation();
    const _dispatch = useDispatch();
    const { register, unregister, handleSubmit, errors, setValue } = useForm();
    const [skinSettingId, setSkinSettingId] = useState("");

    const [text, setText] = useState("");
    const [imageFile, setImageFile] = useState([]);
    const [image, setImage] = useState();
    const [imageTopRightFile, setImageTopRightFile] = useState([]);
    const [imageTopRight, setImageTopRight] = useState();
    const [imageBottomLeftFile, setImageBottomLeftFile] = useState([]);
    const [imageBottomLeft, setImageBottomLeft] = useState();
    const [isNewImage, setIsNewImage] = useState(false);
    const [status, setStatus] = useState(true);

    /// <summary>
    /// Author : -
    /// </summary>
    const logoDrop = (acceptedFiles) => {
        if (acceptedFiles.length !== 0) {
            const file = acceptedFiles[0];
            setImageFile(file);

            const reader = new FileReader();
            reader.onloadend = () => {
                setIsNewImage(true);
                setImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };
    /// <summary>
    /// Author : -
    /// </summary>
    const accessoryTopRightDrop = (acceptedFiles) => {
        if (acceptedFiles.length !== 0) {
            const file = acceptedFiles[0];
            setImageTopRightFile(file);

            const reader = new FileReader();
            reader.onloadend = () => {
                setIsNewImage(true);
                setImageTopRight(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };
    /// <summary>
    /// Author : -
    /// </summary>
    const accessoryBottomLeftDrop = (acceptedFiles) => {
        if (acceptedFiles.length !== 0) {
            const file = acceptedFiles[0];
            setImageBottomLeftFile(file);

            const reader = new FileReader();
            reader.onloadend = () => {
                setIsNewImage(true);
                setImageBottomLeft(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    /// <summary>
    /// Author : -
    /// </summary>
    useEffect(() => {
        console.log(_location);
        console.log(_location.state);
        init();
    }, []);

    /// <summary>
    /// Author : -
    /// </summary>
    async function init() {
        if (_location.state) {
            var apiUrl = ApiUrl._API_GET_SKIN_SETTINGS_BY_ID;
            apiUrl += "?id=" + _location.state.id;
            fetch(apiUrl, {
                method: ApiKey._API_GET,
                headers: {
                    "Content-Type": ApiKey._API_FORM_URLENCODED,
                    "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
                },
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    console.log(responseJson[ApiKey._API_DATA_KEY]);
                    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                        setSkinSettingId(responseJson[ApiKey._API_DATA_KEY]["id"]);
                        setText(responseJson[ApiKey._API_DATA_KEY]["skinName"]);
                        setStatus(responseJson[ApiKey._API_DATA_KEY]["status"]);
                        setImage(responseJson[ApiKey._API_DATA_KEY]["backgroundImage"]);
                        setImageBottomLeft(responseJson[ApiKey._API_DATA_KEY]["imageBottomLeft"]);
                        setImageTopRight(responseJson[ApiKey._API_DATA_KEY]["imageTopRight"]);
                    }
                });
        }
    }

    /// <summary>
    /// Author : -
    /// </summary>
    const submitForm = async (data, e) => {
        let params = {
            skinName: data.text,
            status: data.status,
            isNewImage: isNewImage,
        };

        if (!stringIsNullOrEmpty(imageFile)) {
            params["imageFile"] = imageFile;
        }

        if (stringIsNullOrEmpty(image)) {
            params["imageRemoved"] = true;
        }
        if (!stringIsNullOrEmpty(imageBottomLeftFile)) {
            params["imageBottomLeft"] = imageBottomLeftFile;
        }

        if (stringIsNullOrEmpty(imageBottomLeft)) {
            params["imageBottomLeftRemoved"] = true;
        }
        if (!stringIsNullOrEmpty(imageTopRightFile)) {
            params["imageTopRight"] = imageTopRightFile;
        }

        if (stringIsNullOrEmpty(imageTopRight)) {
            params["imageTopRightRemoved"] = true;
        }

        if (!stringIsNullOrEmpty(skinSettingId)) {
          params["id"] = skinSettingId;
          _dispatch(setSaveAdminLog("EDIT_BO_SKIN_SETTING", _history.location.pathname, params));
        } else {
          _dispatch(setSaveAdminLog("CREATE_BO_SKIN_SETTING", _history.location.pathname, params));
        }

        let formBody = createMultiPartFormBody(params);
        _dispatch(setBusy());

        fetch(ApiUrl._API_CREATE_OR_UPDATE_SKIN_SETTINGS, {
            method: ApiKey._API_POST,
            headers: {
                "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
            },
            body: formBody,
        })
            .then((response) => response.json())
            .then((responseJson) => {
                _dispatch(setIdle());
                _dispatch(
                    showMessage(
                        responseJson[ApiKey._API_SUCCESS_KEY],
                        t(responseJson[ApiKey._API_MESSAGE_KEY])
                    )
                );

                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    setSkinSettingId(responseJson[ApiKey._API_DATA_KEY]["id"]);
                }
            });
    };

    return (
        <div>
            <h1 className="page-header">
                {skinSettingId ? t("EDIT_SKIN_SETTINGS") : t("ADD_SKIN_SETTINGS") /*localization*/}
                <NavigationButton history={_history} />
            </h1>
            <div className="row">
                <div className="col-xl-12">
                    <Panel>
                        <PanelBody>
                            <form onSubmit={handleSubmit(submitForm)}>
                                <div className="row">
                                    <div className="col-lg-8">
                                        <div className="form-group row m-b-15">
                                            <label className="col-md-4 col-form-label">
                                                <b>{t("SKIN_NAME" /*localization*/)}</b>
                                            </label>
                                            <div className="col-md-7">
                                                <input
                                                    className="form-control"
                                                    rows="5"
                                                    style={{ marginTop: "10px" }}
                                                    ref={register({ required: "PLEASE_ENTER_TEXT" })}
                                                    name="text"
                                                    defaultValue={text}
                                                />
                                                {errors.text && (
                                                    <div className="invalid-feedback">
                                                        {t(errors.text.message)}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-8">
                                        <div className="form-group row m-b-15">
                                            <label className="col-md-4 col-form-label">
                                                <b>{t("IMAGE")}</b>
                                            </label>
                                            <div className="col-md-7">
                                                <Dropzone
                                                    accept={"image/*"}
                                                    onDrop={(acceptedFiles) => logoDrop(acceptedFiles)}
                                                >
                                                    {({ getRootProps, getInputProps }) => (
                                                        <section>
                                                            <div
                                                                className="dropzone"
                                                                style={{
                                                                    minHeight: "200px",
                                                                    textAlign: "center",
                                                                }}
                                                                {...getRootProps()}
                                                            >
                                                                <input {...getInputProps()} />
                                                                <h4 style={{ color: "grey" }}>
                                                                    {t(
                                                                        "DRAG_N_DROP_SOME_FILES_HERE_OR_CLICK_TO_SELECT_FILES"
                                                                    )}
                                                                </h4>
                                                                {!stringIsNullOrEmpty(image) && (
                                                                    <aside className="thumbsContainer">
                                                                        <div className="thumb">
                                                                            <div className="thumbInner">
                                                                                <img
                                                                                    src={image}
                                                                                    className="dropzone-img"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </aside>
                                                                )}
                                                            </div>
                                                        </section>
                                                    )}
                                                </Dropzone>
                                                <br />
                                                <button
                                                    type="button"
                                                    className="btn btn-danger"
                                                    onClick={() => {
                                                        setImage("");
                                                        setImageFile();
                                                    }}
                                                >
                                                    {t("REMOVE_IMAGE")}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-8">
                                        <div className="form-group row m-b-15">
                                            <label className="col-md-4 col-form-label">
                                                <b>{t("ACCESSORY_TOP_RIGHT")}</b>
                                            </label>
                                            <div className="col-md-7">
                                                <Dropzone
                                                    accept={"image/*"}
                                                    onDrop={(acceptedFiles) => accessoryTopRightDrop(acceptedFiles)}
                                                >
                                                    {({ getRootProps, getInputProps }) => (
                                                        <section>
                                                            <div
                                                                className="dropzone"
                                                                style={{
                                                                    minHeight: "200px",
                                                                    textAlign: "center",
                                                                }}
                                                                {...getRootProps()}
                                                            >
                                                                <input {...getInputProps()} />
                                                                <h4 style={{ color: "grey" }}>
                                                                    {t(
                                                                        "DRAG_N_DROP_SOME_FILES_HERE_OR_CLICK_TO_SELECT_FILES"
                                                                    )}
                                                                </h4>
                                                                {!stringIsNullOrEmpty(imageTopRight) && (
                                                                    <aside className="thumbsContainer">
                                                                        <div className="thumb">
                                                                            <div className="thumbInner">
                                                                                <img
                                                                                    src={imageTopRight}
                                                                                    className="dropzone-img"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </aside>
                                                                )}
                                                            </div>
                                                        </section>
                                                    )}
                                                </Dropzone>
                                                <br />
                                                <button
                                                    type="button"
                                                    className="btn btn-danger"
                                                    onClick={() => {
                                                        setImageTopRight("");
                                                        setImageTopRightFile();
                                                    }}
                                                >
                                                    {t("REMOVE_IMAGE")}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-8">
                                        <div className="form-group row m-b-15">
                                            <label className="col-md-4 col-form-label">
                                                <b>{t("ACCESSORY_BOTTOM_LEFT")}</b>
                                            </label>
                                            <div className="col-md-7">
                                                <Dropzone
                                                    accept={"image/*"}
                                                    onDrop={(acceptedFiles) => accessoryBottomLeftDrop(acceptedFiles)}
                                                >
                                                    {({ getRootProps, getInputProps }) => (
                                                        <section>
                                                            <div
                                                                className="dropzone"
                                                                style={{
                                                                    minHeight: "200px",
                                                                    textAlign: "center",
                                                                }}
                                                                {...getRootProps()}
                                                            >
                                                                <input {...getInputProps()} />
                                                                <h4 style={{ color: "grey" }}>
                                                                    {t(
                                                                        "DRAG_N_DROP_SOME_FILES_HERE_OR_CLICK_TO_SELECT_FILES"
                                                                    )}
                                                                </h4>
                                                                {!stringIsNullOrEmpty(imageBottomLeft) && (
                                                                    <aside className="thumbsContainer">
                                                                        <div className="thumb">
                                                                            <div className="thumbInner">
                                                                                <img
                                                                                    src={imageBottomLeft}
                                                                                    className="dropzone-img"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </aside>
                                                                )}
                                                            </div>
                                                        </section>
                                                    )}
                                                </Dropzone>
                                                <br />
                                                <button
                                                    type="button"
                                                    className="btn btn-danger"
                                                    onClick={() => {
                                                        setImageBottomLeft("");
                                                        setImageBottomLeftFile();
                                                    }}
                                                >
                                                    {t("REMOVE_IMAGE")}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-8">
                                        <div className="form-group row m-b-15">
                                            <label className="col-md-4 col-form-label">
                                                <b>{t("STATUS")}</b>
                                            </label>
                                            <div className="col-md-7">
                                                <div className="switcher">
                                                    <input
                                                        type="checkbox"
                                                        name="status"
                                                        id="status"
                                                        onChange={(e) => setStatus(e.target.checked)}
                                                        value={true}
                                                        checked={status}
                                                        ref={register}
                                                    />
                                                    <label htmlFor="status"></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-lg-3">
                                        <div className="form-group">
                                            <button type="submit" className="btn btn-primary">
                                                {t("SUBMIT")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </PanelBody>
                    </Panel>
                </div>
            </div>
        </div>
    );
};

export default SkinSettingsDetail;
