import React, { useState, useEffect } from "react";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import { useForm } from "react-hook-form";
import { ApiKey, ApiUrl, LanguageKey } from "../../util/Constant";
import { stringIsNullOrEmpty, createMultiPartFormBody } from "../../util/Util";
import { useLocation, useHistory } from "react-router-dom";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import { setBusy, setIdle, showMessage, setSaveAdminLog } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import Dropzone from "react-dropzone";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";

/// <summary>
/// Author : -
/// </summary>
const GameDetail = (props) => {
  const { t } = useTranslation(LanguageKey._PRIMARY);
  const _location = useLocation();
  const _history = useHistory();
  const _dispatch = useDispatch();
  const { register, handleSubmit, errors, setValue, unregister } = useForm();

  const [gameImage, setGameImage] = useState("");
  const [gameFile, setGameFile] = useState("");
  const [isNewImage, setIsNewImage] = useState(false);
  const [desktopImage, setDesktopImage] = useState("");
  const [desktopFile, setDesktopFile] = useState("");
  const [isNewDesktopImage, setIsNewDesktopImage] = useState(false);
  const [desktopImageHover, setDesktopImageHover] = useState("");
  const [desktopFileHover, setDesktopFileHover] = useState("");
  const [isNewDesktopImageHover, setIsNewDesktopImageHover] = useState(false);

  const [gameName, setGameName] = useState("");
  const [gameCode, setGameCode] = useState("");
  const [gameId, setGameId] = useState("");
  const [status, setStatus] = useState(false);
  const [recommended, setRecommended] = useState(false);
  const [rank, setRank] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [gameUm, setGameUm] = useState(false);
  const [hidden, setHidden] = useState(false);
  const [isNewGame, setIsNewGame] = useState(false);
  const [appUrl, setAppUrl] = useState("");
  const [appDeepLink, setAppDeepLink] = useState("");

  const [isApp, setIsApp] = useState(false);


  const [parameter_1, setParameter_1] = useState("");

  const [parameter_2, setParameter_2] = useState("");

  const [parameter_3, setParameter_3] = useState("");

  const [parameter_4, setParameter_4] = useState("");

  const [parameter_5, setParameter_5] = useState("");

  /// <summary>
  /// Author : -
  /// </summary>
  const submitForm = async (data, e) => {
    let params = {
      gameCode: data.code,
      gameName: data.name,
      status: data.status,
      gameUm: data.gameUm,
      hidden: data.hiddenGame,
      isApp: data.isApplication,
      appUrl: data.appUrl,
      appDeepLink: data.appDeepLink,
      productCategoryId: selectedCategory,
      rank: data.rank,
      isRecommended: recommended,
      isNewGameImage: isNewImage,
      isNewDesktopImage: isNewDesktopImage,
      isNewDesktopImageHover: isNewDesktopImageHover,
      parameter_1: data.param1,
      parameter_2: data.param2,
      parameter_3: data.param3,
      parameter_4: data.param4,
      parameter_5: data.param5,
      isNewGame: data.isNewGame,
    };

    /*if (!stringIsNullOrEmpty(parameter_1)) {
      params["parameter_1"] = parameter_1;
  }
    if (!stringIsNullOrEmpty(parameter_2)) {
        params["parameter_2"] = parameter_2;
  }
    if (!stringIsNullOrEmpty(parameter_3)) {
        params["parameter_3"] = parameter_3;
  }
    if (!stringIsNullOrEmpty(parameter_4)) {
        params["parameter_4"] = parameter_4;
  }
    if (!stringIsNullOrEmpty(parameter_5)) {
        params["parameter_5"] = parameter_5;
  }*/

    if (_location.state["isNew"]) {
      params["productId"] = _location.state["id"];
    }

    if (!stringIsNullOrEmpty(gameFile)) {
      params["gameImage"] = gameFile;
    }

    if (stringIsNullOrEmpty(gameImage)) {
      params["gameImageRemoved"] = true;
    }

    if (!stringIsNullOrEmpty(desktopFile)) {
      params["desktopImage"] = desktopFile;
    }

    if (stringIsNullOrEmpty(desktopImage)) {
      params["desktopImageRemoved"] = true;
    }

    if (!stringIsNullOrEmpty(desktopFileHover)) {
      params["desktopImageHover"] = desktopFileHover;
    }

    if (stringIsNullOrEmpty(desktopImageHover)) {
      params["desktopImageRemovedHover"] = true;
    }

    if (!stringIsNullOrEmpty(gameId)) {
      params["id"] = gameId;
      _dispatch(setSaveAdminLog("EDIT_BO_PRODUCT_GAME", _history.location.pathname, params));
    } else {
      _dispatch(setSaveAdminLog("CREATE_BO_PRODUCT_GAME", _history.location.pathname, params));
    }

    let formBody = createMultiPartFormBody(params);
    _dispatch(setBusy());

    fetch(ApiUrl._API_CREATE_OR_UPDATE_GAME_PRODUCT, {
      method: ApiKey._API_POST,
      headers: {
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
      body: formBody,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        _dispatch(
          showMessage(
            responseJson[ApiKey._API_SUCCESS_KEY],
            t(responseJson[ApiKey._API_MESSAGE_KEY])
          )
        );
        _dispatch(setIdle());

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          setIsNewImage(false);
          setIsNewDesktopImage(false);
          setIsNewDesktopImageHover(false);
          setGameId(responseJson[ApiKey._API_DATA_KEY]["id"]);
        }
      });
  };

  /// <summary>
  /// Author : -
  /// Editted : - (Add Category)
  /// </summary>
  useEffect(() => {
    register({ name: "gameImage" }, { required: "PLEASE_SELECT_IMAGE" });
    //register({ name: "desktopImage" }, { required: "PLEASE_SELECT_IMAGE" });
    register({ name: "category" }, { required: "PLEASE_SELECT_CATEGORY" });
    getProductCategory();
    if (_location.state != null && !_location.state["isNew"]) {
      fetch(
        ApiUrl._API_GET_PRODUCT_GAME_BY_ID + "?id=" + _location.state["id"],
        {
          method: ApiKey._API_GET,
          headers: {
            "Content-Type": ApiKey._API_FORM_URLENCODED,
            "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
          },
        }
      )
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setGameCode(responseJson[ApiKey._API_DATA_KEY]["gameCode"]);
            setGameName(responseJson[ApiKey._API_DATA_KEY]["gameName"]);
            setGameId(responseJson[ApiKey._API_DATA_KEY]["id"]);
            setStatus(responseJson[ApiKey._API_DATA_KEY]["status"]);
            setGameUm(responseJson[ApiKey._API_DATA_KEY]["gameUm"]);
            setHidden(responseJson[ApiKey._API_DATA_KEY]["hidden"]);
            setIsNewGame(responseJson[ApiKey._API_DATA_KEY]["isNewGame"]);
            setIsApp(responseJson[ApiKey._API_DATA_KEY]["isApp"]);
            setAppUrl(responseJson[ApiKey._API_DATA_KEY]["appUrl"]);
            setAppDeepLink(responseJson[ApiKey._API_DATA_KEY]["appDeepLink"]);
            setSelectedCategory(
              responseJson[ApiKey._API_DATA_KEY]["productCategoryId"]
            );
            setValue(
              "category",
              responseJson[ApiKey._API_DATA_KEY]["productCategoryId"]
            );
            setRecommended(responseJson[ApiKey._API_DATA_KEY]["isRecommended"]);
            setRank(responseJson[ApiKey._API_DATA_KEY]["rank"]);
            setParameter_1(responseJson[ApiKey._API_DATA_KEY]["parameter_1"]);
            setParameter_2(responseJson[ApiKey._API_DATA_KEY]["parameter_2"]);
            setParameter_3(responseJson[ApiKey._API_DATA_KEY]["parameter_3"]);
            setParameter_4(responseJson[ApiKey._API_DATA_KEY]["parameter_4"]);
            setParameter_5(responseJson[ApiKey._API_DATA_KEY]["parameter_5"]);

            if (
              !stringIsNullOrEmpty(responseJson[ApiKey._API_DATA_KEY]["image"])
            ) {
              setGameImage(responseJson[ApiKey._API_DATA_KEY]["image"]);
              setValue(
                "gameImage",
                responseJson[ApiKey._API_DATA_KEY]["image"]
              );
            }

            if (
              !stringIsNullOrEmpty(responseJson[ApiKey._API_DATA_KEY]["desktopImage"])
            ) {
              setDesktopImage(responseJson[ApiKey._API_DATA_KEY]["desktopImage"]);
              setValue(
                "desktopImage",
                responseJson[ApiKey._API_DATA_KEY]["desktopImage"]
              );
            }

            if (
              !stringIsNullOrEmpty(responseJson[ApiKey._API_DATA_KEY]["desktopImageHover"])
            ) {
              setDesktopImageHover(responseJson[ApiKey._API_DATA_KEY]["desktopImageHover"]);
              setValue(
                "desktopImageHover",
                responseJson[ApiKey._API_DATA_KEY]["desktopImageHover"]
              );
            }
          }
        });
    }
  }, []);

  /// <summary>
  /// Author : -
  /// </summary>
  const getProductCategory = async () => {
    await fetch(ApiUrl._API_GET_PRODUCT_CATEGORY, {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          const productCategorys = [];
          responseJson[ApiKey._API_DATA_KEY].forEach(function (
            productCategory
          ) {
            productCategorys.push({
              label: productCategory.title,
              value: productCategory.id,
            });
          });

          setCategoryList(productCategorys);
        }
      });
  };

  /// <summary>
  /// Author : -
  /// </summary>
  const logoDrop = (acceptedFiles) => {
    if (acceptedFiles.length !== 0) {
      const file = acceptedFiles[0];

      const reader = new FileReader();
      reader.onloadend = () => {
        setGameFile(file);
        setGameImage(reader.result);
        setValue("gameImage", reader.result);
        setIsNewImage(true);
      };
      reader.readAsDataURL(file);
    }
  };

  const desktopLogoDrop = (acceptedFiles) => {
    if (acceptedFiles.length !== 0) {
      const file = acceptedFiles[0];

      const reader = new FileReader();
      reader.onloadend = () => {
        setDesktopFile(file);
        setDesktopImage(reader.result);
        setValue("desktopImage", reader.result);
        setIsNewDesktopImage(true);
      };
      reader.readAsDataURL(file);
    }
  };

  const desktopLogoHoverDrop = (acceptedFiles) => {
    if (acceptedFiles.length !== 0) {
      const file = acceptedFiles[0];

      const reader = new FileReader();
      reader.onloadend = () => {
        setDesktopFileHover(file);
        setDesktopImageHover(reader.result);
        setValue("desktopImageHover", reader.result);
        setIsNewDesktopImageHover(true);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div>
      <h1 className="page-header">
        {t("GAME_DETAIL")}
        <NavigationButton history={_history} />
      </h1>
      <div className="row">
        <div className="col-xl-12">
          <Panel>
            <PanelBody>
              <form onSubmit={handleSubmit(submitForm)}>
                <div className="row">
                  <h3>{t("DESKTOP")}</h3>
                </div>
                <div className="row">
                  <div className="col-lg-8">
                    <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        <b>{t("DESKTOP_IMAGE")}</b>
                      </label>
                      <div className="col-md-7">
                        <Dropzone
                          accept={"image/*"}
                          onDrop={(acceptedFiles) =>
                            desktopLogoDrop(acceptedFiles, true)
                          }
                        >
                          {({ getRootProps, getInputProps }) => (
                            <section>
                              <div
                                className="dropzone"
                                style={{
                                  minHeight: "200px",
                                  textAlign: "center",
                                }}
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <h4 style={{ color: "grey" }}>
                                  {t(
                                    "DRAG_N_DROP_SOME_FILES_HERE_OR_CLICK_TO_SELECT_FILES"
                                  )}
                                </h4>
                                {!stringIsNullOrEmpty(desktopImage) && (
                                  <aside className="thumbsContainer">
                                    <div className="thumb">
                                      <div className="thumbInner">
                                        <img
                                          src={desktopImage}
                                          className="dropzone-img"
                                        />
                                      </div>
                                    </div>
                                  </aside>
                                )}
                              </div>
                            </section>
                          )}
                        </Dropzone>
                        {errors.desktopImage && (
                          <div className="invalid-feedback">
                            {t(errors.desktopImage.message)}
                          </div>
                        )}
                        <br />
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={() => {
                            setDesktopImage("");
                            setDesktopFile();
                          }}
                        >
                          {t("REMOVE_IMAGE")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-8">
                    <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        <b>{t("DESKTOP_IMAGE_HOVER")}</b>
                      </label>
                      <div className="col-md-7">
                        <Dropzone
                          accept={"image/*"}
                          onDrop={(acceptedFiles) =>
                            desktopLogoHoverDrop(acceptedFiles, true)
                          }
                        >
                          {({ getRootProps, getInputProps }) => (
                            <section>
                              <div
                                className="dropzone"
                                style={{
                                  minHeight: "200px",
                                  textAlign: "center",
                                }}
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <h4 style={{ color: "grey" }}>
                                  {t(
                                    "DRAG_N_DROP_SOME_FILES_HERE_OR_CLICK_TO_SELECT_FILES"
                                  )}
                                </h4>
                                {!stringIsNullOrEmpty(desktopImageHover) && (
                                  <aside className="thumbsContainer">
                                    <div className="thumb">
                                      <div className="thumbInner">
                                        <img
                                          src={desktopImageHover}
                                          className="dropzone-img"
                                        />
                                      </div>
                                    </div>
                                  </aside>
                                )}
                              </div>
                            </section>
                          )}
                        </Dropzone>
                        {errors.desktopImageHover && (
                          <div className="invalid-feedback">
                            {t(errors.desktopImageHover.message)}
                          </div>
                        )}
                        <br />
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={() => {
                            setDesktopImageHover("");
                            setDesktopFileHover();
                          }}
                        >
                          {t("REMOVE_IMAGE")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <h3>{t("WEB_APP") + " / " + t("MOBILE_APP")}</h3>
                </div>
                <div className="row">
                  <div className="col-lg-8">
                    <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        <b>{t("GAME_IMAGE")}</b>
                      </label>
                      <div className="col-md-7">
                        <Dropzone
                          accept={"image/*"}
                          onDrop={(acceptedFiles) =>
                            logoDrop(acceptedFiles, true)
                          }
                        >
                          {({ getRootProps, getInputProps }) => (
                            <section>
                              <div
                                className="dropzone"
                                style={{
                                  minHeight: "200px",
                                  textAlign: "center",
                                }}
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <h4 style={{ color: "grey" }}>
                                  {t(
                                    "DRAG_N_DROP_SOME_FILES_HERE_OR_CLICK_TO_SELECT_FILES"
                                  )}
                                </h4>
                                {!stringIsNullOrEmpty(gameImage) && (
                                  <aside className="thumbsContainer">
                                    <div className="thumb">
                                      <div className="thumbInner">
                                        <img
                                          src={gameImage}
                                          className="dropzone-img"
                                        />
                                      </div>
                                    </div>
                                  </aside>
                                )}
                              </div>
                            </section>
                          )}
                        </Dropzone>
                        {errors.gameImage && (
                          <div className="invalid-feedback">
                            {t(errors.gameImage.message)}
                          </div>
                        )}
                        <br />
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={() => {
                            setGameImage("");
                            setGameFile();
                          }}
                        >
                          {t("REMOVE_IMAGE")}
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-8">
                    <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        <b>{t("GAME_NAME")}</b>
                      </label>
                      <div className="col-md-7">
                        <input
                          type="text"
                          className="form-control"
                          placeholder={t("PLEASE_ENTER_GAME_NAME")}
                          name="name"
                          ref={register({ required: "PLEASE_ENTER_GAME_NAME" })}
                          defaultValue={gameName}
                        />
                        {errors.name && (
                          <div className="invalid-feedback">
                            {t(errors.name.message)}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-8">
                    <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        <b>{t("GAME_CODE")}</b>
                      </label>
                      <div className="col-md-7">
                        <input
                          type="text"
                          className="form-control"
                          placeholder={t("PLEASE_ENTER_GAME_CODE")}
                          name="code"
                          ref={register({ required: "PLEASE_ENTER_GAME_CODE" })}
                          defaultValue={gameCode}
                        />
                        {errors.code && (
                          <div className="invalid-feedback">
                            {t(errors.code.message)}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-8">
                    <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        <b>{t("CATEGORY")}</b>
                      </label>
                      <div className="col-md-7">
                        <Select
                          name="category"
                          options={categoryList}
                          placeholder={
                            categoryList.filter(
                              (option) => option.value == selectedCategory
                            )[0] !== undefined
                              ? categoryList.filter(
                                (option) => option.value == selectedCategory
                              )[0].label
                              : ""
                          }
                          value={categoryList.filter(
                            (option) => option.value == selectedCategory
                          )}
                          onChange={(e) => {
                            unregister("category");
                            setSelectedCategory(e.value);
                          }}
                        />
                        {errors.category && (
                          <div className="invalid-feedback">
                            {t(errors.category.message)}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        <b>{t("RANK")}</b>
                      </label>
                      <div className="col-md-7 col-form-label">
                        <input
                          type="number"
                          className="form-control"
                          placeholder={t("PLEASE_ENTER_RANK")}
                          name="rank"
                          defaultValue={rank}
                          ref={register({
                            required: "PLEASE_ENTER_RANK",
                            min: {
                              value: 0,
                              message: "PLEASE_ENTER_VALUE_NOT_LESS_THAN_ZERO",
                            },
                          })}
                        />
                        {errors.rank && (
                          <div className="invalid-feedback">
                            {t(errors.rank.message)}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        <b>{t("Parameter_1_(Optional)")}</b>
                      </label>
                      <div className="col-md-7">
                        <input
                          type="text"
                          className="form-control"
                          placeholder={t("PLEASE_ENTER_PORTFOLIO")}
                          name="param1"
                          defaultValue={parameter_1}
                          ref={register()}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        <b>{t("Parameter_2_(Optional)")}</b>
                      </label>
                      <div className="col-md-7">
                        <input
                          type="text"
                          className="form-control"
                          placeholder={t("PLEASE_ENTER_GPID")}
                          name="param2"
                          defaultValue={parameter_2}
                          ref={register()}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        <b>{t("Parameter_3_(Optional)")}</b>
                      </label>
                      <div className="col-md-7">
                        <input
                          type="text"
                          className="form-control"
                          placeholder={t("PLEASE_ENTER_PRODUCT_TYPE")}
                          name="param3"
                          defaultValue={parameter_3}
                          ref={register()}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        <b>{t("Parameter_4_(Optional)")}</b>
                      </label>
                      <div className="col-md-7">
                        <input
                          type="text"
                          className="form-control"
                          placeholder={t("PLEASE_ENTER_GAME_TYPE")}
                          name="param4"
                          defaultValue={parameter_4}
                          ref={register()}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        <b>{t("Parameter_5_(Optional)")}</b>
                      </label>
                      <div className="col-md-7">
                        <input
                          type="text"
                          className="form-control"
                          placeholder={t("PLEASE_ENTER_GAME_ID")}
                          name="param5"
                          defaultValue={parameter_5}
                          ref={register()}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        <b>{t("RECOMMEND")}</b>
                      </label>
                      <div className="col-md-7 col-form-label">
                        <div className="switcher">
                          <input
                            type="checkbox"
                            name="recommended"
                            id="recommended"
                            onChange={(e) => setRecommended(e.target.checked)}
                            value={false}
                            checked={recommended}
                            ref={register}
                          />
                          <label htmlFor="recommended"></label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        <b>{t("STATUS")}</b>
                      </label>
                      <div className="col-md-7 col-form-label">
                        <div className="switcher">
                          <input
                            type="checkbox"
                            name="status"
                            id="status"
                            onChange={(e) => setStatus(e.target.checked)}
                            value={true}
                            checked={status}
                            ref={register}
                          />
                          <label htmlFor="status"></label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        <b>{t("GAME_UM")}</b>
                      </label>
                      <div className="col-md-7 col-form-label">
                        <div className="switcher">
                          <input
                            type="checkbox"
                            name="gameUm"
                            id="gameUm"
                            onChange={(e) => setGameUm(e.target.checked)}
                            value={true}
                            checked={gameUm}
                            ref={register}
                          />
                          <label htmlFor="gameUm"></label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        <b>{t("Hidden")}</b>
                      </label>
                      <div className="col-md-7 col-form-label">
                        <div className="switcher">
                          <input
                            type="checkbox"
                            name="hiddenGame"
                            id="hiddenGame"
                            onChange={(e) => setHidden(e.target.checked)}
                            value={true}
                            checked={hidden}
                            ref={register}
                          />
                          <label htmlFor="hiddenGame"></label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        <b>{t("IsNewGame")}</b>
                      </label>
                      <div className="col-md-7 col-form-label">
                        <div className="switcher">
                          <input
                            type="checkbox"
                            name="isNewGame"
                            id="isNewGame"
                            onChange={(e) => setIsNewGame(e.target.checked)}
                            value={true}
                            checked={isNewGame}
                            ref={register}
                          />
                          <label htmlFor="isNewGame"></label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        <b>{t("IS_APP")}</b>
                      </label>
                      <div className="col-md-7 col-form-label">
                        <div className="switcher">
                          <input
                            type="checkbox"
                            name="isApplication"
                            id="isApplication"
                            onChange={(e) => setIsApp(e.target.checked)}
                            value={true}
                            checked={isApp}
                            ref={register}
                          />
                          <label htmlFor="isApplication"></label>
                        </div>
                      </div>
                    </div>
                  </div>
                  {isApp && <div className="col-lg-8">
                    <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        <b>{t("APP_URL")}</b>
                      </label>
                      <div className="col-md-7">
                        <input
                          type="text"
                          className="form-control"
                          placeholder={t("PLEASE_ENTER_APP_URL")}
                          name="appUrl"
                          defaultValue={appUrl}
                          ref={register()}
                        />
                      </div>
                    </div>
                  </div>}
                  {isApp && <div className="col-lg-8">
                    <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        <b>{t("IS_DEEPLINK")}</b>
                      </label>
                      <div className="col-md-7">
                        <input
                          type="text"
                          className="form-control"
                          placeholder={t("PLEASE_ENTER_APP_URL")}
                          name="appDeepLink"
                          defaultValue={appDeepLink}
                          ref={register()}
                        />
                      </div>
                    </div>
                  </div>}
                </div>
                <hr />
                <div className="row">
                  <div className="col-lg-3">
                    <div className="form-group">
                      <button type="submit" className="btn btn-primary">
                        {t("SUBMIT")}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </PanelBody>
          </Panel>
        </div>
      </div>
    </div>
  );
};

export default GameDetail;