import React, { useState, useRef, useEffect, useCallback } from "react";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { showMessage } from "../../redux/AppAction";
import { useDispatch, useSelector } from "react-redux";
import {
  ApiKey,
  ApiUrl,
  WebUrl,
  LanguageKey,
  _TICKET_STATUS,
  DEFAULT_PAGE_SIZE,
  Role,
} from "../../util/Constant";
import {
  stringIsNullOrEmpty,
  isObjectEmpty,
  numberWithCurrencyFormat,
} from "../../util/Util";
import { useTranslation } from "react-i18next";
import ApiEngine from "../../util/ApiEngine.js";
import Select from "react-select";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import classnames from "classnames";
import { AsyncPaginate } from "react-select-async-paginate";
import { CSVLink } from "react-csv";

/// <summary>
/// Author : -
/// </summary>

const AdminOperationLogReport = () => {
  let _dispatch = useDispatch();
  const { t } = useTranslation(LanguageKey._PRIMARY);
  const [userList, setUserList] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [roleList, setRoleList] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");
  const [reportData, setReportData] = useState([]);
  const [viewBody, setViewBody] = useState({});
  const [viewBodyModal, setViewBodyModal] = useState(false);
  const [startDate, setStartDate] = useState(
    moment().startOf("day").format("YYYY-MM-DD HH:mm:ss")
  );
  const [endDate, setEndDate] = useState(
    moment().endOf("day").format("YYYY-MM-DD HH:mm:ss")
  );

  /// <summary>
  /// Author : -
  /// </summary>
  const _OPTION_STYLES = {
    control: (base) => ({
      ...base,
      "min-height": "34px",
      height: "34px",
    }),
    valueContainer: (base) => ({
      ...base,
      height: "34px",
      "min-height": "34px",
    }),
    indicatorsContainer: (base) => ({
      ...base,
      height: "34px",
      "min-height": "34px",
    }),
    input: (base) => ({
      height: "34px",
      "min-height": "34px",
    }),
  };

  /// <summary>
  /// Author : -
  /// </summary>
  const handleEvent = (event, picker) => {
    setStartDate(moment(picker.startDate).format("YYYY-MM-DD HH:mm:ss"));
    setEndDate(moment(picker.endDate).format("YYYY-MM-DD HH:mm:ss"));
  };

  useEffect(() => {
    readReport();
  },[]);

  /// <summary>
  /// Author : -
  /// </summary>
  async function readReport() {
    try {
      var apiUrl =
        ApiUrl._API_GET_ADMIN_OPERATION_LOG_REPORT +
        "?startDate=" +
        startDate +
        "&endDate=" +
        endDate 

      if (!stringIsNullOrEmpty(selectedUser)) {
        apiUrl += "&userId=" + selectedUser;
      }

      if (!stringIsNullOrEmpty(selectedRole)) {
        apiUrl += "&roleId=" + selectedRole;
      }

      var responseJson = await ApiEngine.get(apiUrl);

      if (responseJson[ApiKey._API_SUCCESS_KEY])
      {
        setReportData(responseJson[ApiKey._API_DATA_KEY]);
      }
      else
      {
        throw responseJson[ApiKey._API_MESSAGE_KEY];
      }

    } catch (error) {
      _dispatch(showMessage(false, t(error)));
    }
  }


  let _tableColumns = [
    {
      Header: "USERNAME",
      accessor: "username",
    },
    {
      Header: "ROLENAME",
      accessor: "roleName",
    },
    {
      Header: "IPADDRESS",
      accessor: "ipAddress",
    },
    {
      Header: "URLPATH",
      accessor: "urlPath",
    },
    {
      Header: "ACTION",
      accessor: "actionKey",
    },
    {
      Header: "BODY",
      accessor: "body",
      Cell: ({ row }) => {

        if (row.original.body != "{\"body\":\"null\"}") {
          return (
            <span>
              <label style={{ cursor: 'pointer' }} onClick={() => { setViewBody(JSON.parse(row.original.body)); setViewBodyModal(true); }}>View Body</label>
            </span>
          )
        } else {
          return (
            <></>
          )
        }
      }
    },
    {
      Header: "CREATEDTIME",
      accessor: "createdTime",
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <label>{moment(row.original.createdTime).format("YYYY-MM-DD HH:mm:ss")}</label>
        )
      }
    }
  ];

  function PrettyPrint(props) {
    return <pre style={{ fontSize:'13px' }}>{JSON.stringify(props.jsonObj, null, 2)}</pre>
  }

  useEffect(() => {
    init();
  }, []);

  async function init() {
    await fetch(ApiUrl._API_GET_ALL_USER_AND_ROLE_LIST, {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            const roleOptionList = [];
            const userOptionList = [];
            responseJson[ApiKey._API_DATA_KEY]["roleList"].forEach(function (option) {
              roleOptionList.push({
                label: option.roleName,
                value: option.id,
              });
            });

            responseJson[ApiKey._API_DATA_KEY]["userList"].forEach(function (option) {
              userOptionList.push({
                label: option.username,
                value: option.id,
              });
            });
            setUserList(userOptionList);
            setRoleList(roleOptionList);
          }
        } else {
          _dispatch(
            showMessage(
              responseJson[ApiKey._API_SUCCESS_KEY],
              t(responseJson[ApiKey._API_MESSAGE_KEY])
            )
          );
        }
      });
  }


  return (
    <div>
      <h1 className="page-header">
        {t("ADMIN_OPERATION_LOG_REPORT")}
      </h1>
      <Panel>
        <PanelBody>
          <div className="row">
            <div className="col-lg-3">
              <div className="form-group">
                <label>
                  <b>{t("DATE")}</b>
                </label>
                <div
                  className="input-group"
                  style={{ width: "100%", flexFlow: "nowrap" }}
                >
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fa fa-calendar"></i>
                    </span>
                  </div>
                  <div style={{ width: "80%" }}>
                    <DateRangePicker
                      containerStyles={{ width: "100%" }}
                      startDate={startDate}
                      endDate={endDate}
                      onApply={handleEvent}
                      alwaysShowCalendars={true}
                      locale={{ format: "YYYY-MM-DD HH:mm:ss" }}
                      timePicker={true}
                      timePickerSeconds={true}
                      ranges={{
                        Today: [moment().startOf("day"), moment().endOf("day")],
                        Yesterday: [
                          moment().subtract(1, "days").startOf("day"),
                          moment().subtract(1, "days").endOf("day"),
                        ],
                        "Last 7 Days": [
                          moment().subtract(6, "days").startOf("day"),
                          moment().endOf("day"),
                        ],
                        "Last 30 Days": [
                          moment().subtract(29, "days").startOf("day"),
                          moment().endOf("day"),
                        ],
                        "This Month": [
                          moment().startOf("month"),
                          moment().endOf("month"),
                        ],
                        "Last Month": [
                          moment().subtract(1, "month").startOf("month"),
                          moment().subtract(1, "month").endOf("month"),
                        ],
                        "This Year": [
                          moment().startOf("year"),
                          moment().endOf("year"),
                        ],
                      }}
                    >
                      <input
                        type="text"
                        className="form-control"
                        value={startDate + " - " + endDate}
                      />
                    </DateRangePicker>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-group">
                <label>
                  <b>{t("USERNAME")}</b>
                </label>
                <Select
                  styles={_OPTION_STYLES}
                  value={userList.filter(
                    (user) => user.value == selectedUser
                  )}
                  options={userList}
                  onChange={(e) => {
                    setSelectedUser(e.value);
                  }}
                />
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-group">
                <label>
                  <b>{t("ROLE_NAME")}</b>
                </label>
                <Select
                  styles={_OPTION_STYLES}
                  value={roleList.filter(
                    (role) => role.value == selectedRole
                  )}
                  options={roleList}
                  onChange={(e) => {
                    setSelectedRole(e.value);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-2">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  readReport();
                }}
              >
                <i className="fas fa-search"></i> {t("SEARCH")}
              </button>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-lg-12">
              <ReactTable data={reportData} columns={_tableColumns} renderFooter={true} />
            </div>
          </div>
        </PanelBody>
      </Panel>
      <Modal
        isOpen={viewBodyModal}
        toggle={() => {
          setViewBodyModal(!viewBodyModal);
        }}
        centered={true}
      >
        <ModalBody>
          <div>
            <PrettyPrint jsonObj={viewBody} />
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default AdminOperationLogReport;
