import React, { useState, useEffect } from "react";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import Select from "react-select";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ApiKey, ApiUrl, LanguageKey } from "../../util/Constant";
import { useForm } from "react-hook-form";
import { stringIsNullOrEmpty, createFormBody } from "../../util/Util";
import { setBusy, setIdle, showMessage } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";

/// <summary>
/// Author : -
/// </summary>

const VipCriteriaDetail = (props) => {
  const { t } = useTranslation(LanguageKey._PRIMARY);
  const _dispatch = useDispatch();
  const {
    register,
    unregister,
    handleSubmit,
    errors,
    setValue,
    getValues,
    setError,
    clearErrors,
    trigger,
  } = useForm();
  const [vipId, setVipId] = useState();
  const [vipCriteriaId, setVipCriteriaId] = useState();
  const [isPrivate, setIsPrivate] = useState(false);
  const [isDefault, setIsDefault] = useState(false);
  const [vipObtainField, setVipObtainField] = useState();
  const [vipMaintainField, setVipMaintainField] = useState();
  const [depositTime, setDepositTime] = useState(0);
  const [minStake, setMinStake] = useState(0);
  const [withinDay, setWithinDay] = useState(0);
  const [minMaintainStake, setMinMaintainStake] = useState(0);
  const [everyDay, setEveryDay] = useState(0);
  const [isDowngradable, setIsDowngradable] = useState(true);
  const [vipOption, setVipOption] = useState([]);
  const [downgradeId, setDowngradeId] = useState();
  const [upgradeId, setUpgradeId] = useState();
  const [initCheck, setInitCheck] = useState(false);
  const [content, setContent] = useState("");

  const _DEFAULT_FIELD = 1;
  const _DEPOSIT_FIELD = 2;
  const _MIN_OBTAIN_FIELD = 3;
  const _PRIVATE_FIELD = 4;
  const _MIN_MAINTAIN_FIELD = 5;
  const _DOWNGRADABLE_FIELD = 6;

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    register({ name: "content" }, { required: "PLEASE_ENTER_CONTENT" });
    register(
      { name: "downgradeId" },
      { required: "PLEASE_SELECT_DOWNGRADE_TARGET" }
    );
    register(
      { name: "upgradeId" },
      { required: "PLEASE_SELECT_UPGRADE_TARGET" }
    );
    register(
      { name: "obtainCriteria" },
      {
        validate: {
          empty: (value) =>
            !stringIsNullOrEmpty(vipObtainField) ||
            "PLEASE_CHOOSE_ONE_OF_BELOW",
        },
      }
    );
    register(
      { name: "maintainCriteria" },
      {
        validate: {
          empty: (value) =>
            !stringIsNullOrEmpty(vipMaintainField) ||
            "PLEASE_CHOOSE_ONE_OF_BELOW",
        },
      }
    );
    init();
  }, []);

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    if (!stringIsNullOrEmpty(vipObtainField) && initCheck) {
      setIsDefault(false);
      setIsPrivate(false);
      setValue("minStake", 0);
      setValue("depositTime", 0);
      setValue("withinDay", 0);
      unregister("obtainCriteria");
      clearErrors();
    }
  }, [vipObtainField]);

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    if (!stringIsNullOrEmpty(vipMaintainField) && initCheck) {
      setIsDowngradable(true);
      unregister("maintainCriteria");
      setValue("minMaintainStake", 0);
      setValue("everyDay", 0);
      clearErrors();
    }
  }, [vipMaintainField]);

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    if (isDowngradable == false && initCheck) {
      setDowngradeId(props.id);
      setValue("downgradeId", props.id);
    }
  }, [isDowngradable]);

  /// <summary>
  /// Author : -
  /// </summary>
  async function init() {
    if (props.id) {
      setVipId(props.id);
      await fetch(ApiUrl._API_GET_VIP_CRITERIA_BY_VIP_ID + "?id=" + props.id, {
        method: ApiKey._API_GET,
        headers: {
          "Content-Type": ApiKey._API_FORM_URLENCODED,
          "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            let criteriaData = responseJson[ApiKey._API_DATA_KEY];

            setValue("displayBankDetails", props.displayBank);

            if (!stringIsNullOrEmpty(criteriaData)) {
              props.setParentCriteriaId(criteriaData.id);
              unregister("obtainCriteria");
              unregister("maintainCriteria");

              setDowngradeId(criteriaData.vipModel.downgradeTargetId);
              setUpgradeId(criteriaData.vipModel.upgradeTargetId);

              if (criteriaData.isDefault == true) {
                setVipObtainField(_DEFAULT_FIELD);
              } else if (criteriaData.isPrivate == true) {
                setVipObtainField(_PRIVATE_FIELD);
              } else if (criteriaData.depositCount > 0) {
                setVipObtainField(_DEPOSIT_FIELD);
              } else {
                setVipObtainField(_MIN_OBTAIN_FIELD);
              }

              if (criteriaData.isDowngrable == false) {
                setVipMaintainField(_DOWNGRADABLE_FIELD);
              } else {
                setVipMaintainField(_MIN_MAINTAIN_FIELD);
              }

              setVipCriteriaId(criteriaData.id);
              setIsDefault(criteriaData.isDefault);
              setDepositTime(criteriaData.depositCount);
              setMinStake(criteriaData.minObtainTurnover);
              setWithinDay(criteriaData.obtainWithinDayCount);
              setIsPrivate(criteriaData.isPrivateInvitation);
              setMinMaintainStake(criteriaData.minMaintainTurnover);
              setEveryDay(criteriaData.maintainEveryDayCount);
              setIsDowngradable(criteriaData.isDowngrable);
              setContent(criteriaData.content);
              setValue("depositTime", criteriaData.depositCount);
              setValue("minStake", criteriaData.minObtainTurnover);
              setValue("withinDay", criteriaData.obtainWithinDayCount);
              setValue("minMaintainStake", criteriaData.minMaintainTurnover);
              setValue("everyDay", criteriaData.maintainEveryDayCount);
              setValue("downgradeId", criteriaData.vipModel.downgradeTargetId);
              setValue("upgradeId", criteriaData.vipModel.upgradeTargetId);
            }
          }
        });
    }

    await fetch(ApiUrl._API_GET_USER_VIP, {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          const vipList = [];
          responseJson[ApiKey._API_DATA_KEY].forEach(function (vipLevel) {
            vipList.push({ label: vipLevel.name, value: vipLevel.id });
          });

          setVipOption(vipList);
        }
      });
    setInitCheck(!initCheck);
  }

  /// <summary>
  /// Author : -
  /// </summary>
  const submitForm = async (data, e) => {
    let params = {
      vipId: vipId,
      isDefault: data.isDefault != null ? data.isDefault : false,
      depositCount: !stringIsNullOrEmpty(data.depositTime)
        ? data.depositTime
        : 0,
      minObtainTurnover: !stringIsNullOrEmpty(data.minStake)
        ? data.minStake
        : 0,
      obtainWithinDayCount: !stringIsNullOrEmpty(data.withinDay)
        ? data.withinDay
        : 0,
      isPrivateInvitation: data.isPrivate != null ? data.isPrivate : false,
      minMaintainTurnover: !stringIsNullOrEmpty(data.minMaintainStake)
        ? data.minMaintainStake
        : 0,
      maintainEveryDayCount: !stringIsNullOrEmpty(data.everyDay)
        ? data.everyDay
        : 0,
      isDowngrable: data.isDowngradable != null ? data.isDowngradable : true,
      content: content,
      displayBankDetails: data.displayBankDetails,
    };

    if (!stringIsNullOrEmpty(downgradeId)) {
      params["downgradeTargetId"] = downgradeId;
    }

    if (!stringIsNullOrEmpty(upgradeId)) {
      params["upgradeTargetId"] = upgradeId;
    }

    if (!stringIsNullOrEmpty(vipCriteriaId)) {
      params["id"] = vipCriteriaId;
    }

    let formBody = createFormBody(params);
    _dispatch(setBusy());

    fetch(ApiUrl._API_CREATE_OR_UPDATE_VIP_CRITERIA, {
      method: ApiKey._API_POST,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
      body: formBody,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        _dispatch(setIdle());
        _dispatch(
          showMessage(
            responseJson[ApiKey._API_SUCCESS_KEY],
            t(responseJson[ApiKey._API_MESSAGE_KEY])
          )
        );

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          setVipCriteriaId(responseJson[ApiKey._API_DATA_KEY]["id"]);
          props.setParentCriteriaId(responseJson[ApiKey._API_DATA_KEY]["id"]);
        }
      });
  };

  return (
    <div>
      <div className="row">
        <div className="col-xl-12">
          <Panel>
            <PanelBody>
              <form onSubmit={handleSubmit(submitForm)}>
                <h3>
                  {t("OBTAIN_CRITERIA")} ({t("PLEASE_CHOOSE_ONE_OF_BELOW")})
                </h3>
                <div>
                  {errors.obtainCriteria && (
                    <div className="invalid-feedback">
                      {t(errors.obtainCriteria.message)}
                    </div>
                  )}
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label style={{ display: "flex" }}>
                        <div className="form-group form-check">
                          <input
                            type="checkbox"
                            style={{ marginTop: "0.2rem" }}
                            className="form-check-input"
                            onChange={() => setVipObtainField(_DEFAULT_FIELD)}
                            checked={vipObtainField == _DEFAULT_FIELD}
                          />
                        </div>
                        <b>{t("DEFAULT")}</b>
                      </label>
                      {vipObtainField == _DEFAULT_FIELD && (
                        <div
                          className="switcher"
                          style={{
                            pointerEvents:
                              vipObtainField != _DEFAULT_FIELD ? "none" : "",
                          }}
                        >
                          <input
                            type="checkbox"
                            name="isDefault"
                            id="isDefault"
                            onChange={(e) => setIsDefault(e.target.checked)}
                            checked={isDefault}
                            ref={register({
                              validate: {
                                checked: (value) =>
                                  value == true || "PLEASE_SWITCH_ON",
                              },
                            })}
                          />
                          <label htmlFor="isDefault"></label>
                        </div>
                      )}
                      {errors.isDefault && (
                        <div className="invalid-feedback">
                          {t(errors.isDefault.message)}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <div className="form-group">
                        <label style={{ display: "flex" }}>
                          <div className="form-group form-check">
                            <input
                              type="checkbox"
                              style={{ marginTop: "0.2rem" }}
                              className="form-check-input"
                              onChange={() => setVipObtainField(_DEPOSIT_FIELD)}
                              checked={vipObtainField == _DEPOSIT_FIELD}
                            />
                          </div>
                          <b>{t("TIME_OF_DEPOSIT")}</b>
                        </label>
                        {vipObtainField == _DEPOSIT_FIELD && (
                          <input
                            style={{
                              pointerEvents:
                                vipObtainField != _DEPOSIT_FIELD ? "none" : "",
                            }}
                            type="number"
                            className="form-control"
                            placeholder={t("PLEASE_ENTER_TIME")}
                            name="depositTime"
                            defaultValue={depositTime}
                            ref={register({
                              validate: {
                                required: (value) =>
                                  !stringIsNullOrEmpty(value) ||
                                  "PLEASE_ENTER_AMOUNT",
                                moreThanZero: (value) =>
                                  value > 0 ||
                                  "PLEASE_ENTER_VALUE_MORE_THAN_ZERO",
                              },
                            })}
                          />
                        )}
                        {errors.depositTime && (
                          <div className="invalid-feedback">
                            {t(errors.depositTime.message)}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label style={{ display: "flex" }}>
                        <div className="form-group form-check">
                          <input
                            type="checkbox"
                            style={{ marginTop: "0.2rem" }}
                            className="form-check-input"
                            onChange={() =>
                              setVipObtainField(_MIN_OBTAIN_FIELD)
                            }
                            checked={vipObtainField == _MIN_OBTAIN_FIELD}
                          />
                        </div>
                        <b>{t("MINIMUM_STAKE_TURNOVER")}</b>
                      </label>
                      {vipObtainField == _MIN_OBTAIN_FIELD && (
                        <div
                          style={{
                            display: "flex",
                            pointerEvents:
                              vipObtainField != _MIN_OBTAIN_FIELD ? "none" : "",
                          }}
                        >
                          <input
                            type="number"
                            className="form-control"
                            placeholder={t("PLEASE_ENTER_AMOUNT")}
                            name="minStake"
                            defaultValue={minStake}
                            ref={register({
                              validate: {
                                required: (value) =>
                                  !stringIsNullOrEmpty(value) ||
                                  "PLEASE_ENTER_AMOUNT_FOR_MIN_STAKE",
                                moreThanZero: (value) =>
                                  value > 0 ||
                                  "PLEASE_ENTER_VALUE_MORE_THAN_ZERO_FOR_MIN_STAKE",
                              },
                            })}
                          />
                          <span style={{ margin: "0.5rem" }}>
                            <b>{t("WITHIN")}</b>
                          </span>
                          <input
                            type="number"
                            className="form-control"
                            placeholder={t("PLEASE_ENTER_AMOUNT")}
                            name="withinDay"
                            defaultValue={withinDay}
                            ref={register({
                              validate: {
                                required: (value) =>
                                  !stringIsNullOrEmpty(value) ||
                                  "PLEASE_ENTER_AMOUNT_FOR_DAY",
                                moreThanZero: (value) =>
                                  value > 0 ||
                                  "PLEASE_ENTER_VALUE_MORE_THAN_ZERO_FOR_DAY",
                              },
                            })}
                          />
                          <span
                            style={{ margin: "0.5rem", whiteSpace: "nowrap" }}
                          >
                            <b>{t("WITHIN_DAYS")}</b>
                          </span>
                        </div>
                      )}
                      {errors.minStake && (
                        <div className="invalid-feedback">
                          {t(errors.minStake.message)}
                        </div>
                      )}
                      {errors.withinDay && (
                        <div className="invalid-feedback">
                          {t(errors.withinDay.message)}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label style={{ display: "flex" }}>
                        <div className="form-group form-check">
                          <input
                            type="checkbox"
                            style={{ marginTop: "0.2rem" }}
                            className="form-check-input"
                            onChange={() => setVipObtainField(_PRIVATE_FIELD)}
                            checked={vipObtainField == _PRIVATE_FIELD}
                          />
                        </div>
                        <b>{t("PRIVATE_INVITATION")}</b>
                      </label>
                      {vipObtainField == _PRIVATE_FIELD && (
                        <div
                          className="switcher"
                          style={{
                            pointerEvents:
                              vipObtainField != _PRIVATE_FIELD ? "none" : "",
                          }}
                        >
                          <input
                            type="checkbox"
                            name="isPrivate"
                            id="isPrivate"
                            onChange={(e) => setIsPrivate(e.target.checked)}
                            checked={isPrivate}
                            ref={register({
                              validate: {
                                checked: (value) =>
                                  value == true || "PLEASE_SWITCH_ON",
                              },
                            })}
                          />
                          <label htmlFor="isPrivate"></label>
                        </div>
                      )}
                      {errors.isPrivate && (
                        <div className="invalid-feedback">
                          {t(errors.isPrivate.message)}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <hr />
                <h3>
                  {t("MAINTAIN_CRITERIA")} ({t("PLEASE_CHOOSE_ONE_OF_BELOW")})
                </h3>
                <div>
                  {errors.maintainCriteria && (
                    <div className="invalid-feedback">
                      {t(errors.maintainCriteria.message)}
                    </div>
                  )}
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label style={{ display: "flex" }}>
                        <div className="form-group form-check">
                          <input
                            type="checkbox"
                            style={{ marginTop: "0.2rem" }}
                            className="form-check-input"
                            onChange={() =>
                              setVipMaintainField(_MIN_MAINTAIN_FIELD)
                            }
                            checked={vipMaintainField == _MIN_MAINTAIN_FIELD}
                          />
                        </div>
                        <b>{t("MINIMUM_STAKE_TURNOVER")}</b>
                      </label>
                      {vipMaintainField == _MIN_MAINTAIN_FIELD && (
                        <div
                          style={{
                            display: "flex",
                            pointerEvents:
                              vipMaintainField != _MIN_MAINTAIN_FIELD
                                ? "none"
                                : "",
                          }}
                        >
                          <input
                            type="number"
                            className="form-control"
                            placeholder={t("PLEASE_ENTER_AMOUNT")}
                            name="minMaintainStake"
                            defaultValue={minMaintainStake}
                            ref={register({
                              validate: {
                                required: (value) =>
                                  !stringIsNullOrEmpty(value) ||
                                  "PLEASE_ENTER_AMOUNT_FOR_MIN_STAKE",
                                moreThanZero: (value) =>
                                  value > 0 ||
                                  "PLEASE_ENTER_VALUE_MORE_THAN_ZERO_FOR_MIN_STAKE",
                              },
                            })}
                          />

                          <span style={{ margin: "0.5rem" }}>
                            <b>{t("EVERY")}</b>
                          </span>
                          <input
                            type="number"
                            className="form-control"
                            placeholder={t("PLEASE_ENTER_AMOUNT")}
                            name="everyDay"
                            defaultValue={everyDay}
                            ref={register({
                              validate: {
                                required: (value) =>
                                  !stringIsNullOrEmpty(value) ||
                                  "PLEASE_ENTER_AMOUNT_FOR_DAY",
                                moreThanZero: (value) =>
                                  value > 0 ||
                                  "PLEASE_ENTER_VALUE_MORE_THAN_ZERO_FOR_DAY",
                              },
                            })}
                          />
                          <span
                            style={{ margin: "0.5rem", whiteSpace: "nowrap" }}
                          >
                            <b>{t("DAYS")}</b>
                          </span>
                        </div>
                      )}
                      {errors.minMaintainStake && (
                        <div className="invalid-feedback">
                          {t(errors.minMaintainStake.message)}
                        </div>
                      )}
                      {errors.everyDay && (
                        <div className="invalid-feedback">
                          {t(errors.everyDay.message)}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label style={{ display: "flex" }}>
                        <div className="form-group form-check">
                          <input
                            type="checkbox"
                            style={{ marginTop: "0.2rem" }}
                            className="form-check-input"
                            onChange={() =>
                              setVipMaintainField(_DOWNGRADABLE_FIELD)
                            }
                            checked={vipMaintainField == _DOWNGRADABLE_FIELD}
                          />
                        </div>
                        <b>
                          {t("DOWNGRADABLE")} ({t("BY_DEFAULT_ON")})
                        </b>
                      </label>
                      {vipMaintainField == _DOWNGRADABLE_FIELD && (
                        <div
                          className="switcher"
                          style={{
                            pointerEvents:
                              vipMaintainField != _DOWNGRADABLE_FIELD
                                ? "none"
                                : "",
                          }}
                        >
                          <input
                            type="checkbox"
                            name="isDowngradable"
                            id="isDowngradable"
                            onChange={(e) =>
                              setIsDowngradable(e.target.checked)
                            }
                            checked={isDowngradable}
                            ref={register({
                              validate: {
                                checked: (value) =>
                                  value == false || "PLEASE_SWITCH_OFF",
                              },
                            })}
                          />
                          <label htmlFor="isDowngradable"></label>
                        </div>
                      )}
                      {errors.isDowngradable && (
                        <div className="invalid-feedback">
                          {t(errors.isDowngradable.message)}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <hr />
                <h3>{t("OTHERS")}</h3>
                <div className="row">
                  <div className="col-lg-6">
                    <label className="d-flex">
                      <div className="form-group form-check">
                        <input
                          type="checkbox"
                          style={{ marginTop: "0.2rem" }}
                          className="form-check-input"
                          defaultChecked={
                            getValues("displayBankDetails") != false
                          }
                          name="displayBankDetails"
                          id="displayBankDetails"
                          ref={register()}
                        />
                      </div>
                      <b>{t("DISPLAY_BANK_DETAILS")}</b>
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>
                        <b>
                          {t("DOWNGRADE_TARGET")} (
                          {t("PLEASE_SELECT_CURRENT_VIP_IF_NO_TARGET")})
                        </b>
                      </label>
                      <Select
                        name="downgradeId"
                        options={vipOption}
                        placeholder={
                          vipOption.filter(
                            (option) => option.value === downgradeId
                          )[0] !== undefined
                            ? vipOption.filter(
                                (option) => option.value === downgradeId
                              )[0].label
                            : ""
                        }
                        value={vipOption.filter(
                          (option) => option.value === downgradeId
                        )}
                        isDisabled={!isDowngradable}
                        onChange={(e) => {
                          setDowngradeId(e.value);
                          setValue("downgradeId", e.value);
                          trigger();
                        }}
                      />
                      {errors.downgradeId && (
                        <div className="invalid-feedback">
                          {t(errors.downgradeId.message)}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>
                        <b>
                          {t("UPGRADE_TARGET")} (
                          {t("PLEASE_SELECT_CURRENT_VIP_IF_NO_TARGET")})
                        </b>
                      </label>
                      <Select
                        name="upgradeId"
                        options={vipOption}
                        placeholder={
                          vipOption.filter(
                            (option) => option.value === upgradeId
                          )[0] !== undefined
                            ? vipOption.filter(
                                (option) => option.value === upgradeId
                              )[0].label
                            : ""
                        }
                        value={vipOption.filter(
                          (option) => option.value === upgradeId
                        )}
                        onChange={(e) => {
                          setUpgradeId(e.value);
                          setValue("upgradeId", e.value);
                          trigger();
                        }}
                      />
                      {errors.upgradeId && (
                        <div className="invalid-feedback">
                          {t(errors.upgradeId.message)}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <label>
                      <b>{t("DISPLAY_CONTENT")}</b>
                    </label>
                    <CKEditor
                      editor={ClassicEditor}
                      name="content"
                      data={content}
                      onInit={(editor) => {
                        editor.setData(content);
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setContent(data);
                        setValue("content", data);
                        trigger("content");
                      }}
                    />
                    {errors.content && (
                      <div className="invalid-feedback">
                        {t(errors.content.message)}
                      </div>
                    )}
                  </div>
                </div>
                <br />
                <div className="row">
                  <div className="col-lg-3">
                    <div className="form-group">
                      <button type="submit" className="btn btn-primary">
                        {t("SUBMIT")}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </PanelBody>
          </Panel>
        </div>
      </div>
    </div>
  );
};

export default VipCriteriaDetail;
