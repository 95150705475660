import React, { useState, useEffect } from "react";
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from "react-i18next";
import { ApiKey, ApiUrl, AccessRight, WebUrl, LanguageKey } from "../../util/Constant";
import { createFormBody } from "../../util/Util";
import { setBusy, setIdle, showMessage } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import { useForm } from "react-hook-form";
import { stringIsNullOrEmpty, isObjectEmpty, numberWithCurrencyFormat } from "../../util/Util";
import ApiEngine from "../../util/ApiEngine";

/// <summary>
/// Author : -
/// </summary>
const WalletBalance = props => {
    var { t } = useTranslation();
    const [walletDetails, setWalletDetails] = useState(false);
    const _dispatch = useDispatch();
    const [loadingWalletFlag, setLoadingWalletFlag] = useState(true);
    const [wallets, setWallets] = useState([]);



    /// <summary>
    /// Author : -
    /// </summary>
    useEffect(() => {
       
            
        ApiEngine.get(ApiUrl._API_GET_NON_SEAMLESS_PRODUCT_BALANCE + "?memberId=" + props.id  ).then((responseJson) => {
                if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                    console.log("hi" + responseJson[ApiKey._API_SUCCESS_KEY])
                } else {
                    setWallets(responseJson[ApiKey._API_DATA_KEY]);
                    setLoadingWalletFlag(false);
                }
            })
        
    }, [walletDetails]);



    /// <summary>
    /// Author : -
    /// </summary>
    async function init() {
        
    
    }




    return (
        <div>
            <table className="table">
                <th>
                    <b>Wallet Info</b></th>
                <tr>
                    <td><b>{t("CATEGORY")}</b></td>
                    <td><b>{t("BALANCE")}</b></td>
                </tr>
                {wallets.map((wallet, index) => {
                    return (
                        <tr>
                            <td><b>{wallet.productName}</b></td>
                            <td>{parseFloat(wallet.balance).toFixed(2)}</td>
                        </tr>
                    )
                }
                )
                }

            </table>
        </div>
    )
}

export default WalletBalance;