import React, { useState, useEffect } from "react";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import {
    ApiUrl,
    ApiKey,
    LanguageKey,
} from "../../util/Constant";
import { useTranslation } from "react-i18next";
import ApiEngine from "../../util/ApiEngine";
import { useDispatch } from "react-redux";
import Icon from '@mdi/react';
import classnames from 'classnames';
import { mdiPauseCircleOutline, mdiPlayCircleOutline } from '@mdi/js';
import { numberWithCurrencyFormat, stringIsNullOrEmpty } from "../../util/Util";
import moment from 'moment';

const SportsLiveMonitor = props => {
    const _dispatch = useDispatch();
    const { t } = useTranslation(LanguageKey._PRIMARY);
    const [liveData, setLiveData] = useState([]);
    const [dataToDisplay, setDataToDisplay] = useState([]);
    const [isPaused, setIsPaused] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [intervalId, setIntervalId] = useState(0);
    const [filterAmt, setFilterAmt] = useState(0);
    const [currentCategory, setCurrentCategory] = useState("All");
    const [currentRiskLevel, setCurrentRiskLevel] = useState(-1);
    const [allAvailableCategory, SetAllAvailableCategory] = useState([]);
    const [serverCurrentTime, setServerCurrentTime] = useState(moment());
    const [parlayBetDetails, setParlayBetDetails] = useState([]);
  
    const _risk_level_color = [
        { value: -1, label: "normal" },
        { value: -99, label: "purple"},
        { value: 0, label: "normal" },
        { value: 1, label: "yellow" },
        { value: 2, label: "green" },
        { value: 3, label: "orange" },
        { value: 4, label: "blue" },
        { value: 5, label: "grey" },
        { value: 6, label: "red" }
    ];

    const _defaultColumns = [
        {
            Header: t("DATE"),
            accessor: "date",
            minWidth: 150,
            Cell: ({ row }) => {
                return (<>
                    {!stringIsNullOrEmpty(row.original.betData) ? <div className={"live-data-cell background-color-" + (_risk_level_color.find(x => x.value === row.original.riskLevel).label)} style={{ display: "flex", flexDirection: "column" }}>
                        <div>{row.original.date}</div>
                    </div>
                        :
                        <div className={"live-data-cell background-color-" + (_risk_level_color.find(x => x.value === row.original.riskLevel).label)}>{t("LOADING")}</div>}
                </>);
            },
        },
        {
            Header: t("USERNAME") + " (" + t("RISK_LEVEL_SHORT") + ")",
            accessor: "memberUsername",
            minWidth: 100,
            Cell: ({ row }) => {
                let betId = row.original.betId;
                if (betId.includes("-")) { betId = betId.substring(betId.indexOf("-") + 1, betId.length); }
                return (
                    <div className={"live-data-cell background-color-" + (_risk_level_color.find(x => x.value === row.original.riskLevel).label)} style={{ display: "flex", flexDirection: "column" }}>
                        <div>{row.original.memberUsername}</div>
                        <div>{"(" + (row.original.riskLevel === -99 ? "New" : row.original.riskLevel) + ")"}</div>
                    </div>
                )
            },
        },
        //{
        //    Header: t("PLAYER_ID") + " (" + t("BET_ID") + ")",
        //    accessor: "username",
        //    minWidth: 100,
        //    Cell: ({ row }) => {
        //        let betId = row.original.betId;
        //        if (betId.includes("-")) {
        //            if (row.original.productName === "SABA Sports") {
        //                betId = betId.substring(0, betId.indexOf("-"));
        //            }
        //            else {
        //                betId = betId.substring(betId.indexOf("-") + 1, betId.length);
        //            }
        //        }
        //        return (
        //            <div className={"live-data-cell background-color-" + (_risk_level_color.find(x => x.value === row.original.riskLevel).label)} style={{ display: "flex", flexDirection: "column" }}>
        //                <div>{row.original.username}</div>
        //                <div style={{ fontSize: "12px" }}>{"(" + betId + ")"}</div>
        //            </div>
        //        );
        //    },
        //},
        {
            Header: t("PRODUCT"),
            accessor: "productName",
            minWidth: 110,
            Cell: ({ row }) => {
                return (
                    <div className={"live-data-cell background-color-" + (_risk_level_color.find(x => x.value === row.original.riskLevel).label)} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <div>{row.original.productName}</div>
                    </div>
                );
            },
        },
        {
            Header: t("BET")/* + " (" + t("HANDICAP_SHORT") + ")"*/,
            accessor: "betOption",
            minWidth: 100,
            Cell: ({ row }) => {
                let team1 = "";
                let team2 = "";
                let playerBet = "";
                let matchStarted = false;
                let isParlayBet = false;
                let isHTBet = false;
                let isOverUnderHdpBet = false;
                let isTotalGoalBet = false;
                let betDataJSON = {};
                if (!stringIsNullOrEmpty(row.original.betData)) {
                    betDataJSON = JSON.parse(row.original.betData);
                    if (betDataJSON.subBet.length > 0) {
                        isParlayBet = betDataJSON.subBet.length > 1;
                        playerBet = betDataJSON.subBet[0].betOption;
                        team1 = (betDataJSON.subBet[0].match).substring(0, (betDataJSON.subBet[0].match).indexOf(" vs "));
                        team2 = (betDataJSON.subBet[0].match).substring((betDataJSON.subBet[0].match).indexOf("vs ") + 3, (betDataJSON.subBet[0].match).length);
                        matchStarted = moment(betDataJSON.subBet[0].kickOffTime).add(12, 'hours') < serverCurrentTime;
                        isHTBet = betDataJSON.subBet[0].marketType.includes('First Half') || betDataJSON.subBet[0].marketType.includes('FirstHalf') || betDataJSON.subBet[0].marketType.includes('1H');
                        isOverUnderHdpBet = playerBet.includes("Over") || playerBet.includes("Under") || playerBet.includes("over") || playerBet.includes("under") || betDataJSON.subBet[0].marketType.includes("Hdp") || betDataJSON.subBet[0].marketType.includes("Handicap") || betDataJSON.subBet[0].marketType.includes("hdp") || betDataJSON.subBet[0].marketType.includes("handicap");
                    }
                }
                let displayPlayerBet = playerBet;
                //regex checking
                let tempRegexChk = /[0-9]+[-][0-9]+/;
                if (playerBet.match(tempRegexChk)) {
                    isTotalGoalBet = betDataJSON.subBet.filter(x => x.marketType.includes("Total Goal")).length > 0;
                    if (!isTotalGoalBet) {
                        displayPlayerBet = playerBet.substring(0, playerBet.indexOf("-")) + ":" + playerBet.substring(playerBet.indexOf("-") + 1, playerBet.length);
                    }
                }
                //others
                let tempPlayerBet = playerBet.trim();
                if (!isOverUnderHdpBet) {
                    if (tempPlayerBet === "away") {
                        displayPlayerBet = "2";
                    }
                    else if (tempPlayerBet === "home") {
                        displayPlayerBet = "1";
                    }
                    else if (tempPlayerBet === "even" || tempPlayerBet === "draw") {
                        displayPlayerBet = "X";
                    }
                    else {
                        if (tempPlayerBet === "o") {
                            displayPlayerBet = "AOS";
                        }
                        else if (tempPlayerBet === "da" || tempPlayerBet === "dd" || tempPlayerBet === "ha" || tempPlayerBet === "hd" || tempPlayerBet === "hh") {
                            displayPlayerBet = tempPlayerBet.toUpperCase();
                        }
                    }
                }
                else if (playerBet === team1) {
                    displayPlayerBet = "home";
                }
                else if (playerBet === team2) {
                    displayPlayerBet = "away";
                }
                displayPlayerBet = displayPlayerBet.replace("Handicap", "hdp");
                if (isHTBet && displayPlayerBet.includes("1H ")) { displayPlayerBet = displayPlayerBet.replace("1H ", ""); }
                return (<>
                    {(!stringIsNullOrEmpty(row.original.betData) && JSON.parse(row.original.betData).subBet.length > 0) ? <div className={"live-data-cell background-color-" + (_risk_level_color.find(x => x.value === row.original.riskLevel).label)} style={{ display: "flex", flexDirection: "column", textAlign: "center" }}>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", whiteSpace: "nowrap" }}>
                            {isHTBet && <span style={{ marginRight: "3px" }}>{"HT : "}</span>}<span style={{ color: playerBet === team1 ? "#00008d" : playerBet === team2 ? "#db0000" : "unset" }}>{isParlayBet ? <a style={{ textDecoration: "underline", color: "blue", cursor: "pointer" }} onClick={() => openParlayBetDetails(betDataJSON.subBet)}>{"Parlay"}</a> : displayPlayerBet}</span>{!isParlayBet && matchStarted && <span style={{ marginLeft: "3px" }}>{"@ " + betDataJSON.subBet[0].liveScore}</span>}
                        </div>
                        {!isParlayBet && isOverUnderHdpBet && <div><span>{"("}</span><span style={{ color: betDataJSON.subBet[0].hdp < 0 ? "red" : betDataJSON.subBet[0].hdp === 0 ? "unset" : "#006e00" }}>{numberWithCurrencyFormat(betDataJSON.subBet[0].hdp, 2, true)}</span>{")"}</div>}
                    </div>
                        :
                        <div className={"live-data-cell background-color-" + (_risk_level_color.find(x => x.value === row.original.riskLevel).label)}>{t("LOADING")}</div>}
                </>);
            },
        },
        {
            Header: t("ODDS"),
            accessor: "odds",
            minWidth: 50,
            Cell: ({ row }) => {
                let betDataJSON = {};
                if (!stringIsNullOrEmpty(row.original.betData)) {
                    betDataJSON = JSON.parse(row.original.betData);
                }
                if (stringIsNullOrEmpty(betDataJSON.oddsStyle))
                {
                    betDataJSON.oddsStyle = " ";
                }
                return (<>
                    {!stringIsNullOrEmpty(row.original.betData) ? <div className={"live-data-cell background-color-" + (_risk_level_color.find(x => x.value === row.original.riskLevel).label)} style={{ display: "flex", justifyContent: "flex-end" }}>
                        <span style={{ color: betDataJSON.odds < 0 ? "red" : "unset" }}>{numberWithCurrencyFormat(betDataJSON.odds, 2, true)}</span><span style={{ marginLeft: "5px" }}>{" (" + betDataJSON.oddsStyle.substring(0, 1) + ")"}</span>
                    </div>
                        :
                        <div className={"live-data-cell background-color-" + (_risk_level_color.find(x => x.value === row.original.riskLevel).label)}>{t("LOADING")}</div>}
                </>);
            },
        },
        {
            Header: t("STAKE"),
            accessor: "stake",
            minWidth: 50,
            Cell: ({ row }) => {
                let betDataJSON = {};
                if (!stringIsNullOrEmpty(row.original.betData)) {
                    betDataJSON = JSON.parse(row.original.betData);
                }
                return (<>
                    {!stringIsNullOrEmpty(row.original.betData) ? <div className={"live-data-cell background-color-" + (_risk_level_color.find(x => x.value === row.original.riskLevel).label)} style={{ display: "flex", justifyContent: "flex-end" }}>
                        {numberWithCurrencyFormat(betDataJSON.stake, 2, true)}
                    </div>
                        :
                        <div className={"live-data-cell background-color-" + (_risk_level_color.find(x => x.value === row.original.riskLevel).label)}>{t("LOADING")}</div>}
                </>);
            },
        },
        {
            Header: t("EVENT"),
            accessor: "event",
            minWidth: 300,
            Cell: ({ row }) => {
                let betDataJSON = {};
                let team1 = "";
                let team2 = "";
                if (!stringIsNullOrEmpty(row.original.betData)) {
                    betDataJSON = JSON.parse(row.original.betData);
                    if (betDataJSON.subBet.length > 0) {
                        team1 = (betDataJSON.subBet[0].match).substring(0, (betDataJSON.subBet[0].match).indexOf(" vs "));
                        team2 = (betDataJSON.subBet[0].match).substring((betDataJSON.subBet[0].match).indexOf(" vs ") + 3, (betDataJSON.subBet[0].match).length);
                    }
                }
                return (<>
                    {!stringIsNullOrEmpty(row.original.betData) ? <div className={"live-data-cell background-color-" + (_risk_level_color.find(x => x.value === row.original.riskLevel).label)} style={{ display: "flex", flexDirection: "column", textAlign: "center" }}>
                        {!stringIsNullOrEmpty(team1) && <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><div style={{ color: "#00008d", fontSize: "13px" }}>{team1}</div><div style={{ margin: "0px 10px" }}>{" vs "}</div><div style={{ color: "#db0000", fontSize: "13px" }}>{team2}</div></div>}
                        <div style={{ color: "#690069" }}>{betDataJSON.subBet[0].league}</div>
                    </div>
                        :
                        <div className={"live-data-cell background-color-" + (_risk_level_color.find(x => x.value === row.original.riskLevel).label)}>{t("LOADING")}</div>}
                </>);
            },
        },
        //{
        //    Header: t("DATE") + " (" + t("IP") + ")",
        //    accessor: "date",
        //    minWidth: 150,
        //    Cell: ({ row }) => {
        //        let betDataJSON = {};
        //        if (!stringIsNullOrEmpty(row.original.betData)) {
        //            betDataJSON = JSON.parse(row.original.betData);
        //        }
        //        return (<>
        //            {!stringIsNullOrEmpty(row.original.betData) ? <div className={"live-data-cell background-color-" + (_risk_level_color.find(x => x.value === row.original.riskLevel).label)} style={{ display: "flex", flexDirection: "column" }}>
        //                <div>{row.original.date}</div>
        //                <div style={{ fontSize: "12px" }}>{"(" + betDataJSON.ip + ")"}</div>
        //            </div>
        //                :
        //                <div className={"live-data-cell background-color-" + (_risk_level_color.find(x => x.value === row.original.riskLevel).label)}>{t("LOADING")}</div>}
        //        </>);
        //    },
        //},
    ];

    const getLatestData = async () => {
        setIsLoading(true);
        var apiUrl = ApiUrl._API_GET_SPORTS_LIVE_DATA + "?amount=" + filterAmt + "&riskLevel=" + currentRiskLevel;
        fetch(apiUrl, {
            method: ApiKey._API_GET,
            headers: {
                "Accept": ApiKey._API_APPLICATION_JSON,
                "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY
            }
        }).then((response) => response.json()).then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            if (responseJson[ApiKey._API_DATA_KEY] != null) {
                let data = responseJson[ApiKey._API_DATA_KEY]['data'];
                setLiveData(data);
                setServerCurrentTime(moment(responseJson[ApiKey._API_DATA_KEY]['currentDateTime']));
            }
            else {
                setLiveData([]);
            }
        }
        });
        setIsLoading(false);
    }

    useEffect(() => {
        clearInterval(intervalId);
        getLatestData();
        if (!isPaused) {
            let tempIntervalId = setInterval(() => getLatestData(), 20000);
            setIntervalId(tempIntervalId);
        }
    }, [filterAmt, currentRiskLevel]);

    /// <summary>
    /// Author : -
    /// </summary>
    const toggleCategory = (categoryTitle) => {
        if (currentCategory !== categoryTitle) {
            setCurrentCategory(categoryTitle);
        }
    }

    const toggleRiskLevel = (riskLevel) => {
        if (currentRiskLevel !== riskLevel) {
            setCurrentRiskLevel(riskLevel);
        }
    }

    const togglePause = () => {
        let tempIsPaused = isPaused;
        if (tempIsPaused === false) { //clear loop before pausing
            clearInterval(intervalId);
        }
        else { //start back loop before resume
            getLatestData();
            let tempIntervalId = setInterval(() => getLatestData(), 20000);
            setIntervalId(tempIntervalId);
        }
        setIsPaused(!isPaused);
    }

    const openParlayBetDetails = (betDetailList) => {
        let betDetailToDisplay = "";
        betDetailList.map((detail, index) => {
            betDetailToDisplay += (index+1) + ". " + JSON.stringify(detail) + "\n\n";
        });
        betDetailToDisplay = betDetailToDisplay.replaceAll(',', ",\n");
        var myWindow = window.open("", "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400");
        myWindow.document.write("<pre>" + betDetailToDisplay + "</pre>");
    }

    return (
        <>
            <div id="sports-live-monitor">
                <div className="filter-section">
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <div style={{ display: "flex", alignItems: "center", fontSize: "16px", fontWeight: "bold" }}>
                            {t("FILTER_BY") + " " + t("RISK_LEVEL") + " : "}
                            {_risk_level_color.map((riskLevel, index) => {
                                return (<>
                                    <div onClick={() => toggleRiskLevel(riskLevel.value)} className={"live-data-risk-level " + classnames({ active: currentRiskLevel === riskLevel.value })}>{riskLevel.value === -1 ? "All" : riskLevel.value === -99 ? "New" : riskLevel.value}</div>
                                    {index !== (_risk_level_color.length - 1) && <hr className="vertical-hr" />}
                                </>)
                            })}
                        </div>
                    </div>
                    <div style={{ display: "flex" }}>
                        <label htmlFor="dataAmount" style={{ marginBottom: "0", marginRight: "5px", fontSize: "15px", fontWeight: "bolder" }}>{t("FILTER_BET_AMOUNT") + ": >="}</label>
                        <div>
                            <input
                                type="number"
                                min="0"
                                step="1"
                                id="dataAmount"
                                className="data-amount-filter"
                                onChange={(e) => {
                                    clearInterval(intervalId);
                                    setFilterAmt(e.target.valueAsNumber);
                                }}
                            />
                        </div>
                        <div onClick={() => togglePause()} style={{ marginLeft: "25px" }}>
                            <Icon
                                path={isPaused ? mdiPlayCircleOutline : mdiPauseCircleOutline}
                                size={1.33}
                                color="black"
                            />
                        </div>
                    </div>
                </div>
                <hr />
                <div>
                    <div style={{ height: "30px", fontSize: "13px" }}>{isLoading && <span>{t("LOADING_NEW_DATA")}</span>}</div>
                <ReactTable
                    className=" sports-live-monitor"
                    data={liveData}
                    columns={_defaultColumns}
                    globalFilterable={false}
                        initialPageSize={20}
                />
                </div>
                <div style={{ marginTop: "15px" }}>
                    <div style={{ marginBottom: "10px" }}><b style={{ fontSize: "15px" }}>Legend :</b></div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        {_risk_level_color.filter(x => x.label !== "normal").map((riskLevel, index) => {
                            return (
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", marginBottom: "10px" }}>
                                    <div className={"background-color-" + riskLevel.label} style={{ height: "20px", width: "20px", backgroundColor: riskLevel.colorCode, marginRight: "5px" }}></div>
                                    <div style={{ fontSize: "15px" }}>{"- " + (riskLevel.value === -99 ? t("NEW_MEMBER") : t("RISK_LEVEL") + " (" + riskLevel.value + ") ")}</div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </>
    )
}

export default SportsLiveMonitor;