import React, { useState, useEffect } from 'react';
import { Panel, PanelBody } from './../../components/panel/panel.jsx';
import 'react-datepicker/dist/react-datepicker.css';
import { ApiKey, ApiUrl, LanguageKey, _CURRENCY_RATIO } from "../../util/Constant";
import { numberWithCommas, numberWithCurrencyFormat } from "../../util/Util";
import { useTranslation } from 'react-i18next';
import 'react-intl-tel-input/dist/main.css';
import ApiEngine from '../../util/ApiEngine.js';
import MemberWithRolloverReportTable from "../../components/constantComponent/reactTable/MemberWithRolloverReportTable.js";
import SweetAlert from "react-bootstrap-sweetalert";
import { useDispatch } from 'react-redux';
import { showMessage } from '../../redux/AppAction.js';
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { useForm } from "react-hook-form";

/// <summary>
/// Author : -
/// </summary>
const MemberWithRolloverReport = (props) => {
  const { t, i18n } = useTranslation(LanguageKey._PRIMARY);
  const _dispatch = useDispatch();
  const [tableData, setTableData] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedMemberId, setSelectedMemberId] = useState();
  const _columns = [
    {
      Header: (
        <input
          type="checkbox"
          checked={selectedRows.length === tableData.length}
          onChange={() => handleChecked('all')}
        />
      ),
      headerStyle: {
        textAlign: 'center',
        width: '50px'
      },
      cellStyle: {
        textAlign: 'center',
        width: '50px'
      },
      accessor: 'test',
      disableSortBy : true,
      Cell: ({ row }) => (
        <input
          type="checkbox"
          name={`checkbox-${row.original.memberId}`}
          checked={selectedRows.includes(row.original.memberId)}
          onChange={() => handleChecked(row.original.memberId)}
        />
      ),
    },
    {
      Header: t("USERNAME"),
      accessor: "memberUsername",
      headerStyle: {
        textAlign: 'right',
        minWidth: '100px'
      },
      cellStyle: {
        textAlign: 'right',
        minWidth: '100px'
      },
      Cell: ({ row }) => {
        return (
          <span>
            {row.original.memberUsername}
          </span>
        );
      },
    },
    {
      Header: t("WALLET_BALANCE"),
      accessor: "memberWalletBalance",
      headerStyle: {
        textAlign: 'right',
        minWidth: '100px'
      },
      cellStyle: {
        textAlign: 'right',
        minWidth: '100px'
      },
      Cell: ({ row }) => {
        return (
          <div>{numberWithCurrencyFormat(parseFloat(row.original.memberWalletBalance), 2, true)}</div>
        );
      },
    },
    {
      Header: t("ROLLOVER_BALANCE"),
      accessor: "memberRolloverBalance",
      headerStyle: {
        textAlign: 'right',
        minWidth: '100px'
      },
      cellStyle: {
        textAlign: 'right',
        minWidth: '100px'
      },
      Cell: ({ row }) => {
        return (
          <div>{numberWithCurrencyFormat(parseFloat(row.original.memberRolloverBalance), 2, true)}</div>
        );
      },
    },
    {
      Header: t("BONUS_DEPOSIT_ID"),
      accessor: "bonusDepositId",
      headerStyle: {
        textAlign: 'right',
        minWidth: '100px'
      },
      cellStyle: {
        textAlign: 'right',
        minWidth: '100px'
      },
      Cell: ({ row }) => {
        return (
          <span>
            {row.original.bonusDepositId}
          </span>
        );
      },
    },
    {
      Header: t("ROLLOVER_CATEGORIES"),
      accessor: "categoryTitles",
      headerStyle: {
        textAlign: 'right',
        minWidth: '100px'
      },
      cellStyle: {
        textAlign: 'right',
        minWidth: '100px'
      },
      Cell: ({ row }) => {
        return (
          <span>
            {row.original.categoryTitles}
          </span>
        );
      },
    },
    {
      id: "action",
      Header: "",
      headerStyle: {
        textAlign: 'center',
        width: '50px'
      },
      cellStyle: {
        textAlign: 'center',
        width: '50px'
      },
      Cell: ({ row }) => {
        if (selectedRows.length > 0) {
          return (<></>)
        }
        else {
          return (
            <div className="btn-group m-r-5 m-b-5">
              <UncontrolledButtonDropdown>
                <DropdownToggle caret color="default">
                  <i className="fas fa-cog"></i>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    onClick={() => {
                      setSelectedMemberId(row.original.memberId);
                      setShowConfirmation(true);
                    }}
                  >
                    <span className="text-primary">{t("RESET_ROLLOVER")}</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            </div>
          )
        }
      },
      Footer: () => {
        if (selectedRows.length <= 0) {
          return (<></>)
        } else {
          return (
            <div className="btn-group m-r-5 m-b-5">
              <UncontrolledButtonDropdown>
                <DropdownToggle caret color="default">
                  <i className="fas fa-cog"></i>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    onClick={() => {
                      setShowConfirmation(true);
                    }}
                  >
                    <span className="text-primary">{t("RESET_ROLLOVER")}</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            </div>
          )
        }
      },
      disableSortBy: true,
      disableFilters: true,
      width: 100,
      style: { overflow: "visible" },
    },
  ];

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_WITH_ROLLOVERS);
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY];
      setTableData(data);
    }
  }

  const resetRollover = async (memberIds ) => {
    let responseJson = await ApiEngine.post(ApiUrl._API_RESET_ROLLOVER_RECORD, memberIds);
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      _dispatch(showMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
      init();
    }
    else {
      _dispatch(showMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
      init();
    }
    setSelectedMemberId("");
    setSelectedRows([]);
  }

  const handleChecked = (Id) => {
    if (Id === 'all') {
      if (selectedRows.length !== 0 && selectedRows.length === tableData.length) {
        setSelectedRows([]);
      } else {
        setSelectedRows(tableData.map((_) => _.memberId));
      }
    } else {
      setSelectedRows((prevSelectedRows) => {
        const index = prevSelectedRows.indexOf(Id);
        if (index === -1) {
          return [...prevSelectedRows, Id];
        } else {
          const updatedRows = [...prevSelectedRows];
          updatedRows.splice(index, 1);
          return updatedRows;
        }
      });
    }
  };

  return (
    <div id="member-rollover-report">
      {showConfirmation && (
        <SweetAlert
          warning
          showCancel
          confirmBtnText={t("CONFIRM")}
          cancelBtnText={t("CANCEL")}
          confirmBtnBsStyle="danger"
          title={selectedRows.length > 1 ? t("RESET_MEMBER_ROLLOVERBALANCE_CONFIRMATION", { total: selectedRows.length }) : selectedRows.length === 1 ? t("RESET_MEMBER_ROLLOVERBALANCE_CONFIRMATION_2", { name: tableData.filter(x => x.memberId === selectedRows[0])[0].memberUsername }): t("RESET_MEMBER_ROLLOVERBALANCE_CONFIRMATION_2", { name: tableData.filter(x => x.memberId === selectedMemberId)[0].memberUsername })}
          onConfirm={() => {
            setShowConfirmation(false);
            selectedRows.length > 0 ? resetRollover(selectedRows) : resetRollover([selectedMemberId]);
          }}
          onCancel={() => {
            setSelectedMemberId("");
            setShowConfirmation(false);
          }}
        >
          {t("UNABLE_TO_REVERT")}
        </SweetAlert>
      )}
      <h1 className="page-header">
        {t("MEMBER_WITH_ROLLOVER_REPORT") + " (" + t("MEMBER_BALANCE_LESS_THAN_VALUE", {value: 5}) + ")"}
      </h1>
      <Panel>
        <div className="row">
          <div className="col-lg-12">
            <PanelBody>
              {(tableData && Object.keys(tableData).length > 0) ? <MemberWithRolloverReportTable
                data={tableData}
                columns={_columns}
                renderFooter={true}
              /> : <div>{t("NO_DATA_FOUND")}</div>}
            </PanelBody>
          </div>
        </div>
      </Panel>
    </div>
  )

}

export default MemberWithRolloverReport;