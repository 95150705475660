import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import {
    ApiKey,
    ApiUrl,
    WebUrl,
    TransactionStatus,
    TransactionType,
    LanguageKey,
    ReadWritePermission,
    AccessRight,
    Role
} from "../../util/Constant";
import {
    stringIsNullOrEmpty,
    numberWithCommas,
    navigateTo,
} from "../../util/Util";
import moment from "moment";
import { useTranslation } from "react-i18next";
import ApiEngine from "../../util/ApiEngine";
import { useDispatch, useSelector } from "react-redux";
import { showMessage } from "../../redux/AppAction";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useForm } from "react-hook-form";
import { checkIfPermissionExist } from "../../util/PermissionChecker";

/// <summary>
/// Author : -
/// </summary>
const TransactionDetail = (props) => {
    const { t } = useTranslation(LanguageKey._PRIMARY);
    let _history = useHistory();
    let _dispatch = useDispatch();
    const _location = useLocation();
    const authState = useSelector(state => state.authState);
    const { register, unregister, handleSubmit, errors } = useForm();

    const [txn, setTxn] = useState();
    const [transactionDetail, setTransactionDetail] = useState([]);
    const [auditDetail, setAuditDetail] = useState([]);
    const [systemBalance, setSystemBalance] = useState([]);
    const [withdrawalModal, setWithdrawalModal] = useState(false);

    const [showDetails, setShowDetails] = useState(true);

    const [fullName, setFullName] = useState("");

    const [bankAccountOption, setBankAccountOption] = useState([]);

    const [auditBankOption, setAuditBankOption] = useState([]);
    const [auditBankId, setAuditBankId] = useState();
    const [auditBankAccountId, setAuditBankAccountId] = useState();
    const [fetchPreviousDataUrl, setFetchPreviousDataUrl] = useState(ApiUrl._API_GET_PREVIOUS_TRANSACTION + "?transactionId=" + _location.state.id);
    const _userData = useSelector((state) => state.authState.userData);
    const userRole = _userData.userRole;
    let role1IsAllowed = checkIfPermissionExist(
        AccessRight._PAYMENT_GATEWAY_APPROVAL_PERMISSION,
        true
    );
    let role3IsAllowed = checkIfPermissionExist(
        AccessRight._TRANSACTION_APPROVAL_PERMISSION,
        true
    );
    let role4IsAllowed = checkIfPermissionExist(
        AccessRight._TRANSACTION_REJECTION_PERMISSION,
        true
    );

    /// <summary>
    /// Author : -
    /// </summary>
    useEffect(() => {
        init();
    }, []);

    /// <summary>
    /// Author : -
    /// </summary>
    async function getBankAccount() {
        var apiUrl = ApiUrl._API_GET_BANK_ACCOUNT_BY_BANK_ID;
        apiUrl += "?bankId=" + auditBankId + "&withdrawal=" + true;
        let responseJson = await ApiEngine.get(apiUrl);
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            const bankAccountList = [];
            responseJson[ApiKey._API_DATA_KEY].forEach(function (bankAccount) {
                if (bankAccount.withdrawalStatus) {
                    bankAccountList.push({
                        label: bankAccount.accountNumber,
                        value: bankAccount.id,
                    });
                }
            });

            setBankAccountOption(bankAccountList);
        } else {
            _dispatch(showMessage(false, t(responseJson[ApiKey._API_MESSAGE_KEY])));
        }
    }

    /// <summary>
    /// Author : -
    /// </summary>
    useEffect(() => {
        if (!stringIsNullOrEmpty(auditBankId)) {
            getBankAccount();
        }
    }, [auditBankId]);

    /// <summary>
    /// Author : -
    /// </summary>
    const getTransactionDetail = async (transactionId, vfullname) => {
        let responseJson = await ApiEngine.get(
            ApiUrl._API_GET_TRANSACTION_BY_ID + "?transactionId=" + transactionId
        );
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            let transaction = responseJson[ApiKey._API_DATA_KEY]["transaction"]
                ? responseJson[ApiKey._API_DATA_KEY]["transaction"]
                : responseJson[ApiKey._API_DATA_KEY];
            //if (!stringIsNullOrEmpty(transaction)) 
            {
                setTxn(transaction);

                // before approve, fullName is null so use vfullname
                // after approve, vfullname is null so will use fullName
                if (vfullname == null) {
                    vfullname = fullName;
                }
                if (transaction.lockedByFullName != vfullname && transaction.lockedByFullName != null && !userRole.includes("Company") && !userRole.includes("Supervisor")) {
                    _dispatch(showMessage(false, "Transaction has been locked by another user"));
                    setShowDetails(false);
                    return;
                }

                let transactionDetails = [
                    { label: "TRANSACTION_ID", value: transaction.id },
                    {
                        label: "TRANSACTION_TIME",
                        value: moment(transaction.transactionDateTime).format(
                            "YYYY-MM-DD HH:mm:ss"
                        ),
                    },
                    {
                        label: "USERNAME",
                        value: transaction.memberUsername,
                        id: transaction.memberId,
                        isTestAccount: transaction.memberIsTestAccount
                    },
                    {
                        label: "SUBMIT_TIME",
                        value: moment(transaction.submitTime).format("YYYY-MM-DD HH:mm:ss"),
                    },
                    { label: "FULLNAME", value: transaction.memberFullName },
                    { label: "IP", value: transaction.ipAddress },
                    { label: "TRANSACTION_AMOUNT", value: transaction.amount },
                    { label: "STATUS", value: transaction.approvalStatusString },
                    {
                        label: transaction.transactionType.toUpperCase() + "_CHANNEL",
                        value:
                            transaction.transactionTypeId == TransactionType._UPLINE_TRANSFER
                                ? "Internal"
                                : transaction.channel,
                    },
                    { label: "PANEL", value: transaction.panel },
                ];
                if (transaction.transactionTypeId == TransactionType._DEPOSIT) {
                    let bankAccount = responseJson[ApiKey._API_DATA_KEY]["bankAccount"];
                    if (!stringIsNullOrEmpty(bankAccount)) {
                        let extraDetails = [
                            {
                                label: "RECEIPT_REFERENCE",
                                value: responseJson[ApiKey._API_DATA_KEY]["receiptReference"],
                            },
                            {
                                label: "BANK",
                                value: bankAccount.bankName + " - " + bankAccount.accountNumber,
                            },
                            {
                                label: "RECEIPT",
                                value:
                                    responseJson[ApiKey._API_DATA_KEY]["receipt"] != null
                                        ? t("HAVING_RECEIPT")
                                        : t("NO_RECEIPT_SELECTED"),
                            },
                            {
                                label: "BONUS",
                                value:
                                    transaction.remark != null
                                        ? transaction.remark
                                        : t("NO_BONUS_SELECTED"),
                            },
                        ];
                        transactionDetails = transactionDetails.concat(extraDetails);
                    }
                } else if (
                    transaction.transactionTypeId == TransactionType._WITHDRAWAL
                ) {
                    let nodePath = responseJson[ApiKey._API_DATA_KEY]["transaction"]["member"]["nodePath"];
                    let lastOccurenceOfSymbol = nodePath.lastIndexOf("<");
                    let tempUsername = "";
                    let usernameJson = await ApiEngine.get(ApiUrl._API_GET_USERNAME_BY_NODE_ID + "?nodeId=" + nodePath.substring(lastOccurenceOfSymbol + 1))

                    if (usernameJson[ApiKey._API_SUCCESS_KEY]) {
                        tempUsername = usernameJson[ApiKey._API_DATA_KEY];
                    }
                    transactionDetails = transactionDetails.filter(x => x.label !== "USERNAME")
                    let extraDetails = [
                        {
                            label: "BANK_NAME",
                            value: responseJson[ApiKey._API_DATA_KEY]["bankName"],
                        },
                        {
                            label: "BANK_ACCOUNT_HOLDER_NAME",
                            value:
                                responseJson[ApiKey._API_DATA_KEY]["bankAccountHolderName"],
                        },
                        {
                            label: "BANK_ACCOUNT_NUMBER",
                            value: responseJson[ApiKey._API_DATA_KEY]["bankAccountNumber"],
                        },
                    ];
                    transactionDetails = transactionDetails.concat(extraDetails);
                    transactionDetails.splice(2, 0, {
                        label: "USERNAME",
                        value: transaction.memberUsername + " (" + tempUsername + ")",
                        id: transaction.memberId,
                    });
                } else if (
                    transaction.transactionTypeId == TransactionType._ADJUSTMENT
                ) {
                    transactionDetails.push({
                        label: "REMARK",
                        value: transaction.remark,
                    });
                } else if (transaction.transactionTypeId == TransactionType._BONUS) {
                    let bonus = responseJson[ApiKey._API_DATA_KEY]["bonus"];
                    if (!stringIsNullOrEmpty(bonus)) {
                        let extraDetails = [
                            {
                                label: "DEPOSIT_ID",
                                value:
                                    responseJson[ApiKey._API_DATA_KEY]["depositId"] != null
                                        ? responseJson[ApiKey._API_DATA_KEY]["depositId"]
                                        : null,
                            },
                            {
                                label: "DEPOSIT_AMOUNT",
                                value: responseJson[ApiKey._API_DATA_KEY]["depositAmount"],
                            },
                            { label: "MINIMUM_DEPOSIT", value: bonus.minimumDeposit },
                            {
                                label: "MAXIMUM_BONUS_AMOUNT",
                                value: bonus.maximumBonusAmount,
                            },
                            { label: "BONUS_TYPE", value: bonus.bonusType },
                            { label: "BONUS_METHOD", value: bonus.methodName },
                            {
                                label: "BONUS_VALUE",
                                value: bonus.amount + " " + bonus.methodName,
                            },
                            { label: "BONUS_CAMPAIGN", value: bonus.code },
                            { label: "BONUSTURNOVER", value: "X " + bonus.turnover },
                        ];
                        transactionDetails = transactionDetails.concat(extraDetails);
                    }
                } else if (
                    transaction.transactionTypeId == TransactionType._UPLINE_TRANSFER
                ) {
                    let extraDetails = [
                        {
                            label: "TARGET_MEMBER",
                            value: responseJson[ApiKey._API_DATA_KEY]["memberUserFullName"],
                            isTestAccount: responseJson[ApiKey._API_DATA_KEY]["memberIsTestAccount"]
                        },
                    ];
                    transactionDetails = transactionDetails.concat(extraDetails);
                }
                setTransactionDetail(transactionDetails);
                let auditDetails = [
                    { label: "AUDITED_BY", value: transaction.auditedFullName ?? "-" },
                    {
                        label: "AUDITED_DATE_TIME",
                        value: transaction.auditedTime
                            ? moment(transaction.auditedTime).format("YYYY-MM-DD HH:mm:ss")
                            : "-",
                    },
                    {
                        label: "AUDITED_REMARK",
                        value: transaction.auditRemark ? transaction.auditRemark : "-",
                    },
                ];
                setAuditDetail(auditDetails);
            }
        }
    };

    /// <summary>
    /// Author : -
    /// </summary>
    const init = async () => {
        try {
            if (_location.state) {
                let transactionId = _location.state.id;

                let vfullname = "";

                var apiUrl = ApiUrl._API_GET_ACCOUNT;
                apiUrl += "?userId=" + authState.userData.userId;

                fetch(apiUrl, {
                    method: ApiKey._API_GET,
                    headers: {
                        "Content-Type": ApiKey._API_FORM_URLENCODED,
                        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
                    },
                })
                    .then((response) => response.json())
                    .then((responseJson) => {
                        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                            vfullname = responseJson[ApiKey._API_DATA_KEY]["userFullName"];
                            setFullName(vfullname);
                            getTransactionDetail(transactionId, vfullname);
                        }
                    });

                let userBankResponseJson = await ApiEngine.get(
                    ApiUrl._API_GET_USER_BANK
                );
                if (userBankResponseJson[ApiKey._API_SUCCESS_KEY]) {
                    const bankList = [];
                    userBankResponseJson[ApiKey._API_DATA_KEY].forEach(function (bank) {
                        bankList.push({ label: bank.bankName, value: bank.id });
                    });
                    setAuditBankOption(bankList);
                }

                //let previousResponseJson = await ApiEngine.get(
                //  ApiUrl._API_GET_PREVIOUS_TRANSACTION +
                //  "?transactionId=" +
                //  transactionId
                //);

                //if (previousResponseJson[ApiKey._API_SUCCESS_KEY]) {
                //  setPreviousTransaction(previousResponseJson[ApiKey._API_DATA_KEY]);
                //} else {
                //  throw previousResponseJson[ApiKey._API_MESSAGE_KEY];
                //}

                let balanceJson = await ApiEngine.get(
                    ApiUrl._API_GET_SYSTEM_BALANCE +
                    "?memberId=" +
                    _location.state.memberId
                );

                if (balanceJson[ApiKey._API_SUCCESS_KEY]) {
                    setSystemBalance([balanceJson[ApiKey._API_DATA_KEY]]);
                } else {
                    throw balanceJson[ApiKey._API_MESSAGE_KEY];
                }

                getBankAccount();
            }
        } catch (ex) {
            _dispatch(showMessage(false, t(ex)));
        }
    };

    const _systemBalanceColumns = [
        {
            Header: t("DEPOSIT"),
            accessor: "approvedDeposit",
            Cell: ({ row }) => {
                if (row.original.approvedDeposit) {
                    return <span>{numberWithCommas(row.original.approvedDeposit)}</span>;
                } else {
                    return <>0</>;
                }
            },
        },
        {
            Header: t("WITHDRAWAL"),
            accessor: "approvedWithdrawal",
            Cell: ({ row }) => {
                if (row.original.approvedWithdrawal) {
                    return (
                        <span style={{ color: "red" }}>
                            {numberWithCommas(row.original.approvedWithdrawal)}
                        </span>
                    );
                } else {
                    return <>0</>;
                }
            },
        },
        {
            Header: t("BONUS"),
            accessor: "approvedBonus",
            Cell: ({ row }) => {
                if (row.original.approvedBonus) {
                    return <span>{numberWithCommas(row.original.approvedBonus)}</span>;
                } else {
                    return <>0</>;
                }
            },
        },
        {
            Header: t("ADJUSTMENT"),
            accessor: "approvedAdjustment",
            Cell: ({ row }) => {
                if (row.original.approvedAdjustment) {
                    return (
                        <span>{numberWithCommas(row.original.approvedAdjustment)}</span>
                    );
                } else {
                    return <>0</>;
                }
            },
        },
        {
            Header: t("PENDING_DEPOSIT"),
            accessor: "pendingDeposit",
            Cell: ({ row }) => {
                if (row.original.pendingDeposit) {
                    return <span>{numberWithCommas(row.original.pendingDeposit)}</span>;
                } else {
                    return <>0</>;
                }
            },
        },
        {
            Header: t("PENDING_WITHDRAWAL"),
            accessor: "pendingWithdrawal",
            Cell: ({ row }) => {
                if (row.original.pendingWithdrawal) {
                    return (
                        <span>{numberWithCommas(row.original.pendingWithdrawal)}</span>
                    );
                } else {
                    return <>0</>;
                }
            },
        },
        {
            Header: t("PENDING_ADJUSTMENT"),
            accessor: "pendingAdjustment",
            Cell: ({ row }) => {
                if (row.original.pendingAdjustment) {
                    return (
                        <span>{numberWithCommas(row.original.pendingAdjustment)}</span>
                    );
                } else {
                    return <>0</>;
                }
            },
        },
        {
            Header: t("PENDING_BONUS"),
            accessor: "pendingBonus",
            Cell: ({ row }) => {
                if (row.original.pendingBonus) {
                    return <span>{numberWithCommas(row.original.pendingBonus)}</span>;
                } else {
                    return <>0</>;
                }
            },
        },
    ];

    const _previousTransactionColumns = [
        {
            Header: t("DATE"),
            accessor: "submitTime",
            style: {
                whiteSpace: "unset",
                wordBreak: "break-word",
            },
            Cell: ({ row }) => {
                return (
                    <>{moment(row.original.submitTime).format("YYYY-MM-DD HH:mm:ss")}</>
                );
            },
            minWidth: 150,
        },
        {
            Header: t("TYPE"),
            accessor: "transactionType",
        },
        {
            Header: "ID",
            accessor: "id",
        },
        {
            Header: t("STATUS"),
            accessor: "approvalStatusString",
        },
        {
            id: "detail",
            Header: t("DETAIL"),
            accessor: "detail",
        },
        {
            id: "credit",
            Header: t("CREDIT"),
            accessor: "amount",
            Cell: ({ row }) => {
                if (row.original.transactionTypeId != TransactionType._WITHDRAWAL) {
                    return <>{row.original.amount}</>;
                } else {
                    return <></>;
                }
            },
        },
        {
            id: "debit",
            Header: t("DEBIT"),
            accessor: "amount",
            Cell: ({ row }) => {
                if (row.original.transactionTypeId == TransactionType._WITHDRAWAL) {
                    return <span style={{ color: "red" }}>-{row.original.amount}</span>;
                } else {
                    return <></>;
                }
            },
        },
        {
            Header: t("LIMIT"),
            accessor: "limit",
            Cell: ({ row }) => {
                return <>{row.original.limit}</>;
            },
        },
        {
            Header: t("TURNOVER"),
            accessor: "turnover",
            Cell: ({ row }) => {
                return <a href="#">{row.original.turnover}</a>;
            },
        },
        {
            Header: t("DIFFERENCE"),
            accessor: "differences",
            Cell: ({ row }) => {
                if (row.original.differences < 0) {
                    return (
                        <span style={{ color: "red" }}>{row.original.differences}</span>
                    );
                } else {
                    return <>{row.original.differences}</>;
                }
            },
        },
        {
            id: "balance",
            Header: t("BALANCE"),
            accessor: "balance",
        },
    ];

    /// <summary>
    /// Author : -
    /// </summary>
    const processTransaction = (action) => {
        let txnTypeId = txn.transactionTypeId;

        if (action == TransactionStatus._APPROVED) {
            if (txnTypeId == TransactionType._WITHDRAWAL) {
                setWithdrawalModal(true);
            } else {
                updateStatus(action, null);
            }
        } else if (action == TransactionStatus._REJECTED) {
            updateStatus(action, null);
        }
    };

    /// <summary>
    /// Author : -
    /// </summary>
    const submitForm = (data, e) => {
        updateStatus(TransactionStatus._APPROVED, auditBankAccountId);
        setWithdrawalModal(false);
    };

    /// <summary>
    /// Author : -
    /// </summary>
    const updateStatus = async (status, auditBankAccountId) => {
        let apiUrl = ApiUrl._API_UPDATE_TRANSACTION_STATUS;
        let transactionId = txn.id;

        apiUrl += "?transactionId=" + transactionId + "&status=" + status;
        if (!stringIsNullOrEmpty(auditBankAccountId)) {
            apiUrl += "&auditBankAccountId=" + auditBankAccountId;
        }

        let responseJson = await ApiEngine.post(apiUrl);
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            getTransactionDetail(transactionId);
        }
        _dispatch(
            showMessage(
                responseJson[ApiKey._API_SUCCESS_KEY],
                t(responseJson[ApiKey._API_MESSAGE_KEY])
            )
        );
    };

    return (
        <div>
            <h1 className="page-header">
                {t("TRANSACTION")}
                <NavigationButton history={_history} />
            </h1>
            <div className="row">
                <div className="col-xl-12">
                    <div className="row">
                        <div className="col-xl-8">
                            <div
                                className="card text-truncate mb-3"
                                style={{ overflow: "visible" }}
                            >
                                <div className="card-header">
                                    <b>{t("TRANSACTION_DATA")}</b>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        {transactionDetail.map((data, index) => {
                                            return (
                                                <div className="col-lg-6" key={index}>
                                                    <div className="form-group row m-b-0">
                                                        <label
                                                            className="col-md-4 col-form-label"
                                                            style={{ whiteSpace: "normal" }}
                                                        >
                                                            <b>{t(data.label)}</b>
                                                        </label>
                                                        <div
                                                            className="col-md-7"
                                                            style={{ whiteSpace: "normal" }}
                                                        >
                                                            <label className="col-form-label">
                                                                {data.label == "Username" ? (
                                                                    <a
                                                                        href="#"
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            navigateTo(
                                                                                _history,
                                                                                WebUrl._URL_CREATE_OR_UPDATE_MEMBER,
                                                                                { id: data.id }
                                                                            );
                                                                        }}
                                                                    >
                                                                        {data.value}
                                                                    </a>
                                                                ) : (
                                                                    data.value
                                                                )}
                                                                {data.isTestAccount && 
                                                                    <span className="badge badge-pill badge-warning ml-2">{t("TEST_ACCOUNT")}</span>    
                                                                }
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4">
                            <div
                                className="card text-truncate mb-3"
                                style={{ overflow: "visible" }}
                            >
                                <div className="card-header">
                                    <b>{t("AUDIT_DATA")}</b>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            {auditDetail.map((data, index) => {
                                                return (
                                                    <div className="col-lg-12" key={index}>
                                                        <div className="form-group row m-b-0">
                                                            <label className="col-md-4 col-form-label">
                                                                <b>{t(data.label)}</b>
                                                            </label>
                                                            <div
                                                                className="col-md-7"
                                                                style={{ whiteSpace: "normal" }}
                                                            >
                                                                <label className="col-form-label">
                                                                    {data.value}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {txn?.approvalStatus == TransactionStatus._OPEN && showDetails && (<>
                                <div>
                                    {!txn.isInstant ?
                                        <>
                                            {((txn.lockedBy === _userData.userId || userRole.includes("Company")) && showDetails) && <button
                                                className="btn btn-success mr-1"
                                                onClick={() =>
                                                    processTransaction(TransactionStatus._APPROVED)
                                                }
                                            >
                                                {t("APPROVE")}
                                            </button>}
                                            {((txn.lockedBy === _userData.userId || userRole.includes("Company")) && showDetails) && <button
                                                className="btn btn-danger"
                                                onClick={() =>
                                                    processTransaction(TransactionStatus._REJECTED)
                                                }
                                            >
                                                {t("REJECT")}
                                            </button>}
                                        </> :
                                        <>{role1IsAllowed && <button
                                            className="btn btn-success mr-1"
                                            onClick={() =>
                                                processTransaction(TransactionStatus._APPROVED)
                                            }
                                        >
                                            {t("APPROVE")}
                                        </button>
                                        }</>}
                                </div>
                            </>)}
                        </div>
                    </div>
                </div>
                <div className="col-xl-12">
                    <div className="card text-truncate mb-3">
                        <div className="card-header">
                            <b>{t("SYSTEM_BALANCES")}</b>
                        </div>
                        <div className="card-body">
                            <ReactTable
                                data={systemBalance}
                                columns={_systemBalanceColumns}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-xl-12">
                    <div className="card text-truncate mb-3">
                        <div className="card-header">
                            <b>{t("PREVIOUS_TRANSACTIONS")}</b>
                        </div>
                        <div className="card-body">
                            <ReactTable
                                fetchUrl={fetchPreviousDataUrl}
                                //data={previousTransaction}
                                columns={_previousTransactionColumns}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                isOpen={withdrawalModal}
                toggle={() => {
                    setWithdrawalModal(!withdrawalModal);
                }}
                centered={true}
            >
                <form onSubmit={handleSubmit(submitForm)}>
                    <ModalHeader
                        toggle={() => {
                            setWithdrawalModal(!withdrawalModal);
                        }}
                    >
                        {t("PLEASE_SELECT_AUDIT_BANK_ACCOUNT")}
                    </ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="form-group row m-b-15">
                                    <label className="col-md-4 col-form-label">
                                        <b>{t("AUDIT_BANK")}</b>
                                    </label>
                                    <div className="col-md-7">
                                        <Select
                                            name="auditBankId"
                                            options={auditBankOption}
                                            placeholder={
                                                auditBankOption.filter(
                                                    (option) => option.value == auditBankId
                                                )[0] !== undefined
                                                    ? auditBankOption.filter(
                                                        (option) => option.value == auditBankId
                                                    )[0].label
                                                    : ""
                                            }
                                            value={auditBankOption.filter(
                                                (option) => option.value == auditBankId
                                            )}
                                            onChange={(e) => {
                                                unregister("auditBankId");
                                                setAuditBankId(e.value);
                                            }}
                                        />
                                        {errors.auditBankId && (
                                            <div className="invalid-feedback">
                                                {t(errors.auditBankId.message)}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="form-group row m-b-15">
                                    <label className="col-md-4 col-form-label">
                                        <b>{t("AUDIT_BANK_ACCOUNT")}</b>
                                    </label>
                                    <div className="col-md-7">
                                        <Select
                                            name="auditBankAccountId"
                                            options={bankAccountOption}
                                            placeholder={
                                                bankAccountOption.filter(
                                                    (option) => option.value == auditBankAccountId
                                                )[0] !== undefined
                                                    ? bankAccountOption.filter(
                                                        (option) => option.value == auditBankAccountId
                                                    )[0].label
                                                    : ""
                                            }
                                            value={bankAccountOption.filter(
                                                (option) => option.value == auditBankAccountId
                                            )}
                                            onChange={(e) => {
                                                unregister("auditBankAccountId");
                                                setAuditBankAccountId(e.value);
                                            }}
                                        />
                                        {errors.auditBankAccountId && (
                                            <div className="invalid-feedback">
                                                {t(errors.auditBankAccountId.message)}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button type="submit" className="btn btn-primary">
                            {t("CONFIRM")}
                        </button>{" "}
                        <button
                            type="button"
                            className="btn btn-danger"
                            onClick={() => {
                                setWithdrawalModal(!withdrawalModal);
                            }}
                        >
                            {t("CANCEL")}
                        </button>
                    </ModalFooter>
                </form>
            </Modal>
        </div>
    );
};

export default TransactionDetail;