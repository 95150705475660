import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { Language, SessionKey } from "./util/Constant";

import common_en from "./translations/en/common.json";
import common_cn from "./translations/cn/common.json";

const _resources = {};

_resources[Language._ENGLISH] = { common: common_en };
_resources[Language._CHINESE] = { common: common_cn };

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        debug: false,
        resources: _resources,
        lng: sessionStorage.getItem(SessionKey._Language) || Language._DEFAULT,
        fallbackLng: [Language._DEFAULT, Language._ENGLISH, Language._CHINESE, Language._BAHASA_MELAYU],
        keySeparator: false,

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;