﻿import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import { useHistory } from "react-router-dom";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import {
  ApiKey,
  ApiUrl,
  LanguageKey,
  NotificationType
} from "../../util/Constant";
import {
    stringIsNullOrEmpty,
    createFormBody
} from "../../util/Util";
import { useLocation } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { showMessage, setSaveAdminLog } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import { fetch } from "whatwg-fetch";
import ApiEngine from "../../util/ApiEngine";
import Select from "react-select";

/// <summary>
/// Author : -
/// </summary>

const PushNotificationDetail = (props) => {
  const _DETAIL_TAB = "1";
  const _DFAULT_LANGUAGE_VALUE = -1;

  const TAG_OPTIONS = [
   // { value: "{pin}", label: "pin" },
   // { value: "{phonenumber}", label: "phonenumber" },
    { value: "{wallet}", label: "wallet" },
    { value: "{datetime}", label: "datetime" },
    { value: "{withdrawalamount}", label: "withdrawalamount" },
    { value: "{depositamount}", label: "depositamount" },
    { value: "{id}", label: "id" },
    { value: "{status}", label: "status" },
    { value: "{username}", label: "username" },
    { value: "{referralcode}", label: "referralcode" },
    { value: "{commissionamount}", label: "commissionamount" },
   // { value: "{TAC}", label: "TAC" },
  ];

  const MESSAGE_TYPE = [
    { value: "general", label: "General" },
    { value: "automate", label: "Automate" },
  ];

  let _history = useHistory();
  const { t } = useTranslation(LanguageKey._PRIMARY);
  const _location = useLocation();
  const _dispatch = useDispatch();
  const { register, unregister, handleSubmit, errors, control, setValue} = useForm();
  const [pushNotificationId, setPushNotificationId] = useState("");
  const [activeTab, setActiveTab] = useState(_DETAIL_TAB);
  const [languages, setLanguages] = useState([]);
  const [languageContent, setLanguageContent] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState( _DFAULT_LANGUAGE_VALUE);
  const [contentError, setContentError] = useState("");
  const [cursorPosition, setCursorPosition] = useState(0);
  const [notificationTypeShow, setNotificationTypeShow] = useState("");
  const [selectNotificationType, setSelectNotificationType] = useState("");

  /// <summary>
  /// Author : -
  /// </summary>
  const textareaBlurHandler = (e) => {
    setCursorPosition(e.target.selectionStart);
  };

  const tagChangeHandler = (e) => {
    let currentContentValueBody = languageContent.filter(
      (i) => i.id == selectedLanguage
    )[0]?.value.body;

    let processedValue = "";

    if (stringIsNullOrEmpty(currentContentValueBody)) {
      processedValue = e.value;
    } else {
      processedValue =
      currentContentValueBody.substr(0, cursorPosition) +
        e.value +
        currentContentValueBody.substr(cursorPosition);
    }

    setLanguageContent(
      languageContent.map((content) =>
        content.id == selectedLanguage
          ? { ...content, 
            value: {
              title: languageContent.filter(
                (i) => i.id == selectedLanguage
              [0]?.value.title),
              body: processedValue 
            }
          }
          : content
      )
    );

    setCursorPosition(cursorPosition + e.value.length);
  };

  /// <summary>
  /// Author : -
  /// </summary>
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    init();
  }, []);

  /// <summary>
  /// Author : -
  /// </summary>
    async function init() {
      setValue("notificationTypeId", NotificationType._DEPOSIT_TYPE);
      setSelectNotificationType(NotificationType._DEPOSIT_TYPE);
      setNotificationTypeShow("general");
      let storedLanguageContent = [];
      let storedTitle = "";
      let storedBody = "";

      if (_location.state) {
          var apiUrl =
              ApiUrl._API_GET_PUSH_NOTIFICATION_BY_ID +
              "?includeLanguageContent=true";
          apiUrl += "&id=" + _location.state.id;
          let responseJson = await ApiEngine.get(apiUrl);

          if (responseJson[ApiKey._API_SUCCESS_KEY]) {
              setPushNotificationId(responseJson[ApiKey._API_DATA_KEY]["id"]);
              setValue(
                "notificationTypeId",
                  responseJson[ApiKey._API_DATA_KEY]["notificationTypeId"]
              );
              setNotificationTypeShow(responseJson[ApiKey._API_DATA_KEY]["messageType"])
              setSelectNotificationType(responseJson[ApiKey._API_DATA_KEY]["notificationTypeId"]);
              storedLanguageContent =
                 responseJson[ApiKey._API_DATA_KEY]["pushNotificationLanguages"];
              storedTitle = responseJson[ApiKey._API_DATA_KEY]["title"];
              storedBody = responseJson[ApiKey._API_DATA_KEY]["body"];
          } else {
              throw t(responseJson[ApiKey._API_MESSAGE_KEY]);
          }
      }

    let languageJson = await ApiEngine.get(
      ApiUrl._API_GET_PREFERENCE_LANGUAGE
    );

    if (languageJson[ApiKey._API_SUCCESS_KEY]) {
      let tempLanguages = [
        { label: "Default", value: _DFAULT_LANGUAGE_VALUE },
      ];
      let tempContents = [
          {
              name: "content", value: {
                  title: storedTitle,
                    body: storedBody
              }, id: _DFAULT_LANGUAGE_VALUE
              }
      ];

      languageJson[ApiKey._API_DATA_KEY].map((lang) => {
          let tempTitle = "";
          let tempBody = "";

        if (
          storedLanguageContent.filter((i) => i.languageId == lang.id)
            .length > 0
        ) {
            tempTitle =
                storedLanguageContent.filter((i) => i.languageId == lang.id)[0]
                    .title ?? "";

            tempBody =
            storedLanguageContent.filter((i) => i.languageId == lang.id)[0]
                .body ?? "";
        }

        tempLanguages.push({ label: lang.name, value: lang.id });
          tempContents.push({
              name: "content_" + lang.id,
              value: {
                  title: tempTitle,
                  body: tempBody
              },
          id: lang.id,
        });
      });

      setLanguageContent(tempContents);
      setLanguages(tempLanguages);
    } else {
      throw t(languageJson[ApiKey._API_MESSAGE_KEY]);
      }
  }
 
  /// <summary>
  /// Author : -
  /// </summary>
    const submitForm = async (data, e) => {
        if (
            stringIsNullOrEmpty(
               languageContent.filter((i) => i.id == _DFAULT_LANGUAGE_VALUE)[0].value.body 
            ) || stringIsNullOrEmpty(
              languageContent.filter((i) => i.id == _DFAULT_LANGUAGE_VALUE)[0].value.title 
           )
        ) {
            setContentError("PLEASE_ENTER_DEFAULT_TITLE_BODY");
            setSelectedLanguage(_DFAULT_LANGUAGE_VALUE);
            return;
        }

        setContentError("");

        let notificationTypeId = "";

        if (data.notificationTypeId === undefined) {
            notificationTypeId = selectNotificationType
        }
        else {
            notificationTypeId = data.notificationTypeId
        }
        
        let params = {
            notificationTypeId: notificationTypeShow === 'general' ? NotificationType._GENERAL : notificationTypeId,
        };

        languageContent.map((content) => {
            params[content.name] = JSON.stringify(content.id == selectedLanguage
                ? {
                    ...content,
                    value:
                    {
                        title: data.title,
                        body: data.body,
                    }
                }
                : content);
        });

        if (!stringIsNullOrEmpty(pushNotificationId)) {
            params["id"] = pushNotificationId;
            languageContent.map((content) => {
                if (content.id == -1) {
                    params["title"] = content.value.title
                    params["body"] = content.value.body
                }
            });
       
            params["messageType"] = notificationTypeShow;
            _dispatch(setSaveAdminLog("EDIT_BO_PUSH_NOTIFICATION", _history.location.pathname, params));
        } else {
            params["title"] = languageContent[0].value.title;
            params["body"] =  languageContent[0].value.body;
            params["messageType"] = notificationTypeShow;
            _dispatch(setSaveAdminLog("CREATE_BO_PUSH_NOTIFICATION", _history.location.pathname, params));
        }

        let formBody = createFormBody(params);
        
        let responseJson = await ApiEngine.post(
            ApiUrl._API_CREATE_OR_UPDATE_PUSH_NOTIFICATION,
            formBody
        );

        _dispatch(
            showMessage(
                responseJson[ApiKey._API_SUCCESS_KEY],
                t(responseJson[ApiKey._API_MESSAGE_KEY])
            )
        );

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setPushNotificationId(responseJson[ApiKey._API_DATA_KEY]["id"]);
        }
    }

  return (
    <div>
      <h1 className="page-header">
        {pushNotificationId ? t("EDIT_PUSH_NOTIFICATION") : t("ADD_PUSH_NOTIFICATION")}
        <NavigationButton history={_history} />
      </h1>
      {pushNotificationId && (
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === _DETAIL_TAB })}
              onClick={() => {
                toggle(_DETAIL_TAB);
              }}
            >
              {t("DETAIL")}
            </NavLink>
          </NavItem>
        </Nav>
      )}
      <TabContent activeTab={activeTab}>
        <TabPane tabId={_DETAIL_TAB}>
          <div className="row">
            <div className="col-xl-12">
              <Panel>
                <PanelBody>
                  <form onSubmit={handleSubmit(submitForm)}>
                    <div className="row">
                    
                    <div className="col-lg-8">
                        <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        <b>{t("MESSAGE_TYPE")}</b>
                      </label>

                      <div className="col-md-7">
                        <Select
                            name="messageType"
                            options={MESSAGE_TYPE}
                            onChange={(e) => {
                                setNotificationTypeShow(e.value);
                            }}
                            value={MESSAGE_TYPE.filter(
                                (option) => option.value === notificationTypeShow
                             )}
                        />
                        </div>
                      </div>
                      </div>

                    <div className="col-lg-8">
                      <div className="form-group row m-b-15">
                        <label className="col-md-4 col-form-label">
                          <b>{t("LANGUAGE")}</b>
                        </label>
                        <div className="col-md-7">
                          <Select
                            value={languages.filter(
                              (option) => option.value == selectedLanguage
                            )}
                            options={languages}
                            onChange={(e) => {
                              setSelectedLanguage(e.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    {notificationTypeShow === "automate" &&
                        <div className="col-lg-8">
                            <div className="form-group row m-b-15">
                                <label className="col-md-4 col-form-label">
                                    <b>{t("NOTIFICATION_TYPE")}</b>
                                </label>
                                <div className="col-md-7">
                                    <Controller
                                        name="notificationTypeId"
                                        control={control}
                                        render={({ value, onChange }) => {
                                            return (
                                                <select
                                                    className="form-control"
                                                    onChange={(e) => {
                                                        onChange(e.target.value);
                                                        setSelectNotificationType(e.target.value);
                                                    }
                                                    }
                                                    value={selectNotificationType}
                                                >
                                                    <option value={NotificationType._DEPOSIT_TYPE}>
                                                        {t("SMS_TYPE_DEPOSIT")}
                                                    </option>
                                                    <option value={NotificationType._WITHDRAWAL_TYPE}>
                                                        {t("SMS_TYPE_WITHDRAWAL")}
                                                    </option>
                                                    <option value={NotificationType._NEW_DOWNLINE_TYPE}>
                                                      {t("SMS_TYPE_NEW_DOWNLINE")}
                                                    </option>
                                                    <option value={NotificationType._COMMISSION_RELEASED_TYPE}>
                                                      {t("SMS_TYPE_COMMISSION_RELEASED")}
                                                    </option>
                                                </select>
                                            );
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    }

                      <div className="col-lg-8">
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>{t("TITLE")}</b>
                          </label>
                          <div className="col-md-7">
                            <input
                              type="text"
                              className="form-control"
                              placeholder={t("PLEASE_ENTER_TITLE")}
                              ref={register}
                              name="title"
                              maxlength="50"
                              value={
                                languageContent.filter(
                                    (i) => i.id == selectedLanguage
                                )[0]?.value.title
                              }
                              onBlur={textareaBlurHandler}
                              onChange={(e) =>
                                setLanguageContent(
                                  languageContent.map((content) =>
                                    content.id == selectedLanguage
                                      ? { ...content, value: {
                                        title: e.target.value,
                                        body: languageContent.filter(
                                          (i) => i.id == selectedLanguage
                                        )[0]?.value.body
                                      }}
                                      : content
                                  )
                                )
                              }
                            />
                            {errors.title && (
                              <div className="invalid-feedback">
                                {t(errors.title.message)}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>
                              {t("BODY")}
                              {" "}
                              (
                                {
                                  languages.filter(
                                    (option) => option.value == selectedLanguage
                                  )[0]?.label
                                }
                              )
                            </b>
                           
                          </label>
                            <div className="col-md-7">

                            { notificationTypeShow === "automate" && 

                              <Select
                                options={TAG_OPTIONS}
                                onChange={tagChangeHandler}
                              />
                            }
                            <textarea
                                className="form-control"
                                rows="5"
                                style={{ marginTop: "10px" }}
                                ref={register}
                                name="body"
                                maxlength="200"
                                value={
                                  languageContent.filter(
                                    (i) => i.id == selectedLanguage
                                  )[0]?.value.body
                                }
                                onBlur={textareaBlurHandler}
                                onChange={(e) =>
                                  setLanguageContent(
                                    languageContent.map((content) =>
                                      content.id == selectedLanguage
                                        ? { ...content, value: {
                                          title: languageContent.filter(
                                            (i) => i.id == selectedLanguage
                                          )[0]?.value.title,
                                          body: e.target.value
                                        }}
                                        : content
                                    )
                                  )
                                }
                            ></textarea>
                            {!stringIsNullOrEmpty(contentError) && (
                              <div className="invalid-feedback">
                                {t(contentError)}
                              </div>
                            )}

                          { notificationTypeShow === "automate" && 
                            <div className="m-t-10" style={{ lineHeight: "28px" }}>
                            <b>Tags to be used for dynamic value, </b>
                            {/* <span className="label label-blue f-s-12 m-2">
                              &#123;TAC&#125;
                            </span>
                            <span className="label label-blue f-s-12 m-2">
                              &#123;pin&#125;
                            </span>
                            <span className="label label-blue f-s-12 m-2">
                              &#123;phonenumber&#125;
                            </span> */}
                            <span className="label label-blue f-s-12 m-2">
                              &#123;wallet&#125;
                            </span>
                            <span className="label label-blue f-s-12 m-2">
                              &#123;datetime&#125;
                            </span>
                            <span className="label label-blue f-s-12 m-2">
                              &#123;withdrawamount&#125;
                            </span>
                            <span className="label label-blue f-s-12 m-2">
                              &#123;depositamount&#125;
                            </span>
                            <span className="label label-blue f-s-12 m-2">
                              &#123;id&#125;
                            </span>
                            <span className="label label-blue f-s-12 m-2">
                              &#123;status&#125;
                            </span>
                          </div>
                            }
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-2"></div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-lg-3">
                        <div className="form-group">
                          <button type="submit" className="btn btn-primary">
                            {t("SUBMIT")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </PanelBody>
              </Panel>
            </div>
          </div>
        </TabPane>
      </TabContent>
    </div>
  );
};

export default withRouter(PushNotificationDetail);
