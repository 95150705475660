import React, { useState, useEffect } from "react";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import "../../assets/css/custom.css";
import { useHistory } from "react-router-dom";
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { navigateTo, stringIsNullOrEmpty } from "../../util/Util";
import {
  WebUrl,
  ApiKey,
  ApiUrl,
  AccessRight,
  LanguageKey,
  AllVipOption,
  DisplayDevice,
} from "../../util/Constant";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import { checkIfPermissionExist } from "../../util/PermissionChecker";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";

/// <summary>
/// Author : -
/// </summary>

const ManagePromoBanner = (props) => {
  const { t } = useTranslation(LanguageKey._PRIMARY);
  let _history = useHistory();
  const [bannerData, setBannerData] = useState([]);

  let _tableColumns = [
    {
      Header: "TITLE",
      accessor: "title",
      disableSortBy: true,
    },
    {
      Header: "VIP",
      accessor: "vipId",
      Cell: ({ row }) => {
        if (stringIsNullOrEmpty(row.original.vipId)) {
          return <>{AllVipOption._LABEL}</>;
        } else {
          return <>{row.original.vipModel.name}</>;
        }
      },
    },
    {
      Header: "DISPLAY_DEVICES",
      accessor: "displayDevice",
      Cell: ({ row }) => {
        if (row.original.displayDevice === DisplayDevice._HOMEPAGE_PROMO_MOBILE) {
          return <>{t("MOBILE")}</>;
        } else if (row.original.displayDevice === DisplayDevice._HOMEPAGE_PROMO_DESKTOP) {
          return <>{t("DESKTOP")}</>;
        } else {
          return <>{row.original.vipModel.name}</>;
        }
      },
    },
    {
      Header: "BANNER_IMAGE",
      accessor: "productImage",
      Cell: ({ row }) => (
        <div>
          {!stringIsNullOrEmpty(row.original.mobileImage) && row.original.displayDevice === DisplayDevice._HOMEPAGE_PROMO_MOBILE ? (
            <img
              style={{ height: "100px" }}
              src={row.original.mobileImage}
              alt="mobile-banner"
            />
          ) : <>
            {!stringIsNullOrEmpty(row.original.desktopImage) && row.original.displayDevice === DisplayDevice._HOMEPAGE_PROMO_DESKTOP ? (
              <img
                style={{ height: "100px" }}
                src={row.original.desktopImage}
                alt="desktop-banner"
              />
            ) : (
              <span> - </span>
            )}
          </>
          }
        </div>
      ),
      disableSortBy: true,
    },
    {
      Header: "STATUS",
      accessor: "status",
      Cell: ({ row }) => (
        <div className="btn-group m-r-5 m-b-5">
          {row.original.status == 1 ? (
            <span className="badge badge-secondary badge-green">
              {t("ACTIVE")}
            </span>
          ) : (
            <span className="badge badge-secondary badge-danger">
              {t("SUSPENDED")}
            </span>
          )}
        </div>
      ),
      disableSortBy: true,
      style: { overflow: "visible" },
    },
    {
      id: "action",
      Header: "",
      Cell: ({ row }) =>
        checkIfPermissionExist(
          AccessRight._BANNER_SETTING_PERMISSION,
          true
        ) && (
          <div className="btn-group m-r-5 m-b-5">
            <UncontrolledButtonDropdown>
              <DropdownToggle caret color="default">
                <i className="fas fa-cog"></i>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={() => {
                    navigateTo(
                      _history,
                      WebUrl._URL_CREATE_OR_UPDATE_PROMO_BANNER,
                      row.original
                    );
                  }}
                >
                  <span className="text-warning">{t("EDIT")}</span>
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    navigateTo(_history, WebUrl._URL_CREATE_OR_UPDATE_PROMO_BANNER, {
                      refId: row.original.id,
                    });
                  }}
                >
                  <span className="text-primary">{t("CLONE")}</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        ),
      disableSortBy: true,
      disableFilters: true,
      width: 100,
      style: { overflow: "visible" },
    },
  ];

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    fetch(ApiUrl._API_GET_USER_PROMO_BANNER + "?all=true&isCompanyUser=true", {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          setBannerData(responseJson[ApiKey._API_DATA_KEY]);
        }
      });
  }, []);

  return (
    <div>
      <h1 className="page-header">
        {t("PROMO_BANNERS")}
        {checkIfPermissionExist(
          AccessRight._BANNER_SETTING_PERMISSION,
          true
        ) && (
            <NavigationButton
              history={_history}
              url={WebUrl._URL_CREATE_OR_UPDATE_PROMO_BANNER}
              buttonText={t("ADD_PROMO_BANNER")}
            />
          )}
      </h1>
      <Panel>
        <PanelBody>
          <ReactTable data={bannerData} columns={_tableColumns} />
        </PanelBody>
      </Panel>
    </div>
  );
};

export default ManagePromoBanner;
