import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import {
    ApiKey,
    ApiUrl,
    WebUrl,
    TransactionStatus,
    TransactionType,
    LanguageKey,
    ReadWritePermission,
    AccessRight,
    Role
} from "../../util/Constant";
import {
    stringIsNullOrEmpty,
    numberWithCommas,
    navigateTo,
} from "../../util/Util";
import moment from "moment";
import { useTranslation } from "react-i18next";
import ApiEngine from "../../util/ApiEngine";
import { useDispatch, useSelector } from "react-redux";
import { showMessage } from "../../redux/AppAction";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useForm } from "react-hook-form";

/// <summary>
/// Author : -
/// </summary>
const ExcelDetail = (props) => {
    const { t } = useTranslation(LanguageKey._PRIMARY);
    let _history = useHistory();
    let _dispatch = useDispatch();
    const _location = useLocation();
    const authState = useSelector(state => state.authState);
    const { register, unregister, handleSubmit, errors } = useForm();



    /// <summary>
    /// Author : -
    /// </summary>
    useEffect(() => {
        init();
    }, []);


    /// <summary>
    /// Author : -
    /// </summary>
    const init = async () => {
        try {
            console.log("your code here");
        }
        catch (ex) {
            //_dispatch(showMessage(false, t(ex)));
        }
    };

    return (
        <div>
            <h1 className="page-header">
                {t("CHECK_EXCEL")}
                <NavigationButton history={_history} />
            </h1>
         
        </div>
    );
};

export default ExcelDetail;