import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import { useHistory } from "react-router-dom";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import {
  ApiKey,
  ApiUrl,
  LanguageKey,
  ContentTypeString,
} from "../../util/Constant";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { stringIsNullOrEmpty, createFormBody } from "../../util/Util";
import { setBusy, setIdle, showMessage, setSaveAdminLog } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import ManageConfigurationLanguage from "./ManageConfigurationLanguage";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import { fetch } from "whatwg-fetch";

/// <summary>
/// Author : -
/// </summary>

const VipBenefitDetail = (props) => {
  const _DETAIL_TAB = "1";
  const _LANGUAGE_TAB = "2";
  const { t } = useTranslation(LanguageKey._PRIMARY);
  let _history = useHistory();
  const _location = useLocation();
  const _dispatch = useDispatch();

  const { register, unregister, handleSubmit, errors, setError } = useForm();

  const [vipBenefitId, setVipBenefitId] = useState("");
  const [title, setTitle] = useState("");
  const [status, setStatus] = useState(true);

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    init();
  }, []);

  const [activeTab, setActiveTab] = useState(_DETAIL_TAB);

  /// <summary>
  /// Author : -
  /// </summary>
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  /// <summary>
  /// Author : -
  /// </summary>
  async function init() {
    if (_location.state) {
      var apiUrl = ApiUrl._API_GET_VIP_BENEFIT_BY_ID;
      apiUrl += "?id=" + _location.state.id;
      await fetch(apiUrl, {
        method: ApiKey._API_GET,
        headers: {
          "Content-Type": ApiKey._API_FORM_URLENCODED,
          "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
        },
      })
        .then((response) => response.json())
        .then(async (responseJson) => {
          if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setVipBenefitId(responseJson[ApiKey._API_DATA_KEY]["id"]);
            setTitle(responseJson[ApiKey._API_DATA_KEY]["title"]);
            setStatus(responseJson[ApiKey._API_DATA_KEY]["status"]);
          }
        });
    }
  }

  /// <summary>
  /// Author : -
  /// </summary>
  const submitForm = async (data, e) => {
    let params = {
      title: data.title,
      status: data.status,
    };

    if (!stringIsNullOrEmpty(vipBenefitId)) {
      params["id"] = vipBenefitId;
      _dispatch(setSaveAdminLog("EDIT_BO_VIP_BENEFIT", _history.location.pathname, params));
    } else {
      _dispatch(setSaveAdminLog("CREATE_BO_VIP_BENEFIT", _history.location.pathname, params));
    }

    let formBody = createFormBody(params);
    _dispatch(setBusy());

    fetch(ApiUrl._API_CREATE_OR_UPDATE_VIP_BENEFIT, {
      method: ApiKey._API_POST,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
      body: formBody,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        _dispatch(setIdle());
        _dispatch(
          showMessage(
            responseJson[ApiKey._API_SUCCESS_KEY],
            t(responseJson[ApiKey._API_MESSAGE_KEY])
          )
        );

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          setVipBenefitId(responseJson[ApiKey._API_DATA_KEY]["id"]);

          _history.replace({
            pathname: _location.pathname,
            state: {
              id: responseJson[ApiKey._API_DATA_KEY]["id"],
            },
          });
        }
      });
  };

  return (
    <div>
      <h1 className="page-header">
        {vipBenefitId
          ? t("EDIT_VIP_BENEFIT_TITLE")
          : t("ADD_VIP_BENEFIT_TITLE")}
        <NavigationButton history={_history} />
      </h1>
      {vipBenefitId && (
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === _DETAIL_TAB })}
              onClick={() => {
                toggle(_DETAIL_TAB);
              }}
            >
              {t("DETAIL")}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === _LANGUAGE_TAB })}
              onClick={() => {
                toggle(_LANGUAGE_TAB);
              }}
            >
              {t("LANGUAGE")}
            </NavLink>
          </NavItem>
        </Nav>
      )}
      <TabContent activeTab={activeTab}>
        <TabPane tabId={_DETAIL_TAB}>
          <div className="row">
            <div className="col-xl-12">
              <Panel>
                <PanelBody>
                  <form onSubmit={handleSubmit(submitForm)}>
                    <div className="row">
                      <div className="col-lg-8">
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>{t("TITLE")}</b>
                          </label>
                          <div className="col-md-7">
                            <input
                              type="text"
                              className="form-control"
                              placeholder={t("PLEASE_ENTER_TITLE")}
                              name="title"
                              ref={register({ required: "PLEASE_ENTER_TITLE" })}
                              defaultValue={title}
                            />
                            {errors.title && (
                              <div className="invalid-feedback">
                                {t(errors.title.message)}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>{t("STATUS")}</b>
                          </label>
                          <div className="col-md-7">
                            <div className="switcher">
                              <input
                                type="checkbox"
                                name="status"
                                id="status"
                                onChange={(e) => setStatus(e.target.checked)}
                                value={true}
                                checked={status}
                                ref={register}
                              />
                              <label htmlFor="status"></label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-lg-3">
                        <div className="form-group">
                          <button type="submit" className="btn btn-primary">
                            {t("SUBMIT")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </PanelBody>
              </Panel>
            </div>
          </div>
        </TabPane>
        <TabPane tabId={_LANGUAGE_TAB}>
          {vipBenefitId && (
            <div className="row">
              <div className="col-lg-12">
                <ManageConfigurationLanguage
                  id={vipBenefitId}
                  type={ContentTypeString._VIP_BENEFIT}
                />
              </div>
            </div>
          )}
        </TabPane>
      </TabContent>
    </div>
  );
};

export default withRouter(VipBenefitDetail);
