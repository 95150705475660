import React, { useState, useEffect, useMemo, useCallback } from 'react';
import ReactTable from '../../components/constantComponent/reactTable/ReactTable';
import { Panel, PanelHeader, PanelBody } from './../../components/panel/panel.jsx';
import { useHistory } from "react-router-dom";
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import { navigateTo } from '../../util/Util';
import { ApiKey, ApiUrl, WebUrl, AccessRight, TransactionType, TransactionStatus, PanelType, LanguageKey, BankingChannelName, DEFAULT_PAGE_SIZE } from '../../util/Constant';
import { setBusy, setIdle, showMessage, setSaveAdminLog } from "../../redux/AppAction";
import { useDispatch, useSelector } from "react-redux";
import { stringIsNullOrEmpty, createFormBody, numberWithCurrencyFormat2 } from "../../util/Util";
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import ApiEngine from '../../util/ApiEngine';
import { AsyncPaginate } from "react-select-async-paginate";
import SweetAlert from 'react-bootstrap-sweetalert';

const TransactionSummaryReport = props => {
    const { t } = useTranslation(LanguageKey._PRIMARY);
    let _history = useHistory();
    const _dispatch = useDispatch();
    const { register, unregister, handleSubmit, errors } = useForm();
    const [calendarStartDate, setCalendarStartDate] = useState(moment().startOf('day').format("YYYY-MM-DD HH:mm:ss"));
    const [calendarEndDate, setCalendarEndDate] = useState(moment().endOf('day').format("YYYY-MM-DD HH:mm:ss"));
    const [memberId, setMemberId] = useState();
    const [initFlag, setInitFlag] = useState(false);
    const [transactionApiUrl, setTransactionApiUrl] = useState("");

    const [username, setUsername] = useState("");
    const [showDialog, setShowDialog] = useState()


    /// <summary>
    /// Author : -
    /// </summary>
    const handleEvent = (event, picker) => {
        setCalendarStartDate(moment(picker.startDate).format("YYYY-MM-DD HH:mm:ss"));
        setCalendarEndDate(moment(picker.endDate).format("YYYY-MM-DD HH:mm:ss"));
    }


    /// <summary>
    /// Author : -
    /// </summary>
    const init = async () => {
        if (props.username && props.id) {
            setUsername(props.username);
            setMemberId(props.id);
        }
        try {

            setInitFlag(true);
        }
        catch (ex) {
            _dispatch(showMessage(false, t(ex)));
        }
    }

    /// <summary>
    /// Author : -
    /// </summary>
    const getSummaryTransaction = async () => {

        if (calendarEndDate > moment(calendarStartDate).add(7, 'days').format("YYYY-MM-DD HH:mm:ss")){
            setShowDialog(true);
            return 
        }
        let fetchUrl = ApiUrl._API_GET_TRANSACTION_SUMMARY_REPORT;
        fetchUrl += "?StartDate=" + calendarStartDate + "&EndDate=" + calendarEndDate;
        setTransactionApiUrl(fetchUrl);
    }

    let _tableColumns = [
        //{
        //    id: "id",
        //    Header: "ID",
        //    accessor: "id",
        //    minWidth: 100,
        //    Footer: () => {
        //        return <span><b>{t("TOTAL")}: </b></span>
        //    },
        //},
        {
            Header: t("USERNAME"),
            accessor: "username",
            Cell: ({ row }) => {
                return <>{t(row.original.username)}</>
            },
            minWidth: 100,
            Footer: () => {
                return <span><b>{t("TOTAL")}: </b></span>
            },
        },

        {
            Header: t("DEPOSIT"),
            accessor: "deposit",
            Cell: ({ row }) => {
                return <>{numberWithCurrencyFormat2(row.original.deposit)}</>
            },
            minWidth: 100,
            Footer: ({ page }) => {
                const debitSum = page.reduce((sum, currentValue) => {
                    if (currentValue.original.deposit) {
                        return sum + (parseFloat(currentValue.original.deposit));
                    }
                    else {
                        return sum;
                    }
                }, 0);
                return <span><b>{numberWithCurrencyFormat2(parseFloat(debitSum))}</b></span>
            }
        },
        {
            Header: t("WITHDRAWAL"),
            accessor: "withdrawal",
            Cell: ({ row }) => {
                return <>{numberWithCurrencyFormat2(row.original.withdrawal)}</>
            },
            minWidth: 100,
            Footer: ({ page }) => {
                const debitSum = page.reduce((sum, currentValue) => {
                    if (currentValue.original.withdrawal) {
                        return sum + (parseFloat(currentValue.original.withdrawal));
                    }
                    else {
                        return sum;
                    }
                }, 0);
                return <span><b>{numberWithCurrencyFormat2(parseFloat(debitSum))}</b></span>
            }
        },
        {
            Header: t("TRANSFER_OUT"),
            accessor: "transfer",
            Cell: ({ row }) => {
                return <>{numberWithCurrencyFormat2(row.original.transfer)}</>
            },
            minWidth: 100,
            Footer: ({ page }) => {
                const debitSum = page.reduce((sum, currentValue) => {
                    if (currentValue.original.transfer) {
                        return sum + (parseFloat(currentValue.original.transfer));
                    }
                    else {
                        return sum;
                    }
                }, 0);
                return <span><b>{numberWithCurrencyFormat2(parseFloat(debitSum))}</b></span>
            }
        },
        {
            Header: t("SHAREHOLDER"),
            accessor: "shareholder",
            Cell: ({ row }) => {
                return <>{numberWithCurrencyFormat2(row.original.shareholder)}</>
            },
            minWidth: 100,
            Footer: ({ page }) => {
                const debitSum = page.reduce((sum, currentValue) => {
                    if (currentValue.original.shareholder) {
                        return sum + (parseFloat(currentValue.original.shareholder));
                    }
                    else {
                        return sum;
                    }
                }, 0);
                return <span><b>{numberWithCurrencyFormat2(parseFloat(debitSum))}</b></span>
            }
        },
        {
            Header: t("COMMISSION"),
            accessor: "commission",
            Cell: ({ row }) => {
                return <>{numberWithCurrencyFormat2(row.original.commission)}</>
            },
            minWidth: 100,
            Footer: ({ page }) => {
                const debitSum = page.reduce((sum, currentValue) => {
                    if (currentValue.original.commission) {
                        return sum + (parseFloat(currentValue.original.commission));
                    }
                    else {
                        return sum;
                    }
                }, 0);
                return <span><b>{numberWithCurrencyFormat2(parseFloat(debitSum))}</b></span>
            }
        },
        {
            Header: t("REWARDS"),
            accessor: "rewards",
            Cell: ({ row }) => {
                return <>{numberWithCurrencyFormat2(row.original.rewards)}</>
            },
            minWidth: 100,
            Footer: ({ page }) => {
                const debitSum = page.reduce((sum, currentValue) => {
                    if (currentValue.original.rewards) {
                        return sum + (parseFloat(currentValue.original.rewards));
                    }
                    else {
                        return sum;
                    }
                }, 0);
                return <span><b>{numberWithCurrencyFormat2(parseFloat(debitSum))}</b></span>
            }
        },

    ]

  async function adminLog() {

    let params = {
      "startDate": calendarStartDate,
      "endDate": calendarEndDate,
    };

    _dispatch(setSaveAdminLog("VIEW_BO_TRANSACTION_SUMMARY_REPORT", _history.location.pathname, params));
  }

    return (
        <div>
            {showDialog && (
                <SweetAlert
                    warning
                    confirmBtnText={t("CONFIRM")}
                    confirmBtnBsStyle="danger"
                    title={t("WARNING")}
                    onConfirm={() => {
                        setShowDialog(false);
                    }}
                >
                    {t("MAX_DATE_RANGE_7_DAYS") }
                </SweetAlert>
            )}
            <h1 className="page-header">{t("TRANSACTION_SUMMARY_REPORT")}</h1>
            <Panel>
                <div className="row">
                    <div className="col-lg-12">
                        <PanelBody>
                            <div className="row">
                                <div className="col-lg-4">
                                    <div className="form-group" style={{ display: "flex" }}>
                                        <div className="input-group" style={{ width: "100%", flexFlow: "nowrap" }}>
                                            <div className="input-group-prepend"><span className="input-group-text"><i className="fa fa-calendar"></i></span></div>
                                            <div className="input-group-append" style={{ width: "100%" }}>
                                                <DateRangePicker
                                                    containerStyles={{ width: "100%" }}
                                                    alwaysShowCalendars={true}
                                                    timePicker={false}
                                                    timePickerSeconds={false}
                                                    onApply={handleEvent}
                                                    ranges={{
                                                        'Today': [moment().startOf('day'), moment().endOf('day')],
                                                        'Yesterday': [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],
                                                        'Last 7 Days': [moment().subtract(6, 'days').startOf('day'), moment().endOf('day')],
                                                        'This Week': [moment().startOf('week'), moment().endOf('week')],
                                                    }}>
                                                    <input type="text" className="form-control" value={calendarStartDate + " - " + calendarEndDate} />
                                                </DateRangePicker>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2">
                                  <button type="button" onClick={() => { getSummaryTransaction(); adminLog() }} className="btn btn-primary">{t("SEARCH")}</button>
                                </div>
                            </div>
                            <hr />
                            <ReactTable fetchUrl={transactionApiUrl} columns={_tableColumns} renderFooter={true} />
                        </PanelBody>
                    </div>
                </div>

            </Panel>
        </div>
    )
}

export default TransactionSummaryReport;