import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import Accordion from 'react-bootstrap/Accordion';
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import { useTranslation } from "react-i18next";
import { LanguageKey } from "../../util/Constant.js";

const FAQHelp = (props) => {
    const { t, i18n } = useTranslation(LanguageKey._PRIMARY);
    const [activeEventKey0, setActiveEventKey0] = useState(false);
    const [activeEventKey1, setActiveEventKey1] = useState(false);
    const [activeEventKey2, setActiveEventKey2] = useState(false);
    const [activeEventKey3, setActiveEventKey3] = useState(false);
    const [activeEventKey4, setActiveEventKey4] = useState(false);
    const [activeEventKey5, setActiveEventKey5] = useState(false);
    const [activeEventKey6, setActiveEventKey6] = useState(false);

    return (
        <div id="faq">
            <h1 className="page-header" style={{ marginLeft: "20px" }}>
                {t("FAQ")}
            </h1>
            <Panel>
                <div className="row">
                    <div className="col-lg-12">
                        <PanelBody>
                            <Accordion defaultActiveKey={['-1']}>
                                <Accordion.Item eventKey="0" onClick={() => setActiveEventKey0(!activeEventKey0)}>
                                    <Accordion.Header><div>Cannot approve deposit/withdrawal</div><div style={{ fontSize: "20px", fontWeight: "bold", transform: activeEventKey0 ? "rotate(90deg)" : "unset" }}>{">"}</div></Accordion.Header>
                                    <Accordion.Body>
                                        1) Please check player transaction queue (2.9) to see if player is stuck in queue, and clear it. Please wait a few minutes if the player is not shown in the queue page. <br /><br />
                                    </Accordion.Body>
                                </Accordion.Item>
                                <hr style={{ margin: "0" }}/>
                                <Accordion.Item eventKey="1" onClick={() => setActiveEventKey1(!activeEventKey1)}>
                                    <Accordion.Header><div>Customer bets remain pending</div><div style={{ fontSize: "20px", fontWeight: "bold", transform: activeEventKey1 ? "rotate(90deg)" : "unset" }}>{">"}</div></Accordion.Header>
                                    <Accordion.Body>
                                        1) Go to Vendor's Backoffice to check if the customer bet is also pending or not<br /><br />
                                        2) If not, then contact the IT team about the issue.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <hr style={{ margin: "0" }} />
                                <Accordion.Item eventKey="2" onClick={() => setActiveEventKey2(!activeEventKey2)}>
                                    <Accordion.Header><div>Add/Edit new games from Pragmatic Play Slot vendor</div><div style={{ fontSize: "20px", fontWeight: "bold", transform: activeEventKey2 ? "rotate(90deg)" : "unset" }}>{">"}</div></Accordion.Header>
                                    <Accordion.Body>
                                        1) Go to Master Products Page (10.2). <br /><br />
                                        2) Click on gear icon > <b>Manage Game</b>. <br /><br />
                                        3) Click on gear icon again > <b>Edit</b>. <br /><br />
                                        4) Click on IsNewGame button to set it on/off.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <hr style={{ margin: "0" }} />
                                <Accordion.Item eventKey="3" onClick={() => setActiveEventKey3(!activeEventKey3)}>
                                    <Accordion.Header><div>Set games in MasterProducts with own lobby to UM</div><div style={{ fontSize: "20px", fontWeight: "bold", transform: activeEventKey3 ? "rotate(90deg)" : "unset" }}>{">"}</div></Accordion.Header>
                                    <Accordion.Body>
                                        1) Go to Products Page (10.3). <br /><br />
                                        2) Click on Game UM button to set it on/off.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <hr style={{ margin: "0" }} />
                                <Accordion.Item eventKey="4" onClick={() => setActiveEventKey4(!activeEventKey4)}>
                                    <Accordion.Header><div>Adding new games</div><div style={{ fontSize: "20px", fontWeight: "bold", transform: activeEventKey4 ? "rotate(90deg)" : "unset" }}>{">"}</div></Accordion.Header>
                                    <Accordion.Body>
                                        1) Go to Products (10.3). <br /><br />
                                        2) Find the provider name and click on the button on the right, then click 'Update Game List'. <br /><br />
                                        3) Double-check on member site if game is properly added.<br /><br />
                                        4) If the game(s) is not properly added, please contact IT team about the issue.<br /><br />
                                    </Accordion.Body>
                                </Accordion.Item>
                                <hr style={{ margin: "0" }} />
                                <Accordion.Item eventKey="5" onClick={() => setActiveEventKey5(!activeEventKey5)}>
                                    <Accordion.Header><div>New method of adding promo banners for MemberApp / MemberDesktop</div><div style={{ fontSize: "20px", fontWeight: "bold", transform: activeEventKey5 ? "rotate(90deg)" : "unset" }}>{">"}</div></Accordion.Header>
                                    <Accordion.Body>
                                        1) Go to Banner Page (12.2). <br /><br />
                                        2) Click on Add Banner button at the top right of the page. <br /><br />
                                        3) Insert all relevant details<br /><br />
                                        4) There are 5 types of banner locations to select from.<br /><br />
                                        For banner in Mobile slider banner at the top <b>Banner location = Mobile Main Banner</b>
                                        <br /><br />
                                        For banner in Mobile secondary banner below providers list. <b>Banner location = Mobile Secondary Banner</b>
                                        <br /><br />
                                        For main banner in Desktop. <b>Banner location = Desktop Page Main Banner</b>
                                        <br /><br />
                                        For sub banners in Desktop. <b>Banner location = Desktop Page Slider Banner</b>
                                        <br /><br />
                                        For popup banners in Desktop. <b>Banner location = Desktop Page Popup Banner</b>
                                        <br /><br />
                                        5) Click submit <br></br>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <hr style={{ margin: "0" }} />
                                <Accordion.Item eventKey="6" onClick={() => setActiveEventKey6(!activeEventKey6)}>
                                    <Accordion.Header><div>Add/Edit/Send push notification for Mobile App</div><div style={{ fontSize: "20px", fontWeight: "bold", transform: activeEventKey5 ? "rotate(90deg)" : "unset" }}>{">"}</div></Accordion.Header>
                                    <Accordion.Body>
                                        1) Go to Manage Push Notification Page (11.26). <br /><br />
                                        2) Click on Add Push Notification button at the top right of the page. <br /><br />
                                        3) Select one of the Message Type. There are 2 types of message type.<br /><br />
                                        For <b>General</b> type is send normal notification without dynamic value.<br /><br />
                                        For <b>Automate</b> type is send notificaiton with dynamic value. It is use for transaction enquiry.<br /><br />
                                        4) Select the <b>language</b> that want to set. <br /><br />
                                        5) Insert title and body. <br /><br />
                                        6) Click submit. <br /><br />
                                        7) Click on gear icon > <b>Edit</b>. <br /><br />
                                        8) Click on gear icon > <b>Send Push Notification</b>. <br /><br />
                                        9) For send push notification, only show the push notification button on <b>General type</b>.<br /><br />
                                        There are 2 type of target to select which is <b>Topic</b> or <b>Username</b>. 
                                        <br /><br />
                                        For the topic is once user login mobile app will subscribe the <b>4D topic</b>. 
                                        <br /><br />
                                        For the username is able to select <b>one or multiple </b> user to send push notification.
                                        <br /><br />
                                        10) Click submit. 
                                        <br /><br />
                                        11) Attention: If the member is didn't login into mobile app before. It will not able to receive the push notification.
                                        <br /><br />
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </PanelBody>
                    </div>
                </div>
            </Panel>
        </div>
    )
}

export default withRouter(FAQHelp);