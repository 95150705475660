import React, { useState, useEffect } from "react";
import ReactTable from "../../../components/constantComponent/reactTable/ReactTable";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "./../../../components/panel/panel.jsx";
import { useHistory } from "react-router-dom";
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { navigateTo } from "../../../util/Util";
import {
  WebUrl,
  ApiKey,
  ApiUrl,
  AccessRight,
  LanguageKey,
} from "../../../util/Constant";
import NavigationButton from "../../../components/constantComponent/button/NavigationButton";
import { checkIfPermissionExist } from "../../../util/PermissionChecker";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";

/// <summary>
/// Author : -
/// </summary>
const ManageBank = (props) => {
  const { t } = useTranslation(LanguageKey._PRIMARY);
  let _history = useHistory();
  const [bankAccountData, setBankAccountData] = useState([]);

  let _tableColumns = [
    {
      Header: "BANK",
      accessor: "bankName",
    },
    {
      Header: t("BANK_ACCOUNT_HOLDER_NAME"),
      accessor: "accountHolderName",
    },
    {
      Header: "BANK_ACCOUNT_NUMBER",
      accessor: "accountNumber",
      style: {
        whiteSpace: "unset",
        wordBreak: "break-word",
      },
    },
    {
      Header: "VIP_LEVEL",
      accessor: "vipLevel",
    },
    {
      Header: "MINIMUM_DEPOSIT",
      accessor: "minimumDeposit",
      width: 100,
    },
    {
      Header: "MINIMUM_WITHDRAWAL",
      accessor: "minimumWithdrawal",
      width: 100,
    },
    {
      Header: "MAXIMUM_DEPOSIT",
      accessor: "maximumDeposit",
      width: 100,
    },
    {
      Header: "MAXIMUM_WITHDRAWAL",
      accessor: "maximumWithdrawal",
      width: 100,
    },
    {
      id: "account",
      Header: "ACCOUNT_STATUS",
      accessor: (d) => {
        return d.accountStatus ? (
          <span className="badge badge-secondary badge-green">{t("ACTIVE")}</span>
        ) : (
          <span className="badge badge-secondary badge-danger">
            {t("SUSPENDED")}
          </span>
        );
      },
    },
    {
      id: "deposit",
      Header: "DEPOSIT_STATUS",
      accessor: (d) => {
        return d.depositStatus ? (
          <span className="badge badge-secondary badge-green">{t("ACTIVE")}</span>
        ) : (
          <span className="badge badge-secondary badge-danger">
            {t("SUSPENDED")}
          </span>
        );
      },
    },
    {
      id: "withdrawal",
      Header: "WITHDRAWAL_STATUS",
      accessor: (d) => {
        return d.withdrawalStatus ? (
          <span className="badge badge-secondary badge-green">{t("ACTIVE")}</span>
        ) : (
          <span className="badge badge-secondary badge-danger">
            {t("SUSPENDED")}
          </span>
        );
      },
    },
    {
      id: "action",
      Header: "",
      Cell: ({ row }) =>
        checkIfPermissionExist(AccessRight._BANK_ACCOUNT_SETTING_PERMISSION, true) && (
          <div className="btn-group m-r-5 m-b-5">
            <UncontrolledButtonDropdown>
              <DropdownToggle caret color="default">
                <i className="fas fa-cog"></i>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={() => {
                    navigateTo(
                      _history,
                      WebUrl._URL_CREATE_OR_UPDATE_BANK_ACCOUNT,
                      row.original
                    );
                  }}
                >
                  <span className="text-warning">{t("EDIT")}</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        ),
      disableSortBy: true,
      disableFilters: true,
      width: 100,
      style: { overflow: "visible" },
    },
  ];

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    fetch(ApiUrl._API_GET_USER_BANK_ACCOUNT + "?all=true", {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          setBankAccountData(responseJson[ApiKey._API_DATA_KEY]);
        }
      });
  }, []);

  return (
    <div>
      <h1 className="page-header">
        {t("BANK_ACCOUNT")}
        {checkIfPermissionExist(AccessRight._BANK_ACCOUNT_SETTING_PERMISSION, true) && (
          <NavigationButton
            history={_history}
            url={WebUrl._URL_CREATE_OR_UPDATE_BANK_ACCOUNT}
            buttonText={t("ADD_NEW_BANK_ACCOUNT")}
          />
        )}
      </h1>
      <Panel>
        <PanelHeader></PanelHeader>
        <PanelBody>
          <ReactTable data={bankAccountData} columns={_tableColumns} />
        </PanelBody>
      </Panel>
    </div>
  );
};

export default ManageBank;
