import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import { useHistory } from "react-router-dom";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import {
  ApiKey,
  ApiUrl,
  LanguageKey,
} from "../../util/Constant";
import { setBusy, setIdle, showMessage } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ApiEngine from "../../util/ApiEngine";
import { createFormBody, createMultiPartFormBody, stringIsNullOrEmpty } from "../../util/Util.js";
import moment from 'moment';

/// <summary>
/// Author : -
/// </summary>

const AutoWithdrawalSettings = (props) => {
  const { t } = useTranslation(LanguageKey._PRIMARY);
  let _history = useHistory();
  const _dispatch = useDispatch();
  const [status, setStatus] = useState(true);
  const [initCheck, setInitCheck] = useState(false);
  const [startHour, setStartHour] = useState(0);
  const [startMinute, setStartMinute] = useState(0);
  const [endHour, setEndHour] = useState(0);
  const [endMinute, setEndMinute] = useState(0);
  const [autoWithdrawalPaymentGatewayListStatus, setAutoWithdrawalPaymentGatewayListStatus] = useState([]);

  const {
    register,
    unregister,
    handleSubmit,
    errors,
    setError,
    control,
    setValue,
  } = useForm();

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    _dispatch(setBusy());
    init();
    setInitCheck(true);
  }, []);

  /// <summary>
  /// Author : -
  /// </summary>
  async function init() {
    let apiUrl = ApiUrl._API_GET_USER_SYSTEM_SETTINGS + "?type=autoWithdrawal";
    let responseJson = await ApiEngine.get(apiUrl);
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY];
      setStatus(data === "true");
    }

    let instantPayAutoWithdrawStatusUrl = ApiUrl._API_GET_AVAILABLE_AUTO_WITHDRAWAL_INSTANT_PAY_STATUS;
    let instantPayAutoWithdrawStatusJson = await ApiEngine.get(instantPayAutoWithdrawStatusUrl);
    if (instantPayAutoWithdrawStatusJson[ApiKey._API_SUCCESS_KEY]) {
      let instantPayAutoWithdrawStatusData = instantPayAutoWithdrawStatusJson[ApiKey._API_DATA_KEY];

      let tempArr = [];
      
      instantPayAutoWithdrawStatusData.forEach((item) => {
        tempArr.push({ id: item.id, name: item.instantPayGatewayString, status: item.autoWithdrawalStatus })
      })

      setAutoWithdrawalPaymentGatewayListStatus(tempArr); 
    }

    _dispatch(setIdle());
  }

  const submitForm = async (data) => {
    let params = {
      'status': data.status
    };

    let formBody = createMultiPartFormBody(params);
    let apiUrl = ApiUrl._API_CREATE_OR_UPDATE_SYSTEM_SETTINGS + "?type=autoWithdrawal";
    let responseJson = await ApiEngine.post(apiUrl, formBody);
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {

      if (data.status) {
        responseJson = await ApiEngine.post(ApiUrl._API_UPDATE_AUTO_WITHDRAWAL_INSTANT_PAY_STATUS, autoWithdrawalPaymentGatewayListStatus)

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          init();
        }
      }
      else {
        init();
      }
    }
    _dispatch(showMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
  }

  const handleStatusChange = (event, item) => {
    setAutoWithdrawalPaymentGatewayListStatus(prevItems => prevItems.map(currItem => currItem.id === item.id ? { ...currItem, status: event.target.checked } : currItem))
  }

  return (
    <div>
      <h1 className="page-header">
        {t("AUTO_WITHDRAWAL_SETTINGS")}
        <NavigationButton history={_history} />
      </h1>
      <div className="row">
        <div className="col-xl-12">
          {initCheck && <Panel>
            <PanelBody>
              <form onSubmit={handleSubmit(submitForm)}>
                <div className="col-lg-8">
                  <div className="form-group row m-b-15">
                    <label className="col-md-4 col-form-label">
                      <b>{t("STATUS")}</b>
                    </label>
                    <div className="col-md-7">
                      <div className="switcher">
                        <input
                          type="checkbox"
                          name="status"
                          id="status"
                          onChange={(e) => setStatus(e.target.checked)}
                          value={true}
                          checked={status}
                          ref={register}
                        />
                        <label htmlFor="status"></label>
                      </div>
                    </div>
                  </div>
                </div>
                {status &&
                  <>
                    <hr />
                    <div className="col-lg-8">
                      {autoWithdrawalPaymentGatewayListStatus &&
                        autoWithdrawalPaymentGatewayListStatus.length > 0 &&
                        autoWithdrawalPaymentGatewayListStatus.map((item, index) => {
                          return (
                            <div className="form-group row m-b-15">
                              <label className="col-md-4 col-form-label">
                                <b>{item.name}</b>
                              </label>
                              <div className="col-md-7">
                                <div className="switcher">
                                  <input
                                    type="checkbox"
                                    name={item.name + "-status"}
                                    id={item.name + "-status"}
                                    onChange={(e) => handleStatusChange(e, item)}
                                    value={true}
                                    checked={item.status}
                                    ref={register}
                                  />
                                  <label htmlFor={item.name + "-status"}></label>
                                </div>
                              </div>
                            </div>
                          )
                        })}
                    </div>
                  </>
                }
                <hr />
                <div className="row">
                  <div className="col-lg-3">
                    <div className="form-group">
                      <button type="submit" className="btn btn-primary">
                        {t("SUBMIT")}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </PanelBody>
          </Panel>}
        </div>
      </div>
    </div>
  );
};

export default withRouter(AutoWithdrawalSettings);