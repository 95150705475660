import React, { useState, useEffect } from "react";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import "../../assets/css/custom.css";
import Select from "react-select";
import { useForm } from "react-hook-form";
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
import { createFormBody, navigateTo, stringIsNullOrEmpty } from "../../util/Util";
import {
  ApiUrl,
  ApiKey,
  ContentTypeString,
  LanguageKey,
} from "../../util/Constant";
import { useTranslation } from "react-i18next";
import ConfigurationLanguageDetail from "./ConfigurationLanguageDetail";
import { fetch } from "whatwg-fetch";
import ApiEngine from "../../util/ApiEngine";
import { useDispatch } from "react-redux";
import { showMessage, setSaveAdminLog } from "../../redux/AppAction";
import { useHistory } from "react-router-dom";

/// <summary>
/// Author : -
/// </summary>
const DepositTutorialContentLangauge = (props) => {
  const { handleSubmit, errors, register, content, setValue, clearErrors } = useForm();
  const { t } = useTranslation(LanguageKey._PRIMARY);
  const [data, setData] = useState([]);
  const _dispatch = useDispatch();
  let _history = useHistory();
  const [
    languageDetailModalVisibility,
    setLanguageDetailModalVisibility,
  ] = useState(false);

  const _EXCLUDE_TITLE_TYPE = [
    ContentTypeString._VIP_BENEFIT_CONTENT,
    ContentTypeString._VIP_CRITERIA,
  ];
  const _EXCLUDE_CONTENT_TYPE = [
    ContentTypeString._VIP_BENEFIT,
    ContentTypeString._PRODUCT_CATEGORY,
  ];
  const [isOpen, setIsOpen] = useState(false);
  const [languageDetailId, setLanguageDetailId] = useState("");
  const [tableColumns, setTableColumns] = useState([]);
  const [languageOption, setLanguageOption] = useState([]);
  const [languageId, setLanguageId] = useState("");
  const [depositTutorialLangaugeId, setDepositTutorialLangaugeId] = useState("");
  const [depositTutorialData, setDepositTutorialData] = useState([]);
  const [isCreated, setIsCreated] = useState(0);
  const META_COUNT = 10;
  const [apiData, setApiData] = useState([]);
  const [eidtApiData, setEditApiData] = useState([]);
  const [paymentMethodLabel, setPaymentMethodLabel] = useState("");
  const [noOfRows, setNoOfRows] = useState(1);

  let _tableColumns = [
    {
      Header: "LANGUAGE",
      accessor: "languageName",
      disableSortBy: true,
    },
    {
      Header: "CONTENT",
      accessor: "content",
      disableSortBy: true,
    },
    {
      id: "action",
      Header: "",
      Cell: ({ row }) => (
        <div className="btn-group m-r-5 m-b-5">
          <UncontrolledButtonDropdown>
            <DropdownToggle caret color="default">
              <i className="fas fa-cog"></i>
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                onClick={() => {
                  editDepositTutorialLangauge(row.original);
                  
                }}
              >
                <span className="text-warning">{t("EDIT")}</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </div>
      ),
      disableSortBy: true,
      disableFilters: true,
      width: 100,
      style: { overflow: "visible" },
    },
  ];

  async function editDepositTutorialLangauge(data) {
      setIsCreated(2);
      setIsOpen(true);
      setDepositTutorialLangaugeId(data.id);
      setLanguageId(data.languageId);
      var content = JSON.parse(data["content"]);
      var contentLength = Object.keys(content).length;
      setNoOfRows(contentLength);
    var newData = [];
    for (var i = 0; i < contentLength; i++) {
      newData[i] = {
        name: "STEP " + (i + 1),
        key: content["parameter_" + (i + 1)],
        value: (i + 1),
      };

    }
    setEditApiData([]);
    setEditApiData(newData);
      //for (var i = 0; i < META_COUNT; i++) {
      //  newData[i] = {
      //    name: "Parameter " + (i + 1),
      //    key: data["parameter_" + (i + 1)],
      //    value: data["parameter_" + (i + 1)],
      //  };

      //}
      //setEditApiData([]);
      //setEditApiData(newData);
  } 

  const onSubmit = async (data) => {
    let params = {
      depositTutorialId: props.id, 
      languageId: languageId,
    };

    var parameter = [];
    for (var i = 1; i <= noOfRows; i++) {
      if (!stringIsNullOrEmpty(data["Parameter_" + i])) {
        parameter.push({
          value: data["Parameter_" + i]
        });
      }
    }
    for (var x = 0; x < parameter.length; x++) {
      params["parameter_" + (x + 1)] = parameter[x].value;
    }

    if (!stringIsNullOrEmpty(depositTutorialLangaugeId)) {
      params["id"] = depositTutorialLangaugeId;
      _dispatch(setSaveAdminLog("EDIT_" + props.type + "_Language", _history.location.pathname, params));
    } else {
      _dispatch(setSaveAdminLog("CREATE_" + props.type + "_Language", _history.location.pathname, params));
    }
    

    let responseJson = await ApiEngine.post(
      ApiUrl._API_CREATE_OR_UPDATE_DEPOSIT_TUTORIAL_CONTENT_LANGUAGE,
      createFormBody(params)
    );
    _dispatch(
      showMessage(
        responseJson[ApiKey._API_SUCCESS_KEY],
        t(responseJson[ApiKey._API_MESSAGE_KEY])
      )
    );
    setIsOpen(false);
    getDepositTutorialContent();
    setDepositTutorialLangaugeId("");
    setLanguageId("");
    setNoOfRows(1);
  };
  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    getConfigLanguage();
    getLanguage();
    getDepositTutorialContent();
    /// Used to Exclude the column of table
    if (_EXCLUDE_CONTENT_TYPE.includes(props.type)) {
      _tableColumns.splice(1, 1);
    } else if (_EXCLUDE_TITLE_TYPE.includes(props.type)) {
      _tableColumns.splice(2, 1);
    }
    setTableColumns(_tableColumns);
  }, []);

  async function getLanguage() {
    await fetch(ApiUrl._API_GET_LANGUAGE, {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          const languageList = [];
          responseJson[ApiKey._API_DATA_KEY].forEach(function (language) {
            languageList.push({ label: language.name, value: language.id });
          });
          setLanguageOption(languageList);
        }
      });
  }
  /// <summary>
  /// Author : -
  /// </summary>
  const getConfigLanguage = async () => {
    await fetch(
      ApiUrl._API_GET_CONFIG_LANGUAGE_BY_TYPE_ID +
      "?type=" +
      props.type +
      "&id=" +
      props.id,
      {
        method: ApiKey._API_GET,
        headers: {
          "Content-Type": ApiKey._API_FORM_URLENCODED,
          "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
        },
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          setData(responseJson[ApiKey._API_DATA_KEY]);
        }
      });
  };

  useEffect(() => {
    if (props.method == 1) {
      setPaymentMethodLabel("INSTANT_PAYMENT");
    } else if (props.method == 2) {
      setPaymentMethodLabel("DUITNOW");
    } else {
      setPaymentMethodLabel("NORMAL_DEPOSIT");
    }
  }, [props.method]);

  async function getDepositTutorialContent(languageId) {

    var apiUrl = ApiUrl._API_GET_DEPOSIT_TUTORIAL_CONTENT_LANGAUGE;
    if (languageId != null) {
      apiUrl += "?id=" + languageId
    } else {
      apiUrl += "?depositTutorialId=" + props.id
    }

    fetch(apiUrl, {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          setDepositTutorialData(responseJson[ApiKey._API_DATA_KEY]);
          
          if (!stringIsNullOrEmpty(data["content"])) {
            var content = JSON.parse(data["content"]);
            var contentLength = Object.keys(content).length;
            setNoOfRows(contentLength);

            var newData = [];
            for (var i = 0; i < contentLength; i++) {
              newData[i] = {
                name: "STEP " + (i + 1),
                key: content["parameter_" + (i + 1)],
                value: content["parameter_" + (i + 1)],
              };

            }
            setApiData([]);
            setApiData(newData);
          }
          

          //  var newData = [];
          //  for (var i = 0; i < META_COUNT; i++) {
          //    newData[i] = {
          //      name: "Parameter " + (i + 1),
          //      key: responseJson[ApiKey._API_DATA_KEY]["parameter_" + (i + 1)],
          //      value: responseJson[ApiKey._API_DATA_KEY]["parameter_" + (i + 1)],
          //    };

          //  }
          //  setApiData([]);
          //  setApiData(newData);

          //} else {
          //  var newData = [];
          //  for (var i = 0; i < META_COUNT; i++) {
          //    newData[i] = {
          //      name: "Parameter " + (i + 1),
          //      key: "",
          //      value: "",
          //    };
          //  }
          //  setApiData([]);
          //  setApiData(newData);

          //}
        }
      }
    );
  }

  async function addStep() {
    let list = eidtApiData;

    let countRow = noOfRows + 1;

    var newData = [];


    let data1 = {
      name: "STEP " + (countRow),
      key: "",
      value: "",
    };
    list.push(data1);
    setNoOfRows(countRow);
    setEditApiData(list);

  } 

  async function removeStep(index) {
    let list = eidtApiData;
    let countRow = noOfRows - 1;
    let inx = (index + 1);
    var newData = [];
    newData = list.filter((x) => x.value !== inx);

    var parameter = [];
    var params = [];
    for (var i = 0; i < newData.length; i++) {
      parameter.push({
        value: newData[i]
      });
    }

    for (var x = 0; x < parameter.length; x++) {
      params[x] = {
        name: "STEP " + (x + 1),
        key: parameter[x].value.key,
        value: x + 1,
      };

    }
    console.log("parameter", params);
    for (var i = 1; i < params.length; i++) {
      var x = document.getElementById("inputTextModel_" + (i + 1));
      x.value = parameter[i].value.key;
    }

    setNoOfRows(countRow);
    setEditApiData(params);

  }

  return (
    <div>
      <div className="row">
        <ConfigurationLanguageDetail
          visible={languageDetailModalVisibility}
          onFinish={() => {
            setLanguageDetailModalVisibility(false);
            getConfigLanguage();
          }}
          dataTypeId={props.id}
          languageDetailId={languageDetailId}
          languageType={props.type}
        />

      </div>
      <Panel>
        <PanelBody>
          <button
            type="button"
            onClick={() => {
              setLanguageDetailId("");
              setIsCreated(1);
              setIsOpen(true);
            }}
            className="btn btn-purple"
          >
            {t("ADD_LANGUAGE")}
          </button>
          <ReactTable data={depositTutorialData} columns={_tableColumns} minRows={1} />
        </PanelBody>
      </Panel>

      <Modal fade={false} contentClassName="modal-brand modal-bottom modal-numpad withdrawal" isOpen={isOpen} centered>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader>
            {isCreated == 1 ? t("ADD_LANGUAGE") : t("EDIT_LANGUAGE")} 
          </ModalHeader>
          <ModalBody style={{ minHeight: "30vh" }}>
          
            {isCreated == 1 ?
              (
                <>
                  <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        <b>{t("PAYMENT_METHOD")}</b>
                      </label>
                      <div className="col-md-7">
                        <label>
                          {t(paymentMethodLabel)}
                        </label>
                      </div>
                    </div>
                  </div>
                  
                  <div className="col-lg-12">
                    <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        <b>{t("LANGUAGE")}</b>
                      </label>
                      <div className="col-md-7">
                        <Select
                          name="languageId"
                          options={languageOption}
                          placeholder={
                            languageOption.filter(
                              (option) => option.value == languageId
                            )[0] !== undefined
                              ? languageOption.filter(
                                (option) =>
                                  option.value == languageId
                              )[0].label
                              : ""
                          }
                          value={languageOption.filter(
                            (option) => option.value == languageId
                          )}
                          onChange={(e) => {
                            setLanguageId(e.value);
                            setValue("languageId", e.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  </div>
                  <hr />
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div><h3>{t("CONTENT")}</h3></div>
                    <div><button type="button" class="btn btn-primary me-3" onClick={() => setNoOfRows(noOfRows + 1)}>Add</button></div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <table className="table table-hover">
                        <thead>
                          <tr>
                            <th>{t("PARAMETER")}</th>
                            <th>{t("CONTENT")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {/*{Object.keys(apiData).map((key, value) => {*/}
                          {/*  return (*/}

                          {/*    <tr key={key}>*/}
                          {/*      <td>{apiData[key].name}</td>*/}
                          {/*      <td>*/}
                          {/*        <input*/}
                          {/*          type="text"*/}
                          {/*          defaultValue={apiData[key]["key"]}*/}
                          {/*          className="form-control m-b-5"*/}
                          {/*          name={"Parameter_" + (value + 1)}*/}
                          {/*          ref={register}*/}
                          {/*        />*/}
                          {/*      </td>*/}
                          {/*    </tr>*/}
                          {/*  );*/}
                          {/*})*/}
                          {/*}*/}
                          {[...Array(noOfRows)].map((elementInArray, index) => {
                              return (
                                <tr>
                                  <th scope="row">{t("STEPS ")} {index + 1}</th>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control m-b-5"
                                      name={"Parameter_" + (index + 1)}
                                      ref={register}
                                    />
                                  </td>
                                </tr>
                              )
                            })
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <hr />
                </>
              ) :
              (
                <>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group row m-b-15">
                        <label className="col-md-4 col-form-label">
                          <b>{t("PAYMENT_METHOD")}</b>
                        </label>
                        <div className="col-md-7">
                          <label>
                            {t(paymentMethodLabel)}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <div className="form-group row m-b-15">
                        <label className="col-md-4 col-form-label">
                          <b>{t("LANGUAGE")}</b>
                        </label>
                        <div className="col-md-7">
                          <Select
                            name="languageId"
                            options={languageOption}
                            placeholder={
                              languageOption.filter(
                                (option) => option.value == languageId
                              )[0] !== undefined
                                ? languageOption.filter(
                                  (option) =>
                                    option.value == languageId
                                )[0].label
                                : ""
                            }
                            value={languageOption.filter(
                              (option) => option.value == languageId
                            )}
                            onChange={(e) => {
                              setLanguageId(e.value);
                              setValue("languageId", e.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div><h3>{t("CONTENT")}</h3></div>
                    <div><button type="button" class="btn btn-primary me-3" onClick={() =>  addStep() }>Add</button></div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <table className="table table-hover">
                        <thead>
                          <tr>
                            <th>{t("PARAMETER")}</th>
                            <th>{t("CONTENT")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {eidtApiData.map((data, value) => {
                            return (

                              <tr >
                                <td>{data.name}</td>
                                <td>
                                  <input
                                    type="text"
                                    id={"inputTextModel_" + (value + 1)}
                                    defaultValue={data.key }
                                    className="form-control m-b-5"
                                    name={"Parameter_" + (value + 1)}
                                    ref={register}
                                  />
                                </td>
                                <td><button type="button" class="btn btn-danger me-3" onClick={() => removeStep(value)}>-</button></td>
                              </tr>
                            );
                          })
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <hr />
                </>
              )
              
            }
          
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-white"
              onClick={() => setIsOpen(false)}
            >
              {t("CLOSE")}
            </button>
            <button type="submit" className="btn btn-primary">
              {t("SUBMIT")}
            </button>
        </ModalFooter>
      </form>
      </Modal>

    </div>

  );
};

export default DepositTutorialContentLangauge;
