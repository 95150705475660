import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import { ApiKey, ApiUrl, WebUrl, LanguageKey } from "../../util/Constant";
import { useTranslation } from "react-i18next";
import moment from "moment";
import ApiEngine from "../../util/ApiEngine.js";
import { stringIsNullOrEmpty, isObjectEmpty, numberWithCurrencyFormat } from "../../util/Util";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { showMessage, setSaveAdminLog } from '../../redux/AppAction';
import { useHistory } from "react-router-dom";

const Summary = () => {
    const { t, i18n } = useTranslation(LanguageKey._PRIMARY);
    var _dispatch = useDispatch();
    let _history = useHistory();
    var _username = useSelector(
        (state) => state["authState"]["userData"]["username"]
    );

    const [allData, setAllData] = useState({});
    const [startDate, setStartDate] = useState(moment().add(-1, "days").startOf('day').format("YYYY-MM-DD"));
    const [endDate, setEndDate] = useState(moment().add(-1, "days").endOf('day').format("YYYY-MM-DD"));

    const [companySummaryData, setCompanySummaryData] = useState({});
    const [companyCategoryTotal, setCompanyCategoryTotal] = useState({});
    const [companyGrandTotal, setCompanyGrandTotal] = useState({});

    const [processedData, setProcessedData] = useState({});
    const [processedUserTotal, setProcessedUserTotal] = useState({});
    const [processedCategoryTotal, setProcessedCategoryTotal] = useState({});
    const [processedSubTotal, setProcessedSubTotal] = useState({});
    const [processedGrandTotal, setProcessedGrandTotal] = useState({});
    const [currentViewer, setCurrentViewer] = useState("");
    const _SELECTED_MEMBER_KEY = "selectedMemberKey";

    const [selectedMember, setSelectedMember] = useState("");
    const [locationStateObj, setLocationStateObj] = useState();
    const [memberId, setMemberId] = useState("");
    const [nodePathMembers, setNodePathMembers] = useState([]);
    const [temp, setTemp] = useState([]);
    const [initPage, setInitPage] = useState(true);

    useEffect(() => {
        let nodePath = [];
        let memId = "";
        if (temp.memberNodePath) {
            if (temp.memberNodePath.length > 0) {
                nodePath = temp.memberNodePath;
                memId = nodePath[nodePath.length - 1].id;
            }
        }

        setNodePathMembers(nodePath);
        setMemberId(memId);
        setLocationStateObj({
            parentMemberId: memId,
        });
    }, [temp]);

    const companyToggle = (category) => {
        let tempObj = companyCategoryTotal;
        tempObj[category].show = !tempObj[category].show;
        setCompanyCategoryTotal({ ...tempObj });
    }

    const settlementToggle = (username) => {
        let tempObj = processedUserTotal;
        tempObj[username].showset = !tempObj[username].showset;
        setProcessedUserTotal({ ...tempObj });
    }

    const settlementCatToggle = (username, category) => {
        let tempObj = processedCategoryTotal;
        tempObj[username][category].showset = !tempObj[username][category].showset;
        setProcessedCategoryTotal({ ...tempObj });
    }

    const usernameToggle = (username) => {
        let tempObj = processedUserTotal;
        tempObj[username].show = !tempObj[username].show;
        setProcessedUserTotal({ ...tempObj });
    }
    const categoryToggle = (username, category) => {
        let tempObj = processedCategoryTotal;
        tempObj[username][category].show = !tempObj[username][category].show;
        setProcessedCategoryTotal({ ...tempObj });
    }

    /// <summary>
    /// Author: -
    /// </summary>
    async function getShareholderDetail() { //for the first page only
        setTemp([]);
        setCurrentViewer("");
        let params = {
          "startDate": startDate,
          "endDate": endDate
        }
        _dispatch(setSaveAdminLog("VIEW_SHAREHOLDER_DOWNLIE_REPORT", _history.location.pathname, params));
        var responseJson = await ApiEngine.get(`${ApiUrl._API_GET_MEMBER_SHAREHOLDER_DETAIL}?&startdate=${startDate}&enddate=${endDate}`);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            let data = responseJson[ApiKey._API_DATA_KEY];
            console.log(data);
            setAllData(data);

            let summaryRows = {};
            let summaryCategoryTotal = {};
            let summaryGrandTotal = { sales: 0, vsales: 0, payout: 0, netProfit: 0 };

            let dataRows = {};
            let userTotal = {};
            let categoryTotal = {};
            let subTotal = { sales: 0, vsales: 0, netProfit: 0 };
            let grandTotal = { sales: 0, vsales: 0, netProfit: 0 };

            if (data != null) {

                data["summaryData"].map((summary, index) => {
                    if (!summaryRows[summary.categoryName]) {
                        summaryRows[summary.categoryName] = {};
                        summaryCategoryTotal[summary.categoryName] = { grossSales: 0, validTurnover: 0, payout: 0, netProfit: 0 };
                    }

                    if (!summaryRows[summary.categoryName][summary.productName]) {
                        summaryRows[summary.categoryName][summary.productName] = { grossSales: summary["grossSales"], validTurnover: summary["validTurnover"], payout: summary["payout"], netProfit: summary["netProfit"] };
                    }

                    summaryCategoryTotal[summary.categoryName].show = false;
                    summaryCategoryTotal[summary.categoryName].grossSales += summary["grossSales"];
                    summaryCategoryTotal[summary.categoryName].validTurnover += summary["validTurnover"];
                    summaryCategoryTotal[summary.categoryName].payout += summary["payout"];
                    summaryCategoryTotal[summary.categoryName].netProfit += summary["netProfit"];

                    if (summary.categoryName !== null) {
                        summaryGrandTotal.sales += summary["grossSales"];
                        summaryGrandTotal.vsales += summary["validTurnover"];
                        summaryGrandTotal.payout += summary["payout"];
                        summaryGrandTotal.netProfit += summary["netProfit"];
                    }
                    else {
                        summaryGrandTotal.netProfit += summary["netProfit"];
                    }
                })
                setCompanySummaryData(summaryRows);
                setCompanyCategoryTotal(summaryCategoryTotal);
                setCompanyGrandTotal(summaryGrandTotal);


                data["downlineData"].map((e, i) => {
                    if (!dataRows[e.username]) {
                        dataRows[e.username] = {};
                        userTotal[e.username] = { grossSales: 0, validTurnover: 0, netProfit: 0, profit: 0, settlement: 0, share: 0 };
                        categoryTotal[e.username] = {};
                    }

                    if (!dataRows[e.username][e.categoryName]) {
                        dataRows[e.username][e.categoryName] = {};
                        categoryTotal[e.username][e.categoryName] = { grossSales: 0, validTurnover: 0, netProfit: 0, profit: 0, settlement: 0, share: 0 };
                    }

                    if (!dataRows[e.username][e.categoryName][e.productName]) {
                        dataRows[e.username][e.categoryName][e.productName] = { grossSales: e.grossSales, validTurnover: e.validTurnover, netProfit: e.netProfit, profit: e.profit, settlement: e.settlement, share: e.share };
                    }

                    userTotal[e.username].show = false;
                    userTotal[e.username].showset = false;
                    userTotal[e.username].grossSales += e.grossSales;
                    userTotal[e.username].validTurnover += e.validTurnover;
                    userTotal[e.username].netProfit += e.netProfit;
                    userTotal[e.username].profit += e.profit;
                    userTotal[e.username].settlement += e.settlement;
                    userTotal[e.username].share = e.share;


                    categoryTotal[e.username][e.categoryName].show = false;
                    categoryTotal[e.username][e.categoryName].showset = false;
                    categoryTotal[e.username][e.categoryName].grossSales += e.grossSales;
                    categoryTotal[e.username][e.categoryName].validTurnover += e.validTurnover;
                    categoryTotal[e.username][e.categoryName].netProfit += e.netProfit;
                    categoryTotal[e.username][e.categoryName].profit += e.profit;
                    categoryTotal[e.username][e.categoryName].settlement += e.settlement;
                    categoryTotal[e.username][e.categoryName].share = e.share;

                    subTotal.sales += e.grossSales;
                    subTotal.vsales += e.validTurnover;
                    subTotal.netProfit += e.profit;

                    grandTotal.sales += e.grossSales;
                    grandTotal.vsales += e.validTurnover;
                    grandTotal.netProfit += e.profit;
                    grandTotal.netProfit += e.settlement;

                });
                setProcessedUserTotal(userTotal);
                setProcessedCategoryTotal(categoryTotal);
                setProcessedData(dataRows);
                setProcessedSubTotal(subTotal);
                setProcessedGrandTotal(grandTotal);
            }
        }
        setInitPage(false);
    }

    const getDownlineSales = async (username) => { // for 2nd page onwards, after clicking "Company" on first page
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_SHAREHOLDER_DETAIL + "?startDate=" + startDate + "&endDate=" + endDate + "&username=" + username);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            let data = responseJson[ApiKey._API_DATA_KEY];
            console.log(data);
            setAllData(data);

            let dataRows = {};
            let userTotal = {};
            let categoryTotal = {};
            let subTotal = { sales: 0, vsales: 0, netProfit: 0 };
            let grandTotal = { sales: 0, vsales: 0, netProfit: 0 };
            if (data["downlineData"].length != 0) {
                setCurrentViewer(username);
                if (username === "Company") {
                    data["usernameList"].map((uplineUser, i) => {
                        if (!dataRows[uplineUser]) {
                            dataRows[uplineUser] = {};
                            userTotal[uplineUser] = { grossSales: 0, validTurnover: 0, netProfit: 0, profit: 0, settlement: 0, share: 0 };
                            categoryTotal[uplineUser] = {};
                        }
                    });

                    data["downlineData"].map((downline, i) => {

                        downline.map((player, index) => {
                            if (!dataRows[player.username][player.categoryName]) {
                                dataRows[player.username][player.categoryName] = {};
                                categoryTotal[player.username][player.categoryName] = { grossSales: 0, validTurnover: 0, netProfit: 0, profit: 0, settlement: 0, share: 0 };
                            }

                            categoryTotal[player.username][player.categoryName].grossSales += player.grossSales;
                            categoryTotal[player.username][player.categoryName].validTurnover += player.validTurnover;
                            categoryTotal[player.username][player.categoryName].netProfit += player.netProfit;
                            categoryTotal[player.username][player.categoryName].profit += player.profit;
                            categoryTotal[player.username][player.categoryName].settlement += player.settlement;
                            categoryTotal[player.username][player.categoryName].share = player.share;

                            userTotal[player.username].grossSales += player.grossSales;
                            userTotal[player.username].validTurnover += player.validTurnover;
                            userTotal[player.username].netProfit += player.netProfit;
                            userTotal[player.username].profit += player.profit;
                            userTotal[player.username].settlement += player.settlement;
                            userTotal[player.username].share = player.share;
                        });
                    });
                    setProcessedUserTotal(userTotal);
                    setProcessedCategoryTotal(categoryTotal);
                    setProcessedData(dataRows);
                }
                else {
                    data["downlineData"].map((e, i) => {
                        if (!dataRows[e.username]) {
                            dataRows[e.username] = {};
                            userTotal[e.username] = { grossSales: 0, validTurnover: 0, netProfit: 0, profit: 0, settlement: 0, share: 0 };
                            categoryTotal[e.username] = {};
                        }

                        if (!dataRows[e.username][e.categoryName]) {
                            dataRows[e.username][e.categoryName] = {};
                            categoryTotal[e.username][e.categoryName] = { grossSales: 0, validTurnover: 0, netProfit: 0, profit: 0, settlement: 0, share: 0 };
                        }

                        if (!dataRows[e.username][e.categoryName][e.productName]) {
                            dataRows[e.username][e.categoryName][e.productName] = { grossSales: e.grossSales, validTurnover: e.validTurnover, netProfit: e.netProfit, profit: e.profit, settlement: e.settlement, share: e.share };
                        }

                        userTotal[e.username].show = false;
                        userTotal[e.username].showset = false;
                        userTotal[e.username].grossSales += e.grossSales;
                        userTotal[e.username].validTurnover += e.validTurnover;
                        userTotal[e.username].netProfit += e.netProfit;
                        userTotal[e.username].profit += e.profit;
                        userTotal[e.username].settlement += e.settlement;
                        userTotal[e.username].share = e.share;


                        categoryTotal[e.username][e.categoryName].show = false;
                        categoryTotal[e.username][e.categoryName].showset = false;
                        categoryTotal[e.username][e.categoryName].grossSales += e.grossSales;
                        categoryTotal[e.username][e.categoryName].validTurnover += e.validTurnover;
                        categoryTotal[e.username][e.categoryName].netProfit += e.netProfit;
                        categoryTotal[e.username][e.categoryName].profit += e.profit;
                        categoryTotal[e.username][e.categoryName].settlement += e.settlement;
                        categoryTotal[e.username][e.categoryName].share = e.share;


                        subTotal.sales += e.grossSales;
                        subTotal.vsales += e.validTurnover;
                        subTotal.netProfit += e.profit;

                        grandTotal.sales += e.grossSales;
                        grandTotal.vsales += e.validTurnover;
                        grandTotal.netProfit += e.profit;
                        grandTotal.netProfit += e.settlement;
                    });
                    setProcessedUserTotal(userTotal);
                    setProcessedCategoryTotal(categoryTotal);
                    setProcessedData(dataRows);
                    setProcessedSubTotal(subTotal);
                    setProcessedGrandTotal(grandTotal);
                }
                responseJson = await ApiEngine.get(ApiUrl._API_GET_DOWNLINE_BY_REFERRER_USING_USERNAME + "?username=" + username);
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    let data = responseJson[ApiKey._API_DATA_KEY];
                    setTemp(data);
                }
            }
            else {
                _dispatch(showMessage(false, t("NO_FURTHER_DOWNLINE_DATA_FOUND")));
            }
        }
    }

    /// <summary>
    /// Author : -
    /// </summary>
    const handleEvent = (event, picker) => {
        setStartDate(moment(picker.startDate).format("YYYY-MM-DD"));
        setEndDate(moment(picker.endDate).format("YYYY-MM-DD"));
    };

    return (
        <div>
            <h1 className="page-header">{t("SHAREHOLDERS_DOWNLINE_REPORT")}</h1>
            <Panel>
                <PanelBody>
                    <div className="row">
                        <div className="col-lg-12">
                            {currentViewer !== "" &&
                                <>
                                    <div className="hierarchy-line">
                                        <a
                                            href="javascript:;"
                                            onClick={() => {
                                                setSelectedMember("");
                                                sessionStorage.removeItem(_SELECTED_MEMBER_KEY);
                                                getShareholderDetail();
                                            }}
                                        >
                                            {_username}
                                        </a>
                                        {nodePathMembers.map((m, index) => {
                                            return (
                                                <>
                                                    {index == 0 && ">"}
                                                    <a
                                                        href="javascript:;"
                                                        onClick={() => getDownlineSales(m.username)}
                                                    >
                                                        {m.username}
                                                    </a>
                                                    {index != nodePathMembers.length - 1 && ">"}
                                                </>
                                            );
                                        })}
                                    </div>
                                    <hr />
                                </>
                            }
                            <div className="row">
                                <div className="col-lg-4">
                                    <div
                                        className="form-group"
                                        style={{ display: "flex", width: "100%" }}
                                    >
                                        <div className="input-group" style={{ width: "100%" }}>
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                    <i className="fa fa-calendar"></i>
                                                </span>
                                            </div>
                                            <div style={{ width: "80%" }}>
                                                <DateRangePicker
                                                    containerStyles={{ width: "100%" }}
                                                    alwaysShowCalendars={true}
                                                    onApply={handleEvent}
                                                    maxDate={moment().endOf('day')}
                                                    ranges={{
                                                        'Today': [moment().startOf('day'), moment().endOf('day')],
                                                        'Yesterday': [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],
                                                        'Last 7 Days': [moment().subtract(6, 'days').startOf('day'), moment().endOf('day')],
                                                        'Last 30 Days': [moment().subtract(29, 'days').startOf('day'), moment().endOf('day')],
                                                        'This Month': [moment().startOf('month'), moment().endOf('month')],
                                                        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                                                        'This Year': [moment().startOf('year'), moment().endOf('year')]
                                                    }}>
                                                    <input type="text" className="form-control" value={startDate + " - " + endDate} />
                                                </DateRangePicker>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={() => getShareholderDetail()}
                                    >
                                        {t("SEARCH")}
                                    </button>
                                </div>
                            </div>

                            {Object.keys(companySummaryData) && Object.keys(companySummaryData).length > 0 && <>
                                <div className="downline-title title-md">{t("COMPANY_SUMMARY")}</div>
                                <div className="card card-package">
                                    <div className="card-body p-0">
                                        <div className="card-table table-responsive">
                                            <table className="table table-kpi-summary">
                                                <thead>
                                                    <tr>
                                                        <th style={{ textAlign: "left" }}>{t("CATEGORY")}</th>
                                                        <th style={{ textAlign: "right" }}>{t("VTURNOVER")}</th>
                                                        <th style={{ textAlign: "right" }}>{t("PAYOUT")}</th>
                                                        <th style={{ textAlign: "right" }}>{t("NET_PROFIT")}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {Object.keys(companySummaryData).length > 0 && Object.keys(companyCategoryTotal).length > 0 &&
                                                        Object.keys(companySummaryData).map((category, i) => {
                                                            if (typeof (companyCategoryTotal[category]) !== "undefined") {
                                                                return (
                                                                    <>
                                                                        <tr key={i}>
                                                                            <td style={{ textAlign: "left" }} onClick={() => companyToggle(category)}>{category === "null" ? "Settlement" : category}{category !== "null" && <i className={companyCategoryTotal[category].show ? "shareholder-dropdown fas fa-caret-up" : "shareholder-dropdown fas fa-caret-down"} />}</td>
                                                                            <td style={{ textAlign: "right" }}>{numberWithCurrencyFormat(parseFloat(companyCategoryTotal[category]["validTurnover"]), 3, true)}</td>
                                                                            <td style={{ textAlign: "right" }}>{numberWithCurrencyFormat(parseFloat(companyCategoryTotal[category]["payout"]), 3, true)}</td>
                                                                            <td className={companyCategoryTotal[category]["netProfit"] < 0 ? "text-red" : ""} style={{ textAlign: "right" }}>{numberWithCurrencyFormat(parseFloat(companyCategoryTotal[category]["netProfit"]), 3, true)}</td>
                                                                        </tr>
                                                                        {companyCategoryTotal[category].show &&
                                                                            Object.keys(companySummaryData[category]).map((product, index) => {
                                                                                return (
                                                                                    <tr key={index} className="child-row">
                                                                                        <td className="category-product" style={{ textAlign: "left" }}>- {product}</td>
                                                                                        <td style={{ textAlign: "right" }}>{numberWithCurrencyFormat(parseFloat(companySummaryData[category][product]["validTurnover"]), 3, true)}</td>
                                                                                        <td style={{ textAlign: "right" }}>{numberWithCurrencyFormat(parseFloat(companySummaryData[category][product]["payout"]), 3, true)}</td>
                                                                                        <td className={companySummaryData[category][product]["netProfit"] < 0 ? "text-red" : ""} style={{ textAlign: "right" }}>{numberWithCurrencyFormat(parseFloat(companySummaryData[category][product]["netProfit"]), 3, true)}</td>
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                        }
                                                                    </>
                                                                )
                                                            }
                                                        })
                                                    }
                                                    <tr className="grandtotal" key={"companygrandtotal"}>
                                                        <td style={{ textAlign: "left" }}>{t("Grand Total")}</td>
                                                        <td style={{ textAlign: "right" }}>{numberWithCurrencyFormat(parseFloat(companyGrandTotal.vsales), 3, true)}</td>
                                                        <td style={{ textAlign: "right" }}>{numberWithCurrencyFormat(parseFloat(companyGrandTotal.payout), 3, true)}</td>
                                                        <td className={companyGrandTotal.netProfit < 0 ? "text-red" : ""} style={{ textAlign: "right" }}>{numberWithCurrencyFormat(parseFloat(companyGrandTotal.netProfit), 3, true)}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </>
                            }

                            {Object.keys(companySummaryData).length === 0 && <>
                                <div className="downline-title title-md">{t("COMPANY_SUMMARY")}</div>
                                <div className="card card-package">
                                    <div className="card-body p-0">
                                        <div className="card-table table-responsive">
                                            <table className="table table-kpi-summary">
                                                <thead>
                                                    <tr>
                                                        <th style={{ textAlign: "left" }}>{t("CATEGORY")}</th>
                                                        <th style={{ textAlign: "right" }}>{t("VTURNOVER")}</th>
                                                        <th style={{ textAlign: "right" }}>{t("PAYOUT")}</th>
                                                        <th style={{ textAlign: "right" }}>{t("NET_PROFIT")}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td colSpan="4">{initPage ? t("SELECT_DATE_AND_CONTINUE") : t("NO_REPORT_FOR_THE_DATE")}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </>
                            }

                            {Object.keys(processedData).length > 0 && <>
                                <div className="downline-title title-md">{t("MY_INCOME")}</div>
                                <div className="card card-package">
                                    <div className="card-body p-0">
                                        <div className="card-table table-responsive">
                                            <table className="table table-kpi-summary">
                                                <thead>
                                                    <tr>
                                                        <th style={{ textAlign: "left" }}>{t("USERNAME")}</th>
                                                        <th style={{ textAlign: "left" }}>{t("CATEGORY")}</th>
                                                        <th style={{ textAlign: "right" }}>{t("VTURNOVER")}</th>
                                                        <th style={{ textAlign: "right" }}>{t("%")}</th>
                                                        <th style={{ textAlign: "right" }}>{t("NET_PROFIT")}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        Object.keys(processedData).length > 0 && Object.keys(processedData).map((_username, i) => {
                                                            if (typeof (processedUserTotal[_username]) !== "undefined") {
                                                                return (
                                                                    <>
                                                                        <tr key={"username_" + _username}>
                                                                            <td style={{ textAlign: "left" }}>{currentViewer !== _username ? <a href="" onClick={(e) => { e.preventDefault(); getDownlineSales(_username); }}>{_username}</a> : _username}</td>
                                                                            <td style={{ textAlign: "left" }} onClick={() => usernameToggle(_username)}>{t("All")} <i className={processedUserTotal[_username].show ? "shareholder-dropdown fas fa-caret-up" : "shareholder-dropdown fas fa-caret-down"} /></td>
                                                                            <td style={{ textAlign: "right" }}>{numberWithCurrencyFormat(parseFloat(processedUserTotal[_username].validTurnover), 3, true)}</td>
                                                                            <td style={{ textAlign: "right" }}>{_username !== "Company" ? numberWithCurrencyFormat(parseFloat(processedUserTotal[_username].share), 0, true) + "\/ 130" : ""}</td>
                                                                            <td className={processedUserTotal[_username].profit < 0 ? "text-red" : ""} style={{ textAlign: "right" }}>{numberWithCurrencyFormat(parseFloat(processedUserTotal[_username].profit), 3, true)}</td>
                                                                        </tr>
                                                                        {
                                                                            processedUserTotal[_username].show && Object.keys(processedData[_username]).map((_category, j) => {
                                                                                return (
                                                                                    <>
                                                                                        <tr key={"category_" + _username + "_" + _category} className="child-row">
                                                                                            <td style={{ textAlign: "left" }}></td>
                                                                                            <td style={{ textAlign: "left" }} className="cat-prod" onClick={() => categoryToggle(_username, _category)}>- {_category} <i className={processedCategoryTotal[_username][_category].show ? "shareholder-dropdown fas fa-caret-up" : "shareholder-dropdown fas fa-caret-down"} /></td>
                                                                                            <td style={{ textAlign: "right" }}>{numberWithCurrencyFormat(parseFloat(processedCategoryTotal[_username][_category].validTurnover), 3, true)}</td>
                                                                                            <td style={{ textAlign: "right" }}>{numberWithCurrencyFormat(parseFloat(processedCategoryTotal[_username][_category].share), 0, true)}/130</td>
                                                                                            <td className={processedCategoryTotal[_username][_category].profit < 0 ? "text-red" : ""} style={{ textAlign: "right" }}>{numberWithCurrencyFormat(parseFloat(processedCategoryTotal[_username][_category].profit), 3, true)}</td>
                                                                                        </tr>
                                                                                        {
                                                                                            processedCategoryTotal[_username][_category].show && Object.keys(processedData[_username][_category]).map((_product, k) => {
                                                                                                return (
                                                                                                    <>
                                                                                                        <tr key={"product_" + _username + "_" + _category + "_" + _product} className="child-row">
                                                                                                            <td style={{ textAlign: "left" }}></td>
                                                                                                            <td style={{ textAlign: "left" }} className="cat-prod">-- {_product}</td>
                                                                                                            <td style={{ textAlign: "right" }}>{numberWithCurrencyFormat(parseFloat(processedData[_username][_category][_product].validTurnover), 3, true)}</td>
                                                                                                            <td style={{ textAlign: "right" }}>{numberWithCurrencyFormat(parseFloat(processedData[_username][_category][_product].share), 0, true)}/130</td>
                                                                                                            <td className={processedData[_username][_category][_product].profit < 0 ? "text-red" : ""} style={{ textAlign: "right" }}>{numberWithCurrencyFormat(parseFloat(processedData[_username][_category][_product].profit), 3, true)}</td>
                                                                                                        </tr>
                                                                                                    </>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </>
                                                                                )
                                                                            })
                                                                        }
                                                                    </>
                                                                )
                                                            }
                                                        })
                                                    }

                                                    <tr className="subtotal" key={"subtotal"}>
                                                        <td style={{ textAlign: "left" }} colSpan="2">{t("Sub Total")}</td>
                                                        <td style={{ textAlign: "right" }}>{numberWithCurrencyFormat(parseFloat(processedSubTotal.vsales), 3, true)}</td>
                                                        <td style={{ textAlign: "right" }}></td>
                                                        <td className={processedSubTotal.netProfit < 0 ? "text-red" : ""} style={{ textAlign: "right" }}>{numberWithCurrencyFormat(parseFloat(processedSubTotal.netProfit), 3, true)}</td>
                                                    </tr>

                                                    {
                                                        Object.keys(processedData).length > 0 && Object.keys(processedData).map((_username, i) => {
                                                            if (typeof (processedUserTotal[_username]) !== "undefined") {
                                                                return (
                                                                    <>
                                                                        <tr key={"settlement_username_" + _username}>
                                                                            <td style={{ textAlign: "left" }}>{_username}</td>
                                                                            <td style={{ textAlign: "left" }} colSpan="2" onClick={() => settlementToggle(_username)}>{t("Settlement")} <i className={processedUserTotal[_username].showset ? "shareholder-dropdown fas fa-caret-up" : "shareholder-dropdown fas fa-caret-down"} /></td>
                                                                            <td style={{ textAlign: "right" }}>{_username !== "Company" ? numberWithCurrencyFormat(parseFloat(processedUserTotal[_username].share), 0, true) + "\/ 130" : ""}</td>
                                                                            <td className={processedUserTotal[_username].settlement < 0 ? "text-red" : ""} style={{ textAlign: "right" }}>{numberWithCurrencyFormat(parseFloat(processedUserTotal[_username].settlement), 3, true)}</td>
                                                                        </tr>
                                                                        {
                                                                            processedUserTotal[_username].showset && Object.keys(processedData[_username]).map((_category, j) => {
                                                                                return (
                                                                                    <>
                                                                                        <tr key={"category_" + _username + "_" + _category} className="child-row">
                                                                                            <td style={{ textAlign: "left" }}></td>
                                                                                            <td style={{ textAlign: "left" }} className="cat-prod" onClick={() => settlementCatToggle(_username, _category)}>-{_category} <i className={processedCategoryTotal[_username][_category].show ? "shareholder-dropdown fas fa-caret-up" : "shareholder-dropdown fas fa-caret-down"} /></td>
                                                                                            <td style={{ textAlign: "right" }}></td>
                                                                                            <td style={{ textAlign: "right" }}>{numberWithCurrencyFormat(parseFloat(processedCategoryTotal[_username][_category].share), 0, true)}/130</td>
                                                                                            <td className={processedCategoryTotal[_username][_category].settlement < 0 ? "text-red" : ""} style={{ textAlign: "right" }}>{numberWithCurrencyFormat(parseFloat(processedCategoryTotal[_username][_category].settlement), 3, true)}</td>
                                                                                        </tr>
                                                                                        {
                                                                                            processedCategoryTotal[_username][_category].showset && Object.keys(processedData[_username][_category]).map((_product, k) => {
                                                                                                return (
                                                                                                    <>
                                                                                                        <tr key={"product_" + _username + "_" + _category + "_" + _product} className="child-row">
                                                                                                            <td style={{ textAlign: "left" }}></td>
                                                                                                            <td style={{ textAlign: "left" }} className="cat-prod">--{_product}</td>
                                                                                                            <td style={{ textAlign: "right" }}></td>
                                                                                                            <td style={{ textAlign: "right" }}>{numberWithCurrencyFormat(parseFloat(processedData[_username][_category][_product].share), 0, true)}/130</td>
                                                                                                            <td className={processedData[_username][_category][_product].settlement < 0 ? "text-red" : ""} style={{ textAlign: "right" }}>{numberWithCurrencyFormat(parseFloat(processedData[_username][_category][_product].settlement), 3, true)}</td>
                                                                                                        </tr>
                                                                                                    </>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </>
                                                                                )
                                                                            })
                                                                        }
                                                                    </>
                                                                )
                                                            }
                                                        })
                                                    }

                                                    <tr className="grandtotal" key={"grandtotal"}>
                                                        <td style={{ textAlign: "left" }} colSpan="2">{t("Grand Total")}</td>
                                                        <td style={{ textAlign: "right" }}></td>
                                                        <td style={{ textAlign: "right" }}></td>
                                                        <td className={processedGrandTotal.netProfit < 0 && "text-red"} style={{ textAlign: "right" }}>{numberWithCurrencyFormat(parseFloat(processedGrandTotal.netProfit), 3, true)}</td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </>
                            }

                            {Object.keys(processedData).length == 0 && <>
                                <div className="downline-title title-md">{t("MY_INCOME")}</div>
                                <hr />
                                <div className="card-table table-responsive">
                                    <table className="table table-kpi-summary">
                                        <thead>
                                            <tr>
                                                <th style={{ textAlign: "left" }}>{t("USERNAME")}</th>
                                                <th style={{ textAlign: "left" }}>{t("CATEGORY")}</th>
                                                <th style={{ textAlign: "right" }}>{t("VTURNOVER")}</th>
                                                <th style={{ textAlign: "right" }}>{t("%")}</th>
                                                <th style={{ textAlign: "right" }}>{t("NET_PROFIT")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td colSpan="5">{initPage ? t("SELECT_DATE_AND_CONTINUE") : t("NO_REPORT_FOR_THE_DATE")}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </>
                            }
                        </div>
                    </div>
                </PanelBody>
            </Panel>
        </div>
    );
};

export default Summary;