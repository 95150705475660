import React, { useState, useEffect } from "react";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { useForm } from "react-hook-form";
import { ApiKey, ApiUrl, Status, LanguageKey } from "../../util/Constant";
import { stringIsNullOrEmpty, createFormBody } from "../../util/Util";
import { useLocation } from "react-router-dom";
import { setBusy, setIdle, showMessage, setSaveAdminLog } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import { fetch } from "whatwg-fetch";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import PinInput from 'react-pin-input';

/// <summary>
/// Author : -
/// </summary>

const CreateSubAccount = (props) => {
  const { t } = useTranslation(LanguageKey._PRIMARY);
  const _location = useLocation();
  const _dispatch = useDispatch();
  const _history = useHistory();
  const {
    register,
    unregister,
    handleSubmit,
    errors,
    trigger,
    formState,
    setValue,
  } = useForm();
  const [submitError, setSubmitError] = useState("");
  const [submitSuccess, setSubmitSuccess] = useState("");
  const [roleOptions, setRoleOptions] = useState([]);

  const [status, setStatus] = useState(true);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [fullName, setFullName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [userId, setUserId] = useState("");
  const [timeZone, setTimeZone] = useState();
  const [transactionPinNumber, setTransactionPinNumber] = useState("");
  const [pinEntered, setPinEntered] = useState(false);

  const [roleId, setRoleId] = useState("");
  const [restrictedIP, setRestrictedIP] = useState("");

  var _TIMEZONE_OPTION = [
    { label: "(GMT-11:00) Pago Pago", value: -11 },
    { label: "(GMT-10:00) Pacific/Honolulu", value: -10 },
    { label: "(GMT-08:00) America/Los_Angeles", value: -8 },
    { label: "(GMT-07:00) America/Denver", value: -7 },
    { label: "(GMT-06:00) America/Chicago", value: -6 },
    { label: "(GMT-05:00) America/New_York", value: -5 },
    { label: "(GMT-04:00) America/Guyana", value: -4 },
    { label: "(GMT-03:00) America/Santiago", value: -3 },
    { label: "(GMT-02:00) America/Sao_Paulo", value: -2 },
    { label: "(GMT-01:00) Atlantic/Azores", value: -1 },
    { label: "(GMT+00:00) Europe/London", value: 0 },
    { label: "(GMT+01:00) Europe/Berlin", value: 1 },
    { label: "(GMT+02:00) Europe/Athens", value: 2 },
    { label: "(GMT+03:00) Europe/Istanbul", value: 3 },
    { label: "(GMT+04:00) Europe/Samara", value: 4 },
    { label: "(GMT+05:00) Asia/Karachi", value: 5 },
    { label: "(GMT+06:00) Asia/Dhaka", value: 6 },
    { label: "(GMT+07:00) Asia/Bangkok", value: 7 },
    { label: "(GMT+08:00) Asia/Singapore", value: 8 },
    { label: "(GMT+09:00) Asia/Seoul", value: 9 },
    { label: "(GMT+10:00) Asia/Magadan", value: 10 },
    { label: "(GMT+11:00) Australia/Sydney", value: 11 },
    { label: "(GMT+12:00) Pacific/Majuro", value: 12 },
    { label: "(GMT+13:00) Pacific/Auckland", value: 13 },
    { label: "(GMT+14:00) Pacific/Apia", value: 14 },
  ];

  /// <summary>
  /// Author : -
  /// </summary>
  const submitForm = async (data, e) => {
    if (data.password != data.confirmPassword) {
      _dispatch(
        showMessage(false, t("CONFIRM_PASSWORD_AND_PASSWORD_IS_NOT_TALLY"))
      );
      return;
    }
    if (!pinEntered && transactionPinNumber != "") {
      _dispatch(
        showMessage(false, t("INVALID_PIN_NUMBER"))
      );
      return;
    }

    let params = {
      username: data.username,
      password: data.password,
      email: data.email,
      phoneNumber: stringIsNullOrEmpty(data.phone) ? "" : data.phone,
      userFullName: data.fullName,
      roleId: roleId,
      userStatus: data.status ? Status._ENABLED : Status._DISABLED,
      timeZone: timeZone,
      restrictedIP: !stringIsNullOrEmpty(data.restrictedIP) ? data.restrictedIP : '',
      transactionPinNumber: transactionPinNumber
    };

    if (!stringIsNullOrEmpty(userId)) {
      params["id"] = userId;
      _dispatch(setSaveAdminLog("EDIT_BO_SUB_ACCOUNT", _history.location.pathname, params));
    } else {
      _dispatch(setSaveAdminLog("CREATE_BO_SUB_ACCOUNT", _history.location.pathname, params));
    }

    let formBody = createFormBody(params);
    _dispatch(setBusy());

    fetch(ApiUrl._API_CREATE_OR_UPDATE_ACCOUNT, {
      method: ApiKey._API_POST,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
      body: formBody,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        _dispatch(setIdle());

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          setUserId(responseJson[ApiKey._API_DATA_KEY]["id"]);
        }
        _dispatch(
          showMessage(
            responseJson[ApiKey._API_SUCCESS_KEY],
            t(responseJson[ApiKey._API_MESSAGE_KEY])
          )
        );
      });
  };

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    register({ name: "roleId" }, { required: "PLEASE_SELECT_ROLE" });
    //register(
    //  { name: "phone" },
    //  {
    //    required: "PLEASE_ENTER_PHONE",
    //    validate: {
    //      exist: (value) => value != "INVALID_PHONE" || "INVALID_PHONE",
    //    },
    //  }
    //);
    init();
  }, []);

  /// <summary>
  /// Author : -
  /// </summary>
  async function init() {
    var apiUrl = ApiUrl._API_GET_USERS_ROLE;
    if (_location.state) {
      await fetch(ApiUrl._API_GET_ACCOUNT + "?userId=" + _location.state["id"], {
        method: ApiKey._API_GET,
        headers: {
          "Content-Type": ApiKey._API_FORM_URLENCODED,
          "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setStatus(
              responseJson[ApiKey._API_DATA_KEY]["userStatus"] ==
                Status._ENABLED
            );
            setUsername(responseJson[ApiKey._API_DATA_KEY]["username"]);
            setFullName(responseJson[ApiKey._API_DATA_KEY]["userFullName"]);
            setPhone(responseJson[ApiKey._API_DATA_KEY]["phoneNumber"]);
            setTimeZone(responseJson[ApiKey._API_DATA_KEY]["timeZone"]);
            setValue(
              "timeZone",
              responseJson[ApiKey._API_DATA_KEY]["timeZone"]
            );
            setValue(
              "phone",
              responseJson[ApiKey._API_DATA_KEY]["phoneNumber"]
            );
            setEmail(responseJson[ApiKey._API_DATA_KEY]["email"]);
            setUserId(responseJson[ApiKey._API_DATA_KEY]["id"]);
            setRoleId(responseJson[ApiKey._API_DATA_KEY]["roleId"]);
            setRestrictedIP(responseJson[ApiKey._API_DATA_KEY]["restrictedIP"]);
            unregister("roleId");
          }
        });
    }

    await fetch(apiUrl, {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          const roleList = [];
          responseJson[ApiKey._API_DATA_KEY].forEach(function (role) {
            roleList.push({ label: role.roleName, value: role.id });
          });

          setRoleOptions(roleList);
        }
      });
  }

  /// <summary>
  /// Author -
  /// </summary>
  const onMobileChange = (isValid, newNumber, countryData, fullNumber, ext) => {
    setPhone(newNumber);
    let formattedNumber = fullNumber.split(/[- ]+/).join("").replace("+", "");

    if (isValid) {
      setValue("phone", formattedNumber);
    } else {
      var isEmptyNumber = stringIsNullOrEmpty(formattedNumber);
      setValue("phone", isEmptyNumber ? "" : "INVALID_PHONE");
    }

    trigger("phone");
  };

  async function checkPinValue(data, e) {
    // check if pin has 4 digits (valid pin)
    // if true then save pin
    if (data.length == 4) {
      setPinEntered(true);
    }
    else {
      setPinEntered(false);
    }
    /*await delay(60000)*/

  }

  return (
    <div>
      <h1 className="page-header">
        {t("SUB_ACCOUNT_DETAIL")}
        <NavigationButton history={_history} />
      </h1>
      <div className="row">
        <div className="col-xl-12">
          <Panel>
            <PanelBody>
              <form onSubmit={handleSubmit(submitForm)} autoComplete="off">
                <h3>{t("PERSONAL_INFORMATION")}</h3>
                <div className="row">
                  <div className="col-lg-2">
                    <div className="form-group">
                      <label>
                        <b>{t("USERNAME")}</b>
                      </label>
                      <input
                        type="text"
                        name="username"
                        ref={register({ required: "PLEASE_ENTER_USERNAME" })}
                        defaultValue={username}
                        className="form-control m-b-5"
                        placeholder={t("PLEASE_ENTER_USERNAME")}
                        autocomplete="off"
                      />
                      {errors.username && (
                        <div className="invalid-feedback">
                          {t(errors.username.message)}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-2">
                    <div className="form-group">
                      <label>
                        <b>{t("PASSWORD")}</b>
                      </label>
                      <input
                        type="password"
                        name="password"
                        defaultValue={password}
                        ref={register}
                        className="form-control m-b-5"
                        placeholder={t("PLEASE_ENTER_PASSWORD")}
                        autocomplete="off"
                      />
                      {errors.password && (
                        <div className="invalid-feedback">
                          {t(errors.password.message)}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-2">
                    <div className="form-group">
                      <label>
                        <b>{t("CONFIRM_PASSWORD")}</b>
                      </label>
                      <input
                        type="password"
                        name="confirmPassword"
                        defaultValue={confirmPassword}
                        ref={register}
                        className="form-control m-b-5"
                        placeholder={t("PLEASE_ENTER_CONFIRM_PASSWORD")}
                        autocomplete="off"
                      />
                      {errors.confirmPassword && (
                        <div className="invalid-feedback">
                          {t(errors.confirmPassword.message)}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-2">
                    <div className="form-group">
                      <label>
                        <b>{t("FULLNAME")}</b>
                      </label>
                      <input
                        type="text"
                        name="fullName"
                        defaultValue={fullName}
                        ref={register({ required: "PLEASE_ENTER_FULL_NAME" })}
                        className="form-control m-b-5"
                        placeholder={t("PLEASE_ENTER_FULL_NAME")}
                      />
                      {errors.fullName && (
                        <div className="invalid-feedback">
                          {t(errors.fullName.message)}
                        </div>
                      )}
                    </div>
                  </div>
                  {/*<div className="col-lg-2">
                    <div className="form-group">
                      <label>
                        <b>{t("MOBILE")}</b>
                      </label>
                      <IntlTelInput
                        fieldName={"phone"}
                        preferredCountries={["my"]}
                        style={{ display: "block" }}
                        containerClassName="intl-tel-input"
                        inputClassName="form-control"
                        onPhoneNumberChange={onMobileChange}
                        onPhoneNumberBlur={onMobileChange}
                        customPlaceholder={(placeholder, countryData) => {
                          return placeholder.split(/[- ]+/).join("");
                        }}
                        defaultValue={phone}
                        value={phone}
                        ref={register}
                      />
                      {errors.phone && formState.submitCount > 0 && (
                        <div className="invalid-feedback">
                          {t(errors.phone.message)}
                        </div>
                      )}
                    </div>
                  </div>*/}
                  <div className="col-lg-2">
                    <div className="form-group">
                      <label>
                        <b>{t("EMAIL")}</b>
                      </label>
                      <input
                        type="text"
                        name="email"
                        ref={register({
                          required: "PLEASE_ENTER_EMAIL",
                          pattern: {
                            value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                            message: "INVALID_EMAIL",
                          },
                        })}
                        className="form-control m-b-5"
                        defaultValue={email}
                        placeholder={t("EMAIL")}
                      />
                      {errors.email && (
                        <div className="invalid-feedback">
                          {t(errors.email.message)}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="form-group">
                      <label>
                        <b>{t("RESTRICTED_IP_ADDRESS")}</b>
                      </label>
                      <input
                        type="text"
                        name="restrictedIP"
                        defaultValue={restrictedIP}
                        ref={register({
                          pattern: {
                            value: /^[0-9.]+$/g,
                            message: "INVALID_IP",
                          },
                        })}
                        className="form-control m-b-5"
                        placeholder={t("RESTRICTED_IP_ADDRESS")}
                      />
                      {errors.restrictedIP && (
                        <div className="invalid-feedback">
                          {t(errors.restrictedIP.message)}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="form-group">
                      <label>
                        <b>{t("ROLES")}</b>
                      </label>
                      <Select
                        name="roleId"
                        options={roleOptions}
                        placeholder={
                          roleOptions.filter(
                            (option) => option.value == roleId
                          )[0] !== undefined
                            ? roleOptions.filter(
                                (option) => option.value == roleId
                              )[0].label
                            : ""
                        }
                        value={roleOptions.filter(
                          (option) => option.value == roleId
                        )}
                        onChange={(e) => {
                          unregister("roleId");
                          setRoleId(e.value);
                        }}
                      />
                      {errors.roleId && (
                        <div className="invalid-feedback">
                          {t(errors.roleId.message)}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="form-group">
                      <label>
                        <b>{t("TIME_ZONE")}</b>
                      </label>
                      <br />
                      <Select
                        name="timeZone"
                        options={_TIMEZONE_OPTION}
                        placeholder={
                          _TIMEZONE_OPTION.filter(
                            (option) => option.value == timeZone
                          )[0] !== undefined
                            ? _TIMEZONE_OPTION.filter(
                                (option) => option.value == timeZone
                              )[0].label
                            : ""
                        }
                        value={_TIMEZONE_OPTION.filter(
                          (option) => option.value == timeZone
                        )}
                        onChange={(e) => {
                          setValue("timeZone", e.value);
                          setTimeZone(e.value);
                        }}
                      />
                      {errors.timeZone && (
                        <div className="invalid-feedback">
                          {t(errors.timeZone.message)}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="form-group">
                      <label>
                        <b>{t("STATUS")}</b>
                      </label>
                      <br />
                      <div className="switcher">
                        <input
                          type="checkbox"
                          name="status"
                          id="status"
                          onChange={(e) => setStatus(e.target.checked)}
                          value={true}
                          checked={status}
                          ref={register}
                        />
                        <label htmlFor="status"></label>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-2">
                    <div className="form-group">
                      <label>
                        <b>{t("PIN_NUMBER")}</b>
                      </label>
                      <PinInput
                        length={4}
                        initialValue={transactionPinNumber}
                        secret={false}
                        focus={true}
                        ref={register}
                        type="numeric"
                        onChange={(value, index) => {
                          setTransactionPinNumber(value);
                          checkPinValue(value, index);
                        }}
                        inputMode="numeric"
                        inputStyle={{ border: "0", margin: "0px 10px", width: "40px", height: "40px", backgroundColor: "transparent", borderBottom: "2px solid #002e6c" }}
                        inputFocusStyle={{ borderBottom: "2px solid #FFC159" }}
                        regexCriteria={/^[ 0-9_@./#&+-]*$/}
                      />
                      {/*errors.transactionPinNumber && (
                        <div className="invalid-feedback">
                          {t(errors.transactionPinNumber.message)}
                        </div>
                      )*/}
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-lg-3">
                    <div className="form-group">
                      <button type="submit" className="btn btn-primary">
                        {t("SUBMIT")}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </PanelBody>
          </Panel>
        </div>
      </div>
    </div>
  );
};

export default CreateSubAccount;
