import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Wheel } from 'react-custom-roulette';
import { useTranslation } from 'react-i18next';
import { ApiKey, ApiUrl, LanguageKey } from '../../util/Constant';
import { useForm } from "react-hook-form";
import ApiEngine from '../../util/ApiEngine.js';
import { createFormBody } from '../../util/Util';
import { useDispatch } from 'react-redux';
import { showMessage } from '../../redux/AppAction';
import { Panel, PanelBody } from '../../components/panel/panel';
import SweetAlert from "react-bootstrap-sweetalert";

const ManageFreeSpin = (props) => {
  const { t } = useTranslation(LanguageKey._PRIMARY);
  const _dispatch = useDispatch();
  const [startSpinning, setStartSpinning] = useState(false);
  const [freeSpinItems, setFreeSpinItems] = useState([]);
  const [displayWheel, setDisplayWheel] = useState(false);
  const [initDone, setInitDone] = useState(false);
  const [wheelMinimumDeposit, setWheelMinimumDeposit] = useState(0);
  const [wheelMaxDaysForMinDeposit, setWheelMaxDaysForMinDeposit] = useState(0);
  const [wheelOuterBorderColor, setWheelOuterBorderColor] = useState("");
  const [wheelInnerBorderColor, setWheelInnerBorderColor] = useState("");
  const [winningId, setWinningId] = useState(0);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [winningOption, setWinningOption] = useState({});
  const [showWinningText, setShowWinningText] = useState(false);
  const [isFreeSpinWin, setIsFreeSpinWin] = useState(true);
  const { register, handleSubmit, errors, setValue, watch, trigger, formState, control, getValues } = useForm();

  useEffect(() => {
    init();
    setTimeout(() => setInitDone(true), 1000);
    setTimeout(() => setDisplayWheel(true), 1);
  }, []);

  const init = async () => {
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_FREE_SPIN_CONTENTS);
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY];
      let _temp = [];
      data["itemData"].map((item) => {
        _temp.push({
          id: item.id,
          option: item.name,
          style:
          {
            backgroundColor: item.backgroundColor,
            textColor: item.fontColor,
            fontSize: item.fontSize
          },
          optionSize: item.optionSize,
          amount: item.amount,
          rolloverMultiplier: item.rolloverMultiplier,
          winChance: item.winChance
        });
      });
      setWheelMaxDaysForMinDeposit(parseFloat(data["wheelData"].filter(x => (x.key).endsWith("WheelMaxDaysForMinDeposit"))[0].value));
      setWheelMinimumDeposit(parseFloat(data["wheelData"].filter(x => (x.key).endsWith("WheelMinDepositForFreeSpin"))[0].value));
      setWheelOuterBorderColor(data["wheelData"].filter(x => (x.key).endsWith("WheelOuterBorderColor"))[0].value);
      setWheelInnerBorderColor(data["wheelData"].filter(x => (x.key).endsWith("WheelInnerBorderColor"))[0].value);
      setFreeSpinItems(_temp);
      calculateWinItem(_temp);
    }
  }

  const deleteItem = (itemToDelete) => {
    let tempFreeSpinItems = freeSpinItems;
    let tempUpdate = [];
    tempFreeSpinItems.map((freeSpinItem) => {
      let loopData = freeSpinItem;
      if (freeSpinItem.id === itemToDelete.id) {
        //ignore
      }
      else {
        loopData.name = getValues("item-name-" + loopData.id);
        loopData.style.textColor = getValues("item-text-color-" + loopData.id);
        loopData.style.fontSzie = getValues("item-text-size-" + loopData.id);
        loopData.style.backgroundColor = getValues("item-bg-color-" + loopData.id);
        loopData.optionSize = getValues("item-size-" + loopData.id);
        loopData.amount = getValues("item-winAmount-" + loopData.id);
        loopData.rolloverMultiplier = getValues("item-rolloverMultiplier-" + loopData.id);
        loopData.winChance = getValues("item-winChance-" + loopData.id);
        tempUpdate.push(loopData);
      }
    });
    setFreeSpinItems(tempUpdate);
    calculateWinItem(tempUpdate);
    setDisplayWheel(false);
    setTimeout(() => setDisplayWheel(true), 1);
  }

  const handleAddItem = async () => {
    let tempData = freeSpinItems;
    let newData =
    {
      id: freeSpinItems[freeSpinItems.length - 1]["id"] + 1,
      option: "",
      style:
      {
        backgroundColor: "transparent",
        textColor: "black",
        fontSize: 20
      },
      optionSize: 1,
      amount: 0,
      rolloverMultiplier: 1,
      winChance: 0
    };
    await tempData.push(newData);
    setFreeSpinItems(tempData);
    calculateWinItem(tempData);
    setDisplayWheel(false);
    setTimeout(() => setDisplayWheel(true), 1);
  }

  const submitPreview = async (data, e) => {
    let updatedFreeSpinItems = [];
    freeSpinItems.map((item) => {
      let tempItem = item;
      tempItem.option = data["item-name-" + item.id];
      tempItem.style.textColor = data["item-text-color-" + item.id];
      tempItem.style.fontSize = data["item-text-size-" + item.id];
      tempItem.style.backgroundColor = data["item-bg-color-" + item.id];
      tempItem.optionSize = data["item-size-" + item.id];
      tempItem.amount = data["item-winAmount-" + item.id];
      tempItem.rolloverMultiplier = data["item-rolloverMultiplier-" + item.id];
      tempItem.winChance = data["item-winChance-" + item.id];
      updatedFreeSpinItems.push(tempItem);
    })
    setWheelMinimumDeposit(data["wheel-minimum-deposit"]);
    setWheelMaxDaysForMinDeposit(data["wheel-max-days"]);
    setWheelOuterBorderColor(data["wheel-outer-border-color"]);
    setWheelInnerBorderColor(data["wheel-inner-border-color"]);
    setFreeSpinItems(updatedFreeSpinItems);
    setDisplayWheel(false);
    setTimeout(() => setDisplayWheel(true), 1);
  }

  const calculateWinItem = (spinItems) => {
    let loopData = spinItems;
    let prizeList = [];
    loopData.map((item) => {
      let limit = Math.ceil(item.winChance);
      let count = 0;
      while (count <= limit) {
        count++;
        prizeList.push(item.id);
      }
    });
    let win = Math.ceil(Math.random() * (prizeList.length - 1));
    let winId = prizeList[win];
    setWinningId(winId);
    let winItem = spinItems.filter(x => x.id === winId)[0];
    if (winItem.option.indexOf("$") === -1) {
      setIsFreeSpinWin(false);
    }
  }

  function preSubmit() {
    setShowConfirmation(true);
  }

  const submitForm = async () => {
    let params = {};
    freeSpinItems.map((item) => {
      params["item-name-" + item.id] = item.option;
      params["item-text-color-" + item.id] = item.style.textColor;
      params["item-text-size-" + item.id] = item.style.fontSize;
      params["item-bg-color-" + item.id] = item.style.backgroundColor;
      params["item-size-" + item.id] = item.optionSize;
      params["item-winAmount-" + item.id] = item.amount;
      params["item-rolloverMultiplier-" + item.id] = item.rolloverMultiplier;
      params["item-winChance-" + item.id] = item.winChance;
    });
    params["wheel-max-days"] = wheelMaxDaysForMinDeposit;
    params["wheel-minimum-deposit"] = wheelMinimumDeposit;
    params["wheel-outer-border-color"] = wheelOuterBorderColor;
    params["wheel-inner-border-color"] = wheelInnerBorderColor;

    let responseJson = await ApiEngine.post(ApiUrl._API_SET_FREE_SPIN_CONTENTS, createFormBody(params));
    _dispatch(showMessage(responseJson[ApiKey._API_SUCCESS_KEY], responseJson[ApiKey._API_MESSAGE_KEY]));
  }

  return (
    <>
      <h3>{t("MANAGE_FREE_SPIN")}</h3>
      <Panel>
        <PanelBody>
          {initDone && <form onSubmit={handleSubmit(submitPreview)}>
            <h3>{t("GENERAL_SETTINGS")}</h3>
            <br />
            <div className="row">
              <div className="col-lg-3">
                <div className="form-group">
                  <label>
                    <b>{t("MINIMUM_DEPOSIT_FOR_FREE_SPIN")}</b>
                  </label>
                  <input
                    type="number"
                    step="0.01"
                    defaultValue={wheelMinimumDeposit}
                    placeholder={t("MINIMUM_DEPOSIT")}
                    className="form-control m-b-5"
                    name="wheel-minimum-deposit"
                    ref={register}
                    onChange={(e) => setWheelMinimumDeposit(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-lg-3">
                <div className="form-group">
                  <label>
                    <b>{t("MAX_DAYS_FOR_MIN_DEPOSIT")}</b>
                  </label>
                  <input
                    type="number"
                    step="1"
                    defaultValue={wheelMaxDaysForMinDeposit}
                    placeholder={t("NUM_OF_DAYS")}
                    className="form-control m-b-5"
                    name="wheel-max-days"
                    ref={register}
                    onChange={(e) => setWheelMaxDaysForMinDeposit(e.target.value)}
                  />
                </div>
              </div>
              {/*<div className="col-lg-3">*/}
              {/*  <div className="form-group">*/}
              {/*    <label>*/}
              {/*      <b>{t("WHEEL_OUTER_BORDER_COLOR")}</b>*/}
              {/*    </label>*/}
              {/*    <input*/}
              {/*      type="text"*/}
              {/*      defaultValue={wheelOuterBorderColor}*/}
              {/*      placeholder={"e.g. #fff000 or yellow"}*/}
              {/*      className="form-control m-b-5"*/}
              {/*      name={"wheel-outer-border-color"}*/}
              {/*      ref={register}*/}
              {/*      onChange={(e) => setWheelOuterBorderColor(e.target.value)}*/}
              {/*    />*/}
              {/*  </div>*/}
              {/*</div>*/}
              {/*<div className="col-lg-3">*/}
              {/*  <div className="form-group">*/}
              {/*    <label>*/}
              {/*      <b>{t("WHEEL_INNER_BORDER_COLOR")}</b>*/}
              {/*    </label>*/}
              {/*    <input*/}
              {/*      type="text"*/}
              {/*      defaultValue={wheelInnerBorderColor}*/}
              {/*      placeholder={"e.g. #fff000 or yellow"}*/}
              {/*      className="form-control m-b-5"*/}
              {/*      name={"wheel-inner-border-color"}*/}
              {/*      ref={register}*/}
              {/*      onChange={(e) => setWheelInnerBorderColor(e.target.value)}*/}
              {/*    />*/}
              {/*  </div>*/}
              {/*</div>*/}
            </div>
            <hr />
            <h3>{t("ITEMS")}</h3>
            <br />
            <div className="row">
              <div className="col-lg-12">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>{" "}</th>
                      <th>{t("TEXT")}</th>
                      {/*<th>{t("TEXT_COLOR")}</th>*/}
                      {/*<th>{t("TEXT_SIZE")}</th>*/}
                      {/*<th>{t("BACKGROUND_COLOR")}</th>*/}
                      {/*<th>{t("SIZE")}</th>*/}
                      <th>{t("WIN_AMOUNT")}</th>
                      <th>{t("ROLLOVER_MULTIPLIER")}</th>
                      {/*<th>{t("WIN_CHANCE") + " (%) "}</th>*/}
                    </tr>
                  </thead>
                  <tbody>
                    {initDone && freeSpinItems.length > 0 &&
                      freeSpinItems.map((item, index) => {
                        return (
                          <tr key={item}>
                            <td onClick={() => deleteItem(item)}>
                              <div className="delete-icon">
                                <i className="fas fa-trash"></i>
                              </div>
                            </td>
                            <td>
                              <input
                                type="text"
                                defaultValue={item.option}
                                placeholder={"e.g. iPhone or Better luck next time! or $100"}
                                className="form-control m-b-5"
                                name={"item-name-" + item.id}
                                ref={register}
                                onChange={(e) => setValue("item-name-" + item.id, e.target.value)}
                              />
                            </td>
                            {/*<td>*/}
                            {/*  <input*/}
                            {/*    type="text"*/}
                            {/*    defaultValue={item.style.textColor}*/}
                            {/*    placeholder={"e.g. #fff000 or yellow"}*/}
                            {/*    className="form-control m-b-5"*/}
                            {/*    name={"item-text-color-" + item.id}*/}
                            {/*    ref={register}*/}
                            {/*    onChange={(e) => setValue("item-text-color-" + item.id, e.target.value)}*/}
                            {/*  />*/}
                            {/*</td>*/}
                            {/*<td>*/}
                            {/*  <input*/}
                            {/*    type="number"*/}
                            {/*    step="0.1"*/}
                            {/*    defaultValue={item.style.fontSize}*/}
                            {/*    placeholder={t("TEXT_SIZE")}*/}
                            {/*    className="form-control m-b-5"*/}
                            {/*    name={"item-text-size-" + item.id}*/}
                            {/*    ref={register}*/}
                            {/*    onChange={(e) => setValue("item-text-size-" + item.id, e.target.value)}*/}
                            {/*  />*/}
                            {/*</td>*/}
                            {/*<td>*/}
                            {/*  <input*/}
                            {/*    type="text"*/}
                            {/*    defaultValue={item.style.backgroundColor}*/}
                            {/*    placeholder={"e.g. #fff000 or yellow"}*/}
                            {/*    className="form-control m-b-5"*/}
                            {/*    name={"item-bg-color-" + item.id}*/}
                            {/*    ref={register}*/}
                            {/*    onChange={(e) => setValue("item-bg-color-" + item.id, e.target.value)}*/}
                            {/*  />*/}
                            {/*</td>*/}
                            {/*<td>*/}
                            {/*  <input*/}
                            {/*    type="number"*/}
                            {/*    step="1"*/}
                            {/*    defaultValue={item.optionSize}*/}
                            {/*    placeholder={"size of item in spinwheel"}*/}
                            {/*    className="form-control m-b-5"*/}
                            {/*    name={"item-size-" + item.id}*/}
                            {/*    ref={register}*/}
                            {/*    onChange={(e) => setValue("item-size-" + item.id, e.target.value)}*/}
                            {/*  />*/}
                            {/*</td>*/}
                            <td>
                              <input
                                type="number"
                                step="0.01"
                                defaultValue={item.amount}
                                className="form-control m-b-5"
                                name={"item-winAmount-" + item.id}
                                ref={register}
                                onChange={(e) => setValue("item-winAmount-" + item.id, e.target.value)}
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                step="0.01"
                                defaultValue={item.rolloverMultiplier}
                                className="form-control m-b-5"
                                name={"item-rolloverMultiplier-" + item.id}
                                ref={register}
                                onChange={(e) => setValue("item-rolloverMultiplier-" + item.id, e.target.value)}
                              />
                            </td>
                            {/*<td>*/}
                            {/*  <input*/}
                            {/*    type="number"*/}
                            {/*    step="0.01"*/}
                            {/*    defaultValue={item.winChance}*/}
                            {/*    className="form-control m-b-5"*/}
                            {/*    name={"item-winChance-" + item.id}*/}
                            {/*    ref={register}*/}
                            {/*    onChange={(e) => setValue("item-winChance-" + item.id, e.target.value)}*/}
                            {/*  />*/}
                            {/*</td>*/}
                          </tr>
                        );
                      })}
                    <tr style={{ cursor: "pointer" }} onClick={() => handleAddItem()}>
                      <td><i className="fas fa-plus"></i></td>
                      <td colSpan="6">
                        <div>{t("ADD_ITEM") + "..."}</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <hr className="grey-hr" />
              {/*<div className="col-lg-12 submit-preview-btn">*/}
              {/*  <button type="submit" className="btn btn-primary">{t("SUBMIT") + " " + t("PREVIEW")}</button>*/}
              {/*</div>*/}
              <div className="col-lg-12">
                <button type="submit" className="btn btn-primary" onClick={() => preSubmit()}>{t("SAVE_CHANGES")}</button>
              </div>
              {/*<hr className="grey-hr"/>*/}
              {/*<div className="col-lg-12">*/}
              {/*  <div className="spinwheel-preview-wrapper">*/}
              {/*    <div className="spinwheel-preview-text">{t("PREVIEW")}</div>*/}
              {/*    <div className="spinwheel-btn">*/}
              {/*      <button type="button" className="btn btn-primary" onClick={() => {*/}
              {/*        setStartSpinning(true);*/}
              {/*        setShowWinningText(false);*/}
              {/*        setWinningOption(freeSpinItems.filter(x => x.id === winningId)[0]);*/}
              {/*      }}>*/}
              {/*        {t("SPIN_THE_WHEEL")}*/}
              {/*      </button>*/}
              {/*    </div>*/}
              {/*    {displayWheel && <div className="spinwheel-div">*/}
              {/*      <Wheel*/}
              {/*        mustStartSpinning={startSpinning}*/}
              {/*        prizeNumber={winningId - 1}*/}
              {/*        data={freeSpinItems}*/}
              {/*        onStopSpinning={() => {*/}
              {/*          setStartSpinning(false);*/}
              {/*          setShowWinningText(true);*/}
              {/*          calculateWinItem(freeSpinItems);*/}
              {/*        }}*/}
              {/*        outerBorderColor={wheelOuterBorderColor}*/}
              {/*        radiusLineColor={wheelInnerBorderColor}*/}
              {/*        startingOptionIndex={0}*/}
              {/*      />*/}
              {/*    </div>}*/}
              {/*    {showWinningText && <div className="spinwheel-winning-text">*/}
              {/*      {isFreeSpinWin ? (t("CONGRATULATIONS") + "! " + t("YOU_HAVE_WON") + " " + winningOption.option + "!") : winningOption.option}*/}
              {/*    </div>}*/}
              {/*  </div>*/}
              {/*</div>*/}
            </div>
          </form>}
        </PanelBody>
      </Panel>
      {showConfirmation && (
        <SweetAlert
          warning
          showCancel
          confirmBtnText={t("CONFIRM")}
          cancelBtnText={t("CANCEL")}
          confirmBtnBsStyle="danger"
          title={t("CONFIRM") + " " + t("SAVE_CHANGES") + "?"}
          onConfirm={() => {
            submitForm();
            setShowConfirmation(false);
          }}
          onCancel={() => {
            setShowConfirmation(false);
          }}
        ></SweetAlert>
      )}
    </>
  )
}

export default withRouter(ManageFreeSpin);