﻿import React, { useState, useEffect } from "react";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import { useHistory } from "react-router-dom";
import Dropzone from "react-dropzone";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import {
  ApiKey,
  ApiUrl,
  Status,
  LanguageKey,
  _SYNC_INTERVAL_PATTERN,
} from "../../util/Constant";
import { stringIsNullOrEmpty, createMultiPartFormBody } from "../../util/Util";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { showMessage, setSaveAdminLog } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import SweetAlert from "react-bootstrap-sweetalert";
import ApiEngine from "../../util/ApiEngine.js";
import Select from "react-select";

/// <summary>
/// Author : -
/// </summary>

const MasterProductDetail = (props) => {
  const { t } = useTranslation(LanguageKey._PRIMARY);
  let _history = useHistory();
  const _location = useLocation();
  const _dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    errors,
    setValue,
    watch,
    unregister,
  } = useForm();
  const [productId, setProductId] = useState("");
  const [productDisplayId, setProductDisplayId] = useState("");
  const [productName, setProductName] = useState("");
  const [productCode, setProductCode] = useState("");
  const [clientCode, setClientCode] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  const [backUrl, setBackUrl] = useState("");
  const [regularSyncInterval, setRegularSyncInterval] = useState("");
  const [selectRegularSyncPattern, setSelectRegularSyncPattern] = useState(0);
  const [settlementSyncEnabled, setSettlementSyncEnabled] = useState("");
  const [settlementSyncTimeHH, setSettlementSyncTimeHH] = useState("");
  const [settlementSyncTimeMM, setSettlementSyncTimeMM] = useState("");
  const [reportUrl, setReportUrl] = useState("");
  const [status, setStatus] = useState(true);
  const [isNewImage, setIsNewImage] = useState(false);
  const [isNewLobbyIconMobileImage, setIsNewLobbyIconMobileImage] = useState(false);
  const [isNewLobbyIconMobile2Image, setIsNewLobbyIconMobile2Image] = useState(false);
  const [isNewLobbyIconDesktopImage, setIsNewLobbyIconDesktopImage] = useState(false);
  const [isNewBanner, setIsNewBanner] = useState(false);
  const [isNewDesktopBanner, setIsNewDesktopBanner] = useState(false);
  const [isNewPopupBanner, setIsNewPopupBanner] = useState(false);
  const [isNewProductDesktopImage, setIsNewProductDesktopImage] = useState(false);
  const [isNewProductDesktopImageHover, setIsNewProductDesktopImageHover] = useState(false);
  const [isNewIcon, setIsNewIcon] = useState(false);
  const [isSeamless, setIsSeamless] = useState(true);
  const [downloadUrl, setDownloadUrl] = useState("");
  const [bonus, setBonus] = useState();
  const [commission, setCommission] = useState();
  const [clubFee, setClubFee] = useState();
  const [showConfirmation, setShowConfirmation] = useState(false);

  const [content, setContent] = useState("");
  const [logoFile, setLogoFile] = useState([]);
  const [logoImage, setLogoImage] = useState();
  const [lobbyIconMobileFile, setLobbyIconMobileFile] = useState([]);
  const [lobbyIconMobileImage, setLobbyIconMobileImage] = useState();
  const [lobbyIconMobile2File, setLobbyIconMobile2File] = useState([]);
  const [lobbyIconMobile2Image, setLobbyIconMobile2Image] = useState();
  const [lobbyIconDesktopFile, setLobbyIconDesktopFile] = useState([]);
  const [lobbyIconDesktopImage, setLobbyIconDesktopImage] = useState();
  const [bannerFile, setBannerFile] = useState([]);
  const [bannerImage, setBannerImage] = useState();
  const [bannerDesktopFile, setBannerDesktopFile] = useState([]);
  const [bannerDesktopImage, setBannerDesktopImage] = useState();
  const [productDesktopFile, setProductDesktopFile] = useState([]);
  const [productDesktopImage, setProductDesktopImage] = useState();
  const [productDesktopFileHover, setProductDesktopFileHover] = useState([]);
  const [productDesktopImageHover, setProductDesktopImageHover] = useState();
  const [popupBannerFile, setPopupBannerFile] = useState([]);
  const [popupBannerImage, setPopupBannerImage] = useState();
  const [iconFile, setIconFile] = useState([]);
  const [iconImage, setIconImage] = useState();
  const [hourList, setHourList] = useState([]);
  const [minuteList, setMinuteList] = useState([]);
  const [supportAgentMode, setSupportAgentMode] = useState(false);
  const [showRankInput, setShowRankInput] = useState(false);

  /// <summary>
  /// Author : -
  /// Used to set logo
  /// </summary>
  const logoDrop = (acceptedFiles) => {
    if (acceptedFiles.length !== 0) {
      const file = acceptedFiles[0];
      setLogoFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setLogoImage(reader.result);
        setIsNewImage(true);
      };
      reader.readAsDataURL(file);
    }
  };

  /// <summary>
  /// Author : -
  /// Used to set banner
  /// </summary>
  const bannerDrop = (acceptedFiles) => {
    if (acceptedFiles.length !== 0) {
      const file = acceptedFiles[0];
      setBannerFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setBannerImage(reader.result);
        setIsNewBanner(true);
      };
      reader.readAsDataURL(file);
    }
  };

  const lobbyIconMobileImageDrop = (acceptedFiles) => {
    if (acceptedFiles.length !== 0) {
      const file = acceptedFiles[0];
      setLobbyIconMobileFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setLobbyIconMobileImage(reader.result);
        setIsNewLobbyIconMobileImage(true);
      };
      reader.readAsDataURL(file);
    }
  };

  const lobbyIconMobile2ImageDrop = (acceptedFiles) => {
    if (acceptedFiles.length !== 0) {
      const file = acceptedFiles[0];
      setLobbyIconMobile2File(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setLobbyIconMobile2Image(reader.result);
        setIsNewLobbyIconMobile2Image(true);
      };
      reader.readAsDataURL(file);
    }
  };

  const lobbyIconDesktopImageDrop = (acceptedFiles) => {
    if (acceptedFiles.length !== 0) {
      const file = acceptedFiles[0];
      setLobbyIconDesktopFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setLobbyIconDesktopImage(reader.result);
        setIsNewLobbyIconDesktopImage(true);
      };
      reader.readAsDataURL(file);
    }
  };
    
  /// <summary>
  /// Author : -
  /// Used to set banner for desktop
  /// </summary>
  const bannerDesktopDrop = (acceptedFiles) => {
    if (acceptedFiles.length !== 0) {
      const file = acceptedFiles[0];
      setBannerDesktopFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setBannerDesktopImage(reader.result);
        setIsNewDesktopBanner(true);
      };
      reader.readAsDataURL(file);
    }
  };

  /// <summary>
  /// Author : -
  /// Used to set banner icon
  /// </summary>
  const iconDrop = (acceptedFiles) => {
    if (acceptedFiles.length !== 0) {
      const file = acceptedFiles[0];
      setIconFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setIconImage(reader.result);
        setIsNewIcon(true);
      };
      reader.readAsDataURL(file);
    }
  };

  /// <summary>
  /// Author : -
  /// Used to set icon for desktop
  /// </summary>
  const desktopLogoDrop = (acceptedFiles) => {
    if (acceptedFiles.length !== 0) {
      const file = acceptedFiles[0];
      setProductDesktopFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setProductDesktopImage(reader.result);
        setIsNewProductDesktopImage(true);
      };
      reader.readAsDataURL(file);
    }
  };

  /// <summary>
  /// Author : -
  /// Used to set hover icon for desktop
  /// </summary>
  const desktopLogoHoverDrop = (acceptedFiles) => {
    if (acceptedFiles.length !== 0) {
      const file = acceptedFiles[0];
      setProductDesktopFileHover(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setProductDesktopImageHover(reader.result);
        setIsNewProductDesktopImageHover(true);
      };
      reader.readAsDataURL(file);
    }
  };

  /// <summary>
  /// Author : -
  /// Used to set popup banner
  /// </summary>
  const popupBannerDrop = (acceptedFiles) => {
    if (acceptedFiles.length !== 0) {
      const file = acceptedFiles[0];
      setPopupBannerFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPopupBannerImage(reader.result);
        setIsNewPopupBanner(true);
      };
      reader.readAsDataURL(file);
    }
  };

  /// <summary>
  /// Author : -
  /// </summary>
  const submitForm = async (data, e) => {
    let params = {
      productName: data.productName,
      status: data.status,
      productId: data.productDisplayId,
      productCode: data.productCode,
      clientCode: data.clientCode,
      clientSecret: data.clientSecret,
      isNewProductImage: isNewImage,
      isNewLobbyIconMobileImage: isNewLobbyIconMobileImage,
      isNewLobbyIconMobile2Image: isNewLobbyIconMobile2Image,
      isNewLobbyIconMobileDesktop: isNewLobbyIconDesktopImage,
      isNewBannerImage: isNewBanner,
      isNewPopupBannerImage: isNewPopupBanner,
      isNewProductDesktopImage: isNewProductDesktopImage,
      isNewProductDesktopImageHover: isNewProductDesktopImageHover,
      isNewIconImage: isNewIcon,
      backUrl: data.backUrl,
      reportUrl: data.reportUrl,
      isSeamless: data.isSeamless,
      downloadUrl: data.downloadUrl,
      bonus: data.bonus,
      commission: data.commission,
      useGroupRate: data.useGroupRate,
      regularSyncInterval: data.regularSyncInterval,
      regularSyncPattern: selectRegularSyncPattern,
      settlementSyncEnabled: data.settlementSyncEnabled,
      settlementSyncTimeHH: settlementSyncTimeHH,
      settlementSyncTimeMM: settlementSyncTimeMM,
      supportFullPlayerGameRecords: data.supportAgentMode,
      hasOwnLobby: data.hasOwnLobby,
      content: data.content,
    };
    if (data.clubFee) { params['clubFee'] = data.clubFee; }
    if (typeof (data.hideLobby) != "undefined") {
      params["hideLobby"] = data.hideLobby;
    }
    if (!stringIsNullOrEmpty(data.rankInGamesLobby)) {
      params["rankInGamesLobby"] = data.rankInGamesLobby;
    }

    if (!stringIsNullOrEmpty(logoFile)) {
      params["productImage"] = logoFile;
    }

    if (!stringIsNullOrEmpty(bannerFile)) {
      params["bannerImage"] = bannerFile;
    }

    if (!stringIsNullOrEmpty(lobbyIconMobileFile)) {
      params["lobbyIconMobile"] = lobbyIconMobileFile;
    }

    if (!stringIsNullOrEmpty(lobbyIconMobile2File)) {
      params["lobbyIconMobile2"] = lobbyIconMobile2File;
    }

    if (!stringIsNullOrEmpty(lobbyIconDesktopFile)) {
      params["lobbyIconDesktop"] = lobbyIconDesktopFile;
    }

    if (!stringIsNullOrEmpty(bannerDesktopFile)) {
      params["bannerDesktopImage"] = bannerDesktopFile;
    }

    if (!stringIsNullOrEmpty(productDesktopFile)) {
      params["productDesktopImage"] = productDesktopFile;
    }

    if (!stringIsNullOrEmpty(productDesktopFileHover)) {
      params["productDesktopImageHover"] = productDesktopFileHover;
    }

    if (!stringIsNullOrEmpty(iconFile)) {
      params["iconImage"] = iconFile;
    }

    if (!stringIsNullOrEmpty(popupBannerFile)) {
      params["popupBannerImage"] = popupBannerFile;
    }

    if (stringIsNullOrEmpty(bannerImage)) {
      params["bannerImageRemoved"] = true;
    }

    if (stringIsNullOrEmpty(lobbyIconMobileImage)) {
      params["lobbyIconMobileImageRemoved"] = true;
    }

    if (stringIsNullOrEmpty(lobbyIconMobile2Image)) {
      params["lobbyIconMobile2ImageRemoved"] = true;
    }

    if (stringIsNullOrEmpty(lobbyIconDesktopImage)) {
      params["lobbyIconDesktopRemoved"] = true;
    }

    if (stringIsNullOrEmpty(bannerDesktopImage)) {
      params["bannerDesktopImageRemoved"] = true;
    }

    if (stringIsNullOrEmpty(productDesktopImage)) {
      params["productDesktopImageRemoved"] = true;
    }

    if (stringIsNullOrEmpty(productDesktopImageHover)) {
      params["productDesktopImageRemovedHover"] = true;
    }

    if (stringIsNullOrEmpty(logoImage)) {
      params["productImageRemoved"] = true;
    }

    if (stringIsNullOrEmpty(iconImage)) {
      params["iconImageRemoved"] = true;
    }

    if (stringIsNullOrEmpty(popupBannerImage)) {
      params["popupBannerImageRemoved"] = true;
    }

    if (!stringIsNullOrEmpty(productId)) {
      params["id"] = productId;
      _dispatch(setSaveAdminLog("EDIT_BO_MASTER_PRODUCT", _history.location.pathname, params));
    } else {
      _dispatch(setSaveAdminLog("CREATE_BO_MASTER_PRODUCT", _history.location.pathname, params));
    }

    let formBody = createMultiPartFormBody(params);

    let responseJson = await ApiEngine.post(
      ApiUrl._API_CREATE_OR_UPDATE_MASTER_PRODUCT,
      formBody
    );

    _dispatch(
      showMessage(
        responseJson[ApiKey._API_SUCCESS_KEY],
        t(responseJson[ApiKey._API_MESSAGE_KEY])
      )
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setIsNewImage(false);
      setIsNewBanner(false);
      setIsNewIcon(false);
      setProductId(responseJson[ApiKey._API_DATA_KEY]["id"]);
      setBonus(watch("bonus"));
      setCommission(watch("commission"));
      setClubFee(watch("clubFee"));
    }
  };

  /// <summary>
  /// Author : -
  /// </summary>
  async function init() {
    initHourAndMinuteList();
    if (_location.state) {
      var apiUrl = ApiUrl._API_GET_MASTER_PRODUCT_BY_ID;
      apiUrl += "?id=" + _location.state.id;
      let responseJson = await ApiEngine.get(apiUrl);
      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        setProductId(responseJson[ApiKey._API_DATA_KEY]["id"]);
        setProductDisplayId(responseJson[ApiKey._API_DATA_KEY]["productId"]);
        setProductName(responseJson[ApiKey._API_DATA_KEY]["productName"]);
        setProductCode(responseJson[ApiKey._API_DATA_KEY]["productCode"]);
        setClientCode(responseJson[ApiKey._API_DATA_KEY]["clientCode"]);
        setClientSecret(responseJson[ApiKey._API_DATA_KEY]["clientSecret"]);
        setBackUrl(responseJson[ApiKey._API_DATA_KEY]["backUrl"]);
        setReportUrl(responseJson[ApiKey._API_DATA_KEY]["reportUrl"]);
        setStatus(responseJson[ApiKey._API_DATA_KEY]["status"]);
        setIsSeamless(responseJson[ApiKey._API_DATA_KEY]["isSeamless"]);
        setContent(responseJson[ApiKey._API_DATA_KEY]["content"])
        if (
          !stringIsNullOrEmpty(
            responseJson[ApiKey._API_DATA_KEY]["productImage"]
          )
        ) {
          setLogoImage(responseJson[ApiKey._API_DATA_KEY]["productImage"]);
        }
        if (
          !stringIsNullOrEmpty(
            responseJson[ApiKey._API_DATA_KEY]["productDesktopImage"]
          )
        ) {
          setProductDesktopImage(responseJson[ApiKey._API_DATA_KEY]["productDesktopImage"]);
        }
        if (
          !stringIsNullOrEmpty(
            responseJson[ApiKey._API_DATA_KEY]["lobbyIconMobile"]
          )
        ) {
          setLobbyIconMobileImage(responseJson[ApiKey._API_DATA_KEY]["lobbyIconMobile"]);
        }
        if (
          !stringIsNullOrEmpty(
            responseJson[ApiKey._API_DATA_KEY]["lobbyIconMobile2"]
          )
        ) {
          setLobbyIconMobile2Image(responseJson[ApiKey._API_DATA_KEY]["lobbyIconMobile2"]);
        }
        if (
          !stringIsNullOrEmpty(
            responseJson[ApiKey._API_DATA_KEY]["lobbyIconDesktop"]
          )
        ) {
          setLobbyIconDesktopImage(responseJson[ApiKey._API_DATA_KEY]["lobbyIconDesktop"]);
        }
        if (
          !stringIsNullOrEmpty(
            responseJson[ApiKey._API_DATA_KEY]["productDesktopImageHover"]
          )
        ) {
          setProductDesktopImageHover(responseJson[ApiKey._API_DATA_KEY]["productDesktopImageHover"]);
        }
        if (
          !stringIsNullOrEmpty(
            responseJson[ApiKey._API_DATA_KEY]["bannerImage"]
          )
        ) {
          setBannerImage(responseJson[ApiKey._API_DATA_KEY]["bannerImage"]);
        }
        if (
          !stringIsNullOrEmpty(
            responseJson[ApiKey._API_DATA_KEY]["iconImage"]
          )
        ) {
          setIconImage(responseJson[ApiKey._API_DATA_KEY]["iconImage"]);
        }
        if (
          !stringIsNullOrEmpty(
            responseJson[ApiKey._API_DATA_KEY]["popupBannerImage"]
          )
        ) {
          setPopupBannerImage(responseJson[ApiKey._API_DATA_KEY]["popupBannerImage"]);
        }
        setDownloadUrl(responseJson[ApiKey._API_DATA_KEY]["downloadUrl"]);
        setBonus(responseJson[ApiKey._API_DATA_KEY]["bonus"]);
        setValue("bonus", responseJson[ApiKey._API_DATA_KEY]["bonus"]);
        setValue(
          "commission",
          responseJson[ApiKey._API_DATA_KEY]["commission"]
        );
        setCommission(responseJson[ApiKey._API_DATA_KEY]["commission"]);
        setValue(
          "clubFee",
          responseJson[ApiKey._API_DATA_KEY]["clubFee"]
        );
        setClubFee(responseJson[ApiKey._API_DATA_KEY]["clubFee"]);
        setValue(
          "useGroupRate",
          responseJson[ApiKey._API_DATA_KEY]["useGroupRate"]
        );
        setRegularSyncInterval(
          responseJson[ApiKey._API_DATA_KEY]["regularSyncInterval"]
        );
        setSelectRegularSyncPattern(
          responseJson[ApiKey._API_DATA_KEY]["regularSyncPattern"]
        );
        setSettlementSyncEnabled(
          responseJson[ApiKey._API_DATA_KEY]["settlementSyncEnabled"]
        );
        setValue(
          "settlementSyncEnabled",
          responseJson[ApiKey._API_DATA_KEY]["settlementSyncEnabled"]
        );
        setSettlementSyncTimeHH(
          responseJson[ApiKey._API_DATA_KEY]["settlementSyncTimeHH"]
        );
        setSettlementSyncTimeMM(
          responseJson[ApiKey._API_DATA_KEY]["settlementSyncTimeMM"]
        );
        setValue(
          "supportAgentMode",
          responseJson[ApiKey._API_DATA_KEY]["supportFullPlayerGameRecords"]
        );
        setValue(
          "hasOwnLobby",
          responseJson[ApiKey._API_DATA_KEY]["hasOwnLobby"]
        );
        setShowRankInput(responseJson[ApiKey._API_DATA_KEY]["hasOwnLobby"]);
        setValue("hideLobby", responseJson[ApiKey._API_DATA_KEY]["hideLobby"]);
        setValue(
          "rankInGamesLobby",
          responseJson[ApiKey._API_DATA_KEY]["rankInGamesLobby"]
        );
      }
    }
  }

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    register({ name: "selectRegularSyncPattern" });
    register({ name: "settlementSyncTimeHH" });
    register({ name: "settlementSyncTimeMM" });
    init();
  }, []);

  /// <summary>
  /// Author : -
  /// </summary>
  function preSubmit() {
    if (
      productId &&
      (bonus != watch("bonus") || commission != watch("commission") || clubFee != watch("clubFee"))
    ) {
      setShowConfirmation(true);
    } else {
      handleSubmit(submitForm)();
    }
  }

  function initHourAndMinuteList() {
    setHourList(makeNumberList(0, 23));
    setMinuteList(makeNumberList(0, 59));
    setSettlementSyncTimeHH("00");
    setSettlementSyncTimeMM("00");
  }

  /// <summary>
  /// Make the number list
  /// </summary>
  function makeNumberList(minimum, maximum) {
    let numberList = [];
    for (let i = minimum; i <= maximum; i++) {
      numberList.push({
        value: i,
        label: i.toString().padStart(2, "0"),
      });
    }
    return numberList;
  }

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    (async () => {
      let currentUseGroupRate = watch("useGroupRate");

      if (currentUseGroupRate) {
        let responseJson = await ApiEngine.get(
          ApiUrl._API_GET_FIRST_GROUPED_PRODUCT
        );
        if (
          responseJson[ApiKey._API_SUCCESS_KEY] &&
          responseJson[ApiKey._API_DATA_KEY] != null
        ) {
          setValue("bonus", responseJson[ApiKey._API_DATA_KEY]["bonus"]);
          setValue(
            "commission",
            responseJson[ApiKey._API_DATA_KEY]["commission"]
          );
        }
      }
    })();
  }, [watch("useGroupRate"), productId]);

  return (
    <div>
      {showConfirmation && (
        <SweetAlert
          warning
          showCancel
          confirmBtnText={t("CONFIRM")}
          cancelBtnText={t("CANCEL")}
          confirmBtnBsStyle="danger"
          title={t("BONUS_COMMISSION_CHANGE_CONFIRMATION")}
          onConfirm={() => {
            setShowConfirmation(false);
            handleSubmit(submitForm)();
          }}
          onCancel={() => {
            setShowConfirmation(false);
          }}
        ></SweetAlert>
      )}
      <h1 className="page-header">
        {productId ? t("EDIT_MASTER_PRODUCT") : t("ADD_MASTER_PRODUCT")}
        <NavigationButton history={_history} />
      </h1>
      <div className="row">
        <div className="col-xl-12">
          <Panel>
            <PanelBody>
              <form onSubmit={handleSubmit(submitForm)}>
                <div className="row">
                  <h3>{t("WEB_APP") + " / " + t("MOBILE_APP")}</h3>
                </div>
                <div className="row">
                  <div className="col-lg-3">
                    <div className="form-group">
                      <label>
                        <b>{t("LOGO")}</b>
                      </label>
                      <Dropzone
                        accept={"image/*"}
                        onDrop={(acceptedFiles) => logoDrop(acceptedFiles)}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <section>
                            <div
                              className="dropzone"
                              style={{
                                minHeight: "200px",
                                textAlign: "center",
                              }}
                              {...getRootProps()}
                            >
                              <input {...getInputProps()} />
                              <h4 style={{ color: "grey" }}>
                                {t(
                                  "DRAG_N_DROP_SOME_FILES_HERE_OR_CLICK_TO_SELECT_FILES"
                                )}
                              </h4>
                              {!stringIsNullOrEmpty(logoImage) && (
                                <aside className="thumbsContainer">
                                  <div className="thumb">
                                    <div className="thumbInner">
                                      <img
                                        src={logoImage}
                                        className="dropzone-img"
                                      />
                                    </div>
                                  </div>
                                </aside>
                              )}
                            </div>
                          </section>
                        )}
                      </Dropzone>
                      <br />
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => {
                          setLogoImage("");
                          setLogoFile();
                        }}
                      >
                        {t("REMOVE_IMAGE")}
                      </button>
                    </div>
                    <div className="form-group">
                      <label>
                        <b>{t("HOMEPAGE_LOBBY_ICON") + " (" + t("MOBILE") + ")"}</b>
                      </label>
                      <Dropzone
                        accept={"image/*"}
                        onDrop={(acceptedFiles) => lobbyIconMobileImageDrop(acceptedFiles)}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <section>
                            <div
                              className="dropzone"
                              style={{
                                minHeight: "200px",
                                textAlign: "center",
                              }}
                              {...getRootProps()}
                            >
                              <input {...getInputProps()} />
                              <h4 style={{ color: "grey" }}>
                                {t(
                                  "DRAG_N_DROP_SOME_FILES_HERE_OR_CLICK_TO_SELECT_FILES"
                                )}
                              </h4>
                              {!stringIsNullOrEmpty(lobbyIconMobileImage) && (
                                <aside className="thumbsContainer">
                                  <div className="thumb">
                                    <div className="thumbInner">
                                      <img
                                        src={lobbyIconMobileImage}
                                        className="dropzone-img"
                                      />
                                    </div>
                                  </div>
                                </aside>
                              )}
                            </div>
                          </section>
                        )}
                      </Dropzone>
                      <br />
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => {
                          setLobbyIconMobileImage("");
                          setLobbyIconMobileFile();
                        }}
                      >
                        {t("REMOVE_IMAGE")}
                      </button>
                    </div>
                    <div className="form-group">
                      <label>
                        <b>{t("HOMEPAGE_LOBBY_ICON") + " 2 (" + t("MOBILE") + ")"}</b>
                      </label>
                      <Dropzone
                        accept={"image/*"}
                        onDrop={(acceptedFiles) => lobbyIconMobile2ImageDrop(acceptedFiles)}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <section>
                            <div
                              className="dropzone"
                              style={{
                                minHeight: "200px",
                                textAlign: "center",
                              }}
                              {...getRootProps()}
                            >
                              <input {...getInputProps()} />
                              <h4 style={{ color: "grey" }}>
                                {t(
                                  "DRAG_N_DROP_SOME_FILES_HERE_OR_CLICK_TO_SELECT_FILES"
                                )}
                              </h4>
                              {!stringIsNullOrEmpty(lobbyIconMobile2Image) && (
                                <aside className="thumbsContainer">
                                  <div className="thumb">
                                    <div className="thumbInner">
                                      <img
                                        src={lobbyIconMobile2Image}
                                        className="dropzone-img"
                                      />
                                    </div>
                                  </div>
                                </aside>
                              )}
                            </div>
                          </section>
                        )}
                      </Dropzone>
                      <br />
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => {
                          setLobbyIconMobile2Image("");
                          setLobbyIconMobile2File();
                        }}
                      >
                        {t("REMOVE_IMAGE")}
                      </button>
                    </div>
                  </div>
                  <div className="col-lg-9">
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="form-group">
                          <label>
                            <b>{t("PRODUCT_ID")}</b>
                          </label>
                          <input
                            type="text"
                            name="productDisplayId"
                            className="form-control m-b-5"
                            placeholder={t("PLEASE_ENTER_PRODUCT_ID")}
                            ref={register({
                              required: "PLEASE_ENTER_PRODUCT_ID",
                            })}
                            defaultValue={productDisplayId}
                          />
                          {errors.productDisplayId && (
                            <div className="invalid-feedback">
                              {t(errors.productDisplayId.message)}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="form-group">
                          <label>
                            <b>{t("PRODUCT_NAME")}</b>
                          </label>
                          <input
                            type="text"
                            name="productName"
                            className="form-control m-b-5"
                            placeholder={t("PLEASE_ENTER_PRODUCT_NAME")}
                            ref={register({
                              required: "PLEASE_ENTER_PRODUCT_NAME",
                            })}
                            defaultValue={productName}
                          />
                          {errors.productName && (
                            <div className="invalid-feedback">
                              {t(errors.productName.message)}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="form-group">
                          <label>
                            <b>{t("SHORT_CODE")}</b>
                          </label>
                          <input
                            type="text"
                            className="form-control m-b-5"
                            name="productCode"
                            placeholder={t("PLEASE_ENTER_SHORT_CODE")}
                            ref={register({
                              required: "PLEASE_ENTER_SHORT_CODE",
                            })}
                            defaultValue={productCode}
                          />
                          {errors.productCode && (
                            <div className="invalid-feedback">
                              {t(errors.productCode.message)}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="form-group">
                          <label>
                            <b>{t("BASE_URL")}</b>
                          </label>
                          <input
                            type="text"
                            name="backUrl"
                            className="form-control m-b-5"
                            placeholder={t("PLEASE_ENTER_GAME_BASE_URL")}
                            defaultValue={backUrl}
                            ref={register({
                              required: "PLEASE_ENTER_GAME_BASE_URL",
                            })}
                          />
                          {errors.backUrl && (
                            <div className="invalid-feedback">
                              {t(errors.backUrl.message)}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="form-group">
                          <label>
                            <b>{t("REPORT_URL")}</b>
                          </label>
                          <input
                            type="text"
                            name="reportUrl"
                            className="form-control m-b-5"
                            placeholder={t("PLEASE_ENTER_REPORT_URL")}
                            defaultValue={reportUrl}
                            ref={register()}
                          />
                          {errors.reportUrl && (
                            <div className="invalid-feedback">
                              {t(errors.reportUrl.message)}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="form-group">
                          <label>
                            <b>{t("DOWNLOAD_URL")}</b>
                          </label>
                          <input
                            type="text"
                            name="downloadUrl"
                            className="form-control m-b-5"
                            placeholder={t("PLEASE_ENTER_DOWNLOAD_URL")}
                            defaultValue={downloadUrl}
                            ref={register}
                          />
                          {errors.downloadUrl && (
                            <div className="invalid-feedback">
                              {t(errors.downloadUrl.message)}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="form-group">
                          <label>
                            <b>{t("PRODUCT_CLIENT_CODE")}</b>
                          </label>
                          <input
                            type="text"
                            name="clientCode"
                            className="form-control m-b-5"
                            placeholder={t("PLEASE_ENTER_CLIENT_CODE")}
                            defaultValue={clientCode}
                            ref={register({
                              required: "PLEASE_ENTER_CLIENT_CODE",
                            })}
                          />
                          {errors.clientCode && (
                            <div className="invalid-feedback">
                              {t(errors.clientCode.message)}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="form-group">
                          <label>
                            <b>{t("PRODUCT_CLIENT_SECRET")}</b>
                          </label>
                          <input
                            type="text"
                            name="clientSecret"
                            className="form-control m-b-5"
                            placeholder={t("PLEASE_ENTER_CLIENT_SECRET")}
                            defaultValue={clientSecret}
                            ref={register({
                              required: "PLEASE_ENTER_CLIENT_SECRET",
                            })}
                          />
                          {errors.clientSecret && (
                            <div className="invalid-feedback">
                              {t(errors.clientSecret.message)}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="form-group">
                          <label>
                            <b>{t("STATUS")}</b>
                          </label>
                          <div className="m-b-5">
                            <div className="switcher">
                              <input
                                type="checkbox"
                                name="status"
                                id="status"
                                onChange={(e) => setStatus(e.target.checked)}
                                value={true}
                                checked={status}
                                ref={register}
                              />
                              <label htmlFor="status"></label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={productName === "D2 Poker" ? "col-lg-3" : "col-lg-4"}>
                        <div className="form-group">
                          <label>
                            <b>{t("COMMISSION")} (%)</b>
                          </label>
                          <br />
                          <input
                            type="number"
                            name="commission"
                            ref={register({
                              required: "PLEASE_ENTER_COMMISSION",
                              min: {
                                value: 0,
                                message:
                                  "PLEASE_ENTER_VALUE_NOT_LESS_THAN_ZERO",
                              },
                              max: {
                                value: 100,
                                message: "PLEASE_ENTER_VALUE_NOT_MORE_THAN_100",
                              },
                            })}
                            className="form-control m-b-5"
                            placeholder={t("PLEASE_ENTER_COMMISSION")}
                            defaultValue={0}
                          />
                          {errors.commission && (
                            <div className="invalid-feedback">
                              {t(errors.commission.message)}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className={productName === "D2 Poker" ? "col-lg-3" : "col-lg-4"}>
                        <div className="form-group">
                          <label>
                            <b>{t("BONUS")} (%)</b>
                          </label>
                          <br />
                          <input
                            type="number"
                            name="bonus"
                            ref={register({
                              required: "PLEASE_ENTER_BONUS",
                              min: {
                                value: 0,
                                message:
                                  "PLEASE_ENTER_VALUE_NOT_LESS_THAN_ZERO",
                              },
                              max: {
                                value: 100,
                                message: "PLEASE_ENTER_VALUE_NOT_MORE_THAN_100",
                              },
                            })}
                            className="form-control m-b-5"
                            placeholder={t("PLEASE_ENTER_BONUS")}
                            defaultValue={0}
                          />
                          {errors.bonus && (
                            <div className="invalid-feedback">
                              {t(errors.bonus.message)}
                            </div>
                          )}
                        </div>
                      </div>
                      {productName === "D2 Poker" && <div className="col-lg-3">
                        <div className="form-group">
                          <label>
                            <b>{t("CLUB_FEE")} (%)</b>
                          </label>
                          <br />
                          <input
                            type="number"
                            name="clubFee"
                            ref={register({
                              required: "PLEASE_ENTER_CLUB_FEE",
                              min: {
                                value: 0,
                                message:
                                  "PLEASE_ENTER_VALUE_NOT_LESS_THAN_ZERO",
                              },
                              max: {
                                value: 100,
                                message: "PLEASE_ENTER_VALUE_NOT_MORE_THAN_100",
                              },
                            })}
                            className="form-control m-b-5"
                            placeholder={t("PLEASE_ENTER_CLUB_FEE")}
                            defaultValue={0}
                          />
                          {errors.clubFee && (
                            <div className="invalid-feedback">
                              {t(errors.clubFee.message)}
                            </div>
                          )}
                        </div>
                      </div>}
                      <div className={productName === "D2 Poker" ? "col-lg-3" : "col-lg-4"}>
                        <div className="form-group">
                          <label>
                            <b>{t("GROUP_RATE")}</b>
                          </label>
                          <div className="m-b-5">
                            <div className="switcher">
                              <input
                                type="checkbox"
                                name="useGroupRate"
                                id="useGroupRate"
                                onChange={(e) =>
                                  setValue("useGroupRate", e.target.checked)
                                }
                                value={true}
                                checked={watch("useGroupRate")}
                                ref={register}
                              />
                              <label htmlFor="useGroupRate"></label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="form-group">
                          <label>
                            <b>{t("REGULAR_SYNC_INTERVAL")}</b>
                          </label>
                          <input
                            type="text"
                            name="regularSyncInterval"
                            className="form-control m-b-5"
                            defaultValue={regularSyncInterval}
                            ref={register({
                              required: "PLEASE_ENTER_SYNC_INTERVAL",
                            })}
                          />
                          {errors.regularSyncInterval && (
                            <div className="invalid-feedback">
                              {t(errors.regularSyncInterval.message)}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="form-group">
                          <label>
                            <b>{t("REGULAR_SYNC_PATTERN")}</b>
                          </label>
                          <div className="col-md-7">
                            <Select
                              name="selectRegularSyncPattern"
                              options={_SYNC_INTERVAL_PATTERN}
                              value={_SYNC_INTERVAL_PATTERN.filter(
                                (option) =>
                                  option.value == selectRegularSyncPattern
                              )}
                              onChange={(e) => {
                                setSelectRegularSyncPattern(e.value);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="form-group">
                          <label>
                            <b>{t("SETTLEMENT_SYNC_ENABLED")}</b>
                          </label>
                          <div className="m-b-5">
                            <div className="switcher">
                              <input
                                type="checkbox"
                                name="settlementSyncEnabled"
                                id="settlementSyncEnabled"
                                onChange={(e) =>
                                  setValue(
                                    "settlementSyncEnabled",
                                    e.target.checked
                                  )
                                }
                                value={true}
                                checked={watch("settlementSyncEnabled")}
                                ref={register}
                              />
                              <label htmlFor="settlementSyncEnabled"></label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="form-group">
                          <label>
                            <b>{t("SETTLEMENT_SYNC_HOUR")}</b>
                          </label>
                          <div className="col-md-7">
                            <Select
                              name="settlementSyncTimeHH"
                              options={hourList}
                              value={hourList.filter(
                                (option) => option.value == settlementSyncTimeHH
                              )}
                              onChange={(e) => {
                                setSettlementSyncTimeHH(e.value);
                              }}
                            />
                            {errors.settlementSyncTimeHH && (
                              <div className="invalid-feedback">
                                {t(errors.settlementSyncTimeHH.message)}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="form-group">
                          <label>
                            <b>{t("SETTLEMENT_SYNC_MINUTE")}</b>
                          </label>
                          <div className="col-md-7">
                            <Select
                              name="settlementSyncTimeMM"
                              options={minuteList}
                              value={minuteList.filter(
                                (option) => option.value == settlementSyncTimeMM
                              )}
                              onChange={(e) => {
                                setSettlementSyncTimeMM(e.value);
                              }}
                            />
                            {errors.settlementSyncTimeMM && (
                              <div className="invalid-feedback">
                                {t(errors.settlementSyncTimeMM.message)}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="form-group">
                          <label>
                            <b>{t("SUPPORT_AGENT_GAME_RETRIEVAL")}</b>
                          </label>
                          <div className="m-b-5">
                            <div className="switcher">
                              <input
                                type="checkbox"
                                name="supportAgentMode"
                                id="supportAgentMode"
                                onChange={(e) =>
                                  setValue("supportAgentMode", e.target.checked)
                                }
                                value={true}
                                checked={watch("supportAgentMode")}
                                ref={register}
                              />
                              <label htmlFor="supportAgentMode"></label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="form-group">
                          <label>
                            <b>{t("IS_SEAMLESS")}</b>
                          </label>
                          <div className="m-b-5">
                            <div className="switcher">
                              <input
                                type="checkbox"
                                name="isSeamless"
                                id="isSeamless"
                                onChange={(e) =>
                                  setIsSeamless(e.target.checked)
                                }
                                value={true}
                                checked={isSeamless}
                                ref={register}
                              />
                              <label htmlFor="isSeamless"></label>
                            </div>
                          </div>
                        </div>
                      </div>
                      {!isSeamless &&
                        <div className="col-lg-8">
                          <div className="form-group row m-b-15">
                            <label className="col-md-4 col-form-label">
                              <b>{t("CONTENT")}</b>
                            </label>
                            <textarea
                              className="form-control"
                              rows="5"
                              style={{ marginTop: "10px" }}
                              ref={register}
                              name="content"
                              defaultValue={content}
                            ></textarea>
                            {errors.content && (
                              <div className="invalid-feedback">
                                {t(errors.content.message)}
                              </div>
                            )}
                          </div>
                        </div>}
                      {!isSeamless &&  <div className="col-lg-4">
                        <div className="form-group">
                          <label>
                            <b>{t("POPUP_BANNER_IMAGE")}</b>
                          </label>
                          <Dropzone
                            accept={"image/*"}
                            onDrop={(acceptedFiles) => popupBannerDrop(acceptedFiles)}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <section>
                                <div
                                  className="dropzone"
                                  style={{
                                    minHeight: "200px",
                                    textAlign: "center",
                                  }}
                                  {...getRootProps()}
                                >
                                  <input {...getInputProps()} />
                                  <h4 style={{ color: "grey" }}>
                                    {t(
                                      "DRAG_N_DROP_SOME_FILES_HERE_OR_CLICK_TO_SELECT_FILES"
                                    )}
                                  </h4>
                                  {!stringIsNullOrEmpty(popupBannerImage) && (
                                    <aside className="thumbsContainer">
                                      <div className="thumb">
                                        <div className="thumbInner">
                                          <img
                                            src={popupBannerImage}
                                            className="dropzone-img"
                                          />
                                        </div>
                                      </div>
                                    </aside>
                                  )}
                                </div>
                              </section>
                            )}
                          </Dropzone>
                          <br />
                          <button
                            type="button"
                            className="btn btn-danger"
                            onClick={() => {
                              setPopupBannerImage("");
                              setPopupBannerFile();
                            }}
                          >
                            {t("REMOVE_IMAGE")}
                          </button>
                        </div>
                      </div>}
                      <div className="col-lg-4">
                        <div className="form-group">
                          <label>
                            <b>{t("HAS_OWN_LOBBY")}</b>
                          </label>
                          <div className="m-b-5">
                            <div className="switcher">
                              <input
                                type="checkbox"
                                name="hasOwnLobby"
                                id="hasOwnLobby"
                                onChange={(e) => {
                                  setValue("hasOwnLobby", e.target.checked);
                                  setShowRankInput(e.target.checked);
                                }}
                                value={true}
                                checked={watch("hasOwnLobby")}
                                ref={register}
                              />
                              <label htmlFor="hasOwnLobby"></label>
                            </div>
                          </div>
                        </div>
                      </div>
                      {showRankInput && <>
                      <div className="col-lg-4">
                        <div className="form-group">
                          <label>
                            <b>{t("LOBBY_BANNER")}</b>
                          </label>
                          <Dropzone
                            accept={"image/*"}
                            onDrop={(acceptedFiles) => bannerDrop(acceptedFiles)}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <section>
                                <div
                                  className="dropzone"
                                  style={{
                                    minHeight: "200px",
                                    textAlign: "center",
                                  }}
                                  {...getRootProps()}
                                >
                                  <input {...getInputProps()} />
                                  <h4 style={{ color: "grey" }}>
                                    {t(
                                      "DRAG_N_DROP_SOME_FILES_HERE_OR_CLICK_TO_SELECT_FILES"
                                    )}
                                  </h4>
                                  {!stringIsNullOrEmpty(bannerImage) && (
                                    <aside className="thumbsContainer">
                                      <div className="thumb">
                                        <div className="thumbInner">
                                          <img
                                            src={bannerImage}
                                            className="dropzone-img"
                                          />
                                        </div>
                                      </div>
                                    </aside>
                                  )}
                                </div>
                              </section>
                            )}
                          </Dropzone>
                          <br />
                          <button
                            type="button"
                            className="btn btn-danger"
                            onClick={() => {
                              setBannerImage("");
                              setBannerFile();
                            }}
                          >
                            {t("REMOVE_IMAGE")}
                          </button>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="form-group">
                          <label>
                            <b>{t("BANNER_ICON")}</b>
                          </label>
                          <Dropzone
                            accept={"image/*"}
                            onDrop={(acceptedFiles) => iconDrop(acceptedFiles)}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <section>
                                <div
                                  className="dropzone"
                                  style={{
                                    minHeight: "200px",
                                    textAlign: "center",
                                  }}
                                  {...getRootProps()}
                                >
                                  <input {...getInputProps()} />
                                  <h4 style={{ color: "grey" }}>
                                    {t(
                                      "DRAG_N_DROP_SOME_FILES_HERE_OR_CLICK_TO_SELECT_FILES"
                                    )}
                                  </h4>
                                  {!stringIsNullOrEmpty(iconImage) && (
                                    <aside className="thumbsContainer">
                                      <div className="thumb">
                                        <div className="thumbInner">
                                          <img
                                            src={iconImage}
                                            className="dropzone-img"
                                          />
                                        </div>
                                      </div>
                                    </aside>
                                  )}
                                </div>
                              </section>
                            )}
                          </Dropzone>
                          <br />
                          <button
                            type="button"
                            className="btn btn-danger"
                            onClick={() => {
                              setIconImage("");
                              setIconFile();
                            }}
                          >
                            {t("REMOVE_IMAGE")}
                          </button>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="form-group">
                          <label>
                            <b>{t("RANK_IN_GAMES_LOBBY")}</b>
                          </label>
                          <br />
                          <input
                            type="number"
                            name="rankInGamesLobby"
                            ref={register({
                              min: {
                                value: 0,
                                message:
                                  "PLEASE_ENTER_VALUE_NOT_LESS_THAN_ZERO",
                              },
                              max: {
                                value: 100,
                                message: "PLEASE_ENTER_VALUE_NOT_MORE_THAN_100",
                              },
                            })}
                            className="form-control m-b-5"
                            placeholder={t("PLEASE_ENTER_RANK")}
                            defaultValue={0}
                          />
                          {errors.rankInGamesLobby && (
                            <div className="invalid-feedback">
                              {t(errors.rankInGamesLobby.message)}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="form-group">
                          <label>
                            <b>{t("HIDE_LOBBY")}</b>
                          </label>
                          <div className="m-b-5">
                            <div className="switcher">
                              <input
                                type="checkbox"
                                name="hideLobby"
                                id="hideLobby"
                                onChange={(e) => {
                                  setValue("hideLobby", e.target.checked);
                                }}
                                value={true}
                                checked={watch("hideLobby")}
                                ref={register}
                              />
                              <label htmlFor="hideLobby"></label>
                            </div>
                          </div>
                        </div>
                      </div></>}
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <h3>{t("DESKTOP")}</h3>
                </div>
                <div className="row">
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label>
                        <b>{t("DESKTOP_LOBBY_BANNER")}</b>
                      </label>
                      <Dropzone
                        accept={"image/*"}
                        onDrop={(acceptedFiles) => bannerDesktopDrop(acceptedFiles)}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <section>
                            <div
                              className="dropzone"
                              style={{
                                minHeight: "200px",
                                textAlign: "center",
                              }}
                              {...getRootProps()}
                            >
                              <input {...getInputProps()} />
                              <h4 style={{ color: "grey" }}>
                                {t(
                                  "DRAG_N_DROP_SOME_FILES_HERE_OR_CLICK_TO_SELECT_FILES"
                                )}
                              </h4>
                              {!stringIsNullOrEmpty(bannerDesktopImage) && (
                                <aside className="thumbsContainer">
                                  <div className="thumb">
                                    <div className="thumbInner">
                                      <img
                                        src={bannerDesktopImage}
                                        className="dropzone-img"
                                      />
                                    </div>
                                  </div>
                                </aside>
                              )}
                            </div>
                          </section>
                        )}
                      </Dropzone>
                      <br />
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => {
                          setBannerDesktopImage("");
                          setBannerDesktopFile();
                        }}
                      >
                        {t("REMOVE_IMAGE")}
                      </button>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label>
                        <b>{t("DESKTOP_ICON_IMAGE")}</b>
                      </label>
                      <Dropzone
                        accept={"image/*"}
                        onDrop={(acceptedFiles) => desktopLogoDrop(acceptedFiles)}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <section>
                            <div
                              className="dropzone"
                              style={{
                                minHeight: "200px",
                                textAlign: "center",
                              }}
                              {...getRootProps()}
                            >
                              <input {...getInputProps()} />
                              <h4 style={{ color: "grey" }}>
                                {t(
                                  "DRAG_N_DROP_SOME_FILES_HERE_OR_CLICK_TO_SELECT_FILES"
                                )}
                              </h4>
                              {!stringIsNullOrEmpty(productDesktopImage) && (
                                <aside className="thumbsContainer">
                                  <div className="thumb">
                                    <div className="thumbInner">
                                      <img
                                        src={productDesktopImage}
                                        className="dropzone-img"
                                      />
                                    </div>
                                  </div>
                                </aside>
                              )}
                            </div>
                          </section>
                        )}
                      </Dropzone>
                      <br />
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => {
                          setProductDesktopImage("");
                          setProductDesktopFile();
                        }}
                      >
                        {t("REMOVE_IMAGE")}
                      </button>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label>
                        <b>{t("DESKTOP_ICON_HOVER_IMAGE")}</b>
                      </label>
                      <Dropzone
                        accept={"image/*"}
                        onDrop={(acceptedFiles) => desktopLogoHoverDrop(acceptedFiles)}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <section>
                            <div
                              className="dropzone"
                              style={{
                                minHeight: "200px",
                                textAlign: "center",
                              }}
                              {...getRootProps()}
                            >
                              <input {...getInputProps()} />
                              <h4 style={{ color: "grey" }}>
                                {t(
                                  "DRAG_N_DROP_SOME_FILES_HERE_OR_CLICK_TO_SELECT_FILES"
                                )}
                              </h4>
                              {!stringIsNullOrEmpty(productDesktopImageHover) && (
                                <aside className="thumbsContainer">
                                  <div className="thumb">
                                    <div className="thumbInner">
                                      <img
                                        src={productDesktopImageHover}
                                        className="dropzone-img"
                                      />
                                    </div>
                                  </div>
                                </aside>
                              )}
                            </div>
                          </section>
                        )}
                      </Dropzone>
                      <br />
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => {
                          setProductDesktopImageHover("");
                          setProductDesktopFileHover();
                        }}
                      >
                        {t("REMOVE_IMAGE")}
                      </button>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="form-group">
                      <label>
                        <b>{t("HOMEPAGE_LOBBY_ICON") + " (" + t("DESKTOP") + ")"}</b>
                      </label>
                      <Dropzone
                        accept={"image/*"}
                        onDrop={(acceptedFiles) => lobbyIconDesktopImageDrop(acceptedFiles)}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <section>
                            <div
                              className="dropzone"
                              style={{
                                minHeight: "200px",
                                textAlign: "center",
                              }}
                              {...getRootProps()}
                            >
                              <input {...getInputProps()} />
                              <h4 style={{ color: "grey" }}>
                                {t(
                                  "DRAG_N_DROP_SOME_FILES_HERE_OR_CLICK_TO_SELECT_FILES"
                                )}
                              </h4>
                              {!stringIsNullOrEmpty(lobbyIconDesktopImage) && (
                                <aside className="thumbsContainer">
                                  <div className="thumb">
                                    <div className="thumbInner">
                                      <img
                                        src={lobbyIconDesktopImage}
                                        className="dropzone-img"
                                      />
                                    </div>
                                  </div>
                                </aside>
                              )}
                            </div>
                          </section>
                        )}
                      </Dropzone>
                      <br />
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => {
                          setLobbyIconDesktopImage("");
                          setLobbyIconDesktopFile();
                        }}
                      >
                        {t("REMOVE_IMAGE")}
                      </button>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-lg-3">
                    <div className="form-group">
                      <button
                        type="button"
                        onClick={() => preSubmit()}
                        className="btn btn-primary"
                      >
                        {t("SUBMIT")}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </PanelBody>
          </Panel>
        </div>
      </div>
    </div>
  );
};

export default MasterProductDetail;