import React, { useState, useEffect } from "react";
import ReactTable from "../../../components/constantComponent/reactTable/ReactTable";
import { Panel, PanelBody } from "./../../../components/panel/panel.jsx";
import "../../../assets/css/custom.css";
import Select from "react-select";
import { useForm } from "react-hook-form";
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
import { createFormBody, navigateTo, stringIsNullOrEmpty } from "../../../util/Util";
import {
  ApiUrl,
  ApiKey,
  ContentTypeString,
  LanguageKey,
} from "../../../util/Constant";
import { useTranslation } from "react-i18next";
import ConfigurationLanguageDetail from "./../ConfigurationLanguageDetail";
import { fetch } from "whatwg-fetch";
import ApiEngine from "../../../util/ApiEngine";
import { useDispatch } from "react-redux";
import { showMessage, setSaveAdminLog } from "../../../redux/AppAction";
import { useHistory } from "react-router-dom";

/// <summary>
/// Author : -
/// </summary>
const ChatShpereDetailLanguage = (props) => {
  const { handleSubmit, errors, register, content, setValue, clearErrors } = useForm();
  const { t } = useTranslation(LanguageKey._PRIMARY);
  const [data, setData] = useState([]);
  const _dispatch = useDispatch();
  let _history = useHistory();
  const [
    languageDetailModalVisibility,
    setLanguageDetailModalVisibility,
  ] = useState(false);

  const _EXCLUDE_TITLE_TYPE = [
    ContentTypeString._VIP_BENEFIT_CONTENT,
    ContentTypeString._VIP_CRITERIA,
  ];
  const _EXCLUDE_CONTENT_TYPE = [
    ContentTypeString._VIP_BENEFIT,
    ContentTypeString._PRODUCT_CATEGORY,
  ];
  const [isOpen, setIsOpen] = useState(false);
  const [languageDetailId, setLanguageDetailId] = useState("");
  const [tableColumns, setTableColumns] = useState([]);
  const [languageOption, setLanguageOption] = useState([]);
  const [languageId, setLanguageId] = useState("");
  const [dataContentId, setDataContentId] = useState("");
  const [contentData, setContentData] = useState([]);
  const [isCreated, setIsCreated] = useState(0);
  const META_COUNT = 10;
  const [apiData, setApiData] = useState([]);
  const [editApiData, setEditApiData] = useState([]);
  const [noOfRows, setNoOfRows] = useState(1);
  const [contentError, setContentError] = useState("");
  const [content1, setContent1] = useState("");
  const [status1, setStatus1] = useState(false);
  const [contentLanguageId, setContentLanguageId] = useState(0);

  let _tableColumns = [
    {
      Header: "LANGUAGE",
      accessor: "languageName",
      disableSortBy: true,
    },
    {
      Header: "CONTENT",
      accessor: "content",
      disableSortBy: true,
    },
    {
      id: "status",
      Header: "STATUS",
      accessor: (d) => {
        return d.status ? (
          <span className="badge badge-secondary badge-green">{t("ACTIVE")}</span>
        ) : (
          <span className="badge badge-secondary badge-danger">
            {t("SUSPENDED")}
          </span>
        );
      },
    },
    {
      id: "action",
      Header: "",
      Cell: ({ row }) => (
        <div className="btn-group m-r-5 m-b-5">
          <UncontrolledButtonDropdown>
            <DropdownToggle caret color="default">
              <i className="fas fa-cog"></i>
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                onClick={() => {
                  setLanguageId(row.original.languageId);
                  setContent1(row.original.content);
                  setStatus1(row.original.status);
                  setContentLanguageId(row.original.id);
                  setIsOpen(true);
                }}
              >
                <span className="text-warning">{t("EDIT")}</span>
              </DropdownItem>
              {!row.original.status &&
                <>
                  <DropdownItem
                    onClick={() => {
                      onSubmit(row.original);
                    }}
                  >
                    <span className="text-warning">{row.original.status ? t("SET_SUSPENDED") : t("SET_ACTIVE")}</span>
                  </DropdownItem>
                </>
              }
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </div>
      ),
      disableSortBy: true,
      disableFilters: true,
      width: 100,
      style: { overflow: "visible" },
    },
  ];


  const onSubmit = async (data) => {
    let params = {
      contentId: props.id,
      content: data.content1,
      languageId: languageId,
      status: data.status1
    };

    if (stringIsNullOrEmpty(params["languageId"])) {
      params["languageId"] = data.languageId
    }

    if (stringIsNullOrEmpty(params["content"])) {
      params["content"] = data.content
    }

    if (stringIsNullOrEmpty(params["status"])) {
      params["status"] = !data.status
    }

    if (!stringIsNullOrEmpty(contentLanguageId)) {
      params["id"] = contentLanguageId;
    } else if (!stringIsNullOrEmpty(data.id)) {
      params["id"] = data.id;
    }

    let responseJson = await ApiEngine.post(
      ApiUrl._API_CREATE_OR_UPDATE_CHAT_SHPERE_CONTENT_LANGUAGE,
      createFormBody(params)
    );
    _dispatch(
      showMessage(
        responseJson[ApiKey._API_SUCCESS_KEY],
        t(responseJson[ApiKey._API_MESSAGE_KEY])
      )
    );
    setIsOpen(false);
    getChatSphereContent();
    setDataContentId("");
    setLanguageId("");
    setContentLanguageId("");
    setContent1("");
    setStatus1(false);
  };
  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    getLanguage();
    getChatSphereContent();
    /// Used to Exclude the column of table

  }, []);

  async function getLanguage() {
    await fetch(ApiUrl._API_GET_LANGUAGE, {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          const languageList = [];
          responseJson[ApiKey._API_DATA_KEY].forEach(function (language) {
            languageList.push({ label: language.name, value: language.id });
          });
          setLanguageOption(languageList);
        }
      });
  }


  async function getChatSphereContent(languageId) {

    var apiUrl = ApiUrl._API_GET_CHAT_SHPERE_CONTENT_LANGUAGE;
    if (languageId != null) {
      apiUrl += "?id=" + languageId
    } else {
      apiUrl += "?contentId=" + props.id
    }

    fetch(apiUrl, {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          setContentData(responseJson[ApiKey._API_DATA_KEY]);
        }
      }
      );
  }



  return (
    <div>
      <div className="row">
        <ConfigurationLanguageDetail
          visible={languageDetailModalVisibility}
          onFinish={() => {
            setLanguageDetailModalVisibility(false);
          }}
          dataTypeId={props.id}
          languageDetailId={languageDetailId}
          languageType={props.type}
        />

      </div>
      <Panel>
        <PanelBody>
          <button
            type="button"
            onClick={() => {
              setLanguageDetailId("");
              setIsCreated(1);
              setIsOpen(true);
              setNoOfRows(1);
            }}
            className="btn btn-purple"
          >
            {t("ADD_LANGUAGE")}
          </button>
          <ReactTable data={contentData} columns={_tableColumns} minRows={1} />
        </PanelBody>
      </Panel>

      <Modal fade={false} contentClassName="modal-brand modal-bottom modal-numpad withdrawal" isOpen={isOpen} centered>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader>
            {isCreated == 1 ? t("ADD_LANGUAGE") : t("EDIT_LANGUAGE")}
          </ModalHeader>
          <ModalBody style={{ minHeight: "30vh" }}>

            {isCreated == 1 ?
              (
                <>
                  <div className="row">

                    <div className="col-lg-12">
                      <div className="form-group row m-b-15">
                        <label className="col-md-4 col-form-label">
                          <b>{t("LANGUAGE")}</b>
                        </label>
                        <div className="col-md-7">
                          <Select
                            name="languageId"
                            options={languageOption}
                            placeholder={
                              languageOption.filter(
                                (option) => option.value == languageId
                              )[0] !== undefined
                                ? languageOption.filter(
                                  (option) =>
                                    option.value == languageId
                                )[0].label
                                : ""
                            }
                            value={languageOption.filter(
                              (option) => option.value == languageId
                            )}
                            onChange={(e) => {
                              setLanguageId(e.value);
                              setValue("languageId", e.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group row m-b-15">
                        <label className="col-md-4 col-form-label">
                          <b>{t("CONTENT")}</b>
                        </label>
                        <div className="col-md-7">
                          <textarea
                            className="form-control"
                            rows="5"
                            style={{ marginTop: "10px" }}
                            ref={register}
                            name="content1"
                            defaultValue={content1}
                            onChange={(e) => {
                              setContent1(e.target.value);
                              setValue("content1", e.target.value);
                            }}
                          ></textarea>
                          {errors.content1 && (
                            <div className="invalid-feedback">
                              {t(errors.content1.message)}
                            </div>
                          )}
                          {!stringIsNullOrEmpty(contentError) && (
                            <div className="invalid-feedback">
                              {t(contentError)}
                            </div>
                          )}
                          <div className="m-t-10" style={{ lineHeight: "28px" }}>
                            <b>Tags to be used for dynamic value, </b>
                            <span className="label label-blue f-s-12 m-2">
                              &#123;USERNAME&#125;
                            </span>
                            <span className="label label-blue f-s-12 m-2">
                              &#123;PASSWORD&#125;
                            </span>
                            <span className="label label-blue f-s-12 m-2">
                              &#123;LINK&#125;
                            </span>
                          </div>
                          <div className="m-t-10" style={{ lineHeight: "28px" }}>
                            <b>May get emoji cheat sheet with this link, </b>
                            <a href="https://www.webfx.com/tools/emoji-cheat-sheet/" target="_blank">Emoji Cheat Sheet</a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group row m-b-15">
                        <label className="col-md-4 col-form-label">
                          <b>{t("STATUS")}</b>
                        </label>
                        <div className="col-md-7">
                          <div className="switcher">
                            <input
                              type="checkbox"
                              name="status1"
                              id="status1"
                              onChange={(e) => setStatus1(e.target.checked)}
                              value={true}
                              checked={status1}
                              ref={register}
                            />
                            <label htmlFor="status1"></label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) :
              (
                <>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group row m-b-15">
                        <label className="col-md-4 col-form-label">
                          <b>{t("LANGUAGE")}</b>
                        </label>
                        <div className="col-md-7">
                          <Select
                            name="languageId"
                            options={languageOption}
                            placeholder={
                              languageOption.filter(
                                (option) => option.value == languageId
                              )[0] !== undefined
                                ? languageOption.filter(
                                  (option) =>
                                    option.value == languageId
                                )[0].label
                                : ""
                            }
                            value={languageOption.filter(
                              (option) => option.value == languageId
                            )}
                            onChange={(e) => {
                              setLanguageId(e.value);
                              setValue("languageId", e.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group row m-b-15">
                        <label className="col-md-4 col-form-label">
                          <b>{t("CONTENT")}</b>
                        </label>
                        <div className="col-md-7">
                          <textarea
                            className="form-control"
                            rows="5"
                            style={{ marginTop: "10px" }}
                            ref={register}
                            name="content1"
                            defaultValue={content1}
                            onChange={(e) => {
                              setContent1(e.target.value);
                              setValue("content1", e.target.value);
                            }}
                          ></textarea>
                          {errors.content1 && (
                            <div className="invalid-feedback">
                              {t(errors.content1.message)}
                            </div>
                          )}
                          {!stringIsNullOrEmpty(contentError) && (
                            <div className="invalid-feedback">
                              {t(contentError)}
                            </div>
                          )}
                          <div className="m-t-10" style={{ lineHeight: "28px" }}>
                            <b>Tags to be used for dynamic value, </b>
                            <span className="label label-blue f-s-12 m-2">
                              &#123;USERNAME&#125;
                            </span>
                            <span className="label label-blue f-s-12 m-2">
                              &#123;PASSWORD&#125;
                            </span>
                            <span className="label label-blue f-s-12 m-2">
                              &#123;LINK&#125;
                            </span>
                          </div>
                          <div className="m-t-10" style={{ lineHeight: "28px" }}>
                            <b>May get emoji cheat sheet with this link, </b>
                            <a href="https://www.webfx.com/tools/emoji-cheat-sheet/" target="_blank">Emoji Cheat Sheet</a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group row m-b-15">
                        <label className="col-md-4 col-form-label">
                          <b>{t("STATUS")}</b>
                        </label>
                        <div className="col-md-7">
                          <div className="switcher">
                            <input
                              type="checkbox"
                              name="status1"
                              id="status1"
                              onChange={(e) => setStatus1(e.target.checked)}
                              value={true}
                              checked={status1}
                              ref={register}
                            />
                            <label htmlFor="status1"></label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )
            }

          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-white"
              onClick={() => {
                setIsOpen(false);
                setDataContentId("");
                setLanguageId("");
                setContentLanguageId("");
                setContent1("");
                setStatus1(false);
              }}

            >
              {t("CLOSE")}
            </button>
            <button type="submit" className="btn btn-primary">
              {t("SUBMIT")}
            </button>
          </ModalFooter>
        </form>
      </Modal>

    </div>

  );
};

export default ChatShpereDetailLanguage;
