import React, { useState, useEffect } from "react";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import { useForm } from "react-hook-form";
import { createFormBody } from "../../util/Util";
import { ApiKey, ApiUrl, Role, LanguageKey } from "../../util/Constant";
import { setBusy, setIdle, showMessage } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";

require("codemirror/mode/xml/xml.js");
require("codemirror/mode/javascript/javascript.js");

/// <summary>
/// Author : -
/// </summary>
const SuperAccount = (props) => {
  const { t } = useTranslation(LanguageKey._PRIMARY);
  const _dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [fullName, setFullName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [userId, setUserId] = useState("");
  const [timeZone, setTimeZone] = useState();

    var _TIMEZONE_OPTION = [
        { label: "(GMT-11:00) Pago Pago", value: -11 },
        { label: "(GMT-10:00) Pacific/Honolulu", value: -10 },
        { label: "(GMT-08:00) America/Los_Angeles", value: -8 },
        { label: "(GMT-07:00) America/Denver", value: -7 },
        { label: "(GMT-06:00) America/Chicago", value: -6 },
        { label: "(GMT-05:00) America/New_York", value: -5 },
        { label: "(GMT-04:00) America/Guyana", value: -4 },
        { label: "(GMT-03:00) America/Santiago", value: -3 },
        { label: "(GMT-02:00) America/Sao_Paulo", value: -2 },
        { label: "(GMT-01:00) Atlantic/Azores", value: -1 },
        { label: "(GMT+00:00) Europe/London", value: 0 },
        { label: "(GMT+01:00) Europe/Berlin", value: 1 },
        { label: "(GMT+02:00) Europe/Athens", value: 2 },
        { label: "(GMT+03:00) Europe/Istanbul", value: 3 },
        { label: "(GMT+04:00) Europe/Samara", value: 4 },
        { label: "(GMT+05:00) Asia/Karachi", value: 5 },
        { label: "(GMT+06:00) Asia/Dhaka", value: 6 },
        { label: "(GMT+07:00) Asia/Bangkok", value: 7 },
        { label: "(GMT+08:00) Asia/Singapore", value: 8 },
        { label: "(GMT+09:00) Asia/Seoul", value: 9 },
        { label: "(GMT+10:00) Asia/Magadan", value: 10 },
        { label: "(GMT+11:00) Australia/Sydney", value: 11 },
        { label: "(GMT+12:00) Pacific/Majuro", value: 12 },
        { label: "(GMT+13:00) Pacific/Auckland", value: 13 },
        { label: "(GMT+14:00) Pacific/Apia", value: 14 },
      ];

  /// <summary>
  /// Author : -
  /// </summary>
  const submitForm = async (data, e) => {
    if (data.password != data.confirmPassword) {
      _dispatch(
        showMessage(false, t("CONFIRM_PASSWORD_AND_PASSWORD_IS_NOT_TALLY"))
      );
      return;
    }

    let params = {
      username: data.username,
      password: data.password,
      email: data.email,
      phone: data.phone,
      fullName: data.fullName,
      //roleId: 1,
      //role: data.fullName,
      id: userId,
      timeZone: timeZone
    };

    let formBody = createFormBody(params);
    _dispatch(setBusy());

    fetch(ApiUrl._API_CREATE_OR_UPDATE_ACCOUNT, {
      method: ApiKey._API_POST,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
      body: formBody,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        _dispatch(setIdle());
        _dispatch(
          showMessage(
            responseJson[ApiKey._API_SUCCESS_KEY],
            t(responseJson[ApiKey._API_MESSAGE_KEY])
          )
        );
      });
  };

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    fetch(ApiUrl._API_GET_SUPER_COMPANY, {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          setUsername(responseJson[ApiKey._API_DATA_KEY]["username"]);
          setPhone(responseJson[ApiKey._API_DATA_KEY]["phoneNumber"]);
          setFullName(responseJson[ApiKey._API_DATA_KEY]["userFullName"]);
          setPassword(responseJson[ApiKey._API_DATA_KEY]["password"]);
          setConfirmPassword(responseJson[ApiKey._API_DATA_KEY]["password"]);
          setEmail(responseJson[ApiKey._API_DATA_KEY]["email"]);
          setUserId(responseJson[ApiKey._API_DATA_KEY]["id"]);
          setTimeZone(responseJson[ApiKey._API_DATA_KEY]["timeZone"]);
        }
      });
  }, []);

  return (
    <div>
      <h1 className="page-header">{t("MANAGE_SUPER_COMPANY")}</h1>
      <div className="row">
        <div className="col-xl-12">
          <Panel>
            <PanelBody>
              <form onSubmit={handleSubmit(submitForm)}>
                <div className="row">
                  <div className="col-lg-3">
                    <div className="form-group">
                      <label>
                        <b>{t("USERNAME")} *</b>
                      </label>
                      <input
                        type="text"
                        ref={register({ required: "PLEASE_ENTER_USERNAME" })}
                        name="username"
                        className="form-control m-b-5"
                        placeholder={t("PLEASE_ENTER_USERNAME")}
                        defaultValue={username}
                      />
                      {errors.username && (
                        <div className="invalid-feedback">
                          {t(errors.username.message)}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="form-group">
                      <label>
                        <b>{t("PASSWORD")} *</b>
                      </label>
                      <input
                        type="password"
                        ref={register({
                          required: "PLEASE_ENTER_PASSWORD",
                          minLength: {
                            value: 6,
                            message: "MINIMUM_6_DIGIT_PASSWORD",
                          },
                        })}
                        name="password"
                        className="form-control m-b-5"
                        placeholder={t("PLEASE_ENTER_PASSWORD")}
                        defaultValue={password}
                      />
                      {errors.password && (
                        <div className="invalid-feedback">
                          {t(errors.password.message)}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="form-group">
                      <label>
                        <b>{t("CONFIRM_PASSWORD")} *</b>
                      </label>
                      <input
                        type="password"
                        name="confirmPassword"
                        ref={register({
                          required: "PLEASE_ENTER_CONFIRM_PASSWORD",
                          minLength: {
                            value: 6,
                            message: "MINIMUM_6_DIGIT_PASSWORD",
                          },
                        })}
                        className="form-control m-b-5"
                        defaultValue={confirmPassword}
                        placeholder={t("PLEASE_ENTER_CONFIRM_PASSWORD")}
                      />
                      {errors.confirmPassword && (
                        <div className="invalid-feedback">
                          {t(errors.confirmPassword.message)}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-3"></div>
                  <div className="col-lg-3">
                    <div className="form-group">
                      <label>
                        <b>{t("FULLNAME")} *</b>
                      </label>
                      <input
                        type="text"
                        name="fullName"
                        ref={register({ required: "PLEASE_ENTER_FULL_NAME" })}
                        className="form-control m-b-5"
                        defaultValue={fullName}
                        placeholder={t("PLEASE_ENTER_FULL_NAME")}
                      />
                      {errors.fullName && (
                        <div className="invalid-feedback">
                          {t(errors.fullName.message)}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="form-group">
                      <label>
                        <b>{t("MOBILE")} *</b>
                      </label>
                      <input
                        type="text"
                        name="phone"
                        ref={register({
                          required: "PLEASE_ENTER_PHONE",
                          maxLength: {
                            value: 11,
                            message: "MAXIMUM_11_DIGIT_PHONE_NUMBER",
                          },
                          pattern: {
                            value: /^(\d{10}|\d{12})$/,
                            message: "INVALID_PHONE_FORMAT",
                          },
                          minLength: {
                            value: 10,
                            message: "MINIMUM_10_DIGIT_PHONE_NUMBER",
                          },
                        })}
                        defaultValue={phone}
                        className="form-control"
                      />
                      {errors.phone && (
                        <div className="invalid-feedback">
                          {t(errors.phone.message)}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="form-group">
                      <label>
                        <b>{t("EMAIL")}</b>
                      </label>
                      <input
                        type="text"
                        name="email"
                        ref={register({
                          required: "PLEASE_ENTER_EMAIL",
                          pattern: {
                            value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                            message: "INVALID_EMAIL",
                          },
                        })}
                        className="form-control m-b-5"
                        defaultValue={email}
                        placeholder={t("PLEASE_ENTER_EMAIL")}
                      />
                      {errors.email && (
                        <div className="invalid-feedback">
                          {t(errors.email.message)}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="form-group">
                      <label>
                        <b>{t("TIME_ZONE")}</b>
                      </label>
                      <Select
                        name="timeZone"
                        options={_TIMEZONE_OPTION}
                        placeholder={
                          _TIMEZONE_OPTION.filter(
                            (option) => option.value == timeZone
                          )[0] !== undefined
                            ? _TIMEZONE_OPTION.filter(
                                (option) => option.value == timeZone
                              )[0].label
                            : ""
                        }
                        value={_TIMEZONE_OPTION.filter(
                          (option) => option.value == timeZone
                        )}
                        onChange={(e) => {
                          setTimeZone(e.value);
                        }}
                      />
                      {errors.timeZone && (
                        <div className="invalid-feedback">
                          {t(errors.timeZone.message)}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <button type="submit" className="btn btn-primary">
                        {t("SUBMIT")}
                      </button>
                    </div>
                  </div>
                </div>
                <input
                  type="hidden"
                  name="role"
                  className="form-control m-b-5"
                  value={Role._SUPER_COMPANY}
                />
              </form>
            </PanelBody>
          </Panel>
        </div>
      </div>
    </div>
  );
};

export default SuperAccount;
