import React, { useState, useEffect } from "react";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import "../../assets/css/custom.css";
import { useHistory } from "react-router-dom";
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { navigateTo, stringIsNullOrEmpty } from "../../util/Util";
import {
  WebUrl,
  ApiKey,
  ApiUrl,
  AccessRight,
  LanguageKey,
  AllVipOption,
} from "../../util/Constant";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import { checkIfPermissionExist } from "../../util/PermissionChecker";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";
import { useDispatch } from "react-redux";
import { showMessage } from "../../redux/AppAction";
import SweetAlert from "react-bootstrap-sweetalert";

/// <summary>
/// Author : -
/// </summary>

const ManageBanner = (props) => {
  const { t } = useTranslation(LanguageKey._PRIMARY);
  let _history = useHistory();
  const _dispatch = useDispatch();
  const [bannerData, setBannerData] = useState([]);
  const [showConfirmDeleteBanner, setShowConfirmDeleteBanner] = useState(false);
  const [selectedBannerIdToDelete, setSelectedBannerIdToDelete] = useState(0);

  let _tableColumns = [
    {
      Header: "TITLE",
      accessor: "title",
      disableSortBy: true,
    },
    {
      Header: "VIP",
      accessor: "vipId",
      Cell: ({ row }) => {
        if (stringIsNullOrEmpty(row.original.vipId)) {
          return <>{AllVipOption._LABEL}</>;
        } else {
          return <>{row.original.vipModel.name}</>;
        }
      },
    },
    {
      Header: "BANNER_LOCATION",
      accessor: "displayDeviceString",
    },
    {
      Header: "STATUS",
      accessor: "status",
      Cell: ({ row }) => (
        <div className="btn-group m-r-5 m-b-5">
          {row.original.status == 1 ? (
            <span className="badge badge-secondary badge-green">
              {t("ACTIVE")}
            </span>
          ) : (
            <span className="badge badge-secondary badge-danger">
              {t("SUSPENDED")}
            </span>
          )}
        </div>
      ),
      disableSortBy: true,
      style: { overflow: "visible" },
    },
    {
      id: "action",
      Header: "",
      Cell: ({ row }) =>
        checkIfPermissionExist(
          AccessRight._BANNER_SETTING_PERMISSION,
          true
        ) && (
          <div className="btn-group m-r-5 m-b-5">
            <UncontrolledButtonDropdown>
              <DropdownToggle caret color="default">
                <i className="fas fa-cog"></i>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={() => {
                    navigateTo(
                      _history,
                      WebUrl._URL_CREATE_OR_UPDATE_BANNER,
                      row.original
                    );
                  }}
                >
                  <span className="text-warning">{t("EDIT")}</span>
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    navigateTo(_history, WebUrl._URL_CREATE_OR_UPDATE_BANNER, {
                      refId: row.original.id,
                    });
                  }}
                >
                  <span className="text-primary">{t("CLONE")}</span>
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    setShowConfirmDeleteBanner(true);
                    setSelectedBannerIdToDelete(row.original.id);
                  }}
                >
                  <span className="text-danger">{t("DELETE")}</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        ),
      disableSortBy: true,
      disableFilters: true,
      width: 100,
      style: { overflow: "visible" },
    },
  ];

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    fetch(ApiUrl._API_GET_USER_BANNER + "?all=true&isCompanyUser=true", {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          setBannerData(responseJson[ApiKey._API_DATA_KEY]);
        }
      });
  }

  const deleteBanner = async () => {
    await fetch(
      ApiUrl._API_REMOVE_BANNER_BY_ID + "?bannerId=" + selectedBannerIdToDelete,
      {
        method: ApiKey._API_GET,
        headers: {
          "Content-Type": ApiKey._API_FORM_URLENCODED,
          "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
        },
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          init();
          _dispatch(showMessage(true, t(responseJson[ApiKey._API_MESSAGE_KEY])));
        }
        else {
          _dispatch(showMessage(false, t("DELETE_FAILED")));
        }
      });
  }

  return (
    <div>
      <h1 className="page-header">
        {t("BANNERS")}
        {checkIfPermissionExist(
          AccessRight._BANNER_SETTING_PERMISSION,
          true
        ) && (
          <NavigationButton
            history={_history}
            url={WebUrl._URL_CREATE_OR_UPDATE_BANNER}
            buttonText={t("ADD_BANNER")}
          />
        )}
      </h1>
      <Panel>
        <PanelBody>
          <ReactTable data={bannerData} columns={_tableColumns} />
        </PanelBody>
      </Panel>
      {showConfirmDeleteBanner && (
        <SweetAlert
          warning
          showCancel
          confirmBtnText={t("CONFIRM")}
          cancelBtnText={t("CANCEL")}
          confirmBtnBsStyle="danger"
          title={t("CONFIRM") + " " + t("DELETE") + "?"}
          onConfirm={() => {
            setShowConfirmDeleteBanner(false);
            deleteBanner();
          }}
          onCancel={() => {
            setShowConfirmDeleteBanner(false);
          }}
        ></SweetAlert>
      )}
    </div>
  );
};

export default ManageBanner;
