import React, { useState, useEffect } from "react";
import moment from "moment";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import { BetStatus, LanguageKey, ApiKey, ApiUrl, ReadWritePermission, AccessRight } from "../../util/Constant";
import { useTranslation } from "react-i18next";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { isObjectEmpty, numberWithCurrencyFormat, numberWithCurrencyFormat2 } from "../../util/Util";
import ApiEngine from "../../util/ApiEngine";
import { useDispatch, useSelector } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import { showMessage } from "../../redux/AppAction";
import { checkIfPermissionExist } from "../../util/PermissionChecker";

/// <summary>
/// Author : -
/// </summary>
const BetDetailPanel = (props) => {
    const _dispatch = useDispatch();
    const { t } = useTranslation(LanguageKey._PRIMARY);
    const [filteredDatas, setFilteredDatas] = useState([]);
    const [targetDetails, setTargetDetails] = useState({});
    const [showGameRecord, setShowGameRecord] = useState(false);
    const [showCancelLog, setShowCancelLog] = useState(false);
    const [showPayload, setShowPayload] = useState(false);
    const [showApiload, setShowApiload] = useState(false);
    const [cancelPayload, setCancelPayload] = useState();
    const [payLoad, setPayload] = useState("");
    const [refNo, setRefNo] = useState("");
    const [username, setUsername] = useState("");
    const [apiLog, setApiLog] = useState([]);
    const [overallInfo, setOverallInfo] = useState({});
    const [selectedPgrIdToCancel, setSelectedPgrIdToCancel] = useState(0);
    const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
    const [showReactTable, setShowReactTable] = useState(true);
    let role1IsAllowed = checkIfPermissionExist(
          AccessRight._MANUAL_CANCEL_BET_PERMISSION,
          true
        );
    const _OVERALL_KEYS = {
        AMOUNT: "TOTAL_AMOUNT",
        WIN: "TOTAL_WIN",
    };

    const _defaultTableColumns = [

        {
            Header: t("ID"),
            accessor: "id",
        },
        {
            Header: "Description",
            Cell: ({ row }) => {
                return (
                    <div>
                        <span
                            className="text-clickable"
                            onClick={() => {
                                viewGameRecord(row.original);
                            }}
                        >
                            {" "}
                            {row.original.betId}
                        </span><br />
                        <span
                            className="btn btn-default text-clickable"
                            onClick={() => {
                                viewPayload(row.original);
                            }}
                        >
                            CheckPayload
                        </span>
                    </div>
                );
            },
            Footer: () => {
                return (
                    <span>
                        <b>{t("GRAND TOTAL")}: </b>
                    </span>
                );
            },
            Footer: () => {
                return (
                    <span>
                        <b>{t("GRAND TOTAL")}: </b>
                    </span>
                );
            },
        },
        {
            Header: t("PRODUCT_NAME"),
            accessor: "productName",
        },
        {
            Header: t("CREATED_TIME"),
            accessor: "date",
            minWidth: 100,
        },
        {
            Header: t("UPDATED_TIME"),
            accessor: "updatedTime",
            minWidth: 100,
        },
        {
            Header: t("USERNAME"),
            accessor: "memberUsername",
            minWidth: 100,
        },
        {
            Header: t("PLAYERID"),
            accessor: "username",
            minWidth: 100,
        },
        {
            Header: t("GAME_NAME"),
            accessor: "gameName",
            minWidth: 100,
        },
        {
            Header: t("STATUS"),
            accessor: "statusString",
            minWidth: 100,
            Cell: ({ row }) => {
              return (<>
                <div className="display-flex flex-column">
                  <span>{row.original.statusString}</span>
{/*                  {row.original.statusString === "Pending" && role1IsAllowed && <button onClick={() => handleCancelBet(row)}>{t("CANCEL")}</button>}*/}
                </div>
              </>)
            }
        },
        {
            Header: t("BET_AMOUNT"),
            accessor: "amount",
            minWidth: 100,
            Cell: ({ row }) => {
                return <div className="text-align-right">{numberWithCurrencyFormat2(row.original.amount.toFixed(2), 2, true)}</div>;
            },
            Footer: () => {
                let totalAmount = 0;
                if (!isObjectEmpty(overallInfo) && overallInfo[_OVERALL_KEYS.AMOUNT]) {
                    totalAmount = parseFloat(overallInfo[_OVERALL_KEYS.AMOUNT]);
                }
                return (
                    <div className="text-align-right">
                        <b>{numberWithCurrencyFormat2(totalAmount, 3, true)}</b>
                    </div>
                );
            },
        },
        {
            Header: t("WIN_LOSS"),
            accessor: "winAmount",
            minWidth: 100,
            Cell: ({ row }) => {
                let winLoss =
                    parseFloat(row.original.winAmount) - parseFloat(row.original.amount);
                if (row.original.status == BetStatus._SETTLED_BET_STATUS) {
                    return (
                        <div className="text-align-right" style={{ color: winLoss < 0 ? "red" : "" }}>
                            {numberWithCurrencyFormat(winLoss)}
                        </div>
                    );
                } else {
                    return <>0</>;
                }
            },
            Footer: () => {
                let winLossTotal = 0;
                if (
                    !isObjectEmpty(overallInfo) &&
                    overallInfo[_OVERALL_KEYS.WIN] &&
                    overallInfo[_OVERALL_KEYS.AMOUNT]
                ) {
                    winLossTotal =
                        parseFloat(overallInfo[_OVERALL_KEYS.WIN]) -
                        parseFloat(overallInfo[_OVERALL_KEYS.AMOUNT]);
                }
                return (
                    <div className="text-align-right" style={{ color: winLossTotal < 0 ? "red" : "" }}>
                        <b>{numberWithCurrencyFormat(winLossTotal)}</b>
                    </div>
                );
            },
        },
        {
            Header: t("REMARK"),
            accessor: "remark",
            minWidth: 100,
        },
        {
            Header: t("Jpc"),
            accessor: "jpc",
            id: "jpc",
            minWidth: 100,
            Cell: ({ row }) => {
                return (
                    <div className="text-align-right" style={{ color: row.original.jpc < 0 ? "red" : "" }}>{numberWithCurrencyFormat2(parseFloat(row.original.jpc), 3, true)}</div>
                );
            },
            Footer: ({ row, column }) => {
                let jpcSum = 0;
                column.filteredRows.map((dataRow, index) => {
                    jpcSum += parseFloat(dataRow.original.jpc);
                });
                return (
                    <div className="text-align-right" style={{ color: jpcSum < 0 ? "red" : "" }}><b>{numberWithCurrencyFormat2(parseFloat(jpcSum), 3, true)}</b></div>
                );
            },
        },
        {
            Header: t("Jpw"),
            accessor: "jpw",
            id: "jpw",
            minWidth: 100,
            Cell: ({ row }) => {
                return (
                    <div className="text-align-right" style={{ color: row.original.jpw < 0 ? "red" : "" }}>{numberWithCurrencyFormat2(parseFloat(row.original.jpw))}</div>
                );
            },
            Footer: ({ row, column }) => {
                let jpwSum = 0;
                column.filteredRows.map((dataRow, index) => {
                    jpwSum += parseFloat(dataRow.original.jpw);
                });
                return (
                    <div className="text-align-right" style={{ color: jpwSum < 0 ? "red" : "" }}><b>{numberWithCurrencyFormat2(parseFloat(jpwSum), 3, true)}</b></div>
                );
            },
        },
    ];

    useEffect(() => {
    }, [props.fetchUrl]);

    function handleCancelBet(rowData) {
        setSelectedPgrIdToCancel(rowData.original.id);
        setShowCancelConfirmation(true);
    }

    async function cancelSelectedBet() {
        let responseJson = await ApiEngine.get(ApiUrl._API_MANUAL_CANCEL_BET + "?id=" + selectedPgrIdToCancel);
        if (responseJson[ApiKey._API_SUCCESS_KEY]){
            _dispatch(
                showMessage(
                    responseJson[ApiKey._API_SUCCESS_KEY],
                    t(responseJson[ApiKey._API_MESSAGE_KEY])
                )
            );
            setShowReactTable(false);
            setTimeout(() => setShowReactTable(true), 1);
        }
    }

    function viewGameRecord(item) {
        let apiUrl = ApiUrl._API_GET_PRODUCT_GAME_PAYLOAD + "?betId=" + item.betId;
        fetch(apiUrl, {
            method: ApiKey._API_POST,
            headers: {
                'Content-Type': ApiKey._API_FORM_URLENCODED,
                "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY
            },
            //body: formBody
        }).then((response) => response.json()).then((responseJson) => {
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                if (responseJson[ApiKey._API_DATA_KEY] != null && responseJson[ApiKey._API_DATA_KEY]['url'] != null) {
                    if (responseJson[ApiKey._API_DATA_KEY]['url'].substring(0, 4) !== "http" && responseJson[ApiKey._API_DATA_KEY]['url'].substring(0, 2) !== "//") {
                        var win = window.open("", "Title", "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=780,height=200,top=200,left=200");
                        win.document.body.innerHTML = responseJson[ApiKey._API_DATA_KEY]['url'];
                        //<XEPayload data={data} />
                    }
                    else {
                        window.open(responseJson[ApiKey._API_DATA_KEY]['url']);
                    }
                }
                else if (responseJson[ApiKey._API_DATA_KEY] != null && responseJson[ApiKey._API_DATA_KEY]['xml'] != null) {
                    setShowPayload(true);
                    var obj = JSON.parse(responseJson[ApiKey._API_DATA_KEY]['xml'].replaceAll(",", ",\n"));

                    setPayload(JSON.stringify(obj, null, 4)); 
                }
                else if (responseJson[ApiKey._API_DATA_KEY] != null && responseJson[ApiKey._API_DATA_KEY]['data'] != null && responseJson[ApiKey._API_DATA_KEY]['data']['record_type'] == "Html") {
                    var htmlWin = window.open("");
                    try {
                        htmlWin.document.write(responseJson[ApiKey._API_DATA_KEY]['data']['record']);
                    } catch (e) {
                        alert("Pop-up Blocker is enabled! Please add this site to your exception list.");
                    }
                }
                else if (responseJson[ApiKey._API_DATA_KEY] != null && responseJson[ApiKey._API_DATA_KEY]['data'] != null && responseJson[ApiKey._API_DATA_KEY]['data']['record_type'] == "URL") {
                    window.open(responseJson[ApiKey._API_DATA_KEY]['data']['record']);
                }
                else {
                    setTargetDetails(item);
                    setShowGameRecord(true);
                }
            }
        }, []);
        /*setTargetDetails(item);
        setShowGameRecord(true);*/
    }
    function viewPayload(item) {
      let apiUrl = ApiUrl._API_GET_BET_RESULT + "?betId=" + item.betId;
      fetch(apiUrl, {
        method: ApiKey._API_GET,
        headers: {
          'Content-Type': ApiKey._API_FORM_URLENCODED,
          "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY
        },
        //body: formBody
      }).then((response) => response.json()).then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          if (responseJson[ApiKey._API_DATA_KEY] != null) {
            let payload = responseJson[ApiKey._API_DATA_KEY].replaceAll(",\"", ",\n");
            setPayload(payload);
            setShowPayload(true);
          }
          else {
            setTargetDetails(item);
            setShowGameRecord(true);
          }

        }
      }, []);
      /*setTargetDetails(item);
      setShowGameRecord(true);*/
    }

    function viewApiLog(item) {
        let apiUrl = ApiUrl._API_GET_API_LOG + "?betId=" + item.betId;
        fetch(apiUrl, {
            method: ApiKey._API_GET,
            headers: {
                'Content-Type': ApiKey._API_FORM_URLENCODED,
                "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY
            },
            //body: formBody
        }).then((response) => response.json()).then((responseJson) => {
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                if (responseJson[ApiKey._API_DATA_KEY] != null) {
                    setApiLog(responseJson[ApiKey._API_DATA_KEY]);
                    responseJson[ApiKey._API_DATA_KEY].map((log, index) => {
                        setUsername(log.username)
                        setRefNo(log.refNo)
                    })
                    setShowApiload(true);
                }
                else {
                    setTargetDetails(item);
                    setShowApiload(true);
                }

            }
        }, []);
        /*setTargetDetail,s(item);
        setShowGameRecord(true);*/
    }
/*
    function viewCancelLog(item) {
        let apiUrl = ApiUrl._API_GET_CANCEL_LOG + "?betId=" + item.betId;
        fetch(apiUrl, {
            method: ApiKey._API_GET,
            headers: {
                'Content-Type': ApiKey._API_FORM_URLENCODED,
                "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY
            },
            //body: formBody
        }).then((response) => response.json()).then((responseJson) => {
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                if (responseJson[ApiKey._API_DATA_KEY] != null) {
                    let data = responseJson[ApiKey._API_DATA_KEY];
                    setCancelPayload(data);
                    setShowCancelLog(true);
                }
                else {
                    setTargetDetails(item);
                    setShowGameRecord(true);
                }

            }
        }, []);
    }*/

    /// <summary>
    /// Author : -
    /// </summary>
    function getOverallInfo(info) {
        setOverallInfo(info);
    }

    function copyCancelPayload() {
        navigator.clipboard.writeText(cancelPayload);
    }
    function copyPayload() {
      navigator.clipboard.writeText(payLoad);
    }

    return (
        <div>
            {showReactTable && <ReactTable
                fetchUrl={props.fetchUrl}
                columns={_defaultTableColumns}
                getOverallInfo={getOverallInfo}
                renderFooter={true}
            />}
            {showCancelConfirmation && (
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText={t("CONFIRM")}
                    cancelBtnText={t("CANCEL")}
                    confirmBtnBsStyle="danger"
                    title={t("CONFIRM") + "?"}
                    onConfirm={() => {
                      setShowCancelConfirmation(false);
                      cancelSelectedBet();
                    }}
                    onCancel={() => {
                      setShowCancelConfirmation(false);
                    }}
                ></SweetAlert>
            )}
            <Modal
                isOpen={showPayload}
                toggle={() => setShowGameRecord(false)}
            >
                <ModalBody>
                    <pre>
                        {payLoad}
                    </pre>
                </ModalBody>

                <ModalFooter>
                    <button
                        type="button"
                        className="btn btn btn-inverse-brand"
                        onClick={() => copyPayload()}
                    >
                        {t("COPY")}
                    </button>
                    <button
                        type="button"
                        className="btn btn btn-inverse-brand"
                        onClick={() => setShowPayload(false)}
                    >
                        {t("CLOSE")}
                    </button>
                </ModalFooter>
            </Modal>
            <Modal
                isOpen={showCancelLog}
                toggle={() => setShowCancelLog(false)}
            >
                <ModalBody>
                    <pre>
                        {cancelPayload && cancelPayload}
                    </pre>

                </ModalBody>

                <ModalFooter>
                    <button
                        type="button"
                        className="btn btn btn-inverse-brand"
                        onClick={() => copyCancelPayload()}
                    >
                        {t("COPY")}
                    </button>
                    <button
                        type="button"
                        className="btn btn btn-inverse-brand"
                        onClick={() => setShowCancelLog(false)}
                    >
                        {t("CLOSE")}
                    </button>
                </ModalFooter>
            </Modal>
            <Modal
                isOpen={showApiload}
                toggle={() => setShowGameRecord(false)}
            >
                <ModalBody>
                    <pre>
                        <div>RefNo:{refNo}<br />PlayerId:{username}
                            {apiLog.map((log, index) => {
                                return (<>
                                    <div>ClassTag:{log['classTag']} - {log['date']} GMT+0<br />Req: {log['request']}<br />Res: {log['response']}
                                    </div>
                                    <div><br /></div>
                                </>)
                            })}
                        </div>
                    </pre>
                </ModalBody>

                <ModalFooter>
                    <button
                        type="button"
                        className="btn btn btn-inverse-brand"
                        onClick={() => setShowApiload(false)}
                    >
                        {t("CLOSE")}
                    </button>
                </ModalFooter>
            </Modal>

            <Modal
                isOpen={showGameRecord}
                toggle={() => setShowGameRecord(false)}
                style={{ paddingBottom: "100px" }}
                className="modal-lg"
                keyboard={true}
            >
                <ModalHeader toggle={() => setShowGameRecord(false)}>
                    {t("SUMMARY")}
                </ModalHeader>
                <ModalBody>
                    <div className="table-responsive">
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>{t("DATE")}</th>
                                    <th>{t("DESCRIPTION")}</th>
                                    <th>{t("SETTLED_ON")}</th>
                                    <th>{t("USERNAME")}</th>
                                    <th>{t("PLAYERID")}</th>
                                    <th>{t("GAME")}</th>
                                    <th>{t("REMARK")}</th>
                                    <th>{t("AMOUNT")}</th>
                                    <th>{t("STATUS")}</th>
                                    <th>{t("STRIKE")}</th>
                                    <th>{t("NET_PROFIT")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{targetDetails["date"]}</td>
                                    <td>{targetDetails["betId"]}</td>
                                    <td>{targetDetails["updatedTime"]}</td>
                                    <td>{targetDetails["memberUsername"]}</td>
                                    <td>{targetDetails["username"]}</td>
                                    <td>{targetDetails["gameName"]}</td>
                                    <td>
                                        <span style={{ whiteSpace: "pre-line" }}>
                                            {targetDetails["remark"]}
                                        </span>
                                    </td>
                                    <td>
                                        {numberWithCurrencyFormat(
                                            parseFloat(targetDetails["amount"])
                                        )}
                                    </td>
                                    <td>{targetDetails["statusString"]}</td>
                                    <td>{targetDetails["winAmount"]}</td>
                                    <td>
                                        {numberWithCurrencyFormat(
                                            parseFloat(targetDetails["winAmount"]) -
                                            parseFloat(targetDetails["amount"])
                                        )}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button
                        type="button"
                        className="btn btn btn-inverse-brand"
                        onClick={() => setShowGameRecord(false)}
                    >
                        {t("CLOSE")}
                    </button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default BetDetailPanel;