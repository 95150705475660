import React, { useState, useEffect } from "react";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import "../../assets/css/custom.css";
import Select from "react-select";
import { useForm } from "react-hook-form";
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
import { createFormBody, navigateTo, stringIsNullOrEmpty } from "../../util/Util";
import {
  ApiUrl,
  ApiKey,
  ContentTypeString,
  LanguageKey,
} from "../../util/Constant";
import { useTranslation } from "react-i18next";
import ConfigurationLanguageDetail from "./ConfigurationLanguageDetail";
import { fetch } from "whatwg-fetch";
import ApiEngine from "../../util/ApiEngine";
import { useDispatch } from "react-redux";
import { showMessage, setSaveAdminLog } from "../../redux/AppAction";
import { useHistory } from "react-router-dom";

/// <summary>
/// Author : -
/// </summary>
const SeoMetaContentLanguage = (props) => {
  const { handleSubmit, errors, register, content, setValue, clearErrors } = useForm();
  const { t } = useTranslation(LanguageKey._PRIMARY);
  const [data, setData] = useState([]);
  const _dispatch = useDispatch();
  let _history = useHistory();
  const [
    languageDetailModalVisibility,
    setLanguageDetailModalVisibility,
  ] = useState(false);

  const _EXCLUDE_TITLE_TYPE = [
    ContentTypeString._VIP_BENEFIT_CONTENT,
    ContentTypeString._VIP_CRITERIA,
  ];
  const _EXCLUDE_CONTENT_TYPE = [
    ContentTypeString._VIP_BENEFIT,
    ContentTypeString._PRODUCT_CATEGORY,
  ];
  const [isOpen, setIsOpen] = useState(false);
  const [languageDetailId, setLanguageDetailId] = useState("");
  const [tableColumns, setTableColumns] = useState([]);
  const [languageOption, setLanguageOption] = useState([]);
  const [languageId, setLanguageId] = useState("");
  const [seodataContentId, setSeodataContentId] = useState("");
  const [seoContentData, setSeoContentData] = useState([]);
  const [isCreated, setIsCreated] = useState(0);
  const META_COUNT = 10;
  const [apiData, setApiData] = useState([]);
  const [editApiData, setEditApiData] = useState([]);
  const [seoMetaContentLabel, setSeoMetaContentLabel] = useState("");
  const [noOfRows, setNoOfRows] = useState(1);

  let _tableColumns = [
    {
      Header: "LANGUAGE",
      accessor: "languageName",
      disableSortBy: true,
    },
    {
      Header: "CONTENT",
      accessor: "content",
      disableSortBy: true,
    },
    {
      id: "action",
      Header: "",
      Cell: ({ row }) => (
        <div className="btn-group m-r-5 m-b-5">
          <UncontrolledButtonDropdown>
            <DropdownToggle caret color="default">
              <i className="fas fa-cog"></i>
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                onClick={() => {
                  editDepositTutorialLangauge(row.original);

                }}
              >
                <span className="text-warning">{t("EDIT")}</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </div>
      ),
      disableSortBy: true,
      disableFilters: true,
      width: 100,
      style: { overflow: "visible" },
    },
  ];

  async function editDepositTutorialLangauge(data) {
    
    setIsCreated(2);
    setIsOpen(true);
    setSeodataContentId(data.id);
    setLanguageId(data.languageId);
    var content = JSON.parse(data["content"]);
    var contentLength = Object.keys(content).length;
    setNoOfRows(contentLength);
    var newData = [];
    for (var x = 0; x < contentLength; x++) {
      var jsonObj = JSON.parse(content["parameter_" + (x + 1)]);

      newData[x] = {
        name: (x + 1),
        metaType: jsonObj["metaType"],
        content: jsonObj["content"],
        value: x + 1,
      }
      
    }
    setEditApiData([]);
    setEditApiData(newData);
  }

  const onSubmit = async (data) => {
    let params = {
      seoContentId: props.id,
      languageId: languageId,
    };
    var parameter = [];
    for (var i = 0; i <= noOfRows; i++) {
      if (!stringIsNullOrEmpty(data["Edit_MetaType_" + i]) && !stringIsNullOrEmpty(data["Edit_Content_" + i])) {
        parameter.push({
          metaType: data["Edit_MetaType_" + i],
          content: data["Edit_Content_" + i]
        });
      }
    }
    for (var x = 0; x < parameter.length; x++) {
      var myJson = JSON.stringify(parameter[x]);
      params["parameter_" + (x + 1)] = myJson;
    }
    if (!stringIsNullOrEmpty(seodataContentId)) {
      params["id"] = seodataContentId;
      _dispatch(setSaveAdminLog("EDIT_" + props.type + "_Language", _history.location.pathname, params));
    } else {
      _dispatch(setSaveAdminLog("CREATE_" + props.type + "_Language", _history.location.pathname, params));
    }


    let responseJson = await ApiEngine.post(
      ApiUrl._API_CREATE_OR_UPDATE_SEO_METADATA_CONTENT_LANGUAGE,
      createFormBody(params)
    );
    _dispatch(
      showMessage(
        responseJson[ApiKey._API_SUCCESS_KEY],
        t(responseJson[ApiKey._API_MESSAGE_KEY])
      )
    );
    setIsOpen(false);
    getSeoMetadataContent();
    setSeodataContentId("");
    setLanguageId("");
    setNoOfRows(1);
  };
  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    getConfigLanguage();
    getLanguage();
    getSeoMetadataContent();
    /// Used to Exclude the column of table
    if (_EXCLUDE_CONTENT_TYPE.includes(props.type)) {
      _tableColumns.splice(1, 1);
    } else if (_EXCLUDE_TITLE_TYPE.includes(props.type)) {
      _tableColumns.splice(2, 1);
    }
    setTableColumns(_tableColumns);
  }, []);

  async function getLanguage() {
    await fetch(ApiUrl._API_GET_LANGUAGE, {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          const languageList = [];
          responseJson[ApiKey._API_DATA_KEY].forEach(function (language) {
            languageList.push({ label: language.name, value: language.id });
          });
          setLanguageOption(languageList);
        }
      });
  }
  /// <summary>
  /// Author : -
  /// </summary>
  const getConfigLanguage = async () => {
    await fetch(
      ApiUrl._API_GET_CONFIG_LANGUAGE_BY_TYPE_ID +
      "?type=" +
      props.type +
      "&id=" +
      props.id,
      {
        method: ApiKey._API_GET,
        headers: {
          "Content-Type": ApiKey._API_FORM_URLENCODED,
          "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
        },
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          setData(responseJson[ApiKey._API_DATA_KEY]);
        }
      });
  };

  useEffect(() => {
    setSeoMetaContentLabel(props.method);
  }, [props.method]);

  async function getSeoMetadataContent(languageId) {

    var apiUrl = ApiUrl._API_GET_SEO_METADATA_CONTENT_LANGUAGE;
    if (languageId != null) {
      apiUrl += "?id=" + languageId
    } else {
      apiUrl += "?seoContentId=" + props.id
    }

    fetch(apiUrl, {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          setSeoContentData(responseJson[ApiKey._API_DATA_KEY]);

          if (!stringIsNullOrEmpty(data["content"])) {
            var content = JSON.parse(data["content"]);
            var contentLength = Object.keys(content).length;
            setNoOfRows(contentLength);

            var newData = [];
            for (var i = 0; i < contentLength; i++) {
              var jsonObj = JSON.parse(content["parameter_" + (i + 1)]);
              newData[i] = {
                name: (i + 1),
                metaType: jsonObj["metaType"],
                content: jsonObj["content"],
                value: i + 1,
              };

              setApiData(newData);
            }
          }
        }
      }
      );
  }

  async function addStep() {
    let list = editApiData;

    let countRow = noOfRows + 1;

    var newData = [];


    let data1 = {
      name: (countRow),
      metaType: "",
      content: "",
      value: countRow,
    };
    list.push(data1);
    setNoOfRows(countRow);
    setEditApiData(list);

  }

  async function removeStep(index) {
    let list = editApiData;
    let countRow = noOfRows - 1;
    let inx = index;
    var newData = [];
    newData = list.filter((x) => x.value !== inx);

    var parameter = [];
    var params = [];
    for (var i = 0; i < newData.length; i++) {
      parameter.push({
        value: newData[i]
      });
    }

    for (var x = 0; x < parameter.length; x++) {
      params[x] = {
        name: (x + 1),
        metaType: parameter[x].value.metaType,
        content: parameter[x].value.content,
        value: x + 1,
      };

    }

    for (var i = 0; i < params.length; i++) {
      var x = document.getElementById("Edit_MetaType_" + (i));
      x.value = parameter[i].value.metaType;
      var y = document.getElementById("Edit_Content_" + (i));
      y.value = parameter[i].value.content;
    }

    setNoOfRows(countRow);
    setEditApiData(params);

  }

  return (
    <div>
      <div className="row">
        <ConfigurationLanguageDetail
          visible={languageDetailModalVisibility}
          onFinish={() => {
            setLanguageDetailModalVisibility(false);
            getConfigLanguage();
          }}
          dataTypeId={props.id}
          languageDetailId={languageDetailId}
          languageType={props.type}
        />

      </div>
      <Panel>
        <PanelBody>
          <button
            type="button"
            onClick={() => {
              setLanguageDetailId("");
              setIsCreated(1);
              setIsOpen(true);
              setNoOfRows(1);
            }}
            className="btn btn-purple"
          >
            {t("ADD_LANGUAGE")}
          </button>
          <ReactTable data={seoContentData} columns={_tableColumns} minRows={1} />
        </PanelBody>
      </Panel>

      <Modal fade={false} contentClassName="modal-brand modal-bottom modal-numpad withdrawal" isOpen={isOpen} centered>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader>
            {isCreated == 1 ? t("ADD_LANGUAGE") : t("EDIT_LANGUAGE")}
          </ModalHeader>
          <ModalBody style={{ minHeight: "30vh" }}>

            {isCreated == 1 ?
              (
                <>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group row m-b-15">
                        <label className="col-md-4 col-form-label">
                          <b>{t("METADATATYPE")}</b>
                        </label>
                        <div className="col-md-7">
                          <label>
                            {t(seoMetaContentLabel)}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <div className="form-group row m-b-15">
                        <label className="col-md-4 col-form-label">
                          <b>{t("LANGUAGE")}</b>
                        </label>
                        <div className="col-md-7">
                          <Select
                            name="languageId"
                            options={languageOption}
                            placeholder={
                              languageOption.filter(
                                (option) => option.value == languageId
                              )[0] !== undefined
                                ? languageOption.filter(
                                  (option) =>
                                    option.value == languageId
                                )[0].label
                                : ""
                            }
                            value={languageOption.filter(
                              (option) => option.value == languageId
                            )}
                            onChange={(e) => {
                              setLanguageId(e.value);
                              setValue("languageId", e.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div><h3>{t("CONTENT")}</h3></div>
                    <div><button type="button" class="btn btn-primary me-3" onClick={() => setNoOfRows(noOfRows + 1)}>Add</button></div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <table className="table table-hover">
                        <thead>
                          <tr>
                            <th>{t("No.")}</th>
                            <th>{t("PAGE")}</th>
                            <th>{t("CONTENT")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {[...Array(noOfRows)].map((elementInArray, index) => {
                            return (
                              <tr>
                                <th scope="row">{index + 1}</th>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control m-b-5"
                                    name={"Edit_MetaType_" + (index + 1)}
                                    ref={register}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control m-b-5"
                                    name={"Edit_Content_" + (index + 1)}
                                    ref={register}
                                  />
                                </td>
                              </tr>
                            )
                          })
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <hr />
                </>
              ) :
              (
                <>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group row m-b-15">
                        <label className="col-md-4 col-form-label">
                          <b>{t("METADATATYPE")}</b>
                        </label>
                        <div className="col-md-7">
                          <label>
                            {t(seoMetaContentLabel)}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <div className="form-group row m-b-15">
                        <label className="col-md-4 col-form-label">
                          <b>{t("LANGUAGE")}</b>
                        </label>
                        <div className="col-md-7">
                          <Select
                            name="languageId"
                            options={languageOption}
                            placeholder={
                              languageOption.filter(
                                (option) => option.value == languageId
                              )[0] !== undefined
                                ? languageOption.filter(
                                  (option) =>
                                    option.value == languageId
                                )[0].label
                                : ""
                            }
                            value={languageOption.filter(
                              (option) => option.value == languageId
                            )}
                            onChange={(e) => {
                              setLanguageId(e.value);
                              setValue("languageId", e.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div><h3>{t("CONTENT")}</h3></div>
                    <div><button type="button" class="btn btn-primary me-3" onClick={() => addStep()}>Add</button></div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <table className="table table-hover">
                        <thead>
                          <tr>
                            <th>{t("No.")}</th>
                            <th>{t("PAGE")}</th>
                            <th>{t("CONTENT")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {editApiData.map((data, value) => {
                            return (

                              <tr >
                                <td>{data.name}</td>
                                <td>
                                  <input
                                    type="text"
                                    id={"Edit_MetaType_" + (value)}
                                    defaultValue={data.metaType}
                                    className="form-control m-b-5"
                                    name={"Edit_MetaType_" + (value)}
                                    ref={register}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    id={"Edit_Content_" + (value)}
                                    defaultValue={data.content}
                                    className="form-control m-b-5"
                                    name={"Edit_Content_" + (value)}
                                    ref={register}
                                  />
                                </td>
                                <td><button type="button" class="btn btn-danger me-3" onClick={() => removeStep(value + 1)}>-</button></td>
                              </tr>
                            );
                          })
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <hr />
                </>
              )

            }

          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-white"
              onClick={() => setIsOpen(false)}
            >
              {t("CLOSE")}
            </button>
            <button type="submit" className="btn btn-primary">
              {t("SUBMIT")}
            </button>
          </ModalFooter>
        </form>
      </Modal>

    </div>

  );
};

export default SeoMetaContentLanguage;
