import React, { useState, useEffect } from "react";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import { useHistory } from "react-router-dom";
import {
    UncontrolledButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from "reactstrap";
import { navigateTo, stringIsNullOrEmpty } from "../../util/Util";
import {
    ApiUrl,
    ApiKey,
    WebUrl,
    AccessRight,
    LanguageKey,
} from "../../util/Constant";
import { checkIfPermissionExist } from "../../util/PermissionChecker";
import { setBusy, setIdle, showMessage } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";

/// <summary>
/// Author : -
/// </summary>
const ManageLoyaltyPoints = (props) => {
    const { t } = useTranslation(LanguageKey._PRIMARY);
    let _history = useHistory();
    const _dispatch = useDispatch();
    const [itemData, setItemData] = useState([]);

    let _tableColumns = [
        {
            Header: "PRODUCT_NAME",
            accessor: "productName",
            Cell: ({ row }) => (
                <div style={{ textAlign: "center" }}>{row.original.productName}</div>
            ),
        },
        {
            Header: "PRODUCT_CODE",
            accessor: "productCode",
            Cell: ({ row }) => (
                <div style={{ textAlign: "center" }}>{row.original.productCode}</div>
            ),
        },
        {
            Header: "GAME_POINTS",
            accessor: "loyaltyPoints",
            Cell: ({ row }) => (
                <div style={{ textAlign: "center" }}>{row.original.loyaltyPoints.toFixed(2)}</div>
            ),
        },
        {
            Header: "VALUE_IN_CURRENCY",
            accessor: "valueInCurrency",
            Cell: ({ row }) => (
                <div style={{ textAlign: "center" }}>{row.original.valueInCurrency.toFixed(13)}</div>
            ),
        },
        {
            id: "action",
            Header: "",
            Cell: ({ row }) =>
                checkIfPermissionExist(
                    AccessRight._LOYALTY_POINTS_PERMISSION,
                    true
                ) && (
                    <div className="btn-group m-r-5 m-b-5">
                        <UncontrolledButtonDropdown>
                            <DropdownToggle caret color="default">
                                <i className="fas fa-cog"></i>
                            </DropdownToggle>
                            <DropdownMenu container="body" flip={false} right>
                                <DropdownItem
                                    onClick={() => {
                                        navigateTo(
                                            _history,
                                            WebUrl._URL_UPDATE_LOYALTY_POINTS,
                                            row.original
                                        );
                                    }}
                                >
                                    <span className="text-warning">{t("EDIT")}</span>
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>
                    </div>
                ),
            disableSortBy: true,
            disableFilters: true,
            width: 100,
            style: { overflow: "visible" },
        },
    ];

    /// <summary>
    /// Author : -
    /// </summary>
    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        _dispatch(setBusy());
        fetch(ApiUrl._API_GET_ACCOUNT_PRODUCT, {
            method: ApiKey._API_GET,
            headers: {
                "Content-Type": ApiKey._API_FORM_URLENCODED,
                "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
            },
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    setItemData(responseJson[ApiKey._API_DATA_KEY]);
                }
            });
        _dispatch(setIdle());
    }

    return (
        <div>
            <h1 className="page-header">
                {t("GAME_POINTS_SETTING")}
            </h1>
            <Panel>
                <PanelBody>
                    <ReactTable filterable data={itemData} columns={_tableColumns} />
                </PanelBody>
            </Panel>
        </div>
    );
};

export default ManageLoyaltyPoints;