import React, { useState, useEffect } from "react";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import { useHistory } from "react-router-dom";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import { ApiKey, ApiUrl, LanguageKey } from "../../util/Constant";
import { stringIsNullOrEmpty, createMultiPartFormBody } from "../../util/Util";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { setBusy, setIdle, showMessage, setSaveAdminLog } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Dropzone from "react-dropzone";
import { fetch } from "whatwg-fetch";

/// <summary>
/// Author : -
/// </summary>
const LanguageDetail = (props) => {
  const { t } = useTranslation(LanguageKey._PRIMARY);
  let _history = useHistory();
  const _location = useLocation();
  const _dispatch = useDispatch();
  const [name, setName] = useState("");
  const [status, setStatus] = useState(true);
  const [code, setCode] = useState("");
  const [languageId, setLanguageId] = useState("");

  const { register, handleSubmit, errors } = useForm();
  const [submitError, setSubmitError] = useState("");
  const [submitSuccess, setSubmitSuccess] = useState("");
  const [iconImage, setIconImage] = useState(null);
  const [iconFile, setIconFile] = useState(null);
  const [isNewIcon, setIsNewIcon] = useState(false);

  /// <summary>
  /// Author : -
  /// </summary>
  const submitForm = async (data, e) => {
    let params = {
      name: data.name,
      status: data.status,
      code: data.code,
    };

    if (!stringIsNullOrEmpty(iconFile) && isNewIcon) {
      params["icon"] = iconFile;
    }

    if (stringIsNullOrEmpty(iconImage)) {
      params["iconRemoved"] = true;
    }

    if (!stringIsNullOrEmpty(languageId)) {
      params["id"] = languageId;
      _dispatch(setSaveAdminLog("EDIT_BO_LANGUAGE", _history.location.pathname, params));
    } else {
      _dispatch(setSaveAdminLog("CREATE_BO_LANGUAGE", _history.location.pathname, params));
    }

    let formBody = createMultiPartFormBody(params);
    _dispatch(setBusy());

    fetch(ApiUrl._API_CREATE_OR_UPDATE_LANGUAGE, {
      method: ApiKey._API_POST,
      headers: {
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
      body: formBody,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        _dispatch(setIdle());
        _dispatch(
          showMessage(
            responseJson[ApiKey._API_SUCCESS_KEY],
            t(responseJson[ApiKey._API_MESSAGE_KEY])
          )
        );

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          setIsNewIcon(false);
          setLanguageId(responseJson[ApiKey._API_DATA_KEY]["id"]);
        }
      });
  };

  /// <summary>
  /// Author : -
  /// Used to set logo
  /// </summary>
  const logoDrop = (acceptedFiles) => {
    if (acceptedFiles.length !== 0) {
      const file = acceptedFiles[0];
      setIconFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setIconImage(reader.result);
      };
      reader.readAsDataURL(file);
      setIsNewIcon(true);
    }
  };

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    if (_location.state) {
      var apiUrl = ApiUrl._API_GET_LANGUAGE_BY_ID;
      apiUrl += "?id=" + _location.state.id;
      fetch(apiUrl, {
        method: ApiKey._API_GET,
        headers: {
          "Content-Type": ApiKey._API_FORM_URLENCODED,
          "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setLanguageId(responseJson[ApiKey._API_DATA_KEY]["id"]);
            setName(responseJson[ApiKey._API_DATA_KEY]["name"]);
            setStatus(responseJson[ApiKey._API_DATA_KEY]["status"]);
            setCode(responseJson[ApiKey._API_DATA_KEY]["code"]);
            if (
              !stringIsNullOrEmpty(responseJson[ApiKey._API_DATA_KEY]["icon"])
            ) {
              setIconImage(responseJson[ApiKey._API_DATA_KEY]["icon"]);
            }
          }
        });
    }
  }, []);

  return (
    <div>
      <h1 className="page-header">
        {!stringIsNullOrEmpty(languageId)
          ? t("EDIT_LANGUAGE")
          : t("ADD_LANGUAGE")}
        <NavigationButton history={_history} />
      </h1>
      <div className="row">
        <div className="col-xl-12">
          <Panel>
            <PanelBody>
              <form onSubmit={handleSubmit(submitForm)}>
                <div className="row">
                  <div className="col-lg-8">
                    <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        <b>{t("LANGUAGE_NAME")}</b>
                      </label>
                      <div className="col-md-7">
                        <input
                          type="text"
                          className="form-control"
                          placeholder={t("PLEASE_ENTER_LANGUAGE_NAME")}
                          name="name"
                          ref={register({
                            required: "PLEASE_ENTER_LANGUAGE_NAME",
                          })}
                          defaultValue={name}
                        />
                        {errors.name && (
                          <div className="invalid-feedback">
                            {t(errors.name.message)}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        <b>{t("CODE")}</b>
                      </label>
                      <div className="col-md-7">
                        <input
                          type="text"
                          className="form-control"
                          placeholder={t("PLEASE_ENTER_CODE")}
                          name="code"
                          ref={register({ required: "PLEASE_ENTER_CODE" })}
                          defaultValue={code}
                        />
                        {errors.code && (
                          <div className="invalid-feedback">
                            {t(errors.code.message)}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        <b>{t("STATUS")}</b>
                      </label>
                      <div className="col-md-7 col-form-label">
                        <div className="switcher">
                          <input
                            type="checkbox"
                            name="status"
                            id="status"
                            onChange={(e) => setStatus(e.target.checked)}
                            value={true}
                            checked={status}
                            ref={register}
                          />
                          <label htmlFor="status"></label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        <b>{t("ICON")}</b>
                      </label>
                      <div className="col-md-7">
                        <Dropzone
                          accept={"image/*"}
                          onDrop={(acceptedFiles) => logoDrop(acceptedFiles)}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <section>
                              <div
                                className="dropzone"
                                style={{
                                  minHeight: "200px",
                                  textAlign: "center",
                                }}
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <h4 style={{ color: "grey" }}>
                                  {t("SELECT_OR_DRAG_AND_DROP_FILE")}
                                </h4>
                                {!stringIsNullOrEmpty(iconImage) && (
                                  <aside className="thumbsContainer">
                                    <div className="thumb">
                                      <div className="thumbInner">
                                        <img
                                          src={iconImage}
                                          className="dropzone-img"
                                        />
                                      </div>
                                    </div>
                                  </aside>
                                )}
                              </div>
                            </section>
                          )}
                        </Dropzone>
                        <br />
                        <br />
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={() => {
                            setIconImage("");
                            setIconFile();
                          }}
                        >
                          {t("REMOVE_ICON")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-lg-3">
                    <div className="form-group">
                      <button type="submit" className="btn btn-primary">
                        {t("SUBMIT")}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </PanelBody>
          </Panel>
        </div>
      </div>
    </div>
  );
};

export default LanguageDetail;
