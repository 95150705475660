import React, { useState, useEffect } from "react";
import ReactTable from "../../../components/constantComponent/reactTable/ReactTable";
import Select from "react-select";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../../components/panel/panel.jsx";
import { useHistory } from "react-router-dom";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { ApiKey, ApiUrl, LanguageKey, WebUrl, AccessRight, PaymentGateway, RoleId } from "../../../util/Constant";
import { stringIsNullOrEmpty, createFormBody } from "../../../util/Util";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { setBusy, setIdle, showMessage, setSaveAdminLog } from "../../../redux/AppAction";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";
import NavigationButton from "../../../components/constantComponent/button/NavigationButton";
import { checkIfPermissionExist } from "../../../util/PermissionChecker";
import { navigateTo } from "../../../util/Util";

/// <summary>
/// Author : -
/// </summary>
const AutoWithdrawalBankAccountDetail = (props) => {
  const { t } = useTranslation(LanguageKey._PRIMARY);
  let _history = useHistory();
  const _location = useLocation();
  const _dispatch = useDispatch();

  const [accountId, setAccountId] = useState("");
  const [bankAccountHolderName, setBankAccountHolderName] = useState("");
  const [bankAccountNumber, setBankAccountNumber] = useState("");
  const [bankLoginUsername, setBankLoginUsername] = useState("");
  const [bankLoginPassword, setBankLoginPassword] = useState("");
  const [status, setStatus] = useState(true);
  const [paymentGatewayId, setPaymentGatewayId] = useState(0);
  const [bankId, setBankId] = useState(0);

  const [isNameChange, setIsNameChange] = useState(false);
  const [isNoChange, setIsNoChange] = useState(false);
  const [isUsernameChange, setIsUsernameChange] = useState(false);
  const [isPasswordChange, setIsPasswordChange] = useState(false);
  const [bankAccount, setBankAccount] = useState([]);
  const [isOpen, setIsOpen] = useState(false);


  const { register, handleSubmit, errors, unregister, setValue } = useForm();
  const {
    register: bankFormRegister,
    handleSubmit: bankFormHandleSubmit,
    errors: bankFormErrors,
    unregister: bankFormUnregister,
    setValue: bankFormSetValue,
  } = useForm();

  const [paymentGatewayOption, setPaymentGatewayOption] = useState([]);
  const [bankOption, setBankOption] = useState([]);
  const { userRoleId } = useSelector((state) => ({
    userRoleId: state.authState.userData.userRoleId
  }));

  /// <summary>
  /// Author : -
  /// </summary>
  const submitForm = async (data, e) => {
    let params = {
      bankAccountHolderName: data.bankAccountHolderName,
      bankAccountNumber: data.bankAccountNumber,
      bankLoginUsername: data.bankLoginUsername,
      bankLoginPassword: data.bankLoginPassword,
      status: stringIsNullOrEmpty(data.id) ? data.status : true,
      bankId: stringIsNullOrEmpty(data.bankId) ? bankId : data.bankId,
      paymentGatewayId: paymentGatewayId,
      isNameChange: isNameChange,
      isNoChange: isNoChange,
      isUsernameChange: isUsernameChange,
      isPasswordChange: isPasswordChange
    };
    if (!stringIsNullOrEmpty(accountId) || !stringIsNullOrEmpty(data.id)) {
      params["id"] = stringIsNullOrEmpty(data.id) ? accountId : data.id;
    }

    let formBody = createFormBody(params);
    _dispatch(setBusy());

    fetch(ApiUrl._API_CREATE_OR_UPDATE_AUTO_WITHDRAWAL_BANK_ACCOUNT, {
      method: ApiKey._API_POST,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
      body: formBody,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        _dispatch(setIdle());
        _dispatch(
          showMessage(
            responseJson[ApiKey._API_SUCCESS_KEY],
            t(responseJson[ApiKey._API_MESSAGE_KEY])
          )
        );

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          setIsNameChange(false);
          setIsNoChange(false);
          setIsUsernameChange(false);
          setIsPasswordChange(false);
          //setBankAccount("");
          setBankAccountHolderName("");
          setBankAccountNumber("");
          setBankLoginUsername("");
          setBankLoginPassword("");
          setBankId(0);
          setAccountId("");
          setIsOpen(false);
          init();
        }
      });
  };

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    //bankFormRegister({ name: "bankId" }, { required: "PLEASE_SELECT_BANK" });
    //register(
    //  { name: "paymentGatewayId" },
    //  { required: "PLEASE_SELECT_PAYMENT_GATEWAY" }
    //);
    init();
  }, []);

  /// <summary>
  /// Author : -
  /// </summary>
  async function init() {
    if (_location.state) {
      setPaymentGatewayId(_location.state.insantPaySettingId);
      
      fetch(ApiUrl._API_GET_AUTOMATEDWITHDRAWAL_BANK_ACCOUNT_BY_PAYMENT_GATEWAY_ID + "?instantPayGatewayId=" + _location.state.insantPaySettingId, {
        method: ApiKey._API_GET,
        headers: {
          "Content-Type": ApiKey._API_FORM_URLENCODED,
          "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setBankAccount(responseJson[ApiKey._API_DATA_KEY]);
          }
        });


      //setInstantPaySettingId()
      //var apiUrl = ApiUrl._API_GET_AUTOMATEDWITHDRAWAL_BANK_ACCOUNT_BY_PAYMENT_GATEWAY_ID;
      //apiUrl += "?instantPayGatewayId=" + _location.state.instantPayGatewayId;
      //await fetch(apiUrl, {
      //  method: ApiKey._API_GET,
      //  headers: {
      //    "Content-Type": ApiKey._API_FORM_URLENCODED,
      //    "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      //  },
      //})
      //  .then((response) => response.json())
      //  .then((responseJson) => {
      //    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      //      let data = responseJson[ApiKey._API_DATA_KEY];
      //      setPaymentGatewayId(data.paymentGatewayId);
      //      setBankId(data.bankId);
      //      setBankAccountHolderName(data.bankAccountHolderName);
      //      setBankAccountNumber(data.bankAccountNumber);
      //      setBankLoginUsername(data.bankLoginUsername);
      //      setBankLoginPassword(data.bankLoginPassword);
      //      setStatus(data.status);
      //      setInstantPaySettingId(_location.state.id);
      //      //setValue(
      //      //  "paymentGatewayId",
      //      //  data.paymentGatewayId
      //      //);
      //      //setValue(
      //      //  "bankId",
      //      //  data.bankId
      //      //);
      //    }
      //  });
    }

    await fetch(ApiUrl._API_GET_INSTANT_PAYMENT_GATEWAY, {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          const paymentGatewayList = [];
          responseJson[ApiKey._API_DATA_KEY].forEach(function (gateway) {
            paymentGatewayList.push({ label: gateway.name, value: gateway.id });
          });

          setPaymentGatewayOption(paymentGatewayList);
        }
      });

      await fetch(ApiUrl._API_GET_AUTOMATEDWITHDRAWAL_BANK + "?paymentGateway=" + PaymentGateway._PAYMENT_GATEWAY_SPG_WITHDRAW, {
        method: ApiKey._API_GET,
        headers: {
          "Content-Type": ApiKey._API_FORM_URLENCODED,
          "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            const bankList = [];
            responseJson[ApiKey._API_DATA_KEY].forEach(function (bank) {
              bankList.push({ label: bank.bankName, value: bank.id });
            });

            setBankOption(bankList);
          }
        });
  }

  let _tableColumns = [
    {
      Header: "BANK_NAME",
      accessor: "bankName",
      width: 100,
    },
    {
      Header: t("BANK_ACCOUNT_HOLDER_NAME"),
      accessor: "bankAccountHolderName",
    },
    {
      Header: "BANK_ACCOUNT_NUMBER",
      accessor: "bankAccountNumber",
      style: {
        whiteSpace: "unset",
        wordBreak: "break-word",
      },
    },
    {
      Header: t("LOGIN_USERNAME"),
      accessor: "bankLoginUsername",
    },
    {
      Header: t("LOGIN_PASSWORD"),
      accessor: "bankLoginPassword",
    },
    {
      id: "status",
      Header: "STATUS",
      accessor: (d) => {
        return d.status ? (
          <span className="badge badge-secondary badge-green">{t("ACTIVE")}</span>
        ) : (
          <span className="badge badge-secondary badge-danger">
            {t("SUSPENDED")}
          </span>
        );
      },
    },
    {
      id: "action",
      Header: "",
      Cell: ({ row }) =>
        checkIfPermissionExist(AccessRight._MANAGE_AUTOWITHDRAWAL_BANK_ACCOUNT_PERMISSION, true) && (
          <div className="btn-group m-r-5 m-b-5">
            <UncontrolledButtonDropdown>
              <DropdownToggle caret color="default">
                <i className="fas fa-cog"></i>
              </DropdownToggle>
              <DropdownMenu>
                {(userRoleId === RoleId._ROLE_COMPANY_ID || userRoleId === RoleId._ROLE_ACCOUNT_SUPERVISOR) &&
                  <DropdownItem
                    onClick={() => {
                      setIsOpen(true);
                      setBankAccountHolderName(row.original.bankAccountHolderName);
                      setBankAccountNumber(row.original.bankAccountNumber);
                      setBankId(row.original.bankId);
                      setBankLoginUsername(row.original.bankLoginUsername);
                      setBankLoginPassword(row.original.bankLoginPassword);
                      setStatus(row.original.status);
                      setAccountId(row.original.id);
                    }}
                  >
                    <span className="text-warning">{t("EDIT")}</span>
                  </DropdownItem>
                }
                {!row.original.status &&
                  <DropdownItem
                    onClick={() => {
                      submitForm(row.original);
                    }}
                  >
                    <span className="text-warning">{t("ENABLE")}</span>
                  </DropdownItem>
                }
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        ),
      disableSortBy: true,
      disableFilters: true,
      width: 100,
      style: { overflow: "visible" },
    },
  ];

  return (
    <div>
      <h1 className="page-header">
        {!stringIsNullOrEmpty(paymentGatewayId)
          ? t("EDIT_AUTOWITHDRAWAL_BANK_ACCOUNT")
          : t("ADD_AUTOWITHDRAWAL_BANK_ACCOUNT")}
        <NavigationButton history={_history} />
      </h1>
      <div className="row">
        <div className="col-xl-12">
          <Panel>
            <PanelBody>
              
                <div className="row">
                  <div className="col-lg-12">
                    <div className="row">
                      <div className="col-lg-8">
                        <div className="form-group row m-b-15">
                          <label className="col-md-5 col-form-label">
                            <b>{t("INSTANY_PAYMENT_GATEWAY")}</b>
                          </label>
                          <div className="col-md-7">
                            <Select
                              name="paymentGatewayId"
                              options={paymentGatewayOption}
                              placeholder={
                                paymentGatewayOption.filter(
                                  (option) => option.value == paymentGatewayId
                                )[0] !== undefined
                                  ? paymentGatewayOption.filter(
                                    (option) =>
                                      option.value == paymentGatewayId
                                  )[0].label
                                  : ""
                              }
                              value={paymentGatewayOption.filter(
                                (option) => option.value == paymentGatewayId
                              )}
                              onChange={(e) => {
                                setPaymentGatewayId(e.value);
                                setValue("paymentGatewayId", e.value);
                              }}
                            />
                            {errors.paymentGatewayId && (
                              <div className="invalid-feedback">
                                {t(errors.paymentGatewayId.message)}
                              </div>
                            )}
                          </div>
                        </div>
                        
                        

                      </div>
                    </div>
                  </div>
                </div>
            </PanelBody>
            <Panel style={{ marginRight: '10px' }}>
              {userRoleId === RoleId._ROLE_COMPANY_ID || userRoleId === RoleId._ROLE_ACCOUNT_SUPERVISOR ?
                <>
                  <button type="button"
                    className="btn btn-primary"
                    style={{ float: "right" }}
                    onClick={() => setIsOpen(true)}
                  >
                    <i className="fa fa-plus-circle m-r-10"></i>{t("ADD_NEW_AUTOWITHDRAWAL_BANK_ACCOUNT")}
                  </button>
                </>
                :
                <></>
              }
              
              <PanelBody>
                <ReactTable data={bankAccount} columns={_tableColumns} />
              </PanelBody>
            </Panel>
          </Panel>
        </div>
      </div>
      <Modal fade={false} contentClassName="modal-brand modal-bottom modal-numpad withdrawal" isOpen={isOpen} centered>
        <ModalHeader>
          {t("ADD_NEW_AUTOWITHDRAWAL_BANK_ACCOUNT")}
        </ModalHeader>
        <ModalBody style={{ minHeight: "30vh" }}>
        <form onSubmit={handleSubmit(submitForm)}>
          <div className="row">
            <div className="col-lg-12">
              <div className="form-group row m-b-15">
                <label className="col-md-5 col-form-label">
                  <b>{t("BANK")}</b>
                </label>
                <div className="col-md-7">
                  <Select
                    name="bankId"
                    options={bankOption}
                    placeholder={
                      bankOption.filter(
                        (option) => option.value == bankId
                      )[0] !== undefined
                        ? bankOption.filter(
                          (option) =>
                            option.value == bankId
                        )[0].label
                        : ""
                    }
                    value={bankOption.filter(
                      (option) => option.value == bankId
                    )}
                    onChange={(e) => {
                      setBankId(e.value);
                      setValue("bankId", e.value);
                    }}
                  />
                  {errors.bankId && (
                    <div className="invalid-feedback">
                      {t(errors.bankId.message)}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="form-group row m-b-15">
                <label className="col-md-5 col-form-label">
                  <b>{t("BANK_ACCOUNT_HOLDER_NAME")}</b>
                </label>
                <div className="col-md-7">
                  <input
                    type="text"
                    className="form-control"
                    placeholder={t("PLEASE_ENTER_BANK_ACCOUNT_HOLDER_NAME")}
                    name="bankAccountHolderName"
                    defaultValue={bankAccountHolderName}
                    ref={register({
                      required: "PLEASE_ENTER_BANK_ACCOUNT_HOLDER_NAME",
                    })}
                    onChange={(e) => { setBankAccountHolderName(e.target.checked); setIsNameChange(true); }}
                  />
                  {errors.bankAccountHolderName && (
                    <div className="invalid-feedback">
                      {t(errors.bankAccountHolderName.message)}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="form-group row m-b-15">
                <label className="col-md-5 col-form-label">
                  <b>{t("BANK_ACCOUNT_NUMBER")}</b>
                </label>
                <div className="col-md-7">
                  <input
                    type="text"
                    className="form-control"
                    placeholder={t("PLEASE_ENTER_BANK_ACCOUNT_NUMBER")}
                    defaultValue={bankAccountNumber}
                    name="bankAccountNumber"
                    ref={register({
                      required: "PLEASE_ENTER_BANK_ACCOUNT_NUMBER",
                    })}
                    onChange={() => setIsNoChange(true)}
                  />
                  {errors.bankAccountNumber && (
                    <div className="invalid-feedback">
                      {t(errors.bankAccountNumber.message)}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="form-group row m-b-15">
                <label className="col-md-5 col-form-label">
                  <b>{t("BANK_LOGIN_USERNAME")}</b>
                </label>
                <div className="col-md-7">
                  <input
                    type="text"
                    className="form-control"
                    name="bankLoginUsername"
                    defaultValue={bankLoginUsername}
                    placeholder={t("PLEASE_ENTER_BANK_LOGIN_USERNAME")}
                    ref={register({ required: "PLEASE_ENTER_BANK_LOGIN_USERNAME" })}
                    onChange={() => setIsUsernameChange(true)}
                  />
                  {errors.bankLoginUsername && (
                    <div className="invalid-feedback">
                      {t(errors.bankLoginUsername.message)}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="form-group row m-b-15">
                <label className="col-md-5 col-form-label">
                  <b>{t("BANK_LOGIN_PASSWORD")}</b>
                </label>
                <div className="col-md-7">
                  <input
                    type="password"
                    className="form-control"
                    name="bankLoginPassword"
                    defaultValue={bankLoginPassword}
                    placeholder={t("PLEASE_ENTER_BANK_LOGIN_PASSWORD")}
                    ref={register({ required: "PLEASE_ENTER_BANK_LOGIN_PASSWORD" })}
                    onChange={() => setIsPasswordChange(true)}
                  />
                  {errors.bankLoginPassword && (
                    <div className="invalid-feedback">
                      {t(errors.bankLoginPassword.message)}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="form-group row m-b-15">
                <label className="col-md-5 col-form-label">
                  <b>{t("STATUS")}</b>
                </label>
                <div className="col-md-7">
                  <div className="switcher">
                    <input
                      type="checkbox"
                      name="status"
                      id="status"
                      onChange={(e) => setStatus(e.target.checked)}
                      value={true}
                      checked={status}
                      ref={register}
                    />
                    <label htmlFor="status"></label>
                  </div>
                </div>
              </div>
            </div>
            </div>
            <div style={{ float: 'right', display: 'flex', gridColumnGap:'20px' }}>
              <div>
                <button
                type="button"
                className="btn btn-white"
                onClick={() => setIsOpen(false)}
              >
                {t("CLOSE")}
                </button>
              </div>
              <div>
                <button type="submit" className="btn btn-primary" >
                {t("SUBMIT")}
                </button>
              </div>
              
              
            </div>
            
          </form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default AutoWithdrawalBankAccountDetail;