import React, { useState, useEffect } from "react";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import Dropzone from "react-dropzone";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import {
  ApiKey,
  ApiUrl,
  TransactionType,
  PanelType,
  LanguageKey,
  BankingChannel,
  BonusMethodType,
  DEFAULT_PAGE_SIZE,
} from "../../util/Constant";
import { stringIsNullOrEmpty, createMultiPartFormBody, createFormBody } from "../../util/Util";
import { useForm } from "react-hook-form";
import { setBusy, setIdle, showMessage, setSaveAdminLog } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";
import { AsyncPaginate } from "react-select-async-paginate";
import ApiEngine from "../../util/ApiEngine";

import PinInput from 'react-pin-input';
import { Modal, ModalHeader, ModalBody } from "reactstrap";

/// <summary>
/// Author : -
/// </summary>

const Deposit = (props) => {
  const { t } = useTranslation(LanguageKey._PRIMARY);
  let _history = useHistory();
  const _dispatch = useDispatch();
  const {
    register,
    unregister,
    handleSubmit,
    errors,
    reset,
    setValue,
    trigger,
  } = useForm();
  const [bankAccountOption, setBankAccountOption] = useState([]);
  const [bankAccountId, setBankAccountId] = useState("");
  const [memberOption, setMemberOption] = useState([]);
  const [memberId, setMemberId] = useState("");
  const [bonusOption, setBonusOption] = useState([]);
  const [bonusId, setBonusId] = useState("");
  const [minDep, setMinDep] = useState(0);
  const [maxDep, setMaxDep] = useState(0);

  const [fullScreen, setFullScreen] = useState(false);

  const [receiptFile, setReceiptFile] = useState([]);
  const [receiptImage, setReceiptImage] = useState();
  const [selectedMember, setSelectedMember] = useState();

  const [showPinEntry, setShowPinEntry] = useState(false);

  const [enteredDepositData, setEnteredDepositData] = useState({});
  const [enteredDepositE, setEnteredDepositE] = useState({});

  /// <summary>
  /// Author : -
  /// Used to set logo
  /// </summary>
  const imageDrop = (acceptedFiles) => {
    if (acceptedFiles.length !== 0) {
      const file = acceptedFiles[0];
      setReceiptFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setReceiptImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    register({ name: "memberId" }, { required: "PLEASE_SELECT_USER" });
    register(
      { name: "bankAccountId" },
      { required: "PLEASE_SELECT_BANK_ACCOUNT" }
    );
    /// For The Size Difference of Modal And Page
    if (props.id) {
      setFullScreen(true);
    }
    init();
    initFormField();
  }, []);

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    if (!stringIsNullOrEmpty(memberId)) {
      getUserBonus();
    }
  }, [memberId]);

  const getUserBonus = async () => {
    var userBonusApiUrl = ApiUrl._API_GET_USER_BONUS;
    userBonusApiUrl += "?dayCheck=" + true + "&memberId=" + memberId;
    await fetch(userBonusApiUrl, {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          const bonusList = [];
          responseJson[ApiKey._API_DATA_KEY].forEach(function (bonus) {
            bonusList.push({
              label:
                bonus.bonusName +
                " (" +
                bonus.bonus
                + "%" +
                //(bonus.methodId == BonusMethodType._PERCENT ? "%" : "") +
                ")",
              value: bonus.id,
            });
          });

          setBonusOption(bonusList);
        }
      });
  };

  /// <summary>
  /// Author : -
  /// </summary>
  async function init() {
    _dispatch(setBusy());
    await fetch(ApiUrl._API_GET_USER_BANK_ACCOUNT + "?all=true", {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          const bankAccountList = [];
          responseJson[ApiKey._API_DATA_KEY].forEach(function (bankAccount) {
            if (bankAccount.depositStatus) {
              bankAccountList.push({
                label: bankAccount.bankName + " - " + bankAccount.accountNumber,
                value: bankAccount.id,
                minDeposit: bankAccount.minimumDeposit,
                maxDeposit: bankAccount.maximumDeposit,
              });
            }
          });

          setBankAccountOption(bankAccountList);
          if (bankAccountList.length == 0) {
            _dispatch(showMessage(false, t("NO_BANK_ACCOUNT_WITH_DEPOSIT_STATUS_OPEN")));
          }
        }
      });

    _dispatch(setIdle());
  }

  async function getMemberList(search, loadOptions, { page }) {
    var responseJson = await ApiEngine.get(
      `${ApiUrl._API_GET_USER_MEMBER_LIST}?start=${(page - 1) * DEFAULT_PAGE_SIZE
      }&count=${DEFAULT_PAGE_SIZE}&keyword=${search}`
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY]["data"];
      let formattedData = data.map((m) => {
        return { label: m.username, value: m.id, balance: m.primaryCredit };

      });
      { console.log(formattedData) }
      return {
        options: formattedData,
        hasMore: formattedData.length == DEFAULT_PAGE_SIZE,
        additional: {
          page: page + 1,
        },
      };
    }
  }

  /// <summary>
  /// Author : -
  /// </summary>
  const initFormField = () => {
    if (stringIsNullOrEmpty(props.id)) {
      setMemberId("");
      setValue("memberId", "");
    } else {
      setMemberId(props.id);
      setValue("memberId", props.id);
    }
    setValue("bankAccountId", "");
    setBankAccountId("");
    setBonusId("");
    setReceiptFile([]);
    setReceiptImage();
    setBonusOption([]);
  };

  /// <summary>
  /// Author : -
  /// Editted : -
  /// </summary>
  const submitForm = async (data, e) => {
    let params = {
      transactionTypeId: TransactionType._DEPOSIT,
      memberId: memberId,
      amount: data.amount,
      channel: BankingChannel._CHANNEL_OFFLINE_BANKING,
      panel: PanelType._BACKOFFICE,
      bankAccountId: bankAccountId,
      receiptReference: data.reference,
    };

    if (!stringIsNullOrEmpty(bonusId)) {
      params["bonusId"] = bonusId;
    }

    if (!stringIsNullOrEmpty(receiptFile)) {
      params["receipt"] = receiptFile;
    }
    _dispatch(setSaveAdminLog("CREATE_TRANSACTION_DEPOSIT", _history.location.pathname, params));
    let formBody = createMultiPartFormBody(params);
    _dispatch(setBusy());

    fetch(ApiUrl._API_CREATE_TRANSACTION, {
      method: ApiKey._API_POST,
      headers: {
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
      body: formBody,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        _dispatch(setIdle());
        _dispatch(
          showMessage(
            responseJson[ApiKey._API_SUCCESS_KEY],
            t(responseJson[ApiKey._API_MESSAGE_KEY])
          )
        );

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          e.target.reset();
          window.location.reload();
          initFormField();

          if (props.onComplete != null) {
            props.onComplete();
          }
        }
        else {
          setShowPinEntry(false);
        }
      });
  };

  async function onPinSubmit(data, e) {
    // verify pin
    let params = { transactionPinNumber: data };
    let formBody = createFormBody(params);
    let responseJson = await ApiEngine.post(ApiUrl._API_VERIFY_USER_TRANSACTION_PIN_NUMBER, formBody);

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      submitForm(enteredDepositData, enteredDepositE);
    }
    else {
      _dispatch(showMessage(false, t("INCORRECT") + " " + t("PIN_NUMBER")));
      setShowPinEntry(false);
    }
  }

  async function checkPinValue(data, e) {
    // check if pin has 4 digits (valid pin)
    // if true then submit pin
    if (data.length == 4) {
      onPinSubmit(data);
    }

  }

  async function onSubmit(data, e) {
    setEnteredDepositData(data);
    setEnteredDepositE(e);

    setShowPinEntry(true);
    /*
    let tempStatus = await checkSystemSettings();
    if (tempStatus) {
        setShowPinEntry(true);
    }
    else {
        _dispatch(showMessage(false, t("DEPOSIT") + " " + t("CURRENTLY_OFFLINE")));
        // props.parentInit();
    }*/
  }

  async function checkMember(e) {
    _dispatch(setBusy());
    let responseJson = await ApiEngine.post(ApiUrl._API_CHECK_MEMBER_IS_SPECIAL_ROLLOVER + "?memberId=" + e.value);

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY];

      if (data === false) {
        setValue("memberId", e.value);
        setMemberId(e.value);
        setSelectedMember(e);
        trigger("memberId");
      }
      else {
        _dispatch(
          showMessage(false, t("ROLLOVER_EXIST"))
        );
      }
    }
    _dispatch(setIdle());
  }

  return (
    <div>
      <h1 className="page-header">
        {t("DEPOSIT")}{" "}
        {props.id ? (
          <button
            style={{ float: "right" }}
            className="btn btn-inverse"
            onClick={() => {
              props.toggleModal(false);
            }}
          >
            <i className="fa fa-times"></i>
          </button>
        ) : (
          <NavigationButton history={_history} />
        )}
      </h1>
      <div className="row">
        <div className="col-xl-12">
          <Panel>
            <PanelBody>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className={fullScreen ? "col-lg-12" : "col-lg-8"}>
                    <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        <b>{t("USERNAME")}</b>
                      </label>
                      <div className="col-md-7">
                        <AsyncPaginate
                          name="memberId"
                          placeholder={
                            selectedMember ? selectedMember.label : ""
                          }
                          isDisabled={!stringIsNullOrEmpty(props.id)}
                          debounceTimeout={250}
                          loadOptions={getMemberList}
                          additional={{
                            page: 1,
                          }}
                          value={selectedMember}
                          onChange={(e) => {
                            checkMember(e);
                          }}
                        />
                        {errors.memberId && (
                          <div className="invalid-feedback">
                            {t(errors.memberId.message)}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className={fullScreen ? "col-lg-12" : "col-lg-8"}>
                    <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        <b>{t("BANK")}</b>
                      </label>
                      <div className="col-md-7">
                        <Select
                          name="bankAccountId"
                          options={bankAccountOption}
                          placeholder={
                            bankAccountOption.filter(
                              (option) => option.value == bankAccountId
                            )[0] !== undefined
                              ? bankAccountOption.filter(
                                (option) => option.value == bankAccountId
                              )[0].label
                              : ""
                          }
                          value={bankAccountOption.filter(
                            (option) => option.value == bankAccountId
                          )}
                          onChange={(e) => {
                            setValue("bankAccountId", e.value);
                            setBankAccountId(e.value);
                            setMinDep(e.minDeposit);
                            setMaxDep(e.maxDeposit);
                            trigger("bankAccountId");
                          }}
                        />
                        {errors.bankAccountId && (
                          <div className="invalid-feedback">
                            {t(errors.bankAccountId.message)}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className={fullScreen ? "col-lg-12" : "col-lg-8"}>
                    <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        <b>{t("AMOUNT")}</b>
                      </label>
                      <div className="col-md-7">
                        <input
                          type="number"
                          step="0.01"
                          className="form-control"
                          placeholder={t("PLEASE_ENTER_AMOUNT")}
                          name="amount"
                          defaultValue={0}
                          ref={register({
                            required: "PLEASE_ENTER_AMOUNT",
                            min: {
                              value: 0,
                              message: "PLEASE_ENTER_VALUE_NOT_LESS_THAN_ZERO",
                            },
                          })}
                        />
                        {errors.amount && (
                          <div className="invalid-feedback">
                            {t(errors.amount.message)}
                          </div>
                        )}
                        {
                          (minDep != 0 && maxDep != 0) &&
                          <div className="col-md-12"><span style={{ color: "red" }}>* Min: {minDep}/ Max: {maxDep}</span></div>
                        }
                      </div>
                    </div>
                  </div>
                  <div className={fullScreen ? "col-lg-12" : "col-lg-8"}>
                    <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        <b>{t("RECEIPT_REFERENCE")}</b>
                      </label>
                      <div className="col-md-7">
                        <input
                          type="text"
                          className="form-control"
                          name="reference"
                          ref={register({
                            required: "PLEASE_ENTER_RECEIPT_REFERENCE",
                          })}
                          placeholder={t("PLEASE_ENTER_RECEIPT_REFERENCE")}
                        />
                        {errors.reference && (
                          <div className="invalid-feedback">
                            {t(errors.reference.message)}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className={fullScreen ? "col-lg-12" : "col-lg-8"}>
                    <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        <b>{t("BONUS")}</b>
                      </label>
                      <div className="col-md-7">
                        <Select
                          name="bonusId"
                          options={bonusOption}
                          placeholder={
                            bonusOption.filter(
                              (option) => option.value == bonusId
                            )[0] !== undefined
                              ? bonusOption.filter(
                                (option) => option.value == bonusId
                              )[0].label
                              : ""
                          }
                          value={bonusOption.filter(
                            (option) => option.value == bonusId
                          )}
                          onChange={(e) => {
                            unregister("bonusId");
                            setBonusId(e.value);
                          }}
                        />
                        {errors.bonusId && (
                          <div className="invalid-feedback">
                            {t(errors.bonusId.message)}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className={fullScreen ? "col-lg-12" : "col-lg-8"}>
                    <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        <b>{t("RECEIPT")}</b>
                      </label>
                      <div className="col-md-7">
                        <Dropzone
                          accept={"image/*"}
                          onDrop={(acceptedFiles) => imageDrop(acceptedFiles)}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <section>
                              <div
                                className="dropzone"
                                style={{
                                  minHeight: "200px",
                                  textAlign: "center",
                                }}
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <h4 style={{ color: "grey" }}>
                                  {t(
                                    "DRAG_N_DROP_SOME_FILES_HERE_OR_CLICK_TO_SELECT_FILES"
                                  )}
                                </h4>
                                {!stringIsNullOrEmpty(receiptImage) && (
                                  <aside className="thumbsContainer">
                                    <div className="thumb">
                                      <div className="thumbInner">
                                        <img
                                          src={receiptImage}
                                          className="dropzone-img"
                                        />
                                      </div>
                                    </div>
                                  </aside>
                                )}
                              </div>
                            </section>
                          )}
                        </Dropzone>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-lg-3">
                    <div className="form-group">
                      <button type="submit" className="btn btn-primary">
                        {t("SUBMIT")}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </PanelBody>
          </Panel>
        </div>
      </div>
      <Modal fade={false} contentClassName="modal-brand modal-bottom modal-numpad" isOpen={showPinEntry} centered>
        <ModalBody>
          <form onSubmit={handleSubmit(onPinSubmit)}>
            <div className="display-wrapper">
              <button type="button" style={{
                alignSelf: "flex-end", backgroundColor: "transparent", border: "none", marginRight: "15px", fontSize: "2rem", color: "#002e6c", marginTop: "-15px", float: "right", fontWeight: "bold"
              }} onClick={() => { setShowPinEntry(false); }} data-dismiss="modal" aria-hidden="true">&times;</button>
              <strong className="reload-title text-yellow mb-3 d-block" style={{ fontSize: "20px" }}>{t("ENTER_YOUR_PIN")}</strong>
              <PinInput
                length={4}
                initialValue=""
                secret={true}
                focus={true}
                autoSelect={true}
                type="numeric"
                onChange={(value, index) => {
                  checkPinValue(value, index);
                }}
                inputMode="numeric"
                inputStyle={{ border: "0", margin: "0px 10px", width: "40px", height: "40px", backgroundColor: "transparent", borderBottom: "2px solid #002e6c" }}
                inputFocusStyle={{ borderBottom: "2px solid #FFC159" }}
                regexCriteria={/^[ 0-9_@./#&+-]*$/}
              />
              {/*<button type="submit" style={{ position: "relative", marginTop: "30px", width: "250px" }} className="btn btn-block btn-brand-gradient btn-sticky-bottom">{t("SUBMIT_TRANSACTION")}</button>*/}
              {/*<div className="invalid-feedback" style={{ fontSize: "12px", marginTop: "1rem" }}>{invalidPin && t("PIN_DOES_NOT_MATCH")}{!invalidPin && <span>&nbsp;</span>}</div>*/}

            </div>
          </form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default Deposit;
