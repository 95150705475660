import React, { useState } from "react";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import { Panel, PanelBody } from "../../components/panel/panel.jsx";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { withRouter } from "react-router-dom";
import { ApiKey, ApiUrl, LanguageKey, DEFAULT_PAGE_SIZE } from "../../util/Constant";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";
import { AsyncPaginate } from "react-select-async-paginate";
import ApiEngine from "../../util/ApiEngine";
import {
  stringIsNullOrEmpty,
} from "../../util/Util";
import { useDispatch } from "react-redux";
import { showMessage, setSaveAdminLog } from "../../redux/AppAction";
import { useHistory } from "react-router-dom";

const ManageProductGameRecordWithRewardNotTallyRecords = (props) => {
  let _dispatch = useDispatch();
  let _history = useHistory();
  const { t } = useTranslation(LanguageKey._PRIMARY);
  const _SELECTED_MEMBER_KEY = "selectedMemberKey";
  const [selectedMember, setSelectedMember] = useState("");
  const [labelUsername, setLabelUsername] = useState("All");
  const [selectedDate, setSelectedDate] = useState("");

  const [productGameRecordWithRewardNotTallyRecords, setProductGameRecordWithRewardNotTallyRecords] = useState([]);

  const GetProductGameRecordWithRewardNotTally = async () => {
      try {
        var apiUrl =
          ApiUrl._API_GET_PRODUCT_GAME_RECORD_WITH_REWARD_NOT_TALLY_RECORDS +
          "?SelectedDate="
          + selectedDate;

        if (!stringIsNullOrEmpty(selectedMember)) {
          apiUrl += "&userName=" + labelUsername;
        }

        var responseJson = await ApiEngine.get(apiUrl);
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          setProductGameRecordWithRewardNotTallyRecords(responseJson[ApiKey._API_DATA_KEY]);
        } else {
            throw responseJson[ApiKey._API_MESSAGE_KEY];
        }
    } catch (error) {
        _dispatch(showMessage(false, t(error)));
    }
  };

  let _GameProductRecordColumns = [
    {
      Header: "RewardDate",
      accessor: "rewardDate",
    },
    {
      Header: "Alias",
      accessor: "alias",
    },
    {
      Header: "Member Product Id",
      accessor: "memberProductId",
    },
    {
      Header: "Sum Valid Turnover",
      accessor: "sumValidTurnover",
    },
    {
      Header: "Reward Valid Turnover",
      accessor: "rewardValidTurnover",
    },
    {
      Header: "Difference Amount",
      accessor: "differenceAmt",
    },
  ];

  const handleEvent = (event, picker) => {
    setSelectedDate(moment(picker.startDate).format("YYYY-MM-DD"));
  };

  async function getMemberList(search, loadOptions, { page }) {
    var responseJson = await ApiEngine.get(
      `${ApiUrl._API_GET_USER_MEMBER_LIST}?&start=${
        (page - 1) * DEFAULT_PAGE_SIZE
      }&count=${DEFAULT_PAGE_SIZE}&keyword=${search}`
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        console.log(responseJson[ApiKey._API_DATA_KEY]);
      let data = responseJson[ApiKey._API_DATA_KEY]["data"];
      let formattedData = data.map((m) => {
        return { label: m.username, value: m.id };
      });

      return {
        options: formattedData,
        hasMore: formattedData.length == DEFAULT_PAGE_SIZE,
        additional: {
          page: page + 1,
        },
      };
    }
  }

  async function adminLog() {
    let params = {
      selectedDate: selectedDate
    };

    if (!stringIsNullOrEmpty(selectedMember)) {
      params["userName"] = labelUsername;
    }

    _dispatch(setSaveAdminLog("VIEW_PRODUCT_GAME_RECORD_WITH_REWARD_NOT_TALLY_RECORD", _history.location.pathname, params));
  }
  return (
    <div>
      <h1 className="page-header">{t("PRODUCT_GAME_RECORD_WITH_REWARD_NOT_TALLY_RECORDS")}</h1>
      <Panel>
        <PanelBody>
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-4">
                  <div
                    className="form-group"
                    style={{ display: "flex", width: "100%" }}
                  >
                    <div className="input-group" style={{ width: "100%" }}>
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="fa fa-calendar"></i>
                        </span>
                      </div>
                      <div style={{ width: "80%" }}>
                        <DateRangePicker
                          containerStyles={{ width: "100%" }}
                          singleDatePicker={true}
                          onApply={handleEvent}
                          minDate={moment('2022-12-22')}
                          alwaysShowCalendars={true}
                          locale={{ format: "YYYY-MM-DD" }}
                        >
                            <input
                                type="text"
                                className="form-control"
                                value={selectedDate}
                            />
                        </DateRangePicker>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                <div className="form-group">
                  {<AsyncPaginate
                    placeholder={labelUsername}
                    debounceTimeout={250}
                    loadOptions={getMemberList}
                    additional={{
                      page: 1,
                    }}
                    value={selectedMember}
                    onChange={(e) => {
                      if (!stringIsNullOrEmpty(e.value)) {
                        sessionStorage.setItem(_SELECTED_MEMBER_KEY, e.value);
                      } else {
                        sessionStorage.removeItem(_SELECTED_MEMBER_KEY);
                      }
                      setLabelUsername(e.label);
                      setSelectedMember(e.value);
                    }}
                  />}
                </div>
                </div>
                <div className="col-lg-3">
                  <button
                    type="button"
                    onClick={() => {
                      GetProductGameRecordWithRewardNotTally();
                      adminLog();
                    }}
                    className="btn btn-primary"
                  >
                    {t("SEARCH")}
                  </button>
                </div>
              </div>
              <hr />
              <ReactTable data={productGameRecordWithRewardNotTallyRecords} columns={_GameProductRecordColumns} />
            </div>
          </div>
        </PanelBody>
      </Panel>
    </div>
  );
};

export default withRouter(ManageProductGameRecordWithRewardNotTallyRecords);
