import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import { useHistory } from "react-router-dom";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import { stringIsNullOrEmpty, createFormBody } from "../../util/Util";
import { ApiKey, ApiUrl, LanguageKey } from "../../util/Constant";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { setBusy, setIdle, showMessage, setSaveAdminLog } from "../../redux/AppAction";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";
import { checkIfPermissionExist } from "../../util/PermissionChecker";

/// <summary>
/// Author : -
/// </summary>

const EditRole = (props) => {
  const { t } = useTranslation(LanguageKey._PRIMARY);
  let _history = useHistory();
  const _location = useLocation();
  const _dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState(true);
  const [accessRightOption, setAccessRightOption] = useState({});
  const [accessNumbering, setAccessNumbering] = useState({});
  const [roleId, setRoleId] = useState("");

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    init();
  }, []);

  /// <summary>
  /// Author : -
  /// </summary>
  async function init() {
    var apiUrl = ApiUrl._API_GET_ROLE_ACCESS_DATA;
    var accessOption = {};
    await fetch(apiUrl, {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          accessOption = responseJson[ApiKey._API_DATA_KEY]["data"];
          setAccessNumbering(responseJson[ApiKey._API_DATA_KEY]["numberingPair"]);
        }
      });

    if (_location.state) {
      var apiUrl = ApiUrl._API_GET_ROLE_BY_ID;
      apiUrl += "?roleId=" + _location.state["id"];
      await fetch(apiUrl, {
        method: ApiKey._API_GET,
        headers: {
          "Content-Type": ApiKey._API_FORM_URLENCODED,
          "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            Object.keys(accessOption).map((key, value) => {
              Object.keys(accessOption[key]).map((innerKey, innerValue) => {
                accessOption[key][innerKey] =
                  responseJson[ApiKey._API_DATA_KEY][
                  innerKey[0].toLowerCase() +
                  innerKey.substr(1).replace(/\s+/g, "")
                  ];
              });
            });
            setName(responseJson[ApiKey._API_DATA_KEY]["roleName"]);
            setDescription(responseJson[ApiKey._API_DATA_KEY]["description"]);
            setStatus(responseJson[ApiKey._API_DATA_KEY]["status"]);
            setRoleId(responseJson[ApiKey._API_DATA_KEY]["id"]);
          }
        });
    }
    setAccessRightOption(accessOption);
  }

  /// <summary>
  /// Author : -
  /// </summary>
  const submitForm = async (data, e) => {
    let params = {
      roleName: data.roleName,
      description: data.description,
      status: data.status,
    };

    Object.keys(data).map((key, value) => {
      params[key] = data[key];
    });

    if (!stringIsNullOrEmpty(roleId)) {
      params["id"] = roleId;
      _dispatch(setSaveAdminLog("EDIT_BO_ROLE", _history.location.pathname, params));
    } else {
      _dispatch(setSaveAdminLog("CREATE_BO_ROLE", _history.location.pathname, params));
    }

    let formBody = createFormBody(params);
    _dispatch(setBusy());

    fetch(ApiUrl._API_CREATE_OR_UPDATE_ROLE, {
      method: ApiKey._API_POST,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
      body: formBody,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        _dispatch(setIdle());

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          setRoleId(responseJson[ApiKey._API_DATA_KEY]["id"]);
        }

        _dispatch(
          showMessage(
            responseJson[ApiKey._API_SUCCESS_KEY],
            t(responseJson[ApiKey._API_MESSAGE_KEY])
          )
        );
      });
  };

  return (
    <div>
      <h1 className="page-header">
        {roleId ? t("EDIT_ROLE") : t("ADD_NEW_ROLE")}
        <NavigationButton history={_history} />
      </h1>
      <div className="row">
        <div className="col-xl-12">
          <Panel>
            <PanelBody>
              <form onSubmit={handleSubmit(submitForm)}>
                <h3>{t("ROLE_INFORMATION")}</h3>
                <div className="row">
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label>
                        <b>{t("ROLE_NAME")}</b>
                      </label>
                      <input
                        type="text"
                        name="roleName"
                        className="form-control m-b-5"
                        ref={register({
                          required: "PLEASE_ENTER_ROLE_NAME",
                        })}
                        placeholder={t("PLEASE_ENTER_ROLE_NAME")}
                        defaultValue={name}
                      />
                      {errors.roleName && (
                        <div className="invalid-feedback">
                          {t(errors.roleName.message)}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label>
                        <b>{t("DESCRIPTION")}</b>
                      </label>
                      <input
                        type="text"
                        name="description"
                        className="form-control m-b-5"
                        ref={register({
                          required: "PLEASE_ENTER_ROLE_DESCRIPTION",
                        })}
                        placeholder={t("PLEASE_ENTER_ROLE_DESCRIPTION")}
                        defaultValue={description}
                      />
                      {errors.description && (
                        <div className="invalid-feedback">
                          {t(errors.description.message)}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label>
                        <b>{t("STATUS")}</b>
                      </label>
                      <br />
                      <div className="switcher">
                        <input
                          type="checkbox"
                          name="status"
                          id="status"
                          onChange={(e) => setStatus(e.target.checked)}
                          value={true}
                          checked={status}
                          ref={register}
                        />
                        <label htmlFor="status"></label>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <h3>{t("FUNCTION_LIST")}</h3>
                <div className="row">
                  {Object.keys(accessRightOption).map((key, value) => {
                    return (
                      <div className="col-lg-4" key={value}>
                        <div className="card text-truncate mb-3">
                          <div className="card-header">
                            <b>{t(key.toUpperCase())}</b>
                          </div>
                          <div className="card-body">
                            <div className="table-responsive">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th className="text-nowrap">
                                      {t("PERMISSION")}
                                    </th>
                                    <th className="text-nowrap">
                                      {t("ACCESS_LEVEL")}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {Object.keys(accessRightOption[key]).map(
                                    (innerKey, innerValue) => {
                                      var accessString =
                                        "00" +
                                        accessRightOption[key][
                                          innerKey
                                        ].toString(2);
                                      accessString = accessString.substr(
                                        accessString.length - 2
                                      );
                                      var canWrite = checkIfPermissionExist(
                                        accessNumbering[innerKey],
                                        true
                                      );
                                      var canRead = checkIfPermissionExist(
                                        accessNumbering[innerKey],
                                        false
                                      );

                                      return (
                                        <tr key={innerValue}>
                                          <td>
                                            {" "}
                                            {t(
                                              innerKey
                                                .toUpperCase()
                                                .replace(/ /g, "_")
                                            )}
                                          </td>
                                          <td>
                                            <select
                                              defaultValue={
                                                accessRightOption[key][innerKey]
                                              }
                                              className="form-control"
                                              name={
                                                innerKey[0].toLowerCase() +
                                                innerKey
                                                  .substr(1)
                                                  .replace(/\s+/g, "")
                                              }
                                              ref={register}
                                            >
                                              {canWrite && canRead && (
                                                <option value="3">
                                                  {t("ALL")}
                                                </option>
                                              )}
                                              {canWrite && (
                                                <option value="2">
                                                  {t("CONTROL")}
                                                </option>
                                              )}
                                              {canRead && (
                                                <option value="1">
                                                  {t("VIEW")}
                                                </option>
                                              )}
                                              <option value="0">
                                                {t("NONE")}
                                              </option>
                                            </select>
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <hr />
                <div className="row">
                  <div className="col-lg-3">
                    <div className="form-group">
                      <button type="submit" className="btn btn-primary">
                        {t("SUBMIT")}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </PanelBody>
          </Panel>
        </div>
      </div>
    </div>
  );
};

export default withRouter(EditRole);