import React from 'react';
import { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Panel, PanelBody } from '../../components/panel/panel';
import { useForm } from "react-hook-form";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import { useHistory } from "react-router-dom";
import ApiEngine from '../../util/ApiEngine';
import { ApiKey, ApiUrl, LanguageKey } from '../../util/Constant';
import { useTranslation } from "react-i18next";
import { createFormBody } from '../../util/Util';
import { useDispatch } from 'react-redux';
import { showMessage } from '../../redux/AppAction';
import SweetAlert from "react-bootstrap-sweetalert";

const NoVT918Kiss = (props) => {
  let { t } = useTranslation(LanguageKey._PRIMARY);
  let _history = useHistory();
  let _dispatch = useDispatch();
  const [initCheck, setInitCheck] = useState(true);
  const [productUsername, setProductUsername] = useState("");
  const [amount, setAmount] = useState(0);
  const [winAmount, setWinAmount] = useState(0);
  const [showPreviewText, setShowPreviewText] = useState(false);
  const [previewText, setPreviewText] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { handleSubmit } = useForm();

  const submitPreview = async () => {
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_PRODUCT_BY_USERNAME + "?username=" + productUsername);
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY];
      let tempPreviewText = "INSERT INTO [dbo].[ProductGameRecord] \n([GameId],[BetId],[MemberProductId],[Status],[Amount],[WinAmount],[Date],[Remark],[Turnover],[UpdatedTime],[IsProcessed],[ValidTurnover]) \nVALUES \n";
      tempPreviewText += "(354, '918_" + productUsername + "_" + data["date"] + "', " + data["id"] + ", 1, " + amount + ", " + winAmount + ", '" + data["fullDate"] + "', '918Kiss Sum', 0, '" + data["fullDate"] + "', 1, 0)";
      setPreviewText(tempPreviewText);
      setShowPreviewText(true);
    }
  }

  function preSubmit() {
    setShowConfirmation(true);
  }

  const submitForm = async () => {
    let params = {
      "updateStr": previewText
    };
    let responseJson = await ApiEngine.post(ApiUrl._API_INSERT_918KISS_TICKET_WITH_NO_VT, createFormBody(params));
    _dispatch(showMessage(responseJson[ApiKey._API_SUCCESS_KEY], responseJson[ApiKey._API_MESSAGE_KEY]));
  }

  return (
    <div>
      <h1 className="page-header">
        {t("918KISS_NO_VT")}
        <NavigationButton history={_history} />
      </h1>
      <div className="row">
        <div className="col-xl-12">
          {initCheck && <Panel>
            <PanelBody>
              <form onSubmit={handleSubmit(preSubmit)}>
                <div className="col-lg-8">
                  <div className="form-group row m-b-15">
                    <label className="col-md-4 col-form-label">
                      <b>{t("MEMBER_PRODUCT_USERNAME")}</b>
                    </label>
                    <div className="col-md-7" style={{ display: "flex", lineHeight: "2.5" }}>
                      <div className="col-4">
                        <input
                          type="text"
                          className="form-control"
                          name="productUsername"
                          value={productUsername}
                          onChange={(e) => setProductUsername(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-8">
                  <div className="form-group row m-b-15">
                    <label className="col-md-4 col-form-label">
                      <b>{t("AMOUNT")}</b>
                    </label>
                    <div className="col-md-7" style={{ display: "flex", lineHeight: "2.5" }}>
                      <div className="col-4">
                        <input
                          type="number"
                          step="0.01"
                          className="form-control"
                          name="amount"
                          value={amount}
                          onChange={(e) => setAmount(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-8">
                  <div className="form-group row m-b-15">
                    <label className="col-md-4 col-form-label">
                      <b>{t("WIN_AMOUNT")}</b>
                    </label>
                    <div className="col-md-7" style={{ display: "flex", lineHeight: "2.5" }}>
                      <div className="col-4">
                        <input
                          type="number"
                          step="0.01"
                          className="form-control"
                          name="winAmount"
                          value={winAmount}
                          onChange={(e) => setWinAmount(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {showPreviewText && <div className="col-lg-8">
                  <div>
                    {previewText}
                  </div>
                </div>}
                <hr />
                <div className="row">
                  <div className="col-lg-3">
                    <div className="form-group">
                      <button type="button" className="btn btn-primary" onClick={() => submitPreview()}>
                        {t("SUBMIT") + " " + t("PREVIEW")}
                      </button>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="form-group">
                      <button type="submit" className="btn btn-primary">
                        {t("SUBMIT")}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </PanelBody>
          </Panel>}
        </div>
      </div>
      {showConfirmation && (
        <SweetAlert
          warning
          showCancel
          confirmBtnText={t("CONFIRM")}
          cancelBtnText={t("CANCEL")}
          confirmBtnBsStyle="danger"
          title={t("CONFIRM") + "?"}
          onConfirm={() => {
            submitForm();
            setShowConfirmation(false);
          }}
          onCancel={() => {
            setShowConfirmation(false);
          }}
        ></SweetAlert>
      )}
    </div>
  )
}

export default withRouter(NoVT918Kiss);