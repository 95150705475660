import React, { useEffect, useState } from "react";
import {
    useTable,
    usePagination,
    useSortBy,
    useFilters,
    useGroupBy,
    useExpanded,
    useRowSelect,
    useGlobalFilter,
} from "react-table";
import { LanguageKey, ApiKey, ApiUrl } from "../../../util/Constant";
import {
    stringIsNullOrEmpty,
    isObjectEmpty,
    numberWithCurrencyFormat,
} from "../../../util/Util";
import { useTranslation, Translation } from "react-i18next";
import { showMessage } from "../../../redux/AppAction";
import { useDispatch } from "react-redux";
import ApiEngine from "../../../util/ApiEngine";
import { useExportData } from "react-table-plugins";
import Papa from "papaparse";
import XLSX from "xlsx";
import FileSaver from "file-saver";

// Define a default UI for filtering
const DefaultColumnFilter = ({
    column: { filterValue, preFilteredRows, setFilter },
}) => {
    return (
        <input
            style={{ width: "100%" }}
            className="form-control"
            value={filterValue || ""}
            onChange={(e) => {
                setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
            }}
        />
    );
};

/// <summary>
/// A default UI for Global Filtering
/// </summary>
const GlobalFilter = ({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
}) => {
    return (
        <Translation ns={LanguageKey._PRIMARY}>
            {(t, { i18n }) => (
                <span style={{ margin: "5px" }}>
                    <input
                        style={{ width: "100%" }}
                        className="form-control"
                        value={globalFilter || ""}
                        onChange={(e) => {
                            setGlobalFilter(e.target.value || undefined);
                        }}
                        placeholder={t("SEARCH_HERE")}
                    />
                </span>
            )}
        </Translation>
    );
};

/// <summary>
/// table provided option for filter, global filter, minimum rows, and footer
/// </summary>
const MemberCreditTable = ({
    className = "",
    columns,
    data,
    filterable = false,
    renderFooter = false,
    renderOpening = false,
    renderClosing = false,
    globalFilterable = true,
    tableStyle,
    defaultSortBy,
    minRows = 0,
    getFilteredRecords,
    fetchUrl,
    checkUrl,
    showOverallInfo = false,
    postData,
    getOverallInfo,
    exportRequired = false,
    initialPageSize = 10,
    fetchedData = null,
}) => {
    const { t } = useTranslation(LanguageKey._PRIMARY);
    const [tableData, setTableData] = useState([]);
    const [processedPageCount, setProcessedPageCount] = useState(0);
    const [totalRecordCount, setTotalRecordCount] = useState(0);
    const [overallInfo, setOverallInfo] = useState({});
    const _dispatch = useDispatch();
    const [openingBalance, setOpeningBalance] = useState('');
    const [closingBalance, setClosingBalance] = useState('');

    const defaultColumn = React.useMemo(
        () => ({
            Filter: DefaultColumnFilter,
        }),
        []
    );

    ///default page size option for select
    const pageSizeOptions = React.useMemo(() => {
        return [10, 20, 30, 40, 50, 999, 9999];
    }, []);

    /// <summary>
    /// Author: -
    /// </summary>
    function getExportFileBlob({ columns, data, fileType, fileName }) {
        var response = null;
        if (!stringIsNullOrEmpty(fetchUrl)) {
            if (postData != null) {
                postData["start"] = 0;
                postData["start"] = totalRecordCount;

                response = ApiEngine.post(fetchUrl, postData);
            } else {
                fetchUrl += fetchUrl.indexOf("?") != -1 ? "&" : "?";

                response = ApiEngine.get(
                    fetchUrl + "start=" + 0 + "&count=" + totalRecordCount
                );
            }

            response.then(function (responseJson) {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    var allData =
                        responseJson[ApiKey._API_DATA_KEY][ApiKey._API_DATA_KEY];

                    if (fileType === "csv") {
                        const csvString = Papa.unparse(allData);
                        var csvData = new Blob([csvString], { type: "text/csv" });
                        FileSaver.saveAs(csvData, `${fileName}.csv`);
                    } else if (fileType === "xlsx") {
                        const header = columns.map((c) => c.id);
                        const headerNames = columns.map((c) => t(c.exportValue));

                        const compatibleData = allData.map((row) => {
                            const obj = {};
                            header.forEach((col, index) => {
                                obj[headerNames[index]] = row[col];
                            });
                            return obj;
                        });

                        let wb = XLSX.utils.book_new();
                        let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
                            headerNames,
                        });
                        XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
                        XLSX.writeFile(wb, `${fileName}.xlsx`);
                    }
                    return false;
                }
            });
        }
    }

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        footerGroups,
        OpeningGroup,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        preGlobalFilteredRows,
        setGlobalFilter,
        exportData,
        state: {
            globalFilter,
            pageIndex,
            pageSize,
            sortBy,
            groupBy,
            expanded,
            filters,
            selectedRowIds,
        },
    } = useTable(
        {
            columns,
            data: tableData,
            defaultColumn,
            disableMultiSort: true,
            initialState: {
                pageIndex: 0,
                pageSize: initialPageSize,
                sortBy: defaultSortBy != null ? defaultSortBy : [],
            },
            manualPagination: !stringIsNullOrEmpty(fetchUrl),
            pageCount: processedPageCount,
            autoResetPage: stringIsNullOrEmpty(fetchUrl),
            getExportFileBlob,
            autoResetPage: false,
        },
        useGlobalFilter,
        useFilters,
        useGroupBy,
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect,
        useExportData
    );

    /// used to get the filtered record
    useEffect(() => {
        if (getFilteredRecords != null) {
            getFilteredRecords({ page });
        }
    }, [globalFilter, filters, pageSize, pageIndex]);

    /// <summary>
    /// used to pass the overallInfo to front
    /// </summary>
    useEffect(() => {
        if (getOverallInfo != null) {
            getOverallInfo(overallInfo);
        }
    }, [overallInfo]);

    /// <summary>
    /// Author : -
    /// </summary>
    useEffect(() => {
        if (fetchUrl == null) {
            setTableData(data);
        }
    }, [data]);

    /// <summary>
    /// Author : -
    /// </summary>
    useEffect(() => {
        if (fetchUrl == null) {
            setProcessedPageCount(Math.ceil(tableData.length / pageSize));
        }
    }, [tableData]);

    /// <summary>
    /// Author : -
    /// used for triggering the fetch data function
    /// </summary>
    useEffect(() => {
        updateDisplay();
    }, [fetchUrl, pageSize, pageIndex]);

    /// <summary>
    /// Author : -
    /// </summary>
    async function updateDisplay() {
        if (!stringIsNullOrEmpty(fetchUrl)) {
            const startRow = pageSize * pageIndex;

            if (postData != null) {
                postData["start"] = startRow;
                postData["count"] = pageSize;

                var responseJson = await ApiEngine.post(fetchUrl, postData);
            } else {
                fetchUrl += fetchUrl.indexOf("?") != -1 ? "&" : "?";

                var responseJson = await ApiEngine.get(
                    fetchUrl + "start=" + startRow + "&count=" + pageSize
                );
            }

            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                setTableData(responseJson[ApiKey._API_DATA_KEY][ApiKey._API_DATA_KEY]);

                if (fetchedData != null) {
                    fetchedData(responseJson[ApiKey._API_DATA_KEY]);
                }

                if (renderOpening === true && renderClosing === true) {

                    var responseJs = await ApiEngine.get(checkUrl);
                    let sumOpening = 0;
                    let sumClosing = 0;
                    let sum = 0;
                    for (let x = 0; x < responseJs[ApiKey._API_DATA_KEY][ApiKey._API_DATA_KEY].length; x++) {
                        sumOpening += responseJs[ApiKey._API_DATA_KEY][ApiKey._API_DATA_KEY][x]['amount'];
                        
                    }
                    for (let i = 0; i < responseJson[ApiKey._API_DATA_KEY][ApiKey._API_DATA_KEY].length; i++) {
                        sum += responseJson[ApiKey._API_DATA_KEY][ApiKey._API_DATA_KEY][i]['amount'];
                    }
                    sumClosing = sumOpening - (-sum);

                    setOpeningBalance(sumOpening);
                    setClosingBalance(sumClosing);
                }

                let calculatedPageCount = Math.ceil(
                    responseJson[ApiKey._API_DATA_KEY]["totalCount"] / pageSize
                );
                setProcessedPageCount(calculatedPageCount);
                setTotalRecordCount(responseJson[ApiKey._API_DATA_KEY]["totalCount"]);
                setOverallInfo(responseJson[ApiKey._API_DATA_KEY]["overallInfo"] ?? {});

                if (calculatedPageCount < pageIndex) {
                    gotoPage(Math.max(calculatedPageCount - 1, 0));
                }
                if (responseJson[ApiKey._API_DATA_KEY]["totalCount"] < pageSize) {
                    gotoPage(0);
                }
            } else {
                _dispatch(
                    showMessage(
                        responseJson[ApiKey._API_SUCCESS_KEY],
                        responseJson[ApiKey._API_MESSAGE_KEY]
                    )
                );
            }
        } else {
            setProcessedPageCount(Math.ceil(tableData.length / pageSize));
        }
    }

    // Render the UI for your table
    return (
        <div>
            {!isObjectEmpty(overallInfo) && showOverallInfo && (
                <div className="row m-b-10">
                    {Object.keys(overallInfo).map((key, index) => {
                        return (
                            <div
                                key={index}
                                style={{
                                    fontSize: "16px",
                                    margin: "6px",
                                    boxShadow: "1px 1px 8px rgba(0,0,0,0.1)",
                                    padding: "5px 15px",
                                    marginRight: "10px",
                                }}
                            >
                                <b>
                                    {t(key)}:{" "}
                                    <span
                                        className={
                                            "text-" +
                                            (parseFloat(overallInfo[key]) >= 0 ? "success" : "danger")
                                        }
                                    >
                                        {numberWithCurrencyFormat(parseFloat(overallInfo[key]), 3)}
                                    </span>
                                </b>
                            </div>
                        );
                    })}
                </div>
            )}
            {totalRecordCount > 0 && exportRequired && (
                <div className="row rt-export-div">
                    <div className="col-lg-12">
                        <button
                            className="btn btn-primary"
                            onClick={() => {
                                exportData("xlsx", true);
                            }}
                        >
                            <i className="fa fa-file-excel"></i>
                        </button>
                        <button
                            className="btn btn-primary"
                            onClick={() => {
                                exportData("csv", true);
                            }}
                        >
                            <i className="fas fa-file-csv"></i>
                        </button>
                    </div>
                </div>
            )}
            {globalFilterable && (
                <div className="row">
                    <div className="col-lg-9"></div>
                    <div className="col-lg-3">
                        <GlobalFilter
                            preGlobalFilteredRows={preGlobalFilteredRows}
                            globalFilter={globalFilter}
                            setGlobalFilter={setGlobalFilter}
                        />
                    </div>
                </div>
            )}
            <div className={"table-responsive" + className}>
                <table
                    {...getTableProps()}
                    className="table table-bordered table-hover"
                    style={{ ...tableStyle }}
                >
                    <thead style={{ overflow: "auto" }}>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th {...column.getHeaderProps()}>
                                        <div>
                                            <span {...column.getSortByToggleProps()}>
                                                {t(column.render("Header"))}
                                                {column.disableSortBy ==
                                                    true ? null : column.isSorted ? (
                                                        column.isSortedDesc ? (
                                                            <i
                                                                className="fas fa-sort-down"
                                                                style={{ marginLeft: "3px" }}
                                                            ></i>
                                                        ) : (
                                                            <i
                                                                className="fas fa-sort-up"
                                                                style={{ marginLeft: "3px" }}
                                                            ></i>
                                                        )
                                                    ) : (
                                                    <i
                                                        className="fas fa-sort"
                                                        style={{ marginLeft: "3px" }}
                                                    ></i>
                                                )}
                                            </span>
                                        </div>
                                        {/* Render the columns filter UI */}
                                        <div>
                                            {filterable && column.canFilter
                                                ? column.render("Filter")
                                                : null}
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    {renderOpening && (
                        <tbody>
                            <tr><th colSpan='2'>
                                <b>Opening Balance</b>


                            </th>
                                <th><b>
                                    {
                                        isNaN(parseFloat(openingBalance)) ?
                                            " "
                                            :
                                            numberWithCurrencyFormat(parseFloat(openingBalance), 3)
                                    }
                                   


                                </b></th>
                            </tr>

                        </tbody>
                    )}

                    <tbody {...getTableBodyProps()}>
                        {page.map((row, index) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()} style={{ backgroundColor: index % 2 === 0 ? "#f6f6f6" : "transparent" }}>
                                    {row.cells.map((cell) => {
                                        return (
                                            <td
                                                {...cell.getCellProps()}
                                                style={{
                                                    width: cell.column.width,
                                                    minWidth: cell.column.minWidth,
                                                    ...cell.column.style,
                                                }}
                                            >
                                                {cell.render("Cell")}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                        {page.length === 0 ? (
                            <tr>
                                <td colSpan="100%" style={{ textAlign: "center" }}>
                                    {t("NO_DATA_FOUND")}
                                </td>
                            </tr>
                        ) : (
                            minRows > 0 &&
                            [
                                ...Array(
                                    pageSize <= minRows
                                        ? pageSize - page.length
                                        : Math.max(0, minRows - page.length)
                                ),
                            ].map((x, i) => (
                                <tr>
                                    {[...Array(page[0].cells.length)].map((a, b) => (
                                        <td style={{ textAlign: "center", height: "2rem" }}></td>
                                    ))}
                                </tr>
                            ))
                        )}
                    </tbody>
                    {renderFooter && (
                        <tfoot>
                            {footerGroups.map((group) => (
                                <tr {...group.getFooterGroupProps()}>
                                    {group.headers.map((column) => (
                                        <td {...column.getFooterProps()}>
                                            {column.render("Footer")}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tfoot>
                    )}
                    {renderClosing && (
                        <tbody>
                            <tr><th colSpan='2'>
                                <b>Closing Balance</b>


                            </th>
                                <th><b>
                                    {
                                        isNaN(parseFloat(openingBalance)) ?
                                            " "
                                            :
                                            numberWithCurrencyFormat(parseFloat(closingBalance), 3)
                                    }
                                </b></th>
                            </tr>

                        </tbody>
                    )}
                </table>
            </div>
            <div className="pagination" style={{ display: "unset" }}>
                <button
                    type="button"
                    onClick={() => gotoPage(0)}
                    disabled={!canPreviousPage}
                >
                    {"<<"}
                </button>{" "}
                <button
                    type="button"
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                >
                    {"<"}
                </button>{" "}
                <button
                    type="button"
                    onClick={() => nextPage()}
                    disabled={!canNextPage}
                >
                    {">"}
                </button>{" "}
                <button
                    type="button"
                    onClick={() => gotoPage(processedPageCount - 1)}
                    disabled={!canNextPage}
                >
                    {">>"}
                </button>{" "}
                <div style={{ float: "right" }}>
                    <span>
                        {t("PAGE")}{" "}
                        <strong>
                            {pageIndex + 1} of {pageOptions.length === 0 ? 1 : pageOptions.length}
                        </strong>{" "}
                    </span>
                    <span>
                        | {t("GO_TO_PAGE")}:{" "}
                        <input
                            type="number"
                            defaultValue={pageIndex + 1}
                            onChange={(e) => {
                                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                                gotoPage(page);
                            }}
                            style={{ width: "100px" }}
                        />
                    </span>{" "}
                    <select
                        value={pageSize}
                        onChange={(e) => setPageSize(Number(e.target.value))}
                    >
                        {pageSizeOptions.map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                                Show {pageSize}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        </div>
    );
};

export default MemberCreditTable;
