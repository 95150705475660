import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { Panel, PanelBody } from '../../components/panel/panel';
import Select from "react-select";
import ApiEngine from '../../util/ApiEngine';
import { ApiKey, ApiUrl, LanguageKey } from '../../util/Constant';
import { createFormBody } from '../../util/Util';
import { setSaveAdminLog, showMessage } from '../../redux/AppAction';
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";


const ManageNewGame = (props) => {
  const _dispatch = useDispatch();
  let _history = useHistory();
  const _location = useLocation();
  const { t, i18n } = useTranslation(LanguageKey._PRIMARY);
  const [gameListString, setGameListString] = useState("");
  const [textareaString, setTextareaString] = useState("");
  const [masterProductId, setMasterProductId] = useState("");
  const [productTitle, SetProductTitle] = useState("");
  const [PostInit, SetPostInit] = useState(true);
  const [inputIsNewGame, SetInputIsNewGame] = useState(true);
  const [inputIsRecommended, SetInputIsRecommended] = useState(false);


  async function init() {

    if (_location.state) {
      var apiUrl = ApiUrl._API_GET_PRODUCT_GAME_BY_ACCOUNT_PRODUCT;
      apiUrl += "?accountProductId=" + _location.state.id;
      let responseJson = await ApiEngine.get(apiUrl);


      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        let text = "";
        responseJson[ApiKey._API_DATA_KEY].forEach(function (item) {
          //text += item.gameName + "\n";
          setMasterProductId(item.product.id);
          SetProductTitle(item.product.productName);
        }
        );
        //setTextareaString(text.trimEnd());                            //If required display all
        setGameListString(text.trimEnd());
      }

    }

  }

  //useEffect(() => {
  //  console.log(textareaString);
  //}, [textareaString]);

  useEffect(() => {
    init();
  }, []);

  const updateNewGame = async () => {
    console.log(inputIsNewGame);
    console.log(inputIsRecommended);
    let params = {};
    params = {
      "gameNameList": gameListString,
      //"inputIsNewGame": inputIsNewGame,
      //"inputIsRecommended": inputIsRecommended,
    };
    _dispatch(setSaveAdminLog("MANAGE_VENDOR_NEW_GAME", _history.location.pathname, params));
    let responseJson = await ApiEngine.post(ApiUrl._API_MANAGE_NEW_GAME + "?masterProductId=" + masterProductId, createFormBody(params));
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      _dispatch(showMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
    }
  }


  return (
    <div>
      <div className="row">
        <div className="col-xl-12">
          <h1 className="page-header">{t("Manage " + productTitle + " New Game")}</h1>
          <a className="btn btn-primary" href={'/product/productGameList?productId=' + masterProductId} target='_blank'>
            {t("View Game List")}    </a>
          <br />
          <br />
          <Panel>
            <PanelBody>
              <div className="col-lg-8">
                <div className="form-group row m-b-15">                
                  <label className="col-md-4 col-form-label">                   
                    <b>{t("ENTER_GAME_NAME")}</b>
                  </label>
                  {/*<label className="col-md-5 col-form-label">*/}
                  {/*  <b>{t("SET_TYPE")}</b>*/}
                  {/*</label>*/}
                  <div className="col-4">
                    <textarea
                      className="form-control"
                      rows="10"
                      style={{
                        marginTop: "10px"
                      }}
                      onChange={(e) => setGameListString(e.target.value)}
                      defaultValue={textareaString}
                    >
                    </textarea>
                  </div>

                  {/*<div className="col-5">*/}
                  {/*  <table>*/}
                  {/*    <tr>*/}
                  {/*      <td>*/}
                  {/*        <input type="Checkbox" defaultChecked="true" onChange={(e) => e.target.checked === true ? SetInputIsNewGame(true) : SetInputIsNewGame(false)}></input>*/}
                  {/*      </td>*/}
                  {/*      <td>*/}
                  {/*        <label>{"Is New Game"}</label>*/}
                  {/*      </td>*/}
                  {/*    </tr>*/}
                  {/*    <tr>*/}
                  {/*      <td>*/}
                  {/*        <input type="Checkbox" onChange={(e) => handleChangeRecommended(e)}></input>*/}
                  {/*        <input type="Checkbox" onChange={(e) => e.target.checked === true ? SetInputIsRecommended(true) : SetInputIsRecommended(false)}></input>*/}

                  {/*      </td>*/}
                  {/*      <td>*/}
                  {/*        <label>{"Is Recommended"}</label>*/}
                  {/*      </td>*/}
                  {/*    </tr>*/}
                  {/*  </table>*/}
                    
                  {/*</div>*/}
               
                  <div>
                  </div>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-lg-3">
                  <div className="form-group">
                    <button type="button" className="btn btn-primary" onClick={() => updateNewGame()}>
                      {t("SUBMIT")}
                    </button>
                  </div>
                </div>
              </div>
            </PanelBody>
          </Panel>
        </div>
      </div>
    </div>
  )
}

export default ManageNewGame;