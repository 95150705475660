import React, { useState, useEffect } from "react";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import { useHistory } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import {
    ApiKey,
    ApiUrl,
    AccessRight,
    WebUrl,
    LanguageKey,
    DEFAULT_PAGE_SIZE,
} from "../../util/Constant";
import { showMessage } from "../../redux/AppAction";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ApiEngine from "../../util/ApiEngine";
import moment from 'moment';

/// <summary>
/// Author : -
/// </summary>
const TodayNewMembers = (props) => {
    const { t } = useTranslation(LanguageKey._PRIMARY);
    let _history = useHistory();

    const _dispatch = useDispatch();
    const [tableData, setTableData] = useState([]);

    let _tableColumns = [
        {
            Header: "USERNAME",
            accessor: "username",
            minWidth: 100,
        },
        {
            Header: "UPLINE_USERNAME",
            accessor: "uplineUsername",
            minWidth: 100,
        },
        {
            Header: "FULLNAME",
            accessor: "userFullName",
            minWidth: 100,
        },
        {
            Header: "HAS_DEPOSIT",
            accessor: "hasDeposit",
            minWidth: 100,
            Cell: ({ row }) => {
                return (
                    <div style={{textAlign: "center", margin:"18px"}}>{row.original.hasDeposit === true ? <i className="icon-green-tick fa fa-check"></i> : ""}</div>
                )
            }
        },
        {
            Header: "EMAIL",
            accessor: "email",
            minWidth: 100,
        },
        {
            Header: "PHONE",
            accessor: "phoneNumber",
            minWidth: 100,
        },
        {
            Header: "CREATEDTIME",
            accessor: "createdTime",
            minWidth: 100,
            Cell: ({ row }) => {
                return (
                    <div>{moment(row.original.createdTime).add(8, 'hours').format('YYYY-MM-DD HH:mm:ss')}</div>
                )
            }
        }
    ];

    useEffect(() => {
        getNewMemberList();
    }, []);

    const getNewMemberList = async () => {
        let apiUrl = ApiUrl._API_GET_NEW_MEMBERS_LIST_UNDER_MAIN_ACCOUNT;
        var responseJson = await ApiEngine.get(apiUrl);
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            let data = responseJson[ApiKey._API_DATA_KEY];
            setTableData(data);
        }
    }

    return (
        <div>
            <h1 className="page-header">
                {t("NEW_MEMBERS_7_DAYS")}
            </h1>
            <Panel>
                <div className="row">
                    <div className="col-lg-12">
                        <PanelBody>
                            <ReactTable
                                data={tableData}
                                columns={_tableColumns}
                                filterable
                            />
                        </PanelBody>
                    </div>
                </div>
            </Panel>
        </div>
    );
};

export default TodayNewMembers;
