import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../components/panel/panel.jsx";
import {
  ApiUrl,
  ApiKey,
  TransactionType,
  TransactionStatus,
  LanguageKey,
  Role,
  AccessRight
} from "../../util/Constant.js";
import { numberWithCommas, stringIsNullOrEmpty, numberWithCurrencyFormat } from "../../util/Util.js";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";
import ApiEngine from "../../util/ApiEngine.js";
import { showMessage } from "../../redux/AppAction.js";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { mdiAccount, mdiFileCheck, mdiDebugStepInto, mdiDebugStepOut  } from '@mdi/js';
import Icon from '@mdi/react';
import { checkIfPermissionExist } from "../../util/PermissionChecker.js";
import { mdiStar } from '@mdi/js';

const DashboardV2 = (props) => {
  var _dispatch = useDispatch();
  const { t } = useTranslation(LanguageKey._PRIMARY);
  const [totalPending, setTotalPending] = useState(0);
  const [totalPendingWithdrawal, setTotalPendingWithdrawal] = useState(0);
  const [totalPendingDeposit, setTotalPendingDeposit] = useState(0);
  
  const [totalDeposit, setTotalDeposit] = useState(0);
  const [totalDepositAmount, setTotalDepositAmount] = useState(0);
  const [totalDepositPlayers, setTotalDepositPlayers] = useState(0);
  const [totalWithdrawal, setTotalWithdrawal] = useState(0);
  const [totalWithdrawalAmount, setTotalWithdrawalAmount] = useState(0);
  const [totalWithdrawalPlayers, setTotalWithdrawalPlayers] = useState(0);
  const [totalMember, setTotalMember] = useState(0);
  const [totalNewMember, setTotalNewMember] = useState(0);
  const [totalDepositedNewMember, setTotalDepositedNewMember] = useState(0);

  const [instantPaySetting, setInstantPaySetting] = useState([]);
  const [rejectReason, setRejectReason] = useState([]);
  const [topWithdrawal, setTopWithdrawal] = useState([]);
  const [topDeposit, setTopDeposit] = useState([]);
  const [topWinners, setTopWinners] = useState([]);
  const [topLosers, setTopLosers] = useState([]);
  const [topDepositCount, setTopDepositCount] = useState(30);
  const [topWithdrawalCount, setWithdrawalCount] = useState(30);
  const [topWinnersCount, setTopWinnersCount] = useState(30);
  const [topLosersCount, setTopLosersCount] = useState(30);
  let role1IsAllowed = checkIfPermissionExist(
    AccessRight._DASHBOARD_DETAILS_PERMISSION,
    true
  );

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    init();
    setInterval(init, 60000);
    getTopBetters(1, topWinnersCount);
    getTopBetters(2, topLosersCount);
    getTopTransaction(TransactionType._DEPOSIT, topDepositCount);
    getTopTransaction(TransactionType._WITHDRAWAL, topWithdrawalCount);
  }, []);

  const getTopBetters = async (category, value) => {
    await fetch(ApiUrl._API_GET_DASHBOARD_TOP_WINNERS_AND_LOSERS + "?count=" + value, {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          let data = responseJson[ApiKey._API_DATA_KEY];
          let tempWinners = [];
          let tempLosers = [];
          if (category === 1) {
            data["winners"].map((record, index) => {
              if (record['winning'] >= 0) {
                tempWinners.push(record);
              }
            })
            setTopWinners(tempWinners);
          }
          else {
            data["losers"].map((record, index) => {
              if (record['winning'] < 0) {
                tempLosers.push(record);
              }
            })
            setTopLosers(tempLosers);
          }
        }
      });
  }

  const getTopTransaction = async (category, value) => {
    await fetch(
      ApiUrl._API_GET_DASHBOARD_TOP_MEMBER_TRANSACTION + "?count=" + value,
      {
        method: ApiKey._API_GET,
        headers: {
          "Content-Type": ApiKey._API_APPLICATION_JSON,
          "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
        },
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          let data = responseJson[ApiKey._API_DATA_KEY];
          if (category === TransactionType._DEPOSIT) {
            setTopDeposit(data["deposit"]);
          } else {
            setTopWithdrawal(data["withdrawal"]);
          }
        }
      });
  }

  const getDashbordDisplay = async () => {
    await fetch(ApiUrl._API_GET_DASHBOARD_DISPLAY, {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          if (!stringIsNullOrEmpty(responseJson[ApiKey._API_DATA_KEY])) {
            let data = responseJson[ApiKey._API_DATA_KEY];
            setTotalDeposit(data['totalDeposit']);
            setTotalDepositAmount(data['totalDepositAmount']);
            setTotalDepositPlayers(data['totalDepositPlayers']);

            setTotalWithdrawal(data['totalWithdrawal']);
            setTotalWithdrawalAmount(data['totalWithdrawalAmount']);
            setTotalWithdrawalPlayers(data['totalWithdrawalPlayers']);

            setTotalPending(data['totalPending']);
            setTotalPendingWithdrawal(data['totalPendingWithdrawal']);
            setTotalPendingDeposit(data['totalPendingDeposit']);

            setTotalMember(data['totalMember']);
            setTotalNewMember(data['totalNewMember']);
            setTotalDepositedNewMember(data['totalDepositedNewMember']);

          }
        }
      });
  }

  const getUserInstantPaySetting = async () => {
    await fetch(ApiUrl._API_GET_USER_INSTANT_PAY_SETTING + "?all=true", {
      method: ApiKey._API_GET_USER_INSTANT_PAY_SETTING,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          setInstantPaySetting(responseJson[ApiKey._API_DATA_KEY]);
        }
      });
  }

  const getUserRejectCode = async () => {
    await fetch(ApiUrl._API_GET_USER_REJECT_CODE + "?all=true", {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          setRejectReason(responseJson[ApiKey._API_DATA_KEY]);
        }
      });
  }


  const init = async () => {
    getDashbordDisplay();
    getUserInstantPaySetting();
    getUserRejectCode();
  };

  

  return (
    <div>
      <ol className="breadcrumb float-xl-right">
        <li className="breadcrumb-item">
          <Link to="/dashboard">{t("HOME")}</Link>
        </li>
        <li className="breadcrumb-item active">{t("DASHBOARD")}</li>
      </ol>
      <h1 className="page-header">{t("DASHBOARD")}</h1>

      <div className="row" style={{ justifyContent: "space-between", margin: "0 20px" }}>
        <div className={"col-xl-" + (role1IsAllowed ? "2" : "12") + " col-md-" + (role1IsAllowed ? "4" : "12")}>
          <div className="widget widget-stats bg-blue">
            <div className="stats-icon">
              <i className="fa fa-hand-holding-usd"></i>
            </div>
            <div className="stats-info">
              <div className="header-wrapper" style={{ display: "flex" }}>
                <h4>{t("PENDING_TRANSACTIONS")}</h4>
                <div className="right-side-wrapper">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div className="total-deposit-player">
                      <Icon path={mdiDebugStepInto}
                        size={1}
                        color={"white"}
                      />
                      <span className="tooltip-text left">{t("Deposit")}</span>
                    </div>
                    <div style={{ margin: "0 5px", color: "white", fontSize: "15px" }}>{totalPendingDeposit}</div>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div className="total-deposit-approved">
                      <Icon path={mdiDebugStepOut}
                        size={1}
                        color={"white"}
                      />
                      <span className="tooltip-text right">{t("Withdrawal")}</span>
                    </div>
                    <div style={{ margin: "0 5px", color: "white", fontSize: "15px" }}>{totalPendingWithdrawal}</div>
                  </div>
                </div>
              </div>
              <p>{numberWithCommas(totalPending)}</p>
            </div>
            <div className="stats-link">
              <Link to="/transaction/transactionenquiry">
                {t("VIEW_DETAIL")}{" "}
                <i className="fa fa-arrow-alt-circle-right"></i>
              </Link>
            </div>
          </div>
        </div>
        {role1IsAllowed ? <div className="col-xl-2 col-md-4">
          <div className="widget widget-stats bg-green">
            <div className="stats-icon">
              <i className="fa fa-money-bill"></i>
            </div>
            <div className="stats-info">
              <div className="header-wrapper" style={{ display: "flex" }}>
                <h4>{t("TOTAL_DEPOSIT")}</h4>
                <div className="right-side-wrapper">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div className="total-deposit-player">
                      <Icon path={mdiAccount}
                        size={1}
                        color={"white"}
                      />
                      <span className="tooltip-text left">{t("TOTAL_DEPOSIT_PLAYERS")}</span>
                    </div>
                    <div style={{ margin: "0 5px", color: "white", fontSize: "15px" }}>{totalDepositPlayers}</div>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div className="total-deposit-approved">
                      <Icon path={mdiFileCheck}
                        size={1}
                        color={"white"}
                      />
                      <span className="tooltip-text right">{t("TOTAL_DEPOSIT_APPROVED")}</span>
                    </div>
                    <div style={{ margin: "0 5px", color: "white", fontSize: "15px" }}>{totalDeposit}</div>
                  </div>
                </div>
              </div>
              <p>{numberWithCurrencyFormat(totalDepositAmount)}</p>
            </div>
            <div className="stats-link">
              <Link to="/transaction/deposit">
                {t("VIEW_DETAIL")}{" "}
                <i className="fa fa-arrow-alt-circle-right"></i>
              </Link>
            </div>
          </div>
        </div> : <></>}
        {role1IsAllowed ? <div className="col-xl-2 col-md-4">
          <div className="widget widget-stats bg-red">
            <div className="stats-icon">
              <i className="fa fa-chart-bar"></i>
            </div>
            <div className="stats-info">
              <div className="header-wrapper" style={{ display: "flex" }}>
                <h4>{t("TOTAL_WITHDRAWAL")}</h4>
                <div className="right-side-wrapper">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div className="total-withdrawal-player">
                      <Icon path={mdiAccount}
                        size={1}
                        color={"white"}
                      />
                      <span className="tooltip-text left">{t("TOTAL_WITHDRAWAL_PLAYERS")}</span>
                    </div>
                    <div style={{ margin: "0 5px", color: "white", fontSize: "15px" }}>{totalWithdrawalPlayers}</div>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div className="total-withdrawal-approved">
                      <Icon path={mdiFileCheck}
                        size={1}
                        color={"white"}
                      />
                      <span className="tooltip-text right">{t("TOTAL_WITHDRAWAL_APPROVED")}</span>
                    </div>
                    <div style={{ margin: "0 5px", color: "white", fontSize: "15px" }}>{totalWithdrawal}</div>
                  </div>
                </div>
              </div>
              <p>{totalWithdrawal > 0 ? "-" : ""}{numberWithCurrencyFormat(totalWithdrawalAmount)}</p>
            </div>
            <div className="stats-link">
              <Link to="/transaction/withdrawal">
                {t("VIEW_DETAIL")}{" "}
                <i className="fa fa-arrow-alt-circle-right"></i>
              </Link>
            </div>
          </div>
        </div> : <></>}
        {role1IsAllowed ? <div className="col-xl-2 col-md-6">
          <div className="widget widget-stats bg-custom-yellow">
            <div className="stats-icon">
              <i className="fa fa-solid fa-users"></i>
            </div>
            <div className="stats-info">
              <h4>{t("TOTAL_MEMBERS_REGISTERED")}</h4>
              <p>{totalMember}</p>
            </div>
            <div className="stats-link">
              <Link to="/account/manageMember">
                {t("VIEW_DETAIL")}{" "}
                <i className="fa fa-arrow-alt-circle-right"></i>
              </Link>
            </div>
          </div>
        </div> : <></>}
        {role1IsAllowed ? <div className="col-xl-2 col-md-6">
          <div className="widget widget-stats bg-custom-purple">
            <div className="stats-icon">
              <i className="fa fa-solid fa-users"></i>
            </div>
            <div className="stats-info">
              <h4>{t("NEW_MEMBERS_IN_7_DAYS / DEPOSITED_MEMBER")}</h4>
              <p>{totalNewMember} / {totalDepositedNewMember}</p>
            </div>
            <div className="stats-link">
              <Link to="/account/TodayNewMembers">
                {t("VIEW_DETAIL")}{" "}
                <i className="fa fa-arrow-alt-circle-right"></i>
              </Link>
            </div>
          </div>
        </div> : <></>}
      </div>
      {role1IsAllowed ? <div className="row">
        <div className="col-xl-12">
          <div className="row">
            <div className="col-xl-3">
              <Panel>
                <PanelHeader>{t("TODAY_TOP")}<input className="desktop-top-bettor" type="number" step="1" min={0} max={30} defaultValue={topDepositCount}
                  value={topDepositCount}
                  onChange={(e) => {
                    let { value, min, max } = e.target;
                    value = Math.max(Number(min), Math.min(Number(max), Number(value)));
                    setTopDepositCount(value);
                    if (e.target.value > 0 && e.target.value <= 30) {
                      getTopTransaction(TransactionType._DEPOSIT, e.target.value)
                    }
                    else {
                      e.preventDefault();
                      return false;
                    }
                  }} />{t("DEPOSIT")}</PanelHeader>
                <PanelBody className="p-0">
                  <table className="table table-valign-middle mb-0 dashboard-table">
                    <thead>
                      {topDeposit.length > 0 ? (
                        topDeposit.map((deposit, index) => {
                          return (
                            <tr key={"top-deposit-" + index}>
                              <td>{deposit.userFullName + " (" + deposit.userName + ") "}

                                {deposit.numberOfTransaction > 0 && deposit.numberOfTransaction < 10 ? (<Icon path={mdiStar} size={0.8} />)
                                  : deposit.numberOfTransaction >= 10 && deposit.numberOfTransaction < 50 ? (<React.Fragment><Icon path={mdiStar} size={0.8} /><Icon path={mdiStar} size={0.8} /></React.Fragment>)
                                    : deposit.numberOfTransaction >= 50 ? (<React.Fragment><Icon path={mdiStar} size={0.8} /><Icon path={mdiStar} size={0.8} /><Icon path={mdiStar} size={0.8} /></React.Fragment>) : null}
                              </td>
                              <td className="text-align-right">
                                {numberWithCurrencyFormat(deposit.totalTransaction)}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="100%" style={{ textAlign: "center" }}>
                            {t("NO_RECORD")}
                          </td>
                        </tr>
                      )}
                    </thead>
                  </table>
                </PanelBody>
              </Panel>
            </div>
            <div className="col-xl-3">
              <Panel>
                <PanelHeader>{t("TODAY_TOP")}<input className="desktop-top-bettor" type="number" step="1" min={0} max={30} defaultValue={topWithdrawalCount}
                  value={topWithdrawalCount}
                  onChange={(e) => {
                    let { value, min, max } = e.target;
                    value = Math.max(Number(min), Math.min(Number(max), Number(value)));
                    setWithdrawalCount(value);
                    if (e.target.value > 0 && e.target.value <= 30) {
                      getTopTransaction(TransactionType._WITHDRAWAL, e.target.value)
                    }
                    else {
                      e.preventDefault();
                      return false;
                    }
                  }} />{t("WITHDRAWAL")}</PanelHeader>
                <PanelBody className="p-0">
                  <table className="table table-valign-middle mb-0 dashboard-table">
                    <thead>
                      {topWithdrawal.length > 0 ? (
                        topWithdrawal.map((withdrawal, index) => {
                          return (
                            <tr key={"top-withdrawal-" + index}>
                              <td>{withdrawal.userFullName + " (" + withdrawal.userName + ") "}</td>
                              <td className="text-align-right">
                                {numberWithCurrencyFormat(withdrawal.totalTransaction)}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="100%" style={{ textAlign: "center" }}>
                            {t("NO_RECORD")}
                          </td>
                        </tr>
                      )}
                    </thead>
                  </table>
                </PanelBody>
              </Panel>
            </div>
            <div className="col-xl-3">
              <Panel>
                <PanelHeader>{t("TODAY_TOP")}<input className="desktop-top-bettor" type="number" step="1" min={0} max={30} defaultValue={topWinnersCount}
                  value={topWinnersCount}
                  onChange={(e) => {
                    let { value, min, max } = e.target;
                    value = Math.max(Number(min), Math.min(Number(max), Number(value)));
                    setTopWinnersCount(value);
                    if (e.target.value > 0 && e.target.value <= 30) {
                      getTopBetters(1, e.target.value)
                    }
                    else {
                      e.preventDefault();
                      return false;
                    }
                  }} />{t("WINNER")}</PanelHeader>
                <PanelBody className="p-0">
                  <table className="table table-valign-middle mb-0 dashboard-table">
                    <thead>
                      {topWinners.length > 0 ? (
                        topWinners.map((winners, index) => {
                          return (
                            <tr key={"top-winner-" + index}>
                              <td>{winners.playerUserName}</td>
                              <td className="text-align-right">
                                {numberWithCurrencyFormat(winners.winning, 2, true)}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="100%" style={{ textAlign: "center" }}>
                            {t("NO_RECORD")}
                          </td>
                        </tr>
                      )}
                    </thead>
                  </table>
                </PanelBody>
              </Panel>
            </div>
            <div className="col-xl-3">
              <Panel>
                <PanelHeader>{t("TODAY_TOP")}<input className="desktop-top-bettor" type="number" step="1" min={0} max={30} defaultValue={topLosersCount}
                  value={topLosersCount}
                  onChange={(e) => {
                    let { value, min, max } = e.target;
                    value = Math.max(Number(min), Math.min(Number(max), Number(value)));
                    setTopLosersCount(value);
                    if (e.target.value > 0 && e.target.value <= 30) {
                      getTopBetters(2, e.target.value)
                    }
                    else {
                      e.preventDefault();
                      return false;
                    }
                  }} />{t("LOSERS")}</PanelHeader>
                <PanelBody className="p-0">
                  <table className="table table-valign-middle mb-0 dashboard-table">
                    <thead>
                      {topLosers.length > 0 ? (
                        topLosers.map((losers, index) => {
                          return (
                            <tr key={"top-loser-" + index}>
                              <td>{losers.playerUserName}</td>
                              <td className="text-align-right">
                                {numberWithCurrencyFormat(losers.winning, 2, true)}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="100%" style={{ textAlign: "center" }}>
                            {t("NO_RECORD")}
                          </td>
                        </tr>
                      )}
                    </thead>
                  </table>
                </PanelBody>
              </Panel>
            </div>
            <div className="col-xl-4">
              <Panel>
                <PanelHeader>{t("INSTANT_PAY_SETTINGS")}</PanelHeader>
                <PanelBody className="p-0">
                  <div className="table-responsive">
                    <table className="table table-valign-middle mb-0">
                      <thead>
                        <tr>
                          <th></th>
                          <th>{t("NAME")}</th>
                          <th>{t("STATUS")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {instantPaySetting.length > 0 ? (
                          instantPaySetting.map((setting, index) => {
                            return (
                              <tr key={"instant-pay-" + index}>
                                <td style={{ textAlign: "center" }}>
                                  {!stringIsNullOrEmpty(setting.image) ? (
                                    <img
                                      className="bank-icon"
                                      style={{ objectFit: "contain" }}
                                      src={setting.image}
                                    />
                                  ) : (
                                    <span> - </span>
                                  )}
                                </td>
                                <td>{setting.instantPayGatewayString}</td>
                                <td>
                                  {setting.status ? (
                                    <span className="badge badge-secondary badge-green">
                                      {t("ACTIVE")}
                                    </span>
                                  ) : (
                                    <span className="badge badge-secondary badge-danger">
                                      {t("INACTIVE")}
                                    </span>
                                  )}
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan="100%" style={{ textAlign: "center" }}>
                              {t("NO_RECORD")}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </PanelBody>
              </Panel>
            </div>
            <div className="col-xl-2">
              <Panel>
                <PanelHeader>{t("REJECT_REASONS")}</PanelHeader>
                <PanelBody className="p-0">
                  <ul className="todolist">
                    {rejectReason.length > 0 ? (
                      rejectReason.map((reason, index) => {
                        return (
                          <li key={"reject-reason-"+index }>
                            <Link className="todolist-container active">
                              <div className="todolist-title">{index + 1}.</div>
                              <div className="todolist-title">
                                {reason.description}
                              </div>
                            </Link>
                          </li>
                        );
                      })
                    ) : (
                      <li style={{ textAlign: "center", margin: "0.5rem" }}>
                        <div className="todolist-title">{t("NO_RECORD")}</div>
                      </li>
                    )}
                  </ul>
                </PanelBody>
              </Panel>
            </div>
          </div>
        </div>
      </div> : <></>}
    </div>
  );
};

export default DashboardV2;