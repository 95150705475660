import React, { useState, useEffect, useContext } from "react";
//import "react-simple-keyboard/build/css/index.css";
import ApiEngine from "../../util/ApiEngine";
import { ApiKey, ApiUrl, WebUrl, LanguageKey, Role, AccessRight } from "../../util/Constant";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, withRouter } from "react-router-dom";
import moment from "moment";
//import ContentHeader from "components/content/header";

import "../../assets/css/custom.css";
import appsettings from "../../appsettings-fe.json";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { PageSettings } from "./../../config/page-settings.js";
import { performLogin } from "../../redux/AuthAction.js";
import { showMessage } from "../../redux/AppAction.js";
import { loadCaptchaEnginge, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';
import store from "./../../ApplicationStore.js";
import { checkIfPermissionExist } from "../../util/PermissionChecker.js";
import { createFormBody } from "../../util/Util";

const Login = (props) => {
  var { t } = useTranslation(LanguageKey._PRIMARY);
  var _history = useHistory();
  var _location = useLocation();

  const _TAB_OPTIONS = {
    USER: 1,
    MEMBER: 2,
  };

  const _captcha_length = 4;
  const _context = useContext(PageSettings);
  const _dispatch = useDispatch();
  const { isLoggedIn } = useSelector((state) => ({
    isLoggedIn: state.authState.isLoggedIn,
  }));

  const { register, handleSubmit, errors } = useForm();
  const [activeTab, setActiveTab] = useState(
    appsettings.ApplicationConfig.isMemberPortal == "true"
      ? _TAB_OPTIONS["MEMBER"]
      : _TAB_OPTIONS["USER"]
  );
  const [showTACInput, setShowTACInput] = useState(false);
  const [TACCode, setTACCode] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [companyCode, setCompanyCode] = useState("");

  useEffect(() => {
    loadCaptchaEnginge(_captcha_length, 'black', 'white', 'numbers');
    _context.handleSetPageSidebar(false);
    _context.handleSetPageHeader(false);
    _context.handleSetPageContentFullWidth(true);

    return () => {
      _context.handleSetPageSidebar(true);
      _context.handleSetPageHeader(true);
      _context.handleSetPageContentFullWidth(false);
    };
  }, []);

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    if (isLoggedIn) {
      let tempUserData = store.getState()["authState"]["userData"];
      if (tempUserData['userRole'].includes("Customer Service")) {
        _history.push(WebUrl._URL_TRANSACTION_REPORT); //7.6
      }
      else if (tempUserData['userRole'].includes("Payment Official")) {
        _history.push(WebUrl._URL_TRANSACTION_ENQUIRY); //4.2
      }
      else if (!checkIfPermissionExist(AccessRight._DASHBOARD_DETAILS_PERMISSION)) {
        _history.push(WebUrl._URL_HELP);
      }
      else {
        _history.push(WebUrl._URL_MAIN);
      }
    }
  }, [isLoggedIn]);

  const submitForm = async () => {
    let params = {
      'username': username,
      'TAC': TACCode
    };
    let responseJson = await ApiEngine.post(ApiUrl._API_VERIFY_EMAIL_TAC, createFormBody(params));
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      await _dispatch(
        performLogin(
          username,
          password,
          activeTab == _TAB_OPTIONS["USER"],
          companyCode
        )
      );
    }
    else {
      _dispatch(showMessage(false, responseJson[ApiKey._API_MESSAGE_KEY]));
    }
  }

  /// <summary>
  /// Author : -
  /// </summary>
  const sendTAC = async (data, e) => {
    var temp = validateCaptcha(data.loginCaptcha);
    if (temp === true) {
      //if (data.username === "93com" || data.username === "i8" || data.username === "supercompany") {
        await _dispatch(
          performLogin(
            data.username,
            data.password,
            activeTab == _TAB_OPTIONS["USER"],
            data.companyCode ?? ""
          )
        );
      //}
      //else {
      //  let params = {
      //    'username': data.username,
      //    'password': data.password
      //  };
      //  let responseJson = await ApiEngine.post(ApiUrl._API_SEND_EMAIL_TAC, createFormBody(params));
      //  if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      //    setUsername(data.username);
      //    setPassword(data.password);
      //    setShowTACInput(true);
      //    if (data.companyCode) {
      //      setCompanyCode(data.companyCode);
      //    }
      //  }
      //  else {
      //    _dispatch(showMessage(false, responseJson[ApiKey._API_MESSAGE_KEY]));
      //  }
      //}
    }
    else {
      _dispatch(showMessage(false, "CAPTCHA_FAILED"));
    }
  };

  return (
    <><div className="login login-with-news-feed">
      <section id="richwin-section" style={{ backgroundImage: `url(${require("../../assets/img/login-bg/richwin.jpg")})`, }}>
        <div className="richwin-box">
          <div className="">
            <h2 className="mb-4 text-center">RICHWIN <span>admin</span></h2>
            <form onSubmit={handleSubmit(sendTAC)}>
              <div className="form-group mb-4">
                <input
                  id="username"
                  key="username"
                  type="text"
                  name="username"
                  ref={register({ required: "PLEASE_ENTER_USERNAME" })}
                  className="form-white-input input-bg-white"
                  placeholder={t("USERNAME")}
                />
                {errors.username && (
                  <div className="invalid-feedback">
                    {t(errors.username.message)}
                  </div>
                )}
              </div>
              <div className="form-group mb-4">
                <input
                  id="password"
                  key="password"
                  type="password"
                  name="password"
                  ref={register({ required: "PLEASE_ENTER_PASSWORD" })}
                  className="form-white-input input-bg-white"
                  placeholder={t("PASSWORD")}
                />
                {errors.password && (
                  <div className="invalid-feedback">
                    {t(errors.password.message)}
                  </div>
                )}
              </div>
              <div className="recaptcha-text mb-4">
                <div>
                  <LoadCanvasTemplateNoReload />
                </div>
              </div>
              <div className="form-group mb-4">
                <input
                  name="loginCaptcha"
                  ref={register({
                    required: "PLEASE_ENTER_CAPTCHA",
                    minLength: {
                      value: _captcha_length,
                      message: 'PLEASE_FOLLOW_CAPTCHA_LENGTH'
                    },
                    maxLength: {
                      value: _captcha_length,
                      message: 'PLEASE_FOLLOW_CAPTCHA_LENGTH'
                    }
                  })}
                  type="text"
                  className="form-white-input input-bg-white"
                  placeholder={t("PLEASE_ENTER_CAPTCHA")}
                />
                {errors.loginCaptcha && (
                  <div className="invalid-feedback">
                    {t(errors.loginCaptcha.message)}
                  </div>
                )}
              </div>
              {showTACInput && <div className="form-group mb-4">
                <input
                  name="tac"
                  value={TACCode}
                  ref={register({
                    required: "PLEASE_ENTER_TAC",
                    minLength: {
                      value: 6,
                      message: 'MINIMUM_6_DIGIT_PASSWORD'
                    },
                    maxLength: {
                      value: 6,
                      message: 'MINIMUM_6_DIGIT_PASSWORD'
                    },
                    pattern: {
                      value: /^[0-9]+$/,
                      message:
                        "DIGITS_ONLY",
                    },
                  })}
                  onChange={(e) => setTACCode(e.target.value)}
                  type="text"
                  className="form-white-input input-bg-white"
                  placeholder={t("PLEASE_ENTER_TAC")}
                />
                {errors.tac && (
                  <div className="invalid-feedback">
                    {t(errors.tac.message)}
                  </div>
                )}
              </div>}
              <hr className="richwin-hr" />
              {activeTab == _TAB_OPTIONS["MEMBER"] && (
                <div className="form-group mb-4">
                  <input
                    id="companyCode"
                    key="companyCode"
                    type="companyCode"
                    name="companyCode"
                    ref={register({ required: "PLEASE_ENTER_COMPANY_CODE" })}
                    className="form-white-input input-bg-white"
                    placeholder={t("COMPANY_CODE")}
                  />
                  {errors.companyCode && (
                    <div className="invalid-feedback">
                      {t(errors.companyCode.message)}
                    </div>
                  )}
                </div>
              )}
              {/*!showTACInput*/false ?
                <button
                  type="submit"
                  className="white-long-btn"
                >
                  {t("SEND_TAC_CODE")}
                </button>
                :
                <button
                  type="submit"//"button"
                  className="white-long-btn"
                  //onClick={() => submitForm()}
                >
                  {t("LOGIN")}
                </button>
              }
            </form>
            <div className="version-number">Ver. {process.env.REACT_APP_VERSION}</div>
          </div>
        </div>
      </section>
    </div>
    </>
  );
};
export default withRouter(Login);
