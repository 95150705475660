import React, { useState, useEffect } from "react";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { createFormBody, createMultiPartFormBody, stringIsNullOrEmpty } from "../../util/Util";
import { useLocation, useHistory } from "react-router-dom";
import {
  ApiKey,
  WebUrl,
  ApiUrl,
  AccessRight,
  LanguageKey,
} from "../../util/Constant";
import { checkIfPermissionExist } from "../../util/PermissionChecker";
import { useDispatch } from "react-redux";
import { setBusy, setIdle, setSaveAdminLog, showMessage } from "../../redux/AppAction";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import ApiEngine from "../../util/ApiEngine";
import Dropzone from "react-dropzone";

/// <summary>
/// Author : -
/// </summary>
const ProductGameList = (props) => {
  const { t } = useTranslation(LanguageKey._PRIMARY);
  let _history = useHistory();
  const _location = useLocation();
  const _dispatch = useDispatch();

  const [gameData, setGameData] = useState([]);
  const [showUpdateRankModal, setShowUpdateRankModal] = useState(false);
  const [showUpdateGameImageModal, setShowUpdateGameImageModal] = useState(false);
  const [selectedGame, setSelectedGame] = useState({});
  const [rankValue, setRankValue] = useState(0);
  const params = new URLSearchParams(_location.search);
  const [titleGameName, setTitleGameName] = useState("");
  const [gameImage, setGameImage] = useState();
  const [gameFile, setGameFile] = useState([]);
  const [isNewGameImage, setIsNewGameImage] = useState(false);

  let _tableColumns = [
    {
      Header: "ID",
      accessor: "id",
    },
    {
      Header: "NAME",
      accessor: "gameName",
    },
    {
      Header: "STATUS",
      accessor: "status",
      Cell: ({ row }) => (
        <div className="btn-group m-r-5 m-b-5">
          {row.original.status === true ? (
            <span className="badge badge-secondary badge-green">
              {t("ACTIVE")}
            </span>
          ) : (
            <span className="badge badge-secondary badge-danger">
              {t("INACTIVE")}
            </span>
          )}
        </div>
      ),
      disableSortBy: true,
      style: { overflow: "visible" },
    },
    {
      Header: "IS_UM",
      accessor: "gameUm",
      Cell: ({ row }) => (
        <div className="btn-group m-r-5 m-b-5">
          {row.original.gameUm === 1 ? (
            <span className="badge badge-secondary badge-warning">
              {t("MAINTENANCE")}
            </span>
          ) : (
              <span>{"-"}</span>
          )}
        </div>
      ),
      disableSortBy: true,
      style: { overflow: "visible" },
    },
    {
      id: "action",
      Header: "",
      Cell: ({ row }) => (
        <div className="btn-group m-r-5 m-b-5">
          <UncontrolledButtonDropdown>
            <DropdownToggle caret color="default">
              <i className="fas fa-cog"></i>
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                onClick={() => {
                  updateGameStatus("isNewGame", row.original);
                }}
              >
                <span className="text-warning">{(row.original.isNewGame === true ? t("UNSET_AS") : t("SET_AS")) + t("NEW_GAME")}</span>
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  updateGameStatus("isRecommended", row.original);
                }}
              >
                <span className="text-warning">{(row.original.isRecommended === true ? t("UNSET_AS") : t("SET_AS")) + t("HOT_GAME")}</span>
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  setSelectedGame(row.original);
                  setRankValue(row.original.rank);
                  setShowUpdateRankModal(true);
                }}
              >
                <span className="text-warning">{t("UPDATE_GAME_DISPLAY_RANK")}</span>
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  setSelectedGame(row.original);
                  handleUpdateGameImageOnClick(row.original.id);
                }}
              >
                <span className="text-warning">{t("UPDATE_GAME_IMAGE")}</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </div>
      ),
      disableSortBy: true,
      disableFilters: true,
      width: 100,
      style: { overflow: "visible" },
    },
  ];

  const logoDrop = (acceptedFiles) => {
    if (acceptedFiles.length !== 0) {
      const file = acceptedFiles[0];
      setGameFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setGameImage(reader.result);
        setIsNewGameImage(true);
      };
      reader.readAsDataURL(file);
    }
  };

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    readGame();
  }, []);

  /// <summary>
  /// Author : -
  /// </summary>
  function readGame() {

    var queryProductId = "";

    if (params.get("productId") === null) {
      queryProductId = _location.state["product"]["id"];
    } else {
      queryProductId = params.get("productId");
    }
   
    fetch(
      ApiUrl._API_GET_PRODUCT_GAME + "?productId=" + queryProductId,
      {
        method: ApiKey._API_GET,
        headers: {
          "Content-Type": ApiKey._API_FORM_URLENCODED,
          "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
        },
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          let tempGameData = responseJson[ApiKey._API_DATA_KEY];
          setGameData(tempGameData);
          setTitleGameName(tempGameData[0]["product"]["productId"]);
        }
      });
  }

  const updateGameStatus = async (value, game) => {
    let params = {
      "key": value,
      "rankValue": rankValue,
      "gameId": game.id
    };
    _dispatch(setSaveAdminLog("EDIT_PRODUCT_GAME_" + value, _history.location.pathname, params))
    let responseJson = await ApiEngine.post(ApiUrl._API_UPDATE_PRODUCT_GAME, createFormBody(params));
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setShowUpdateRankModal(false);
      readGame();
    }
    _dispatch(showMessage(responseJson[ApiKey._API_SUCCESS_KEY], responseJson[ApiKey._API_MESSAGE_KEY]));
  }

  const updateGameImage = async () => {
    let params = {
      "gameId": selectedGame["id"],
      "isNewGameImage": isNewGameImage,
      "productGameImage": gameFile
    };
    let responseJson = await ApiEngine.post(ApiUrl._API_UPDATE_PRODUCT_GAME_IMAGE, createMultiPartFormBody(params));
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setShowUpdateGameImageModal(false);
      readGame();
    }
    _dispatch(showMessage(responseJson[ApiKey._API_SUCCESS_KEY], responseJson[ApiKey._API_MESSAGE_KEY]));
  }

  const handleUpdateGameImageOnClick = async (gameId) => {
    let selectedGameData = gameData.filter(x => x.id === gameId)[0];
    await setGameImage(selectedGameData["image"]);
    setShowUpdateGameImageModal(true);
  }

  return (
    <div>
      <h1 className="page-header">
        {t("GAME")} - {titleGameName}
        <NavigationButton history={_history} />
      </h1>
      <Panel>
        <PanelBody>
          <ReactTable filterable data={gameData} columns={_tableColumns} />
        </PanelBody>
      </Panel>
      <Modal
        isOpen={showUpdateRankModal}
        toggle={() => setShowUpdateRankModal(false)}
        size="lg"
        centered
      >
        <ModalHeader>
          <div>{selectedGame.gameName}</div>
        </ModalHeader>
        <ModalBody>
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-3">
                {t("RANK")}
              </div>
              <div className="col-lg-9">
                <input
                  type="number"
                  step="1"
                  value={rankValue}
                  onChange={(e) => setRankValue(e.target.value)}
                />
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div><button className="btn btn-primary" onClick={() => updateGameStatus("rank", selectedGame)}>{t("SUBMIT")}</button></div>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={showUpdateGameImageModal}
        toggle={() => setShowUpdateGameImageModal(false)}
        size="lg"
        centered
      >
        <ModalHeader>
          <div>{selectedGame.gameName}</div>
        </ModalHeader>
        <ModalBody>
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-3">
                {t("IMAGE")}
              </div>
              <div className="col-lg-9">
                <Dropzone
                  accept={"image/*"}
                  onDrop={(acceptedFiles) => logoDrop(acceptedFiles)}
                >
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div
                        className="dropzone"
                        style={{
                          minHeight: "200px",
                          textAlign: "center",
                        }}
                        {...getRootProps()}
                      >
                        <input {...getInputProps()} />
                        <h4 style={{ color: "grey" }}>
                          {t(
                            "DRAG_N_DROP_SOME_FILES_HERE_OR_CLICK_TO_SELECT_FILES"
                          )}
                        </h4>
                        {!stringIsNullOrEmpty(gameImage) && (
                          <aside className="thumbsContainer">
                            <div className="thumb">
                              <div className="thumbInner">
                                <img
                                  src={gameImage}
                                  className="dropzone-img"
                                />
                              </div>
                            </div>
                          </aside>
                        )}
                      </div>
                    </section>
                  )}
                </Dropzone>
                <br />
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div><button className="btn btn-primary" onClick={() => updateGameImage()}>{t("SUBMIT")}</button></div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ProductGameList;