import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import Dropzone from "react-dropzone";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import {
  ApiKey,
  ApiUrl,
  DisplayMode,
  DisplayType,
  LanguageKey,
  ContentTypeString,
  AllVipOption,
  DisplayDevice,
} from "../../util/Constant";
import { useForm, Controller } from "react-hook-form";
import { useLocation } from "react-router-dom";
import {
  stringIsNullOrEmpty,
  createMultiPartFormBody,
  imagePathToFileObject,
} from "../../util/Util";
import { setBusy, setIdle, showMessage, setSaveAdminLog } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import ManageBannerLanguage from "./ManageBannerLanguage";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import { fetch } from "whatwg-fetch";

/// <summary>
/// Author : -
/// </summary>

const BannerDetail = (props) => {
  const _DETAIL_TAB = "1";
  const _LANGUAGE_TAB = "2";
  const { t } = useTranslation(LanguageKey._PRIMARY);
  let _history = useHistory();
  const _location = useLocation();
  const _dispatch = useDispatch();

  const {
    register,
    unregister,
    handleSubmit,
    errors,
    setError,
    control,
    setValue,
  } = useForm();
  const [displayType, setDisplayType] = useState("");
  const [displayMode, setDisplayMode] = useState("");

  const [bannerId, setBannerId] = useState("");
  const [bannerRefId, setBannerRefId] = useState("");
  const [title, setTitle] = useState("");
  const [page, setPage] = useState("");
  const [content, setContent] = useState("");
  const [url, setUrl] = useState("");
  const [rank, setRank] = useState("");
  const [status, setStatus] = useState(true);
  const [displayDevice, setDisplayDevice] = useState("");

  const [desktopFile, setDesktopFile] = useState([]);
  const [mobileFile, setMobileFile] = useState([]);
  const [desktopImage, setDesktopImage] = useState();
  const [mobileImage, setMobileImage] = useState();
  const [isNewDesktopImage, setIsNewDesktopImage] = useState(false);
  const [isNewMobileImage, setIsNewMobileImage] = useState(false);
  const [vip, setVip] = useState("");
  const [vipOption, setVipOption] = useState([]);
  const [showExample, setShowExample] = useState(false);

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    init();
  }, []);

  const [activeTab, setActiveTab] = useState(_DETAIL_TAB);

  /// <summary>
  /// Author : -
  /// </summary>
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  /// <summary>
  /// Author : -
  /// </summary>
  async function init() {
    if (_location.state) {
      var apiUrl = ApiUrl._API_GET_USER_BANNER_BY_ID;
      let stateBannerId = _location.state.id || _location.state.refId;
      apiUrl += "?id=" + stateBannerId;
      await fetch(apiUrl, {
        method: ApiKey._API_GET,
        headers: {
          "Content-Type": ApiKey._API_FORM_URLENCODED,
          "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
        },
      })
        .then((response) => response.json())
        .then(async (responseJson) => {
          if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            if (_location.state.id) {
              setBannerId(responseJson[ApiKey._API_DATA_KEY]["id"]);
              setBannerRefId("");
            } else if (_location.state.refId) {
              setBannerId("");
              setBannerRefId(responseJson[ApiKey._API_DATA_KEY]["id"]);
            }

            setDisplayMode(responseJson[ApiKey._API_DATA_KEY]["displayModeId"]);
            setDisplayType(responseJson[ApiKey._API_DATA_KEY]["displayTypeId"]);
            setContent(responseJson[ApiKey._API_DATA_KEY]["content"]);
            setTitle(responseJson[ApiKey._API_DATA_KEY]["title"]);
            setRank(responseJson[ApiKey._API_DATA_KEY]["rank"]);
            setPage(responseJson[ApiKey._API_DATA_KEY]["page"]);
            if (stringIsNullOrEmpty(responseJson[ApiKey._API_DATA_KEY]["page"])) setShowExample(true);
            setUrl(responseJson[ApiKey._API_DATA_KEY]["url"]);
            setStatus(responseJson[ApiKey._API_DATA_KEY]["status"]);
            setDisplayDevice(responseJson[ApiKey._API_DATA_KEY]["displayDevice"]);
            setVip(
              stringIsNullOrEmpty(responseJson[ApiKey._API_DATA_KEY]["vipId"])
                ? AllVipOption._VALUE
                : responseJson[ApiKey._API_DATA_KEY]["vipId"]
            );
            setValue(
              "vip",
              stringIsNullOrEmpty(responseJson[ApiKey._API_DATA_KEY]["vipId"])
                ? AllVipOption._VALUE
                : responseJson[ApiKey._API_DATA_KEY]["vipId"]
            );

            if (
              !stringIsNullOrEmpty(
                responseJson[ApiKey._API_DATA_KEY]["desktopImage"]
              )
            ) {
              setDesktopImage(
                responseJson[ApiKey._API_DATA_KEY]["desktopImage"]
              );
              if (_location.state.refId) {
                setIsNewDesktopImage(true);
                let imageFile = await imagePathToFileObject(
                  responseJson[ApiKey._API_DATA_KEY]["desktopImage"]
                );
                setDesktopFile(imageFile);
              }
            }

            if (
              !stringIsNullOrEmpty(
                responseJson[ApiKey._API_DATA_KEY]["mobileImage"]
              )
            ) {
              setMobileImage(responseJson[ApiKey._API_DATA_KEY]["mobileImage"]);
              if (_location.state.refId) {
                setIsNewMobileImage(true);
                let imageFile = await imagePathToFileObject(
                  responseJson[ApiKey._API_DATA_KEY]["mobileImage"]
                );
                setMobileFile(imageFile);
              }
            }

            unregister("content");
          }
        });
    }

    await fetch(ApiUrl._API_GET_USER_VIP, {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          const vipList = [
            { label: AllVipOption._LABEL, value: AllVipOption._VALUE },
          ];
          responseJson[ApiKey._API_DATA_KEY].forEach(function (vipLevel) {
            vipList.push({ label: vipLevel.name, value: vipLevel.id });
          });

          setVipOption(vipList);
        }
      });
  }

  /// <summary>
  /// Author : -
  /// </summary>
  const submitForm = async (data, e) => {
    let tempDisplayTypeId = 1;
    if (data.displayDevice === 1 || data.displayDevice === 3) {
      tempDisplayTypeId = 2;
    }
    let params = {
      id: bannerId,
      title: data.title,
      displayModeId: data.displayMode,
      displayTypeId: tempDisplayTypeId,
      page: data.page,
      url: data.url,
      rank: data.rank,
      status: data.status,
      content: data.content,
      isNewDesktopFile: isNewDesktopImage,
      isNewMobileFile: isNewMobileImage,
      vipId: stringIsNullOrEmpty(vip) ? "" : vip,
      displayDevice: data.displayDevice
    };

    if (!stringIsNullOrEmpty(desktopFile)) {
      params["desktopFile"] = desktopFile;
    }

    if (stringIsNullOrEmpty(desktopImage)) {
      params["desktopImageRemoved"] = true;
    }

    if (!stringIsNullOrEmpty(mobileFile)) {
      params["mobileFile"] = mobileFile;
    }

    if (stringIsNullOrEmpty(mobileImage)) {
      params["mobileImageRemoved"] = true;
    }

    if (!stringIsNullOrEmpty(bannerRefId)) {
      params["refId"] = bannerRefId;
    }

    if (!stringIsNullOrEmpty(bannerId)) {
      params["id"] = bannerId;
      _dispatch(setSaveAdminLog("EDIT_BO_BANNER", _history.location.pathname, params));
    } else {
      _dispatch(setSaveAdminLog("CREATE_BO_BANNER", _history.location.pathname, params));
    }

    let formBody = createMultiPartFormBody(params);
    _dispatch(setBusy());

    fetch(ApiUrl._API_CREATE_OR_UPDATE_BANNER, {
      method: ApiKey._API_POST,
      headers: {
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
      body: formBody,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        _dispatch(setIdle());
        _dispatch(
          showMessage(
            responseJson[ApiKey._API_SUCCESS_KEY],
            t(responseJson[ApiKey._API_MESSAGE_KEY])
          )
        );

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          setIsNewDesktopImage(false);
          setIsNewMobileImage(false);
          setBannerRefId("");
          setBannerId(responseJson[ApiKey._API_DATA_KEY]["id"]);

          _history.replace({
            pathname: _location.pathname,
            state: {
              id: responseJson[ApiKey._API_DATA_KEY]["id"],
            },
          });
        }
      });
  };

  /// <summary>
  /// Author : -
  /// </summary>
  const logoDrop = (acceptedFiles, isDesktop) => {
    if (acceptedFiles.length !== 0) {
      const file = acceptedFiles[0];
      if (isDesktop) {
        setDesktopFile(file);
      } else {
        setMobileFile(file);
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        if (isDesktop) {
          setIsNewDesktopImage(true);
          setDesktopImage(reader.result);
        } else {
          setIsNewMobileImage(true);
          setMobileImage(reader.result);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const checkPageField = (data, e) => {
    if (data == "") setShowExample(true);
    else setShowExample(false);
  }

  return (
    <div>
      <h1 className="page-header">
        {bannerId ? t("EDIT_BANNER") : t("ADD_BANNER")}
        <NavigationButton history={_history} />
      </h1>
      {bannerId && (
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === _DETAIL_TAB })}
              onClick={() => {
                toggle(_DETAIL_TAB);
              }}
            >
              {t("DETAIL")}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === _LANGUAGE_TAB })}
              onClick={() => {
                toggle(_LANGUAGE_TAB);
              }}
            >
              {t("LANGUAGE")}
            </NavLink>
          </NavItem>
        </Nav>
      )}
      <TabContent activeTab={activeTab}>
        <TabPane tabId={_DETAIL_TAB}>
          <div className="row">
            <div className="col-xl-12">
              <Panel>
                <PanelBody>
                  <form onSubmit={handleSubmit(submitForm)}>
                    <div className="row">
                      <div className="col-lg-8">
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>{t("TITLE")}</b>
                          </label>
                          <div className="col-md-7">
                            <input
                              type="text"
                              className="form-control"
                              placeholder={t("PLEASE_ENTER_TITLE")}
                              name="title"
                              ref={register({ required: "PLEASE_ENTER_TITLE" })}
                              defaultValue={title}
                            />
                            {errors.title && (
                              <div className="invalid-feedback">
                                {t(errors.title.message)}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>{t("DISPLAY_MODE")}</b>
                          </label>
                          <div className="col-md-7">
                            <select
                              className="form-control"
                              name="displayMode"
                              ref={register}
                              onChange={(e) => setDisplayMode(e.target.value)}
                              value={displayMode}
                            >
                              <option value={DisplayMode._ALL}>
                                {t("ALL")}
                              </option>
                              <option value={DisplayMode._PUBLIC}>
                                {t("PUBLIC")}
                              </option>
                              <option value={DisplayMode._MEMBER}>
                                {t("MEMBER")}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                      {/*<div className="col-lg-8">
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>{t("DISPLAY_TYPE")}</b>
                          </label>
                          <div className="col-md-7">
                            <select
                              className="form-control"
                              name="displayType"
                              ref={register}
                              onChange={(e) => setDisplayType(e.target.value)}
                              value={displayType}
                            >
                              <option value={DisplayType._POPUP}>
                                {t("POPUP")}
                              </option>
                              <option value={DisplayType._SLIDER}>
                                {t("SLIDER")}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>*/}
                      <div className="col-lg-8">
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>{t("URL")}</b>
                          </label>
                          <div className="col-md-7">
                            <input
                              type="text"
                              className="form-control"
                              placeholder={t("PLEASE_ENTER_URL")}
                              name="url"
                              ref={register}
                              defaultValue={url}
                            />
                            {errors.url && (
                              <div className="invalid-feedback">
                                {t(errors.url.message)}
                              </div>
                            )}
                            <div className="color-lightgrey-custom">{"for \"Click to Play\" Banners, insert :"}<br />{"Game/StartGame?gameId={gameId}&isApp=0"}</div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <div className="form-group row">
                          <label className="col-md-4 col-form-label">
                            <b>{t("VIP_LEVEL")}</b>
                          </label>
                          <div className="col-md-7">
                            <Controller
                              control={control}
                              name="vip"
                              defaultValue={AllVipOption._VALUE}
                              render={({ onChange, value }) => {
                                return (
                                  <Select
                                    name="vip"
                                    options={vipOption}
                                    placeholder={
                                      vipOption.filter(
                                        (option) => option.value == vip
                                      )[0] !== undefined
                                        ? vipOption.filter(
                                            (option) => option.value == vip
                                          )[0].label
                                        : ""
                                    }
                                    value={vipOption.filter(
                                      (option) => option.value == vip
                                    )}
                                    onChange={(e) => {
                                      setVip(e.value);
                                      onChange(e.value);
                                    }}
                                  />
                                );
                              }}
                              rules={{ required: "PLEASE_SELECT_VIP_LEVEL" }}
                            />
                            {errors.vip && (
                              <div className="invalid-feedback">
                                {t(errors.vip.message)}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>{t("SEQUENCE_RANK")}</b>
                          </label>
                          <div className="col-md-7">
                            <input
                              type="number"
                              className="form-control"
                              placeholder={t("PLEASE_ENTER_RANK")}
                              name="rank"
                              defaultValue={rank}
                              ref={register({
                                required: "PLEASE_ENTER_RANK",
                                min: {
                                  value: 0,
                                  message:
                                    "PLEASE_ENTER_VALUE_NOT_LESS_THAN_ZERO",
                                },
                                max: {
                                  value: 100,
                                  message:
                                    "PLEASE_ENTER_VALUE_NOT_MORE_THAN_100",
                                },
                              })}
                            />
                            {errors.rank && (
                              <div className="invalid-feedback">
                                {t(errors.rank.message)}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>{t("CONTENT")}</b>
                          </label>
                          <div className="col-md-7">
                            <textarea
                              className="form-control"
                              rows="5"
                              style={{ marginTop: "10px" }}
                              ref={register}
                              name="content"
                              defaultValue={content}
                            ></textarea>
                            {errors.content && (
                              <div className="invalid-feedback">
                                {t(errors.content.message)}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>{t("DISPLAY_DEVICES")}</b>
                          </label>
                          <div className="col-md-7">
                            <select
                              className="form-control"
                              name="displayDevice"
                              ref={register}
                              onChange={(e) => setDisplayDevice(parseInt(e.target.value))}
                              value={displayDevice}
                              required
                            >
                              <option value="" selected hidden>
                                ({t("SELECT")})
                              </option>
                              <option value={DisplayDevice._DESKTOP_MAIN}>
                                {t("DESKTOP_MAIN")}
                              </option>
                              <option value={DisplayDevice._DESKTOP_SUB}>
                                {t("DESKTOP_SUB")}
                              </option>
                              {/*<option value={DisplayDevice._DESKTOP_POPUP}>*/}
                              {/*  {t("DESKTOP_POPUP")}*/}
                              {/*</option>*/}
                              <option value={DisplayDevice._DESKTOP_BANNER_POPUP}>
                                {t("DESKTOP_BANNER_POPUP")}
                              </option>
                              <option value={DisplayDevice._MOBILE_SLIDER}>
                                {t("MOBILE_SLIDER")}
                              </option>
                              {/*<option value={DisplayDevice._MOBILE_POPOUT}>*/}
                              {/*  {t("MOBILE_POPOUT")}*/}
                              {/*</option>*/}
                              <option value={DisplayDevice._MOBILE_BANNER_POPUP}>
                                {t("MOBILE_BANNER_POPUP")}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                      {(displayDevice === DisplayDevice._DESKTOP_MAIN || displayDevice === DisplayDevice._DESKTOP_SUB || displayDevice === DisplayDevice._DESKTOP_BANNER_POPUP) && <div className="col-lg-8">
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>{t("DISPLAY_PAGE")}</b>< br />
                            {(showExample) ? "(e.g. https://i8.live/Home)" : ""}
                          </label>
                          <div className="col-md-7">
                            <input
                              type="text"
                              className="form-control"
                              placeholder={t("/Home")}
                              name="page"
                              ref={register}
                              defaultValue={page}
                              onChange={(e) => checkPageField(e.target.value)}
                            />
                            {errors.page && (
                              <div className="invalid-feedback">
                                {t(errors.page.message)}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>}
                      {(displayDevice === DisplayDevice._DESKTOP_MAIN || displayDevice === DisplayDevice._DESKTOP_SUB || displayDevice === DisplayDevice._DESKTOP_POPUP || displayDevice === DisplayDevice._DESKTOP_BANNER_POPUP) ? <div className="col-lg-8">
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>
                              {t(/*"DESKTOP_IMAGE_UPLOAD"*/"CLASSIC_BANNER")}
                              <p style={{ color: "blue" }}>
                                ({t("RECOMMENDED_HEIGHT_WIDTH_RATIO_IS_1_5")})
                              </p>
                            </b>
                          </label>
                          <div className="col-md-7">
                            <Dropzone
                              accept={"image/*"}
                              onDrop={(acceptedFiles) =>
                                logoDrop(acceptedFiles, true)
                              }
                            >
                              {({ getRootProps, getInputProps }) => (
                                <section>
                                  <div
                                    className="dropzone"
                                    style={{
                                      minHeight: "200px",
                                      textAlign: "center",
                                    }}
                                    {...getRootProps()}
                                  >
                                    <input {...getInputProps()} />
                                    <h4 style={{ color: "grey" }}>
                                      {t(
                                        "DRAG_N_DROP_SOME_FILES_HERE_OR_CLICK_TO_SELECT_FILES"
                                      )}
                                    </h4>
                                    {!stringIsNullOrEmpty(desktopImage) && (
                                      <aside className="thumbsContainer">
                                        <div className="thumb">
                                          <div className="thumbInner">
                                            <img
                                              src={desktopImage}
                                              className="dropzone-img"
                                            />
                                          </div>
                                        </div>
                                      </aside>
                                    )}
                                  </div>
                                </section>
                              )}
                            </Dropzone>
                            <br />
                            <button
                              type="button"
                              className="btn btn-danger"
                              onClick={() => {
                                setDesktopImage("");
                                setDesktopFile();
                              }}
                            >
                              {t("REMOVE_IMAGE")}
                            </button>
                          </div>
                        </div>
                      </div> :
                      <div className="col-lg-8">
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>
                              {t(/*"MOBILE_IMAGE_UPLOAD"*/"MODERN_BANNER")}
                              <p style={{ color: "blue" }}>
                                ({t("RECOMMENDED_HEIGHT_WIDTH_RATIO_IS_1_5")})
                              </p>
                            </b>
                          </label>
                          <div className="col-md-7">
                            <Dropzone
                              accept={"image/*"}
                              onDrop={(acceptedFiles) =>
                                logoDrop(acceptedFiles, false)
                              }
                            >
                              {({ getRootProps, getInputProps }) => (
                                <section>
                                  <div
                                    className="dropzone"
                                    style={{
                                      minHeight: "200px",
                                      textAlign: "center",
                                    }}
                                    {...getRootProps()}
                                  >
                                    <input {...getInputProps()} />
                                    <h4 style={{ color: "grey" }}>
                                      {t(
                                        "DRAG_N_DROP_SOME_FILES_HERE_OR_CLICK_TO_SELECT_FILES"
                                      )}
                                    </h4>
                                    {!stringIsNullOrEmpty(mobileImage) && (
                                      <aside className="thumbsContainer">
                                        <div className="thumb">
                                          <div className="thumbInner">
                                            <img
                                              src={mobileImage}
                                              className="dropzone-img"
                                            />
                                          </div>
                                        </div>
                                      </aside>
                                    )}
                                  </div>
                                </section>
                              )}
                            </Dropzone>
                            <br />
                            <button
                              type="button"
                              className="btn btn-danger"
                              onClick={() => {
                                setMobileImage("");
                                setMobileFile();
                              }}
                            >
                              {t("REMOVE_IMAGE")}
                            </button>
                          </div>
                        </div>
                      </div>}
                      <div className="col-lg-8">
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>{t("STATUS")}</b>
                          </label>
                          <div className="col-md-7">
                            <div className="switcher">
                              <input
                                type="checkbox"
                                name="status"
                                id="status"
                                onChange={(e) => setStatus(e.target.checked)}
                                value={true}
                                checked={status}
                                ref={register}
                              />
                              <label htmlFor="status"></label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-lg-3">
                        <div className="form-group">
                          <button type="submit" className="btn btn-primary">
                            {t("SUBMIT")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </PanelBody>
              </Panel>
            </div>
          </div>
        </TabPane>
        <TabPane tabId={_LANGUAGE_TAB}>
          {bannerId && (
            <div className="row">
              <div className="col-lg-12">
                <ManageBannerLanguage
                  id={bannerId}
                  type={ContentTypeString._BANNER}
                  displayDevice={displayDevice}
                />
              </div>
            </div>
          )}
        </TabPane>
      </TabContent>
    </div>
  );
};

export default withRouter(BannerDetail);
