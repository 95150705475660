import React, { useState, useEffect } from "react";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import "../../assets/css/custom.css";
import { useHistory } from "react-router-dom";
import {
    UncontrolledButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";
import { navigateTo, createFormBody } from "../../util/Util";

import { ApiKey, ApiUrl, WebUrl, LanguageKey } from "../../util/Constant";
import { setBusy, setIdle, showMessage, setSaveAdminLog } from "../../redux/AppAction";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";
import moment from "moment";
import ApiEngine from "../../util/ApiEngine";

/// <summary>
/// Author : -
/// </summary>

const RewardsSchedule = (props) => {
    const { t } = useTranslation(LanguageKey._PRIMARY);
    const _dispatch = useDispatch();
    const { register, handleSubmit, errors, setValue, watch } = useForm({ mode: "onBlur" });

    const [formData, setFormData] = useState({});

    let _history = useHistory();
    const [scheduleData, setScheduleData] = useState([]);
    const [categoryData, setCategoryData] = useState([]);
    const [productData, setProductData] = useState([]);
    const { loginUserId } = useSelector((state) => ({
        loginUserId: state.authState.userData.userId,
    }));

    /// <summary>
    /// Author : -
    /// </summary>
    function updateProductData() {

        _dispatch(setBusy());
        fetch(ApiUrl._API_GET_REWARDS_SCHEDULE, {
            method: ApiKey._API_GET,
            headers: {
                "Content-Type": ApiKey._API_FORM_URLENCODED,
                "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
            },
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    setProductData(responseJson[ApiKey._API_DATA_KEY].data.products);
                    setCategoryData(responseJson[ApiKey._API_DATA_KEY].data.categories);
                    setScheduleData(responseJson[ApiKey._API_DATA_KEY].data.schedules);
                }
            });

        _dispatch(setIdle());
    }

    /// <summary>
    /// Author : -
    /// </summary>
    useEffect(() => {
        updateProductData();

    }, []);

    /// <summary>
    /// Author : -
    /// </summary>
    const submitForm = async (data, e) => {
        console.log(data);
        let formBody = createFormBody(data);
        console.log(formBody);
        let responseJson = await ApiEngine.post(
            ApiUrl._API_UPDATE_REWARDS_SCHEDULE,
            formBody
        );

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            _dispatch(
                showMessage(
                    responseJson[ApiKey._API_SUCCESS_KEY],
                    t(responseJson[ApiKey._API_MESSAGE_KEY])
                )
            );
        }
        else {
            _dispatch(
                showMessage(
                    responseJson[ApiKey._API_SUCCESS_KEY],
                    t(responseJson[ApiKey._API_MESSAGE_KEY])
                )
            );
        }
      _dispatch(setSaveAdminLog("EDIT_REWARD_SCHEDULES", _history.location.pathname, data));
    };

    /// <summary>
    /// Author : -
    /// </summary>
    function preSubmit() {
        handleSubmit(submitForm)();
    }

    return (
        <div>
            <h1 className="page-header">{t("Rewards Schedules")}</h1>

            <Panel>
                <PanelBody>
                    <form>
                        <div style={{ overflow: "scroll" }}>
                            <table className="table table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th></th>
                                        {
                                            Object.keys(scheduleData).length > 0 && Object.entries(scheduleData).map(
                                                (o, i) => {
                                                    return (
                                                        <th style={{ textAlign: "center" }}>{moment(o[0], "YYYY-MM-DD").format("DD/MM/YYYY")}<br />{moment(o[0], "YYYY-MM-DD").format("dddd")}</th>
                                                    )
                                                }
                                            )
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        categoryData.length > 0 && categoryData.map(
                                            (o, i) => {
                                                return (
                                                    <>
                                                        <tr>
                                                            <th colSpan="12" style={{padding:"0 8px", background:"#eee"}}>{o.title}</th>
                                                        </tr>
                                                        <>
                                                            {
                                                                Object.entries(Object.entries(productData)[Object.keys(productData).findIndex(c => c == o.title)][1]) && Object.entries(Object.entries(productData)[Object.keys(productData).findIndex(c => c == o.title)][1]).length > 0 && Object.entries(Object.entries(productData)[Object.keys(productData).findIndex(c => c == o.title)][1]).map(
                                                                    (o2, i2) => {
                                                                        return (
                                                                            <tr>
                                                                                <th>{o2[1]}</th>
                                                                                {
                                                                                    Object.entries(scheduleData).map(
                                                                                        (o3, i3) => {
                                                                                            return (<th>{
                                                                                                moment(o3[0], "YYYY-MM-DD").isAfter(moment(), "d") ? <input
                                                                                                    key={o.id + "|" +o3[0] + "|" + o2[0]}
                                                                                                    name={o.id + "|" + o3[0] + "|" + o2[0]}
                                                                                                    type="number"
                                                                                                    step="0.01"
                                                                                                    className="form-control"
                                                                                                    ref={register({
                                                                                                        required: "Please enter value",
                                                                                                        //min: {
                                                                                                        //    value: 0,
                                                                                                        //    message: "Min 0.0%",
                                                                                                        //},
                                                                                                        //max: {
                                                                                                        //    value: (o2[1] == "JJVIP" ? 2.5 : 1),
                                                                                                        //    message: (o2[1] == "JJVIP" ? "Max 2.5%" : "Max 1%"),
                                                                                                        //},
                                                                                                    })}
                                                                                                    onChange={(e) => {
                                                                                                        parseFloat(e.target.value) < 0 ?
                                                                                                            (e.target.value = 0) : (parseFloat(e.target.value) > (o2[1] == "JJVIP" ? 2.5 : 1) ?
                                                                                                                e.target.value = (o2[1] == "JJVIP" ? 2.5 : 1) : e.preventDefault());

                                                                                                        //console.log(formData);
                                                                                                        //setFormData({ ...formData, [o.id + "|" + o3[0] + "|" + o2[0]]: e.target.value });
                                                                                                        //console.log(formData);

                                                                                                    }}
                                                                                                    defaultValue={o3[1][o.title][o2[0]]}
                                                                                                    style={{ textAlign: "center", width: "80px" }} />:
                                                                                                o3[1][o.title][o2[0]]
                                                                                            }</th>)
                                                                                        }
                                                                                    )
                                                                                }
                                                                            </tr>
                                                                        )
                                                                    }
                                                                )
                                                            }
                                                        </>
                                                    </>
                                                )
                                            }
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>

                        <hr />
                        <div className="row">
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <button
                                        type="button"
                                        onClick={() => preSubmit()}
                                        className="btn btn-primary"
                                    >
                                        {t("SUBMIT")}
                                    </button>
                                </div>
                            </div>
                        </div>
                        </form>
                </PanelBody>
            </Panel>
        </div>
    );
};

export default RewardsSchedule;
