import React, { useState, useEffect } from "react";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import { ApiKey, ApiUrl, LanguageKey } from "../../util/Constant";
import { useForm } from "react-hook-form";
import { stringIsNullOrEmpty, createFormBody, createMultiPartFormBody } from "../../util/Util";
import { setBusy, setIdle, showMessage } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";
import Select from "react-select";
import ApiEngine from "../../util/ApiEngine";

const ManageChatSpherePhone = (props) => {
  const { t } = useTranslation(LanguageKey._PRIMARY);
  const _dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm();

  const [url, setUrl] = useState("");
  const [username, setUsername] = useState("");
  const [systemName, setSystemName] = useState("");
  const [password, setPassword] = useState("");
  const [settingId, setSettingId] = useState("");
  const [cpTypeOption, setCPTypeOption] = useState([]);
  const [cpTypeId, setCPTypeId] = useState(0);
  const [chatSphereType, setChatSphereType] = useState("");
  const [chatSpherePhoneNumber, setChatSpherePhoneNumber] = useState("");

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    _dispatch(setBusy());
    let dataList = [];

    await fetch(ApiUrl._API_GET_CHAT_SPHERE_TYPE, {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          responseJson[ApiKey._API_DATA_KEY].forEach(function (gateway) {
            dataList.push({ label: gateway.name, value: gateway.id });
          });
          setCPTypeOption(dataList);
        }
      });


    let apiUrl = ApiUrl._API_GET_USER_SYSTEM_SETTINGS + "?type=settings";
    var responseJson = await ApiEngine.get(apiUrl);
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY];
      data.map((keyValuePair, index) => {
        if (keyValuePair['key'] === "ChatSphereType") {
          let chatSphereType = keyValuePair['value'];
          setChatSphereType(chatSphereType);
          setCPTypeId(dataList.filter(i => i.label === chatSphereType)[0].value);
        }
        if (keyValuePair['key'] === "ChatSpherePhoneNumber") {
          let chatSphereType = keyValuePair['value'];
          setChatSpherePhoneNumber(chatSphereType);
        }
      });
    }
    getChatSphereType();
    _dispatch(setIdle());
  }

  const getChatSphereType = async () => {
    await fetch(ApiUrl._API_GET_CHAT_SPHERE_TYPE, {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          const cpList = [];
          responseJson[ApiKey._API_DATA_KEY].forEach(function (gateway) {
            cpList.push({ label: gateway.name, value: gateway.id });
          });

          setCPTypeOption(cpList);
        }
      });
  }

  /// <summary>
  /// Author : -
  /// </summary>
  const submitForm = async (data, e) => {
    let params = {
      'chatSphereType': chatSphereType,
      'chatSpherePhoneNumber': data.chatSpherePhoneNumber
    };
    let formBody = createMultiPartFormBody(params);
    let apiUrl = ApiUrl._API_CREATE_OR_UPDATE_SYSTEM_SETTINGS + "?type=ChatSphereType";
    let responseJson = await ApiEngine.post(apiUrl, formBody);
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      init();
    }
    _dispatch(showMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
  };

  return (
    <div>
      <h1 className="page-header">{t("CHAT_SPHERE_PHONE_NUMBER")}</h1>
      <div className="row">
        <div className="col-xl-12">
          <Panel>
            <PanelBody>
              <form onSubmit={handleSubmit(submitForm)}>
                <div className="row">
                  <div className="col-lg-8">
                    <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        <b>{t("SYSTEM_NAME")}</b>
                      </label>
                      <div className="col-md-7">
                        <Select
                          name="cpTypeId"
                          options={cpTypeOption}
                          placeholder={
                            cpTypeOption.filter(
                              (option) => option.value == cpTypeId
                            )[0] !== undefined
                              ? cpTypeOption.filter(
                                (option) =>
                                  option.value == cpTypeId
                              )[0].label
                              : ""
                          }
                          value={cpTypeOption.filter(
                            (option) => option.value == cpTypeId
                          )}
                          onChange={(e) => {
                            setCPTypeId(e.value);
                            setChatSphereType(e.label);
                          }}
                        />
                        {
                          errors.cpTypeId && (
                            <div className="invalid-feedback">
                              {t(errors.cpTypeId.message)}
                            </div>
                          )
                        }
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        <b>{t("PHONE_NUMBER")}</b>
                      </label>
                      <div className="col-md-7">
                        <input
                          type="text"
                          className="form-control"
                          placeholder={t("PLEASE_ENTER_USERNAME")}
                          defaultValue={chatSpherePhoneNumber}
                          ref={register({ required: "PLEASE_ENTER_USERNAME" })}
                          name="chatSpherePhoneNumber"
                        />
                        {errors.chatSpherePhoneNumber && (
                          <div className="invalid-feedback">
                            {t(errors.chatSpherePhoneNumber.message)}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-lg-3">
                    <div className="form-group">
                      <button type="submit" className="btn btn-primary">
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </PanelBody>
          </Panel>
        </div>
      </div>
    </div>
  );
};

export default ManageChatSpherePhone;
