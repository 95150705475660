import React, { useState } from "react";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import { createFormBody } from "../../util/Util";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { ApiKey, ApiUrl, LanguageKey } from "../../util/Constant";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";
import { setBusy, setIdle, showMessage, setSaveAdminLog } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

/// <summary>
/// Author : -
/// </summary>

const NonProcessGameRecordReport  = (props) => {
    const _dispatch = useDispatch();
    let _history = useHistory();
    const { t } = useTranslation(LanguageKey._PRIMARY);
    const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
    const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
    const [processReportDatas, setProcessReportDatas] = useState([]);
    const StartManualJob = (betId) => {
        _dispatch(setBusy);
        let apiUrl = ApiUrl._API_MANUAL_RESETTLE_568_PRODUCT_GAME_RECORD_FROM_BO ;
        let params = {
            "betId": betId
        };
        let formBody = createFormBody(params);
        fetch(apiUrl, {
            method: ApiKey._API_POST,
            headers: {
                "Content-Type": ApiKey._API_FORM_URLENCODED,
                "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
            },
            body: formBody
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    _dispatch(
                        showMessage(
                            responseJson[ApiKey._API_SUCCESS_KEY],
                            t("OPERATION_SUCCESS")
                        )
                    )
                }
            });
        _dispatch(setIdle);
    }
    /// <summary>
    /// Author : -
    /// </summary>
    useEffect(() => {
        getNonProcessReport();
    }, []);

    /// <summary>
    /// Author : -
    /// </summary>
    const getNonProcessReport = async () => {
        _dispatch(setBusy());
        let apiUrl = ApiUrl._API_GET_568_NON_PROCESS_GAME_RECORD_REPORT + "?startDate=" + startDate + "&endDate=" + endDate;
        //setGetReportApi(apiUrl);
        await fetch(
            ApiUrl._API_GET_568_NON_PROCESS_GAME_RECORD_REPORT +
            "?startDate=" +
            startDate +
            "&endDate=" +
            endDate, 
            {
                method: ApiKey._API_GET,
                headers: {
                    "Content-Type": ApiKey._API_FORM_URLENCODED,
                    "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
                },
            }
        )
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    console.log(responseJson[ApiKey._API_DATA_KEY]);
                    setProcessReportDatas(responseJson[ApiKey._API_DATA_KEY]);
                } else {
                    _dispatch(
                        showMessage(
                            responseJson[ApiKey._API_SUCCESS_KEY],
                            t(responseJson[ApiKey._API_MESSAGE_KEY])
                        )
                    );
                }
                _dispatch(setIdle());
            });
    };

    let _tableColumns = [
        {
            Header: t("ProcessBet"),
            Cell: ({ row }) => {
                return <><button className="job-button" style={{ width: "100%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartManualJob(row.original.betId)}>{t("START")}</button></>
            },
        },
        {
            Header: t("Id"),
            accessor: "id",
        },
        {
            Header: t("GameId"),
            accessor: "gameId",
        },
        {
            Header: t("BetId"),
            accessor: "betId",
        },
        {
            Header: t("TEXT_FOR_CS"),
            accessor: "textForCs",
            Cell: ({ row }) => {
                let masterProductName = row.original.productGame.product.productName;
                let displayBetId = row.original.betId;
                //displayBetId = displayBetId.includes("-") ? displayBetId.substring(0, displayBetId.indexOf("-")) : displayBetId;
                let displayDateTime = moment(row.original.date).format("YYYY-MM-DD HH:mm:ss.SSS Z");
                displayDateTime = displayDateTime.substring(0, displayDateTime.indexOf("+")) + "GMT" + displayDateTime.substring(displayDateTime.indexOf("+"), displayDateTime.length);
                switch (masterProductName) {
                    case ("PlayTech Slots" || "PlayTech Live"):
                        let transId = displayBetId.substring(0, displayBetId.indexOf("-"));
                        let roundId = displayBetId.substring(displayBetId.indexOf("-") + 1, displayBetId.length);
                        return (
                            <>
                                <div>
                                    Trans ID : {transId}<br />
                                    Round ID : {roundId}<br />
                                    Player ID : {row.original.memberProduct.username}<br />
                                    DateTime : {displayDateTime}<br />
                                </div>
                            </>
                        )
                        break;
                    case "XE88":
                        displayBetId = displayBetId.substring(3, displayBetId.length); //substring 3 because of 'XE_' prefix
                        return (
                            <>
                                <div>
                                    Bet ID : {displayBetId}<br/>
                                    Player ID : {row.original.memberProduct.username}<br />
                                    DateTime : {displayDateTime}<br />
                                </div>
                            </>
                        )
                        break;
                    case "CT855":
                        displayBetId = displayBetId.substring(6, displayBetId.length); //substring 6 because of 'CT855_' prefix
                        let ticketId = displayBetId.substring(0, displayBetId.indexOf("-"));
                        return (
                            <>
                                <div>
                                    Ticket ID : {ticketId}<br />
                                    Player ID : {row.original.memberProduct.username}<br />
                                    DateTime : {displayDateTime}<br />
                                </div>
                            </>
                        )
                        break;
                    default: //568Win games
                        if (masterProductName === "SBO Sports") {
                            return (
                                <>
                                    <div>
                                        Provider : {masterProductName}<br />
                                        Bet ID : {displayBetId}<br />
                                        Player ID : {row.original.memberProduct.username}<br />
                                        DateTime : {displayDateTime}<br />
                                    </div>
                                </>
                            )
                        }
                        else {
                            let transferCode = displayBetId.substring(0, displayBetId.indexOf("-"));
                            let transactionId = displayBetId.substring(displayBetId.indexOf("-") + 1, displayBetId.length);
                            return (
                                <>
                                    <div>
                                        Provider : {masterProductName}<br/>
                                        TransferCode : {transferCode}<br />
                                        Transaction ID : {transactionId}<br />
                                        Username : {row.original.memberProduct.username}<br />
                                        DateTime : {displayDateTime}<br />
                                    </div>
                                </>
                            )
                        }
                        break;
                }
            },
        },
        {
            Header: t("MemberProductId"),
            accessor: "memberProductId",
        },
        {
            Header: t("Status"),
            accessor: "status",
        },
        {
            Header: t("Amount"),
            accessor: "amount",
        },
        {
            Header: t("WinAmount"),
            accessor: "winAmount",
        },
        {
            Header: t("Date"),
            accessor: "date",
            Cell: ({ row }) => {
                return <>{moment(row.original.date).format("YYYY-MM-DD HH:mm:ss")}</>
            },
        },
        {
            Header: t("Remark"),
            accessor: "remark",
        },
        {
            Header: t("Turnover"),
            accessor: "turnover",
        },
        {
            Header: t("UpdatedTime"),
            accessor: "updatedTime",
            Cell: ({ row }) => {
                return <>{moment(row.original.updatedTime).format("YYYY-MM-DD HH:mm:ss")}</>
            },
        },
        {
            Header: t("PreMemberPrimaryBalance"),
            accessor: "preMemberPrimaryBalance",
        },
        {
            Header: t("PostMemberPrimaryBalance"),
            accessor: "postMemberPrimaryBalance",
        },
        {
            Header: t("LatestSyncRecordId"),
            accessor: "latestSyncRecordId",
        },
        {
            Header: t("PreSettleMemberPrimaryBalance"),
            accessor: "preSettleMemberPrimaryBalance",
        },
        {
            Header: t("PostSettleMemberPrimaryBalance"),
            accessor: "postSettleMemberPrimaryBalance",
        },
        {
            Header: t("IsCreditChange"),
            accessor: "isCreditChange",
            Cell: ({ row }) => {
                return row.original.isCreditChange ? <span className="badge badge-secondary badge-green">{t("YES")}</span> : <span className="badge badge-secondary badge-danger">{t("NO")}</span>
            },
        },
        {
            Header: t("SettlementAt"),
            accessor: "settlementAt",
        },
        {
            Header: t("BetIdParam_1"),
            accessor: "betIdParam_1",
        },
        {
            Header: t("Rollbacked"),
            accessor: "rollbacked",
        },
        {
            Header: t("IsJackpot"),
            accessor: "isJackpot",
            Cell: ({ row }) => {
                return row.original.isJackpot ? <span className="badge badge-secondary badge-green">{t("YES")}</span> : <span className="badge badge-secondary badge-danger">{t("NO")}</span>
            },
        },
        {
            Header: t("IsProcessed"),
            accessor: "isProcessed",
            Cell: ({ row }) => {
                return row.original.isProcessed ? <span className="badge badge-secondary badge-green">{t("YES")}</span> : <span className="badge badge-secondary badge-danger">{t("NO")}</span>
            },
        },
        {
            Header: t("ValidTurnover"),
            accessor: "validTurnover",
            Cell: ({ row }) => {
                return <>{(row.original.validTurnover).toFixed(3)}</>
            },
        }
    ];

    /// <summary>
    /// Author : -
    /// </summary>
    const handleEvent = (event, picker) => {
        setStartDate(moment(picker.startDate).format("YYYY-MM-DD"));
        setEndDate(moment(picker.endDate).format("YYYY-MM-DD"));
  };

    async function adminLog() {

      let params = {
        "startDate": startDate,
        "endDate": endDate,
      };

      _dispatch(setSaveAdminLog("VIEW_BO_NON_PROCESS_GAME_RECORD_REPORT", _history.location.pathname, params));
  }

    return (
        <div>
            <h1 className="page-header">{t("568_NON_PROCESS_GAME_RECORD_REPORT")}</h1>
            <Panel>
                <PanelBody>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="row">
                                <div className="col-lg-4">
                                    <div
                                        className="form-group"
                                        style={{ display: "flex", width: "100%" }}
                                    >
                                        <div className="input-group" style={{ width: "100%" }}>
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                    <i className="fa fa-calendar"></i>
                                                </span>
                                            </div>
                                            <div style={{ width: "80%" }}>
                                                <DateRangePicker
                                                    containerStyles={{ width: "100%" }}
                                                    startDate={startDate}
                                                    endDate={endDate}
                                                    onApply={handleEvent}
                                                    alwaysShowCalendars={true}
                                                    locale={{ format: "YYYY-MM-DD" }}
                                                    ranges={{
                                                        Today: [moment(), moment()],
                                                        Yesterday: [
                                                            moment().subtract(1, "days"),
                                                            moment().subtract(1, "days"),
                                                        ],
                                                        "Last 7 Days": [
                                                            moment().subtract(6, "days"),
                                                            moment(),
                                                        ],
                                                        "Last 30 Days": [
                                                            moment().subtract(29, "days"),
                                                            moment(),
                                                        ],
                                                        "This Month": [
                                                            moment().startOf("month"),
                                                            moment().endOf("month"),
                                                        ],
                                                        "Last Month": [
                                                            moment().subtract(1, "month").startOf("month"),
                                                            moment().subtract(1, "month").endOf("month"),
                                                        ],
                                                    }}
                                                >
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={startDate + " - " + endDate}
                                                    />
                                                </DateRangePicker>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={() => {getNonProcessReport();adminLog()}}
                                    >
                                        {t("SEARCH")}
                                    </button>
                                </div>
                            </div>
                            <hr />
                            <ReactTable data={processReportDatas} columns={_tableColumns} />
                        </div>
                    </div>
                </PanelBody>
            </Panel>
        </div>
    );
};

export default NonProcessGameRecordReport ;
