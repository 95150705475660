import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import { useHistory } from "react-router-dom";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import {
    ApiKey,
    ApiUrl,
    LanguageKey,
} from "../../util/Constant";
import { setBusy, setIdle, setSaveAdminLog, showMessage } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ApiEngine from "../../util/ApiEngine";
import { createFormBody, createMultiPartFormBody, stringIsNullOrEmpty } from "../../util/Util.js";
import moment from 'moment';

const AutoDepositSetting = props => {
    const { t } = useTranslation(LanguageKey._PRIMARY);
    const _dispatch = useDispatch();
    const _history = useHistory();
   
    const [status, setStatus] = useState(false);
    const [initCheck, setInitCheck] = useState(false);

    const {
        register,
        unregister,
        handleSubmit,
        errors,
        setError,
        control,
        setValue,
    } = useForm();

    /// <summary>
    /// Author : -
    /// </summary>
    useEffect(() => {
        init();
        setInitCheck(true);
    }, []);

    async function init() {
        let apiUrl = ApiUrl._API_GET_USER_SYSTEM_SETTINGS + "?type=settings";
        var responseJson = await ApiEngine.get(apiUrl);
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            let data = responseJson[ApiKey._API_DATA_KEY];
            data.map((keyValuePair, index) => {
                
                if (keyValuePair['key'] === "AutoDeposit") {
                    let trueOrFalse = (keyValuePair['value'] === 'true');
                    setStatus(trueOrFalse);
                }
            });
        }
    }

    const submitForm = async (data) => {
        let params = {
            'status': data.status
        };
        _dispatch(setSaveAdminLog("EDIT_AUTO_DEBIT_SETTING", _history.location.pathname, params));

        let formBody = createMultiPartFormBody(params);
        let apiUrl = ApiUrl._API_CREATE_OR_UPDATE_SYSTEM_SETTINGS + "?type=autoDeposit";
        let responseJson = await ApiEngine.post(apiUrl, formBody);
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            init();
        }
        _dispatch(showMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
    }

    return (
        <div>
            <h1 className="page-header">
                {t("AUTO_DEPOSIT_SETTING")}
                <NavigationButton history={_history} />
            </h1>
            <div className="row">
                <div className="col-xl-12">
                    {initCheck && <Panel>
                        <PanelBody>
                            <form onSubmit={handleSubmit(submitForm)}>
                                <div className="col-lg-8">
                                    <div className="form-group row m-b-15">
                                        <label className="col-md-4 col-form-label">
                                            <b>{t("STATUS")}</b>
                                        </label>
                                        <div className="col-md-7">
                                            <div className="switcher">
                                                <input
                                                    type="checkbox"
                                                    name="status"
                                                    id="status"
                                                    onChange={(e) => setStatus(e.target.checked)}
                                                    value={true}
                                                    checked={status}
                                                    ref={register}
                                                />
                                                <label htmlFor="status"></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-lg-3">
                                        <div className="form-group">
                                            <button type="submit" className="btn btn-primary">
                                                {t("SUBMIT")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </PanelBody>
                    </Panel>}
                </div>
            </div>
        </div>
    )
}

export default AutoDepositSetting;
