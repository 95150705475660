import React from 'react';
import { Link } from 'react-router-dom'
import DropdownNotification from './dropdown/notification.jsx';
import DropdownLanguage from './dropdown/language.jsx';
import DropdownProfile from './dropdown/profile.jsx';
import DropdownMegaMenu from './dropdown/mega.jsx';
import { PageSettings } from './../../config/page-settings.js';
import '../../assets/css/custom.css';
import { fetch } from 'whatwg-fetch';
import { ApiKey, ApiUrl, WebUrl, AccessRight } from "../../util/Constant";
import { connect } from "react-redux";
import { clearMessage } from '../../redux/FirebaseAction';
import { ToastContainer } from 'react-toastify';
import notificationSound from '../../assets/notification.mp3';
import Icon from '@mdi/react';
import { mdiCircleSlice8 } from '@mdi/js';
import DND_ACTIVE from './../../assets/img/dnd-active.svg';
import DND_INACTIVE from './../../assets/img/dnd-inactive.svg';
import { checkIfPermissionExist } from "../../util/PermissionChecker";

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.audio = new Audio(notificationSound);
        this.toggleMegaMenu = this.toggleMegaMenu.bind(this);
        this.state = {
            soundPlayed: false,
            collapseMegaMenu: false,
            doNotDisturb: window.sessionStorage.getItem("doNotDisturb"),
            notifications: []
        };
        this.checkPendingDeposit = this.checkPendingDeposit.bind(this);
    }

    toggleMegaMenu() {
        this.setState({ collapseMegaMenu: !this.state.collapseMegaMenu });
    }

    getRecentNotification = () => {
        if (!window.location.pathname.match(/login/)) {
            fetch(ApiUrl._API_GET_RECENT_NOTIFICATIONS, {
                method: ApiKey._API_GET,
                headers: {
                    "Accept": ApiKey._API_APPLICATION_JSON,
                    "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY
                }
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                        this.setState({ notifications: responseJson[ApiKey._API_DATA_KEY] });
                    }
                });
        }
    }

    toggleNotificationSound = (data) => {
        if (!this.state.doNotDisturb) {
            if (data > 0) {
                this.audio.loop = true;
                this.audio.play();
                this.state.soundPlayed = true;
            }
        }
        else {
            this.audio.loop = false;
            this.audio.pause();
            this.state.soundPlayed = false;
        }
    }

    checkPendingDeposit() {
        if (window.location.pathname !== "/login" && window.location.pathname !== "/liveMonitor" && window.location.pathname !== "/sportsLiveMonitor") {
            fetch(ApiUrl._API_CHECK_PENDING_DEPOSIT, {
                method: ApiKey._API_GET,
                headers: {
                    "Accept": ApiKey._API_APPLICATION_JSON,
                    "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY
                }
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                        this.toggleNotificationSound(responseJson[ApiKey._API_DATA_KEY]);
                    }
                });
        }
    }

    toggleDND() {
        if (this.state.doNotDisturb) { //turn off doNotDisturb
            window.sessionStorage.setItem("doNotDisturb", false);
            this.audio.loop = true;
            this.audio.play();
            this.state.soundPlayed = true;
        }
        else { //turn on doNotDisturb
            window.sessionStorage.setItem("doNotDisturb", true);
            this.audio.loop = false;
            this.audio.pause();
            this.state.soundPlayed = false;
        }
        this.setState({ doNotDisturb: !this.state.doNotDisturb });
    }

    componentDidUpdate() {
        if (this.props.firebaseState.dataContent != null) {
            this.getRecentNotification();
            this.checkPendingDeposit();
            this.props.clearMessage();
        }
    }

    componentDidMount() {
        this.getRecentNotification();
        this.checkPendingDeposit();
        var intervalId = setInterval(this.checkPendingDeposit, 180000);
        this.setState({ intervalId: intervalId });
    }

    componentWillUnmount() {
        clearInterval(this.state.intervalId);
        this.audio.loop = false;
        this.audio.pause();
        this.state.soundPlayed = false;
    }

    render() {
        return (

            <PageSettings.Consumer>
                {({ toggleMobileSidebar, toggleRightSidebar, toggleMobileRightSidebar, toggleMobileTopMenu, pageHeaderLanguageBar, pageHeaderMegaMenu, pageTwoSidebar, pageTopMenu, pageSidebar }) => (
                    <div id="header" className="header navbar-default">
                        <div className="navbar-header">
                            {pageTwoSidebar && (
                                <button type="button" className="navbar-toggle pull-left" onClick={toggleMobileRightSidebar}>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                </button>
                            )}
                            <Link to="/" className="navbar-brand"><span className="navbar-logo"></span> <b>M93</b> Back Office</Link>

                            {pageHeaderMegaMenu && (
                                <button type="button" className="navbar-toggle pt-0 pb-0 mr-0" onClick={this.toggleMegaMenu}>
                                    <span className="fa-stack fa-lg text-inverse">
                                        <i className="far fa-square fa-stack-2x"></i>
                                        <i className="fa fa-cog fa-stack-1x"></i>
                                    </span>
                                </button>
                            )}
                            {pageTopMenu && pageSidebar && (
                                <button type="button" className="navbar-toggle pt-0 pb-0 mr-0 collapsed" onClick={toggleMobileTopMenu}>
                                    <span className="fa-stack fa-lg text-inverse">
                                        <i className="far fa-square fa-stack-2x"></i>
                                        <i className="fa fa-cog fa-stack-1x"></i>
                                    </span>
                                </button>
                            )}
                            {!pageSidebar && pageTopMenu && (
                                <button type="button" className="navbar-toggle" onClick={toggleMobileTopMenu}>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                </button>
                            )}
                            {pageSidebar && (
                                <button type="button" className="navbar-toggle" onClick={toggleMobileSidebar}>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                </button>
                            )}
                        </div>

                        {pageHeaderMegaMenu && (
                            <DropdownMegaMenu collapse={this.state.collapseMegaMenu} />
                        )}

                        {!this.state.doNotDisturb && <ToastContainer
                            autoClose={5000}
                            hideProgressBar={true}
                        />}

                        <ul className="navbar-nav navbar-right">

                            {checkIfPermissionExist(AccessRight._TRANSACTION_ENQUIRY_PERMISSION, true) && 
                              <>
                                <li onClick={() => this.toggleDND()}>
                                    <a>{this.state.doNotDisturb ? <i className="fa fas fa-moon"></i> : <i className="far fa-moon"></i>}</a>
                                </li>

                                <DropdownNotification notifications={this.state.notifications} readNotification={this.getRecentNotification} />

                                {pageHeaderLanguageBar && (
                                    <DropdownLanguage />
                                )}
                              
                              </>
                            }

                            <DropdownProfile />

                            {pageTwoSidebar && (
                                <li className="divider d-none d-md-block"></li>
                            )}

                            {pageTwoSidebar && (
                                <li className="d-none d-md-block">
                                    <Link to="/" onClick={toggleRightSidebar} className="f-s-14">
                                        <i className="fa fa-th"></i>
                                    </Link>
                                </li>
                            )}
                        </ul>
                    </div>
                )}
            </PageSettings.Consumer>
        )
    }
}

const mapStateToProps = state => ({
    ...state
});

export default connect(
    mapStateToProps,
    {
        clearMessage: clearMessage
    }
)(Header);