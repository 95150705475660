import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Panel, PanelBody } from "./../../../components/panel/panel.jsx";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import NavigationButton from "../../../components/constantComponent/button/NavigationButton";
import { stringIsNullOrEmpty, createFormBody } from "../../../util/Util";
import { ApiKey, ApiUrl, LanguageKey, SmsType } from "../../../util/Constant";
import { useLocation } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { showMessage } from "../../../redux/AppAction";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import ApiEngine from "../../../util/ApiEngine";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import ChartSphereDetailLanguage from "./ChaTSphereDetailLanguage.js";
/// <summary>
/// Author : -
/// </summary>
const ChatSphereDetail = (props) => {
  let _history = useHistory();
  const _DETAIL_TAB = "1";
  const _LANGUAGE_TAB = "2";
  const _DFAULT_LANGUAGE_VALUE = -1;
  const { t } = useTranslation(LanguageKey._PRIMARY);
  const _location = useLocation();
  const _dispatch = useDispatch();
  const {
    register,
    unregister,
    handleSubmit,
    errors,
    reset,
    setError,
    setValue,
    control,
  } = useForm();
  const [cpContentId, setCPContentId] = useState();
  const [status, setStatus] = useState(false);

  const [cpId, setCPId] = useState(0);
  const [contentError, setContentError] = useState("");
  const [cpTypeOption, setCPTypeOption] = useState([]);
  const [content, setContent] = useState("");
  const [cpTypeId, setCPTypeId] = useState(0);

  const TAG_OPTIONS = [
    { value: "{USERNAME}", label: "USERNAME" },
    { value: "{PASSWORD}", label: "PASSWORD" },
    { value: "{LINK}", label: "LINK" }
  ];

  const [activeTab, setActiveTab] = useState(_DETAIL_TAB);

  useEffect(() => {
    init();
  }, [_location.state != undefined]);

  /// <summary>
  /// Author : -
  /// </summary>
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const tagChangeHandler = (e) => {

    let textarea = content;
    setContent(textarea + e.value);
  };
  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    init();
  }, []);

  /// <summary>
  /// Author : -
  /// </summary>
  const init = async () => {
    try {
      let storedContent = "";

      if (_location.state) {
        setCPContentId(_location.state.id);
        var apiUrl =
          ApiUrl._API_GET_CHAT_SPHERE_CONTENT_BY_ID;
        apiUrl += "?id=" + _location.state.id;
        let responseJson = await ApiEngine.get(apiUrl);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          var data = responseJson[ApiKey._API_DATA_KEY];
          setContent(data["content"]);
          setCPTypeId(data["chatSphereTypeId"]);
          setStatus(data["status"]);
        } else {
          throw t(responseJson[ApiKey._API_MESSAGE_KEY]);
        }
      }

    } catch (err) {
      _dispatch(showMessage(false, err));
    }

    await fetch(ApiUrl._API_GET_CHAT_SPHERE_TYPE, {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          const cpList = [];
          responseJson[ApiKey._API_DATA_KEY].forEach(function (gateway) {
            cpList.push({ label: gateway.name, value: gateway.id });
          });

          setCPTypeOption(cpList);
        }
      });
  };

  /// <summary>
  /// Author : -
  /// </summary>
  const submitForm = async (data, e) => {
    setContentError("");

    let params = {
      status: data.status,
      cpTypeId: cpTypeId,
      content: content
    };

    if (!stringIsNullOrEmpty(cpContentId)) {
      params["id"] = cpContentId;
    }

    let formBody = createFormBody(params);
    let responseJson = await ApiEngine.post(
      ApiUrl._API_CREATE_OR_UPDATE_CHAT_SHPERE_CONTENT,
      formBody
    );

    _dispatch(
      showMessage(
        responseJson[ApiKey._API_SUCCESS_KEY],
        t(responseJson[ApiKey._API_MESSAGE_KEY])
      )
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setCPContentId(responseJson[ApiKey._API_DATA_KEY]["id"]);
      _history.replace({
        pathname: _location.pathname,
        state: {
          id: responseJson[ApiKey._API_DATA_KEY]["id"],
        },
      });
    }
  };

  return (
    <div>
      <h1 className="page-header">
        {cpContentId ? t("EDIT_CHAT_SPHERE_DETAIL") : t("ADD_CHAT_SPHERE_DETAIL")}
        <NavigationButton history={_history} />
      </h1>
      {cpContentId && (
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === _DETAIL_TAB })}
              onClick={() => {
                toggle(_DETAIL_TAB);
              }}
            >
              {t("DETAIL")}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === _LANGUAGE_TAB })}
              onClick={() => {
                toggle(_LANGUAGE_TAB);
              }}
            >
              {t("LANGUAGE")}
            </NavLink>
          </NavItem>
        </Nav>
      )}
      <TabContent activeTab={activeTab}>
        <TabPane tabId={_DETAIL_TAB}>
          <div className="row">
            <div className="col-xl-12">
              <Panel>
                <PanelBody>
                  <form onSubmit={handleSubmit(submitForm)}>
                    <div className="row">
                      <div className="col-lg-8">
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>{t("SMS_TYPE")}</b>
                          </label>
                          <div className="col-md-7">
                            <Select
                              name="cpTypeId"
                              options={cpTypeOption}
                              placeholder={
                                cpTypeOption.filter(
                                  (option) => option.value == cpTypeId
                                )[0] !== undefined
                                  ? cpTypeOption.filter(
                                    (option) =>
                                      option.value == cpTypeId
                                  )[0].label
                                  : ""
                              }
                              value={cpTypeOption.filter(
                                (option) => option.value == cpTypeId
                              )}
                              onChange={(e) => {
                                setCPTypeId(e.value);
                              }}
                            />
                            {
                              errors.cpTypeId && (
                                <div className="invalid-feedback">
                                  {t(errors.cpTypeId.message)}
                                </div>
                              )
                            }
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>
                              {t("CONTENT")}
                            </b>
                          </label>
                          <div className="col-md-7">
                            <Select
                              options={TAG_OPTIONS}
                              onChange={tagChangeHandler}
                            />
                            <textarea
                              className="form-control"
                              rows="5"
                              style={{ marginTop: "10px" }}
                              ref={register}
                              name="content"
                              defaultValue={content}
                              onChange={(e) => {
                                setContent(e.target.value);
                                setValue("content", e.target.value);
                              }}
                            ></textarea>
                            {errors.content && (
                              <div className="invalid-feedback">
                                {t(errors.content.message)}
                              </div>
                            )}
                            {!stringIsNullOrEmpty(contentError) && (
                              <div className="invalid-feedback">
                                {t(contentError)}
                              </div>
                            )}
                            <div className="m-t-10" style={{ lineHeight: "28px" }}>
                              <b>Tags to be used for dynamic value, </b>
                              <span className="label label-blue f-s-12 m-2">
                                &#123;USERNAME&#125;
                              </span>
                              <span className="label label-blue f-s-12 m-2">
                                &#123;PASSWORD&#125;
                              </span>
                              <span className="label label-blue f-s-12 m-2">
                                &#123;LINK&#125;
                              </span>
                            </div>
                            <div className="m-t-10" style={{ lineHeight: "28px" }}>
                              <b>May get emoji cheat sheet with this link, </b>
                              <a href="https://www.webfx.com/tools/emoji-cheat-sheet/" target="_blank">Emoji Cheat Sheet</a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>{t("STATUS")}</b>
                          </label>
                          <div className="col-md-7">
                            <div className="switcher">
                              <input
                                type="checkbox"
                                name="status"
                                id="status"
                                onChange={(e) => setStatus(e.target.checked)}
                                value={true}
                                checked={status}
                                ref={register}
                              />
                              <label htmlFor="status"></label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-lg-3">
                        <div className="form-group">
                          <button type="submit" className="btn btn-primary">
                            {t("SUBMIT")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </PanelBody>
              </Panel>
            </div>
          </div>
        </TabPane>
        <TabPane tabId={_LANGUAGE_TAB}>
          {cpContentId && (
            <div className="row">
              <div className="col-lg-12">
                <ChartSphereDetailLanguage
                  id={cpContentId}
                />
              </div>
            </div>
          )}
        </TabPane>
      </TabContent>
    </div>
  );
};
export default ChatSphereDetail;
