import React, { useState } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import { ApiKey, ApiUrl, LanguageKey } from "../../../util/Constant";
import { createFormBody } from "../../../util/Util";
import { useForm } from "react-hook-form";
import { setBusy, setIdle, showMessage, setSaveAdminLog } from "../../../redux/AppAction";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";
import { useHistory } from "react-router-dom";

/// <summary>
/// Author : -
/// </summary>
const BankSummaryAddCreditDebit = (props) => {
  const _dispatch = useDispatch();
  let _history = useHistory();
  const { t } = useTranslation(LanguageKey._PRIMARY);
  const { register, handleSubmit, errors, reset } = useForm();
  const [dateTime, setDateTime] = useState(
    moment().format("YYYY-MM-DD HH:mm:ss")
  );

  /// <summary>
  /// Author : -
  /// </summary>
  const submitForm = async (data, e) => {
    let params = {
      bankAccountId: props.data.bankAccountId,
      transactionTypeId: props.type,
      amount: data.amount,
      transactionDateTime: dateTime,
      remark: data.remark,
    };

    let formBody = createFormBody(params);
    _dispatch(setBusy());

    _dispatch(setSaveAdminLog(props.type == 1 ? "CREATE_BANK_TRANSACTION_CREDIT" : "CREATE_BANK_TRANSACTION_DEBIT", _history.location.pathname, params));

    await fetch(ApiUrl._API_CREATE_BANK_TRANSACTION, {
      method: ApiKey._API_POST,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
      body: formBody,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        _dispatch(
          showMessage(
            responseJson[ApiKey._API_SUCCESS_KEY],
            t(responseJson[ApiKey._API_MESSAGE_KEY])
          )
        );
        _dispatch(setIdle());

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          e.target.reset();
          props.getBankSummaryDatas();
        }
      });
  };

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <div className="row row-marginBottom-10">
        <div className="col-lg-4">
          <label style={{ float: "right" }}>
            <b>{t("BANK")}</b>
          </label>
        </div>
        <div className="col-lg-8">
          <label>{props.data.bankName}</label>
        </div>
      </div>
      <div className="row row-marginBottom-10">
        <div className="col-lg-4">
          <label style={{ float: "right" }}>
            <b>{t("BANK_ACCOUNT_HOLDER_NAME")}</b>
          </label>
        </div>
        <div className="col-lg-8">
          <label>{props.data.bankAccountHolderName}</label>
        </div>
      </div>
      <div className="row row-marginBottom-10">
        <div className="col-lg-4">
          <label style={{ float: "right" }}>
            <b>{t("BANK_ACCOUNT_NUMBER")}</b>
          </label>
        </div>
        <div className="col-lg-8">
          <label>{props.data.bankAccountNumber}</label>
        </div>
      </div>
      <div className="row row-marginBottom-10">
        <div className="col-lg-4">
          <label style={{ float: "right" }}>
            <b>{t("AMOUNT")}</b>
          </label>
        </div>
        <div className="col-lg-8">
          <input
            type="number"
            step="0.01"
            className="form-control"
            placeholder={t("PLEASE_ENTER_AMOUNT")}
            name="amount"
            defaultValue={0}
            ref={register({
              required: "PLEASE_ENTER_AMOUNT",
              min: {
                value: 0,
                message: "PLEASE_ENTER_VALUE_NOT_LESS_THAN_ZERO",
              },
            })}
          />
          {errors.amount && (
            <div className="invalid-feedback">{t(errors.amount.message)}</div>
          )}
        </div>
      </div>
      <div className="row row-marginBottom-10">
        <div className="col-lg-4">
          <label style={{ float: "right" }}>
            <b>{t("DATE")}</b>
          </label>
        </div>
        <div className="col-lg-8">
          <div className="form-group" style={{ display: "flex" }}>
            <div className="input-group" style={{ width: "unset" }}>
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="fa fa-calendar"></i>
                </span>
              </div>
              <div style={{ width: "80%" }}>
                <DateRangePicker
                  startDate={dateTime}
                  timePicker={true}
                  singleDatePicker={true}
                  timePickerSeconds={true}
                  locale={{ format: "YYYY-MM-DD HH:mm:ss" }}
                  onApply={(event, picker) => {
                    setDateTime(
                      moment(picker.startDate).format("YYYY-MM-DD HH:mm:ss")
                    );
                  }}
                >
                  <input
                    type="text"
                    className="form-control"
                    value={dateTime}
                  />
                </DateRangePicker>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row row-marginBottom-10">
        <div className="col-lg-4">
          <label style={{ float: "right" }}>
            <b>{t("REMARK")}</b>
          </label>
        </div>
        <div className="col-lg-8">
          <input
            type="text"
            name="remark"
            ref={register({
              required: "PLEASE_ENTER_REMARK",
            })}
            className="form-control"
            placeholder={t("REMARK")}
          />
          {errors.remark && (
            <div className="invalid-feedback">{t(errors.remark.message)}</div>
          )}
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-lg-3">
          <button type="submit" className="btn btn-primary">
            {t("SUBMIT")}
          </button>
        </div>
      </div>
    </form>
  );
};

export default BankSummaryAddCreditDebit;
