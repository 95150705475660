import React, { useState, useEffect } from 'react';
import ReactTable from '../../components/constantComponent/reactTable/ReactTable';
import { Panel, PanelBody } from './../../components/panel/panel.jsx';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { ApiKey, ApiUrl, LanguageKey, DEFAULT_PAGE_SIZE } from '../../util/Constant';
import { useTranslation } from 'react-i18next';
import ApiEngine from '../../util/ApiEngine';
import { numberWithCurrencyFormat, stringIsNullOrEmpty } from "../../util/Util";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setBusy, setIdle, setSaveAdminLog } from "../../redux/AppAction";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { AsyncPaginate } from "react-select-async-paginate";

const DailyCheckInReport = props => {
  const { t } = useTranslation(LanguageKey._PRIMARY);
  const [monthDate, setMonthDate] = useState(moment().startOf('day').format("YYYY-MM-DD HH:mm:ss"));
  const [checkInApiUrl, setCheckInApiUrl] = useState("");
  const [monthList, setMonthList] = useState([]);
  const _dispatch = useDispatch();
  let _history = useHistory();
  const [startDate, setStartDate] = useState(
    moment().startOf("day").format("YYYY-MM-DD HH:mm:ss")
  );
  const [endDate, setEndDate] = useState(
    moment().endOf("day").format("YYYY-MM-DD HH:mm:ss")
  );
  const [labelUsername, setLabelUsername] = useState("Select...");
  const [selectedMember, setSelectedMember] = useState();


  useEffect(() => {
    init();
  }, []);

  /// <summary>
  /// Author : -
  /// </summary>
  const handleEvent = (event, picker) => {
    setStartDate(moment(picker.startDate).format("YYYY-MM-DD HH:mm:ss"));
    setEndDate(moment(picker.endDate).format("YYYY-MM-DD HH:mm:ss"));
  };

  const init = () => {
    generateMonth()
  }

  useEffect(() => {
    getActiveMember();
  }, []);

  /// <summary>
  /// Author : -
  /// </summary>
  const getActiveMember = async () => {
    let fetchUrl = ApiUrl._API_GET_DAILY_CHECK_IN_REPORT;
    if (!stringIsNullOrEmpty(selectedMember)) {

      console.log("1", selectedMember)
      fetchUrl += "?startDate=" + startDate + "&endDate=" + endDate + "&selectedMember=" + selectedMember.value;
    } else {

      console.log("2", selectedMember)
      fetchUrl += "?startDate=" + startDate + "&endDate=" + endDate;
    }
    setCheckInApiUrl(fetchUrl);
  }

  let _tableColumns = [
    {
      Header: t("USERNAME"),
      accessor: "username",
      minWidth: 100,
      Footer: () => {
         return <span><b>{t("TOTAL")}: </b></span>
      },
    },
    {
      Header: t("POINTS_EARNED"),
      accessor: "totalPoint",
      Cell: ({ row }) => {
        return <>{numberWithCurrencyFormat(parseFloat(row.original.totalPoint), 2)}</>
      },
      minWidth: 100,
      Footer: ({ page }) => {
        const total = page.reduce((sum, currentValue) => {
          if (currentValue.original.totalPoint) {
            return sum + (parseFloat(currentValue.original.totalPoint));
          }
          else {
            return sum;
          }
        }, 0);
        return <span><b>{numberWithCurrencyFormat(parseFloat(total))}</b></span>
      }
    },
    {
      Header: t("CREDIT_EARNED"),
      accessor: "totalCredit",
      Cell: ({ row }) => {
        return <>{numberWithCurrencyFormat(parseFloat(row.original.totalCredit), 2)}</>

      },
      minWidth: 100,
      Footer: ({ page }) => {
        const total = page.reduce((sum, currentValue) => {
          if (currentValue.original.totalCredit) {
            return sum + (parseFloat(currentValue.original.totalCredit));
          }
          else {
            return sum;
          }
        }, 0);
        return <span><b>{numberWithCurrencyFormat(parseFloat(total))}</b></span>
      }
    },
    {
      Header: t("DAY"),
      accessor: "days",
      minWidth: 100,
    },
    {
      Header: t("LAST_CHECK_IN"),
      accessor: "lastCheckIn",
      minWidth: 100,
      Cell: ({ row }) => {
        return <>{moment(row.original.lastCheckIn).format("YYYY-MM-DD HH:mm:ss")}</>
      }
    },
  ]

  const generateMonth = () => {
    var emptyList = []
    for (var i = 0; i <= 6; i++) {
      var date = moment().subtract(i, 'months');
      var year = date.get('year');
      var month = date.format('MMMM');
      emptyList.push({ "year": year, "month": month });
    }
    setMonthList(emptyList.reverse());
  }

  async function adminLog() {
    let params = {
      "monthDate": monthDate,
    };

    _dispatch(setSaveAdminLog("VIEW_ACTIVE_MEMBER_REPORT", _history.location.pathname, params));
  }

  async function getMemberList(search, loadOptions, { page }) {
    var responseJson = await ApiEngine.get(
      `${ApiUrl._API_GET_USER_MEMBER_LIST}?start=${(page - 1) * DEFAULT_PAGE_SIZE
      }&count=${DEFAULT_PAGE_SIZE}&keyword=${search}`
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY]["data"];
      let formattedData = data.map((m) => {
        return { label: m.username, value: m.id };
      });

      return {
        options: formattedData,
        hasMore: formattedData.length == DEFAULT_PAGE_SIZE,
        additional: {
          page: page + 1,
        },
      };
    }
  }

  return (
    <div>
      <h1 className="page-header">{t("DAILY_CHECK_IN_REPORT")}</h1>
      <Panel>
        <div className="row">
          <div className="col-lg-12">
            <PanelBody>
              <div className="row">
                <div className="col-lg-4">
                  <div
                    className="form-group"
                    style={{ display: "flex", width: "100%" }}
                  >
                    <div
                      className="input-group"
                      style={{ width: "100%", flexFlow: "nowrap" }}
                    >
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="fa fa-calendar"></i>
                        </span>
                      </div>
                      <div style={{ width: "80%" }}>
                        <DateRangePicker
                          containerStyles={{ width: "100%" }}
                          startDate={startDate}
                          endDate={endDate}
                          onApply={handleEvent}
                          alwaysShowCalendars={true}
                          locale={{ format: "YYYY-MM-DD HH:mm:ss" }}
                          ranges={{
                            Today: [moment(), moment()],
                            Yesterday: [
                              moment().subtract(1, "days"),
                              moment().subtract(1, "days"),
                            ],
                            "Last 7 Days": [
                              moment().subtract(6, "days"),
                              moment(),
                            ],
                            "This Month": [
                              moment().startOf("month"),
                              moment().endOf("month"),
                            ],
                            "Last Month": [
                              moment().subtract(1, "month").startOf("month"),
                              moment().subtract(1, "month").endOf("month"),
                            ],
                          }}
                        >
                          <input
                            type="text"
                            className="form-control"
                            value={startDate + " - " + endDate}
                          />
                        </DateRangePicker>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div
                    className="input-group mb-3"
                    style={{ flexFlow: "nowrap" }}
                  >
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="fas fa-user"></i>
                      </span>
                    </div>
                    <div
                      className="input-group-append"
                      style={{ width: "100%" }}
                    >
                      <AsyncPaginate
                        styles={{
                          container: () => ({
                            width: "100%",
                          }),
                        }}
                        placeholder={labelUsername}
                        debounceTimeout={250}
                        isDisabled={!stringIsNullOrEmpty(props.id)}
                        loadOptions={getMemberList}
                        additional={{
                          page: 1,
                        }}
                        value={selectedMember}
                        onChange={setSelectedMember}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 text-align-right">
                  <button type="button" onClick={() => { adminLog(); getActiveMember(); }} className="btn btn-primary">{t("SEARCH")}</button>
                </div>
              </div>
              <hr />
              <ReactTable fetchUrl={checkInApiUrl} columns={_tableColumns} renderFooter={true} />
            </PanelBody>
          </div>
        </div>
      </Panel>
    </div>
  )
}

export default DailyCheckInReport;
