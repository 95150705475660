import React, { useState, useEffect } from "react";
import ReactTable from "../../../components/constantComponent/reactTable/ReactTable";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "./../../../components/panel/panel.jsx";
import { useHistory } from "react-router-dom";
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { navigateTo } from "../../../util/Util";
import {
  WebUrl,
  ApiKey,
  ApiUrl,
  AccessRight,
  LanguageKey,
} from "../../../util/Constant";
import NavigationButton from "../../../components/constantComponent/button/NavigationButton";
import { checkIfPermissionExist } from "../../../util/PermissionChecker";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";

/// <summary>
/// Author : -
/// </summary>
const ManageAutoWithdrawalBankAccount = (props) => {
  const { t } = useTranslation(LanguageKey._PRIMARY);
  let _history = useHistory();
  const [automatedWithdrawalBankAccountData, setAutomatedWithdrawalBankAccountData] = useState([]);

  let _tableColumns = [
    {
      Header: "INSTANY_PAYMENT_GATEWAY",
      accessor: "insantPaySettingName",
    },
    {
      Header: "BANK_NAME",
      accessor: "item.bankName",
      width: 100,
    },
    {
      Header: t("BANK_ACCOUNT_HOLDER_NAME"),
      accessor: "item.bankHolderName",
    },
    {
      Header: "BANK_ACCOUNT_NUMBER",
      accessor: "item.bankAccountNumber",
      style: {
        whiteSpace: "unset",
        wordBreak: "break-word",
      },
    },
    {
      id: "status",
      Header: "STATUS",
      accessor: (d) => {
        return d.item.status ? (
          <span className="badge badge-secondary badge-green">{t("ACTIVE")}</span>
        ) : (
          <span className="badge badge-secondary badge-danger">
            {t("SUSPENDED")}
          </span>
        );
      },
    },
    {
      id: "action",
      Header: "",
      Cell: ({ row }) =>
        checkIfPermissionExist(AccessRight._MANAGE_AUTOWITHDRAWAL_BANK_ACCOUNT_PERMISSION, true) && (
          <div className="btn-group m-r-5 m-b-5">
            <UncontrolledButtonDropdown>
              <DropdownToggle caret color="default">
                <i className="fas fa-cog"></i>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={() => {
                    navigateTo(
                      _history,
                      WebUrl._URL_AUTOWITHDRAWAL_BANK_ACCOUNT_DETAIL,
                      row.original
                    );
                  }}
                >
                  <span className="text-warning">{t("EDIT")}</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        ),
      disableSortBy: true,
      disableFilters: true,
      width: 100,
      style: { overflow: "visible" },
    },
  ];

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    fetch(ApiUrl._API_GET_AUTOMATEDWITHDRAWAL_BANK_ACCOUNT, {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          setAutomatedWithdrawalBankAccountData(responseJson[ApiKey._API_DATA_KEY]);
        }
      });
  }, []);

  return (
    <div>
      <h1 className="page-header">
        {t("MANAGE_AUTOWITHDRAWAL_BANK_ACCOUNT")}
        {checkIfPermissionExist(AccessRight._MANAGE_AUTOWITHDRAWAL_BANK_ACCOUNT_PERMISSION, true) && (
          <NavigationButton
            history={_history}
            url={WebUrl._URL_AUTOWITHDRAWAL_BANK_ACCOUNT_DETAIL}
            buttonText={t("ADD_NEW_AUTOWITHDRAWAL_BANK")}
          />
        )}
      </h1>
      <Panel>
        <PanelBody>
          <ReactTable data={automatedWithdrawalBankAccountData} columns={_tableColumns} />
        </PanelBody>
      </Panel>
    </div>
  );
};

export default ManageAutoWithdrawalBankAccount;
