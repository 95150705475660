import React, { useState, useEffect } from "react";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import "../../assets/css/custom.css";
import { useHistory } from "react-router-dom";
import {
    UncontrolledButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";
import { navigateTo, stringIsNullOrEmpty } from "../../util/Util";
import {
    ApiUrl,
    ApiKey,
    WebUrl,
    AccessRight,
    LanguageKey,
    DEFAULT_PAGE_SIZE
} from "../../util/Constant";
import { setBusy, setIdle, showMessage, setSaveAdminLog } from "../../redux/AppAction";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import { checkIfPermissionExist } from "../../util/PermissionChecker";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { fetch } from "whatwg-fetch";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useForm } from "react-hook-form";
import Select from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import ApiEngine from "../../util/ApiEngine";
import SweetAlert from "react-bootstrap-sweetalert";

/// <summary>
/// Author : -
/// </summary>

const ManagePushNotification = (props) => {
    const { t } = useTranslation(LanguageKey._PRIMARY);
    let _history = useHistory();
    const _dispatch = useDispatch();
    const [pushNotificationData, setPushNotificationData] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const { register, unregister, handleSubmit, errors } = useForm();
    const [labelUsername, setLabelUsername] = useState([]);
    const [selectedTopic, setSelectedTopic] = useState("");
    const [selectedTarget, setSelectedTarget] = useState("");
    const [value, onChange] = useState([]);
    const [notificationTypeId, setNotificationTypeId] = useState("");
    const [selectedNotificationId, setSelectedNotificationId] = useState("");
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [confirmValue, setConfirmValue] = useState("");

    let _tableColumns = [
        {
            Header: "TITLE",
            accessor: "title",
            disableSortBy: true,
            Cell: ({ row }) => {
                return (
                    <>
                        <div>[Default]: </div>
                        <div>{row.original.title != "" ? row.original.title : "-"}</div>
                        <br />

                        {row.original.pushNotificationLanguages.map((item) =>
                            <>
                                <div>[{item.languageName}]: </div>
                                <div>{item.title}</div>
                                <br />
                            </>
                        )}
                    </>
                );

            },
        },
        {
            Header: "BODY",
            accessor: "pushNotificationLanguages",
            disableSortBy: true,
            Cell: ({ row }) => {
                return (
                    <>
                        <div>[Default]: </div>
                        <div>{row.original.body != "" ? row.original.body : "-"}</div>
                        <br />
                        
                        {row.original.pushNotificationLanguages.map((item) =>
                            <>
                                <div>[{item.languageName}]: </div>
                                <div>{item.body}</div>
                                <br/>
                            </>
                        )}
                    </>
                );
            },
        },
        {
            Header: "MESSAGE_TYPE",
            accessor: "messageType",
            disableSortBy: true,
            Cell: ({ row }) => {
                const messageType = row.original.messageType;
                if (messageType != "") {
                    return (
                        <>
                            {messageType[0].toUpperCase() + messageType.slice(1)}
                        </>
                    );
                }
                else {
                    return "-";
                }
            },
        },
        {
            Header: "Notification Type",
            accessor: "nodificationTypeId",
            disableSortBy: true,
            Cell: ({ row }) => {
                const notificationTypeId = row.original.notificationTypeId;
                if (notificationTypeId === 1) {
                    return "General"
                }
                else if (notificationTypeId === 2) {
                    return "Deposit"
                }
                else if (notificationTypeId === 3) {
                    return "Withdrawal"
                }
                else if (notificationTypeId === 4) {
                  return "New Downline"
                }
                else if (notificationTypeId === 5) {
                  return "Commission Released "
                }
            },
        },
        {
            id: "action",
            Header: "",
            Cell: ({ row }) =>
                checkIfPermissionExist(
                    AccessRight._MANAGE_PUSH_NOTIFICATION_PERMISSION,
                    true
                ) && (
                    <div className="btn-group m-r-5 m-b-5">
                        <UncontrolledButtonDropdown>
                            <DropdownToggle caret color="default">
                                <i className="fas fa-cog"></i>
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem
                                    onClick={() => {
                                        navigateTo(
                                            _history,
                                            WebUrl._URL_CREATE_OR_UPDATE_PUSH_NOTIFICATION,
                                            row.original
                                        );
                                    }}
                                >
                                    <span className="text-warning">{t("EDIT")}</span>
                                </DropdownItem>

                                {
                                    row.original.messageType === 'general' && 
                                    <DropdownItem
                                        onClick={() => {
                                            setIsOpen(true);
                                                const { id, notificationTypeId } = row.original;
                                                setSelectedTarget("");
                                                setNotificationTypeId(notificationTypeId);
                                                setSelectedNotificationId(id);
                                        }}
                                    >
                                        <span className="text-warning">{t("SEND_PUSH_NOTIFICATION")}</span>
                                    </DropdownItem>
                                }
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>
                    </div>
                ),
            disableSortBy: true,
            disableFilters: true,
            width: 100,
            style: { overflow: "visible" },
        },
    ];

    const _target = [
        { value: "topic", label: "TOPIC" },
        { value: "username", label: "USERNAME" },
    ];

    // to involved all language 
    const _topic = [
        { value: "4d", label: "4D" }, // prod env
        { value: "TESTENV", label: "TESTENV" },  // testing env
    ];
    
    /// <summary>
    /// Author : -
    /// </summary>
    useEffect(() => {
        fetch(ApiUrl._API_GET_PUSH_NOTIFICATION + "?all=true&includeLanguageContent=true", {
            method: ApiKey._API_GET,
            headers: {
                "Content-Type": ApiKey._API_FORM_URLENCODED,
                "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
            },
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    setPushNotificationData(responseJson[ApiKey._API_DATA_KEY]);
                }
            });
    }, []);

    const onPushNotification = async (data, e) => {
        setConfirmValue("");
        let memberUsernameArr = [];

        if (stringIsNullOrEmpty(value) && selectedTarget === 'USERNAME') {
            _dispatch(showMessage(false, t("PLEASE_SELECT_USERNAME")));
        }
        if (stringIsNullOrEmpty(selectedTopic) && selectedTarget === 'TOPIC') {
            _dispatch(showMessage(false, t("PLEASE_SELECT_TOPIC")));
        }
        else if(!stringIsNullOrEmpty(selectedTopic) && selectedTarget === 'TOPIC') {
            setConfirmValue(selectedTopic);
            setShowConfirmation(true);
        }
        else if (!stringIsNullOrEmpty(value) && selectedTarget === 'USERNAME') {
            value.map((item) => {
                memberUsernameArr.push(item.label);
            });
            setConfirmValue(memberUsernameArr.toString());
            setShowConfirmation(true);
        }
    }

    const pushNotificationSubmit = async () => {
        let memberArr = [];

        if (!stringIsNullOrEmpty(value)) {
            value.map((item) => {
                memberArr.push(item.value);
            })
        }

        var apiUrl =
            ApiUrl._API_GET_PUSH_NOTIFICATION_BY_NOTIFICATIONTYPEID;
        apiUrl += "?memberId=" + memberArr + "&notificationTypeId=" + notificationTypeId + "&target=" + selectedTarget + "&topic=" + selectedTopic; // hardcode first

        let responseJson = await ApiEngine.get(apiUrl);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            _dispatch(setIdle());
            _dispatch(
                showMessage(
                    responseJson[ApiKey._API_SUCCESS_KEY],
                    t(responseJson[ApiKey._API_MESSAGE_KEY])
                )
            );
        }
        else {
            _dispatch(
                showMessage(
                    responseJson[ApiKey._API_SUCCESS_KEY],
                    responseJson[ApiKey._API_MESSAGE_KEY]
                )
            );
        }
    }

    async function adminLog(actionKey, params) {
        _dispatch(setSaveAdminLog(actionKey, _history.location.pathname, params));
    }

    async function getMemberList(search, loadOptions, { page }) {
        var responseJson = await ApiEngine.get(
            `${ApiUrl._API_GET_USER_MEMBER_LIST}?&start=${(page - 1) * DEFAULT_PAGE_SIZE
            }&count=${DEFAULT_PAGE_SIZE}&keyword=${search}`
        );
        let params = {
            "start": (page - 1) * DEFAULT_PAGE_SIZE,
            "count": DEFAULT_PAGE_SIZE,
            "keyword": search
        }
        adminLog("SELECT_USERNAME", params);
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            let data = responseJson[ApiKey._API_DATA_KEY]["data"];
            let formattedData = data.map((m) => {
                return { label: m.username, value: m.id };
            });

            return {
                options: formattedData,
                hasMore: formattedData.length == DEFAULT_PAGE_SIZE,
                additional: {
                    page: page + 1,
                },
            };
        }
    }

    return (
        <div>
            <h1 className="page-header">
                {t("MANAGE_PUSH_NOTIFICATION")}
                {checkIfPermissionExist(
                    AccessRight._MANAGE_PUSH_NOTIFICATION_PERMISSION,
                    true
                ) && (
                        <NavigationButton
                            history={_history}
                            url={WebUrl._URL_CREATE_OR_UPDATE_PUSH_NOTIFICATION}
                            buttonText={t("ADD_PUSH_NOTIFICATION")}
                        />
                    )}
            </h1>
            <Panel>
                <PanelBody>
                    <ReactTable data={pushNotificationData} columns={_tableColumns} />
                </PanelBody>
            </Panel>

            <Modal fade={false} contentClassName="modal-brand modal-bottom modal-numpad" isOpen={isOpen} centered>
                <ModalBody>
                    <form onSubmit={handleSubmit(onPushNotification)}>
                        <ModalHeader>
                            {t("SEND_PUSH_NOTIFICATION")}
                        </ModalHeader>

                        <ModalBody style={{ minHeight: "15vh" }}>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="form-group row m-b-15">
                                        <label className="col-md-4 col-form-label">
                                            <b>{t("TARGET")}</b>
                                        </label>

                                        <div className="col-md-7">
                                            <Select
                                                name="target"
                                                options={_target}
                                                onChange={(e) => {
                                                    setSelectedTarget(e.label);
                                                    if(e.label == "USERNAME") {
                                                        setSelectedTopic("");
                                                    }
                                                }} />
                                        </div>
                                    </div>
                                </div>

                                {
                                    selectedTarget === 'TOPIC' && 
                                    <div className="col-lg-12">
                                        <div className="form-group row m-b-15">
                                            <label className="col-md-4 col-form-label">
                                                <b>{t("TOPIC")}</b>
                                            </label>

                                            <div className="col-md-7">
                                                <Select options={_topic}
                                                        name="topic"
                                                        onChange={(e) => {
                                                            setSelectedTopic(e.label);
                                                        }}
                                                    />
                                            </div>
                                        </div>
                                    </div>
                                }
                                
                                {
                                    selectedTarget === 'USERNAME' &&
                                    <div className="col-lg-12">
                                        <div className="form-group row m-b-15">
                                            <label className="col-md-4 col-form-label">
                                                <b>{t("USERNAME")}</b>
                                            </label>

                                            <div className="col-md-7">
                                                {<AsyncPaginate
                                                    placeholder={labelUsername}
                                                    debounceTimeout={250}
                                                    loadOptions={getMemberList}
                                                    isMulti
                                                    isClearable
                                                    additional={{
                                                        page: 1,
                                                        }}

                                                        value={value}
                                                        onChange={onChange}
                                                  
                                                />}
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <button
                                type="button"
                                className="btn btn-white"
                                onClick={() => setIsOpen(false)}
                            >
                                {t("CLOSE")}
                            </button>
                            <button type="submit" className="btn btn-primary">
                                {t("SUBMIT")}
                            </button>
                        </ModalFooter>
                    </form>
                </ModalBody>
            </Modal>

            {showConfirmation && (
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText={t("CONFIRM")}
                    cancelBtnText={t("CANCEL")}
                    confirmBtnBsStyle="danger"
                    title={t("CONFIRM_PUSH_NOTIFICATION", { target: selectedTarget, value: confirmValue })}
                    onConfirm={() => {
                        pushNotificationSubmit();
                        setShowConfirmation(false);
                    }}
                    onCancel={() => {
                        setShowConfirmation(false);
                    }}
                ></SweetAlert>
            )}
        </div>
    );
};

export default ManagePushNotification;
