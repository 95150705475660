import React, { useState, useEffect } from "react";
import moment from "moment";
import ApiEngine from "../../util/ApiEngine.js";
import { ApiKey, ApiUrl, LanguageKey, BetStatus } from "../../util/Constant";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import { useTranslation } from "react-i18next";
import { isObjectEmpty, numberWithCurrencyFormat } from "../../util/Util";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";

const UsernameBetDetailReport = (props) => {
    const { t } = useTranslation(LanguageKey._PRIMARY);
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const username = params.get('username');
    const startDate = moment(params.get('startDate')).format("YYYY-MM-DD HH:mm:ss");
    const endDate = moment(params.get('endDate')).format("YYYY-MM-DD HH:mm:ss");
    const timeZone = params.get('zone');
    const selectedProduct = params.get('productId');
    const [tableDataUrl, setTableDataUrl] = useState([]);
    const [overallInfo, setOverallInfo] = useState({});
    const filtertype = params.get('filtertype');
    const _OVERALL_KEYS = {
        AMOUNT: "TOTAL_AMOUNT",
        WIN: "TOTAL_WIN",
    };

    /// <summary>
    /// Author : -
    /// </summary>
    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        setTableDataUrl(ApiUrl._API_GET_BET_DETAIL_REPORT + "?username=" + username + "&startDate=" + startDate + "&endDate=" + endDate + "&timeZone=" + timeZone + (selectedProduct === "all" ? "" : "&productId=" + selectedProduct) + "&type=" + filtertype);
    }

    const _defaultTableColumns = [

        {
            Header: t("ID"),
            accessor: "id",
        },
        {
            Header: "Description",
            Cell: ({ row }) => {
                return (
                    <div>
                        {row.original.betId}
                    </div>
                );
            },
            Footer: () => {
                return (
                    <span>
                        <b>{t("GRAND TOTAL")}: </b>
                    </span>
                );
            },
        },
        {
            Header: t("PRODUCT_NAME"),
            accessor: "productName",
        },
        {
            Header: t("DATE"),
            accessor: filtertype == 0 ? "date" : "updatedTime",
            minWidth: 100,
        },
        {
            Header: t("USERNAME"),
            accessor: "memberUsername",
            minWidth: 100,
        },
        {
            Header: t("PLAYERID"),
            accessor: "username",
            minWidth: 100,
        },
        {
            Header: t("GAME_NAME"),
            accessor: "gameName",
            minWidth: 100,
        },
        {
            Header: t("STATUS"),
            accessor: "statusString",
            minWidth: 100,
        },
        {
            Header: t("BET_AMOUNT"),
            accessor: "amount",
            minWidth: 100,
            Cell: ({ row }) => {
                return <div className="text-align-right">{row.original.amount.toFixed(2)}</div>;
            },
            Footer: () => {
                let totalAmount = 0;
                if (!isObjectEmpty(overallInfo) && overallInfo[_OVERALL_KEYS.AMOUNT]) {
                    totalAmount = parseFloat(overallInfo[_OVERALL_KEYS.AMOUNT]);
                }
                return (
                    <div className="text-align-right">
                        <b>{numberWithCurrencyFormat(totalAmount, 3)}</b>
                    </div>
                );
            },
        },
        {
            Header: t("WIN_LOSS"),
            accessor: "winAmount",
            minWidth: 100,
            Cell: ({ row }) => {
                let winLoss =
                    parseFloat(row.original.winAmount) - parseFloat(row.original.amount);
                if (row.original.status == BetStatus._SETTLED_BET_STATUS) {
                    return (
                        <div className="text-align-right" style={{ color: winLoss < 0 ? "red" : "" }}>
                            {numberWithCurrencyFormat(winLoss)}
                        </div>
                    );
                } else {
                    return <>0</>;
                }
            },
            Footer: () => {
                let winLossTotal = 0;
                if (
                    !isObjectEmpty(overallInfo) &&
                    overallInfo[_OVERALL_KEYS.WIN] &&
                    overallInfo[_OVERALL_KEYS.AMOUNT]
                ) {
                    winLossTotal =
                        parseFloat(overallInfo[_OVERALL_KEYS.WIN]) -
                        parseFloat(overallInfo[_OVERALL_KEYS.AMOUNT]);
                }
                return (
                    <div className="text-align-right" style={{ color: winLossTotal < 0 ? "red" : "" }}>
                        <b>{numberWithCurrencyFormat(winLossTotal)}</b>
                    </div>
                );
            },
        },
        {
            Header: t("Jpc"),
            accessor: "jpc",
            id: "jpc",
            minWidth: 100,
            Cell: ({ row }) => {
                return (
                    <div className="text-align-right" style={{ color: row.original.jpc < 0 ? "red" : "" }}>{numberWithCurrencyFormat(parseFloat(row.original.jpc), 3, true)}</div>
                );
            },
            Footer: ({ row, column }) => {
                let jpcSum = 0;
                column.filteredRows.map((dataRow, index) => {
                    jpcSum += parseFloat(dataRow.original.jpc);
                });
                return (
                    <div className="text-align-right" style={{ color: jpcSum < 0 ? "red" : "" }}><b>{numberWithCurrencyFormat(parseFloat(jpcSum), 3, true)}</b></div>
                );
            },
        },
        {
            Header: t("Jpw"),
            accessor: "jpw",
            id: "jpw",
            minWidth: 100,
            Cell: ({ row }) => {
                return (
                    <div className="text-align-right" style={{ color: row.original.jpw < 0 ? "red" : "" }}>{numberWithCurrencyFormat(parseFloat(row.original.jpw))}</div>
                );
            },
            Footer: ({ row, column }) => {
                let jpwSum = 0;
                column.filteredRows.map((dataRow, index) => {
                    jpwSum += parseFloat(dataRow.original.jpw);
                });
                return (
                    <div className="text-align-right" style={{ color: jpwSum < 0 ? "red" : "" }}><b>{numberWithCurrencyFormat(parseFloat(jpwSum), 3, true)}</b></div>
                );
            },
        },
    ];

    /// <summary>
    /// Author : -
    /// </summary>
    function getOverallInfo(info) {
        setOverallInfo(info);
    }

    return (
        <>
            <div>
                <Panel>
                    <PanelBody>
                        <ReactTable
                            fetchUrl={tableDataUrl}
                            columns={_defaultTableColumns}
                            getOverallInfo={getOverallInfo}
                            renderFooter={true}
                        />
                    </PanelBody>
                </Panel>
            </div>
        </>
    )
}

export default UsernameBetDetailReport;