import React, { useState, useEffect, useMemo, useCallback } from 'react';
import ReactTable from '../../components/constantComponent/reactTable/ReactTable';
import { Panel, PanelHeader, PanelBody } from './../../components/panel/panel.jsx';
import { useHistory } from "react-router-dom";
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import { navigateTo } from '../../util/Util';
import { ApiKey, ApiUrl, WebUrl, AccessRight, TransactionType, TransactionStatus, PanelType, LanguageKey, BankingChannelName, DEFAULT_PAGE_SIZE } from '../../util/Constant';
import { setBusy, setIdle, showMessage, setSaveAdminLog } from "../../redux/AppAction";
import { useDispatch, useSelector } from "react-redux";
import { stringIsNullOrEmpty, createFormBody, numberWithCurrencyFormat, numberWithCurrencyFormat2 } from "../../util/Util";
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import ApiEngine from '../../util/ApiEngine';
import { AsyncPaginate } from "react-select-async-paginate";
import SweetAlert from 'react-bootstrap-sweetalert';

const BirthdayBonusReport = props => {
  const { t } = useTranslation(LanguageKey._PRIMARY);
  let _history = useHistory();
  const _dispatch = useDispatch();
  const { register, unregister, handleSubmit, errors } = useForm();
  const [calendarStartDate, setCalendarStartDate] = useState(moment().startOf('day').format("YYYY-MM-DD HH:mm:ss"));
  const [calendarEndDate, setCalendarEndDate] = useState(moment().endOf('day').format("YYYY-MM-DD HH:mm:ss"));
  const [memberId, setMemberId] = useState();
  const [initFlag, setInitFlag] = useState(false);
  const [birthdayApiUrl, setBirthdayApiUrl] = useState("");

  const [username, setUsername] = useState("");


  /// <summary>
  /// Author : -
  /// </summary>
  const handleEvent = (event, picker) => {
    setCalendarStartDate(moment(picker.startDate).format("YYYY-MM-DD HH:mm:ss"));
    setCalendarEndDate(moment(picker.endDate).format("YYYY-MM-DD HH:mm:ss"));
  }

  useEffect(() => {
    getBirthdayBonus();
  }, []);

  /// <summary>
  /// Author : -
  /// </summary>
  const init = async () => {
    if (props.username && props.id) {
      setUsername(props.username);
      setMemberId(props.id);
      
    }
    try {

      setInitFlag(true);
    }
    catch (ex) {
      _dispatch(showMessage(false, t(ex)));
    }
  }

  /// <summary>
  /// Author : -
  /// </summary>
  const getBirthdayBonus = async () => {

    let fetchUrl = ApiUrl._API_GET_BIRTHDAY_BONUS;
    fetchUrl += "?startDate=" + calendarStartDate + "&endDate=" + calendarEndDate;
    setBirthdayApiUrl(fetchUrl);
  }

  let _tableColumns = [
    {
      Header: t("USERNAME"),
      accessor: "memberName",
      Cell: ({ row }) => {
        return <>{t(row.original.memberName)}</>
      },
      minWidth: 100
    },
    {
      Header: t("AMOUNT"),
      accessor: "amount",
      Cell: ({ row }) => {
        return <>{t(row.original.amount)}</>
      },
      minWidth: 100,
      Footer: ({ page }) => {
        const debitSum = page.reduce((sum, currentValue) => {
          if (currentValue.original.amount) {
            return sum + (parseFloat(currentValue.original.amount));
          }
          else {
            return sum;
          }
        }, 0);
          return <span><b>{numberWithCurrencyFormat2(parseFloat(debitSum))}</b></span>
      }
    }, 
    {
      Header: t("YEAR"),
      accessor: "year",
      Cell: ({ row }) => {
        return <>{t(row.original.year)}</>
      },
      minWidth: 100
    },
    {
      Header: t("STATUS"),
      accessor: "status",
      Cell: ({ row }) => {
        return (
          <span>
            {row.original.status == 3 ? (
              <span className="badge badge-secondary badge-green">
                {t("APPROVED")}
              </span>
            ) : (
              <span className="badge badge-secondary badge-danger">
                {t("OPEN")}
              </span>
            )}
          </span>
        );
      },
      minWidth: 100
    },
    {
      Header: t("CREATED_TIME"),
      accessor: "createdTime",
      Cell: ({ row }) => {
        return <>{moment(row.original.createdTime, "YYYY-MM-DD HH:mm:ss").format("DD-MM-YYYY")}</>
      },
      minWidth: 100
    }
    
  ]

  async function adminLog() {
    let params = {
      "startDate": calendarStartDate,
      "endDate": calendarEndDate
    };

    _dispatch(setSaveAdminLog("VIEW_BIRTHDAY_BONUS_REPORT", _history.location.pathname, params));
  }

  return (
    <div>
      <h1 className="page-header">{t("BIRTHDAY_BONUS_REPORT")}</h1>
      <Panel>
        <div className="row">
          <div className="col-lg-12">
            <PanelBody>
              <div className="row">
                <div className="col-lg-4">
                  <div className="form-group" style={{ display: "flex" }}>
                    <div className="input-group" style={{ width: "100%", flexFlow: "nowrap" }}>
                      <div className="input-group-prepend"><span className="input-group-text"><i className="fa fa-calendar"></i></span></div>
                      <div className="input-group-append" style={{ width: "100%" }}>
                        <DateRangePicker
                          containerStyles={{ width: "100%" }}
                          alwaysShowCalendars={true}
                          timePicker={false}
                          timePickerSeconds={false}
                          onApply={handleEvent}
                          ranges={{
                            'Today': [moment().startOf('day'), moment().endOf('day')],
                            'Yesterday': [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],
                            'Last 7 Days': [moment().subtract(6, 'days').startOf('day'), moment().endOf('day')],
                            'This Week': [moment().startOf('week'), moment().endOf('week')],
                          }}>
                          <input type="text" className="form-control" value={calendarStartDate + " - " + calendarEndDate} />
                        </DateRangePicker>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2">
                  <button type="button" onClick={() => { getBirthdayBonus(); adminLog() }} className="btn btn-primary">{t("SEARCH")}</button>
                </div>
              </div>
              <hr />
              <ReactTable fetchUrl={birthdayApiUrl} columns={_tableColumns} renderFooter={true} />
            </PanelBody>
          </div>
        </div>

      </Panel>
    </div>
  )
}

export default BirthdayBonusReport;