import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import { useHistory } from "react-router-dom";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import {
    ApiKey,
    ApiUrl,
    LanguageKey,
} from "../../util/Constant";
import { setBusy, setIdle, setSaveAdminLog, showMessage } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ApiEngine from "../../util/ApiEngine";
import { createFormBody, createMultiPartFormBody, stringIsNullOrEmpty } from "../../util/Util.js";
import moment from 'moment';
import Dropzone from "react-dropzone";

/// <summary>
/// Author : -
/// </summary>

const TnGSettings = (props) => {
    const { t } = useTranslation(LanguageKey._PRIMARY);
    let _history = useHistory();
    const _dispatch = useDispatch();
    const [status, setStatus] = useState(true);
    const [initCheck, setInitCheck] = useState(false);
    const [startHour, setStartHour] = useState(0);
    const [startMinute, setStartMinute] = useState(0);
    const [endHour, setEndHour] = useState(0);
    const [endMinute, setEndMinute] = useState(0);
    const [sundayQRImageFile, setSundayQRImageFile] = useState([]);
    const [mondayQRImageFile, setMondayQRImageFile] = useState([]);
    const [tuesdayQRImageFile, setTuesdayQRImageFile] = useState([]);
    const [wednesdayQRImageFile, setWednesdayQRImageFile] = useState([]);
    const [thursdayQRImageFile, setThursdayQRImageFile] = useState([]);
    const [fridayQRImageFile, setFridayQRImageFile] = useState([]);
    const [saturdayQRImageFile, setSaturdayQRImageFile] = useState([]);
    const [isNewSundayQRImage, setIsNewSundayQRImage] = useState(false);
    const [isNewMondayQRImage, setIsNewMondayQRImage] = useState(false);
    const [isNewTuesdayQRImage, setIsNewTuesdayQRImage] = useState(false);
    const [isNewWednesdayQRImage, setIsNewWednesdayQRImage] = useState(false);
    const [isNewThursdayQRImage, setIsNewThursdayQRImage] = useState(false);
    const [isNewFridayQRImage, setIsNewFridayQRImage] = useState(false);
    const [isNewSaturdayQRImage, setIsNewSaturdayQRImage] = useState(false);
    const [sundayQR, setSundayQR] = useState();
    const [mondayQR, setMondayQR] = useState();
    const [tuesdayQR, setTuesdayQR] = useState();
    const [wednesdayQR, setWednesdayQR] = useState();
    const [thursdayQR, setThursdayQR] = useState();
    const [fridayQR, setFridayQR] = useState();
    const [saturdayQR, setSaturdayQR] = useState();
    const {
        register,
        unregister,
        handleSubmit,
        errors,
        setError,
        control,
        setValue,
    } = useForm();

    /// <summary>
    /// Author : -
    /// </summary>
    useEffect(() => {
        _dispatch(setBusy());
        init();
        setInitCheck(true);
    }, []);

    /// <summary>
    /// Author : -
    /// Used to set popup banner
    /// </summary>
    const QR_ImageDrop = (acceptedFiles, dayInWeek) => {
        if (acceptedFiles.length !== 0) {
            const file = acceptedFiles[0];
            switch (dayInWeek) {
                case 0:
                    setSundayQRImageFile(file);
                    const reader0 = new FileReader();
                    reader0.onloadend = () => {
                        setSundayQR(reader0.result);
                        setIsNewSundayQRImage(true);
                    };
                    reader0.readAsDataURL(file);
                    break;
                case 1:
                    setMondayQRImageFile(file);
                    const reader1 = new FileReader();
                    reader1.onloadend = () => {
                        setMondayQR(reader1.result);
                        setIsNewMondayQRImage(true);
                    };
                    reader1.readAsDataURL(file);
                    break;
                case 2:
                    setTuesdayQRImageFile(file);
                    const reader2 = new FileReader();
                    reader2.onloadend = () => {
                        setTuesdayQR(reader2.result);
                        setIsNewTuesdayQRImage(true);
                    };
                    reader2.readAsDataURL(file);
                    break;
                case 3:
                    setWednesdayQRImageFile(file);
                    const reader3 = new FileReader();
                    reader3.onloadend = () => {
                        setWednesdayQR(reader3.result);
                        setIsNewWednesdayQRImage(true);
                    };
                    reader3.readAsDataURL(file);
                    break;
                case 4:
                    setThursdayQRImageFile(file);
                    const reader4 = new FileReader();
                    reader4.onloadend = () => {
                        setThursdayQR(reader4.result);
                        setIsNewThursdayQRImage(true);
                    };
                    reader4.readAsDataURL(file);
                    break;
                case 5:
                    setFridayQRImageFile(file);
                    const reader5 = new FileReader();
                    reader5.onloadend = () => {
                        setFridayQR(reader5.result);
                        setIsNewFridayQRImage(true);
                    };
                    reader5.readAsDataURL(file);
                    break;
                case 6:
                    setSaturdayQRImageFile(file);
                    const reader6 = new FileReader();
                    reader6.onloadend = () => {
                        setSaturdayQR(reader6.result);
                        setIsNewSaturdayQRImage(true);
                    };
                    reader6.readAsDataURL(file);
                    break;
                default:
                    break;
            }
        }
    };

    /// <summary>
    /// Author : -
    /// </summary>
    async function init() {
        let apiUrl = ApiUrl._API_GET_USER_SYSTEM_SETTINGS + "?type=settings";
        let responseJson = await ApiEngine.get(apiUrl);
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            let data = responseJson[ApiKey._API_DATA_KEY];
            data.map((keyValuePair, index) => {
                if (keyValuePair['key'] === 'TnGStartTime') {
                    let startTimeStr = keyValuePair['value'];
                    setStartHour(moment(startTimeStr, 'HH:mm:ss').get('hours'));
                    setStartMinute(moment(startTimeStr, 'HH:mm:ss').get('minutes'));
                }
                if (keyValuePair['key'] === 'TnGEndTime') {
                    let endTimeStr = keyValuePair['value'];
                    setEndHour(moment(endTimeStr, 'HH:mm:ss').get('hours'));
                    setEndMinute(moment(endTimeStr, 'HH:mm:ss').get('minutes'));
                }
                if (keyValuePair['key'] === 'TnGSundayQR') {
                    setSundayQR(keyValuePair['value']);
                }
                if (keyValuePair['key'] === 'TnGMondayQR') {
                    setMondayQR(keyValuePair['value']);
                }
                if (keyValuePair['key'] === 'TnGTuesdayQR') {
                    setTuesdayQR(keyValuePair['value']);
                }
                if (keyValuePair['key'] === 'TnGWednesdayQR') {
                    setWednesdayQR(keyValuePair['value']);
                }
                if (keyValuePair['key'] === 'TnGThursdayQR') {
                    setThursdayQR(keyValuePair['value']);
                }
                if (keyValuePair['key'] === 'TnGFridayQR') {
                    setFridayQR(keyValuePair['value']);
                }
                if (keyValuePair['key'] === 'TnGSaturdayQR') {
                    setSaturdayQR(keyValuePair['value']);
                }
                if (keyValuePair['key'] === 'TnGStatus') {
                    let trueOrFalse = (keyValuePair['value'] === 'true');
                    setStatus(trueOrFalse);
                }
            })
        }
        _dispatch(setIdle());
    }

    const submitForm = async (data) => {
        let params = {
            'startHour': data.startHour.length === 2 ? data.startHour : "0" + data.startHour,
            'startMinute': data.startMinute.length === 2 ? data.startMinute : "0" + data.startMinute,
            'endHour': data.endHour.length === 2 ? data.endHour : "0" + data.endHour,
            'endMinute': data.endMinute.length === 2 ? data.endMinute : "0" + data.endMinute,
            'isNewSundayQRImage': isNewSundayQRImage,
            'isNewMondayQRImage': isNewMondayQRImage,
            'isNewTuesdayQRImage': isNewTuesdayQRImage,
            'isNewWednesdayQRImage': isNewWednesdayQRImage,
            'isNewThursdayQRImage': isNewThursdayQRImage,
            'isNewFridayQRImage': isNewFridayQRImage,
            'isNewSaturdayQRImage': isNewSaturdayQRImage,
            'status': data.status
        };

        if (!stringIsNullOrEmpty(sundayQRImageFile)) {
            params["sundayQR"] = sundayQRImageFile;
        }
        if (stringIsNullOrEmpty(sundayQR)) {
            params["sundayQRRemoved"] = true;
        }

        if (!stringIsNullOrEmpty(mondayQRImageFile)) {
            params["mondayQR"] = mondayQRImageFile;
        }
        if (stringIsNullOrEmpty(mondayQR)) {
            params["mondayQRRemoved"] = true;
        }

        if (!stringIsNullOrEmpty(tuesdayQRImageFile)) {
            params["tuesdayQR"] = tuesdayQRImageFile;
        }
        if (stringIsNullOrEmpty(tuesdayQR)) {
            params["tuesdayQRRemoved"] = true;
        }

        if (!stringIsNullOrEmpty(wednesdayQRImageFile)) {
            params["wednesdayQR"] = wednesdayQRImageFile;
        }
        if (stringIsNullOrEmpty(wednesdayQR)) {
            params["wednesdayQRRemoved"] = true;
        }

        if (!stringIsNullOrEmpty(thursdayQRImageFile)) {
            params["thursdayQR"] = thursdayQRImageFile;
        }
        if (stringIsNullOrEmpty(thursdayQR)) {
            params["thursdayQRRemoved"] = true;
        }

        if (!stringIsNullOrEmpty(fridayQRImageFile)) {
            params["fridayQR"] = fridayQRImageFile;
        }
        if (stringIsNullOrEmpty(fridayQR)) {
            params["fridayQRRemoved"] = true;
        }

        if (!stringIsNullOrEmpty(saturdayQRImageFile)) {
            params["saturdayQR"] = saturdayQRImageFile;
        }
        if (stringIsNullOrEmpty(saturdayQR)) {
            params["saturdayQRRemoved"] = true;
        }
        _dispatch(setSaveAdminLog("EDIT_TNG_SETTINGS",_history.location.pathname,params));
        let formBody = createMultiPartFormBody(params);
        let apiUrl = ApiUrl._API_CREATE_OR_UPDATE_SYSTEM_SETTINGS + "?type=TnG";
        let responseJson = await ApiEngine.post(apiUrl, formBody);
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            init();
        }
        _dispatch(showMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
    }

    return (
        <div>
            <h1 className="page-header">
                {t("TNG_SETTINGS")}
                <NavigationButton history={_history} />
            </h1>
            <div className="row">
                <div className="col-xl-12">
                    {initCheck && <Panel>
                        <PanelBody>
                            <form onSubmit={handleSubmit(submitForm)}>
                                <div className="col-lg-8">
                                    <div className="form-group row m-b-15">
                                        <label className="col-md-4 col-form-label">
                                            <b>{t("START_TIME")}</b>
                                        </label>
                                        <div className="col-md-7" style={{ display: "flex", lineHeight: "2.5" }}>
                                            <div className="col-4">
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    name="startHour"
                                                    value={startHour}
                                                    onChange={(e) => setStartHour(e.target.value)}
                                                    min={0}
                                                    max={23}
                                                    ref={register({
                                                        min: {
                                                            value: 0,
                                                            message:
                                                                "PLEASE_ENTER_VALUE_NOT_LESS_THAN_ZERO",
                                                        },
                                                        max: {
                                                            value: 23,
                                                            message:
                                                                "PLEASE_ENTER_VALUE_NOT_MORE_THAN_23",
                                                        },
                                                    })}
                                                />
                                                {errors.startHour && (
                                                    <div className="invalid-feedback">
                                                        {t(errors.startHour.message)}
                                                    </div>
                                                )}
                                            </div>
                                            :
                                            <div className="col-4">
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    name="startMinute"
                                                    value={startMinute}
                                                    onChange={(e) => setStartMinute(e.target.value)}
                                                    min={0}
                                                    max={59}
                                                    ref={register({
                                                        min: {
                                                            value: 0,
                                                            message:
                                                                "PLEASE_ENTER_VALUE_NOT_LESS_THAN_ZERO",
                                                        },
                                                        max: {
                                                            value: 59,
                                                            message:
                                                                "PLEASE_ENTER_VALUE_NOT_MORE_THAN_59",
                                                        },
                                                    })}
                                                />
                                                {errors.startMinute && (
                                                    <div className="invalid-feedback">
                                                        {t(errors.startMinute.message)}
                                                    </div>
                                                )}
                                            </div>
                                            (GMT +8)
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-8">
                                    <div className="form-group row m-b-15">
                                        <label className="col-md-4 col-form-label">
                                            <b>{t("END_TIME")}</b>
                                        </label>
                                        <div className="col-md-7" style={{ display: "flex", lineHeight: "2.5" }}>
                                            <div className="col-4">
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    name="endHour"
                                                    value={endHour}
                                                    onChange={(e) => setEndHour(e.target.value)}
                                                    min={0}
                                                    max={23}
                                                    ref={register({
                                                        min: {
                                                            value: 0,
                                                            message:
                                                                "PLEASE_ENTER_VALUE_NOT_LESS_THAN_ZERO",
                                                        },
                                                        max: {
                                                            value: 23,
                                                            message:
                                                                "PLEASE_ENTER_VALUE_NOT_MORE_THAN_23",
                                                        },
                                                    })}
                                                />
                                                {errors.endHour && (
                                                    <div className="invalid-feedback">
                                                        {t(errors.endHour.message)}
                                                    </div>
                                                )}
                                            </div>
                                            :
                                            <div className="col-4">
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    name="endMinute"
                                                    value={endMinute}
                                                    onChange={(e) => setEndMinute(e.target.value)}
                                                    min={0}
                                                    max={59}
                                                    ref={register({
                                                        min: {
                                                            value: 0,
                                                            message:
                                                                "PLEASE_ENTER_VALUE_NOT_LESS_THAN_ZERO",
                                                        },
                                                        max: {
                                                            value: 59,
                                                            message:
                                                                "PLEASE_ENTER_VALUE_NOT_MORE_THAN_59",
                                                        },
                                                    })}
                                                />
                                                {errors.endMinute && (
                                                    <div className="invalid-feedback">
                                                        {t(errors.endMinute.message)}
                                                    </div>
                                                )}
                                            </div>
                                            (GMT +8)
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-8">
                                    <div className="form-group row m-b-15">
                                        <label className="col-md-4 col-form-label">
                                            <b>{t("TNG_SUNDAY_QR")}</b>
                                        </label>
                                        <div className="col-md-7" style={{ display: "flex", lineHeight: "2.5" }}>
                                            <Dropzone
                                                accept={"image/*"}
                                                onDrop={(acceptedFiles) => QR_ImageDrop(acceptedFiles, 0)}
                                            >
                                                {({ getRootProps, getInputProps }) => (
                                                    <section>
                                                        <div
                                                            className="dropzone"
                                                            style={{
                                                                minHeight: "200px",
                                                                textAlign: "center",
                                                            }}
                                                            {...getRootProps()}
                                                        >
                                                            <input {...getInputProps()} />
                                                            <h4 style={{ color: "grey" }}>
                                                                {t(
                                                                    "DRAG_N_DROP_SOME_FILES_HERE_OR_CLICK_TO_SELECT_FILES"
                                                                )}
                                                            </h4>
                                                            {!stringIsNullOrEmpty(sundayQR) && (
                                                                <aside className="thumbsContainer">
                                                                    <div className="thumb">
                                                                        <div className="thumbInner">
                                                                            <img
                                                                                src={sundayQR}
                                                                                className="dropzone-img"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </aside>
                                                            )}
                                                        </div>
                                                    </section>
                                                )}
                                            </Dropzone>
                                            <br />
                                            <button
                                                type="button"
                                                className="btn btn-danger"
                                                style={{ height: "50px", margin: "auto 0 auto 10px" }}
                                                onClick={() => {
                                                    setSundayQR("");
                                                    setSundayQRImageFile();
                                                }}
                                            >
                                                {t("REMOVE_IMAGE")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-8">
                                    <div className="form-group row m-b-15">
                                        <label className="col-md-4 col-form-label">
                                            <b>{t("TNG_MONDAY_QR")}</b>
                                        </label>
                                        <div className="col-md-7" style={{ display: "flex", lineHeight: "2.5" }}>
                                        <Dropzone
                                            accept={"image/*"}
                                            onDrop={(acceptedFiles) => QR_ImageDrop(acceptedFiles, 1)}
                                        >
                                            {({ getRootProps, getInputProps }) => (
                                                <section>
                                                    <div
                                                        className="dropzone"
                                                        style={{
                                                            minHeight: "200px",
                                                            textAlign: "center",
                                                        }}
                                                        {...getRootProps()}
                                                    >
                                                        <input {...getInputProps()} />
                                                        <h4 style={{ color: "grey" }}>
                                                            {t(
                                                                "DRAG_N_DROP_SOME_FILES_HERE_OR_CLICK_TO_SELECT_FILES"
                                                            )}
                                                        </h4>
                                                        {!stringIsNullOrEmpty(mondayQR) && (
                                                            <aside className="thumbsContainer">
                                                                <div className="thumb">
                                                                    <div className="thumbInner">
                                                                        <img
                                                                            src={mondayQR}
                                                                            className="dropzone-img"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </aside>
                                                        )}
                                                    </div>
                                                </section>
                                            )}
                                        </Dropzone>
                                        <br />
                                        <button
                                            type="button"
                                            className="btn btn-danger"
                                            style={{ height: "50px", margin: "auto 0 auto 10px" }}
                                            onClick={() => {
                                                setMondayQR("");
                                                setMondayQRImageFile();
                                            }}
                                        >
                                            {t("REMOVE_IMAGE")}
                                        </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-8">
                                    <div className="form-group row m-b-15">
                                        <label className="col-md-4 col-form-label">
                                            <b>{t("TNG_TUESDAY_QR")}</b>
                                        </label>
                                        <div className="col-md-7" style={{ display: "flex", lineHeight: "2.5" }}>
                                            <Dropzone
                                                accept={"image/*"}
                                                onDrop={(acceptedFiles) => QR_ImageDrop(acceptedFiles, 2)}
                                            >
                                                {({ getRootProps, getInputProps }) => (
                                                    <section>
                                                        <div
                                                            className="dropzone"
                                                            style={{
                                                                minHeight: "200px",
                                                                textAlign: "center",
                                                            }}
                                                            {...getRootProps()}
                                                        >
                                                            <input {...getInputProps()} />
                                                            <h4 style={{ color: "grey" }}>
                                                                {t(
                                                                    "DRAG_N_DROP_SOME_FILES_HERE_OR_CLICK_TO_SELECT_FILES"
                                                                )}
                                                            </h4>
                                                            {!stringIsNullOrEmpty(tuesdayQR) && (
                                                                <aside className="thumbsContainer">
                                                                    <div className="thumb">
                                                                        <div className="thumbInner">
                                                                            <img
                                                                                src={tuesdayQR}
                                                                                className="dropzone-img"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </aside>
                                                            )}
                                                        </div>
                                                    </section>
                                                )}
                                            </Dropzone>
                                            <br />
                                            <button
                                                type="button"
                                                className="btn btn-danger"
                                                style={{ height: "50px", margin: "auto 0 auto 10px" }}
                                                onClick={() => {
                                                    setTuesdayQR("");
                                                    setTuesdayQRImageFile();
                                                }}
                                            >
                                                {t("REMOVE_IMAGE")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-8">
                                    <div className="form-group row m-b-15">
                                        <label className="col-md-4 col-form-label">
                                            <b>{t("TNG_WEDNESDAY_QR")}</b>
                                        </label>
                                        <div className="col-md-7" style={{ display: "flex", lineHeight: "2.5" }}>
                                            <Dropzone
                                                accept={"image/*"}
                                                onDrop={(acceptedFiles) => QR_ImageDrop(acceptedFiles, 3)}
                                            >
                                                {({ getRootProps, getInputProps }) => (
                                                    <section>
                                                        <div
                                                            className="dropzone"
                                                            style={{
                                                                minHeight: "200px",
                                                                textAlign: "center",
                                                            }}
                                                            {...getRootProps()}
                                                        >
                                                            <input {...getInputProps()} />
                                                            <h4 style={{ color: "grey" }}>
                                                                {t(
                                                                    "DRAG_N_DROP_SOME_FILES_HERE_OR_CLICK_TO_SELECT_FILES"
                                                                )}
                                                            </h4>
                                                            {!stringIsNullOrEmpty(wednesdayQR) && (
                                                                <aside className="thumbsContainer">
                                                                    <div className="thumb">
                                                                        <div className="thumbInner">
                                                                            <img
                                                                                src={wednesdayQR}
                                                                                className="dropzone-img"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </aside>
                                                            )}
                                                        </div>
                                                    </section>
                                                )}
                                            </Dropzone>
                                            <br />
                                            <button
                                                type="button"
                                                className="btn btn-danger"
                                                style={{ height: "50px", margin: "auto 0 auto 10px" }}
                                                onClick={() => {
                                                    setWednesdayQR("");
                                                    setWednesdayQRImageFile();
                                                }}
                                            >
                                                {t("REMOVE_IMAGE")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-8">
                                    <div className="form-group row m-b-15">
                                        <label className="col-md-4 col-form-label">
                                            <b>{t("TNG_THURSDAY_QR")}</b>
                                        </label>
                                        <div className="col-md-7" style={{ display: "flex", lineHeight: "2.5" }}>
                                            <Dropzone
                                                accept={"image/*"}
                                                onDrop={(acceptedFiles) => QR_ImageDrop(acceptedFiles, 4)}
                                            >
                                                {({ getRootProps, getInputProps }) => (
                                                    <section>
                                                        <div
                                                            className="dropzone"
                                                            style={{
                                                                minHeight: "200px",
                                                                textAlign: "center",
                                                            }}
                                                            {...getRootProps()}
                                                        >
                                                            <input {...getInputProps()} />
                                                            <h4 style={{ color: "grey" }}>
                                                                {t(
                                                                    "DRAG_N_DROP_SOME_FILES_HERE_OR_CLICK_TO_SELECT_FILES"
                                                                )}
                                                            </h4>
                                                            {!stringIsNullOrEmpty(thursdayQR) && (
                                                                <aside className="thumbsContainer">
                                                                    <div className="thumb">
                                                                        <div className="thumbInner">
                                                                            <img
                                                                                src={thursdayQR}
                                                                                className="dropzone-img"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </aside>
                                                            )}
                                                        </div>
                                                    </section>
                                                )}
                                            </Dropzone>
                                            <br />
                                            <button
                                                type="button"
                                                className="btn btn-danger"
                                                style={{ height: "50px", margin: "auto 0 auto 10px" }}
                                                onClick={() => {
                                                    setThursdayQR("");
                                                    setThursdayQRImageFile();
                                                }}
                                            >
                                                {t("REMOVE_IMAGE")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-8">
                                    <div className="form-group row m-b-15">
                                        <label className="col-md-4 col-form-label">
                                            <b>{t("TNG_FRIDAY_QR")}</b>
                                        </label>
                                        <div className="col-md-7" style={{ display: "flex", lineHeight: "2.5" }}>
                                            <Dropzone
                                                accept={"image/*"}
                                                onDrop={(acceptedFiles) => QR_ImageDrop(acceptedFiles, 5)}
                                            >
                                                {({ getRootProps, getInputProps }) => (
                                                    <section>
                                                        <div
                                                            className="dropzone"
                                                            style={{
                                                                minHeight: "200px",
                                                                textAlign: "center",
                                                            }}
                                                            {...getRootProps()}
                                                        >
                                                            <input {...getInputProps()} />
                                                            <h4 style={{ color: "grey" }}>
                                                                {t(
                                                                    "DRAG_N_DROP_SOME_FILES_HERE_OR_CLICK_TO_SELECT_FILES"
                                                                )}
                                                            </h4>
                                                            {!stringIsNullOrEmpty(fridayQR) && (
                                                                <aside className="thumbsContainer">
                                                                    <div className="thumb">
                                                                        <div className="thumbInner">
                                                                            <img
                                                                                src={fridayQR}
                                                                                className="dropzone-img"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </aside>
                                                            )}
                                                        </div>
                                                    </section>
                                                )}
                                            </Dropzone>
                                            <br />
                                            <button
                                                type="button"
                                                className="btn btn-danger"
                                                style={{ height: "50px", margin: "auto 0 auto 10px" }}
                                                onClick={() => {
                                                    setFridayQR("");
                                                    setFridayQRImageFile();
                                                }}
                                            >
                                                {t("REMOVE_IMAGE")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-8">
                                    <div className="form-group row m-b-15">
                                        <label className="col-md-4 col-form-label">
                                            <b>{t("TNG_SATURDAY_QR")}</b>
                                        </label>
                                        <div className="col-md-7" style={{ display: "flex", lineHeight: "2.5" }}>
                                            <Dropzone
                                                accept={"image/*"}
                                                onDrop={(acceptedFiles) => QR_ImageDrop(acceptedFiles, 6)}
                                            >
                                                {({ getRootProps, getInputProps }) => (
                                                    <section>
                                                        <div
                                                            className="dropzone"
                                                            style={{
                                                                minHeight: "200px",
                                                                textAlign: "center",
                                                            }}
                                                            {...getRootProps()}
                                                        >
                                                            <input {...getInputProps()} />
                                                            <h4 style={{ color: "grey" }}>
                                                                {t(
                                                                    "DRAG_N_DROP_SOME_FILES_HERE_OR_CLICK_TO_SELECT_FILES"
                                                                )}
                                                            </h4>
                                                            {!stringIsNullOrEmpty(saturdayQR) && (
                                                                <aside className="thumbsContainer">
                                                                    <div className="thumb">
                                                                        <div className="thumbInner">
                                                                            <img
                                                                                src={saturdayQR}
                                                                                className="dropzone-img"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </aside>
                                                            )}
                                                        </div>
                                                    </section>
                                                )}
                                            </Dropzone>
                                            <br />
                                            <button
                                                type="button"
                                                className="btn btn-danger"
                                                style={{ height: "50px", margin: "auto 0 auto 10px" }}
                                                onClick={() => {
                                                    setSaturdayQR("");
                                                    setSaturdayQRImageFile();
                                                }}
                                            >
                                                {t("REMOVE_IMAGE")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-8">
                                    <div className="form-group row m-b-15">
                                        <label className="col-md-4 col-form-label">
                                            <b>{t("STATUS")}</b>
                                        </label>
                                        <div className="col-md-7">
                                            <div className="switcher">
                                                <input
                                                    type="checkbox"
                                                    name="status"
                                                    id="status"
                                                    onChange={(e) => setStatus(e.target.checked)}
                                                    value={true}
                                                    checked={status}
                                                    ref={register}
                                                />
                                                <label htmlFor="status"></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-lg-3">
                                        <div className="form-group">
                                            <button type="submit" className="btn btn-primary">
                                                {t("SUBMIT")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </PanelBody>
                    </Panel>}
                </div>
            </div>
        </div>
    );
};

export default withRouter(TnGSettings);
