import React, { useState, useEffect } from "react";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import "../../assets/css/custom.css";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
    UncontrolledButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Modal,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import { createFormBody, navigateTo, stringIsNullOrEmpty } from "../../util/Util";
import {
    ApiUrl,
    ApiKey,
    WebUrl,
    AccessRight,
    LanguageKey,
    AllVipOption,
} from "../../util/Constant";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import { checkIfPermissionExist } from "../../util/PermissionChecker";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";
import { useDispatch } from "react-redux";
import ApiEngine from "../../util/ApiEngine";
import { showMessage } from "../../redux/AppAction";

/// <summary>
/// Author : -
/// </summary>

const ManageMarqueeContent = (props) => {
    const { handleSubmit, errors, register, content } = useForm();
    const { t } = useTranslation(LanguageKey._PRIMARY);
    const _dispatch = useDispatch();
    let _history = useHistory();
    const [marqueeTagContentData, setMarqueeTagContentData] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [marqueePlatform, setMarqueePlatform] = useState("");
    const [marqueeContent, setMarqueeContent] = useState("");
    const [marqueeContentId, setMarqueeContentId] = useState("");
    let _tableColumns = [
        {
            Header: "PLATFORM",
            accessor: "platform",
            disableSortBy: true,
        },
        {
            Header: "CONTENT",
            accessor: "content",
            disableSortBy: true,
        },
        {
            id: "action",
            Header: "",
            Cell: ({ row }) => (
                <div className="btn-group m-r-5 m-b-5">
                    <UncontrolledButtonDropdown>
                        <DropdownToggle caret color="default">
                            <i className="fas fa-cog"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem
                                onClick={() => {
                                    setMarqueeContentId(row.original.id);
                                    setMarqueePlatform(row.original.platform);
                                    setMarqueeContent(row.original.content);
                                    setIsOpen(true);
                                    navigateTo(
                                        _history,
                                        WebUrl._URL_MARQUEE_TAG_CONTENT_DETAIL,
                                        row.original
                                    );
                                }}
                            >
                                <span className="text-warning">{t("EDIT")}</span>
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div>
            ),
            disableSortBy: true,
            disableFilters: true,
            width: 100,
            style: { overflow: "visible" },
        },
    ];
    /// <summary>
    /// Author : -
    /// </summary>
    useEffect(() => {
        getMarqueeTagContent();
    }, []);

    async function getMarqueeTagContent() {
        fetch(ApiUrl._API_GET_MARQUEE_TAG_CONTENT, {
            method: ApiKey._API_GET,
            headers: {
                "Content-Type": ApiKey._API_FORM_URLENCODED,
                "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
            },
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    setMarqueeTagContentData(responseJson[ApiKey._API_DATA_KEY]);


                }
            });
        
    }

    const onSubmit = async (data) => {
        let params = {
            id: marqueeContentId,
            content: data.content,
        };
        let responseJson = await ApiEngine.post(
            ApiUrl._API_UPDATE_MARQUEE_TAG_CONTENT,
            createFormBody(params)
        );
        _dispatch(
            showMessage(
                responseJson[ApiKey._API_SUCCESS_KEY],
                t(responseJson[ApiKey._API_MESSAGE_KEY])
            )
        );
        setIsOpen(false);
        getMarqueeTagContent();
    };

    async function close() {
    
}

    return (
        <div>
            <h1 className="page-header">
                {t("MANAGE_MARQUEE_TAG_CONTENT")}
            </h1>
            <Panel>
                <PanelBody>
                    <ReactTable data={marqueeTagContentData} columns={_tableColumns} />
                </PanelBody>
            </Panel>
        
            <Modal fade={false} contentClassName="modal-brand modal-bottom modal-numpad withdrawal" isOpen={isOpen} centered>
                
                <form onSubmit={handleSubmit(onSubmit)}>
                    <ModalBody style={{ minHeight: "30vh" }}>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="form-group row m-b-15">
                                    <label className="col-md-4 col-form-label">
                                        <b>{t("PLATFORM")}</b>
                                    </label>
                                    <div className="col-md-7">
                                        <label className="col-md-4 col-form-label">
                                            {marqueePlatform}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="form-group row m-b-15">
                                    <label className="col-md-4 col-form-label">
                                        <b>{t("CONTENT")}</b>
                                    </label>
                                    <div className="col-md-7">
                                            <textarea
                                            className="form-control"
                                            rows="5"
                                            name="content"
                                            ref={register()}
                                            style={{ marginTop: "10px" }}
                                            value={marqueeContent}
                                            onChange={(e) => setMarqueeContent(e.target.value)}
                                            ></textarea>
                                        {errors.content && (
                                            <div className="invalid-feedback">
                                                {t(errors.content.message)}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                </ModalBody>
                <ModalFooter>
                    <button
                        type="button"
                        className="btn btn-white"
                        onClick={() => setIsOpen(false)}
                    >
                        {t("CLOSE")}
                    </button>
                    <button type="submit" className="btn btn-primary">
                        {t("SUBMIT")}
                    </button>
                </ModalFooter>
            </form>
        </Modal>
        </div>
    );
};

export default ManageMarqueeContent;
