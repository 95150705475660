import React, { useState, useEffect } from "react";
import { Panel, PanelBody } from "../../components/panel/panel.jsx";
import Select from "react-select";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { setSaveAdminLog, setBusy, setIdle, showMessage } from "../../redux/AppAction.js";
import { useDispatch } from "react-redux";
import { ApiKey, ApiUrl, LanguageKey, DEFAULT_PAGE_SIZE, TransactionStatus, TransactionType } from "../../util/Constant.js";
import ApiEngine from "../../util/ApiEngine.js";
import { stringIsNullOrEmpty, numberWithCurrencyFormat2 } from "../../util/Util.js";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";
import { AsyncPaginate } from "react-select-async-paginate";
import { useHistory } from "react-router-dom";
import ReactTableCustom from "../../components/constantComponent/reactTable/ReactTableCustom.js";

/// <summary>
/// Author : -
/// </summary>

const FreeSpinHistoryReport = (props) => {
  const { t } = useTranslation(LanguageKey._PRIMARY);
  const _dispatch = useDispatch();
  let _history = useHistory();
  const [startDate, setStartDate] = useState(moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"));
  const [endDate, setEndDate] = useState(moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"));
  const [username, setUsername] = useState("");
  const [searchTrigger, setSearchTrigger] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [filterType, setFilterType] = useState(0);
  const [transactionType, setTransactionType] = useState("");
  const [labelUsername, setLabelUsername] = useState(t("PLEASE_SELECT_A_MEMBER"));
  const [selectedMember, setSelectedMember] = useState("");
  const [selectedMemberFreeSpin, setSelectedMemberFreeSpin] = useState(0);
  const [isSearch, setIsSearch] = useState(false);
  const _SELECTED_MEMBER_KEY = "selectedMemberKey";
  const [memberListUrl, setMemberListUrl] = useState(
    `${ApiUrl._API_GET_DOWNLINE_BY_REFERRER}?memberId=${selectedMember}`
  );

  const _defaultTableColumns = [

    {
      Header: t("TRANSACTION_DATE"),
      accessor: "theTime",
      Cell: ({ row }) => {
        return <div className="text-align-left">{moment(row.original.theTime).format("YYYY-MM-DD HH:mm:ss")}</div>;
      },
    },
    {
      Header: t("AMOUNT"),
      accessor: "amount",
      Cell: ({ row }) => {
        if (row.original.type === "DEPOSIT") {
            return <div className="text-align-right">{numberWithCurrencyFormat2(row.original.amount.toFixed(2), 2, true)}</div>;
        } else {
          return <div className="text-align-right">-</div>;
        }
      },
    },
    {
      Header: t("POINTS"),
      accessor: "points",
      minWidth: 100,
      Cell: ({ row }) => {
        if (row.original.type === "SPIN") {
          return <div className="text-align-right">{row.original.points.toFixed(2)}</div>;
        } else {
          return <div className="text-align-right">-</div>;
        }
      },
    },
    {
      Header: t("SPINS_STATUS"),
      accessor: "spinsEarned",
      minWidth: 100,
      Cell: ({ row }) => {
        if (row.original.spinsEarned > 0) {
          return <div className="text-align-right text-green font-weight-bold">{row.original.spinsEarned}</div>;
        } else {
          return <div className="text-align-right">-</div>;
        }
      },
    },
    {
      Header: t("TYPE"),
      accessor: "type",
      minWidth: 100,
      Cell: ({ row }) => {
        return <div className="text-align-center">{t(row.original.type)}</div>;
      },
    },
    {
      Header: t("SPINS_REMAINING"),
      accessor: "currentSpinLeft",
      minWidth: 100,
      Cell: ({ row }) => {
        return <div className="text-align-right">{t(row.original.currentSpinLeft)}</div>;
      },
    },
    {
      Header: t("REMARKS"),
      accessor: "remarks",
      Cell: ({ row }) => {
        return <div className="text-align-center">{t(row.original.remarks)}</div>;
      },
    }
  ];


  /// <summary>
  /// Author : -
  /// </summary>
  const handleEvent = (event, picker) => {
    setStartDate(moment(picker.startDate).format("YYYY-MM-DD HH:mm:ss"));
    setEndDate(moment(picker.endDate).format("YYYY-MM-DD HH:mm:ss"));
  };

  /// <summary>
  /// Author : -
  /// </summary>
  const searchBtnHandler = async () => {
    let apiUrl =
      ApiUrl._API_GET_FREE_SPIN_HISTORY_REPORT +
      "?startDate=" +
      startDate +
      "&endDate=" +
      endDate +
      "&memberId=" + selectedMember;
    if (selectedMember !== "") {
      setSearchTrigger(apiUrl);
    } else {
      _dispatch(
        showMessage(
          "error",
          t("PLEASE_SELECT_A_MEMBER")
        )
      );
    }
  };

  /// <summary>
  /// Author : Wong
  /// </summary>
  async function getMemberList(search, loadOptions, { page }) {
    var responseJson = await ApiEngine.get(
      `${ApiUrl._API_GET_USER_MEMBER_LIST}?&start=${(page - 1) * DEFAULT_PAGE_SIZE
      }&count=${DEFAULT_PAGE_SIZE}&keyword=${search}`
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY]["data"];
      let formattedData = data.map((m) => {
        return { label: m.username, value: m.id, spin: m.freeSpinCount };
      });

      return {
        options: formattedData,
        hasMore: formattedData.length == DEFAULT_PAGE_SIZE,
        additional: {
          page: page + 1,
        },
      };
    }
  }

  async function adminLog() {
    let params = {
      "startDate": startDate,
      "endDate": endDate,
      "type": filterType
    };

    if (selectedMember !== "") {
      params["userId"] = selectedMember
    }
    if (!stringIsNullOrEmpty(username)) {
      params["username"] = username;
    }
    if (!stringIsNullOrEmpty(selectedStatus)) {
      params["status"] = selectedStatus.value;
    }

    _dispatch(setSaveAdminLog("VIEW_FREE_SPIN_HISTORY_REPORT", _history.location.pathname, params));
  }

  return (
    <div>
      <h1 className="page-header">
        {stringIsNullOrEmpty(props.id) && t("FREE_SPIN_HISTORY_REPORT")}
      </h1>
      <Panel
        style={{
          marginBottom: !stringIsNullOrEmpty(props.id) ? "0px" : "20px",
        }}
      >
        <PanelBody>
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-3">
                  <div
                    className="form-group"
                    style={{
                      display: "flex",
                      width: "100%",
                      flexFlow: "nowrap",
                    }}
                  >
                    <div
                      className="input-group"
                      style={{ width: "100%", flexFlow: "nowrap" }}
                    >
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="fa fa-calendar"></i>
                        </span>
                      </div>
                      <div style={{ width: "80%" }}>
                        <DateRangePicker
                          containerStyles={{ width: "100%" }}
                          startDate={startDate}
                          endDate={endDate}
                          onApply={handleEvent}
                          alwaysShowCalendars={true}
                          locale={{ format: "YYYY-MM-DD HH:mm:ss" }}
                          timePicker={true}
                          timePickerSeconds={true}
                          ranges={{
                            Today: [
                              moment().startOf("day"),
                              moment().endOf("day"),
                            ],
                            Yesterday: [
                              moment().subtract(1, "days").startOf("day"),
                              moment().subtract(1, "days").endOf("day"),
                            ],
                            "Last 7 Days": [
                              moment().subtract(6, "days").startOf("day"),
                              moment().endOf("day"),
                            ],
                            "Last 30 Days": [
                              moment().subtract(29, "days").startOf("day"),
                              moment().endOf("day"),
                            ],
                            "This Month": [
                              moment().startOf("month"),
                              moment().endOf("month"),
                            ],
                            "Last Month": [
                              moment().subtract(1, "month").startOf("month"),
                              moment().subtract(1, "month").endOf("month"),
                            ],
                            "This Year": [
                              moment().startOf("year"),
                              moment().endOf("year"),
                            ],
                          }}
                        >
                          <input
                            type="text"
                            className="form-control"
                            value={startDate + " - " + endDate}
                          />
                        </DateRangePicker>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="form-group">
                    <AsyncPaginate
                      placeholder={labelUsername}
                      debounceTimeout={250}
                      loadOptions={getMemberList}
                      additional={{
                        page: 1,
                      }}
                      value={selectedMember}
                      onChange={(e) => {
                        if (!stringIsNullOrEmpty(e.value)) {
                          sessionStorage.setItem(_SELECTED_MEMBER_KEY, e.value);
                        } else {
                          sessionStorage.removeItem(_SELECTED_MEMBER_KEY);
                        }
                        setLabelUsername(e.label);
                        setSelectedMember(e.value);
                        setSelectedMemberFreeSpin(e.spin);
                      }}
                    />
                    {/*<div>{t("KEY_KEYWORD_TO_CONTINUE")}</div>*/}
                  </div>
                </div>
                <div className="col-lg-2">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                      setIsSearch(!isSearch)
                      searchBtnHandler();
                      adminLog();
                    }}
                  >
                    <i className="fas fa-search"></i> {t("SEARCH")}
                  </button>
                </div>
              </div>
              {!stringIsNullOrEmpty(selectedMember) &&
                <div className="row">
                  <div className="col-lg-12">
                    {t("CURRENT_REMAINING_FREE_SPIN")} : {selectedMemberFreeSpin}
                  </div>
                </div>
              }
              <hr />
            </div>
          </div>
          <div
            className="row"
            style={{
              padding: !stringIsNullOrEmpty(props.id) ? "0px 15px 0px 15px" : "",
            }}
          >
            <div className="col-lg-12">
              <ReactTableCustom
                columns={_defaultTableColumns}
                fetchUrl={searchTrigger}
                isSearch={isSearch}
              />
            </div>
          </div>
        </PanelBody>
      </Panel>
    </div>
  );
};

export default FreeSpinHistoryReport;
