import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import { useHistory } from "react-router-dom";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import { ApiKey, ApiUrl, LanguageKey } from "../../util/Constant";
import { stringIsNullOrEmpty, createFormBody } from "../../util/Util";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { setBusy, setIdle, showMessage, setSaveAdminLog } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";

/// <summary>
/// Author : -
/// </summary>
const ProductSettingDetail = (props) => {
  const { t } = useTranslation(LanguageKey._PRIMARY);
  let _history = useHistory();
  const _location = useLocation();
  const _dispatch = useDispatch();

  const { register, unregister, handleSubmit, errors } = useForm();
  const [minimumRebate, setMinimumRebate] = useState("");
  const [maximumRebate, setMaximumRebate] = useState("");
  const [rebatePercent, setRebatePercent] = useState("");
  const [turnoverRate, setTurnoverRate] = useState("");
  const [productSettingId, setProductSettingId] = useState("");
  const [accountProductId, setAccountProductId] = useState("");
  const [accountProductOption, setAccountProductOption] = useState([]);
  const [vipId, setVipId] = useState("");
  const [vipOption, setVipOption] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  const [categoryOption, setCategoryOption] = useState([]);

  /// <summary>
  /// Author : -
  /// </summary>
  const submitForm = async (data, e) => {
    let params = {
      minimumRebate: data.minimumRebate,
      maximumRebate: data.maximumRebate,
      rebatePercent: data.rebatePercent,
      turnoverRate: data.turnoverRate,
      vipId: vipId,
      categoryId: categoryId,
      accountProductId: accountProductId,
    };

    if (!stringIsNullOrEmpty(productSettingId)) {
      params["id"] = productSettingId;
      _dispatch(setSaveAdminLog("EDIT_BO_PRODUCT_SETTING", _history.location.pathname, params));
    } else {
      _dispatch(setSaveAdminLog("CREATE_BO_PRODUCT_SETTING", _history.location.pathname, params));
    }

    let formBody = createFormBody(params);
    _dispatch(setBusy());

    fetch(ApiUrl._API_CREATE_OR_UPDATE_PRODUCT_SETTING, {
      method: ApiKey._API_POST,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
      body: formBody,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        _dispatch(
          showMessage(
            responseJson[ApiKey._API_SUCCESS_KEY],
            t(responseJson[ApiKey._API_MESSAGE_KEY])
          )
        );
        _dispatch(setIdle());

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          setProductSettingId(responseJson[ApiKey._API_DATA_KEY]["id"]);
        }
      });
  };

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    register({ name: "vipId" }, { required: "PLEASE_SELECT_VIP" });
    register(
      { name: "accountProductId" },
      { required: "PLEASE_SELECT_PRODUCT" }
    );
    register({ name: "categoryId" }, { required: "PLEASE_SELECT_CATEGORY" });
    init();
  }, []);

  /// <summary>
  /// Author : -
  /// </summary>
  async function init() {
    if (_location.state) {
      var apiUrl = ApiUrl._API_GET_PRODUCT_SETTING_BY_ID;
      apiUrl += "?id=" + _location.state.id;
      fetch(apiUrl, {
        method: ApiKey._API_GET,
        headers: {
          "Content-Type": ApiKey._API_FORM_URLENCODED,
          "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setProductSettingId(responseJson[ApiKey._API_DATA_KEY]["id"]);
            setMinimumRebate(
              responseJson[ApiKey._API_DATA_KEY]["minimumRebate"]
            );
            setMaximumRebate(
              responseJson[ApiKey._API_DATA_KEY]["maximumRebate"]
            );
            setRebatePercent(
              responseJson[ApiKey._API_DATA_KEY]["rebatePercent"]
            );
            setTurnoverRate(responseJson[ApiKey._API_DATA_KEY]["turnoverRate"]);

            setAccountProductId(
              responseJson[ApiKey._API_DATA_KEY]["accountProductId"]
            );
            setCategoryId(
              responseJson[ApiKey._API_DATA_KEY]["productCategoryId"]
            );
            setVipId(responseJson[ApiKey._API_DATA_KEY]["vipId"]);
            unregister("vipId");
            unregister("accountProductId");
            unregister("categoryId");
          }
        });
    }

    await fetch(ApiUrl._API_GET_MASTER_PRODUCT_ASSIGNMENT_DATA, {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            const accountProductOptionList = [];
            responseJson[ApiKey._API_DATA_KEY].forEach(function (option) {
              accountProductOptionList.push({
                label: option.productName,
                value: option.id,
              });
            });

            setAccountProductOption(accountProductOptionList);
          }
        }
      });

    await fetch(ApiUrl._API_GET_PRODUCT_CATEGORY, {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            const categoryOptionList = [];
            responseJson[ApiKey._API_DATA_KEY].forEach(function (option) {
              categoryOptionList.push({ label: option.name, value: option.id });
            });

            setCategoryOption(categoryOptionList);
          }
        }
      });

    await fetch(ApiUrl._API_GET_USER_VIP, {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            const vipList = [];
            responseJson[ApiKey._API_DATA_KEY].forEach(function (option) {
              vipList.push({ label: option.name, value: option.id });
            });

            setVipOption(vipList);
          }
        }
      });
  }

  return (
    <div>
      <h1 className="page-header">
        {stringIsNullOrEmpty(categoryId)
          ? t("EDIT_PRODUCT_SETTING")
          : t("ADD_PRODUCT_SETTING")}
        <NavigationButton history={_history} />
      </h1>
      <div className="row">
        <div className="col-xl-12">
          <Panel>
            <PanelBody>
              <form onSubmit={handleSubmit(submitForm)}>
                <div className="row">
                  <div className="col-lg-8">
                    <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        <b>{t("PRODUCT")}</b>
                      </label>
                      <div className="col-md-7">
                        <Select
                          name="accountProductId"
                          options={accountProductOption}
                          placeholder={
                            accountProductOption.filter(
                              (option) => option.value == accountProductId
                            )[0] !== undefined
                              ? accountProductOption.filter(
                                  (option) => option.value == accountProductId
                                )[0].label
                              : ""
                          }
                          value={accountProductOption.filter(
                            (option) => option.value == accountProductId
                          )}
                          onChange={(e) => {
                            unregister("accountProductId");
                            setAccountProductId(e.value);
                          }}
                        />
                        {errors.accountProductId && (
                          <div className="invalid-feedback">
                            {t(errors.accountProductId.message)}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        <b>{t("CATEGORY")}</b>
                      </label>
                      <div className="col-md-7">
                        <Select
                          name="categoryId"
                          options={categoryOption}
                          placeholder={
                            categoryOption.filter(
                              (option) => option.value == categoryId
                            )[0] !== undefined
                              ? categoryOption.filter(
                                  (option) => option.value == categoryId
                                )[0].label
                              : ""
                          }
                          value={categoryOption.filter(
                            (option) => option.value == categoryId
                          )}
                          onChange={(e) => {
                            unregister("categoryId");
                            setCategoryId(e.value);
                          }}
                        />
                        {errors.categoryId && (
                          <div className="invalid-feedback">
                            {t(errors.categoryId.message)}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        <b>{t("VIP")}</b>
                      </label>
                      <div className="col-md-7">
                        <Select
                          name="vipId"
                          options={vipOption}
                          placeholder={
                            vipOption.filter(
                              (option) => option.value == vipId
                            )[0] !== undefined
                              ? vipOption.filter(
                                  (option) => option.value == vipId
                                )[0].label
                              : ""
                          }
                          value={vipOption.filter(
                            (option) => option.value == vipId
                          )}
                          onChange={(e) => {
                            unregister("vipId");
                            setVipId(e.value);
                          }}
                        />
                        {errors.vipId && (
                          <div className="invalid-feedback">
                            {t(errors.vipId.message)}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        <b>{t("MINIMUM_REBATE")}</b>
                      </label>
                      <div className="col-md-7 col-form-label">
                        <input
                          type="number"
                          name="minimumRebate"
                          ref={register({
                            required: "PLEASE_ENTER_MINIMUM_REBATE",
                            min: {
                              value: 0,
                              message: "PLEASE_ENTER_VALUE_NOT_LESS_THAN_ZERO",
                            },
                          })}
                          className="form-control m-b-5"
                          placeholder={t("PLEASE_ENTER_MINIMUM_REBATE")}
                          defaultValue={minimumRebate}
                        />
                        {errors.minimumRebate && (
                          <div className="invalid-feedback">
                            {t(errors.minimumRebate.message)}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        <b>{t("MAXIMUM_REBATE")}</b>
                      </label>
                      <div className="col-md-7 col-form-label">
                        <input
                          type="number"
                          name="maximumRebate"
                          ref={register({
                            required: "PLEASE_ENTER_MAXIMUM_REBATE",
                            min: {
                              value: 0,
                              message: "PLEASE_ENTER_VALUE_NOT_LESS_THAN_ZERO",
                            },
                          })}
                          className="form-control m-b-5"
                          placeholder={t("PLEASE_ENTER_MAXIMUM_REBATE")}
                          defaultValue={maximumRebate}
                        />
                        {errors.maximumRebate && (
                          <div className="invalid-feedback">
                            {t(errors.maximumRebate.message)}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        <b>{t("REBATE_PERCENT")}</b>
                      </label>
                      <div className="col-md-7 col-form-label">
                        <input
                          type="number"
                          name="rebatePercent"
                          ref={register({
                            required: "PLEASE_ENTER_REBATE_PERCENT",
                            min: {
                              value: 0,
                              message: "PLEASE_ENTER_VALUE_NOT_LESS_THAN_ZERO",
                            },
                            max: {
                              value: 100,
                              message: "PLEASE_ENTER_VALUE_LESS_THAN_100",
                            },
                          })}
                          className="form-control m-b-5"
                          placeholder={t("PLEASE_ENTER_REBATE_PERCENT")}
                          defaultValue={rebatePercent}
                        />
                        {errors.rebatePercent && (
                          <div className="invalid-feedback">
                            {t(errors.rebatePercent.message)}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        <b>{t("TURNOVER_RATE")}</b>
                      </label>
                      <div className="col-md-7 col-form-label">
                        <input
                          type="number"
                          name="turnoverRate"
                          ref={register({
                            required: "PLEASE_ENTER_TURNOVER_RATE",
                            min: {
                              value: 0,
                              message: "PLEASE_ENTER_VALUE_NOT_LESS_THAN_ZERO",
                            },
                          })}
                          className="form-control m-b-5"
                          placeholder={t("PLEASE_ENTER_TURNOVER_RATE")}
                          defaultValue={turnoverRate}
                        />
                        {errors.turnoverRate && (
                          <div className="invalid-feedback">
                            {t(errors.turnoverRate.message)}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-lg-3">
                    <div className="form-group">
                      <button type="submit" className="btn btn-primary">
                        {t("Submit")}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </PanelBody>
          </Panel>
        </div>
      </div>
    </div>
  );
};

export default ProductSettingDetail;
