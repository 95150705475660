import React from "react";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import { createFormBody } from "../../util/Util";
import {
    ApiUrl,
    ApiKey,
    LanguageKey,
} from "../../util/Constant";
import { setBusy, setIdle, showMessage, setSaveAdminLog } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";
import { useHistory } from "react-router-dom";

/// <summary>
/// Author : -
/// </summary>

const ManageManualJob = (props) => {
    const { t } = useTranslation(LanguageKey._PRIMARY);
    const _dispatch = useDispatch();
    let _history = useHistory();

    const StartManualJob = (jobName) => {
        _dispatch(setBusy);
        let apiUrl = ApiUrl._API_EXECUTE_MANUAL_JOB;
        let params = {
            "jobName": jobName
        };
        let formBody = createFormBody(params);
        fetch(apiUrl, {
            method: ApiKey._API_POST,
            headers: {
                "Content-Type": ApiKey._API_FORM_URLENCODED,
                "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
            },
            body: formBody
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    _dispatch(
                        showMessage(
                            responseJson[ApiKey._API_SUCCESS_KEY],
                            t("OPERATION_SUCCESS")
                        )
                    )
                }
            });
        _dispatch(setSaveAdminLog("CREATE_MANUAL_JOB", _history.location.pathname, params));
        _dispatch(setIdle);
    }
    return (
        <div>
            <h1 className="page-header">
                {t("MANUAL_JOBS")}
            </h1>
            <Panel>
                <PanelBody>
                    <table style={{ width: "100%", border:"1px solid #d6dadd"}}>
                        <tr style={{borderBottom:"1px solid #a7aeb5"}}>
                            <th style={{borderRight: "1px solid #d6dadd", height:"60px", paddingLeft:"15px" }}>{t("JOB_TITLE")}</th><th>   </th>
                        </tr>
                        <tr>
                            <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>ManualResettle568ProductGameRecord      </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartManualJob("ManualResettle568ProductGameRecord")}>{t("START")}</button></td>
                        </tr>
                    </table>
                </PanelBody>
            </Panel>
        </div>
    );
};

export default ManageManualJob;
