import { WebUrl, AccessRight } from "../../util/Constant";

/// <summary>
/// Author : -
/// </summary>
const Menu = [
    {
        path: WebUrl._URL_DASHBOARD, icon: 'fa fa-th', title: 'DASHBOARD', numberLabel:"1. ", isDummy: false, permission: AccessRight._DASHBOARD_DETAILS_PERMISSION + AccessRight._READ_PERMISSION_STRING
    },
    {
        path: WebUrl._URL_MANAGE_COMPANY, icon: 'fa fa-gem', title: 'COMPANY', numberLabel: "99. ", isDummy: false, permission: AccessRight._COMPANY_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING
    },
    {
        path: '/account', icon: 'fa fa-users', title: 'MANAGE_MEMBERS', numberLabel: "2. ", isDummy: true,
        children: [
            { path: WebUrl._URL_MANAGE_MEMBER, title: 'MANAGE_ACCOUNTS', numberLabel: "2.1 ", permission: AccessRight._MEMBER_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_MANAGE_MEMBER_PRODUCT_USERNAME, title: 'MANAGE_MEMBER_PRODUCT_USERNAME', numberLabel: "2.2 ", permission: AccessRight._MEMBER_PRODUCT_USERNAME_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_MEMBER_REPORT, title: 'MEMBER_REPORT_TITLE', numberLabel: "2.3 ", permission: AccessRight._MEMBER_REPORT_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_MANAGE_IP_BLOCK, title: 'MANAGE_IP_BLOCK', numberLabel: "2.4 ", permission: AccessRight._MANAGE_IP_BLOCK_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_MANAGE_MEMBER_WALLET_STATEMENT, title: 'MEMBER_WALLET_STATEMENT', numberLabel: "2.5 ", permission: AccessRight._MEMBER_WALLET_STATEMENT_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_TODAY_NEW_MEMBERS, title: 'NEW_MEMBERS_7_DAYS', numberLabel: "2.6 ", permission: AccessRight._NEW_MEMBER_LIST_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_TRANSFER_LOG, title: 'TRANSFER_LOG', numberLabel: "2.7 ", permission: AccessRight._TRANSFER_LOG_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            //{ path: WebUrl._URL_MANAGE_WALLET_REPORT, title: 'WALLET_TRANSFER_REPORT', numberLabel: "2.8 ", permission: AccessRight._WALLET_TRANSFER_REPORT_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_BET_DETAIL_REPORT, title: 'BET_DETAIL_REPORT_TITLE', numberLabel: "2.8 ", permission: AccessRight._BET_DETAIL_REPORT_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_TRANSACTION_QUEUE, title: 'MANAGE_TRANSACTION_QUEUE', numberLabel: "2.9 ", permission: AccessRight._TRANSACTION_QUEUE_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_MANAGE_MEMBER_PO, title: 'MANAGE_ACCOUNTS_PO', numberLabel: "2.10 ", permission: AccessRight._MANAGE_MEMBER_PO_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_MANAGE_MEMBER_CS, title: 'MANAGE_ACCOUNTS_CS', numberLabel: "2.11 ", permission: AccessRight._MANAGE_MEMBER_CS_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_MANAGE_MEMBER_WALLET_STATEMENT_RECON, title: 'MEMBER_WALLET_STATEMENT_RECON', numberLabel: "2.12 ", permission: AccessRight._MEMBER_WALLET_STATEMENT_RECON_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_MANAGE_MOBILE_TAC, title: 'MANAGE_MOBILE_TAC', numberLabel: "2.13 ", permission: AccessRight._MANAGE_MOBILE_TAC_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_MANAGE_ROLLBACK_TICKET, title: 'MANAGE_ROLLBACK_TICKET', numberLabel: "2.14", permission: AccessRight._MANAGE_ROLLBACK_TICKET_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_VIEW_MEMBER, title: 'EDIT_MEMBER_DETAIL', numberLabel: "2.15", permission: AccessRight._EDIT_MEMBER_DETAIL_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_MEMBER_WITH_ROLLOVER_REPORT, title: 'MEMBER_WITH_ROLLOVER_REPORT', numberLabel: "2.16", permission: AccessRight._RESET_ROLLOVER_PERMISSION + AccessRight._READ_PERMISSION_STRING},
        ]
    },
    {
        path: '/shareholder', icon: 'fa fa-sitemap', title: 'SHAREHOLDERS_LIST', numberLabel: "3. ", isDummy: true, permission: AccessRight._SHAREHOLDER_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        children: [
            { path: WebUrl._URL_SHAREHOLDERS_LIST, title: 'SHAREHOLDERS', numberLabel: "3.1 ", permission: AccessRight._SHAREHOLDER_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_SHAREHOLDERS_PRODUCTS, title: 'SHAREHOLDERS_PRODUCTS', numberLabel: "3.2 ", permission: AccessRight._SHAREHOLDER_PRODUCT_SETTING_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_SHAREHOLDERS_PROCESS_HUAT_TICKET, title: 'SHAREHOLDERS_PROCESS_HUAT_TICKET', numberLabel: "3.3 ", permission: AccessRight._SHAREHOLDER_ADD_HUAT_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_SHAREHOLDERS_INTERNAL_PROCESS, title: 'SHAREHOLDERS_INTERNAL_PROCESS', numberLabel: "3.4 ", permission: AccessRight._SHAREHOLDER_PROCESS_SHARE_SALES_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_SHAREHOLDERS_EXTERNAL_PROCESS, title: 'SHAREHOLDERS_EXTERNAL_PROCESS', numberLabel: "3.4.2 ", permission: AccessRight._SHAREHOLDER_PROCESS_SHARE_SALES_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_SHAREHOLDERS_PROCESS, title: 'SHAREHOLDERS_PROCESS', numberLabel: "3.4.3 ", permission: AccessRight._SHAREHOLDER_PROCESS_SHARE_SALES_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_SHAREHOLDERS_REPORT, title: 'SHAREHOLDERS_REPORT', numberLabel: "3.5 ", permission: AccessRight._SHAREHOLDER_REPORT_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_SHAREHOLDERS_DOWNLINE_REPORT, title: 'SHAREHOLDERS_DOWNLINE_REPORT', numberLabel: "3.6 ", permission: AccessRight._SHAREHOLDER_DOWNLINE_REPORT_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_SHAREHOLDERS_UNCLAIMED_REPORT, title: 'SHAREHOLDER_UNCLAIM_REPORT', numberLabel: "3.7 ", permission: AccessRight._SHAREHOLDER_UNCLAIMED_SALES_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_SHAREHOLDERS_UNCLAIMED_REPORT_LIST, title: 'SHAREHOLDER_UNCLAIM_REPORT_LIST', numberLabel: "3.8 ", permission: AccessRight._SHAREHOLDER_UNCLAIMED_SALES_LIST_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_TOTAL_SHARE_PAYOUT_REPORT, title: 'TOTAL_SHARE_PAYOUT_REPORT', numberLabel: "3.9 ", permission: AccessRight._SHAREHOLDER_SHARE_PAYOUT_REPORT_PERMISSION + AccessRight._READ_PERMISSION_STRING },
        ]
    },
    {
        path: '/transaction', icon: 'fas fa-exchange-alt', title: 'ONLINE_BANKING', numberLabel: "4. ", isDummy: true,
        children: [
            { path: WebUrl._URL_TNG_SETTINGS, title: 'TNG_SETTINGS', numberLabel: "4.1 ", permission: AccessRight._TNG_SETTING_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_TRANSACTION_ENQUIRY, title: 'TRANSACTION_ENQUIRY_TITLE', numberLabel: "4.2 ", permission: AccessRight._TRANSACTION_ENQUIRY_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_DEPOSIT_ENQUIRY, title: 'DEPOSIT_ENQUIRY_TITLE', numberLabel: "4.2.4 ", permission: AccessRight._DEPOSIT_ENQUIRY_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_WITHDRAWAL_ENQUIRY, title: 'WITHDRAWAL_ENQUIRY_TITLE', numberLabel: "4.2.5 ", permission: AccessRight._WITHDRAWAL_ENQUIRY_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_BANK_SUMMARY_REPORT, title: 'BANK_SUMMARY_REPORT_TITLE', numberLabel: "4.3 ", permission: AccessRight._BANK_SUMMARY_REPORT_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_DEPOSIT, title: 'DEPOSIT', numberLabel: "4.4 ", permission: AccessRight._DEPOSIT_TRANSACTION_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_WITHDRAWAL, title: 'WITHDRAWAL', numberLabel: "4.5 ", permission: AccessRight._WITHDRAWAL_TRANSACTION_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_WITHDRAWAL_SETTINGS, title: 'WITHDRAWAL_SETTING', numberLabel: "4.6 ", permission: AccessRight._WITHDRAWAL_SETTINGS_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_BANK_ACCOUNT, title: 'BANK_ACCOUNTS', numberLabel: "4.7 ", permission: AccessRight._BANK_ACCOUNT_SETTING_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_DEPOSIT_REFERENCE, title: 'DEPOSIT_REFERENCE', numberLabel: "4.9 ", permission: AccessRight._DEPOSIT_REFERENCE_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_BONUS_DEPOSIT, title: 'BONUS_DEPOSIT', numberLabel: "4.10 ", permission: AccessRight._BONUS_DEPOSIT_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_MANAGE_AUTOWITHDRAWAL_BANK_ACCOUNT, title: 'MANAGE_AUTOWITHDRAWAL_BANK_ACCOUNT', numberLabel: "4.11 ", permission: AccessRight._MANAGE_AUTOWITHDRAWAL_BANK_ACCOUNT_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_AUTO_WITHDRAWAL_SETTINGS, title: 'AUTO_WITHDRAWAL_SETTINGS', numberLabel: "4.12 ", permission: AccessRight._AUTO_WITHDRAWAL_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_AUTO_DEPOSIT_SETTING, title: 'AUTO_DEPOSIT_SETTING', numberLabel: "4.13 ", permission: AccessRight._AUTO_DEPOSIT_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_NOTISCAN_BANK_ACCOUNT, title: 'NOTISCAN_BANK_ACCOUNT', numberLabel: "4.14 ", permission: AccessRight._NOTISCAN_BANKACCOUNT_PERMISSION + AccessRight._WRITE_PERMISSION_STRING }
        ]
    },
    {
        path: '/paymentgateway', icon: 'fas fa-exchange-alt', title: 'PAYMENT_GATEWAY', numberLabel: "5. ", isDummy: true,
        children: [
            { path: WebUrl._URL_INSTANT_PAY_SETTING, title: 'PAYMENT_GATEWAY_SETTINGS', numberLabel: "5.1 ", permission: AccessRight._INSTANT_PAY_SETTING_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_SERVICE_CHARGE_REPORT, title: 'SERVICE_CHARGE_REPORT', numberLabel: "5.2 ", permission: AccessRight._SERVICE_CHARGE_REPORT_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            ]
    },
    {
        path: '/adjustment', icon: 'fas fa-file-alt', title: 'ADJUSTMENT', numberLabel: "6. ", isDummy: true, permission: AccessRight._ADJUSTMENT_REPORT_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        children: [
            { path: WebUrl._URL_ADJUSTMENT, title: 'ADJUSTMENT', numberLabel: "6.1 ", permission: AccessRight._ADJUSTMENT_TRANSACTION_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            //{ path: WebUrl._URL_COMMISSION_ADJUSTMENT, title: 'COMMISSION_ADJUSTMENT', numberLabel: "6.2 ", permission: AccessRight._COMMISSION_ADJUSTMENT_PERMISSION + AccessRight._WRITE_PERMISSION_STRING },
            //{ path: WebUrl._URL_BONUS_ADJUSTMENT, title: 'BONUS_ADJUSTMENT', numberLabel: "6.3 ", permission: AccessRight._BONUS_ADJUSTMENT_PERMISSION + AccessRight._WRITE_PERMISSION_STRING },
            //{ path: WebUrl._URL_BONUS_ADJUSTMENT, title: 'BONUS_ADJUSTMENT', numberLabel: "6.3 ", permission: AccessRight._BONUS_ADJUSTMENT_PERMISSION + AccessRight._WRITE_PERMISSION_STRING },
            { path: WebUrl._URL_ADJUSTMENT_REPORT, title: 'ADJUSTMENT_REPORT', numberLabel: "6.4 ", permission: AccessRight._ADJUSTMENT_TRANSACTION_PERMISSION + AccessRight._READ_PERMISSION_STRING }, //, permission: AccessRight._ADJUSTMENT_REPORT_PERMISSION + AccessRight._READ_PERMISSION_STRING
            { path: WebUrl._URL_GAME_POINTS_ADJUSTMENT, title: 'GAME_POINTS_ADJUSTMENT', numberLabel: "6.7 ", permission: AccessRight._GAME_POINTS_ADJUSTMENT_PERMISSION + AccessRight._WRITE_PERMISSION_STRING },
            { path: WebUrl._URL_GAME_POINTS_ADJUSTMENT_REPORT, title: 'GAME_POINTS_ADJUSTMENT_REPORT', numberLabel: "6.8 ", permission: AccessRight._GAME_POINTS_ADJUSTMENT_REPORT_PERMISSION + AccessRight._WRITE_PERMISSION_STRING },
            { path: WebUrl._URL_VT_ADJUSTMENT, title: 'VT_ADJUSTMENT', numberLabel: "6.11 ", permission: AccessRight._VT_ADJUSTMENT_PERMISSION + AccessRight._WRITE_PERMISSION_STRING },
            { path: WebUrl._URL_VT_ADJUSTMENT_REPORT, title: 'VT_ADJUSTMENT_REPORT', numberLabel: "6.12 ", permission: AccessRight._VT_ADJUSTMENT_REPORT_PERMISSION + AccessRight._WRITE_PERMISSION_STRING },
        ]
    },
    {
        path: '/report', icon: 'far fa-file-alt', title: 'REPORT', numberLabel: "7. ", isDummy: true,
        children: [
            //{ path: WebUrl._URL_WIN_LOSS_REPORT2, title: 'WINLOSS_REPORT', permission: AccessRight._PROFIT_LOSS_REPORT_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            //{ path: WebUrl._URL_WIN_LOSS_REPORT2, title: 'WINLOSS_REPORT2', permission: AccessRight._PROFIT_LOSS_REPORT_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_WIN_LOSS_REPORT3, title: 'WINLOSS_REPORT', numberLabel: "7.1 ", permission: AccessRight._WINLOSS_REPORT_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_TALLY_WIN_LOSS_REPORT, title: 'TALLY_WINLOSS_REPORT', numberLabel: "7.2 ", permission: AccessRight._TALLY_WINLOSS_REPORT_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_PROFIT_LOSS_REPORT, title: 'PROFIT_LOSS_REPORT_TITLE', numberLabel: "7.3 ", permission: AccessRight._PROFIT_LOSS_REPORT_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_ARCHIVE_SALES_REPORT, title: 'ARCHIVE_SALES_REPORT_TITLE', numberLabel: "7.3.2 ", permission: AccessRight._ARCHIVE_SALES_REPORT_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_DAILY_SUMMARY_REPORT, title: 'DAILY_SUMMARY_REPORT_TITLE', numberLabel: "7.4 ", permission: AccessRight._DAILY_SUMMARY_REPORT_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_BONUS_COMMISSION_REPORT, title: 'BONUS_COMMISSION_REPORT', numberLabel: "7.5 ", permission: AccessRight._BONUS_COMM_REPORT_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_TRANSACTION_REPORT, title: 'TRANSACTION_REPORT', numberLabel: "7.6 ", permission: AccessRight._TRANSACTION_REPORT_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_WALLET_TRANSFER_REPORT, title: 'WALLET_TRANSFER_REPORTS', numberLabel: "7.7 ", permission: AccessRight._WALLET_TRANSFER_REPORT_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_PROCESS_GAME_RECORD_REPORT, title: 'PROCESS_GAME_RECORD_REPORT', numberLabel: "7.8 ", permission: AccessRight._PROCESS_GAME_RECORD_REPORT_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_568_NON_PROCESS_GAME_RECORD_REPORT, title: '568_NON_PROCESS_GAME_RECORD_REPORT', numberLabel: "7.9 ", permission: AccessRight._NON_PROCESS_568_GAME_RECORD_REPORT_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_COMISSION_REPORT_LIST, title: 'COMMISSION_REPORT_LIST', numberLabel: "7.10 ", permission: AccessRight._COMM_REPORT_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_MANAGE_MEMBER_FULL_NAME, title: 'MANAGE_MEMBER_FULL_NAME', numberLabel: "7.11 ", permission: AccessRight._MEMBER_FULLNAME_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_TRANSACTION_SUMMARY_REPORT, title: 'TRANSACTION_SUMMARY_REPORT', numberLabel: "7.14 ", permission: AccessRight._TRANSACTION_SUMMARY_REPORT_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_ACTIVE_MEMBER_REPORT, title: 'ACTIVE_MEMBER_REPORT', numberLabel: "7.15 ", permission: AccessRight._ACTIVE_MEMBER_REPORT_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_BIRTHDAY_BONUS_REPORT, title: 'BIRTHDAY_BONUS_REPORT', numberLabel: "7.16", permission: AccessRight._ACTIVE_MEMBER_REPORT_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_ADMIN_OPERATION_LOG_REPORT, title: 'ADMIN_OPERATION_LOG_REPORT', numberLabel: "7.17", permission: AccessRight._ADMIN_OPERATION_LOG_REPORT_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_PAYMENT_OFFICER_REPORT, title: 'PAYMENT_OFFICER_REPORT', numberLabel: "7.20", permission: AccessRight._PAYMENT_OFFICER_REPORT_PERMISSION + AccessRight._WRITE_PERMISSION_STRING },
            { path: WebUrl._URL_FREE_SPIN_HISTORY_REPORT, title: 'FREE_SPIN_HISTORY_REPORT', numberLabel: "7.21", permission: AccessRight._FREE_SPIN_HISTORY_REPORT_PERMISSION + AccessRight._WRITE_PERMISSION_STRING },
            { path: WebUrl._URL_DAILY_NON_DEPOSIT_NEW_PLAYER, title: 'DAILY_NON_DEPOSIT_NEW_PLAYER_REPORT', numberLabel: "7.22", permission: AccessRight._DAILY_NON_DEPOSIT_NEW_PLAYER_REPORT_PERMISSION + AccessRight._WRITE_PERMISSION_STRING }
        ]
    },
    {
        path: '/tool', icon: 'fas fa-tools', title: 'TOOL', numberLabel: "8. ", isDummy: true,
        children: [
            { path: WebUrl._URL_IP_MONITOR, title: 'IP_MONITOR', numberLabel: "8.1 ", permission: AccessRight._IP_MONITOR_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_PRODUCT_GAME_RECORD_WITH_REWARD_NOT_TALLY_RECORDS, title: 'PRODUCT_GAME_RECORD_WITH_REWARD_NOT_TALLY_RECORDS', numberLabel: "8.2 ", permission: AccessRight._PRODUCT_GAME_RECORD_WITH_REWARD_PERMISSION + AccessRight._READ_PERMISSION_STRING },
        ]
    },
    {
        path: '/admin', icon: 'fa fa-user', title: 'ADMIN', numberLabel: "9. ", isDummy: true,
        children: [
            { path: WebUrl._URL_MANAGE_SUB_ACCOUNT, title: 'SUB_ACCOUNTS', numberLabel: "9.1 ", permission: AccessRight._SUB_ACCOUNT_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_MANAGE_ROLE, title: 'MANAGE_ROLES', numberLabel: "9.2 ", permission: AccessRight._ROLE_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_ACTIVITY_LOG, title: 'ACTIVITY_LOG', numberLabel: "9.3 ", permission: AccessRight._ACTIVITY_LOG_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_CHANGE_PASSWORD, title: 'CHANGE_PASSWORD', numberLabel: "9.4 ", permission: AccessRight._CHANGE_PASSWORD_PERMISSION + AccessRight._READ_PERMISSION_STRING }
        ]
    },
    {
        path: '/product', icon: 'fa fa-cubes', title: 'PRODUCTS', numberLabel: "10. ", isDummy: true,
        children: [
            { path: WebUrl._URL_PRODUCT_CATEGORY, title: 'PRODUCT_CATEGORY', numberLabel: "10.1 ", permission: AccessRight._PRODUCT_CATEGORY_SETTING_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_MASTER_PRODUCT, title: 'MASTER_PRODUCTS', numberLabel: "10.2 ", permission: AccessRight._MASTER_PRODUCT_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_USER_PRODUCT, title: 'PRODUCTS', numberLabel: "10.3 ", permission: AccessRight._PRODUCT_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_RETRIEVE_MEMBER_BET_DATA, title: 'RETRIEVE_MEMBER_BET_DATA', numberLabel: "10.4 ", permission: AccessRight._RETRIEVE_GAME_DATA_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_918KISS_NO_VT, title: 'NO_VT_918KISS', numberLabel: "10.5 ", permission: AccessRight._918KISS_NO_VT_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_MANAGE_NEW_GAME_RANK, title: 'MANAGE_GAME_RANK_TITLE', numberLabel: "10.6", permission: AccessRight._MANAGE_NEW_GAME_RANK_PERMISSION + AccessRight._WRITE_PERMISSION_STRING }
            //{ path: WebUrl._URL_UPDATE_PP_SLOTS_GAMES_RANK, title: 'UPDATE_PP_SLOTS_GAMES_RANK', numberLabel: "10.6 ", permission: AccessRight._UPDATE_PP_SLOTS_GAMES_RANK_PERMISSION + AccessRight._READ_PERMISSION_STRING },
        ]
    },
    {
        path: '/configure', icon: 'fa fa-cogs', title: 'CONFIGURATION', numberLabel: "11. ", isDummy: true,
        children: [
            { path: WebUrl._URL_MANAGE_SMS_CONTENT, title: 'MANAGE_SMS_CONTENT', numberLabel: "11.1 ", permission: AccessRight._SMS_CONTENT_SETTING_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_SMS_SETTING, title: 'SMS_SETTINGS', numberLabel: "11.2 ", permission: AccessRight._SMS_SETTING_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_MANAGE_BONUS, title: 'MANAGE_BONUS', numberLabel: "11.3 ", permission: AccessRight._BONUS_SETTING_PERMISSION + AccessRight._READ_PERMISSION_STRING},
            { path: WebUrl._URL_MANAGE_BANK, title: 'MANAGE_BANKS', numberLabel: "11.4 ", permission: AccessRight._BANK_SETTING_PERMISSION + AccessRight._READ_PERMISSION_STRING},
            { path: WebUrl._URL_MANAGE_REJECT_REASON, title: 'MANAGE_REJECT_REASON', numberLabel: "11.5 ", permission: AccessRight._REJECT_REASON_SETTING_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_PRODUCT_SETTING, title: 'PRODUCT_SETTING_TITLE', numberLabel: "11.6 ", permission: AccessRight._PRODUCT_SETTING_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_VIP_LEVEL, title: 'MANAGE_VIP_LEVEL', numberLabel: "11.7 ", permission: AccessRight._VIP_SETTING_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_MANAGE_VIP_BENEFIT, title: 'VIP_BENEFIT_TITLE', numberLabel: "11.8 ", permission: AccessRight._VIP_SETTING_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_MANAGE_VIP_BENEFIT_CONTENT, title: 'VIP_BENEFIT_CONTENT', numberLabel: "11.9 ", permission: AccessRight._VIP_SETTING_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_REMARK, title: 'MANAGE_REMARK', numberLabel: "11.10 ", permission: AccessRight._REMARK_SETTING_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_TAG, title: 'MANAGE_TAG', numberLabel: "11.11 ", permission: AccessRight._TAG_SETTING_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_LANGUAGE, title: 'MANAGE_LANGUAGE', numberLabel: "11.12 ", permission: AccessRight._LANGUAGE_SETTING_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_BONUS_TYPE, title: 'MANAGE_BONUS_TYPE', numberLabel: "11.13 ", permission: AccessRight._BONUS_TYPE_SETTING_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_MANAGE_COMMUNICATION, title: 'MANAGE_COMMUNICATION', numberLabel: "11.14 ", permission: AccessRight._MANAGE_COMMUNICATION_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_MANAGE_SYSTEM_SETTING, title: 'MANAGE_SYSTEM_SETTING', numberLabel: "11.15 ", permission: AccessRight._SYSTEM_SETTING_ACCESS + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_MANAGE_RECURRING_JOB, title: 'RECURRING_JOBS', numberLabel: "11.16 ", permission: AccessRight._RECURRING_JOB_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_MANAGE_MANUAL_JOB, title: 'MANUAL_JOBS', numberLabel: "11.17 ", permission: AccessRight._RECURRING_JOB_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_MANAGE_SKIN_SETTINGS, title: 'MANAGE_SKIN_SETTINGS', numberLabel: "11.18 ", permission: AccessRight._SKIN_SETTING_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_MANAGE_VIDEO_TUTORIAL, title: 'MANAGE_VIDEO_TUTORIAL', numberLabel: "11.19 ", permission: AccessRight._VIDEO_TUTORIAL_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_GAME_POINTS_ADJUSTMENT_REMARK, title: 'GAME_POINTS_ADJUSTMENT_REMARK', numberLabel: "11.24 ", permission: AccessRight._GAME_POINTS_ADJUSTMENT_REMARK_PERMISSION + AccessRight._WRITE_PERMISSION_STRING },
            { path: WebUrl._URL_MANAGE_TRANSACTION_TIMER, title: 'MANAGE_TRANSACTION_TIMER', numberLabel: "11.25 ", permission: AccessRight._MANAGE_TRANSACTION_TIMER_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_MANAGE_PUSH_NOTIFICATION, title: 'MANAGE_PUSH_NOTIFICATION', numberLabel: "11.26 ", permission: AccessRight._MANAGE_PUSH_NOTIFICATION_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_MANAGE_CHAT_SPHERE_PHONE, title: 'MANAGE_CHAT_SPHERE_PHONE', numberLabel: "11.27 ", permission: AccessRight._MANAGE_CHAT_SPHERE_PHONE_PERMISSION + AccessRight._WRITE_PERMISSION_STRING },
        ]
    },
    {
        path: '/content', icon: 'fa fa-archive', title: 'CONTENT', numberLabel: "12. ", isDummy: true,
        children: [
            { path: WebUrl._URL_ANNOUNCEMENT, title: 'ANNOUNCEMENT', numberLabel: "12.1 ", permission: AccessRight._ANNOUNCEMENT_SETTING_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_BANNER, title: 'BANNER', numberLabel: "12.2 ", permission: AccessRight._BANNER_SETTING_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_PROMOTION, title: 'PROMOTION', numberLabel: "12.3 ", permission: AccessRight._PROMOTION_SETTING_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_SEO, title: 'SEO', numberLabel: "12.4 ", permission: AccessRight._SEO_SETTING_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_SCRIPT, title: 'SCRIPT', numberLabel: "12.5 ", permission: AccessRight._SCRIPT_SETTING_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_EMAIL, title: 'EMAIL', numberLabel: "12.6 ", permission: AccessRight._EMAIL_SETTING_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_CONTENT, title: 'CONTENT', numberLabel: "12.7 ", permission: AccessRight._CONTENT_SETTING_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_MANAGE_CONTACT_US, title: 'CONTACT_US', numberLabel: "12.8 ", permission: AccessRight._CONTACT_US_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_REWARD_BANNER, title: 'REWARD_BANNERS', numberLabel: "12.9 ", permission: AccessRight._REWARD_BANNER_SETTING_PERMISSION + AccessRight._READ_PERMISSION_STRING },   
            { path: WebUrl._URL_MANAGE_WEB_CONTENT, title: 'MANAGE_WEB_CONTENTS', numberLabel: "12.10 ", permission: AccessRight._MANAGE_WEB_CONTENT_SETTING_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_MARQUEE_TAG_CONTENT, title: 'MANAGE_MARQUEE_TAG_CONTENT', numberLabel: "12.12", permission: AccessRight._MARQUEE_TAG_CONTENT + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_MANAGE_DEPOSIT_TUTORIAL_CONTENT, title: 'MANAGE_DEPOSIT_TUTORIAL_CONTENT', numberLabel: "12.13", permission: AccessRight._MANAGE_DEPOSIT_TUTORIAL_CONTENT_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_MANAGE_SEO_META_CONTENT, title: 'MANAGE_SEO_META_CONTENT', numberLabel: "12.14", permission: AccessRight._MANAGE_SEO_META_CONTENT_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_PROMO_BANNER, title: 'PROMO_BANNER', numberLabel: "12.15 ", permission: AccessRight._PROMO_BANNER_SETTING_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_MANAGE_GAME_POINT_CONTENT, title: 'MANAGE_GAME_POINT_CONTENT', numberLabel: "12.16", permission: AccessRight._MANAGE_GAME_POINT_CONTENT_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_MANAGE_CHAT_SPHERE, title: 'MANAGE_CHAT_SPHERE', numberLabel: "12.17", permission: AccessRight._MANAGE_CHAT_SPHERE_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_BIG_WINNING, title: 'MANAGE_BIG_WINNING', numberLabel: "12.18 ", permission: AccessRight._MANAGE_BIG_WINNING + AccessRight._READ_PERMISSION_STRING }

        ]
    },
    {
        path: '/riskControl', icon: 'fa fa-exclamation-triangle', title: 'RISK_CONTROL', numberLabel: "13. ", isDummy: true, permission: AccessRight._LIVE_MONITORING_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        children: [
            { path: WebUrl._URL_LIVE_MONITOR, title: 'LIVE_MONITOR', numberLabel: "13.1 ", permission: AccessRight._LIVE_MONITORING_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_SPORTS_LIVE_MONITOR, title: 'SPORTS_LIVE_MONITOR', numberLabel: "13.2 ", permission: AccessRight._LIVE_MONITORING_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_SPORTS_TEAM_BET_REPORT, title: 'SPORTS_TEAM_BET_REPORT', numberLabel: "13.3 ", permission: AccessRight._LIVE_MONITORING_PERMISSION + AccessRight._READ_PERMISSION_STRING }
        ]
    },
    {
        path: '/reward', icon: 'fa fa-gift', title: 'REWARDS', numberLabel: "14. ", isDummy: true, permission: AccessRight._REWARDS_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        children: [

            { path: WebUrl._URL_REWARDS_SETTING, title: 'REWARDS_SETTING', numberLabel: "14.1 ", permission: AccessRight._REWARDS_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_REWARDS_SCHEDULE, title: 'REWARDS_SCHEDULES', numberLabel: "14.2 ", permission: AccessRight._REWARDS_PERMISSION + AccessRight._READ_PERMISSION_STRING }, 
            { path: WebUrl._URL_CLAIMED_REWARDS_REPORT, title: 'CLAIMED_UNCLAIMED_REWARDS_REPORT', numberLabel: "14.3 ", permission: AccessRight._REWARDS_PERMISSION + AccessRight._READ_PERMISSION_STRING },  
            { path: WebUrl._URL_FORFEITED_REWARDS_REPORT, title: 'FORFEITED_REWARDS_REPORT', numberLabel: "14.4 ", permission: AccessRight._REWARDS_PERMISSION + AccessRight._READ_PERMISSION_STRING },
        ]
    },
    {
        path: '/loyalty', icon: 'fas fa-hand-holding-heart', title: 'GAME_POINTS', numberLabel: "15. ", isDummy: true, permission: AccessRight._LOYALTY_ITEM_LIST_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        children: [

            { path: WebUrl._URL_MANAGE_LOYALTY_ITEM_LIST, title: 'GAME_POINTS_LIST', numberLabel: "15.1 ", permission: AccessRight._LOYALTY_ITEM_LIST_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_MANAGE_LOYALTY_REDEMPTION, title: 'GAME_POINTS_REDEMPTION', numberLabel: "15.2 ", permission: AccessRight._LOYALTY_REDEMPTION_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_MANAGE_LOYALTY_POINTS, title: 'GAME_POINTS_SETTING', numberLabel: "15.3 ", permission: AccessRight._LOYALTY_POINTS_PERMISSION + AccessRight._WRITE_PERMISSION_STRING },
            { path: WebUrl._URL_MANAGE_MEMBER_LOYALTY_POINTS, title: 'MEMBER_GAME_POINTS', numberLabel: "15.4 ", permission: AccessRight._MEMBER_LOYALTY_POINTS_PERMISSION + AccessRight._READ_PERMISSION_STRING },
        ]
    },
    {
        path: '/freeSpin', icon: 'fa fa-hand-holding-heart', title: 'FREE_SPIN', numberLabel: "16. ", isDummy: true,
        children: [

            { path: WebUrl._URL_MANAGE_FREE_SPIN, title: 'MANAGE_FREE_SPIN', numberLabel: "16.1", permission: AccessRight._MANAGE_FREE_SPIN_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_FREE_SPIN_RECORD, title: 'FREE_SPIN_RECORD', numberLabel: "16.2", permission: AccessRight._FREE_SPIN_RECORD_PERMISSION + AccessRight._READ_PERMISSION_STRING },
         
        ]
    },
    {
        path: '/memberCredit', icon: 'fa fa-dollar-sign', title: 'MEMBER_CREDIT', numberLabel: "17. ", isDummy: true, permission: AccessRight._MEMBER_CREDIT + AccessRight._READ_PERMISSION_STRING,
        children: [

            { path: WebUrl._URL_MEMBER_CREDIT, title: 'MEMBER_CREDIT', numberLabel: "17.1", permission: AccessRight._MEMBER_CREDIT + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_MEMBER_CREDIT_REPORT_LOG, title: 'MEMBER_CREDIT_REPORT_LOG', numberLabel: "17.2", permission: AccessRight._MEMBER_CREDIT_REPORT_LOG + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_MEMBER_CREDIT_REPORT, title: 'MEMBER_CREDIT_REPORT', numberLabel: "17.3", permission: AccessRight._MEMBER_CREDIT_REPORT + AccessRight._READ_PERMISSION_STRING },
         
        ]
    },
    {
      path: '/dailyCheckIn', icon: 'fa fa-dollar-sign', title: 'DAILY_CHECK_IN', numberLabel: "18. ", isDummy: true, permission: AccessRight._MANAGE_DAILY_CHECK_IN_PERMISSION + AccessRight._READ_BIT_POSITION,
      children: [
        { path: WebUrl._URL_MANAGE_DAILY_CHECK_IN, title: 'MANAGE_DAILY_CHECK_IN', numberLabel: "18.1", permission: AccessRight._MANAGE_DAILY_CHECK_IN_PERMISSION + AccessRight._READ_PERMISSION_STRING },
        { path: WebUrl._URL_DAILY_CHECK_IN_REPORT, title: 'DAILY_CHECK_IN_REPORT', numberLabel: "18.2", permission: AccessRight._DAILY_CHECK_IN_REPORT_PERMISSION + AccessRight._READ_PERMISSION_STRING },
        { path: WebUrl._URL_MANUAL_DAILY_CHECK_IN, title: 'MANUAL_DAILY_CHECK_IN', numberLabel: "18.3", permission: AccessRight._MANUAL_DAILY_CHECK_IN_PERMISSION + AccessRight._WRITE_PERMISSION_STRING }
      ]
    },
    {
        path: WebUrl._URL_HELP, icon: 'fas fa-question-circle', title: 'HELP', numberLabel: "", isDummy: false/*, permission: AccessRight._LIVE_MONITORING_PERMISSION + AccessRight._READ_PERMISSION_STRING*/
    }
]

export default Menu;