import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Panel, PanelBody } from "./../../../components/panel/panel.jsx";
import { useHistory } from "react-router-dom";
import {
  stringIsNullOrEmpty,
  createMultiPartFormBody,
} from "../../../util/Util";
import { ApiKey, ApiUrl, LanguageKey } from "../../../util/Constant";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import Dropzone from "react-dropzone";
import { setBusy, setIdle, showMessage, setSaveAdminLog } from "../../../redux/AppAction";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";

/// <summary>
/// Author : -
/// </summary>
const EditBank = (props) => {
  const { t } = useTranslation(LanguageKey._PRIMARY);
  let _history = useHistory();
  const _location = useLocation();
  const { register, handleSubmit, errors } = useForm();
  const _dispatch = useDispatch();

  const [bankCode, setBankCode] = useState("");
  const [bankName, setBankName] = useState("");
  const [bankShortName, setBankShortName] = useState("");
  const [bankWebsite, setBankWebsite] = useState("");
  const [bankCurrencyCode, setBankCurrencyCode] = useState("");

  const [minimumDeposit, setMinimumDeposit] = useState("");
  const [minimumWithdrawal, setMinimumWithdrawal] = useState("");
  const [maximumDeposit, setMaximumDeposit] = useState("");
  const [maximumWithdrawal, setMaximumWithdrawal] = useState("");
  const [bankCountryCode, setBankCountryCode] = useState("");

  const [bankAddress, setBankAddress] = useState("");
  const [bankPostalCode, setBankPostalCode] = useState("");
  const [bankPhone, setBankPhone] = useState("");
  const [bankFax, setBankFax] = useState("");
  const [instantPay, setInstantPay] = useState(true);
  const [instantPayRank, setInstantPayRank] = useState();
  const [normalRank, setNormalRank] = useState();
  const [bankId, setBankId] = useState("");
  const [status, setStatus] = useState(true);
  const [logoImage, setLogoImage] = useState(null);
  const [logoFile, setLogoFile] = useState(null);
  const [isNewImage, setIsNewImage] = useState(false);
  const [isNewImage2, setIsNewImage2] = useState(false);
  const [logoImage2, setLogoImage2] = useState(null);
  const [logoFile2, setLogoFile2] = useState(null);
  const [enableDeposit, setEnableDeposit] = useState(false);
  const [enableWithdrawal, setEnableWithdrawal] = useState(true);

  /// <summary>
  /// Author : -
  /// </summary>
  const submitForm = async (data, e) => {
    let params = {
      bankCode: data.bankCode,
      bankName: data.bankName,
      bankShortName: data.bankShortName,
      bankWebsite: data.bankWebsite,
      currencyCode: data.bankCurrencyCode,
      minimumDeposit: data.minimumDeposit,
      minimumWithdrawal: data.minimumWithdrawal,
      maximumDeposit: data.maximumDeposit,
      maximumWithdrawal: data.maximumWithdrawal,
      countryCode: data.bankCountryCode,
      address: data.bankAddress,
      postalCode: data.bankPostalCode,
      phone: data.bankPhone,
      fax: data.bankFax,
      instantPay: data.instantPay,
      normalRank: data.normalRank,
      status: data.status,
      isNewBankLogoImage: isNewImage,
      isNewBankLogoImage2: isNewImage2,
      enableDeposit: data.enableDeposit,
      enableWithdrawal: data.enableWithdrawal,
    };

    /// <summary>
    /// Author : -
    /// Used to set the logo to params
    /// </summary>
    if (!stringIsNullOrEmpty(logoFile)) {
      params["bankLogoImage"] = logoFile;
    }

    if (stringIsNullOrEmpty(logoImage)) {
      params["bankLogoImageRemoved"] = true;
    }

    if (!stringIsNullOrEmpty(logoFile2)) {
      params["bankLogoImage2"] = logoFile2;
    }

    if (stringIsNullOrEmpty(logoImage2)) {
      params["bankLogoImageRemoved2"] = true;
    }

    if (!stringIsNullOrEmpty(bankId)) {
      params["id"] = bankId;
      _dispatch(setSaveAdminLog("EDIT_BO_BANK", _history.location.pathname, params));
    } else {
      _dispatch(setSaveAdminLog("CREATE_BO_BANK", _history.location.pathname, params));
    }
    if (data.instantPay) {
      params["instantPayRank"] = data.instantPayRank;
    }

    console.log(params);
    let formBody = createMultiPartFormBody(params);
    _dispatch(setBusy());

    fetch(ApiUrl._API_CREATE_OR_UPDATE_BANK_DETAIL, {
      method: ApiKey._API_POST,
      headers: {
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
      body: formBody,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        _dispatch(setIdle());

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          setIsNewImage(false);
          setIsNewImage2(false);
          setBankId(responseJson[ApiKey._API_DATA_KEY]["id"]);
        }

        _dispatch(
          showMessage(
            responseJson[ApiKey._API_SUCCESS_KEY],
            t(responseJson[ApiKey._API_MESSAGE_KEY])
          )
        );
      });
  };

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    if (_location.state) {
      var apiUrl = ApiUrl._API_GET_BANK_DETAIL;
      apiUrl += "?bankId=" + _location.state.id;
      fetch(apiUrl, {
        method: ApiKey._API_GET,
        headers: {
          "Content-Type": ApiKey._API_FORM_URLENCODED,
          "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setBankId(responseJson[ApiKey._API_DATA_KEY]["id"]);
            setBankCode(responseJson[ApiKey._API_DATA_KEY]["bankCode"]);

            setBankName(responseJson[ApiKey._API_DATA_KEY]["bankName"]);
            setBankShortName(
              responseJson[ApiKey._API_DATA_KEY]["bankShortName"]
            );
            setBankWebsite(responseJson[ApiKey._API_DATA_KEY]["bankWebsite"]);
            setBankCurrencyCode(
              responseJson[ApiKey._API_DATA_KEY]["currencyCode"]
            );
            setMinimumDeposit(
              responseJson[ApiKey._API_DATA_KEY]["minimumDeposit"]
            );
            setMinimumWithdrawal(
              responseJson[ApiKey._API_DATA_KEY]["minimumWithdrawal"]
            );
            setMaximumDeposit(
              responseJson[ApiKey._API_DATA_KEY]["maximumDeposit"]
            );
            setMaximumWithdrawal(
              responseJson[ApiKey._API_DATA_KEY]["maximumWithdrawal"]
            );
            setBankCountryCode(
              responseJson[ApiKey._API_DATA_KEY]["countryCode"]
            );
            setBankAddress(responseJson[ApiKey._API_DATA_KEY]["address"]);

            setBankPostalCode(responseJson[ApiKey._API_DATA_KEY]["postalCode"]);
            setBankPhone(responseJson[ApiKey._API_DATA_KEY]["phone"]);
            setBankFax(responseJson[ApiKey._API_DATA_KEY]["fax"]);
            setInstantPay(responseJson[ApiKey._API_DATA_KEY]["instantPay"]);
            setInstantPayRank(responseJson[ApiKey._API_DATA_KEY]["instantPayRank"]);
            setNormalRank(responseJson[ApiKey._API_DATA_KEY]["normalRank"])
            setStatus(responseJson[ApiKey._API_DATA_KEY]["status"]);

            setEnableDeposit(
              responseJson[ApiKey._API_DATA_KEY]["enableDeposit"]
            );
            setEnableWithdrawal(
              responseJson[ApiKey._API_DATA_KEY]["enableWithdrawal"]
            );

            if (
              !stringIsNullOrEmpty(
                responseJson[ApiKey._API_DATA_KEY]["bankLogo"]
              )
            ) {
              setLogoImage(responseJson[ApiKey._API_DATA_KEY]["bankLogo"]);
            }

            if (
              !stringIsNullOrEmpty(
                responseJson[ApiKey._API_DATA_KEY]["bankLogo2"]
              )
            ) {
              setLogoImage2(responseJson[ApiKey._API_DATA_KEY]["bankLogo2"]);
            }
          }
        });
    }
  }, []);

  /// <summary>
  /// Author : -
  /// Used to set logo
  /// </summary>
  const logoDrop = (acceptedFiles) => {
    if (acceptedFiles.length !== 0) {
      const file = acceptedFiles[0];
      setLogoFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setLogoImage(reader.result);
        setIsNewImage(true);
      };
      reader.readAsDataURL(file);
    }
  };

  const logoDrop2 = (acceptedFiles) => {
    if (acceptedFiles.length !== 0) {
      const file = acceptedFiles[0];
      setLogoFile2(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setLogoImage2(reader.result);
        setIsNewImage2(true);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div>
      <h1 className="page-header">
        {t("ADD_NEW_BANK")}
        <button
          type="button"
          className="btn btn-inverse"
          style={{ float: "right" }}
          onClick={() => {
            _history.goBack();
          }}
        >
          <i className="fas fa-long-arrow-alt-left"></i>
        </button>
      </h1>
      <div className="row">
        <div className="col-xl-12">
          <Panel>
            <PanelBody>
              <form onSubmit={handleSubmit(submitForm)}>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="card text-truncate mb-3">
                          <div className="card-header">
                            <b>{t("BANK_DETAIL")}</b>
                          </div>
                          <div className="card-body">
                            <div className="form-group row m-b-15">
                              <label className="col-md-4 col-form-label">
                                <b>{t("BANK_CODE")}</b>
                              </label>
                              <div className="col-md-7">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="bankCode"
                                  defaultValue={bankCode}
                                  ref={register({
                                    required: "PLEASE_ENTER_BANK_CODE",
                                  })}
                                  placeholder={t("PLEASE_ENTER_BANK_CODE")}
                                />
                                {errors.bankCode && (
                                  <div className="invalid-feedback">
                                    {t(errors.bankCode.message)}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="form-group row m-b-15">
                              <label className="col-md-4 col-form-label">
                                <b>{t("BANK_NAME")}</b>
                              </label>
                              <div className="col-md-7">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="bankName"
                                  defaultValue={bankName}
                                  ref={register({
                                    required: "PLEASE_ENTER_BANK_NAME",
                                  })}
                                  placeholder={t("PLEASE_ENTER_BANK_NAME")}
                                />
                                {errors.bankName && (
                                  <div className="invalid-feedback">
                                    {t(errors.bankName.message)}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="form-group row m-b-15">
                              <label className="col-md-4 col-form-label">
                                <b>{t("BANK_SHORT_NAME")}</b>
                              </label>
                              <div className="col-md-7">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="bankShortName"
                                  defaultValue={bankShortName}
                                  ref={register({
                                    required: "PLEASE_ENTER_BANK_SHORT_NAME",
                                  })}
                                  placeholder={t(
                                    "PLEASE_ENTER_BANK_SHORT_NAME"
                                  )}
                                />
                                {errors.bankShortName && (
                                  <div className="invalid-feedback">
                                    {t(errors.bankShortName.message)}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="form-group row m-b-15">
                              <label className="col-md-4 col-form-label">
                                <b>{t("BANK_OFFICIAL_WEBSITE_URL")}</b>
                              </label>
                              <div className="col-md-7">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="bankWebsite"
                                  defaultValue={bankWebsite}
                                  ref={register({
                                    required: "PLEASE_ENTER_BANK_WEBSITE",
                                  })}
                                  placeholder={t("PLEASE_ENTER_BANK_WEBSITE")}
                                />
                                {errors.bankWebsite && (
                                  <div className="invalid-feedback">
                                    {t(errors.bankWebsite.message)}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="form-group row m-b-15">
                              <label className="col-md-4 col-form-label">
                                <b>{t("INSTANT_PAY")}</b>
                              </label>
                              <div className="col-md-7">
                                <div className="switcher">
                                  <input
                                    type="checkbox"
                                    name="instantPay"
                                    id="instantPay"
                                    onChange={(e) =>
                                      setInstantPay(e.target.checked)
                                    }
                                    value={true}
                                    checked={instantPay}
                                    ref={register}
                                  />
                                  <label htmlFor="instantPay"></label>
                                </div>
                              </div>
                            </div>
                            {instantPay && (
                              <div className="form-group row m-b-15">
                                <label className="col-md-4 col-form-label">
                                  <b>{t("INSTANT_PAY_RANK")}</b>
                                </label>
                                <div className="col-md-7">
                                  <input
                                    type="number"
                                    className="form-control"
                                    placeholder={t("PLEASE_ENTER_RANK")}
                                    name="instantPayRank"
                                    defaultValue={instantPayRank}
                                    ref={register({
                                      required: "PLEASE_ENTER_RANK",
                                      min: {
                                        value: 0,
                                        message: "PLEASE_ENTER_VALUE_NOT_LESS_THAN_ZERO",
                                      },
                                      max: {
                                        value: 100,
                                        message: "PLEASE_ENTER_VALUE_NOT_MORE_THAN_100",
                                      },
                                    })}
                                  />
                                  {errors.instantPayRank && (
                                    <div className="invalid-feedback">
                                      {t(errors.instantPayRank.message)}
                                    </div>
                                  )}
                                </div>
                              </div>
                            )
                            }

                           
                              <div className="form-group row m-b-15">
                                <label className="col-md-4 col-form-label">
                                  <b>{t("NORMAL_RANK")}</b>
                                </label>
                                <div className="col-md-7">
                                  <input
                                    type="number"
                                    className="form-control"
                                    placeholder={t("PLEASE_ENTER_RANK")}
                                    name="normalRank"
                                    defaultValue={normalRank}
                                    ref={register({
                                      required: "PLEASE_ENTER_RANK",
                                      min: {
                                        value: 0,
                                        message: "PLEASE_ENTER_VALUE_NOT_LESS_THAN_ZERO",
                                      },
                                      max: {
                                        value: 100,
                                        message: "PLEASE_ENTER_VALUE_NOT_MORE_THAN_100",
                                      },
                                    })}
                                  />
                                  {errors.normalRank && (
                                    <div className="invalid-feedback">
                                      {t(errors.normalRank.message)}
                                    </div>
                                  )}
                                </div>
                              </div>
                            <div className="form-group row m-b-15">
                              <label className="col-md-4 col-form-label">
                                <b>{t("STATUS")}</b>
                              </label>
                              <div className="col-md-7">
                                <div className="switcher">
                                  <input
                                    type="checkbox"
                                    name="status"
                                    id="status"
                                    onChange={(e) =>
                                      setStatus(e.target.checked)
                                    }
                                    value={true}
                                    checked={status}
                                    ref={register}
                                  />
                                  <label htmlFor="status"></label>
                                </div>
                              </div>
                            </div>
                            <div className="form-group row m-b-15">
                              <label className="col-md-4 col-form-label">
                                <b>{t("MEMBER_SITE_DISPLAY")}</b>
                              </label>
                              <div className="col-md-7">
                                <div className="d-inline-flex align-items-center mr-3">
                                  <div className="switcher">
                                    <input
                                      type="checkbox"
                                      name="enableDeposit"
                                      id="enableDeposit"
                                      onChange={(e) =>
                                        setEnableDeposit(e.target.checked)
                                      }
                                      checked={enableDeposit}
                                      ref={register}
                                    />
                                    <label htmlFor="enableDeposit"></label>
                                  </div>
                                  <strong className="ml-2">
                                    {t("DEPOSIT")}
                                  </strong>
                                </div>
                                <div className="d-inline-flex align-items-center">
                                  <div className="switcher">
                                    <input
                                      type="checkbox"
                                      name="enableWithdrawal"
                                      id="enableWithdrawal"
                                      onChange={(e) =>
                                        setEnableWithdrawal(e.target.checked)
                                      }
                                      checked={enableWithdrawal}
                                      ref={register}
                                    />
                                    <label htmlFor="enableWithdrawal"></label>
                                  </div>
                                  <strong className="ml-2">
                                    {t("WITHDRAWAL")}
                                  </strong>
                                </div>
                              </div>
                            </div>
                            <div className="form-group row m-b-15">
                              <label className="col-md-4 col-form-label">
                                <b>{t("BANK_LOGO")}</b>
                              </label>
                              <div className="col-md-7">
                                <Dropzone
                                  accept={"image/*"}
                                  onDrop={(acceptedFiles) =>
                                    logoDrop(acceptedFiles)
                                  }
                                >
                                  {({ getRootProps, getInputProps }) => (
                                    <section>
                                      <div
                                        className="dropzone"
                                        style={{
                                          minHeight: "200px",
                                          textAlign: "center",
                                        }}
                                        {...getRootProps()}
                                      >
                                        <input {...getInputProps()} />
                                        <h4 style={{ color: "grey" }}>
                                          {t("SELECT_OR_DRAG_AND_DROP_FILE")}
                                        </h4>
                                        {!stringIsNullOrEmpty(logoImage) && (
                                          <aside className="thumbsContainer">
                                            <div className="thumb">
                                              <div className="thumbInner">
                                                <img
                                                  src={logoImage}
                                                  className="dropzone-img"
                                                />
                                              </div>
                                            </div>
                                          </aside>
                                        )}
                                      </div>
                                    </section>
                                  )}
                                </Dropzone>
                                <br />
                                <br />
                                <button
                                  type="button"
                                  className="btn btn-danger"
                                  onClick={() => {
                                    setLogoImage("");
                                    setLogoFile();
                                  }}
                                >
                                  {t("REMOVE_LOGO")}
                                </button>
                              </div>
                            </div>
                            <div className="form-group row m-b-15">
                              <label className="col-md-4 col-form-label">
                                <b>{t("BANK_LOGO_BIG")}</b>
                              </label>
                              <div className="col-md-7">
                                <Dropzone
                                  accept={"image/*"}
                                  onDrop={(acceptedFiles) =>
                                    logoDrop2(acceptedFiles)
                                  }
                                >
                                  {({ getRootProps, getInputProps }) => (
                                    <section>
                                      <div
                                        className="dropzone"
                                        style={{
                                          minHeight: "200px",
                                          textAlign: "center",
                                        }}
                                        {...getRootProps()}
                                      >
                                        <input {...getInputProps()} />
                                        <h4 style={{ color: "grey" }}>
                                          {t("SELECT_OR_DRAG_AND_DROP_FILE")}
                                        </h4>
                                        {!stringIsNullOrEmpty(logoImage2) && (
                                          <aside className="thumbsContainer">
                                            <div className="thumb">
                                              <div className="thumbInner">
                                                <img
                                                  src={logoImage2}
                                                  className="dropzone-img"
                                                />
                                              </div>
                                            </div>
                                          </aside>
                                        )}
                                      </div>
                                    </section>
                                  )}
                                </Dropzone>
                                <br />
                                <br />
                                <button
                                  type="button"
                                  className="btn btn-danger"
                                  onClick={() => {
                                    setLogoImage2("");
                                    setLogoFile2();
                                  }}
                                >
                                  {t("REMOVE_LOGO")}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="card text-truncate mb-3">
                          <div className="card-header">
                            <b>
                              {t("BANK_AMOUNT_LIMIT")} ({t("DEPOSIT")} /{" "}
                              {t("WITHDRAWAL")})
                            </b>
                          </div>
                          <div className="card-body">
                            <div className="form-group row m-b-15">
                              <label className="col-md-3 col-form-label">
                                <b>{t("BANK_CURRENCY_CODE")}</b>
                              </label>
                              <div className="col-md-7">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="bankCurrencyCode"
                                  defaultValue={bankCurrencyCode}
                                  ref={register({
                                    required: "PLEASE_ENTER_BANK_CURRENCY_CODE",
                                  })}
                                  placeholder={t(
                                    "PLEASE_ENTER_BANK_CURRENCY_CODE"
                                  )}
                                />
                                {errors.bankCurrencyCode && (
                                  <div className="invalid-feedback">
                                    {t(errors.bankCurrencyCode.message)}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="form-group row m-b-15">
                              <label className="col-md-3 col-form-label">
                                <b>{t("MINIMUM_DEPOSIT")}</b>
                              </label>
                              <div className="col-md-7">
                                <input
                                  type="number"
                                  className="form-control"
                                  name="minimumDeposit"
                                  defaultValue={minimumDeposit}
                                  ref={register({
                                    required: "PLEASE_ENTER_MINIMUM_DEPOSIT",
                                    min: {
                                      value: 0,
                                      message:
                                        "PLEASE_ENTER_VALUE_NOT_LESS_THAN_ZERO",
                                    },
                                  })}
                                />
                                {errors.minimumDeposit && (
                                  <div className="invalid-feedback">
                                    {t(errors.minimumDeposit.message)}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="form-group row m-b-15">
                              <label className="col-md-3 col-form-label">
                                <b>{t("MAXIMUM_DEPOSIT")}</b>
                              </label>
                              <div className="col-md-7">
                                <input
                                  type="number"
                                  className="form-control"
                                  name="maximumDeposit"
                                  defaultValue={maximumDeposit}
                                  ref={register({
                                    required: "PLEASE_ENTER_MAXIMUM_DEPOSIT",
                                    min: {
                                      value: 0,
                                      message:
                                        "PLEASE_ENTER_VALUE_NOT_LESS_THAN_ZERO",
                                    },
                                  })}
                                />
                                {errors.maximumDeposit && (
                                  <div className="invalid-feedback">
                                    {t(errors.maximumDeposit.message)}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="form-group row m-b-15">
                              <label className="col-md-3 col-form-label">
                                <b>{t("MINIMUM_WITHDRAWAL")}</b>
                              </label>
                              <div className="col-md-7">
                                <input
                                  type="number"
                                  className="form-control"
                                  name="minimumWithdrawal"
                                  defaultValue={minimumWithdrawal}
                                  ref={register({
                                    required: "PLEASE_ENTER_MINIMUM_WITHDRAWAL",
                                    min: {
                                      value: 0,
                                      message:
                                        "PLEASE_ENTER_VALUE_NOT_LESS_THAN_ZERO",
                                    },
                                  })}
                                />
                                {errors.minimumWithdrawal && (
                                  <div className="invalid-feedback">
                                    {t(errors.minimumWithdrawal.message)}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="form-group row m-b-15">
                              <label className="col-md-3 col-form-label">
                                <b>{t("MAXIMUM_WITHDRAWAL")}</b>
                              </label>
                              <div className="col-md-7">
                                <input
                                  type="number"
                                  className="form-control"
                                  name="maximumWithdrawal"
                                  defaultValue={maximumWithdrawal}
                                  ref={register({
                                    required: "PLEASE_ENTER_MAXIMUM_WITHDRAWAL",
                                    min: {
                                      value: 0,
                                      message:
                                        "PLEASE_ENTER_VALUE_NOT_LESS_THAN_ZERO",
                                    },
                                  })}
                                />
                                {errors.maximumWithdrawal && (
                                  <div className="invalid-feedback">
                                    {t(errors.maximumWithdrawal.message)}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card text-truncate mb-3">
                          <div className="card-header">
                            <b>{t("OFFICIAL_BANK_DETAILS")}</b>
                          </div>
                          <div className="card-body">
                            <div className="form-group row m-b-15">
                              <label className="col-md-3 col-form-label">
                                <b>{t("BANK_COUNTRY_CODE")}</b>
                              </label>
                              <div className="col-md-7">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="bankCountryCode"
                                  defaultValue={bankCountryCode}
                                  placeholder={t(
                                    "PLEASE_ENTER_BANK_COUNTRY_CODE"
                                  )}
                                  ref={register({
                                    required: "PLEASE_ENTER_BANK_COUNTRY_CODE",
                                  })}
                                />
                                {errors.bankCountryCode && (
                                  <div className="invalid-feedback">
                                    {t(errors.bankCountryCode.message)}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="form-group row m-b-15">
                              <label className="col-md-3 col-form-label">
                                <b>{t("BANK_HQ_ADDRESS")}</b>
                              </label>
                              <div className="col-md-7">
                                <input
                                  type="text"
                                  className="form-control"
                                  ref={register}
                                  name="bankAddress"
                                  defaultValue={bankAddress}
                                  placeholder={t(
                                    "PLEASE_ENTER_BANK_HQ_ADDRESS"
                                  )}
                                />
                              </div>
                            </div>
                            <div className="form-group row m-b-15">
                              <label className="col-md-3 col-form-label">
                                <b>{t("BANK_POSTAL_CODE")}</b>
                              </label>
                              <div className="col-md-7">
                                <input
                                  type="text"
                                  className="form-control"
                                  ref={register}
                                  name="bankPostalCode"
                                  defaultValue={bankPostalCode}
                                  placeholder={t(
                                    "PLEASE_ENTER_BANK_POSTAL_CODE"
                                  )}
                                />
                              </div>
                            </div>
                            <div className="form-group row m-b-15">
                              <label className="col-md-3 col-form-label">
                                <b>{t("BANK_HOTLINES")}</b>
                              </label>
                              <div className="col-md-7">
                                <input
                                  type="text"
                                  className="form-control"
                                  ref={register}
                                  name="bankPhone"
                                  defaultValue={bankPhone}
                                  placeholder={t("PLEASE_ENTER_BANK_HOTLINES")}
                                />
                              </div>
                            </div>
                            <div className="form-group row m-b-15">
                              <label className="col-md-3 col-form-label">
                                <b>{t("BANK_FAX_NUMBER")}</b>
                              </label>
                              <div className="col-md-7">
                                <input
                                  type="text"
                                  className="form-control"
                                  ref={register}
                                  name="bankFax"
                                  defaultValue={bankFax}
                                  placeholder={t(
                                    "PLEASE_ENTER_BANK_FAX_NUMBER"
                                  )}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-lg-3">
                    <div className="form-group">
                      <button type="submit" className="btn btn-primary">
                        {t("SUBMIT")}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </PanelBody>
          </Panel>
        </div>
      </div>
    </div>
  );
};

export default withRouter(EditBank);
