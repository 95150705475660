import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Panel, PanelBody } from './../../components/panel/panel.jsx';
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';
import ApiEngine from '../../util/ApiEngine.js';
import ToggleButton from "react-toggle-button";
import { ApiKey, ApiUrl, LanguageKey } from "../../util/Constant";
import { setBusy, setIdle, setSaveAdminLog } from '../../redux/AppAction';
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";

const ManageMemberDownlineGames = (props) => {
  const _dispatch = useDispatch();
  const { t, i18n } = useTranslation(LanguageKey._PRIMARY);
  const { control } = useForm();
  const [selectedGame, setSelectedGame] = useState("");
  const [products, setProducts] = useState([]);
  const [productOption, setProductOption] = useState([]);
  const [usernameList, setUsernameList] = useState([]);
  let _history = useHistory();

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    var productResponseJson = await ApiEngine.get(
      ApiUrl._API_GET_ASSIGNED_MEMBER_PRODUCT_SHOW_LIST + "?memberId=" + props.id
    );
    if (productResponseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = productResponseJson[ApiKey._API_DATA_KEY]["products"];
      setProducts(data);
      let productList = [];
      data.map((data) => {
        productList.push({ label: data.productName, value: data.productId });
      });
      setProductOption(productList);
    }
  }

  async function getDownlineGames(product) {
    _dispatch(setBusy());
    var responseJson = await ApiEngine.get(
      ApiUrl._API_GET_MEMBER_DOWNLINE_GAMES_BY_MASTERPRODUCT + "?memberId=" + props.id + "&masterProductId=" + product
    );
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setUsernameList(responseJson[ApiKey._API_DATA_KEY]);
    }
    _dispatch(setSaveAdminLog("VIEW_DOWNLINE_GAMES", _history.location.pathname, { "memberId": props.id, "masterProductId": product }));
    _dispatch(setIdle());
  }

  async function toggleShowValue(value, index, downlineUsername, product) {
    _dispatch(setBusy());
    let responseJson = await ApiEngine.post(ApiUrl._API_UPDATE_DOWNLINE_MEMBER_GAME + "?uplineId=" + props.id + "&username=" + downlineUsername + "&masterProductId=" + product + "&enable=" + !value);
    let tempProducts = usernameList;
    let tempValue = !value;
    let newArr = tempProducts[index];
    if (tempValue === true) {
      newArr.show = true;
    }
    else {
      newArr.show = false;
    }
    tempProducts[index] = { ...newArr };
    _dispatch(setIdle());

    let params = {
      "uplineId": props.id,
      "username": downlineUsername,
      "masterProductId": product,
      "enable": !value
    };
    _dispatch(setSaveAdminLog("EDIT_DOWNLINE_GAMES", _history.location.pathname, params));
  };

  return (
    <div>
      <h1 className="page-header" style={{ display: "flex", justifyContent: "space-between" }}>
        <div>{t("DOWNLINE_GAMES")}</div>
      </h1>
      <div className="row">
        <div className="col-xl-12">
          <Panel>
            <PanelBody>
              <div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <label><b>{t("GAMES")}</b></label>
                    <Controller
                      control={control}
                      name="vip"
                      render={({ onChange, value }) => (
                        <Select options={productOption}
                          placeholder={selectedGame === "" ? t("PLEASE_SELECT_GAME") : selectedGame}
                          value={value}
                          onChange={(e) => {
                            onChange(e.value);
                            setSelectedGame(e.label);
                            getDownlineGames(e.value);
                          }} />
                      )}
                      rules={{ required: "PLEASE_SELECT_VIP_LEVEL" }}
                    />
                  </div>
                </div>
                {usernameList.map((u, index) => {
                  return (<>
                    <hr />
                    <div className="col-lg-12 m-b-5">
                      <div className="display-flex flex-space-between">
                        <b>{u.username}</b>
                        <ToggleButton
                          value={u.show === true ? true : false}
                          onToggle={(value) => {
                            toggleShowValue(value, index, u.username, u.product);
                          }}
                        />
                      </div>
                    </div>
                  </>)
                })}
              </div>
            </PanelBody>
          </Panel>
        </div>
      </div>
    </div>
  )
}

export default ManageMemberDownlineGames;