import i18n from '../i18n';
import { ApiKey, ApiUrl } from "../util/Constant";
import {  createFormBody } from '../util/Util.js';

export const _BUSY = 'busy';
export const _IDLE = 'idle';
export const _MEMBER_SEARCH = '';
export const _SAVE_ADMIN_LOG = '';

export const _ALERT_TYPES = {
    SUCCESS: 'success',
    ERROR: 'error',
    NONE: 'none'
};

/// <summary>
/// Author : -
/// </summary>
export const setBusy = (message) => {
    return async dispatch => {
        dispatch({
            type: _BUSY,
            message: message
        });
    }
}

/// <summary>
/// Author : -
/// this function is to only update user status to logout
/// </summary>
export const setIdle = () => {
    return async dispatch => {
        dispatch({
            type: _IDLE
        });
    }
}

/// <summary>
/// Author: -
/// </summary>
// decide to pop up as success / error type alert will depend on response status
export const showMessage = (responseStatus, responseMessage, disabledTranslate = false) => {
    return async dispatch => {
        dispatch({
            type: responseStatus == true ? _ALERT_TYPES.SUCCESS : _ALERT_TYPES.ERROR,
            message: disabledTranslate ? responseMessage:i18n.t(responseMessage)
        });
    }
}

/// <summary>
/// Author: -
/// </summary>
// close pop up
export const disposeMessage = () => {
    return async dispatch => {
        dispatch({
            type: _ALERT_TYPES.NONE
        });
    }
}

/// <summary>
/// Author: -
/// </summary>
// maintain member search
export const setMemberSearch = (search) => {
    return async dispatch => {
        dispatch({
            type: _MEMBER_SEARCH,
            value: search
        });
    }
}

export const setSaveAdminLog = (actionKey, pathname, search) => {
  let params = {
    actionKey: actionKey,
    body: JSON.stringify(search),
    pathname: pathname
  };
  let formBody = createFormBody(params);

  fetch(ApiUrl._API_CREATE_ADMIN_OPERATION_LOG, {
    method: ApiKey._API_POST,
    headers: {
      "Content-Type": ApiKey._API_FORM_URLENCODED,
      "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
    },
    body: formBody,
  })
    .then((response) => response.json())
    .then((responseJson) => {
      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      }
    });

  return async dispatch => {
    dispatch({
      type: _SAVE_ADMIN_LOG,
      value: search
    });
  }
}