import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import Dropzone from "react-dropzone";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import {
  ApiKey,
  ApiUrl,
  DisplayMode,
  DisplayType,
  LanguageKey,
  ContentTypeString,
  AllVipOption,
  DisplayDevice,
} from "../../util/Constant";
import { useForm, Controller } from "react-hook-form";
import { useLocation } from "react-router-dom";
import {
  stringIsNullOrEmpty,
  createMultiPartFormBody,
  imagePathToFileObject,
} from "../../util/Util";
import { setBusy, setIdle, showMessage } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import ManageBannerLanguage from "./ManageBannerLanguage";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import { fetch } from "whatwg-fetch";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";

/// <summary>
/// Author : -
/// </summary>

const BigWinningDetail = (props) => {
  const { t } = useTranslation(LanguageKey._PRIMARY);
  let _history = useHistory();
  const _location = useLocation();
  const _dispatch = useDispatch();

  const {
    register,
    unregister,
    handleSubmit,
    errors,
    setError,
    control,
    setValue,
  } = useForm();


  const [status, setStatus] = useState(true);
  const [winningDate, setWinningDate] = useState(moment().format("YYYY-MM-DD HH:mm:ss"));
  const [bigWinningId, setBigWinningId] = useState("");
  const [bigWinningRefId, setBigWinningRefId] = useState("");
  const countryOption = [
    { label: t("INDONESIA"), value: "INDONESIA" },
    { label: t("MALAYSIA"), value: "MALAYSIA" },
    { label: t("THAILAND"), value: "THAILAND" },
    { label: t("AUSTRALIA"), value: "AUSTRALIA" }
  ]


  const rankOption = [
    { label: t("NORMAL"), value: "100" },
    { label: t("TOP_1"), value: "1" },
    { label: t("TOP_2"), value: "2" },
    { label: t("TOP_3"), value: "3" }
  ]

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    init();
  }, []);


  const handleEvent = (event, picker) => {
    setWinningDate(moment(picker.startDate).format("YYYY-MM-DD HH:mm:ss"));
  };

  /// <summary>
  /// Author : -
  /// </summary>
  async function init() {
    if (_location.state) {
      var apiUrl = ApiUrl._API_GET_USER_BIG_WINNING_BY_ID;
      let stateBigWinningId = _location.state.id || _location.state.refId;
      apiUrl += "?id=" + stateBigWinningId;
      await fetch(apiUrl, {
        method: ApiKey._API_GET,
        headers: {
          "Content-Type": ApiKey._API_FORM_URLENCODED,
          "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
        },
      })
        .then((response) => response.json())
        .then(async (responseJson) => {
          if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            if (_location.state.id) {
              setBigWinningId(responseJson[ApiKey._API_DATA_KEY]["id"]);
              setBigWinningRefId("");
            } else if (_location.state.refId) {
              setBigWinningId("");
              setBigWinningRefId(responseJson[ApiKey._API_DATA_KEY]["id"]);
            }

            setValue('username', responseJson[ApiKey._API_DATA_KEY]["username"]);
            setValue('currency', responseJson[ApiKey._API_DATA_KEY]["currency"]);
            setValue('providerName', responseJson[ApiKey._API_DATA_KEY]["providerName"]);
            setValue('gameName', responseJson[ApiKey._API_DATA_KEY]["gameName"]);
            setValue('resultUrl', responseJson[ApiKey._API_DATA_KEY]["resultUrl"]);
            setValue('country', responseJson[ApiKey._API_DATA_KEY]["country"]);
            setValue('rank', responseJson[ApiKey._API_DATA_KEY]["rank"]);
            setValue('amount', responseJson[ApiKey._API_DATA_KEY]["amount"]);
            setValue('spinType', responseJson[ApiKey._API_DATA_KEY]["spinType"]);
            setStatus(responseJson[ApiKey._API_DATA_KEY]["status"]);
            setWinningDate(moment(responseJson[ApiKey._API_DATA_KEY]["winningDate"]).format("YYYY-MM-DD HH:mm:ss"))

          }
        });
    }
  }
  /// <summary>
  /// Author : -
  /// </summary>
  const submitForm = async (data, e) => {
    let params = {
      id: bigWinningId,
      username: data.username,
      providerName: data.providerName,
      gameName: data.gameName,
      currency: data.currency,
      resultUrl: data.resultUrl,
      country: data.country,
      rank: data.rank,
      status: data.status,
      amount: data.amount,
      spinType: data.spinType,
      winningDate: winningDate
    };

    
    let formBody = createMultiPartFormBody(params);
    _dispatch(setBusy());

    fetch(ApiUrl._API_CREATE_OR_UPDATE_BIG_WINNING, {
      method: ApiKey._API_POST,
      headers: {
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
      body: formBody,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        _dispatch(setIdle());
        _dispatch(
          showMessage(
            responseJson[ApiKey._API_SUCCESS_KEY],
            t(responseJson[ApiKey._API_MESSAGE_KEY])
          )
        );

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          setBigWinningRefId("");
          setBigWinningId(responseJson[ApiKey._API_DATA_KEY]["id"]);

          _history.replace({
            pathname: _location.pathname,
            state: {
              id: responseJson[ApiKey._API_DATA_KEY]["id"],
            },
          });
        }
      });
  };


  return (
    <div>
      <h1 className="page-header">
        {bigWinningId ? t("EDIT_BIG_WINNING") : t("ADD_BIG_WINNING")}
        <NavigationButton history={_history} />
      </h1>
      <div className="row">
        <div className="col-xl-12">
          <Panel>
            <PanelBody>
              <form onSubmit={handleSubmit(submitForm)}>
                <div className="row">
                  <div className="col-lg-8">
                    <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        <b>{t("USERNAME")}</b>
                      </label>
                      <div className="col-md-7">
                        <input
                          type="text"
                          className="form-control"
                          placeholder={t("PLEASE_ENTER_USERNAME")}
                          name="username"
                          ref={register({ required: "PLEASE_ENTER_USERNAME" })}
                        />
                        {errors.username && (
                          <div className="invalid-feedback">
                            {t(errors.username.message)}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        <b>{t("CURRENCY")}</b>
                      </label>
                      <div className="col-md-7">
                        <input
                          type="text"
                          className="form-control"
                          placeholder={t("PLEASE_ENTER_CURRENCY")}
                          name="currency"
                          ref={register({ required: "PLEASE_ENTER_CURRENCY" })}
                        />
                        {errors.currency && (
                          <div className="invalid-feedback">
                            {t(errors.currency.message)}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        <b>{t("COUNTRY")}</b>
                      </label>
                      <div className="col-md-7">
                        <Controller
                          control={control}
                          name="country"
                          render={({ onChange, value }) => (
                            <Select options={countryOption}
                              value={countryOption.filter(option => option.value == value)}
                              onChange={(e) => {
                                onChange(e.value);
                              }}
                              rules={{ required: "PLEASE_SELECT_COUNTRY" }}
                            />
                          )}
                        />
                        {errors.country && (
                          <div className="invalid-feedback">
                            {t(errors.country.message)}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        <b>{t("PROVIDER_NAME")}</b>
                      </label>
                      <div className="col-md-7">
                        <input
                          type="text"
                          className="form-control"
                          placeholder={t("PLEASE_ENTER_PROVIDER_NAME")}
                          name="providerName"
                          ref={register({ required: "PLEASE_ENTER_PROVIDER_NAME" })}
                        />
                        {errors.providerName && (
                          <div className="invalid-feedback">
                            {t(errors.providerName.message)}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        <b>{t("GAME_NAME")}</b>
                      </label>
                      <div className="col-md-7">
                        <input
                          type="text"
                          className="form-control"
                          placeholder={t("PLEASE_ENTER_GAME_NAME")}
                          name="gameName"
                          ref={register({ required: "PLEASE_ENTER_GAME_NAME" })}
                        />
                        {errors.gameName && (
                          <div className="invalid-feedback">
                            {t(errors.gameName.message)}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        <b>{t("SPIN_TYPE")}</b>
                      </label>
                      <div className="col-md-7">
                        <input
                          type="text"
                          className="form-control"
                          placeholder={t("PLEASE_ENTER_SPIN_TYPE")}
                          name="spinType"
                          ref={register({ required: "PLEASE_ENTER_SPIN_TYPE" })}
                        />
                        {errors.spinType && (
                          <div className="invalid-feedback">
                            {t(errors.spinType.message)}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        <b>{t("RANK")}</b>
                      </label>
                      <div className="col-md-7">
                        <Controller
                          control={control}
                          name="rank"
                          render={({ onChange, value }) => (
                            <Select options={rankOption}
                              value={rankOption.filter(option => option.value == value)}
                              onChange={(e) => {
                                onChange(e.value);
                              }}
                              rules={{ required: "PLEASE_SELECT_RANK" }}
                            />
                          )}
                        />
                        {errors.rank && (
                          <div className="invalid-feedback">
                            {t(errors.rank.message)}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        <b>{t("RESULT_URL")}</b>
                      </label>
                      <div className="col-md-7">
                        <input
                          type="text"
                          className="form-control"
                          placeholder={t("PLEASE_ENTER_RESULT_URL")}
                          name="resultUrl"
                          ref={register}
                        />
                        {errors.resultUrl && (
                          <div className="invalid-feedback">
                            {t(errors.resultUrl.message)}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        <b>{t("WINNING_DATE")}</b>
                      </label>
                      <div className="col-md-7">
                        <DateRangePicker
                          containerStyles={{ width: "100%" }}
                          singleDatePicker={true}
                          onApply={handleEvent}
                          alwaysShowCalendars={true}
                          locale={{ format: "YYYY-MM-DD HH:mm:ss" }}
                          timePicker={true}
                        >
                          <input
                            type="text"
                            className="form-control"
                            value={winningDate}
                          />
                        </DateRangePicker>
                        {errors.winningDate && (
                          <div className="invalid-feedback">
                            {t(errors.winningDate.message)}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        <b>{t("AMOUNT")}</b>
                      </label>
                      <div className="col-md-7">
                        <input
                          type="number"
                          className="form-control"
                          placeholder={t("PLEASE_ENTER_WINNING_AMOUNT")}
                          name="amount"
                          ref={register({
                            required: "PLEASE_ENTER_WINNING_AMOUNT",
                            validate: (value) =>
                              value > 0 || t("PLEASE_ENTER_VALUE_NOT_LESS_THAN_ZERO")
                          })}
                          step="0.001"
                        />
                        {errors.amount && (
                          <div className="invalid-feedback">
                            {t(errors.amount.message)}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        <b>{t("STATUS")}</b>
                      </label>
                      <div className="col-md-7">
                        <div className="switcher">
                          <input
                            type="checkbox"
                            name="status"
                            id="status"
                            onChange={(e) => setStatus(e.target.checked)}
                            value={true}
                            checked={status}
                            ref={register}
                          />
                          <label htmlFor="status"></label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-lg-3">
                    <div className="form-group">
                      <button type="submit" className="btn btn-primary">
                        {t("SUBMIT")}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </PanelBody>
          </Panel>
        </div>
      </div>
    </div>
  );
};

export default withRouter(BigWinningDetail);
