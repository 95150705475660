import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from "react-router-dom";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import { useLocation } from "react-router-dom";
import {
    ApiKey,
    ApiUrl,
    LanguageKey,
} from "../../util/Constant";
import { showMessage, setSaveAdminLog } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ApiEngine from "../../util/ApiEngine";
import { createMultiPartFormBody, stringIsNullOrEmpty } from "../../util/Util.js";
import Icon from '@mdi/react'
import { mdiContentCopy } from '@mdi/js';
import { useHistory } from "react-router-dom";

const MemberSportsBetLimit = props => {
    const { t } = useTranslation(LanguageKey._PRIMARY);
    const _dispatch = useDispatch();
    let _history = useHistory();
    const _location = useLocation();
    const [betLimitData, setBetLimitData] = useState([]);
    const [betLimitStr, setBetLimitStr] = useState("");
    const [betLimitStrM8Bet1, setBetLimitStrM8Bet1] = useState("");
    const [betLimitStrM8Bet2, setBetLimitStrM8Bet2] = useState("");
    const [betLimitStrM8Bet3, setBetLimitStrM8Bet3] = useState("");
    const [initCheck, setInitCheck] = useState(false);
    const [selectedProductName, setSelectedProductName] = useState("");
    const [selectedSportsType, setSelectedSportsType] = useState(0);
    const [selectedMarketType, setSelectedMarketType] = useState(0);
    const [selectedMarketTypeList, setSelectedMarketTypeList] = useState([]);
    const [mpUsername, setMpUsername] = useState("");
    const defaultSplitSymbol = "|";
    const [productNameList, setProductNameList] = useState([]);

   const sportType = [
       { label: '0 (All)', value: 0 },
       { label: '1 (Soccer Only) ', value: 1 },
    ];

    const sabaSportType = [
        { label: '1 (Soccer) ', value: 1 },
        { label: '2 (Basketball) ', value: 2 },
        { label: '3 (Football) ', value: 3 },
        { label: '5 (Tennis) ', value: 5 },
        { label: '8 (Baseball) ', value: 8 },
        { label: '10 (Golf) ', value: 10 },
        { label: '11 (Motorsports) ', value: 11 },
        { label: '43 (E-Sports) ', value: 43 },
        { label: '99 (Other Sports) ', value: 99 },
        { label: '180 (Virtual sports, Virtual Tennis) ', value: 180 },
        { label: '190 (Virtual sports, Virtual Soccer Nation, Virtual Soccer World Cup, Virtual Basketball, Virtual Soccer Asian Cup, Virtual Soccer English Premier, Virtual Soccer Champions Cup, Virtual Soccer Euro Cup, Virtual Sports Parlay) ', value: 190 },
        { label: '9901 (Saba Soccer) ', value: 9901 },
        { label: '9902 (Saba basketball) ', value: 9902 },
        { label: '1MP (Soccer Mix Parlay) ', value: '1MP' },
        { label: '99MP (Mix Parlay) ', value: '99MP' },
     ];

    const marketType = [
        { label: '0 (All)', value: 0 },
        { label: '40 (Mix Parlay) ', value: 40 },
    ];

    var _copyRef = useRef(null);
    const {
        register,
        unregister,
        handleSubmit,
        errors,
        setError,
        control,
        setValue,
    } = useForm();

    /// <summary>
    /// Author : -
    /// </summary>
    useEffect(() => {
        if (_location.state) {
            init();
        }
        setInitCheck(true);
    }, []);

    /// <summary>
    /// Author : -
    /// </summary>
    useEffect(() => {
        if (betLimitData.length > 0) {
            let tempData = '';

            setSelectedProductName(selectedProductName);
            if (selectedProductName == 'SBO Sports') {
                tempData = betLimitData.find(x => x.sportsType === parseInt(selectedSportsType) && x.marketType === selectedMarketType && x.productName === selectedProductName);
            }
            else if (selectedProductName == 'SABA Sports') {
                if(selectedSportsType == '0'){
                    setSelectedSportsType('1');
                }
                tempData = betLimitData.find(x => x.sabaSportsType === selectedSportsType.toString() && x.productName === selectedProductName);
            }
            else {
                tempData = betLimitData.find(x => x.productName === selectedProductName);
            }

            if (typeof (tempData) != "undefined" ) {
                let tempBetLimitStr = '';
                let tempBetLimitStrM8Bet1 = '';
                let tempBetLimitStrM8Bet2 = '';
                let tempBetLimitStrM8Bet3 = '';

                let { productName, minimumBet, maximumBet, maximumBetPerMatch, 
                    maximumBetPayoutPerMatch, max1, max2, max3, 
                    max4, max5, max6, max7, lim1, lim2, lim3, lim4} = tempData;
                if (productName == 'SBO Sports') {
                    tempBetLimitStr = Math.trunc(minimumBet) + defaultSplitSymbol + Math.trunc(maximumBet) + defaultSplitSymbol + Math.trunc(maximumBetPerMatch);
                }
                else if (productName == 'SABA Sports') {
                    tempBetLimitStr = Math.trunc(minimumBet) + defaultSplitSymbol + Math.trunc(maximumBet) + defaultSplitSymbol + Math.trunc(maximumBetPerMatch) + defaultSplitSymbol + Math.trunc(maximumBetPayoutPerMatch);
                }
                else {
                    tempBetLimitStrM8Bet1 = Math.trunc(max1) + defaultSplitSymbol + Math.trunc(max2) + defaultSplitSymbol + Math.trunc(max3) + defaultSplitSymbol + Math.trunc(max4);
                    tempBetLimitStrM8Bet2 = Math.trunc(max5) + defaultSplitSymbol + Math.trunc(max6) + defaultSplitSymbol + Math.trunc(max7);
                    tempBetLimitStrM8Bet3 = Math.trunc(lim1) + defaultSplitSymbol + Math.trunc(lim2) + defaultSplitSymbol + Math.trunc(lim3) + defaultSplitSymbol + Math.trunc(lim4);
                }

                setBetLimitStr(tempBetLimitStr);
                if(tempBetLimitStrM8Bet1 == '0|0|0|0' && tempBetLimitStrM8Bet2 == '0|0|0' && tempBetLimitStrM8Bet3 == '0|0|0|0'){
                    setBetLimitStrM8Bet1("");
                    setBetLimitStrM8Bet2("");
                    setBetLimitStrM8Bet3("");
                }
                else{
                    setBetLimitStrM8Bet1(tempBetLimitStrM8Bet1);
                    setBetLimitStrM8Bet2(tempBetLimitStrM8Bet2);
                    setBetLimitStrM8Bet3(tempBetLimitStrM8Bet3);
                }
            }
            else {
                setBetLimitStrM8Bet1("");
                setBetLimitStrM8Bet2("");
                setBetLimitStrM8Bet3("");
                setBetLimitStr("");
            }

            if(betLimitData.filter(x => x.productName === selectedProductName).length > 0){
                setMpUsername(betLimitData.filter(x => x.productName === selectedProductName)[0]['username']);
            }
        }
    }, [selectedSportsType, selectedMarketType, selectedProductName, selectedMarketTypeList]);

    async function init() {
        let apiUrl = ApiUrl._API_GET_MEMBER_SPORTS_BET_LIMIT + "?id=" + props.id;
        var responseJson = await ApiEngine.get(apiUrl);
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            let data = responseJson[ApiKey._API_DATA_KEY];
            setBetLimitData(data);
            const uniqueGames = [... new Set(data.map(v => v.productName))].map(x => ({ value: x, label: x }));
            if(uniqueGames && uniqueGames.length > 0){
                setProductNameList(uniqueGames);
                setSelectedProductName(uniqueGames[0].value);
                setSelectedMarketTypeList(marketType);
                setSelectedSportsType(selectedSportsType);
                let temp = '';
                if (uniqueGames[0].value == 'SBO Sports') {
                    setSelectedSportsType(0);
                    temp = data.find(x =>
                        x.sportsType === parseInt(selectedSportsType)
                        && x.marketType === selectedMarketType
                        && x.productName === uniqueGames[0].value
                    );
                }
                else if (uniqueGames[0].value == 'SABA Sports') {
                    setSelectedSportsType('1');
                    temp = data.find(x =>
                        x.sabaSportsType === selectedSportsType.toString()
                        && x.productName === uniqueGames[0].value
                    );
                }
                else {
                    temp = data.find(x =>
                        x.productName === uniqueGames[0].value
                    );
                }

                if (typeof (temp) != "undefined") {
                    let tempBetLimitStr = '';
                    let tempBetLimitStrM8Bet1 = '';
                    let tempBetLimitStrM8Bet2 = '';
                    let tempBetLimitStrM8Bet3 = '';

                    let { minimumBet, maximumBet, maximumBetPerMatch, 
                        maximumBetPayoutPerMatch, max1, max2, max3, 
                        max4, max5, max6, max7, lim1, lim2, lim3, lim4} = temp;
                    if (uniqueGames[0].value == 'SBO Sports') {
                        tempBetLimitStr = Math.trunc(minimumBet) + defaultSplitSymbol + Math.trunc(maximumBet) + defaultSplitSymbol + Math.trunc(maximumBetPerMatch);
                    }
                    else if (uniqueGames[0].value == 'SABA Sports') {
                        tempBetLimitStr = Math.trunc(minimumBet) + defaultSplitSymbol + Math.trunc(maximumBet) + defaultSplitSymbol + Math.trunc(maximumBetPerMatch) + defaultSplitSymbol + Math.trunc(maximumBetPayoutPerMatch);
                    }
                    else {
                        tempBetLimitStrM8Bet1 = Math.trunc(max1) + defaultSplitSymbol + Math.trunc(max2) + defaultSplitSymbol + Math.trunc(max3) + defaultSplitSymbol + Math.trunc(max4);
                        tempBetLimitStrM8Bet2 = Math.trunc(max5) + defaultSplitSymbol + Math.trunc(max6) + defaultSplitSymbol + Math.trunc(max7);
                        tempBetLimitStrM8Bet3 = Math.trunc(lim1) + defaultSplitSymbol + Math.trunc(lim2) + defaultSplitSymbol + Math.trunc(lim3) + defaultSplitSymbol + Math.trunc(lim4);
                        if(tempBetLimitStrM8Bet1 == '0|0|0|0' && tempBetLimitStrM8Bet2 == '0|0|0' && tempBetLimitStrM8Bet3 == '0|0|0|0'){
                            setBetLimitStrM8Bet1("");
                            setBetLimitStrM8Bet2("");
                            setBetLimitStrM8Bet3("");
                        }
                        else{
                            setBetLimitStrM8Bet1(tempBetLimitStrM8Bet1);
                            setBetLimitStrM8Bet2(tempBetLimitStrM8Bet2);
                            setBetLimitStrM8Bet3(tempBetLimitStrM8Bet3);
                        }
                    }
                    setBetLimitStr(tempBetLimitStr);
                    // setMpUsername(data.filter(x => x.productName === selectedProductName)[0]['username']);
                    // setProductNameList([... new Set(data.map(v => v.productName))].map(x => ({ value: x, label: x })));
                    setMpUsername(data.filter(x => x.productName === uniqueGames[0].value)[0]['username']);
                }
                else {
                    setBetLimitStr("");
                    setBetLimitStrM8Bet1("");
                    setBetLimitStrM8Bet2("");
                    setBetLimitStrM8Bet3("");
                    setMpUsername("");
                }
            }
            else{
                setSelectedProductName("SBO Sports");
            }
        }
        else {
            setSelectedProductName("SBO Sports");
        }
    }

    const submitForm = async (data) => {
        let params = {};
        if(selectedProductName == 'SBO Sports'){
            let betSetting = betLimitStr.split(defaultSplitSymbol);
            params = {
                "minBet": betSetting[0],
                "maxBet": betSetting[1],
                "maxBetPerMatch": betSetting[2],
                "memberId": props.id,
                "sportsType": data.sportsType,
                "marketType": data.marketType,
                "productName": data.productName,
            };
        }
        else if(selectedProductName == 'SABA Sports'){
            let betSetting = betLimitStr.split(defaultSplitSymbol);
            params = {
                "minBet": betSetting[0],
                "maxBet": betSetting[1],
                "maxBetPerMatch": betSetting[2],
                "maxBetPayoutPerMatch": betSetting[3],
                "memberId": props.id,
                "sabaSportsType": data.sportsType,
                "productName": data.productName,
            };
        }
        else{
            let betSetting1 = betLimitStrM8Bet1.split(defaultSplitSymbol);
            let betSetting2 = betLimitStrM8Bet2.split(defaultSplitSymbol);
            let betSetting3 = betLimitStrM8Bet3.split(defaultSplitSymbol);

            params = {
                "memberId": props.id,
                "max1": betSetting1[0],
                "max2": betSetting1[1],
                "max3": betSetting1[2],
                "max4": betSetting1[3],
                "max5": betSetting2[0],
                "max6": betSetting2[1],
                "max7": betSetting2[2],
                "lim1": betSetting3[0],
                "lim2": betSetting3[1],
                "lim3": betSetting3[2],
                "lim4": betSetting3[3],
                "sportsType": data.sportsType,
                "productName": data.productName,
            };
        }
        _dispatch(setSaveAdminLog("EDIT_ADJUST_BET_LIMIT", _history.location.pathname, params));
        let formBody = createMultiPartFormBody(params);

        let apiUrl = ApiUrl._API_CREATE_OR_UPDATE_MEMBER_SPORTS_BET_LIMIT;
        let responseJson = await ApiEngine.post(apiUrl, formBody);
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            let data = responseJson[ApiKey._API_DATA_KEY];
            setBetLimitData(data);
            let tempBetLimitStr = '';
            let tempBetLimitStrM8Bet1 = '';
            let tempBetLimitStrM8Bet2 = '';
            let tempBetLimitStrM8Bet3 = '';
            if(selectedProductName == 'SBO Sports') {
                let temp = data.find(x => x.sportsType === parseInt(selectedSportsType) && x.marketType === selectedMarketType && x.productName === selectedProductName);
                let { minimumBet, maximumBet, maximumBetPerMatch } = temp;
                tempBetLimitStr = Math.trunc(minimumBet) + defaultSplitSymbol + Math.trunc(maximumBet) + defaultSplitSymbol + Math.trunc(maximumBetPerMatch) ;
            }
            else if(selectedProductName == 'SABA Sports') {
                let temp = data.find(x => x.sabaSportsType === selectedSportsType.toString() && x.productName === selectedProductName);
                let { minimumBet, maximumBet, maximumBetPerMatch, maximumBetPayoutPerMatch } = temp;
                tempBetLimitStr = Math.trunc(minimumBet) + defaultSplitSymbol + Math.trunc(maximumBet) + defaultSplitSymbol + Math.trunc(maximumBetPerMatch) + defaultSplitSymbol + Math.trunc(maximumBetPayoutPerMatch);
            }
            else{
                let temp = data.find(x => x.productName === selectedProductName);
                let { max1, max2, max3, max4, max5, max6, max7, lim1, lim2, lim3, lim4 } = temp;
                tempBetLimitStrM8Bet1 = Math.trunc(max1) + defaultSplitSymbol + Math.trunc(max2) + defaultSplitSymbol + Math.trunc(max3) + defaultSplitSymbol + Math.trunc(max4);
                tempBetLimitStrM8Bet2 = Math.trunc(max5) + defaultSplitSymbol + Math.trunc(max6) + defaultSplitSymbol + Math.trunc(max7);
                tempBetLimitStrM8Bet3 = Math.trunc(lim1) + defaultSplitSymbol + Math.trunc(lim2) + defaultSplitSymbol + Math.trunc(lim3) + defaultSplitSymbol + Math.trunc(lim4);
            }

            setBetLimitStr(tempBetLimitStr);
            if(tempBetLimitStrM8Bet1 == '0|0|0|0' && tempBetLimitStrM8Bet2 == '0|0|0' && tempBetLimitStrM8Bet3 == '0|0|0|0'){
                setBetLimitStrM8Bet1("");
                setBetLimitStrM8Bet2("");
                setBetLimitStrM8Bet3("");
            }
            else{
                setBetLimitStrM8Bet1(tempBetLimitStrM8Bet1);
                setBetLimitStrM8Bet2(tempBetLimitStrM8Bet2);
                setBetLimitStrM8Bet3(tempBetLimitStrM8Bet3);
            }
            setMpUsername(data.filter(x => x.productName === selectedProductName)[0]['username']);
        }
        _dispatch(showMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
    }

    function copyUsername() {
        _copyRef.current.select();
        document.execCommand("copy");
    }

    const handleSportsTypeChange = (obj) => {
        setSelectedSportsType(obj);
        if (obj == 1) {
            const marketTypeArr = marketType.filter(
            (option) => option.value == 0
            );
            setSelectedMarketTypeList(marketTypeArr);
            setSelectedMarketType(0);
        }
        else{
            setSelectedMarketTypeList(marketType);
        }
    };

    const handleSabaSportsTypeChange = (obj) => {
        setSelectedSportsType(obj);
    };

    const handleMarketType = (obj) => {
        setSelectedMarketType(obj);
    };

    const handleProductNameChange = (obj) => {
        setSelectedProductName(obj);

        //set default sporttype when select product 
        if(obj == 'SBO Sports'){
            setSelectedSportsType(0);
        }
        else if(obj == 'SABA Sports'){
            setSelectedSportsType(1);
        }
        else{
            setSelectedSportsType(0);
        }
    };

    return (
        <div>
            <h1 className="page-header">
                {t("ADJUST_BET_LIMIT")}
            </h1>
            <div className="row">
                <div className="col-xl-12">
                    {initCheck && <Panel>
                        <PanelBody>
                            <form onSubmit={handleSubmit(submitForm)}>
                                {selectedProductName == 'SBO Sports' &&
                                    <div className="col-lg-8">
                                        <div className="form-group row m-b-15">
                                            <label className="col-md-4 col-form-label">
                                                <b>{t("SPORTS_TYPE")}</b>
                                            </label>
                                            <div className="col-md-7">
                                                <select className="form-control"
                                                    name="sportsType"
                                                    ref={register}
                                                    onChange={(e) =>
                                                        handleSportsTypeChange(parseInt(e.target.value))
                                                    }
                                                    value={selectedSportsType}>
                                                    {sportType.map((data, index) => { return (<option value={data.value}>{data.label}</option>) })}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                }

                                {selectedProductName == 'SABA Sports' &&
                                    <div className="col-lg-8">
                                        <div className="form-group row m-b-15">
                                            <label className="col-md-4 col-form-label">
                                                <b>{t("SPORTS_TYPE")}</b>
                                            </label>
                                            <div className="col-md-7">
                                                <select className="form-control"
                                                    name="sportsType"
                                                    ref={register}
                                                    onChange={(e) =>
                                                        handleSabaSportsTypeChange(e.target.value)
                                                    }
                                                    value={selectedSportsType}>
                                                    {sabaSportType.map((data, index) => { return (<option value={data.value}>{data.label}</option>) })}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                }

                                {selectedProductName == 'SBO Sports' &&
                                    <div className="col-lg-8">
                                        <div className="form-group row m-b-15">
                                            <label className="col-md-4 col-form-label">
                                                <b>{t("MARKET_TYPE")}</b>
                                            </label>
                                            <div className="col-md-7">
                                                <select className="form-control"
                                                    name="marketType"
                                                    ref={register}
                                                    onChange={(e) =>
                                                        handleMarketType(parseInt(e.target.value))
                                                    }
                                                    value={selectedMarketType}>
                                                    {selectedMarketTypeList.map((data, index) => { return (<option value={data.value}>{data.label}</option>) })}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                }
                                
                                <div className="col-lg-8">
                                    <div className="form-group row m-b-15">
                                        <label className="col-md-4 col-form-label">
                                            <b>{t("PRODUCT_NAME")}</b>
                                        </label>
                                        <div className="col-md-7">
                                            <select className="form-control"
                                                name="productName"
                                                ref={register}
                                                onChange={(e) =>
                                                    handleProductNameChange(e.target.value)
                                                }
                                                // onChange={(e) => setSelectedProductName(e.target.value)}
                                                value={selectedProductName}
                                            >
                                                {productNameList.map((data, index) => { return (<option value={data.value}>{data.label}</option>) })}
                                            </select>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-8">
                                    <div className="form-group row m-b-15">
                                        <label className="col-md-4 col-form-label">
                                            <b>{t("SPORTS_BET_LIMIT")}</b>
                                        </label>
                                        <div className="col-md-7" style={{ display: "flex", lineHeight: "2.5" }}>
                                        {selectedProductName == 'SBO Sports' ? 
                                            <div className="col-4">
                                                <textarea
                                                    className="form-control"
                                                    rows="5"
                                                    style={{ marginTop: "10px", width: "275px" }}
                                                    ref={register}
                                                    name="betLimitCode"
                                                    value={betLimitStr}
                                                    onChange={(e) => {
                                                        setBetLimitStr(e.target.value)
                                                    }}
                                                ></textarea>
                                                {errors.betLimitCode && (
                                                    <div className="invalid-feedback">
                                                        {t(errors.betLimitCode.message)}
                                                    </div>
                                                )}
                                                {t("MIN") + ":" + defaultSplitSymbol + t("MAX") + ":" + defaultSplitSymbol + t("MAX_PER_MATCH")}
                                            </div>
                                            : selectedProductName == 'SABA Sports' ?
                                            <div className="col-4">
                                                <textarea
                                                    className="form-control"
                                                    rows="5"
                                                    style={{ marginTop: "10px", width: "275px" }}
                                                    ref={register}
                                                    name="betLimitCode"
                                                    value={betLimitStr}
                                                    onChange={(e) => {
                                                        setBetLimitStr(e.target.value)
                                                    }}
                                                ></textarea>
                                                {errors.betLimitCode && (
                                                    <div className="invalid-feedback">
                                                        {t(errors.betLimitCode.message)}
                                                    </div>
                                                )}
                                                {t("MIN") + ":" + defaultSplitSymbol + t("MAX") + ":" + defaultSplitSymbol + t("MAX_PER_MATCH")      + ":" + defaultSplitSymbol + t("MAX_PAYOUT_PER_MATCH")}
                                            </div>
                                            :
                                            <div className="col-18">
                                                <textarea
                                                    className="form-control"
                                                    rows="5"
                                                    style={{ marginTop: "10px", width: "275px" }}
                                                    ref={register}
                                                    name="betLimitCode"
                                                    value={betLimitStrM8Bet1}
                                                    onChange={(e) => {
                                                        setBetLimitStrM8Bet1(e.target.value)
                                                    }}
                                                ></textarea>
                                                {errors.betLimitCode && (
                                                    <div className="invalid-feedback">
                                                        {t(errors.betLimitCode.message)}
                                                    </div>
                                                )}
                                                        {t("MAX_BET_HDP_OU_OE") + ":" + defaultSplitSymbol + t("MAX_SINGLE_BET_LIMIT_PARLAY") + ":"}
                                                        <br />
                                                        { defaultSplitSymbol + t("MAX_BET_CORRECT_SCORE") + ":" + defaultSplitSymbol + t("MAX_BET_STEP")}
                                                
                                                <textarea
                                                    className="form-control"
                                                    rows="5"
                                                    style={{ marginTop: "10px", width: "275px" }}
                                                    ref={register}
                                                    name="betLimitCode"
                                                    value={betLimitStrM8Bet2}
                                                    onChange={(e) => {
                                                        setBetLimitStrM8Bet2(e.target.value)
                                                    }}
                                                ></textarea>
                                                {errors.betLimitCode && (
                                                    <div className="invalid-feedback">
                                                        {t(errors.betLimitCode.message)}
                                                    </div>
                                                )}
                                                {t("MAX_BET_1D") + ":" + defaultSplitSymbol + t("MAX_BET_2D") + ":" + defaultSplitSymbol + t("MAX_BET_3D")}

                                                <textarea
                                                    className="form-control"
                                                    rows="5"
                                                    style={{ marginTop: "10px", width: "275px" }}
                                                    ref={register}
                                                    name="betLimitCode"
                                                    value={betLimitStrM8Bet3}
                                                    onChange={(e) => {
                                                        setBetLimitStrM8Bet3(e.target.value)
                                                    }}
                                                ></textarea>
                                                {errors.betLimitCode && (
                                                    <div className="invalid-feedback">
                                                        {t(errors.betLimitCode.message)}
                                                    </div>
                                                )}
                                                        {t("MAX_PER_MATCH_HDP_OU_OE") + ":" + defaultSplitSymbol + t("MIX_PARLAY_LIMIT_PER_COMBINATION") + ":" }
                                                        <br />
                                                        {defaultSplitSymbol + t("MAX_PER_MATCH_CORRECT_SCORE") + ":" + defaultSplitSymbol + t("STEP_LIMIT_PER_COMBINATION")}
                                            </div>    
                                        }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-8">
                                    <div className="form-group row m-b-15">
                                        <label className="col-md-4 col-form-label">
                                            <b>{t("MEMBER_PRODUCT_USERNAME")}</b>
                                        </label>
                                        <div className="col-md-7" style={{ display: "flex", lineHeight: "2.5" }}>
                                            <div className="bet-limit-username-wrapper col-4">
                                                {!stringIsNullOrEmpty(mpUsername) && <><input ref={_copyRef} className="bet-limit-username" readOnly value={mpUsername} />
                                                    <div style={{ marginLeft: "25px", cursor: "pointer" }} onClick={() => copyUsername()}>
                                                        <Icon path={mdiContentCopy}
                                                            size={1}
                                                            color="black"
                                                        />
                                                    </div></>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-lg-3">
                                        <div className="form-group">
                                            <button type="submit" className="btn btn-primary">
                                                {t("SUBMIT")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </PanelBody>
                    </Panel>}
                </div>
            </div>
        </div>
    )
}

export default withRouter(MemberSportsBetLimit);