import React, { useState } from "react";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import { useTranslation } from "react-i18next";
import {
  ApiKey,
  ApiUrl,
  LanguageKey,
  _TRANSACTION_STATUS,
  _WALLET_GAME_TRANSACTION_TYPE,
} from "../../util/Constant";
import {
  createFormBody,
  stringIsNullOrEmpty,
  numberWithCurrencyFormat,
} from "../../util/Util";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import SweetAlert from "react-bootstrap-sweetalert";
import { setBusy, setIdle, showMessage, setSaveAdminLog } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import { fetch } from "whatwg-fetch";
import ApiEngine from "../../util/ApiEngine.js";
import { useHistory } from "react-router-dom";

/// <summary>
/// Author : -
/// </summary>

const WalletTransfer = (props) => {
  const { register, handleSubmit, errors, setValue, trigger } = useForm();
  const _dispatch = useDispatch();
  let _history = useHistory();
  const { t } = useTranslation(LanguageKey._PRIMARY);
  const [walletTransferModal, setWalletTransferModal] = useState(false);
  const [startDate, setStartDate] = useState(
    moment().startOf("day").format("YYYY-MM-DD HH:mm:ss")
  );
  const [endDate, setEndDate] = useState(
    moment().endOf("day").format("YYYY-MM-DD HH:mm:ss")
  );
  const [memberId, setMemberId] = useState("");
  const [nonSeamlessBalance, setNonSeamlessBalance] = useState([]);
  const [fromWalletBalance, setFromWalletBalance] = useState(0);
  const [toWalletBalance, setToWalletBalance] = useState(0);
  const [fromWallet, setFromWallet] = useState();
  const [toWallet, setToWallet] = useState();
  const [submitError, setSubmitError] = useState("");
  const [submitSuccess, setSubmitSuccess] = useState("");
  const [walletTransactions, setWalletTransactions] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [transactionStatus, setTransactionStatus] = useState();
  const ALL_OPTION_LABEL = "All";
  const ALL_OPTION_VALUE = "all";
  const [selectedProduct, setSelectedProduct] = useState(ALL_OPTION_VALUE);
  const [productList, setProductList] = useState([]);
  const [playerId, setPlayerId] = useState();
  const [transactionType, setTransactionType] = useState();
  const [reportData, setReportData] = useState([]);
  const [navigateFromRole, setNavigateFromRole] = useState('');

  const _OPTION_STYLES = {
    control: (base) => ({
      ...base,
      "min-height": "34px",
      height: "34px",
    }),
    valueContainer: (base) => ({
      ...base,
      height: "34px",
      "min-height": "34px",
    }),
    indicatorsContainer: (base) => ({
      ...base,
      height: "34px",
      "min-height": "34px",
    }),
    input: (base) => ({
      height: "34px",
      "min-height": "34px",
    }),
  };

  let _tableColumns = [
    {
      Header: "TRANSACTION_ID",
      accessor: "id",
      Cell: ({ row }) => {
        return (
          <button
            type="button"
            className="btn btn-link"
            onClick={() => {
              readSubReport(row.original.id);
            }}
          >
            {row.original.id}
          </button>
        );
      },
    },
    {
      Header: "DATE",
      accessor: "createdTime",
    },
    {
      Header: "GAME_NAME",
      accessor: "productName",
    },
    {
      Header: "TRASFER_PLAYER",
      accessor: "transferPlayer",
    },
    {
      Header: "WALLET_BEFORE_BALANCE",
      accessor: "walletBefore",
      Cell: ({ row }) => {
        return numberWithCurrencyFormat(parseFloat(row.original.walletBefore));
      },
    },
    {
      Header: "TRANSFER_OUT",
      Cell: ({ row }) => {
        return numberWithCurrencyFormat(
          -parseFloat(row.original.depositAmount)
        );
      },
    },
    {
      Header: "WALLET_AFTER_BALANCE",
      accessor: "walletAfter",
      Cell: ({ row }) => {
        return numberWithCurrencyFormat(parseFloat(row.original.walletAfter));
      },
    },
    {
      Header: "STATUS",
      accessor: "status",
    },
    {
      Header: "GAME_WALLET_BEFORE_BALANCE",
      accessor: "gameWalletBefore",
      Cell: ({ row }) => {
        return numberWithCurrencyFormat(
          parseFloat(row.original.gameWalletBefore)
        );
      },
    },
    {
      Header: "AMOUNT",
      Cell: ({ row }) => {
        return numberWithCurrencyFormat(parseFloat(row.original.depositAmount));
      },
    },
    {
      Header: "BONUS",
      accessor: "bonus",
      Cell: ({ row }) => {
        return numberWithCurrencyFormat(parseFloat(row.original.bonus));
      },
    },
    {
      Header: "GAME_WALLET_AFTER_BALANCE",
      accessor: "gameWalletAfter",
      Cell: ({ row }) => {
        return numberWithCurrencyFormat(
          parseFloat(row.original.gameWalletAfter)
        );
      },
    },
  ];

  async function readSubReport(targetTransferId) {
    try {
      var apiUrl =
        ApiUrl._API_MANAGE_TRANSFER_WALLET +
        "?dateFrom=" +
        startDate +
        "&dateTo=" +
        endDate +
        "&searchDownline=" +
        true;

      if (!stringIsNullOrEmpty(targetTransferId)) {
        apiUrl += "&targetTransferId=" + targetTransferId;
        apiUrl += "&isSubReport=" + true;
      }

      var responseJson = await ApiEngine.get(apiUrl);
      console.log(responseJson[ApiKey._API_DATA_KEY]);
      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        setReportData(responseJson[ApiKey._API_DATA_KEY]);
      } else {
        throw responseJson[ApiKey._API_MESSAGE_KEY];
      }
    } catch (error) {
      _dispatch(showMessage(false, t(error)));
    }
  }

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    if (props.id) {
      setMemberId(props.id);
      getNonSeamLessProductBalance();
      getProductTransactionRecord();
      register({ name: "fromWallet" }, { required: "PLEASE_SELECT_WALLET" });
      register({ name: "toWallet" }, { required: "PLEASE_SELECT_WALLET" });
    }
  }, [props.id]);

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    if (props.navigateFromRole) {
      setNavigateFromRole(props.navigateFromRole);
    }
  }, [props.navigateFromRole]);

  useEffect(() => {
    init();
  }, []);

  /// <summary>
  /// Author : -
  /// </summary>
  async function init() {
    await fetch(ApiUrl._API_GET_MASTER_PRODUCT_ASSIGNMENT_DATA, {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            const accountProductOptionList = [
              { label: ALL_OPTION_LABEL, value: ALL_OPTION_VALUE },
            ];
            responseJson[ApiKey._API_DATA_KEY].forEach(function (option) {
              accountProductOptionList.push({
                label: option.productName,
                value: option.productId,
              });
            });

            setProductList(accountProductOptionList);
          }
        } else {
          _dispatch(
            showMessage(
              responseJson[ApiKey._API_SUCCESS_KEY],
              t(responseJson[ApiKey._API_MESSAGE_KEY])
            )
          );
        }
      });
  }

  /// <summary>
  /// Author : -
  /// </summary>
  const getNonSeamLessProductBalance = async () => {
    await fetch(
      ApiUrl._API_GET_NON_SEAMLESS_PRODUCT_BALANCE + "?memberId=" + props.id,
      {
        method: ApiKey._API_GET,
        headers: {
          "Content-Type": ApiKey._API_FORM_URLENCODED,
          "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
        },
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          let nonSeamlessBalance = [];
          responseJson[ApiKey._API_DATA_KEY].map((product, index) => {
            nonSeamlessBalance.push({
              label: product.productName,
              value: product.productId,
              balance: product.balance,
            });
          });
          setNonSeamlessBalance(nonSeamlessBalance);
        }
      });
  };

  /// <summary>
  /// Author : -
  /// </summary>
  const handleEvent = (event, picker) => {
    setStartDate(moment(picker.startDate).format("YYYY-MM-DD HH:mm:ss"));
    setEndDate(moment(picker.endDate).format("YYYY-MM-DD HH:mm:ss"));
  };

  /// <summary>
  /// Author : -
  /// </summary>
  const getProductTransactionRecord = async () => {
    try {
      var apiUrl =
        ApiUrl._API_MANAGE_TRANSFER_WALLET +
        "?dateFrom=" +
        startDate +
        "&dateTo=" +
        endDate +
        "&searchDownline=" +
        true;

      apiUrl += "&memberId=" + props.id;

      if (selectedProduct != ALL_OPTION_VALUE) {
        apiUrl += "&masterProductId=" + selectedProduct;
      }

      if (transactionType != ALL_OPTION_VALUE) {
        apiUrl += "&transactionTypeId=" + transactionType;
      }

      if (transactionType != ALL_OPTION_VALUE) {
        apiUrl += "&targetStatus=" + transactionStatus;
      }

      if (!stringIsNullOrEmpty(playerId)) {
        apiUrl += "&playerId=" + playerId;
      }

      var responseJson = await ApiEngine.get(apiUrl);

      console.log(responseJson[ApiKey._API_DATA_KEY]);
      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        setReportData(responseJson[ApiKey._API_DATA_KEY]);
      } else {
        throw responseJson[ApiKey._API_MESSAGE_KEY];
      }
    } catch (error) {
      _dispatch(showMessage(false, t(error)));
    }
  };

  /// <summary>
  /// Author : -
  /// </summary>
  const transferAllToMain = async () => {
    let params = {
      memberId: memberId,
    };
    adminLog("CREATE_TRANSFER_ALL_TO_MAIN", params);
    let formBody = createFormBody(params);
    _dispatch(setBusy());

    await fetch(ApiUrl._API_TRANSFER_ALL_TO_MAIN, {
      method: ApiKey._API_POST,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
      body: formBody,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        _dispatch(setIdle());

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          getNonSeamLessProductBalance();
        }
        _dispatch(
          showMessage(
            responseJson[ApiKey._API_SUCCESS_KEY],
            t(responseJson[ApiKey._API_MESSAGE_KEY])
          )
        );
        getProductTransactionRecord();
      });
  };

  /// <summary>
  /// Author : -
  /// </summary>
  const submitForm = async (data, e) => {
    let params = {
      fromWallet: data.fromWallet.value,
      toWallet: data.toWallet.value,
      amount: data.amount,
      memberId: memberId,
    };
    adminLog("CREATE_WALLET_TRANSFER_TRANSACTION", params);
    let formBody = createFormBody(params);
    _dispatch(setBusy());

    await fetch(ApiUrl._API_CREATE_WALLET_TRANSFER_TRANSACTION, {
      method: ApiKey._API_POST,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
      body: formBody,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        _dispatch(setIdle());

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          getNonSeamLessProductBalance();
        }

        _dispatch(
          showMessage(
            responseJson[ApiKey._API_SUCCESS_KEY],
            t(responseJson[ApiKey._API_MESSAGE_KEY])
          )
        );
        getProductTransactionRecord();
      });
  };

  async function adminLog(actionkey, data) {
    let params = [];
    if (stringIsNullOrEmpty(data)) {
      params = {
        "startDate": startDate,
        "endDate": endDate,
        "searchDownline": "true",
        "memberId": props.id
      };
      if (selectedProduct != ALL_OPTION_VALUE) {
        params["masterProductId"] = selectedProduct;
      }

      if (transactionType != ALL_OPTION_VALUE) {
        params["transactionTypeId"] = transactionType;
      }

      if (!stringIsNullOrEmpty(playerId)) {
        params["playerId"] = playerId;
      }
    } else {
      params = data;
    }
    _dispatch(setSaveAdminLog(actionkey, _history.location.pathname, params));
  }

  return (
    <div>
      <form>
        {showConfirmation && (
          <SweetAlert
            warning
            showCancel
            confirmBtnText={t("CONFIRM")}
            cancelBtnText={t("CANCEL")}
            confirmBtnBsStyle="danger"
            title={t("ARE_YOU_SURE")}
            onConfirm={() => {
              setShowConfirmation(false);
              transferAllToMain();
            }}
            onCancel={() => {
              setShowConfirmation(false);
            }}
          >
            {t("UNABLE_TO_REVERT")}
          </SweetAlert>
        )}
        <div className="row">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-3">
                <div className="form-group">
                  <label>
                    <b>{t("DATE")}</b>
                  </label>
                  <div
                    className="input-group"
                    style={{ width: "100%", flexFlow: "nowrap" }}
                  >
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="fa fa-calendar"></i>
                      </span>
                    </div>
                    <div style={{ width: "80%" }}>
                      <DateRangePicker
                        containerStyles={{ width: "100%" }}
                        startDate={startDate}
                        endDate={endDate}
                        onApply={handleEvent}
                        alwaysShowCalendars={true}
                        locale={{ format: "YYYY-MM-DD HH:mm:ss" }}
                        timePicker={true}
                        timePickerSeconds={true}
                        ranges={{
                          Today: [
                            moment().startOf("day"),
                            moment().endOf("day"),
                          ],
                          Yesterday: [
                            moment().subtract(1, "days").startOf("day"),
                            moment().subtract(1, "days").endOf("day"),
                          ],
                          "Last 7 Days": [
                            moment().subtract(6, "days").startOf("day"),
                            moment().endOf("day"),
                          ],
                          "Last 30 Days": [
                            moment().subtract(29, "days").startOf("day"),
                            moment().endOf("day"),
                          ],
                          "This Month": [
                            moment().startOf("month"),
                            moment().endOf("month"),
                          ],
                          "Last Month": [
                            moment().subtract(1, "month").startOf("month"),
                            moment().subtract(1, "month").endOf("month"),
                          ],
                          "This Year": [
                            moment().startOf("year"),
                            moment().endOf("year"),
                          ],
                        }}
                      >
                        <input
                          type="text"
                          className="form-control"
                          value={startDate + " - " + endDate}
                        />
                      </DateRangePicker>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="form-group">
                  <label>
                    <b>{t("PLAYERID")}</b>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={playerId}
                    placeholder={t("PLAYERID")}
                    readOnly={!stringIsNullOrEmpty(props.playeid)}
                    onChange={(e) => {
                      setPlayerId(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-3">
                <div className="form-group">
                  <label>
                    <b>{t("GAME_NAME")}</b>
                  </label>
                  <Select
                    styles={_OPTION_STYLES}
                    value={productList.filter(
                      (product) => product.value == selectedProduct
                    )}
                    options={productList}
                    onChange={(e) => {
                      setSelectedProduct(e.value);
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-3">
                <div className="form-group">
                  <label>
                    <b>{t("STATUS")}</b>
                  </label>
                  <Select
                    styles={_OPTION_STYLES}
                    options={_TRANSACTION_STATUS}
                    placeholder={""}
                    value={_TRANSACTION_STATUS.filter(
                      (x) => x["value"] == transactionStatus
                    )}
                    onChange={(e) => {
                      setTransactionStatus(e.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3">
                <div className="form-group">
                  <label>
                    <b>{t("TRANSACTION")}</b>
                  </label>
                  <Select
                    styles={_OPTION_STYLES}
                    options={_WALLET_GAME_TRANSACTION_TYPE}
                    placeholder={""}
                    value={_WALLET_GAME_TRANSACTION_TYPE.filter(
                      (x) => x["value"] == transactionType
                    )}
                    onChange={(e) => {
                      setTransactionType(e.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-2">
                <button
                  type="button"
                  style={{ marginLeft: "5px" }}
                  className="btn btn-primary"
                  onClick={() => { getProductTransactionRecord(); adminLog("VIEW_WALLET_TRANSFER") }}
                >
                  {t("SEARCH")}
                </button>
              </div>
              {navigateFromRole !== "CS" ? <div className="col-lg-4">
                <button
                  type="button"
                  className="btn btn-warning"
                  onClick={() => {
                    setWalletTransferModal(!walletTransferModal);
                  }}
                >
                  {t("CREDIT_TRANSFER")}
                </button>
                <button
                  type="button"
                  style={{ marginLeft: "5px" }}
                  className="btn btn-danger"
                  onClick={() => {
                    setShowConfirmation(true);
                  }}
                >
                  {t("TRANSFER_ALL_TO_MAIN")}
                </button>
              </div> : <></>}
            </div>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-lg-12">
            <ReactTable data={reportData} columns={_tableColumns} />
          </div>
        </div>
      </form>
      <Modal
        isOpen={walletTransferModal}
        toggle={() => {
          setWalletTransferModal(!walletTransferModal);
        }}
        size="lg"
        centered={true}
      >
        <ModalHeader
          toggle={() => {
            setWalletTransferModal(!walletTransferModal);
          }}
        >
          {t("WALLET_TRANSFER")}
        </ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit(submitForm)}>
            <div className="row row-marginBottom-10">
              <div className="col-lg-2">
                <label>
                  <b>{t("USERNAME")}</b>
                </label>
              </div>
              <div className="col-lg-8">
                <label>{props.username}</label>
              </div>
            </div>
            <div className="row row-marginBottom-10">
              <div className="col-lg-2">
                <label>
                  <b>{t("FROM")}</b>
                </label>
              </div>
              <div className="col-lg-8">
                <Select
                  name="fromWallet"
                  options={nonSeamlessBalance}
                  placeholder={
                    fromWallet && fromWallet.label
                      ? fromWallet.label
                      : t("PLEASE_SELECT_WALLET")
                  }
                  value={fromWallet}
                  onChange={(e) => {
                    setValue("fromWallet", e);
                    setFromWallet(e);
                    setFromWalletBalance(e.balance);
                    trigger("fromWallet");
                  }}
                />
                {errors.fromWallet && (
                  <div className="invalid-feedback">
                    {t(errors.fromWallet.message)}
                  </div>
                )}
              </div>
            </div>
            <div className="row row-marginBottom-10">
              <div className="col-lg-2">
                <label>
                  <b>{t("FROM_WALLET")}</b>
                </label>
              </div>
              <div className="col-lg-8">
                {parseFloat(fromWalletBalance).toFixed(2)}
              </div>
            </div>
            <div className="row row-marginBottom-10">
              <div className="col-lg-2">
                <label>
                  <b>{t("TO")}</b>
                </label>
              </div>
              <div className="col-lg-8">
                <Select
                  name="toWallet"
                  options={nonSeamlessBalance}
                  placeholder={
                    toWallet && toWallet.label
                      ? toWallet.label
                      : t("PLEASE_SELECT_WALLET")
                  }
                  value={toWallet}
                  onChange={(e) => {
                    setValue("toWallet", e);
                    setToWallet(e);
                    setToWalletBalance(e.balance);
                    trigger("toWallet");
                  }}
                />
                {errors.toWallet && (
                  <div className="invalid-feedback">
                    {t(errors.toWallet.message)}
                  </div>
                )}
              </div>
            </div>
            <div className="row row-marginBottom-10">
              <div className="col-lg-2">
                <label>
                  <b>{t("TO_WALLET")}</b>
                </label>
              </div>
              <div className="col-lg-8">
                {parseFloat(toWalletBalance).toFixed(2)}
              </div>
            </div>
            <div className="row row-marginBottom-10">
              <div className="col-lg-2">
                <label>
                  <b>{t("AMOUNT")}</b>
                </label>
              </div>
              <div className="col-lg-8">
                <input
                  type="number"
                  step="0.01"
                  className="form-control"
                  placeholder={t("PLEASE_ENTER_AMOUNT")}
                  name="amount"
                  defaultValue={0}
                  ref={register({
                    required: "PLEASE_ENTER_AMOUNT",
                    validate: {
                      moreThanZero: (value) =>
                        parseFloat(value) > 0 ||
                        "PLEASE_ENTER_VALUE_MORE_THAN_ZERO",
                    },
                  })}
                />
                {errors.amount && (
                  <div className="invalid-feedback">
                    {t(errors.amount.message)}
                  </div>
                )}
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-lg-3">
                <button type="submit" className="btn btn-primary">
                  {t("SUBMIT")}
                </button>
              </div>
            </div>
          </form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default WalletTransfer;