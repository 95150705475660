import React, { useState, useEffect } from "react";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import { ApiKey, ApiUrl, LanguageKey } from "../../util/Constant";
import { useForm } from "react-hook-form";
import { setBusy, setIdle, setSaveAdminLog, showMessage } from "../../redux/AppAction";
import { stringIsNullOrEmpty, createMultiPartFormBody } from "../../util/Util.js";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from 'moment';
import ApiEngine from "../../util/ApiEngine";
import { useHistory } from "react-router-dom";

const ManageTransactionTimer = (props) => {
  const { t } = useTranslation(LanguageKey._PRIMARY);
  const _dispatch = useDispatch();
  let _history = useHistory();
  const { register, handleSubmit, errors } = useForm();

  const [depositHour, setDepositHour] = useState("");
  const [depositMinute, setDepositMinute] = useState("");
  const [depositSecond, setDepositSecond] = useState("");
  const [withdrawalHour, setWithdrawalHour] = useState("");
  const [withdrawalMinute, setWithdrawalMinute] = useState("");
  const [withdrawalSecond, setWithdrawalSecond] = useState("");

  useEffect(() => {
    init();
  }, []);

  /// <summary>
  /// Author : -
  /// </summary>
  async function init() {
    fetch(ApiUrl._API_GET_TRANSACTION_TIMER, {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          let data = responseJson[ApiKey._API_DATA_KEY];
          data.map((keyValuePair, index) => {
            if (keyValuePair['key'] === 'DepositTransactionTimer') {
              let depositTimeStr = keyValuePair['value'];
              setDepositHour(moment(depositTimeStr, 'HH:mm:ss').get('hours'));
              setDepositMinute(moment(depositTimeStr, 'HH:mm:ss').get('minutes'));
              setDepositSecond(moment(depositTimeStr, 'HH:mm:ss').get('seconds'));
            }
            if (keyValuePair['key'] === 'WithdrawalTransactionTimer') {
              let withdrawalTimeStr = keyValuePair['value'];
              setWithdrawalHour(moment(withdrawalTimeStr, 'HH:mm:ss').get('hours'));
              setWithdrawalMinute(moment(withdrawalTimeStr, 'HH:mm:ss').get('minutes'));
              setWithdrawalSecond(moment(withdrawalTimeStr, 'HH:mm:ss').get('seconds'));
            }
          })
        }
      });
  }

  /// <summary>
  /// Author : -
  /// </summary>
  const submitForm = async (data, e) => {
    if (stringIsNullOrEmpty(data.depositHour) || stringIsNullOrEmpty(data.depositMinute) || stringIsNullOrEmpty(data.depositSecond) || stringIsNullOrEmpty(data.withdrawalHour) || stringIsNullOrEmpty(data.withdrawalMinute) || stringIsNullOrEmpty(data.withdrawalSecond)) {
      _dispatch(showMessage(false, t("VALUE_SHOULD_NOT_BE_EMPTY")));
      return
    }
    let params = {
      'depositHour': data.depositHour.length === 2 ? data.depositHour : "0" + data.depositHour,
      'depositMinute': data.depositMinute.length === 2 ? data.depositMinute : "0" + data.depositMinute,
      'depositSecond': data.depositSecond.length === 2 ? data.depositSecond : "0" + data.depositSecond,
      'withdrawalHour': data.withdrawalHour.length === 2 ? data.withdrawalHour : "0" + data.withdrawalHour,
      'withdrawalMinute': data.withdrawalMinute.length === 2 ? data.withdrawalMinute : "0" + data.withdrawalMinute,
      'withdrawalSecond': data.withdrawalSecond.length === 2 ? data.withdrawalSecond : "0" + data.withdrawalSecond,
    };
    _dispatch(setSaveAdminLog("EDIT_TRANSACTION_TIMER", _history.location.pathname, params));
    let formBody = createMultiPartFormBody(params);
    let apiUrl = ApiUrl._API_CREATE_OR_UPDATE_SYSTEM_SETTINGS + "?type=TransactionTimer";
    let responseJson = await ApiEngine.post(apiUrl, formBody);
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      init();
    }
    _dispatch(showMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
  };

  return (
    <div>
      <h1 className="page-header">{t("MANAGE_TRANSACTION_TIMER")}</h1>
      <div className="row">
        <div className="col-xl-12">
          <Panel>
            <PanelBody>
              <form onSubmit={handleSubmit(submitForm)}>
                <div className="col-lg-8">
                  <div className="form-group row m-b-15">
                    <label className="col-md-4 col-form-label">
                      <b>{t("DEPOSIT_TIMER")}</b>
                    </label>
                    <div className="col-md-7" style={{ display: "flex", lineHeight: "2.5" }}>
                      <div className="col-4">
                        <input
                          type="number"
                          className="form-control"
                          name="depositHour"
                          value={depositHour}
                          onChange={(e) => setDepositHour(e.target.value)}
                          min={0}
                          max={23}
                          ref={register({
                            min: {
                              value: 0,
                              message:
                                t("PLEASE_ENTER_VALUE_NOT_LESS_THAN_ZERO"),
                            },
                            max: {
                              value: 23,
                              message:
                                t("PLEASE_ENTER_VALUE_NOT_MORE_THAN_23"),
                            },
                          })}
                        />
                      </div>
                      {t("HOURS")}
                      <div className="col-4">
                        <input
                          type="number"
                          className="form-control"
                          name="depositMinute"
                          value={depositMinute}
                          onChange={(e) => setDepositMinute(e.target.value)}
                          min={0}
                          max={59}
                          ref={register({
                            min: {
                              value: 0,
                              message:
                                t("PLEASE_ENTER_VALUE_NOT_LESS_THAN_ZERO"),
                            },
                            max: {
                              value: 59,
                              message:
                                t("PLEASE_ENTER_VALUE_NOT_MORE_THAN_59"),
                            },
                          })}
                        />
                      </div>
                      {t("MINUTES")}
                      <div className="col-4">
                        <input
                          type="number"
                          className="form-control"
                          name="depositSecond"
                          value={depositSecond}
                          onChange={(e) => setDepositSecond(e.target.value)}
                          min={0}
                          max={59}
                          ref={register({
                            min: {
                              value: 0,
                              message:
                                t("PLEASE_ENTER_VALUE_NOT_LESS_THAN_ZERO"),
                            },
                            max: {
                              value: 59,
                              message:
                                t("PLEASE_ENTER_VALUE_NOT_MORE_THAN_59"),
                            },
                          })}
                        />
                      </div>
                      {t("SECONDS")}
                    </div>
                  </div>
                </div>
                <div className="col-lg-8">
                  <div className="form-group row m-b-15">
                    <label className="col-md-4 col-form-label">
                      <b>{t("WITHDRAWAL_TIMER")}</b>
                    </label>
                    <div className="col-md-7" style={{ display: "flex", lineHeight: "2.5" }}>
                      <div className="col-4">
                        <input
                          type="number"
                          className="form-control"
                          name="withdrawalHour"
                          value={withdrawalHour}
                          onChange={(e) => setWithdrawalHour(e.target.value)}
                          min={0}
                          max={23}
                          ref={register({
                            min: {
                              value: 0,
                              message:
                                t("PLEASE_ENTER_VALUE_NOT_LESS_THAN_ZERO"),
                            },
                            max: {
                              value: 23,
                              message:
                                t("PLEASE_ENTER_VALUE_NOT_MORE_THAN_23"),
                            },
                          })}
                        />
                      </div>
                      {t("HOURS")}
                      <div className="col-4">
                        <input
                          type="number"
                          className="form-control"
                          name="withdrawalMinute"
                          value={withdrawalMinute}
                          onChange={(e) => setWithdrawalMinute(e.target.value)}
                          min={0}
                          max={59}
                          ref={register({
                            min: {
                              value: 0,
                              message:
                                t("PLEASE_ENTER_VALUE_NOT_LESS_THAN_ZERO"),
                            },
                            max: {
                              value: 59,
                              message:
                                t("PLEASE_ENTER_VALUE_NOT_MORE_THAN_59"),
                            },
                          })}
                        />
                      </div>
                      {t("MINUTES")}
                      <div className="col-4">
                        <input
                          type="number"
                          className="form-control"
                          name="withdrawalSecond"
                          value={withdrawalSecond}
                          onChange={(e) => setWithdrawalSecond(e.target.value)}
                          min={0}
                          max={59}
                          ref={register({
                            min: {
                              value: 0,
                              message:
                                t("PLEASE_ENTER_VALUE_NOT_LESS_THAN_ZERO"),
                            },
                            max: {
                              value: 59,
                              message:
                                t("PLEASE_ENTER_VALUE_NOT_MORE_THAN_59"),
                            },
                          })}
                        />
                      </div>
                      {t("SECONDS")}
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-lg-3">
                    <div className="form-group">
                      <button type="submit" className="btn btn-primary">
                        {t("SAVE_CHANGES")}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </PanelBody>
          </Panel>
        </div>
      </div>
    </div>
  );
};

export default ManageTransactionTimer;
